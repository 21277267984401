import React from 'react';
import { useTranslation } from 'react-i18next';

import { BellOutlined } from '@ant-design/icons';

import { Badge, Button, Card, Dropdown, List } from 'antd';
import NotificationItem from './notificationItem';
import { notificationType } from '@/modules/common/types/common.type';
import { usePageView } from '@/hooks/usePage.hook';
import { ROUTE_PATH } from '@/consts';

interface Props {
  notifications?: notificationType[];
  unRead?: number;
  loading?: boolean;
  UpdateReads?: (id: string[]) => Promise<void>;
}
const NotificationMenu: React.FC<Props> = (props) => {
  const { notifications, unRead, UpdateReads } = props;
  const { t } = useTranslation();
  const { handleOpenPage } = usePageView();

  return (
    <Dropdown
      placement="bottomRight"
      dropdownRender={() => (
        <Card size="small" title={t('Notification')}>
          <List
            dataSource={notifications}
            className="w-[350px] max-h-[200px] overflow-auto"
            renderItem={(notification) => (
              <NotificationItem
                key={notification.id}
                notification={notification}
                onRead={UpdateReads}
              />
            )}
          />
          <Button
            type="text"
            color="primary"
            className="w-full"
            onClick={() => {
              handleOpenPage({
                path: ROUTE_PATH.NOTIFICATION,
              });
            }}
          >
            {t('Show all')}
          </Button>
        </Card>
      )}
    >
      <Badge count={unRead} size="small">
        <Button type="text" icon={<BellOutlined />} />
      </Badge>
    </Dropdown>
  );
};
export default NotificationMenu;
