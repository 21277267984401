import React from 'react';
import '@/styles/antd/custom.less';
import '@/styles/index.css';
import background from '@/images/bg/ERP.svg';

interface Props {}

const HomePage: React.FC<Props> = () => (
  <div className="w-full flex flex-col items-center" style={{ height: '80vh' }}>
    <img src={background} className="w-full h-full mt-5" />
  </div>
);

export default HomePage;
