import React, { useEffect, ReactNode, useRef } from 'react';

import {
  NotificationTwoTone,
  ExclamationCircleTwoTone,
  ClockCircleTwoTone,
  MessageTwoTone,
  SelectOutlined,
} from '@ant-design/icons';

import { EnumNotificationType } from '@/graphql/types/generate';

import moment from 'moment';
import { Avatar, List } from 'antd';
import useIntersection from '@/hooks/useIntersection';
import { notificationType } from '@/modules/common/types/common.type';
import { usePageView } from '@/hooks/usePage.hook';
import classnames from 'classnames';

export const ICON_MAP: {
  [K in keyof typeof EnumNotificationType | 'default']: ReactNode;
} = {
  [EnumNotificationType.Announcement]: (
    <NotificationTwoTone twoToneColor="#66BB6A" />
  ),
  [EnumNotificationType.Alert]: (
    <ExclamationCircleTwoTone twoToneColor="#f5222d" />
  ),
  [EnumNotificationType.Message]: <MessageTwoTone twoToneColor="#1890ff" />,
  [EnumNotificationType.Remind]: <ClockCircleTwoTone twoToneColor="#F9A825" />,
  ['default']: <NotificationTwoTone twoToneColor="#66BB6A" />,
};

interface Props {
  notification: notificationType;
  onRead?: (id: string[]) => Promise<void>;
}
const NotificationItem: React.FC<Props> = (props) => {
  const { notification, onRead } = props;
  const elementRef = useRef(null);
  const isIntersected = useIntersection(elementRef, 0.8);
  const { handleOpenPage } = usePageView();

  useEffect(() => {
    if (isIntersected && !notification.isRead) {
      onRead && onRead([notification.id]);
    }
  }, [isIntersected]);
  const pathObj = {
    path: null,
    ...notification.values,
  };

  return (
    <List.Item
      className={classnames({ ['cursor-pointer']: !!pathObj.path })}
      key={notification.id}
      onClick={() => {
        handleOpenPage({
          path: pathObj.path ?? '',
        });
      }}
      extra={
        <>
          {pathObj.path && <SelectOutlined className="mr-2" />}
          {!notification.isRead && <span className="text-red-500">●</span>}
        </>
      }
    >
      <List.Item.Meta
        title={
          <div
            ref={elementRef}
            dangerouslySetInnerHTML={{
              __html: notification.message?.split('\n').join('<br />') ?? '',
            }}
          />
        }
        description={
          <span style={{ fontSize: 12 }}>
            {moment(notification.createdAt).fromNow()}
          </span>
        }
        avatar={
          <Avatar
            size="large"
            style={{ background: 'transparent' }}
            icon={ICON_MAP[notification.type || 'default']}
          />
        }
      ></List.Item.Meta>
    </List.Item>
  );
};
export default NotificationItem;
