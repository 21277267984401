import { Dropdown } from 'antd';
import React, { useEffect, useState } from 'react';
import { MeQuery } from '@/graphql/types/generate';
import * as localStorageHelper from '@/helpers/localStorage.helper';
import { useRecoilState, useSetRecoilState } from 'recoil';
import { salesGroupState } from '@/store/salesGroup.store';
import { usePageView } from '@/hooks/usePage.hook';
import { ROUTE_PATH } from '@/consts';
import { meState } from '@/store/me.store';

interface Props {
  salesTeamGroup: MeQuery['me']['defaultAvailableSalesTeamGroup'][];
  defaultSalesGroup: MeQuery['me']['defaultAvailableSalesTeamGroup'];
  onItemClick?: (key: string) => void;
}

export const SalesGroupDropdown: React.FC<Props> = ({
  salesTeamGroup,
  defaultSalesGroup,
}) => {
  const [_, setSalesGroup] = useRecoilState(salesGroupState);
  const setMeState = useSetRecoilState(meState);
  const [selectGroup, setSelectGroup] =
    useState<MeQuery['me']['defaultAvailableSalesTeamGroup']>();
  const { handlePageChange } = usePageView();

  useEffect(() => {
    if (defaultSalesGroup) {
      setSelectGroup(defaultSalesGroup);
    }
  }, [defaultSalesGroup]);

  const onChange = (value: any) => {
    const findKey = salesTeamGroup.find((item) => {
      return item?.code === value.key;
    });
    const me = localStorageHelper.getMe();
    setSalesGroup(findKey);
    setMeState({
      ...me,
      defaultAvailableSalesTeamGroup: findKey,
    });
    me &&
      localStorageHelper.setMe({
        ...me,
        defaultAvailableSalesTeamGroup: findKey,
      });
    setSelectGroup(findKey);
    handlePageChange({ path: ROUTE_PATH.HOME });
    window.location.reload();
  };
  const group = salesTeamGroup.map((item) => ({
    key: item?.code ?? '',
    label: item?.name,
  }));

  return (
    <Dropdown
      menu={{
        items: group,
        onClick: onChange,
      }}
      overlayClassName="header-dropdown-menus"
    >
      <span className="header-dropdown">
        {<span className="font-bold">{selectGroup?.name}</span>}
      </span>
    </Dropdown>
  );
};
