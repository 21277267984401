import React from 'react';
import { ConfigProvider } from 'antd';
import '@/styles/antd/custom.less';
import '@/styles/index.css';
import MainRouter from './routers';

interface Props {}

export const App: React.FC<Props> = () => {
  return (
    <ConfigProvider
      theme={{
        token: {
          colorPrimary: '#001529cc',
        },
      }}
    >
      <MainRouter />
    </ConfigProvider>
  );
};
