import React from 'react';
import Logo from '@/images/erpLogo/logo3.png';

interface Props {
  isLoading?: boolean;
}

const PageLoading: React.FC<Props> = ({ isLoading = true }) => {
  return (
    <>
      {isLoading && (
        <div className="  top-0 left-0 right-0 bottom-0  flex justify-center items-center flex-col fixed  bg-[rgba(255,255,255,0.6)] z-30">
          <div className="  w-[300px] h-[50px]  overflow-hidden  flex justify-center animate-[loading_3s_ease-in-out_infinite] ">
            <img src={Logo} className="" height={50} width={50} />
            <h2 className="text-3xl w-[200px] ml-2 h-full flex items-center truncate">
              CLINICO ERP
            </h2>
          </div>
          <h2 className="text-2xl w-[200px] h-[40px] opacity-80 flex items-end justify-center">
            Loading
            <div className="flex ml-2">
              <div className="w-1 h-1 ml-1 rounded-2xl bg-black animate-[loadingPoint_1s_.1s_ease-in-out_infinite]"></div>
              <div className="w-1 h-1 ml-1 rounded-2xl bg-black animate-[loadingPoint_1s_.2s_ease-in-out_infinite]"></div>
              <div className="w-1 h-1 ml-1 rounded-2xl bg-black animate-[loadingPoint_1s_.3s_ease-in-out_infinite]"></div>
            </div>
          </h2>
        </div>
      )}
    </>
  );
};

export default PageLoading;
