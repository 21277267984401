import React, { useEffect, useState } from 'react';
import { Col, Divider, Layout, Menu, MenuProps, Row } from 'antd';
import { AiOutlineMenuFold, AiOutlineMenuUnfold } from 'react-icons/ai';
import { useTranslation } from 'react-i18next';
import {
  PageView,
  PageViewTabs,
} from '@/modules/common/components/tabs/pageView.tabs';
import styles from './multiTabs.layout.module.css';
import { LanguageDropdown } from '@/modules/common/components/dropdown/language.dropdown';
import { ProfileDropdown } from '@/modules/common/components/dropdown/profile.dropdown';
import { SalesGroupDropdown } from '@/modules/common/components/dropdown/sales.dropdown';

import Logo from '@/images/erpLogo/logo3.png';
import NotificationMenu from '@/modules/notifications/components/menu/notification.menu';
import {
  MeQuery,
  useNotificationsLazyQuery,
  useNotificationSubscriptionSubscription,
  useReadNotificationsMutation,
} from '@/graphql/types/generate';
import { notificationType } from '@/modules/common/types/common.type';
import { usePageView } from '@/hooks/usePage.hook';
import configs from '@/configs';
import classnames from 'classnames';
import { useRecoilValue, useRecoilState } from 'recoil';
import { collapsedState } from '@/store/collapsed.store';
import { meState } from '@/store/me.store';

interface Props {
  name: string;
  menuItems: MenuProps['items'];
  defaultOpenKeys: string[];
  defaultSelectedKeys: string[];
  pages: PageView[];
  selectedKey: string[];
  salesTeamGroup: MeQuery['me']['defaultAvailableSalesTeamGroup'][];
  defaultSalesGroup: MeQuery['me']['defaultAvailableSalesTeamGroup'];
  onProfileItemClick: (key: string) => void;
  onLanguageChange: (key: string) => void;
  onMenuItemClick: (key: string) => void;
  onPageChange: (key: string) => void;
  onPageRemove: (key: string) => void;
}

export const MultiTabsLayout: React.FC<Props> = (props) => {
  const {
    name,
    menuItems,
    defaultOpenKeys,
    defaultSelectedKeys,
    pages,
    selectedKey,
    salesTeamGroup,
    defaultSalesGroup,
    onProfileItemClick,
    onLanguageChange,
    onMenuItemClick,
    onPageRemove,
    onPageChange,
  } = props;
  const [collapsed, setCollapsed] = useRecoilState(collapsedState);
  const [openKeys, setOpenKeys] = useState<string[]>([]);
  const [notificationData, setNotificationData] =
    useState<notificationType[]>();

  const me = useRecoilValue(meState);

  const { t } = useTranslation();
  const { handleOpenPage } = usePageView();

  const { data } = useNotificationSubscriptionSubscription();

  const [NotificationsQuery] = useNotificationsLazyQuery({
    variables: { filters: {} },
  });
  const [ReadNotificationsMutation] = useReadNotificationsMutation();

  const unRead = notificationData?.filter(
    (el: { isRead: boolean }) => !el.isRead,
  ).length;

  const fetchNotifications = async (userId?: string, isRead?: boolean) => {
    if (!userId) return;

    try {
      const { data } = await NotificationsQuery({
        variables: { filters: { userId, isRead } },
      });

      setNotificationData(data?.notifications.rows);
    } catch (error) {
      console.log(error);
    }
  };

  const handleNotificationUpdateReads = async (id: string[]) => {
    try {
      const { data } = await ReadNotificationsMutation({
        variables: { ids: id },
      });
      if (!data) return;
      const notificationsMap = data?.readNotifications.map(
        (el: { id: string }) => el.id,
      );

      setNotificationData((pre) => {
        if (pre)
          return pre?.map((el: notificationType) =>
            notificationsMap.includes(el.id) ? { ...el, isRead: true } : el,
          );
      });
    } catch (error) {
      console.log(error);
    }
  };

  const toggleCollapsed = () => {
    if (!collapsed) {
      setOpenKeys([]);
    }
    if (collapsed) {
      setTimeout(() => {
        setOpenKeys(defaultOpenKeys);
      }, 300);
    }
    setCollapsed(!collapsed);
  };

  const handleRegionChange = () => {};

  useEffect(() => {
    if (!data?.notificationSubscription) return;
    setNotificationData((pre) => {
      if (pre) return [data?.notificationSubscription, ...pre];
      return [data?.notificationSubscription];
    });
  }, [data]);

  useEffect(() => {
    setOpenKeys(defaultOpenKeys);
    fetchNotifications(me?.id, false);
  }, []);

  return (
    <Layout className={styles['root']}>
      <Layout.Sider
        style={{ position: 'fixed', background: '#001529cc' }}
        className={`w-[200px] h-screen fixed left-0 top-0 min-h-full z-50  ease-in duration-300`}
        collapsed={collapsed}
      >
        <div className={`flex flex-col z-20`}>
          {
            <div
              className={`overflow-hidden py-2 ease-in duration-300 flex items-center justify-center cursor-pointer text-white ${
                collapsed ? ' w-[80px] ' : 'w-[200px] z-20  '
              }`}
              onClick={() => {
                handleOpenPage({ path: '/home' });
              }}
            >
              <img src={Logo} height={35} width={35} />
              <span
                className={` text-lg font-bold  font-sans overflow-hidden duration-300 truncate ${
                  collapsed ? ' w-0 ' : 'w-[120px] ml-2  '
                }`}
              >
                CLINICO ERP
              </span>
            </div>
          }
          <Menu
            style={{ background: 'none' }}
            className={`side-menu overflow-y-auto text-[#fff] ${
              collapsed ? 'h-[calc(100vh_-_50px)]' : 'h-[calc(100vh_-_80px)]'
            }  `}
            mode="inline"
            items={menuItems}
            openKeys={openKeys}
            defaultSelectedKeys={defaultSelectedKeys}
            selectedKeys={selectedKey}
            onClick={(info) => onMenuItemClick(info.key)}
            onOpenChange={(key) => {
              setOpenKeys(key);
            }}
          />
        </div>
      </Layout.Sider>
      <Layout
        className={`mb-3 ${
          collapsed ? 'ml-[80px]' : 'ml-[200px]'
        } ease-in duration-300`}
      >
        <Layout.Header
          style={{ background: '#f5f5f5', paddingInline: '1rem' }}
          className={`h-[52px]  pl-4 pr-4 shadow ease-in duration-300 fixed right-0 z-50 ${
            collapsed ? 'left-[80px]' : 'left-[200px] header'
          }`}
        >
          <Row justify="space-between">
            <Col>
              {React.createElement(
                collapsed ? AiOutlineMenuUnfold : AiOutlineMenuFold,
                {
                  className: styles['trigger'],
                  onClick: () => toggleCollapsed(),
                },
              )}
            </Col>
            <Col>
              <NotificationMenu
                notifications={notificationData}
                unRead={unRead}
                UpdateReads={handleNotificationUpdateReads}
              />

              <Divider
                type="vertical"
                className={`${styles['divider']} ml-5`}
              />
              <SalesGroupDropdown
                salesTeamGroup={salesTeamGroup}
                defaultSalesGroup={defaultSalesGroup}
              />
              <Divider
                type="vertical"
                className={`${styles['divider']} ml-5`}
              />
              <ProfileDropdown name={name} onItemClick={onProfileItemClick} />
              <Divider type="vertical" className={styles['divider']} />
              <LanguageDropdown onLanguageChange={onLanguageChange} />
            </Col>
          </Row>
        </Layout.Header>
        <Layout.Content className={styles['content']}>
          <input
            type="checkbox"
            id={'page-tabs-trigger'}
            checked={collapsed}
            onChange={() => {}}
          />
          <PageViewTabs
            pages={pages}
            onRemove={onPageRemove}
            onChange={onPageChange}
          />
        </Layout.Content>
        <Layout.Footer
          className={classnames(styles['footer'], {
            ['w-[calc(100vw_-_80px)]']: collapsed,
            ['w-[calc(100vw_-_200px)]']: !collapsed,
          })}
        >
          {t('current system version')}: {configs.version}
          <Divider type="vertical" className={styles['divider']} />
          {t('system suggestion')}
        </Layout.Footer>
      </Layout>
    </Layout>
  );
};
