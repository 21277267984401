import common from './common.json';
import serviceOrder from './serviceOrder.json';
import workDiary from './workDiary.json';
import warrantyContract from './warrantyContract.json';
import customer from './customer.json';
import contactPerson from './contactPerson.json';
import fixedAsset from './fixedAsset.json';
import quotation from './quotation.json';
import lease from './lease.json';
import product from './product.json';
import promotion from './promotion.json';
import business from './business.json';
import visit from './visit.json';
import competitor from './competitor.json';
import permissions from './permissions.json';
import salesTeam from './salesTeam.json';
import analysis from './analysis.json';
import pmTeam from './pmTeam.json';
import achievement from './achievement.json';
import material from './material.json';
import performance from './performance.json';
import dashboard from './dashboard.json';
import inventory from './inventory.json';

export const zhCN = {
  translation: common,
  serviceOrder,
  workDiary,
  warrantyContract,
  customer,
  contactPerson,
  fixedAsset,
  quotation,
  lease,
  product,
  promotion,
  business,
  visit,
  competitor,
  permissions,
  salesTeam,
  pmTeam,
  analysis,
  achievement,
  material,
  performance,
  dashboard,
  inventory,
};
