import { atom } from 'recoil';

import { MeQuery } from '@/graphql/types/generate';

export const salesGroupState = atom<
  undefined | MeQuery['me']['defaultAvailableSalesTeamGroup']
>({
  key: 'salesGroupState',
  default: undefined,
});
