import React from 'react';
import { Button, Result } from 'antd';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

interface Props {}

const NotFoundPage: React.FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  return (
    <Result
      className="w-screen h-screen bg-slate-100"
      status="404"
      title="404"
      subTitle={t('404 not found')}
      extra={
        <Button className="btn bg-[#FD9B44]" onClick={() => navigate('/')}>
          {t('return home page')}
        </Button>
      }
    />
  );
};

export default NotFoundPage;
