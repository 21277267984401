import React from 'react';
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from 'react-router-dom';

import {
  HomeOutlined,
  PlusOutlined,
  SearchOutlined,
  TeamOutlined,
  ScheduleOutlined,
  UserOutlined,
  QuestionOutlined,
  LockOutlined,
  CheckSquareOutlined,
  ClusterOutlined,
  StockOutlined,
  AccountBookOutlined,
  EnvironmentOutlined,
  SnippetsOutlined,
  RocketOutlined,
} from '@ant-design/icons';

import {
  DASHBOARD_PRODUCTS_PERMISSIONS,
  MATERIAL_PERMISSIONS,
  ROUTE_PATH,
} from '@/consts';
import Container from './container';
import AuthGuard from './modules/common/components/authGuard';
import PageLoading from './modules/common/components/loading/pageLoading';
import NotFoundPage from './modules/exception/404.page';
import {
  CUSTOMER_PERMISSIONS,
  CONTACT_PEOPLE_PERMISSIONS,
  QUOTATION_PERMISSIONS,
  WARRANTY_PERMISSIONS,
  SERVICE_ORDER_PERMISSIONS,
  WORK_DIARY_PERMISSIONS,
  PRODUCT_PERMISSIONS,
  BUSINESS_PERMISSIONS,
  VISIT_PERMISSIONS,
  FIXED_ASSET_PERMISSIONS,
  PROVIDER_PERMISSIONS,
  USER_ROLE_PERMISSIONS,
  SALES_TEAM_PERMISSIONS,
  SALES_TEAM_UNIT_PERMISSIONS,
  ANALYSIS_PERMISSIONS,
  PM_TEAM_PERMISSIONS,
  BUSINESS_PRODUCT_PERMISSIONS,
  ACHIEVEMENT_PERMISSIONS,
  SALES_PERFORMANCE_PERMISSIONS,
  SALES_TEAM_PERFORMANCE_PERMISSIONS,
  PRODUCT_SALES_PERFORMANCE_PERMISSIONS,
  PRODUCT_SALE_TEAM_PERFORMANCE_PERMISSIONS,
  SALES_FORECAST_PERMISSIONS,
  SALES_PRODUCTS_FORECAST_PERMISSIONS,
  MARKETING_TARGET_PERMISSIONS,
  INVENTORY_PERMISSIONS,
} from '@/consts';

export function lazyWithSuspense<T extends React.ComponentType<any>>(
  factory: () => Promise<{ default: T }>,
) {
  const Page: any = React.lazy<T>(factory);
  const Wrapped: React.FC<React.ComponentProps<T>> = (props) => (
    <React.Suspense fallback={<PageLoading />}>
      <Page {...props} />
    </React.Suspense>
  );
  return Wrapped;
}

const LoginPage = lazyWithSuspense(
  () => import('./modules/common/pages/login.page'),
);
const MapPage = lazyWithSuspense(
  () => import('./modules/common/pages/map.page'),
);
const MainRouter: React.FC = () => {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Navigate to={ROUTE_PATH.HOME} replace />} />
        <Route path={ROUTE_PATH.LOGIN} element={<LoginPage />} />
        <Route path={ROUTE_PATH.MAP} element={<MapPage />} />
        <Route element={<AuthGuard />}>
          <Route element={<Container />}>
            {Object.keys(routers).map((path: TabRouterKeys) => (
              <Route
                key={path}
                path={path as string}
                element={React.createElement(routers[path].component)}
              />
            ))}
          </Route>
        </Route>
        <Route path="*" element={<NotFoundPage />} />
      </Routes>
    </Router>
  );
};

export default MainRouter;

export const routers = {
  [`${ROUTE_PATH.HOME}`]: {
    auth: [],
    component: lazyWithSuspense(
      () => import('@/modules/common/pages/home/home.page'),
    ),
    title: 'home',
    icon: <HomeOutlined />,
  },

  [`${ROUTE_PATH.NOTIFICATION}`]: {
    auth: [],
    component: lazyWithSuspense(
      () => import('@/modules/notifications/page/detail.page'),
    ),
    title: 'Notification',
    icon: null,
  },

  [`${ROUTE_PATH.NOT_FOUND}`]: {
    auth: [],
    component: lazyWithSuspense(() => import('@/modules/exception/404.page')),
    title: '404',
    icon: <QuestionOutlined />,
  },

  [`${ROUTE_PATH.FORBIDDEN}`]: {
    auth: [],
    component: lazyWithSuspense(() => import('@/modules/exception/403.page')),
    title: 'not authorized',
    icon: <LockOutlined />,
  },

  // 客戶
  [`${ROUTE_PATH.CUSTOMER.SEARCH}`]: {
    auth: [CUSTOMER_PERMISSIONS.READ],
    component: lazyWithSuspense(
      () => import('@/modules/customer/pages/search.page'),
    ),
    title: 'customer:search',
    icon: <SearchOutlined />,
  },
  [`${ROUTE_PATH.CUSTOMER.DETAIL}`]: {
    auth: [CUSTOMER_PERMISSIONS.READ],
    component: lazyWithSuspense(
      () => import('@/modules/customer/pages/detail.page'),
    ),
    title: 'customer',
    icon: <TeamOutlined />,
  },
  [`${ROUTE_PATH.CUSTOMER.CREATE}`]: {
    auth: [CUSTOMER_PERMISSIONS.CREATE],
    component: lazyWithSuspense(
      () => import('@/modules/customer/pages/create.page'),
    ),
    title: 'customer:create',
    icon: <PlusOutlined />,
  },
  [`${ROUTE_PATH.CUSTOMER.CONTACT_PERSON.SEARCH}`]: {
    auth: [CONTACT_PEOPLE_PERMISSIONS.READ],
    component: lazyWithSuspense(
      () => import('@/modules/customer/pages/contactPerson/search.page'),
    ),
    title: 'contactPerson:search',
    icon: <SearchOutlined />,
  },
  [`${ROUTE_PATH.CUSTOMER.CONTACT_PERSON.CREATE}`]: {
    auth: [CONTACT_PEOPLE_PERMISSIONS.CREATE],
    component: lazyWithSuspense(
      () => import('@/modules/customer/pages/contactPerson/create.page'),
    ),
    title: 'contactPerson:create',
    icon: <PlusOutlined />,
  },
  [`${ROUTE_PATH.CUSTOMER.COMPETITOR.SEARCH}`]: {
    auth: [PROVIDER_PERMISSIONS.READ],
    component: lazyWithSuspense(
      () => import('@/modules/competitors/pages/search.page'),
    ),
    title: 'competitor:search',
    icon: <SearchOutlined />,
  },
  [`${ROUTE_PATH.CUSTOMER.COMPETITOR.CREATE}`]: {
    auth: [PROVIDER_PERMISSIONS.CREATE],
    component: lazyWithSuspense(
      () => import('@/modules/competitors/pages/create.page'),
    ),
    title: 'competitor:create',
    icon: <PlusOutlined />,
  },
  [`${ROUTE_PATH.CUSTOMER.COMPETITOR.DETAIL}`]: {
    auth: [PROVIDER_PERMISSIONS.READ],
    component: lazyWithSuspense(
      () => import('@/modules/competitors/pages/detail.page'),
    ),
    title: 'competitor:detail',
    icon: <PlusOutlined />,
  },
  [`${ROUTE_PATH.CUSTOMER.CONTACT_PERSON.DETAIL}`]: {
    auth: [CONTACT_PEOPLE_PERMISSIONS.READ],
    component: lazyWithSuspense(
      () => import('@/modules/customer/pages/contactPerson/detail.page'),
    ),
    title: 'contactPerson:detail',
    icon: <PlusOutlined />,
  },
  [`${ROUTE_PATH.CUSTOMER.BUSINESS.SEARCH}`]: {
    auth: [BUSINESS_PERMISSIONS.READ],
    component: lazyWithSuspense(
      () => import('@/modules/customer/pages/business/search.page'),
    ),
    title: 'business:search',
    icon: <SearchOutlined />,
  },
  [`${ROUTE_PATH.CUSTOMER.BUSINESS.CREATE}`]: {
    auth: [BUSINESS_PERMISSIONS.CREATE],
    component: lazyWithSuspense(
      () => import('@/modules/customer/pages/business/create.page'),
    ),
    title: 'business:create',
    icon: <PlusOutlined />,
  },
  [`${ROUTE_PATH.CUSTOMER.BUSINESS.DETAIL}`]: {
    auth: [BUSINESS_PERMISSIONS.READ],
    component: lazyWithSuspense(
      () => import('@/modules/customer/pages/business/detail.page'),
    ),
    title: 'business:detail',
    icon: <PlusOutlined />,
  },
  [`${ROUTE_PATH.CUSTOMER.VISIT.SEARCH}`]: {
    auth: [VISIT_PERMISSIONS.READ],
    component: lazyWithSuspense(
      () => import('@/modules/customer/pages/visits/search.page'),
    ),
    title: 'visit:search',
    icon: <SearchOutlined />,
  },
  [`${ROUTE_PATH.CUSTOMER.VISIT.CREATE}`]: {
    auth: [VISIT_PERMISSIONS.CREATE],
    component: lazyWithSuspense(
      () => import('@/modules/customer/pages/visits/create.page'),
    ),
    title: 'visit:create',
    icon: <PlusOutlined />,
  },
  [`${ROUTE_PATH.CUSTOMER.VISIT.DETAIL}`]: {
    auth: [VISIT_PERMISSIONS.READ],
    component: lazyWithSuspense(
      () => import('@/modules/customer/pages/visits/detail.page'),
    ),
    title: 'visit:detail',
    icon: <PlusOutlined />,
  },
  [`${ROUTE_PATH.CUSTOMER.VISIT.CALENDAR}`]: {
    auth: [VISIT_PERMISSIONS.READ],
    component: lazyWithSuspense(
      () => import('@/modules/customer/pages/visits/calendar.page'),
    ),
    title: 'visit:calendar',
    icon: <PlusOutlined />,
  },
  [`${ROUTE_PATH.CUSTOMER.VISIT.CHECKIN}`]: {
    auth: [VISIT_PERMISSIONS.READ],
    component: lazyWithSuspense(
      () => import('@/modules/customer/pages/visits/checkIn.page'),
    ),
    title: 'visit:check in record',
    icon: <EnvironmentOutlined />,
  },

  // 商機商品
  [`${ROUTE_PATH.BUSINESS_PRODUCT.SEARCH}`]: {
    auth: [BUSINESS_PRODUCT_PERMISSIONS.READ],
    component: lazyWithSuspense(
      () => import('@/modules/businessProduct/pages/search.page'),
    ),
    title: 'business:product search',
    icon: <SearchOutlined />,
  },
  [`${ROUTE_PATH.BUSINESS_PRODUCT.CREATE}`]: {
    auth: [BUSINESS_PRODUCT_PERMISSIONS.CREATE],
    component: lazyWithSuspense(
      () => import('@/modules/businessProduct/pages/create.page'),
    ),
    title: 'business:create product',
    icon: <PlusOutlined />,
  },
  [`${ROUTE_PATH.BUSINESS_PRODUCT.DETAIL}`]: {
    auth: [BUSINESS_PRODUCT_PERMISSIONS.READ],
    component: lazyWithSuspense(
      () => import('@/modules/businessProduct/pages/edit.page'),
    ),
    title: 'business:product',
    icon: <AccountBookOutlined />,
  },

  // 分析
  [`${ROUTE_PATH.ANALYSIS.BUSINESS}`]: {
    auth: [ANALYSIS_PERMISSIONS.BUSINESS],
    component: lazyWithSuspense(
      () => import('@/modules/analysis/business/pages/analysis.page'),
    ),
    title: 'analysis:business',
    icon: <StockOutlined />,
  },

  // 業績
  [`${ROUTE_PATH.ACHIEVEMENT.NAV_ORDER.SEARCH}`]: {
    auth: [ACHIEVEMENT_PERMISSIONS.NAV_ORDER],
    component: lazyWithSuspense(
      () => import('@/modules/achievement/navOrder/pages/search.page'),
    ),
    title: 'achievement:nav order search',
    icon: <SearchOutlined />,
  },
  [`${ROUTE_PATH.ACHIEVEMENT.NAV_ORDER.DETAIL}`]: {
    auth: [ACHIEVEMENT_PERMISSIONS.NAV_ORDER],
    component: lazyWithSuspense(
      () => import('@/modules/achievement/navOrder/pages/detail.page'),
    ),
    title: 'achievement:nav order',
    icon: <SnippetsOutlined />,
  },
  [`${ROUTE_PATH.ACHIEVEMENT.NAV_SALES_INVOICE.SEARCH}`]: {
    auth: [ACHIEVEMENT_PERMISSIONS.NAV_SALES_INVOICE],
    component: lazyWithSuspense(
      () => import('@/modules/achievement/navSalesInvoices/pages/search.page'),
    ),
    title: 'achievement:sales order search',
    icon: <SearchOutlined />,
  },
  [`${ROUTE_PATH.ACHIEVEMENT.NAV_SALES_INVOICE.DETAIL}`]: {
    auth: [ACHIEVEMENT_PERMISSIONS.NAV_SALES_INVOICE],
    component: lazyWithSuspense(
      () => import('@/modules/achievement/navSalesInvoices/pages/detail.page'),
    ),
    title: 'achievement:sales order',
    icon: <SnippetsOutlined />,
  },
  // 儀表板
  [`${ROUTE_PATH.ACHIEVEMENT.DASHBOARD_PRODUCTS.INDEX}`]: {
    auth: [DASHBOARD_PRODUCTS_PERMISSIONS.READ],
    component: lazyWithSuspense(
      () => import('@/modules/achievement/dashboardProducts/pages/search.page'),
    ),
    title: 'dashboard:title',
    icon: <RocketOutlined />,
  },
  [`${ROUTE_PATH.ACHIEVEMENT.MARKETING_TARGET.INDEX}`]: {
    auth: [MARKETING_TARGET_PERMISSIONS.READ],
    component: lazyWithSuspense(
      () => import('@/modules/achievement/marketingTarget/pages/search.page'),
    ),
    title: 'dashboard:marketing title',
    icon: <RocketOutlined />,
  },
  [`${ROUTE_PATH.ACHIEVEMENT.DASHBOARD_TARGET.INDEX}`]: {
    auth: [],
    component: lazyWithSuspense(
      () => import('@/modules/achievement/dashboardTarget/pages/search.page'),
    ),
    title: 'dashboard:target title',
    icon: <RocketOutlined />,
  },

  //服務單
  [`${ROUTE_PATH.SERVICE_ORDER.WORK_ORDER_LIST}`]: {
    auth: [WORK_DIARY_PERMISSIONS.READ],
    component: lazyWithSuspense(
      () =>
        import('@/modules/eyeServiceOrderWorkDiary/pages/workCalendar.page'),
    ),
    title: 'work order list',
    icon: <ScheduleOutlined />,
  },
  [`${ROUTE_PATH.SERVICE_ORDER.WORK_DIARY_SEARCH}`]: {
    auth: [WORK_DIARY_PERMISSIONS.READ],
    component: lazyWithSuspense(
      () => import('@/modules/eyeServiceOrderWorkDiary/pages/search.page'),
    ),
    title: 'workDiary:search',
    icon: <SearchOutlined />,
  },
  [`${ROUTE_PATH.SERVICE_ORDER.CREATE_WORK_DIARY}`]: {
    auth: [WORK_DIARY_PERMISSIONS.CREATE],
    component: lazyWithSuspense(
      () => import('@/modules/eyeServiceOrderWorkDiary/pages/create.page'),
    ),
    title: 'workDiary:add',
    icon: <PlusOutlined />,
  },
  [`${ROUTE_PATH.SERVICE_ORDER.WORK_DIARY_DETAIL}`]: {
    auth: [WORK_DIARY_PERMISSIONS.READ],
    component: lazyWithSuspense(
      () => import('@/modules/eyeServiceOrderWorkDiary/pages/detail.page'),
    ),
    title: 'work diary',
  },
  [`${ROUTE_PATH.SERVICE_ORDER.WORK_DIARY_CHECK_IN}`]: {
    auth: [WORK_DIARY_PERMISSIONS.READ],
    component: lazyWithSuspense(
      () => import('@/modules/eyeServiceOrderWorkDiary/pages/checkIn.page'),
    ),
    title: 'visit:check in record',
    icon: <EnvironmentOutlined />,
  },
  [`${ROUTE_PATH.SERVICE_ORDER.SEARCH}`]: {
    auth: [SERVICE_ORDER_PERMISSIONS.READ],
    component: lazyWithSuspense(
      () => import('@/modules/eyeServiceOrder/pages/search.page'),
    ),
    title: 'serviceOrder:search',
    icon: <SearchOutlined />,
  },
  // [`${ROUTE_PATH.SERVICE_ORDER.WAIT_APPROVE}`]: {
  //   auth: [SERVICE_ORDER_PERMISSIONS.APPROVE_USER],
  //   component: lazyWithSuspense(
  //     () => import('@/modules/eyeServiceOrder/pages/waitApprove.page'),
  //   ),
  //   title: 'serviceOrder:wait approve',
  //   icon: <CheckSquareOutlined />,
  // },
  [`${ROUTE_PATH.SERVICE_ORDER.WAIT_ASSIGN}`]: {
    auth: [SERVICE_ORDER_PERMISSIONS.APPROVE_USER],
    component: lazyWithSuspense(
      () => import('@/modules/eyeServiceOrder/pages/waitAssign.page'),
    ),
    title: 'serviceOrder:wait assign',
    icon: <CheckSquareOutlined />,
  },
  [`${ROUTE_PATH.SERVICE_ORDER.ACCPETANCE}`]: {
    auth: [SERVICE_ORDER_PERMISSIONS.APPROVE_USER],
    component: lazyWithSuspense(
      () => import('@/modules/eyeServiceOrder/pages/waitAcceptance.page'),
    ),
    title: 'serviceOrder:wait acceptance',
    icon: <CheckSquareOutlined />,
  },
  [`${ROUTE_PATH.SERVICE_ORDER.DETAIL}`]: {
    auth: [SERVICE_ORDER_PERMISSIONS.READ],
    component: lazyWithSuspense(
      () => import('@/modules/eyeServiceOrder/pages/detail.page'),
    ),
    title: 'service order',
  },
  [`${ROUTE_PATH.SERVICE_ORDER.CREATE}`]: {
    auth: [SERVICE_ORDER_PERMISSIONS.CREATE],
    component: lazyWithSuspense(
      () => import('@/modules/eyeServiceOrder/pages/create.page'),
    ),
    title: 'serviceOrder:create',
    icon: <PlusOutlined />,
  },

  //保固合約
  [`${ROUTE_PATH.WARRANTY_CONTRACT.SEARCH}`]: {
    auth: [WARRANTY_PERMISSIONS.READ],
    component: lazyWithSuspense(
      () => import('@/modules/eyeWarrantyContracts/pages/search.page'),
    ),
    title: 'warrantyContract:search',
    icon: <SearchOutlined />,
  },
  [`${ROUTE_PATH.WARRANTY_CONTRACT.DETAIL}`]: {
    auth: [WARRANTY_PERMISSIONS.READ],
    component: lazyWithSuspense(
      () => import('@/modules/eyeWarrantyContracts/pages/detail.page'),
    ),
    title: 'warranty contract',
  },
  [`${ROUTE_PATH.WARRANTY_CONTRACT.CREATE}`]: {
    auth: [WARRANTY_PERMISSIONS.CREATE],
    component: lazyWithSuspense(
      () => import('@/modules/eyeWarrantyContracts/pages/create.page'),
    ),
    title: 'warrantyContract:create',
    icon: <PlusOutlined />,
  },

  //報價單
  [`${ROUTE_PATH.QUOTATION.INDEX}`]: {
    auth: [QUOTATION_PERMISSIONS.READ],
    component: lazyWithSuspense(
      () => import('@/modules/eyeQuotationOrder/pages/eyeQuotationOrder.page'),
    ),
    title: 'quotation',
    icon: null,
  },
  [`${ROUTE_PATH.QUOTATION.SEARCH}`]: {
    auth: [QUOTATION_PERMISSIONS.READ],
    component: lazyWithSuspense(
      () => import('@/modules/eyeQuotationOrder/pages/search.page'),
    ),
    title: 'quotation:search',
    icon: <SearchOutlined />,
  },
  [`${ROUTE_PATH.QUOTATION.CREATE}`]: {
    auth: [QUOTATION_PERMISSIONS.CREATE],
    component: lazyWithSuspense(
      () => import('@/modules/eyeQuotationOrder/pages/create.page'),
    ),
    title: 'quotation:create',
    icon: <PlusOutlined />,
  },

  //固定資產
  [`${ROUTE_PATH.FIXED_ASSET.SEARCH}`]: {
    auth: [FIXED_ASSET_PERMISSIONS.READ],
    component: lazyWithSuspense(
      () => import('@/modules/eyeFixedAsset/pages/search.page'),
    ),
    title: 'fixedAsset:search',
    icon: <SearchOutlined />,
  },
  [`${ROUTE_PATH.FIXED_ASSET.CREATE}`]: {
    auth: [FIXED_ASSET_PERMISSIONS.CREATE],
    component: lazyWithSuspense(
      () => import('@/modules/eyeFixedAsset/pages/create.page'),
    ),
    title: 'fixedAsset:create',
    icon: <PlusOutlined />,
  },
  [`${ROUTE_PATH.FIXED_ASSET.DETAIL}`]: {
    auth: [FIXED_ASSET_PERMISSIONS.READ],
    component: lazyWithSuspense(
      () => import('@/modules/eyeFixedAsset/pages/detail.page'),
    ),
    title: 'fixed asset',
    icon: null,
  },
  [`${ROUTE_PATH.FIXED_ASSET.LEND_LIST}`]: {
    auth: [FIXED_ASSET_PERMISSIONS.READ],
    component: lazyWithSuspense(
      () =>
        import(
          '@/modules/eyeFixedAssetRentalRecord/pages/fixAssetCalendar.page'
        ),
    ),
    title: 'lend list',
    icon: <SearchOutlined />,
  },

  // 商品
  [`${ROUTE_PATH.PRODUCT_EYE.INDEX}`]: {
    auth: [PRODUCT_PERMISSIONS.READ],
    component: lazyWithSuspense(
      () => import('@/modules/eyeProduct/page/eyeProduct.page'),
    ),
    title: 'product',
    icon: null,
  },
  [`${ROUTE_PATH.PRODUCT_EYE.CREATE}`]: {
    auth: [PRODUCT_PERMISSIONS.CREATE],
    component: lazyWithSuspense(
      () => import('@/modules/eyeProduct/page/create.page'),
    ),
    title: 'product:create',
    icon: <PlusOutlined />,
  },
  [`${ROUTE_PATH.PRODUCT_EYE.SEARCH}`]: {
    auth: [PRODUCT_PERMISSIONS.READ],
    component: lazyWithSuspense(
      () => import('@/modules/eyeProduct/page/search.page'),
    ),
    title: 'product:search',
    icon: <SearchOutlined />,
  },

  //商品優惠
  [`${ROUTE_PATH.PROMOTION_EYE.INDEX}`]: {
    auth: [PRODUCT_PERMISSIONS.READ],
    component: lazyWithSuspense(
      () => import('@/modules/eyePromotion/page/eyePromotion.page'),
    ),
    title: 'promotion',
    icon: null,
  },
  [`${ROUTE_PATH.PROMOTION_EYE.CREATE}`]: {
    auth: [PRODUCT_PERMISSIONS.CREATE],
    component: lazyWithSuspense(
      () => import('@/modules/eyePromotion/page/create.page'),
    ),
    title: 'promotion:create',
    icon: <PlusOutlined />,
  },
  [`${ROUTE_PATH.PROMOTION_EYE.SEARCH}`]: {
    auth: [PRODUCT_PERMISSIONS.READ],
    component: lazyWithSuspense(
      () => import('@/modules/eyePromotion/page/search.page'),
    ),
    title: 'promotion:search',
    icon: <SearchOutlined />,
  },

  // 商品料號
  [`${ROUTE_PATH.MATERIAL.SEARCH}`]: {
    auth: [MATERIAL_PERMISSIONS.READ],
    component: lazyWithSuspense(
      () => import('@/modules/material/page/search.page'),
    ),
    title: 'material:search',
    icon: <SearchOutlined />,
  },
  [`${ROUTE_PATH.MATERIAL.INDEX}`]: {
    auth: [MATERIAL_PERMISSIONS.UPDATE],
    component: lazyWithSuspense(
      () => import('@/modules/material/page/detail.page'),
    ),
    title: 'material code',
    icon: null,
  },
  [`${ROUTE_PATH.MATERIAL.CREATE}`]: {
    auth: [MATERIAL_PERMISSIONS.CREATE],
    component: lazyWithSuspense(
      () => import('@/modules/material/page/create.page'),
    ),
    title: 'material:create',
    icon: <PlusOutlined />,
  },

  // 業務團隊
  [`${ROUTE_PATH.SALES_TEAM.SEARCH}`]: {
    auth: [SALES_TEAM_PERMISSIONS.READ],
    component: lazyWithSuspense(
      () => import('@/modules/salesTeam/pages/search.page'),
    ),
    title: 'salesTeam:search',
    icon: <SearchOutlined />,
  },
  [`${ROUTE_PATH.SALES_TEAM.CREATE}`]: {
    auth: [SALES_TEAM_PERMISSIONS.CREATE],
    component: lazyWithSuspense(
      () => import('@/modules/salesTeam/pages/create.page'),
    ),
    title: 'salesTeam:create',
    icon: <PlusOutlined />,
  },
  [`${ROUTE_PATH.SALES_TEAM.DETAIL}`]: {
    auth: [SALES_TEAM_PERMISSIONS.READ],
    component: lazyWithSuspense(
      () => import('@/modules/salesTeam/pages/edit.page'),
    ),
    title: 'salesTeam:team',
    icon: <TeamOutlined />,
  },
  [`${ROUTE_PATH.SALES_TEAM.GROUP}`]: {
    auth: [SALES_TEAM_PERMISSIONS.READ],
    component: lazyWithSuspense(
      () => import('@/modules/salesTeam/pages/group.page'),
    ),
    title: 'salesTeam:group edit',
    icon: <ClusterOutlined />,
  },
  [`${ROUTE_PATH.SALES_TEAM.UNIT_SEARCH}`]: {
    auth: [SALES_TEAM_UNIT_PERMISSIONS.READ],
    component: lazyWithSuspense(
      () => import('@/modules/salesTeam/pages/unit/search.page'),
    ),
    title: 'salesTeam:search unit',
    icon: <SearchOutlined />,
  },
  [`${ROUTE_PATH.SALES_TEAM.UNIT_CREATE}`]: {
    auth: [SALES_TEAM_UNIT_PERMISSIONS.CREATE],
    component: lazyWithSuspense(
      () => import('@/modules/salesTeam/pages/unit/create.page'),
    ),
    title: 'salesTeam:create unit',
    icon: <PlusOutlined />,
  },
  [`${ROUTE_PATH.SALES_TEAM.UNIT_DETAIL}`]: {
    auth: [SALES_TEAM_UNIT_PERMISSIONS.READ],
    component: lazyWithSuspense(
      () => import('@/modules/salesTeam/pages/unit/detail.page'),
    ),
    title: 'salesTeam:sales team unit',
    icon: <TeamOutlined />,
  },

  // PM團隊
  [`${ROUTE_PATH.PM_TEAM.SEARCH}`]: {
    auth: [PM_TEAM_PERMISSIONS.READ],
    component: lazyWithSuspense(
      () => import('@/modules/pmTeam/pages/search.page'),
    ),
    title: 'pmTeam:search',
    icon: <SearchOutlined />,
  },
  [`${ROUTE_PATH.PM_TEAM.CREATE}`]: {
    auth: [PM_TEAM_PERMISSIONS.CREATE],
    component: lazyWithSuspense(
      () => import('@/modules/pmTeam/pages/create.page'),
    ),
    title: 'pmTeam:create',
    icon: <PlusOutlined />,
  },
  [`${ROUTE_PATH.PM_TEAM.DETAIL}`]: {
    auth: [PM_TEAM_PERMISSIONS.READ],
    component: lazyWithSuspense(
      () => import('@/modules/pmTeam/pages/edit.page'),
    ),
    title: 'pmTeam:team',
    icon: <TeamOutlined />,
  },

  //權限
  [`${ROUTE_PATH.PERMISSIONS.USER}`]: {
    auth: [USER_ROLE_PERMISSIONS.READ],
    component: lazyWithSuspense(
      () => import('@/modules/permissions/page/user.page'),
    ),
    title: 'permissions:user management',
    icon: <UserOutlined />,
  },
  [`${ROUTE_PATH.PERMISSIONS.ROLE.SEARCH}`]: {
    auth: [USER_ROLE_PERMISSIONS.READ],
    component: lazyWithSuspense(
      () => import('@/modules/permissions/role/pages/search.page'),
    ),
    title: 'permissions:role management',
    icon: <SearchOutlined />,
  },
  [`${ROUTE_PATH.PERMISSIONS.ROLE.CREATE}`]: {
    auth: [USER_ROLE_PERMISSIONS.CREATE],
    component: lazyWithSuspense(
      () => import('@/modules/permissions/role/pages/edit.page'),
    ),
    title: 'permissions:role management',
    icon: <PlusOutlined />,
  },
  [`${ROUTE_PATH.PERMISSIONS.ROLE.DETAIL}`]: {
    auth: [USER_ROLE_PERMISSIONS.READ],
    component: lazyWithSuspense(
      () => import('@/modules/permissions/role/pages/edit.page'),
    ),
    title: 'permissions:role management',
    icon: <UserOutlined />,
  },

  // 銷售業績
  [`${ROUTE_PATH.SALES_PERFORMANCE.FORECAST.SEARCH}`]: {
    auth: [SALES_FORECAST_PERMISSIONS.READ],
    component: lazyWithSuspense(
      () => import('@/modules/performance/pages/sales/salesForecast.page'),
    ),
    title: 'performance:sales forcast title',
    icon: <SearchOutlined />,
  },
  [`${ROUTE_PATH.SALES_PERFORMANCE.TARGET.SALES_SEARCH}`]: {
    auth: [SALES_PERFORMANCE_PERMISSIONS.READ],
    component: lazyWithSuspense(
      () => import('@/modules/performance/pages/sales/salesPerformance.page'),
    ),
    title: 'performance:sales performance title',
    icon: <SearchOutlined />,
  },
  [`${ROUTE_PATH.SALES_PERFORMANCE.TARGET.SALESTEAM_SEARCH}`]: {
    auth: [SALES_TEAM_PERFORMANCE_PERMISSIONS.READ],
    component: lazyWithSuspense(
      () =>
        import('@/modules/performance/pages/sales/salesTeamPerformance.page'),
    ),
    title: 'performance:sales performance team title',
    icon: <SearchOutlined />,
  },
  // 商品業績
  [`${ROUTE_PATH.PRODUCT_PERFORMANVE.FORECAST.SEARCH}`]: {
    auth: [SALES_PRODUCTS_FORECAST_PERMISSIONS.READ],
    component: lazyWithSuspense(
      () =>
        import('@/modules/performance/pages/products/productsForecast.page'),
    ),
    title: 'performance:products forcast title',
    icon: <SearchOutlined />,
  },
  [`${ROUTE_PATH.PRODUCT_PERFORMANVE.TARGET.SALES_SEARCH}`]: {
    auth: [PRODUCT_SALES_PERFORMANCE_PERMISSIONS.READ],
    component: lazyWithSuspense(
      () =>
        import('@/modules/performance/pages/products/salesPerformance.page'),
    ),
    title: 'performance:products performance title',
    icon: <SearchOutlined />,
  },
  [`${ROUTE_PATH.PRODUCT_PERFORMANVE.TARGET.SALESTEAM_SEARCH}`]: {
    auth: [PRODUCT_SALE_TEAM_PERFORMANCE_PERMISSIONS.READ],
    component: lazyWithSuspense(
      () =>
        import(
          '@/modules/performance/pages/products/salesTeamPerformance.page'
        ),
    ),
    title: 'performance:products performance team title',
    icon: <SearchOutlined />,
  },
  [`${ROUTE_PATH.INVENTORY.SEARCH}`]: {
    auth: [],
    component: lazyWithSuspense(
      () => import('@/modules/inventory/page/search.page'),
    ),
    title: 'inventory:title',
    icon: <SearchOutlined />,
  },
};

export type TabRouter = typeof routers;
export type TabRouterKeys = keyof TabRouter;
