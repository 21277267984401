import React from 'react';
import type { PropsWithChildren, ReactElement } from 'react';
import { DraggableTabs } from './draggable.tabs';

export type PageView = {
  key: string;
  title: ReactElement;
  path: string;
  targetId?: string | number;
  params?: string;
  content?: ReactElement;
  closable: boolean;
};

interface Props extends PropsWithChildren {
  activeKey?: string;
  pages: PageView[];
  onChange?: (key: string) => void;
  onRemove?: (key: string) => void;
}

export const PageViewTabs: React.FC<Props> = (props): ReactElement => {
  const { onChange, onRemove } = props;

  const handleEdit = (
    e: React.MouseEvent | React.KeyboardEvent | string,
    action: 'add' | 'remove',
  ) => {
    if (action == 'remove' && onRemove) {
      onRemove(e as string);
    }
  };

  return (
    <div className="page-tabs-box">
      <DraggableTabs
        hideAdd
        type="editable-card"
        onEdit={handleEdit}
        onChange={onChange}
        className="page-tabs"
      />
    </div>
  );
};
