import React, { useEffect, useState } from 'react';
import { Button, Tabs, TabsProps } from 'antd';
import type { DragEndEvent } from '@dnd-kit/core';
import { DndContext, PointerSensor, useSensor } from '@dnd-kit/core';
import {
  arrayMove,
  horizontalListSortingStrategy,
  SortableContext,
} from '@dnd-kit/sortable';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useRecoilState } from 'recoil';
import { pageViewsState } from '@/store/page.store';
import { DraggableTabNode } from './draggable.tabNode';
import { ROUTE_PATH } from '@/consts';
import HomePage from '../../pages/home/home.page';

interface Tab {
  key: string;
  label: React.ReactElement<any, string | React.JSXElementConstructor<any>>;
  children?: JSX.Element;
  closable: boolean;
}
interface Props extends TabsProps {}
export const DraggableTabs: React.FC<Props> = (props) => {
  const [pages, setPages] = useRecoilState(pageViewsState);
  const sensor = useSensor(PointerSensor, {
    activationConstraint: { distance: 10 },
  });
  const location = useLocation();
  const { t } = useTranslation();

  const [items, setItems] = useState<Tab[]>([
    {
      key: ROUTE_PATH.HOME,
      label: t('home'),
      children: <HomePage />,
      closable: false,
    },
  ]);

  useEffect(() => {
    const item = pages.map((page) => ({
      key: page.key,
      label: page.title,
      children: page.content,
      closable: page.closable,
    }));
    setItems(item);
  }, [pages]);

  const onDragEnd = ({ active, over }: DragEndEvent) => {
    if (items !== undefined && active.id !== over?.id) {
      setItems((prev) => {
        const activeIndex = prev!.findIndex((i) => i.key === active.id);
        const overIndex = prev!.findIndex((i) => i.key === over?.id);
        return arrayMove(prev!, activeIndex, overIndex);
      });
    }
  };

  const renderTabBar: TabsProps['renderTabBar'] = (
    tabBarProps,
    DefaultTabBar,
  ) => (
    <DndContext sensors={[sensor]} onDragEnd={onDragEnd}>
      <SortableContext
        items={items!.map((i) => i.key)}
        strategy={horizontalListSortingStrategy}
      >
        <DefaultTabBar {...tabBarProps}>
          {(node) => (
            <DraggableTabNode
              {...node.props}
              key={node.key}
              onActiveBarTransform={() => {}}
            >
              {node}
            </DraggableTabNode>
          )}
        </DefaultTabBar>
      </SortableContext>
    </DndContext>
  );

  const closeOthers = (key: string) => {
    const filterPage = pages.filter((p) => p.key === key || p.key === '/home');
    setPages(filterPage);
  };

  return (
    <Tabs
      {...props}
      items={items}
      activeKey={`${location.pathname}${location.search}`}
      tabBarExtraContent={
        pages.length > 1 && {
          right: (
            <Button
              type="primary"
              className="mr-1"
              danger
              onClick={() => {
                closeOthers(`${location.pathname}${location.search}`);
              }}
            >
              {t('close other tabs')}
            </Button>
          ),
        }
      }
      renderTabBar={renderTabBar}
    />
  );
};
