import { EnumSortDirection } from '@/graphql/types/generate';

export const ROUTE_PATH = {
  LOGIN: '/login',
  MAP: '/map',
  HOME: '/home',
  NOT_FOUND: '/not-found',
  FORBIDDEN: '/forbidden',
  NOTIFICATION: '/notification',
  CUSTOMER: {
    INDEX: '/customer',
    SEARCH: '/customer/search',
    DETAIL: '/customer/detail',
    CREATE: '/customer/create',
    CONTACT_PERSON: {
      SEARCH: '/customer/contact-person/search',
      CREATE: '/customer/contact-person/create',
      DETAIL: '/customer/contact-person/detail',
    },
    BUSINESS: {
      INDEX: '/customer/business',
      SEARCH: '/customer/business/search',
      CREATE: '/customer/business/create',
      DETAIL: '/customer/business/detail',
    },
    VISIT: {
      INDEX: '/customer/visit',
      SEARCH: '/customer/visit/search',
      CREATE: '/customer/visit/create',
      DETAIL: '/customer/visit/detail',
      CALENDAR: '/customer/visit/calendar',
      CHECKIN: '/customer/visit/checkin',
    },
    COMPETITOR: {
      INDEX: '/customer/competitor',
      SEARCH: '/customer/competitor/search',
      CREATE: '/customer/competitor/create',
      DETAIL: '/customer/competitor/detail',
    },
  },

  BUSINESS_PRODUCT: {
    INDEX: '/business-product',
    SEARCH: '/business-product/search',
    CREATE: '/business-product/create',
    DETAIL: '/business-product/detail',
  },

  SALES_PERFORMANCE: {
    INDEX: '/sales-performance',
    FORECAST: {
      INDEX: '/sales-performance/forecast',
      SEARCH: '/sales-performance/forecast/search',
    },
    TARGET: {
      INDEX: '/sales-performance/target',
      SALES_SEARCH: '/sales-performance/target/sales-search',
      SALESTEAM_SEARCH: '/sales-performance/target/salesteam-search',
    },
  },

  PRODUCT_PERFORMANVE: {
    INDEX: '/product-performance',
    FORECAST: {
      INDEX: '/product-performance/forecast',
      SEARCH: '/product-performance/forecast/search',
    },
    TARGET: {
      INDEX: '/product-performance/target',
      SALES_SEARCH: '/product-performance/target/sales-search',
      SALESTEAM_SEARCH: '/product-performance/target/salesteam-search',
    },
  },

  ANALYSIS: {
    INDEX: '/analysis',
    BUSINESS: '/analysis/business',
  },
  ACHIEVEMENT: {
    INDEX: '/achievement',
    NAV_ORDER: {
      INDEX: '/achievement/navOrder',
      SEARCH: '/achievement/navOrder/search',
      DETAIL: '/achievement/navOrder/detail',
    },
    NAV_SALES_INVOICE: {
      INDEX: '/achievement/navSalesInvoice',
      SEARCH: '/achievement/navSalesInvoice/search',
      DETAIL: '/achievement/navSalesInvoice/detail',
    },
    DASHBOARD_PRODUCTS: {
      INDEX: '/achievement/dashboard-products',
    },
    MARKETING_TARGET: {
      INDEX: '/achievement/marketing-target',
    },
    DASHBOARD_TARGET: {
      INDEX: '/achievement/dashboard-target',
    },
  },

  SERVICE_ORDER: {
    INDEX: '/service-order',
    WORK_ORDER_LIST: '/service-order/work-order/list',
    WORK_DIARY_SEARCH: '/service-order/work-diary/search',
    WORK_DIARY_DETAIL: '/service-order/work-diary/detail',
    CREATE_WORK_DIARY: '/service-order/work-diary/create',
    WORK_DIARY_CHECK_IN: '/service-order/work-diary/check-in',
    SEARCH: '/service-order/search',
    WAIT_APPROVE: '/service-order/waitApprove',
    WAIT_ASSIGN: '/service-order/waitAssign',
    ACCPETANCE: '/service-order/acceptance',
    DETAIL: '/service-order/detail',
    CREATE: '/service-order/create',
  },
  WARRANTY_CONTRACT: {
    INDEX: '/warranty-contract',
    SEARCH: '/warranty-contract/search',
    DETAIL: '/warranty-contract/detail',
    CREATE: '/warranty-contract/create',
  },
  QUOTATION: {
    INDEX: '/quotation',
    SEARCH: '/quotation/search',
    CREATE: '/quotation/create',
  },
  FIXED_ASSET: {
    INDEX: '/fixed-asset',
    SEARCH: '/fixed-asset/search',
    CREATE: '/fixed-asset/create',
    DETAIL: '/fixed-asset/detail',
    LEND_LIST: '/fixed-asset/lend/list',
  },
  PRODUCT_EYE: {
    INDEX: '/product-eye',
    SEARCH: '/product-eye/search',
    CREATE: '/product-eye/create',
  },
  PROMOTION_EYE: {
    INDEX: '/promotion-eye',
    SEARCH: '/promotion-eye/search',
    CREATE: '/promotion-eye/create',
  },
  MATERIAL: {
    INDEX: '/material',
    SEARCH: '/material/search',
    CREATE: '/material/create',
  },
  SALES_TEAM: {
    INDEX: '/sales-team',
    SEARCH: '/sales-team/search',
    CREATE: '/sales-team/create',
    UNIT_SEARCH: '/sales-team/search-unit',
    UNIT_CREATE: '/sales-team/create-unit',
    DETAIL: '/sales-team/detail',
    UNIT_DETAIL: '/sales-team/detail-unit',
    GROUP: '/sales-team/group',
  },
  PM_TEAM: {
    INDEX: '/pm-team',
    SEARCH: '/pm-team/search',
    CREATE: '/pm-team/create',
    DETAIL: '/pm-team/detail',
  },
  PERMISSIONS: {
    INDEX: '/permissions',
    USER: '/permissions/user',
    ROLE: {
      SEARCH: '/permission/role/search',
      CREATE: '/permission/role/create',
      DETAIL: '/permission/role/detail',
    },
  },
  INVENTORY: {
    INDEX: '/inventory',
    SEARCH: '/inventory/search',
  },
  Demo: {
    INDEX: '/demo',
  },
};

// for user permission codes
export enum CUSTOMER_PERMISSIONS {
  READ = 'customer.read',
  CREATE = 'customer.create',
  UPDATE = 'customer.update',
  SUBMIT = 'customer.submit',
  EXPORT = 'customer.export',
  BATCH_UPDATE = 'customer.batch_update',
}

export enum CONTACT_PEOPLE_PERMISSIONS {
  READ = 'contact_people.read',
  CREATE = 'contact_people.create',
  UPDATE = 'contact_people.update',
  DELETE = 'contact_people.delete',
}

export enum QUOTATION_PERMISSIONS {
  READ = 'quotation.read',
  CREATE = 'quotation.create',
  COMMISSION_AMOUNT = 'quotation.commission_amount',
}

export enum WARRANTY_PERMISSIONS {
  READ = 'warranty.read',
  CREATE = 'warranty.create',
  UPDATE = 'warranty.update',
  DELETE = 'warranty.delete',
}

export enum SERVICE_ORDER_PERMISSIONS {
  READ = 'service_order.read',
  CREATE = 'service_order.create',
  UPDATE = 'service_order.update',
  DELETE = 'service_order.delete',
  APPROVE_USER = 'service_order.approver_user',
  APPROVAL_STATUS_UPDATE = 'service_order.approval_status.update',
}

export enum WORK_DIARY_PERMISSIONS {
  READ = 'work_diary.read',
  CREATE = 'work_diary.create',
  UPDATE = 'work_diary.update',
  DELETE = 'work_diary.delete',
}

export enum PRODUCT_PERMISSIONS {
  READ = 'product.read',
  CREATE = 'product.create',
  UPDATE = 'product.update',
  DELETE = 'product.delete',
}

export enum MATERIAL_PERMISSIONS {
  READ = 'material.read',
  CREATE = 'material.create',
  UPDATE = 'material.update',
}

export enum PROMOTION_PERMISSIONS {
  READ = 'promotion.read',
  CREATE = 'promotion.create',
  UPDATE = 'promotion.update',
  DELETE = 'promotion.delete',
}

export enum BUSINESS_PERMISSIONS {
  READ = 'business.read',
  CREATE = 'business.create',
  UPDATE = 'business.update',
  DELETE = 'business.delete',
  EXPORT = 'business.export',
  BATCH_UPDATE = 'business.batch_update',
}

export enum BUSINESS_PRODUCT_PERMISSIONS {
  READ = 'business_product.read',
  CREATE = 'business_product.create',
  UPDATE = 'business_product.update',
}

export enum VISIT_PERMISSIONS {
  READ = 'visit.read',
  CREATE = 'visit.create',
  UPDATE = 'visit.update',
  DELETE = 'visit.delete',
  CHECK_IN = 'visit_check_in.read',
}

export enum ANALYSIS_PERMISSIONS {
  BUSINESS = 'analysis.business',
  BUSINESS_PRODUCTSTAT_EXPORT = 'businessAnalysis.productStat.export',
}

export enum ACHIEVEMENT_PERMISSIONS {
  NAV_ORDER = 'achievement.nav_order.read',
  NAV_SALES_INVOICE = 'achievement.nav_sales_invoice.read',
  ANALYSIS = 'achievement.analysis',
}

export enum FIXED_ASSET_PERMISSIONS {
  READ = 'fixed_asset.read',
  CREATE = 'fixed_asset.create',
  UPDATE = 'fixed_asset.update',
}

export enum PROVIDER_PERMISSIONS {
  READ = 'provider.read',
  CREATE = 'provider.create',
  UPDATE = 'provider.update',
  DELETE = 'provider.delete',
}

export enum SALES_TEAM_PERMISSIONS {
  READ = 'sales_team.read',
  CREATE = 'sales_team.create',
  UPDATE = 'sales_team.update',
}

export enum SALES_TEAM_UNIT_PERMISSIONS {
  READ = 'sales_team_unit.read',
  CREATE = 'sales_team_unit.create',
  UPDATE = 'sales_team_unit.update',
}

export enum PM_TEAM_PERMISSIONS {
  READ = 'product_team.read',
  CREATE = 'product_team.create',
  UPDATE = 'product_team.update',
}

export enum USER_ROLE_PERMISSIONS {
  READ = 'permission.read',
  CREATE = 'permission.create',
  UPDATE = 'permission.update',
  DELETE = 'permission.delete',
}

export enum SALES_FORECAST_PERMISSIONS {
  READ = 'sales_user_forecast.read',
  UPDATE = 'sales_user_forecast.update',
  EXPORT = 'sales_user_forecast.export',
}

export enum SALES_PRODUCTS_FORECAST_PERMISSIONS {
  READ = 'sales_product_user_forecast.read',
  UPDATE = 'sales_product_user_forecast.update',
  EXPORT = 'sales_product_user_forecast.export',
}

export enum SALES_PERFORMANCE_PERMISSIONS {
  READ = 'sales_user_goal.read',
  UPDATE = 'sales_user_goal.update',
  EXPORT = 'sales_user_goal.export',
}

export enum SALES_TEAM_PERFORMANCE_PERMISSIONS {
  READ = 'sales_team_goal.read',
  UPDATE = 'sales_team_goal.update',
  EXPORT = 'sales_team_goal.export',
}

export enum PRODUCT_SALES_PERFORMANCE_PERMISSIONS {
  READ = 'sales_product_user_goal.read',
  UPDATE = 'sales_product_user_goal.update',
  EXPORT = 'sales_product_user_goal.export',
}

export enum PRODUCT_SALE_TEAM_PERFORMANCE_PERMISSIONS {
  READ = 'sales_product_team_goal.read',
  UPDATE = 'sales_product_team_goal.update',
  EXPORT = 'sales_product_team_goal.export',
}

export enum DASHBOARD_PRODUCTS_PERMISSIONS {
  READ = 'sales_product_analysis.read',
  EXPORT = 'sales_product_analysis.export',
}

export enum MARKETING_TARGET_PERMISSIONS {
  READ = 'sales_product_overall.read',
  UPDATE = 'sales_product_overall.update',
  EXPORT = 'sales_product_overall.export',
}

export enum DASHBOARD_TARGET_PERMISSIONS {
  READ = 'ec_dashboard_analysis.read',
  EXPORT = 'ec_dashboard_analysis.export',
}

export enum INVENTORY_PERMISSIONS {
  READ = 'ec_inventory_stat.read',
  EXPORT = 'ec_inventory_stat.export',
}

export const SORT_DIRECTION = {
  ascend: EnumSortDirection.Asc,
  descend: EnumSortDirection.Desc,
};
