import { MeQuery, UserLoginResult } from '@/graphql/types/generate';
import { CommonlySearch } from '@/modules/common/components/input/search.form';

const KEY = {
  USER_INFO: 'user_info',
  TOKEN: 'token',
  ME: 'me',
  COMMONLY_SEARCH: 'commonly_search',
};

const getJSONObject = <T>(key: string): T | null => {
  let data: T | null = null;
  const value = localStorage.getItem(key);
  if (value) {
    try {
      data = JSON.parse(value);
    } catch {
      data = null;
    }
  }
  return data;
};

export const getUserInfo = (): UserLoginResult | null => {
  return getJSONObject<UserLoginResult>(KEY.USER_INFO);
};

export const setUserInfo = (value: UserLoginResult) => {
  localStorage.setItem(KEY.USER_INFO, JSON.stringify(value));
};

export const getToken = (): string | null => {
  return localStorage.getItem(KEY.TOKEN);
};

export const setToken = (value: string) => {
  localStorage.setItem(KEY.TOKEN, value);
};

export const getMe = (): MeQuery['me'] | null => {
  return getJSONObject<MeQuery['me']>(KEY.ME);
};

export const setMe = (value: MeQuery['me']) => {
  localStorage.setItem(KEY.ME, JSON.stringify(value));
};

export const getCommonlySearch = () => {
  return getJSONObject<CommonlySearch[]>(KEY.COMMONLY_SEARCH);
};

export const setCommonlySearch = (value: CommonlySearch[]) => {
  localStorage.setItem(KEY.COMMONLY_SEARCH, JSON.stringify(value));
};

export const setLanguage = (value: string) => {
  localStorage.setItem('language', value);
};

export const getLanguage = () => {
  return localStorage.getItem('language');
};

export const cleanAll = (): void => {
  localStorage.removeItem(KEY.USER_INFO);
  localStorage.removeItem(KEY.TOKEN);
  localStorage.removeItem(KEY.ME);
};
