import { useState } from 'react';
import { SSOLogin, SSOLogout, getSSOToken, removeSSOToken } from '@clinico/sso';
import {
  LoginDocument,
  useLoginMutation,
  useMeLazyQuery,
} from '@/graphql/types/generate';
import * as localStorageHelper from '@/helpers/localStorage.helper';
import configs from '@/configs';
import { ApolloClient } from '@apollo/client';
import { useSetRecoilState } from 'recoil';
import { meState } from '@/store/me.store';
import { MATERIAL_PERMISSIONS } from '@/consts';

export const useAuth = () => {
  const [processing, setProcessing] = useState(false);
  const [userLogin] = useLoginMutation();
  const [meQuery] = useMeLazyQuery();
  const setMeState = useSetRecoilState(meState);

  const getMe = async () => {
    const { data, error } = await meQuery();
    setMeState({ ...(data?.me ?? {}) });
    data?.me && localStorageHelper.setMe({ ...data?.me });
  };

  /**
   * 登入
   */
  const login = async () => {
    setProcessing(true);

    let ssoToken = getSSOToken();
    if (!ssoToken) {
      // SSO Login
      ssoToken = await SSOLogin({
        url: configs.sso.url,
        forceCookie: process.env.NODE_ENV != 'production',
      });
    }
    // 取得 ERP-API User Info
    const { data } = await userLogin({
      variables: { ssoToken: ssoToken },
    });

    localStorageHelper.setUserInfo(data!.userLogin);
    localStorageHelper.setToken(data!.userLogin.token);
    await getMe();

    setProcessing(false);
  };

  /**
   * 登出
   */
  const logout = async () => {
    removeSSOToken({
      domain: configs.sso.domain,
    });
    await SSOLogout(configs.sso.url);
    localStorageHelper.cleanAll();
    window.location.reload();
  };
  return { login, logout, processing };
};

const login = async (client: ApolloClient<any>) => {
  let ssoToken = getSSOToken();
  if (!ssoToken) {
    // SSO Login
    ssoToken = await SSOLogin({
      url: configs.sso.url,
      forceCookie: process.env.NODE_ENV != 'production',
    });
  }

  const { data } = await client.mutate({
    mutation: LoginDocument,
    variables: { ssoToken: ssoToken },
  });

  localStorageHelper.setUserInfo(data!.userLogin);
  localStorageHelper.setToken(data!.userLogin.token);
};

// export const getMe = async (client: ApolloClient<any>) => {
//   const { data } = await client.query({
//     query: MeDocument,
//   });
//   localStorageHelper.setMe(data!.me);
// };

export const relogin = async (client: ApolloClient<any>) => {
  await login(client);
};

export const logout = async () => {
  removeSSOToken({
    domain: configs.sso.domain,
  });
  await SSOLogout(configs.sso.url);
  localStorageHelper.cleanAll();
  window.location.reload();
};
