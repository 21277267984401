import React, { useEffect } from 'react';
import { ROUTE_PATH } from '@/consts';
import { PageView } from '@/modules/common/components/tabs/pageView.tabs';
import { pageViewsState } from '@/store/page.store';
import { useTranslation } from 'react-i18next';
import { createSearchParams, useNavigate, useLocation } from 'react-router-dom';
import { useRecoilState, useRecoilValue } from 'recoil';
import { meState } from '@/store/me.store';
import { routers, TabRouterKeys } from '@/routers';
import * as localStorageHelper from '@/helpers/localStorage.helper';

import ForbiddenPage from '@/modules/exception/403.page';
import { notification } from 'antd';

type Params = {
  path: string;
  targetId?: string | number;
  search?: { [key: string]: any };
  state?: { [key: string]: any };
};

export const usePageProperty = () => {
  const { t } = useTranslation();

  const toSearchParams = (search?: {
    [key: string]: any;
  }): string | undefined => {
    const value = search ? `?${createSearchParams(search)}` : undefined;
    return value;
  };

  const getKey = (params: Params): string => {
    const search = toSearchParams(params.search);
    const key = `${params.path}${search || ''}`;
    return key;
  };

  const getTitle = (
    path: string,
    id?: string,
    name?: string,
  ): React.ReactElement => {
    return (
      <>
        {routers[path].icon} {t(routers[path].title)}
        {id ? ` - ${name ? name : id}` : ''}
      </>
    );
  };

  return { getKey, getTitle, toSearchParams };
};

export const usePageView = () => {
  const [pages, setPages] = useRecoilState(pageViewsState);
  const { getKey, getTitle, toSearchParams } = usePageProperty();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  // const [meQuery] = useMeLazyQuery();
  const me = useRecoilValue(meState) || localStorageHelper.getMe();

  const isAuth = (path: string): boolean => {
    return (
      routers[path]?.auth.some((code) =>
        me?.userPermission?.codes.includes(code),
      ) || routers[path].auth.length === 0
    );
  };

  useEffect(() => {
    if (pages.find((page: PageView) => page.key === '/home')) return;

    const title = getTitle(ROUTE_PATH.HOME);
    const page = {
      key: ROUTE_PATH.HOME,
      title: title,
      path: ROUTE_PATH.HOME,
      content: React.createElement(routers[ROUTE_PATH.HOME].component),
      closable: false,
    };

    setPages([page]);
  }, []);

  const handleOpenPage = (params: Params) => {
    const key = getKey(params);
    isAuth(params.path)
      ? navigate(key, {
          state: params.state,
        })
      : navigate(ROUTE_PATH.FORBIDDEN);
    if (!isAuth(params.path)) {
      notification.error({ message: t('not authorized') });
    }
  };

  const handlePageRemove: (params?: Params) => void = (
    params = { path: location.pathname },
  ) => {
    const key = getKey(params);

    let filteredPages = pages.filter((p) => p.key != key);
    setPages(filteredPages);

    if (`${location.pathname}${location.search}` === key) {
      let newKey =
        pages[pages.length - 1].key === key
          ? pages[pages.length - 2].key
          : pages[pages.length - 1].key;
      navigate(newKey);
    }
  };

  const handlePageChange = (params: Params) => {
    const key = getKey(params);
    navigate(key);
  };

  const openPage = async (params: Params) => {
    const tabPath = Object.keys(routers).find(
      (path: TabRouterKeys) => path === params.path,
    );

    let page: PageView;
    const searchParams = toSearchParams(params.search);
    const key = getKey(params);
    const id = params.search?.get('id') || '';
    const name = params.search?.get('name') || undefined;
    if (key && !pages.find((p) => p.key == key) && tabPath) {
      const title = getTitle(tabPath, id, name);
      const content = isAuth(params.path) ? (
        <div className="p-8">
          {React.createElement(routers[tabPath].component)}
        </div>
      ) : (
        <ForbiddenPage />
      );

      if (!isAuth(params.path)) {
        notification.error({ message: t('not authorized') });
      }

      page = {
        key: key,
        title: title,
        path: params.path,
        params: searchParams,
        content: content,
        closable: key != '/home',
      };
      setPages((pre) => [...pre, page]);
    }
  };

  return {
    handleOpenPage,
    handlePageRemove,
    handlePageChange,
    openPage,
  };
};
