import { Dropdown, Menu } from 'antd';
import React from 'react';
import { AiOutlineLogout, AiOutlineUser } from 'react-icons/ai';
import { ROUTE_PATH } from '@/consts';
import { useTranslation } from 'react-i18next';
import { useAuth } from '@/hooks/useAuth.hook';

interface Props {
  name: string;
  onItemClick: (key: string) => void;
}

export const ProfileDropdown: React.FC<Props> = ({ name, onItemClick }) => {
  const { logout } = useAuth();
  const { t } = useTranslation();
  const items = [
    {
      key: ROUTE_PATH.LOGIN,
      label: t('logout'),
      icon: <AiOutlineLogout />,
    },
  ];

  return (
    <Dropdown
      menu={{ items, onClick: logout }}
      overlayClassName="header-dropdown-menus"
    >
      <span className="header-dropdown">
        {t('hello')}! <span className="font-bold">{name}</span>
      </span>
    </Dropdown>
  );
};
