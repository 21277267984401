import packageInfo from '../package.json';

const Config = {
  api: {
    endpoint: process.env['REACT_APP_API_ENDPOINT'],
    host: process.env['REACT_APP_API_HOST'],
  },
  sso: {
    url: process.env['REACT_APP_SSO_URL'],
    domain: process.env['REACT_APP_SSO_DOMAIN'],
  },
  table: {
    perPageRows: 10,
    perPageSmallRows: 4,
  },
  list: {
    limit: 50,
  },
  product: {
    table: {
      perPageRows: 5,
    },
  },
  version: packageInfo.version,
};

export default Config;
