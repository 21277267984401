import { gql } from '@apollo/client';
import * as ApolloReactCommon from '@apollo/client';
import * as ApolloReactHooks from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K];
};
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]?: Maybe<T[SubKey]>;
};
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]: Maybe<T[SubKey]>;
};
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  Date: any;
  DateTime: any;
  EmailAddress: any;
  JSON: any;
  JWT: any;
  PhoneNumber: any;
  URL: any;
  Upload: any;
};

export type AppVersion = IAppVersion & {
  code: Scalars['String'];
  currentVersion: Scalars['String'];
  id: Scalars['ID'];
  name: Scalars['String'];
  type: Scalars['String'];
};

export type AttachmentCreateInput = {
  file: Scalars['Upload'];
  memo?: InputMaybe<Scalars['String']>;
  /** 檔案名稱 */
  name: Scalars['String'];
};

export type Business = IBusiness & {
  /** 客戶預算金額 */
  budgetAmount?: Maybe<Scalars['Float']>;
  /** 商機與預算商品關聯 */
  businessesToBudgetProducts: Array<BusinessesToBudgetProduct>;
  /** 商機與成交商品關聯 */
  businessesToDealProducts: Array<BusinessesToDealProduct>;
  /** 商機與負責（協助）業務關聯 */
  businessesUsers: Array<BusinessesUser>;
  /** 購買機會 */
  buyingOpportunity?: Maybe<BusinessOpportunity>;
  /** 購買機會 */
  buyingOpportunityId?: Maybe<Scalars['ID']>;
  /** 實際結束日期 */
  closedDate?: Maybe<Scalars['Date']>;
  /** 編號 */
  code?: Maybe<Scalars['String']>;
  competitors: Array<Competitor>;
  /** 內容 */
  content?: Maybe<Scalars['String']>;
  /** 資料新增日期 */
  createdAt: Scalars['DateTime'];
  createdUser?: Maybe<User>;
  /** 資料建立人員 */
  createdUserId?: Maybe<Scalars['ID']>;
  customer?: Maybe<Customer>;
  /** 客戶 */
  customerId?: Maybe<Scalars['ID']>;
  /** 雙方成交金額 */
  dealAmount?: Maybe<Scalars['Float']>;
  /** 經銷商 */
  dealer?: Maybe<Customer>;
  /** 經銷商 */
  dealerId?: Maybe<Scalars['ID']>;
  /** 預計結束日期 */
  expectedClosedDate?: Maybe<Scalars['Date']>;
  /** 眼科報價單編號 */
  eyeQuotationOrderCode?: Maybe<Scalars['String']>;
  eyeServiceOrders: Array<EyeServiceOrder>;
  id: Scalars['ID'];
  /** 丟單改善計畫 */
  losingImprovementPlan?: Maybe<Scalars['String']>;
  /** 丟單原因 */
  losingReason?: Maybe<Scalars['String']>;
  losingReasons: Array<BusinessLosingReason>;
  memos: Array<BusinessMemo>;
  /** 訂單編號（鼎新、道一雲、用友） */
  orderCode?: Maybe<Scalars['String']>;
  primaryContactPeople: Array<ContactPerson>;
  /** @deprecated 改用 salesTeamUnit */
  primaryUser?: Maybe<User>;
  /** 主要負責業務（棄用；改用 sales_team_unit_id ） */
  primaryUserId?: Maybe<Scalars['ID']>;
  properties: Array<BusinessProperty>;
  region?: Maybe<Region>;
  /** 業務銷售金額 */
  saleAmount?: Maybe<Scalars['Float']>;
  /** 商機銷售方式 */
  salesMethod?: Maybe<BusinessSalesMethod>;
  /** 銷售方式 */
  salesMethodId?: Maybe<Scalars['ID']>;
  salesTeam?: Maybe<SalesTeam>;
  /** 業務團隊組織 */
  salesTeamGroup?: Maybe<SalesTeamGroup>;
  /** 業務團隊組織 */
  salesTeamGroupId: Scalars['ID'];
  /** 負責業務團隊 */
  salesTeamId?: Maybe<Scalars['ID']>;
  salesTeamUnit?: Maybe<SalesTeamUnit>;
  /** 業務團隊單位 */
  salesTeamUnitId?: Maybe<Scalars['ID']>;
  status?: Maybe<BusinessStatus>;
  /** 狀態 */
  statusId?: Maybe<Scalars['ID']>;
  /** 標題 */
  title?: Maybe<Scalars['String']>;
  type?: Maybe<BusinessType>;
  /** 類型 */
  typeId?: Maybe<Scalars['ID']>;
  /** 資料修改日期 */
  updatedAt: Scalars['DateTime'];
  updatedUser?: Maybe<User>;
  /** 資料修改人員 */
  updatedUserId?: Maybe<Scalars['ID']>;
  /** 跨部門負責人員 */
  users: Array<BusinessesUser>;
  visits: Array<Visit>;
  /** 贏單機會 */
  winningOpportunity?: Maybe<BusinessOpportunity>;
  /** 贏單機會 */
  winningOpportunityId?: Maybe<Scalars['ID']>;
};

export type BusinessAnalysis = {
  /** 商機商品統計排名 */
  businessProductRank: Array<BusinessAnalysisProductRank>;
  /** 商機商品統計by月 */
  businessProductStat: Array<BusinessAnalysisProductStat>;
  /** 商機統計 */
  businessStat: BusinessAnalysisBusinessStat;
};

export type BusinessAnalysisBusinessProductRankArgs = {
  filters?: InputMaybe<BusinessAnalysisProductRankSearchInput>;
};

export type BusinessAnalysisBusinessProductStatArgs = {
  filters?: InputMaybe<BusinessAnalysisProductStatSearchInput>;
};

export type BusinessAnalysisBusinessStatArgs = {
  filters?: InputMaybe<BusinessAnalysisBusinessStatFilterInput>;
};

export type BusinessAnalysisBusinessStat = {
  /** 細項統計 */
  details: Array<BusinessAnalysisBusinessStat_Detail>;
  /** 總體統計 */
  overall: BusinessAnalysisBusinessStat_Overall;
};

export type BusinessAnalysisBusinessStatFilterInput = {
  /** 欲購買商品 */
  budgetProductIds?: InputMaybe<Array<Scalars['ID']>>;
  /** 實際結束日期（起） */
  closedDate1?: InputMaybe<Scalars['Date']>;
  /** 實際結束日期（迄） */
  closedDate2?: InputMaybe<Scalars['Date']>;
  /** 編號 */
  code?: InputMaybe<Scalars['String']>;
  /** 建立日期（起） */
  createdDate1?: InputMaybe<Scalars['Date']>;
  /** 建立日期（迄） */
  createdDate2?: InputMaybe<Scalars['Date']>;
  /** 客戶統一編號 */
  customerBusinessCode?: InputMaybe<Scalars['String']>;
  /** 客戶編號 */
  customerCode?: InputMaybe<Scalars['String']>;
  /** 客戶 */
  customerId?: InputMaybe<Scalars['ID']>;
  /** 客戶（複數） */
  customerIds?: InputMaybe<Array<Scalars['ID']>>;
  /** 客戶醫事編號 */
  customerMedicalCode?: InputMaybe<Scalars['String']>;
  /** 客戶名稱 */
  customerName?: InputMaybe<Scalars['String']>;
  /** 成交商品 */
  dealProductIds?: InputMaybe<Array<Scalars['ID']>>;
  /** 經銷商 */
  dealerId?: InputMaybe<Scalars['ID']>;
  /** 預計結束日期（起） */
  expectedClosedDate1?: InputMaybe<Scalars['Date']>;
  /** 預計結束日期（迄） */
  expectedClosedDate2?: InputMaybe<Scalars['Date']>;
  /** 固定資產 */
  eyeFixedAssetId?: InputMaybe<Scalars['ID']>;
  /** 眼科報價單編號 */
  eyeQuotationOrderCode?: InputMaybe<Scalars['String']>;
  /** 眼科報價單 */
  eyeQuotationOrderId?: InputMaybe<Scalars['ID']>;
  id?: InputMaybe<Scalars['ID']>;
  /** 訂單編號（鼎新、道一雲、用友） */
  orderCode?: InputMaybe<Scalars['String']>;
  /** 主要負責業務 */
  primaryUserId?: InputMaybe<Scalars['ID']>;
  /** 主要負責業務（複數） */
  primaryUserIds?: InputMaybe<Array<Scalars['ID']>>;
  regionIds?: InputMaybe<Array<Scalars['ID']>>;
  /** 銷售方式 */
  salesMethodIds?: InputMaybe<Array<Scalars['ID']>>;
  /** 業務團隊組織 */
  salesTeamGroupIds?: InputMaybe<Array<Scalars['ID']>>;
  /** 負責業務團隊 */
  salesTeamId?: InputMaybe<Scalars['ID']>;
  /** 負責業務頂層團隊 */
  salesTeamTopId?: InputMaybe<Scalars['ID']>;
  /** 模糊搜尋: 客戶名稱、商機編號 */
  searchAll?: InputMaybe<Scalars['String']>;
  /** 狀態 */
  statusId?: InputMaybe<Scalars['ID']>;
  /** 狀態（複數） */
  statusIds?: InputMaybe<Array<Scalars['ID']>>;
  /** 狀態類型 */
  statusType?: InputMaybe<EnumBusinessStatusType>;
  /** 狀態類型（複數） */
  statusTypes?: InputMaybe<Array<EnumBusinessStatusType>>;
  /** 成交機會 */
  transactionOpportunity?: InputMaybe<EnumBusinessTransactionOpportunity>;
  /** 類型 */
  typeId?: InputMaybe<Scalars['ID']>;
  /** 更新日期（起） */
  updatedDate1?: InputMaybe<Scalars['Date']>;
  /** 更新日期（迄） */
  updatedDate2?: InputMaybe<Scalars['Date']>;
  /** 贏單機會 */
  winningOpportunityId?: InputMaybe<Scalars['ID']>;
  /** 贏單機會（複數） */
  winningOpportunityIds?: InputMaybe<Array<Scalars['ID']>>;
};

export type BusinessAnalysisBusinessStat_Detail = {
  /** 金額 */
  amount: Scalars['Float'];
  /** 金額百分比（1: 100%, 0.1234: 12.34%, 0: 0%）。分母（總計金額）為 0 時，百分比顯示為空值 */
  amountPercent?: Maybe<Scalars['Float']>;
  /** 數量 */
  count: Scalars['Int'];
  /** 數量百分比（1: 100%, 0.1234: 12.34%, 0: 0%）。分母（總計數量）為 0 時，百分比顯示為空值 */
  countPercent?: Maybe<Scalars['Float']>;
  /** 類型 */
  type: EnumBusinessStatType;
};

export type BusinessAnalysisBusinessStat_Overall = {
  /** 金額 */
  amount: Scalars['Float'];
  /** 數量 */
  count: Scalars['Int'];
};

export type BusinessAnalysisProductRank = {
  businessProduct: BusinessProduct;
  /** 商機商品 */
  businessProductId: Scalars['ID'];
  /** 數量 */
  qty: Scalars['Int'];
  /** 排名 */
  rank: Scalars['Int'];
  /** 流水號 */
  seq: Scalars['Int'];
};

export type BusinessAnalysisProductRankSearchInput = {
  /** 欲購買商品 */
  budgetProductIds?: InputMaybe<Array<Scalars['ID']>>;
  /** 實際結束日期（起） */
  closedDate1?: InputMaybe<Scalars['Date']>;
  /** 實際結束日期（迄） */
  closedDate2?: InputMaybe<Scalars['Date']>;
  /** 編號 */
  code?: InputMaybe<Scalars['String']>;
  /** 建立日期（起） */
  createdDate1?: InputMaybe<Scalars['Date']>;
  /** 建立日期（迄） */
  createdDate2?: InputMaybe<Scalars['Date']>;
  /** 客戶統一編號 */
  customerBusinessCode?: InputMaybe<Scalars['String']>;
  /** 客戶編號 */
  customerCode?: InputMaybe<Scalars['String']>;
  /** 客戶 */
  customerId?: InputMaybe<Scalars['ID']>;
  /** 客戶（複數） */
  customerIds?: InputMaybe<Array<Scalars['ID']>>;
  /** 客戶醫事編號 */
  customerMedicalCode?: InputMaybe<Scalars['String']>;
  /** 客戶名稱 */
  customerName?: InputMaybe<Scalars['String']>;
  /** 成交商品 */
  dealProductIds?: InputMaybe<Array<Scalars['ID']>>;
  /** 經銷商 */
  dealerId?: InputMaybe<Scalars['ID']>;
  /** 預計結束日期（起） */
  expectedClosedDate1?: InputMaybe<Scalars['Date']>;
  /** 預計結束日期（迄） */
  expectedClosedDate2?: InputMaybe<Scalars['Date']>;
  /** 固定資產 */
  eyeFixedAssetId?: InputMaybe<Scalars['ID']>;
  /** 眼科報價單編號 */
  eyeQuotationOrderCode?: InputMaybe<Scalars['String']>;
  /** 眼科報價單 */
  eyeQuotationOrderId?: InputMaybe<Scalars['ID']>;
  id?: InputMaybe<Scalars['ID']>;
  /** 訂單編號（鼎新、道一雲、用友） */
  orderCode?: InputMaybe<Scalars['String']>;
  /** 主要負責業務 */
  primaryUserId?: InputMaybe<Scalars['ID']>;
  /** 主要負責業務（複數） */
  primaryUserIds?: InputMaybe<Array<Scalars['ID']>>;
  regionIds?: InputMaybe<Array<Scalars['ID']>>;
  /** 銷售方式 */
  salesMethodIds?: InputMaybe<Array<Scalars['ID']>>;
  /** 業務團隊組織 */
  salesTeamGroupIds?: InputMaybe<Array<Scalars['ID']>>;
  /** 負責業務團隊 */
  salesTeamId?: InputMaybe<Scalars['ID']>;
  /** 負責業務頂層團隊 */
  salesTeamTopId?: InputMaybe<Scalars['ID']>;
  /** 模糊搜尋: 客戶名稱、商機編號 */
  searchAll?: InputMaybe<Scalars['String']>;
  /** 狀態 */
  statusId?: InputMaybe<Scalars['ID']>;
  /** 狀態（複數） */
  statusIds?: InputMaybe<Array<Scalars['ID']>>;
  /** 狀態類型 */
  statusType?: InputMaybe<EnumBusinessStatusType>;
  /** 狀態類型（複數） */
  statusTypes?: InputMaybe<Array<EnumBusinessStatusType>>;
  /** 成交機會 */
  transactionOpportunity?: InputMaybe<EnumBusinessTransactionOpportunity>;
  /** 類型 */
  typeId?: InputMaybe<Scalars['ID']>;
  /** 更新日期（起） */
  updatedDate1?: InputMaybe<Scalars['Date']>;
  /** 更新日期（迄） */
  updatedDate2?: InputMaybe<Scalars['Date']>;
  /** 贏單機會 */
  winningOpportunityId?: InputMaybe<Scalars['ID']>;
  /** 贏單機會（複數） */
  winningOpportunityIds?: InputMaybe<Array<Scalars['ID']>>;
};

export type BusinessAnalysisProductStat = {
  businessProduct: BusinessProduct;
  /** 商機商品 */
  businessProductId: Scalars['ID'];
  /** 1月qty (台數) */
  month1: Scalars['Int'];
  /** 2月qty (台數) */
  month2: Scalars['Int'];
  /** 3月qty (台數) */
  month3: Scalars['Int'];
  /** 4月qty (台數) */
  month4: Scalars['Int'];
  /** 5月qty (台數) */
  month5: Scalars['Int'];
  /** 6月qty (台數) */
  month6: Scalars['Int'];
  /** 7月qty (台數) */
  month7: Scalars['Int'];
  /** 8月qty (台數) */
  month8: Scalars['Int'];
  /** 9月qty (台數) */
  month9: Scalars['Int'];
  /** 10月qty (台數) */
  month10: Scalars['Int'];
  /** 11月qty (台數) */
  month11: Scalars['Int'];
  /** 12月qty (台數) */
  month12: Scalars['Int'];
  /** 流水號 */
  seq: Scalars['Int'];
  /** total qty (台數) */
  total: Scalars['Int'];
  /** 年份 */
  year: Scalars['String'];
};

export type BusinessAnalysisProductStatSearchInput = {
  /** 欲購買商品 */
  budgetProductIds?: InputMaybe<Array<Scalars['ID']>>;
  /** 實際結束日期（起） */
  closedDate1?: InputMaybe<Scalars['Date']>;
  /** 實際結束日期（迄） */
  closedDate2?: InputMaybe<Scalars['Date']>;
  /** 編號 */
  code?: InputMaybe<Scalars['String']>;
  /** 建立日期（起） */
  createdDate1?: InputMaybe<Scalars['Date']>;
  /** 建立日期（迄） */
  createdDate2?: InputMaybe<Scalars['Date']>;
  /** 客戶統一編號 */
  customerBusinessCode?: InputMaybe<Scalars['String']>;
  /** 客戶編號 */
  customerCode?: InputMaybe<Scalars['String']>;
  /** 客戶 */
  customerId?: InputMaybe<Scalars['ID']>;
  /** 客戶（複數） */
  customerIds?: InputMaybe<Array<Scalars['ID']>>;
  /** 客戶醫事編號 */
  customerMedicalCode?: InputMaybe<Scalars['String']>;
  /** 客戶名稱 */
  customerName?: InputMaybe<Scalars['String']>;
  /** 成交商品 */
  dealProductIds?: InputMaybe<Array<Scalars['ID']>>;
  /** 經銷商 */
  dealerId?: InputMaybe<Scalars['ID']>;
  /** 預計結束日期（起） */
  expectedClosedDate1?: InputMaybe<Scalars['Date']>;
  /** 預計結束日期（迄） */
  expectedClosedDate2?: InputMaybe<Scalars['Date']>;
  /** 固定資產 */
  eyeFixedAssetId?: InputMaybe<Scalars['ID']>;
  /** 眼科報價單編號 */
  eyeQuotationOrderCode?: InputMaybe<Scalars['String']>;
  /** 眼科報價單 */
  eyeQuotationOrderId?: InputMaybe<Scalars['ID']>;
  id?: InputMaybe<Scalars['ID']>;
  /** 訂單編號（鼎新、道一雲、用友） */
  orderCode?: InputMaybe<Scalars['String']>;
  /** 主要負責業務 */
  primaryUserId?: InputMaybe<Scalars['ID']>;
  /** 主要負責業務（複數） */
  primaryUserIds?: InputMaybe<Array<Scalars['ID']>>;
  regionIds?: InputMaybe<Array<Scalars['ID']>>;
  /** 銷售方式 */
  salesMethodIds?: InputMaybe<Array<Scalars['ID']>>;
  /** 業務團隊組織 */
  salesTeamGroupIds?: InputMaybe<Array<Scalars['ID']>>;
  /** 負責業務團隊 */
  salesTeamId?: InputMaybe<Scalars['ID']>;
  /** 負責業務頂層團隊 */
  salesTeamTopId?: InputMaybe<Scalars['ID']>;
  /** 模糊搜尋: 客戶名稱、商機編號 */
  searchAll?: InputMaybe<Scalars['String']>;
  /** 狀態 */
  statusId?: InputMaybe<Scalars['ID']>;
  /** 狀態（複數） */
  statusIds?: InputMaybe<Array<Scalars['ID']>>;
  /** 狀態類型 */
  statusType?: InputMaybe<EnumBusinessStatusType>;
  /** 狀態類型（複數） */
  statusTypes?: InputMaybe<Array<EnumBusinessStatusType>>;
  /** 成交機會 */
  transactionOpportunity?: InputMaybe<EnumBusinessTransactionOpportunity>;
  /** 類型 */
  typeId?: InputMaybe<Scalars['ID']>;
  /** 更新日期（起） */
  updatedDate1?: InputMaybe<Scalars['Date']>;
  /** 更新日期（迄） */
  updatedDate2?: InputMaybe<Scalars['Date']>;
  /** 贏單機會 */
  winningOpportunityId?: InputMaybe<Scalars['ID']>;
  /** 贏單機會（複數） */
  winningOpportunityIds?: InputMaybe<Array<Scalars['ID']>>;
};

export type BusinessBudgetProductInput = {
  budgetProductId: Scalars['ID'];
  qty?: InputMaybe<Scalars['Int']>;
};

export type BusinessCreateInput = {
  /** 客戶預算金額 */
  budgetAmount?: InputMaybe<Scalars['String']>;
  /** 商機與預算商品關聯 */
  budgetProducts?: InputMaybe<Array<BusinessBudgetProductInput>>;
  /** 購買機會 */
  buyingOpportunityId?: InputMaybe<Scalars['ID']>;
  /** 實際結束日期 */
  closedDate?: InputMaybe<Scalars['Date']>;
  /** 競爭對手 */
  competitorIds?: InputMaybe<Array<Scalars['ID']>>;
  /** 內容 */
  content?: InputMaybe<Scalars['String']>;
  /** 客戶 */
  customerId?: InputMaybe<Scalars['ID']>;
  /** 雙方成交金額 */
  dealAmount?: InputMaybe<Scalars['String']>;
  /** 商機與成交商品關聯 */
  dealProducts?: InputMaybe<Array<BusinessDealProductInput>>;
  /** 經銷商 */
  dealerId?: InputMaybe<Scalars['ID']>;
  /** 預計結束日期 */
  expectedClosedDate?: InputMaybe<Scalars['Date']>;
  /** 眼科報價單編號 */
  eyeQuotationOrderCode?: InputMaybe<Scalars['String']>;
  /** 丟單改善計畫 */
  losingImprovementPlan?: InputMaybe<Scalars['String']>;
  /** 丟單原因 */
  losingReason?: InputMaybe<Scalars['String']>;
  /** 丟單原因 */
  losingReasonIds?: InputMaybe<Array<Scalars['ID']>>;
  /** 訂單編號（鼎新、道一雲、用友） */
  orderCode?: InputMaybe<Scalars['String']>;
  /** 主要聯絡人 */
  primaryContactPersonIds?: InputMaybe<Array<Scalars['ID']>>;
  /** 商機屬性 */
  propertyIds?: InputMaybe<Array<Scalars['ID']>>;
  /** 業務銷售金額 */
  saleAmount?: InputMaybe<Scalars['String']>;
  /** 銷售方式 */
  salesMethodId?: InputMaybe<Scalars['ID']>;
  salesTeamGroupId: Scalars['ID'];
  /** 負責業務團隊 */
  salesTeamId?: InputMaybe<Scalars['ID']>;
  /** 業務團隊位置 */
  salesTeamUnitId?: InputMaybe<Scalars['ID']>;
  /** 狀態 */
  statusId?: InputMaybe<Scalars['ID']>;
  /** 標題 */
  title?: InputMaybe<Scalars['String']>;
  /** 類型 */
  typeId?: InputMaybe<Scalars['ID']>;
  /** 商機與負責（協助）業務關聯 */
  users?: InputMaybe<Array<BusinessUserInput>>;
  /** 贏單機會 */
  winningOpportunityId?: InputMaybe<Scalars['ID']>;
};

export type BusinessDealProductInput = {
  dealProductId: Scalars['ID'];
  qty?: InputMaybe<Scalars['Int']>;
};

export type BusinessLosingReason = IBusinessLosingReason & {
  /** 資料新增日期 */
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  /** 名稱 */
  name: Scalars['String'];
  region?: Maybe<Region>;
  salesTeamGroup?: Maybe<SalesTeamGroup>;
  /** 業務團隊組織 */
  salesTeamGroupId: Scalars['ID'];
  /** 資料修改日期 */
  updatedAt: Scalars['DateTime'];
  /** 檢視順序 */
  viewOrder?: Maybe<Scalars['Int']>;
};

export type BusinessLosingReasonCreateInput = {
  /** 名稱 */
  name: Scalars['String'];
  /** 業務團隊組織 */
  salesTeamGroupId: Scalars['ID'];
  /** 檢視順序 */
  viewOrder?: InputMaybe<Scalars['Int']>;
};

export type BusinessLosingReasonSearchInput = {
  id?: InputMaybe<Scalars['ID']>;
  /** 名稱 */
  name?: InputMaybe<Scalars['String']>;
  regionIds?: InputMaybe<Array<Scalars['ID']>>;
  salesTeamGroupIds?: InputMaybe<Array<Scalars['ID']>>;
};

export type BusinessLosingReasonSearchResult = {
  count: Scalars['Int'];
  pageInfo: PageInfo;
  rows: Array<BusinessLosingReason>;
};

export type BusinessLosingReasonUpdateInput = {
  id: Scalars['ID'];
  /** 名稱 */
  name?: InputMaybe<Scalars['String']>;
  /** 檢視順序 */
  viewOrder?: InputMaybe<Scalars['Int']>;
};

export type BusinessMemo = IBusinessMemo & {
  business?: Maybe<Business>;
  /** 商機 */
  businessId: Scalars['ID'];
  /** 內容 */
  content?: Maybe<Scalars['String']>;
  /** 資料新增日期 */
  createdAt: Scalars['DateTime'];
  createdUser?: Maybe<User>;
  /** 資料建立人員 */
  createdUserId?: Maybe<Scalars['ID']>;
  id: Scalars['ID'];
  /** 標題 */
  title?: Maybe<Scalars['String']>;
  /** 資料修改日期 */
  updatedAt: Scalars['DateTime'];
  updatedUser?: Maybe<User>;
  /** 資料修改人員 */
  updatedUserId?: Maybe<Scalars['ID']>;
};

export type BusinessMemoCreateInput = {
  businessId?: InputMaybe<Scalars['ID']>;
  content?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
};

export type BusinessMemoSearchInput = {
  businessId?: InputMaybe<Scalars['ID']>;
  id?: InputMaybe<Scalars['ID']>;
};

export type BusinessMemoSearchResult = {
  count: Scalars['Int'];
  pageInfo: PageInfo;
  rows: Array<BusinessMemo>;
};

export type BusinessMemoUpdateInput = {
  businessId?: InputMaybe<Scalars['ID']>;
  content?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
  title?: InputMaybe<Scalars['String']>;
};

export type BusinessOpportunity = IBusinessOpportunity & {
  /** 編號 */
  code?: Maybe<Scalars['String']>;
  /** 資料新增日期 */
  createdAt: Scalars['DateTime'];
  /** 是否啟用於前端畫面顯示 */
  enabled: Scalars['Boolean'];
  id: Scalars['ID'];
  /** 名稱 */
  name: Scalars['String'];
  region?: Maybe<Region>;
  salesTeamGroup?: Maybe<SalesTeamGroup>;
  /** 業務團隊組織 */
  salesTeamGroupId: Scalars['ID'];
  /** 資料修改日期 */
  updatedAt: Scalars['DateTime'];
  /** 檢視順序 */
  viewOrder?: Maybe<Scalars['Int']>;
};

export type BusinessOpportunityCreateInput = {
  code?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  salesTeamGroupId: Scalars['ID'];
  viewOrder?: InputMaybe<Scalars['Int']>;
};

export type BusinessOpportunitySearchInput = {
  code?: InputMaybe<Scalars['String']>;
  enabled?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['ID']>;
  regionIds?: InputMaybe<Array<Scalars['ID']>>;
  salesTeamGroupIds?: InputMaybe<Array<Scalars['ID']>>;
};

export type BusinessOpportunitySearchResult = {
  count: Scalars['Int'];
  pageInfo: PageInfo;
  rows: Array<BusinessOpportunity>;
};

export type BusinessOpportunityUpdateInput = {
  code?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
  name?: InputMaybe<Scalars['String']>;
  salesTeamGroupId?: InputMaybe<Scalars['ID']>;
  viewOrder?: InputMaybe<Scalars['Int']>;
};

/** 批次調整商機負責人 */
export type BusinessPrimaryUserBatchUpdateInput = {
  /** 商機（複數） */
  businessIds: Array<Scalars['ID']>;
  /** 目標負責人 */
  targetUserId: Scalars['ID'];
};

export type BusinessProduct = IBusinessProduct & {
  /** 廠牌 */
  brand?: Maybe<Scalars['String']>;
  /** 資料新增日期 */
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  /** 是否啟用 */
  isActive: Scalars['Boolean'];
  materials: Array<Material>;
  /** 名稱 */
  name: Scalars['String'];
  productTeams: Array<ProductTeam>;
  region?: Maybe<Region>;
  salesTeamGroup?: Maybe<SalesTeamGroup>;
  /** 業務團隊組織 */
  salesTeamGroupId: Scalars['ID'];
  salesTeams: Array<SalesTeam>;
  type?: Maybe<BusinessProductType>;
  /** 類型 */
  typeId?: Maybe<Scalars['ID']>;
  /** 資料修改日期 */
  updatedAt: Scalars['DateTime'];
  /** 檢視順序 */
  viewOrder?: Maybe<Scalars['Int']>;
};

export type BusinessProductCreateInput = {
  /** 廠牌 */
  brand?: InputMaybe<Scalars['String']>;
  /** 是否啟用 */
  isActive?: InputMaybe<Scalars['Boolean']>;
  /** 料號 */
  materialIds?: InputMaybe<Array<Scalars['ID']>>;
  name: Scalars['String'];
  /** PM團隊 */
  productTeamIds?: InputMaybe<Array<Scalars['ID']>>;
  salesTeamGroupId: Scalars['ID'];
  /** 業務團隊 */
  salesTeamIds?: InputMaybe<Array<Scalars['ID']>>;
  typeId?: InputMaybe<Scalars['ID']>;
  viewOrder?: InputMaybe<Scalars['Int']>;
};

export type BusinessProductSearchInput = {
  /** 廠牌 */
  brand?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['ID']>;
  /** 是否啟用 */
  isActive?: InputMaybe<Scalars['Boolean']>;
  /** 料號 */
  materialIds?: InputMaybe<Array<Scalars['ID']>>;
  name?: InputMaybe<Scalars['String']>;
  /** PM團隊 */
  productTeamIds?: InputMaybe<Array<Scalars['ID']>>;
  regionIds?: InputMaybe<Array<Scalars['ID']>>;
  salesTeamGroupIds?: InputMaybe<Array<Scalars['ID']>>;
  salesTeamId?: InputMaybe<Scalars['ID']>;
  typeId?: InputMaybe<Scalars['ID']>;
};

export type BusinessProductSearchResult = {
  count: Scalars['Int'];
  pageInfo: PageInfo;
  rows: Array<BusinessProduct>;
};

export type BusinessProductType = IBusinessProductType & {
  /** 資料新增日期 */
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  /** 名稱 */
  name: Scalars['String'];
  /** 業務團隊組織 */
  salesTeamGroupId: Scalars['ID'];
  /** 資料修改日期 */
  updatedAt: Scalars['DateTime'];
  /** 檢視順序 */
  viewOrder?: Maybe<Scalars['Int']>;
};

export type BusinessProductUpdateInput = {
  /** 廠牌 */
  brand?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
  /** 是否啟用 */
  isActive?: InputMaybe<Scalars['Boolean']>;
  /** 料號 */
  materialIds?: InputMaybe<Array<Scalars['ID']>>;
  name?: InputMaybe<Scalars['String']>;
  /** PM團隊 */
  productTeamIds?: InputMaybe<Array<Scalars['ID']>>;
  salesTeamGroupId?: InputMaybe<Scalars['ID']>;
  /** 業務團隊 */
  salesTeamIds?: InputMaybe<Array<Scalars['ID']>>;
  typeId?: InputMaybe<Scalars['ID']>;
  viewOrder?: InputMaybe<Scalars['Int']>;
};

export type BusinessProperty = IBusinessProperty & {
  /** 資料新增日期 */
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  /** 名稱 */
  name: Scalars['String'];
  region?: Maybe<Region>;
  salesTeamGroup?: Maybe<SalesTeamGroup>;
  /** 業務團隊組織 */
  salesTeamGroupId: Scalars['ID'];
  /** 類型 */
  typeId: Scalars['ID'];
  /** 資料修改日期 */
  updatedAt: Scalars['DateTime'];
  /** 檢視順序 */
  viewOrder?: Maybe<Scalars['Int']>;
};

export type BusinessPropertyCreateInput = {
  name: Scalars['String'];
  salesTeamGroupId: Scalars['ID'];
  typeId: Scalars['ID'];
  viewOrder?: InputMaybe<Scalars['Int']>;
};

export type BusinessPropertySearchInput = {
  id?: InputMaybe<Scalars['ID']>;
  regionIds?: InputMaybe<Array<Scalars['ID']>>;
  salesTeamGroupIds?: InputMaybe<Array<Scalars['ID']>>;
  typeId?: InputMaybe<Scalars['ID']>;
};

export type BusinessPropertySearchResult = {
  count: Scalars['Int'];
  pageInfo: PageInfo;
  rows: Array<BusinessProperty>;
};

export type BusinessPropertyType = IBusinessPropertyType & {
  /** 編號 */
  code?: Maybe<Scalars['String']>;
  /** 資料新增日期 */
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  /** 名稱 */
  name: Scalars['String'];
  properties: Array<BusinessProperty>;
  region?: Maybe<Region>;
  salesTeamGroup?: Maybe<SalesTeamGroup>;
  /** 業務團隊組織 */
  salesTeamGroupId: Scalars['ID'];
  /** 資料修改日期 */
  updatedAt: Scalars['DateTime'];
  /** 檢視順序 */
  viewOrder?: Maybe<Scalars['Int']>;
};

export type BusinessPropertyTypeCreateInput = {
  code: Scalars['String'];
  name: Scalars['String'];
  salesTeamGroupId: Scalars['ID'];
  viewOrder?: InputMaybe<Scalars['Int']>;
};

export type BusinessPropertyTypeSearchInput = {
  code?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['ID']>;
  regionIds?: InputMaybe<Array<Scalars['ID']>>;
  salesTeamGroupIds?: InputMaybe<Array<Scalars['ID']>>;
};

export type BusinessPropertyTypeSearchResult = {
  count: Scalars['Int'];
  pageInfo: PageInfo;
  rows: Array<BusinessPropertyType>;
};

export type BusinessPropertyTypeUpdateInput = {
  id: Scalars['ID'];
  name?: InputMaybe<Scalars['String']>;
  viewOrder?: InputMaybe<Scalars['Int']>;
};

export type BusinessPropertyUpdateInput = {
  id: Scalars['ID'];
  name?: InputMaybe<Scalars['String']>;
  typeId?: InputMaybe<Scalars['ID']>;
  viewOrder?: InputMaybe<Scalars['Int']>;
};

export type BusinessSalesMethod = IBusinessSalesMethod & {
  /** 編號 */
  code?: Maybe<EnumBusinessSalesMethodCode>;
  /** 資料新增日期 */
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  /** 名稱 */
  name: Scalars['String'];
  region?: Maybe<Region>;
  salesTeamGroup?: Maybe<SalesTeamGroup>;
  /** 業務團隊組織 */
  salesTeamGroupId: Scalars['ID'];
  /** 資料修改日期 */
  updatedAt: Scalars['DateTime'];
  /** 檢視順序 */
  viewOrder?: Maybe<Scalars['Int']>;
};

export type BusinessSalesMethodSearchInput = {
  id?: InputMaybe<Scalars['ID']>;
  regionIds?: InputMaybe<Array<Scalars['ID']>>;
  salesTeamGroupIds?: InputMaybe<Array<Scalars['ID']>>;
};

export type BusinessSalesMethodSearchResult = {
  count: Scalars['Int'];
  pageInfo: PageInfo;
  rows: Array<BusinessSalesMethod>;
};

/** 批次調整商機負責業務位置 */
export type BusinessSalesTeamUnitBatchUpdateInput = {
  /** 商機（複數） */
  businessIds: Array<Scalars['ID']>;
  /** 目標負責業務位置 */
  targetSalesTeamUnitId: Scalars['ID'];
};

export type BusinessSearchInput = {
  /** 欲購買商品 */
  budgetProductIds?: InputMaybe<Array<Scalars['ID']>>;
  /** 實際結束日期（起） */
  closedDate1?: InputMaybe<Scalars['Date']>;
  /** 實際結束日期（迄） */
  closedDate2?: InputMaybe<Scalars['Date']>;
  /** 編號 */
  code?: InputMaybe<Scalars['String']>;
  /** 建立日期（起） */
  createdDate1?: InputMaybe<Scalars['Date']>;
  /** 建立日期（迄） */
  createdDate2?: InputMaybe<Scalars['Date']>;
  /** 客戶統一編號 */
  customerBusinessCode?: InputMaybe<Scalars['String']>;
  /** 客戶編號 */
  customerCode?: InputMaybe<Scalars['String']>;
  /** 客戶 */
  customerId?: InputMaybe<Scalars['ID']>;
  /** 客戶（複數） */
  customerIds?: InputMaybe<Array<Scalars['ID']>>;
  /** 客戶醫事編號 */
  customerMedicalCode?: InputMaybe<Scalars['String']>;
  /** 客戶名稱 */
  customerName?: InputMaybe<Scalars['String']>;
  /** 成交商品 */
  dealProductIds?: InputMaybe<Array<Scalars['ID']>>;
  /** 經銷商 */
  dealerId?: InputMaybe<Scalars['ID']>;
  /** 預計結束日期（起） */
  expectedClosedDate1?: InputMaybe<Scalars['Date']>;
  /** 預計結束日期（迄） */
  expectedClosedDate2?: InputMaybe<Scalars['Date']>;
  /** 固定資產 */
  eyeFixedAssetId?: InputMaybe<Scalars['ID']>;
  /** 眼科報價單編號 */
  eyeQuotationOrderCode?: InputMaybe<Scalars['String']>;
  /** 眼科報價單 */
  eyeQuotationOrderId?: InputMaybe<Scalars['ID']>;
  id?: InputMaybe<Scalars['ID']>;
  /** 訂單編號（鼎新、道一雲、用友） */
  orderCode?: InputMaybe<Scalars['String']>;
  /** 主要負責業務 */
  primaryUserId?: InputMaybe<Scalars['ID']>;
  /** 主要負責業務（複數） */
  primaryUserIds?: InputMaybe<Array<Scalars['ID']>>;
  regionIds?: InputMaybe<Array<Scalars['ID']>>;
  /** 銷售方式 */
  salesMethodIds?: InputMaybe<Array<Scalars['ID']>>;
  /** 業務團隊組織 */
  salesTeamGroupIds?: InputMaybe<Array<Scalars['ID']>>;
  /** 負責業務團隊 */
  salesTeamId?: InputMaybe<Scalars['ID']>;
  /** 負責業務頂層團隊 */
  salesTeamTopId?: InputMaybe<Scalars['ID']>;
  /** 模糊搜尋: 客戶名稱、商機編號 */
  searchAll?: InputMaybe<Scalars['String']>;
  /** 狀態 */
  statusId?: InputMaybe<Scalars['ID']>;
  /** 狀態（複數） */
  statusIds?: InputMaybe<Array<Scalars['ID']>>;
  /** 狀態類型 */
  statusType?: InputMaybe<EnumBusinessStatusType>;
  /** 狀態類型（複數） */
  statusTypes?: InputMaybe<Array<EnumBusinessStatusType>>;
  /** 成交機會 */
  transactionOpportunity?: InputMaybe<EnumBusinessTransactionOpportunity>;
  /** 類型 */
  typeId?: InputMaybe<Scalars['ID']>;
  /** 更新日期（起） */
  updatedDate1?: InputMaybe<Scalars['Date']>;
  /** 更新日期（迄） */
  updatedDate2?: InputMaybe<Scalars['Date']>;
  /** 贏單機會 */
  winningOpportunityId?: InputMaybe<Scalars['ID']>;
  /** 贏單機會（複數） */
  winningOpportunityIds?: InputMaybe<Array<Scalars['ID']>>;
};

export type BusinessSearchResult = {
  count: Scalars['Int'];
  pageInfo: PageInfo;
  rows: Array<Business>;
};

export type BusinessSortInput = {
  direction: EnumSortDirection;
  name: EnumBusinessSortName;
};

export type BusinessStatus = IBusinessStatus & {
  /** 購買機會 */
  buyingOpportunity?: Maybe<Scalars['String']>;
  /** 資料新增日期 */
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  /** 名稱 */
  name: Scalars['String'];
  region?: Maybe<Region>;
  salesTeamGroup?: Maybe<SalesTeamGroup>;
  /** 業務團隊組織 */
  salesTeamGroupId: Scalars['ID'];
  /** 類型 */
  type?: Maybe<EnumBusinessStatusType>;
  /** 資料修改日期 */
  updatedAt: Scalars['DateTime'];
  /** 檢視順序 */
  viewOrder?: Maybe<Scalars['Int']>;
};

export type BusinessStatusCreateInput = {
  name: Scalars['String'];
  salesTeamGroupId: Scalars['ID'];
  type?: InputMaybe<EnumBusinessStatusType>;
  viewOrder?: InputMaybe<Scalars['Int']>;
};

export type BusinessStatusSearchInput = {
  id?: InputMaybe<Scalars['ID']>;
  regionIds?: InputMaybe<Array<Scalars['ID']>>;
  salesTeamGroupIds?: InputMaybe<Array<Scalars['ID']>>;
  type?: InputMaybe<EnumBusinessStatusType>;
};

export type BusinessStatusSearchResult = {
  count: Scalars['Int'];
  pageInfo: PageInfo;
  rows: Array<BusinessStatus>;
};

export type BusinessStatusUpdateInput = {
  id: Scalars['ID'];
  name?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<EnumBusinessStatusType>;
  viewOrder?: InputMaybe<Scalars['Int']>;
};

export type BusinessType = IBusinessType & {
  /** 資料新增日期 */
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  /** 名稱 */
  name: Scalars['String'];
  region?: Maybe<Region>;
  salesTeamGroup?: Maybe<SalesTeamGroup>;
  /** 業務團隊組織 */
  salesTeamGroupId: Scalars['ID'];
  /** 資料修改日期 */
  updatedAt: Scalars['DateTime'];
  /** 檢視順序 */
  viewOrder?: Maybe<Scalars['Int']>;
};

export type BusinessTypeCreateInput = {
  name: Scalars['String'];
  salesTeamGroupId?: InputMaybe<Scalars['Float']>;
  viewOrder?: InputMaybe<Scalars['Int']>;
};

export type BusinessTypeSearchInput = {
  id?: InputMaybe<Scalars['ID']>;
  regionIds?: InputMaybe<Array<Scalars['ID']>>;
  salesTeamGroupIds?: InputMaybe<Array<Scalars['ID']>>;
};

export type BusinessTypeSearchResult = {
  count: Scalars['Int'];
  pageInfo: PageInfo;
  rows: Array<BusinessType>;
};

export type BusinessTypeUpdateInput = {
  id: Scalars['ID'];
  name?: InputMaybe<Scalars['String']>;
  viewOrder?: InputMaybe<Scalars['Int']>;
};

export type BusinessUpdateInput = {
  /** 客戶預算金額 */
  budgetAmount?: InputMaybe<Scalars['String']>;
  /** 商機與預算商品關聯 */
  budgetProducts?: InputMaybe<Array<BusinessBudgetProductInput>>;
  /** 購買機會 */
  buyingOpportunityId?: InputMaybe<Scalars['ID']>;
  /** 實際結束日期 */
  closedDate?: InputMaybe<Scalars['Date']>;
  /** 競爭對手 */
  competitorIds?: InputMaybe<Array<Scalars['ID']>>;
  /** 內容 */
  content?: InputMaybe<Scalars['String']>;
  /** 客戶 */
  customerId?: InputMaybe<Scalars['ID']>;
  /** 雙方成交金額 */
  dealAmount?: InputMaybe<Scalars['String']>;
  /** 商機與成交商品關聯 */
  dealProducts?: InputMaybe<Array<BusinessDealProductInput>>;
  /** 經銷商 */
  dealerId?: InputMaybe<Scalars['ID']>;
  /** 預計結束日期 */
  expectedClosedDate?: InputMaybe<Scalars['Date']>;
  /** 眼科報價單編號 */
  eyeQuotationOrderCode?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
  /** 丟單改善計畫 */
  losingImprovementPlan?: InputMaybe<Scalars['String']>;
  /** 丟單原因 */
  losingReason?: InputMaybe<Scalars['String']>;
  /** 丟單原因 */
  losingReasonIds?: InputMaybe<Array<Scalars['ID']>>;
  /** 訂單編號（鼎新、道一雲、用友） */
  orderCode?: InputMaybe<Scalars['String']>;
  /** 主要聯絡人 */
  primaryContactPersonIds?: InputMaybe<Array<Scalars['ID']>>;
  /** 商機屬性 */
  propertyIds?: InputMaybe<Array<Scalars['ID']>>;
  /** 業務銷售金額 */
  saleAmount?: InputMaybe<Scalars['String']>;
  /** 銷售方式 */
  salesMethodId?: InputMaybe<Scalars['ID']>;
  /** 負責業務團隊 */
  salesTeamId?: InputMaybe<Scalars['ID']>;
  /** 業務團隊位置 */
  salesTeamUnitId?: InputMaybe<Scalars['ID']>;
  /** 狀態 */
  statusId?: InputMaybe<Scalars['ID']>;
  /** 標題 */
  title?: InputMaybe<Scalars['String']>;
  /** 類型 */
  typeId?: InputMaybe<Scalars['ID']>;
  /** 商機與負責（協助）業務關聯 */
  users?: InputMaybe<Array<BusinessUserInput>>;
  /** 贏單機會 */
  winningOpportunityId?: InputMaybe<Scalars['ID']>;
};

export type BusinessUserInput = {
  salesTeamId?: InputMaybe<Scalars['ID']>;
  userId: Scalars['ID'];
};

export type BusinessesToBudgetProduct = IBusinessesToBudgetProduct & {
  budgetProduct?: Maybe<BusinessProduct>;
  /** 預算商品 */
  budgetProductId: Scalars['ID'];
  /** 商機 */
  businessId: Scalars['ID'];
  id: Scalars['ID'];
  /** 數量 */
  qty: Scalars['Int'];
};

export type BusinessesToDealProduct = IBusinessesToDealProduct & {
  /** 商機 */
  businessId: Scalars['ID'];
  dealProduct?: Maybe<BusinessProduct>;
  /** 成交商品 */
  dealProductId: Scalars['ID'];
  id: Scalars['ID'];
  /** 數量 */
  qty: Scalars['Int'];
};

export type BusinessesUser = IBusinessesUser & {
  /** 商機 */
  business?: Maybe<Business>;
  /** 商機 */
  businessId: Scalars['ID'];
  id: Scalars['ID'];
  /** 業務團隊 */
  salesTeam?: Maybe<SalesTeam>;
  /** 業務團隊 */
  salesTeamId?: Maybe<Scalars['ID']>;
  /** 人員 */
  user?: Maybe<User>;
  /** 負責（協助）業務 */
  userId: Scalars['ID'];
};

export type BusinessesUserCreateInput = {
  businessId: Scalars['ID'];
  salesTeamId?: InputMaybe<Scalars['ID']>;
  userId: Scalars['ID'];
};

export type BusinessesUserSearchInput = {
  businessId?: InputMaybe<Scalars['ID']>;
  id?: InputMaybe<Scalars['ID']>;
  salesTeamId?: InputMaybe<Scalars['ID']>;
  userId?: InputMaybe<Scalars['ID']>;
};

export type BusinessesUserSearchResult = {
  count: Scalars['Int'];
  pageInfo: PageInfo;
  rows: Array<BusinessesUser>;
};

export type BusinessesUserUpdateInput = {
  businessId?: InputMaybe<Scalars['ID']>;
  id: Scalars['ID'];
  salesTeamId?: InputMaybe<Scalars['ID']>;
  userId?: InputMaybe<Scalars['ID']>;
};

export type City = ICity & {
  areaId?: Maybe<Scalars['ID']>;
  createdAt: Scalars['DateTime'];
  districts: Array<District>;
  id: Scalars['ID'];
  name: Scalars['String'];
  provinceId: Scalars['ID'];
  region?: Maybe<Region>;
  regionId: Scalars['ID'];
  updatedAt: Scalars['DateTime'];
  viewOrder: Scalars['Int'];
};

export type CityCreateInput = {
  /** 這是TODO */
  todo: Scalars['String'];
};

export type CitySearchInput = {
  id?: InputMaybe<Scalars['ID']>;
  name?: InputMaybe<Scalars['String']>;
  provinceId?: InputMaybe<Scalars['ID']>;
  regionIds?: InputMaybe<Array<Scalars['ID']>>;
};

export type CitySearchResult = {
  count: Scalars['Int'];
  pageInfo: PageInfo;
  rows: Array<City>;
};

export type CityUpdateInput = {
  id: Scalars['ID'];
};

export type Company = ICompany & {
  /** 公司編號 */
  code?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  depts?: Maybe<Array<Department>>;
  enabled?: Maybe<Scalars['Boolean']>;
  hrCode?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  name: Scalars['String'];
  phone?: Maybe<Scalars['String']>;
  region?: Maybe<Region>;
  regionId: Scalars['ID'];
  stores?: Maybe<Array<Store>>;
  updatedAt: Scalars['DateTime'];
  zones?: Maybe<Array<Zone>>;
};

export type CompanySearchInput = {
  code?: InputMaybe<Scalars['String']>;
  ids?: InputMaybe<Array<Scalars['ID']>>;
  regionIds?: InputMaybe<Array<Scalars['ID']>>;
};

export type CompanySearchResult = {
  count: Scalars['Int'];
  pageInfo: PageInfo;
  rows: Array<Company>;
};

export type Competitor = ICompetitor & {
  /** 對方優勢 */
  advantage?: Maybe<Scalars['String']>;
  /** 代理商 */
  agents?: Maybe<Scalars['String']>;
  /** 廠牌 */
  brand?: Maybe<Scalars['String']>;
  businessProducts: Array<BusinessProduct>;
  /** 資料新增日期 */
  createdAt: Scalars['DateTime'];
  createdUser?: Maybe<User>;
  /** 資料建立人員 */
  createdUserId?: Maybe<Scalars['ID']>;
  /** 對方劣勢 */
  disadvantage?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  /** 備註 */
  memo?: Maybe<Scalars['String']>;
  /** 機型 */
  model?: Maybe<Scalars['String']>;
  /** 名稱 */
  name: Scalars['String'];
  region?: Maybe<Region>;
  salesTeamGroup?: Maybe<SalesTeamGroup>;
  /** 業務團隊組織 */
  salesTeamGroupId: Scalars['ID'];
  /** 應對策略 */
  strategy?: Maybe<Scalars['String']>;
  /** 資料修改日期 */
  updatedAt: Scalars['DateTime'];
  updatedUser?: Maybe<User>;
  /** 資料修改人員 */
  updatedUserId?: Maybe<Scalars['ID']>;
};

export type CompetitorCreateInput = {
  /** 對方優勢 */
  advantage?: InputMaybe<Scalars['String']>;
  /** 代理商 */
  agents?: InputMaybe<Scalars['String']>;
  /** 廠牌 */
  brand?: InputMaybe<Scalars['String']>;
  businessProductIds?: InputMaybe<Array<Scalars['ID']>>;
  /** 對方劣勢 */
  disadvantage?: InputMaybe<Scalars['String']>;
  /** 備註 */
  memo?: InputMaybe<Scalars['String']>;
  /** 機型 */
  model?: InputMaybe<Scalars['String']>;
  /** 名稱 */
  name: Scalars['String'];
  /** 業務團隊組織 */
  salesTeamGroupId?: InputMaybe<Scalars['ID']>;
  /** 應對策略 */
  strategy?: InputMaybe<Scalars['String']>;
};

export type CompetitorSearchInput = {
  /** 代理商 */
  agents?: InputMaybe<Scalars['String']>;
  /** 廠牌 */
  brand?: InputMaybe<Scalars['String']>;
  /** 商機商品 */
  businessProductIds?: InputMaybe<Array<Scalars['ID']>>;
  id?: InputMaybe<Scalars['ID']>;
  /** 機型 */
  model?: InputMaybe<Scalars['String']>;
  /** 名稱 */
  name?: InputMaybe<Scalars['String']>;
  regionIds?: InputMaybe<Array<Scalars['ID']>>;
  salesTeamGroupIds?: InputMaybe<Array<Scalars['ID']>>;
};

export type CompetitorSearchResult = {
  count: Scalars['Int'];
  pageInfo: PageInfo;
  rows: Array<Competitor>;
};

export type CompetitorUpdateInput = {
  /** 對方優勢 */
  advantage?: InputMaybe<Scalars['String']>;
  /** 代理商 */
  agents?: InputMaybe<Scalars['String']>;
  /** 廠牌 */
  brand?: InputMaybe<Scalars['String']>;
  businessProductIds?: InputMaybe<Array<Scalars['ID']>>;
  /** 對方劣勢 */
  disadvantage?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
  /** 備註 */
  memo?: InputMaybe<Scalars['String']>;
  /** 機型 */
  model?: InputMaybe<Scalars['String']>;
  /** 名稱 */
  name?: InputMaybe<Scalars['String']>;
  /** 業務團隊組織 */
  salesTeamGroupId?: InputMaybe<Scalars['ID']>;
  /** 應對策略 */
  strategy?: InputMaybe<Scalars['String']>;
};

export type ContactPerson = IContactPerson & {
  /** 地址 */
  address?: Maybe<Scalars['String']>;
  /** 身分證字號／公民身份號碼 */
  citizenCode?: Maybe<Scalars['String']>;
  /** 編號（內勤系統自動添加） */
  code?: Maybe<Scalars['String']>;
  /** 資料新增日期 */
  createdAt: Scalars['DateTime'];
  createdUser?: Maybe<User>;
  /** 資料建立人員 */
  createdUserId?: Maybe<Scalars['ID']>;
  customers: Array<Customer>;
  /** 聯絡人部門 */
  department?: Maybe<ContactPersonDepartment>;
  /** 部門 */
  departmentId?: Maybe<Scalars['ID']>;
  /** 部門（棄用） */
  dept?: Maybe<Scalars['String']>;
  /** 醫師代碼 */
  doctorCode?: Maybe<Scalars['String']>;
  /** 電子信箱 */
  email?: Maybe<Scalars['String']>;
  /** 傳真電話 */
  fax?: Maybe<Scalars['String']>;
  /** 性別 */
  gender?: Maybe<EnumContactPeopleGender>;
  /** 興趣愛好 */
  hobby?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  /** 職稱 */
  jobTitle?: Maybe<Scalars['String']>;
  /** 備註 */
  memo?: Maybe<Scalars['String']>;
  /** 行動電話 */
  mobile?: Maybe<Scalars['String']>;
  /** 姓名 */
  name: Scalars['String'];
  /** 暱稱 */
  nickname?: Maybe<Scalars['String']>;
  /** 座機電話 */
  phone?: Maybe<Scalars['String']>;
  primaryUsers: Array<User>;
  /** 轉介編號 */
  referenceCode?: Maybe<Scalars['String']>;
  region?: Maybe<Region>;
  salesTeamGroup?: Maybe<SalesTeamGroup>;
  /** 業務團隊組織 */
  salesTeamGroupId: Scalars['ID'];
  salesTeamUnits: Array<SalesTeamUnit>;
  /** 專長技能 */
  skill?: Maybe<Scalars['String']>;
  /** 資料修改日期 */
  updatedAt: Scalars['DateTime'];
  updatedUser?: Maybe<User>;
  /** 資料修改人員 */
  updatedUserId?: Maybe<Scalars['ID']>;
  users: Array<User>;
  /** 網站 */
  website?: Maybe<Scalars['URL']>;
};

export type ContactPersonCreateInput = {
  address?: InputMaybe<Scalars['String']>;
  /** 身分證字號／公民身份號碼 */
  citizenCode?: InputMaybe<Scalars['String']>;
  customerIds?: InputMaybe<Array<Scalars['ID']>>;
  /** 聯絡人部門 */
  departmentId?: InputMaybe<Scalars['ID']>;
  doctorCode?: InputMaybe<Scalars['String']>;
  email?: InputMaybe<Scalars['String']>;
  fax?: InputMaybe<Scalars['String']>;
  gender?: InputMaybe<EnumContactPeopleGender>;
  hobby?: InputMaybe<Scalars['String']>;
  jobTitle?: InputMaybe<Scalars['String']>;
  memo?: InputMaybe<Scalars['String']>;
  mobile?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  nickname?: InputMaybe<Scalars['String']>;
  phone?: InputMaybe<Scalars['String']>;
  regionId: Scalars['ID'];
  salesTeamGroupId: Scalars['ID'];
  /** 業務團隊位置(複數) */
  salesTeamUnitIds?: InputMaybe<Array<Scalars['ID']>>;
  skill?: InputMaybe<Scalars['String']>;
  website?: InputMaybe<Scalars['String']>;
};

export type ContactPersonDepartment = IContactPersonDepartment & {
  /** 資料新增日期 */
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  /** 名稱 */
  name: Scalars['String'];
  region?: Maybe<Region>;
  salesTeamGroup?: Maybe<SalesTeamGroup>;
  /** 業務團隊組織 */
  salesTeamGroupId: Scalars['ID'];
  /** 資料修改日期 */
  updatedAt: Scalars['DateTime'];
  /** 檢視順序 */
  viewOrder?: Maybe<Scalars['Int']>;
};

export type ContactPersonDepartmentCreateInput = {
  /** 名稱 */
  name: Scalars['String'];
  salesTeamGroupId: Scalars['ID'];
  /** 檢視順序 */
  viewOrder?: InputMaybe<Scalars['Int']>;
};

export type ContactPersonDepartmentSearchInput = {
  id?: InputMaybe<Scalars['ID']>;
  /** 名稱 */
  name?: InputMaybe<Scalars['String']>;
  regionIds?: InputMaybe<Array<Scalars['ID']>>;
  salesTeamGroupIds?: InputMaybe<Array<Scalars['ID']>>;
};

export type ContactPersonDepartmentSearchResult = {
  count: Scalars['Int'];
  pageInfo: PageInfo;
  rows: Array<ContactPersonDepartment>;
};

export type ContactPersonDepartmentUpdateInput = {
  id: Scalars['ID'];
  /** 名稱 */
  name?: InputMaybe<Scalars['String']>;
  /** 檢視順序 */
  viewOrder?: InputMaybe<Scalars['Int']>;
};

export type ContactPersonSearchInput = {
  code?: InputMaybe<Scalars['String']>;
  customerIds?: InputMaybe<Array<Scalars['ID']>>;
  /** 聯絡人部門 */
  departmentId?: InputMaybe<Scalars['ID']>;
  doctorCode?: InputMaybe<Scalars['String']>;
  gender?: InputMaybe<EnumContactPeopleGender>;
  id?: InputMaybe<Scalars['ID']>;
  jobTitle?: InputMaybe<Scalars['String']>;
  /** 行動電話(模糊查詢) */
  mobile?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  notCustomerId?: InputMaybe<Scalars['ID']>;
  /** 座機電話(模糊查詢) */
  phone?: InputMaybe<Scalars['String']>;
  regionIds?: InputMaybe<Array<Scalars['ID']>>;
  salesTeamGroupIds?: InputMaybe<Array<Scalars['ID']>>;
};

export type ContactPersonSearchResult = {
  count: Scalars['Int'];
  pageInfo: PageInfo;
  rows: Array<ContactPerson>;
};

export type ContactPersonUpdateInput = {
  address?: InputMaybe<Scalars['String']>;
  /** 身分證字號／公民身份號碼 */
  citizenCode?: InputMaybe<Scalars['String']>;
  customerIds?: InputMaybe<Array<Scalars['ID']>>;
  /** 聯絡人部門 */
  departmentId?: InputMaybe<Scalars['ID']>;
  doctorCode?: InputMaybe<Scalars['String']>;
  email?: InputMaybe<Scalars['String']>;
  fax?: InputMaybe<Scalars['String']>;
  gender?: InputMaybe<EnumContactPeopleGender>;
  hobby?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
  jobTitle?: InputMaybe<Scalars['String']>;
  memo?: InputMaybe<Scalars['String']>;
  mobile?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  nickname?: InputMaybe<Scalars['String']>;
  phone?: InputMaybe<Scalars['String']>;
  salesTeamGroupId?: InputMaybe<Scalars['ID']>;
  /** 業務團隊位置(複數) */
  salesTeamUnitIds?: InputMaybe<Array<Scalars['ID']>>;
  skill?: InputMaybe<Scalars['String']>;
  website?: InputMaybe<Scalars['String']>;
};

export type Corporation = ICorporation & {
  /** 地址 */
  address?: Maybe<Scalars['String']>;
  code: Scalars['String'];
  createdAt: Scalars['DateTime'];
  depts: Array<Department>;
  disabled: Scalars['Boolean'];
  /** email */
  email?: Maybe<Scalars['EmailAddress']>;
  /** Facebook */
  facebook?: Maybe<Scalars['URL']>;
  /** 傳真 */
  fax?: Maybe<Scalars['PhoneNumber']>;
  /** 統一編號 */
  guiNumber?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  /** Instagram */
  instagram?: Maybe<Scalars['URL']>;
  name: Scalars['String'];
  /** 電話 */
  phone?: Maybe<Scalars['PhoneNumber']>;
  region?: Maybe<Region>;
  regionId: Scalars['ID'];
  /** 抖音 */
  tiktok?: Maybe<Scalars['URL']>;
  /** Twitter */
  twitter?: Maybe<Scalars['URL']>;
  updatedAt: Scalars['DateTime'];
  /** 官網 */
  website?: Maybe<Scalars['URL']>;
  /** 微博 */
  weibo?: Maybe<Scalars['URL']>;
  /** 用友帳套 */
  yonyouSetOfBook?: Maybe<Scalars['String']>;
};

export type CorporationCreateInput = {
  /** 公司HR Code */
  code: Scalars['String'];
  deptIds?: InputMaybe<Array<Scalars['ID']>>;
  /** 統一編號 */
  guiNumber?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  regionId: Scalars['ID'];
  /** 用友帳套 */
  yonyouSetOfBook?: InputMaybe<Scalars['String']>;
};

export type CorporationSearchInput = {
  code?: InputMaybe<Scalars['String']>;
  deptIds?: InputMaybe<Array<Scalars['ID']>>;
  /** 統一編號 */
  guiNumber?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['ID']>;
  name?: InputMaybe<Scalars['String']>;
  regionIds?: InputMaybe<Array<Scalars['ID']>>;
};

export type CorporationSearchResult = {
  count: Scalars['Int'];
  pageInfo: PageInfo;
  rows: Array<Corporation>;
};

export type CorporationUpdateInput = {
  deptIds?: InputMaybe<Array<Scalars['ID']>>;
  /** 統一編號 */
  guiNumber?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
  /** 用友帳套 */
  yonyouSetOfBook?: InputMaybe<Scalars['String']>;
};

export type CostCenter = ICostCenter & {
  /** 編號 */
  code: Scalars['String'];
  company?: Maybe<Company>;
  /** 公司 */
  companyId: Scalars['ID'];
  /** 資料新增日期 */
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  /** 是否啟用 */
  isActive?: Maybe<Scalars['Boolean']>;
  /** 名稱 */
  name: Scalars['String'];
  region?: Maybe<Region>;
  /** 區域 */
  regionId: Scalars['ID'];
  /** 資料修改日期 */
  updatedAt: Scalars['DateTime'];
};

export type CostCenterSearchInput = {
  /** 編號 */
  code?: InputMaybe<Scalars['String']>;
  /** 公司(複數) */
  companyIds?: InputMaybe<Array<Scalars['ID']>>;
  id?: InputMaybe<Scalars['ID']>;
  /** 是否啟用 */
  isActive?: InputMaybe<Scalars['Boolean']>;
  /** 名稱 */
  name?: InputMaybe<Scalars['String']>;
  /** 區域(複數) */
  regionIds?: InputMaybe<Array<Scalars['ID']>>;
};

export type CostCenterSearchResult = {
  count: Scalars['Int'];
  pageInfo: PageInfo;
  rows: Array<CostCenter>;
};

export type CountingUnit = ICountingUnit & {
  /** 編號 */
  code?: Maybe<Scalars['String']>;
  /** 資料新增日期 */
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  /** 名稱 */
  name: Scalars['String'];
  region?: Maybe<Region>;
  /** 區域 */
  regionId: Scalars['ID'];
  /** 資料修改日期 */
  updatedAt: Scalars['DateTime'];
  /** 檢視順序 */
  viewOrder?: Maybe<Scalars['Int']>;
};

export type CountingUnitSearchInput = {
  /** 編號 */
  code?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['ID']>;
  /** 區域 */
  regionId?: InputMaybe<Scalars['ID']>;
};

export type CountingUnitSearchResult = {
  count: Scalars['Int'];
  pageInfo: PageInfo;
  rows: Array<CountingUnit>;
};

export type CreateCustomerToContactPeopleInput = {
  contactPersonIds?: Array<Scalars['ID']>;
  id?: Scalars['ID'];
};

export type CreditPeriod = ICreditPeriod & {
  createdAt?: Maybe<Scalars['DateTime']>;
  /** 天數 */
  days: Scalars['Int'];
  description?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  name: Scalars['String'];
  regionId: Scalars['ID'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CreditPeriodSearchInput = {
  days?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['ID']>;
  name?: InputMaybe<Scalars['String']>;
  regionIds?: InputMaybe<Array<Scalars['ID']>>;
};

export type CreditPeriodSearchResult = {
  count: Scalars['Int'];
  pageInfo: PageInfo;
  rows: Array<CreditPeriod>;
};

export type Currency = ICurrency & {
  /** ISO 4217 currency codes */
  code: Scalars['String'];
  /** 小數點後幾位 */
  decimalPlace: Scalars['Int'];
  id: Scalars['ID'];
  name: Scalars['String'];
  /** 貨幣符號 */
  symbol: Scalars['String'];
};

export type CurrencySearchInput = {
  code?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['ID']>;
};

export type CurrencySearchResult = {
  count: Scalars['Int'];
  pageInfo: PageInfo;
  rows: Array<Currency>;
};

export type Customer = ICustomer & {
  /** 地址 */
  address?: Maybe<Scalars['String']>;
  area?: Maybe<CustomerArea>;
  /** 分區 */
  areaId?: Maybe<Scalars['ID']>;
  attachments: Array<CustomerAttachment>;
  /** 銀行戶頭代碼 */
  bankAccountCode?: Maybe<Scalars['String']>;
  /** 銀行戶頭名稱 */
  bankAccountName?: Maybe<Scalars['String']>;
  /** 開票單位名稱 */
  billingUnitName?: Maybe<Scalars['String']>;
  /** 營業統一編號 */
  businessCode?: Maybe<Scalars['String']>;
  category?: Maybe<CustomerCategory>;
  /** 分類 */
  categoryId?: Maybe<Scalars['ID']>;
  children: Array<Customer>;
  /** 市級、二級行政區 */
  cityId?: Maybe<Scalars['ID']>;
  /** 編號（鼎新／內部通用） */
  code?: Maybe<Scalars['String']>;
  contactPeople: Array<ContactPerson>;
  /** 總負責聯絡人姓名 */
  contactPersonName?: Maybe<Scalars['String']>;
  /** 總負責聯絡人電話 */
  contactPersonPhone?: Maybe<Scalars['String']>;
  /** 資料新增日期 */
  createdAt: Scalars['DateTime'];
  createdUser?: Maybe<User>;
  /** 資料建立人員 */
  createdUserId?: Maybe<Scalars['ID']>;
  /** 信用期限（科林評定） */
  creditPeriod?: Maybe<CreditPeriod>;
  /** 信用期限（科林評定） */
  creditPeriodId?: Maybe<Scalars['ID']>;
  /** 信用額度（科林評定） */
  creditQuota?: Maybe<Scalars['Float']>;
  /** 預設付款方式 */
  defaultPaymentMethod?: Maybe<PaymentMethod>;
  /** 預設付款方式 */
  defaultPaymentMethodId?: Maybe<Scalars['ID']>;
  /** 預設配送方式 */
  defaultShippingMethod?: Maybe<ShippingMethod>;
  /** 預設配送方式 */
  defaultShippingMethodId?: Maybe<Scalars['ID']>;
  /** 縣級、三級行政區 */
  districtId?: Maybe<Scalars['ID']>;
  /** 編號（道一雲） */
  do1Code?: Maybe<Scalars['String']>;
  /** 電子信箱 */
  email?: Maybe<Scalars['String']>;
  eyeQuotationOrders: Array<EyeQuotationOrder>;
  eyeServiceOrders: Array<EyeServiceOrder>;
  eyeSoldProducts: Array<EyeSoldProduct>;
  eyeWarrantyContracts: Array<EyeWarrantyContract>;
  /** 傳真電話 */
  fax?: Maybe<Scalars['String']>;
  group?: Maybe<CustomerGroup>;
  /** 集團 */
  groupId?: Maybe<Scalars['ID']>;
  /** 是否有生效的首營資料證照 */
  hasEffectiveGmpCertificate?: Maybe<Scalars['Boolean']>;
  id: Scalars['ID'];
  /** 是否啟用 */
  isActive: Scalars['Boolean'];
  /** 是否為首營客戶 */
  isGmp?: Maybe<Scalars['Boolean']>;
  /** 最近 BPM 首營資料 Instance ID */
  lastGmpBpmInstanceId?: Maybe<Scalars['ID']>;
  /** 最近 BPM 首營資料申請狀態 */
  lastGmpBpmStatus?: Maybe<EnumLastGmpBpmStatus>;
  /** 最近首營資料 BPM 連結 */
  lastGmpBpmUrl?: Maybe<Scalars['URL']>;
  lastGmpBpmUser?: Maybe<User>;
  /** 最近 BPM 首營資料申請人員 */
  lastGmpBpmUserId?: Maybe<Scalars['ID']>;
  /** 法人姓名 */
  legalPersonName?: Maybe<Scalars['String']>;
  levels: Array<CustomerLevel>;
  /** 醫事機構代碼 */
  medicalCode?: Maybe<Scalars['String']>;
  /** 備註 */
  memo?: Maybe<Scalars['String']>;
  /** 行動電話 */
  mobile?: Maybe<Scalars['String']>;
  /** 名稱 */
  name: Scalars['String'];
  /** 編號（NAV） */
  navCode?: Maybe<Scalars['String']>;
  parent?: Maybe<Customer>;
  /** 上層客戶 */
  parentId?: Maybe<Scalars['ID']>;
  /** 座機電話 */
  phone?: Maybe<Scalars['String']>;
  /** @deprecated 改用 salesTeamUnits */
  primaryUsers: Array<CustomersPrimaryUser>;
  properties: Array<CustomerProperty>;
  province?: Maybe<Province>;
  /** 省級、一級行政區 */
  provinceId?: Maybe<Scalars['ID']>;
  /** 轉介編號 */
  referenceCode?: Maybe<Scalars['String']>;
  region?: Maybe<Region>;
  salesTeamGroup?: Maybe<SalesTeamGroup>;
  /** 業務團隊組織 */
  salesTeamGroupId: Scalars['ID'];
  salesTeamUnits: Array<SalesTeamUnit>;
  /** 收貨地址 */
  shippingAddress?: Maybe<Scalars['String']>;
  /** 簡稱 */
  shortName?: Maybe<Scalars['String']>;
  /** 同步代碼 */
  syncCode?: Maybe<Scalars['String']>;
  /** 同步來源 */
  syncSourceId?: Maybe<Scalars['ID']>;
  type?: Maybe<CustomerType>;
  /** 類型 */
  typeId?: Maybe<Scalars['ID']>;
  /** 資料修改日期 */
  updatedAt: Scalars['DateTime'];
  updatedUser?: Maybe<User>;
  /** 資料修改人員 */
  updatedUserId?: Maybe<Scalars['ID']>;
  users: Array<User>;
  /** 網站 */
  website?: Maybe<Scalars['URL']>;
  /** 編號（用友） */
  yonyouCode?: Maybe<Scalars['String']>;
};

export type CustomerArea = ICustomerArea & {
  /** 資料新增日期 */
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  /** 名稱 */
  name: Scalars['String'];
  region?: Maybe<Region>;
  salesTeamGroup?: Maybe<SalesTeamGroup>;
  /** 業務團隊組織 */
  salesTeamGroupId: Scalars['ID'];
  /** 資料修改日期 */
  updatedAt: Scalars['DateTime'];
  /** 檢視順序 */
  viewOrder?: Maybe<Scalars['Int']>;
};

export type CustomerAreaCreateInput = {
  name: Scalars['String'];
  salesTeamGroupId: Scalars['ID'];
  viewOrder?: InputMaybe<Scalars['Int']>;
};

export type CustomerAreaSearchInput = {
  id?: InputMaybe<Scalars['ID']>;
  regionIds?: InputMaybe<Array<Scalars['ID']>>;
  salesTeamGroupIds?: InputMaybe<Array<Scalars['ID']>>;
};

export type CustomerAreaSearchResult = {
  count: Scalars['Int'];
  pageInfo: PageInfo;
  rows: Array<CustomerArea>;
};

export type CustomerAreaUpdateInput = {
  id: Scalars['ID'];
  name?: InputMaybe<Scalars['String']>;
  salesTeamGroupId?: InputMaybe<Scalars['ID']>;
  viewOrder?: InputMaybe<Scalars['Int']>;
};

export type CustomerAttachment = ICustomerAttachment & {
  /** 資料新增日期 */
  createdAt: Scalars['DateTime'];
  createdUser?: Maybe<User>;
  /** 資料建立人員 */
  createdUserId?: Maybe<Scalars['ID']>;
  /** 客戶 */
  customerId: Scalars['ID'];
  /** 副檔名 */
  extension: Scalars['String'];
  id: Scalars['ID'];
  /** 備註 */
  memo?: Maybe<Scalars['String']>;
  /** 名稱 */
  name: Scalars['String'];
  /** S3 Key */
  s3Key: Scalars['String'];
  /** 檔案大小(Bytes) */
  size: Scalars['Float'];
  /** 資料修改日期 */
  updatedAt: Scalars['DateTime'];
  updatedUser?: Maybe<User>;
  /** 資料修改人員 */
  updatedUserId?: Maybe<Scalars['ID']>;
  /** 短效期的S3 url */
  url?: Maybe<Scalars['URL']>;
};

export type CustomerAttachmentBulkCreateInput = {
  attachments: Array<AttachmentCreateInput>;
  customerId: Scalars['ID'];
};

export type CustomerAttachmentCreateInput = {
  customerId: Scalars['ID'];
  file: Scalars['Upload'];
  memo?: InputMaybe<Scalars['String']>;
  /** 檔案名稱 */
  name: Scalars['String'];
};

export type CustomerAttachmentSearchInput = {
  customerId?: InputMaybe<Scalars['ID']>;
  id?: InputMaybe<Scalars['ID']>;
};

export type CustomerAttachmentSearchResult = {
  count: Scalars['Int'];
  pageInfo: PageInfo;
  rows: Array<CustomerAttachment>;
};

export type CustomerAttachmentUpdateInput = {
  id: Scalars['ID'];
  memo?: InputMaybe<Scalars['String']>;
  /** 檔案名稱 */
  name?: InputMaybe<Scalars['String']>;
};

export type CustomerCategory = ICustomerCategory & {
  /** 資料新增日期 */
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  /** 名稱 */
  name: Scalars['String'];
  salesTeamGroup?: Maybe<SalesTeamGroup>;
  /** 業務團隊組織 */
  salesTeamGroupId: Scalars['ID'];
  /** 資料修改日期 */
  updatedAt: Scalars['DateTime'];
  /** 檢視順序 */
  viewOrder?: Maybe<Scalars['Int']>;
};

export type CustomerCategorySearchInput = {
  id?: InputMaybe<Scalars['ID']>;
  /** 名稱 */
  name?: InputMaybe<Scalars['String']>;
  regionIds?: InputMaybe<Array<Scalars['ID']>>;
  salesTeamGroupIds?: InputMaybe<Array<Scalars['ID']>>;
};

export type CustomerCategorySearchResult = {
  count: Scalars['Int'];
  pageInfo: PageInfo;
  rows: Array<CustomerCategory>;
};

export type CustomerCertificate = ICustomerCertificate & {
  attachments: Array<CustomerCertificateAttachment>;
  /** 證號 */
  code?: Maybe<Scalars['String']>;
  /** 資料新增日期 */
  createdAt: Scalars['DateTime'];
  createdUser?: Maybe<User>;
  /** 資料建立人員 */
  createdUserId?: Maybe<Scalars['ID']>;
  customer?: Maybe<Customer>;
  /** 客戶 */
  customerId: Scalars['ID'];
  /** 是否禁用 */
  disabled: Scalars['Boolean'];
  /** 生效日期 */
  effectiveDate?: Maybe<Scalars['Date']>;
  /** 失效日期 */
  expiryDate?: Maybe<Scalars['Date']>;
  id: Scalars['ID'];
  /** 經營範圍 */
  scope?: Maybe<Scalars['String']>;
  type?: Maybe<CustomerCertificateType>;
  /** 類型 */
  typeId: Scalars['ID'];
  /** 資料修改日期 */
  updatedAt: Scalars['DateTime'];
  updatedUser?: Maybe<User>;
  /** 資料修改人員 */
  updatedUserId?: Maybe<Scalars['ID']>;
};

export type CustomerCertificateAttachment = ICustomerCertificateAttachment & {
  /** 證照 */
  certificateId: Scalars['ID'];
  /** 資料新增日期 */
  createdAt: Scalars['DateTime'];
  createdUser?: Maybe<User>;
  /** 資料建立人員 */
  createdUserId?: Maybe<Scalars['ID']>;
  /** 副檔名 */
  extension: Scalars['String'];
  id: Scalars['ID'];
  /** 備註 */
  memo?: Maybe<Scalars['String']>;
  /** 名稱 */
  name: Scalars['String'];
  /** S3 Key */
  s3Key: Scalars['String'];
  /** 檔案大小(Bytes) */
  size: Scalars['Float'];
  /** 資料修改日期 */
  updatedAt: Scalars['DateTime'];
  updatedUser?: Maybe<User>;
  /** 資料修改人員 */
  updatedUserId?: Maybe<Scalars['ID']>;
  /** 短效期的S3 url */
  url?: Maybe<Scalars['URL']>;
};

export type CustomerCertificateAttachmentCreateInput = {
  certificateId: Scalars['ID'];
  file: Scalars['Upload'];
  memo?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
};

export type CustomerCertificateAttachmentSearchInput = {
  certificateId?: InputMaybe<Scalars['ID']>;
  id?: InputMaybe<Scalars['ID']>;
};

export type CustomerCertificateAttachmentSearchResult = {
  count: Scalars['Int'];
  pageInfo: PageInfo;
  rows: Array<CustomerCertificateAttachment>;
};

export type CustomerCertificateAttachmentUpdateInput = {
  id: Scalars['ID'];
  memo?: InputMaybe<Scalars['String']>;
  /** 檔案名稱 */
  name?: InputMaybe<Scalars['String']>;
};

export type CustomerCertificateCreateInput = {
  attachments?: InputMaybe<Array<AttachmentCreateInput>>;
  code?: InputMaybe<Scalars['String']>;
  customerId?: InputMaybe<Scalars['ID']>;
  effectiveDate?: InputMaybe<Scalars['Date']>;
  expiryDate?: InputMaybe<Scalars['Date']>;
  scope?: InputMaybe<Scalars['String']>;
  typeId?: InputMaybe<Scalars['ID']>;
};

export type CustomerCertificateSearchInput = {
  code?: InputMaybe<Scalars['String']>;
  customerId?: InputMaybe<Scalars['ID']>;
  disabled?: InputMaybe<Scalars['Boolean']>;
  effectiveDate1?: InputMaybe<Scalars['Date']>;
  effectiveDate2?: InputMaybe<Scalars['Date']>;
  expiryDate1?: InputMaybe<Scalars['Date']>;
  expiryDate2?: InputMaybe<Scalars['Date']>;
  id?: InputMaybe<Scalars['ID']>;
  scope?: InputMaybe<Scalars['String']>;
  typeId?: InputMaybe<Scalars['ID']>;
};

export type CustomerCertificateSearchResult = {
  count: Scalars['Int'];
  pageInfo: PageInfo;
  rows: Array<CustomerCertificate>;
};

export type CustomerCertificateType = ICustomerCertificateType & {
  /** 編號 */
  code?: Maybe<Scalars['String']>;
  /** 資料新增日期 */
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  /** 名稱 */
  name: Scalars['String'];
  region?: Maybe<Region>;
  salesTeamGroup?: Maybe<SalesTeamGroup>;
  /** 業務團隊組織 */
  salesTeamGroupId: Scalars['ID'];
  /** 資料修改日期 */
  updatedAt: Scalars['DateTime'];
  /** 檢視順序 */
  viewOrder?: Maybe<Scalars['Int']>;
};

export type CustomerCertificateTypeCreateInput = {
  name: Scalars['String'];
  salesTeamGroupId?: InputMaybe<Scalars['Float']>;
  viewOrder?: InputMaybe<Scalars['Int']>;
};

export type CustomerCertificateTypeSearchInput = {
  id?: InputMaybe<Scalars['ID']>;
  regionIds?: InputMaybe<Array<Scalars['ID']>>;
  salesTeamGroupIds?: InputMaybe<Array<Scalars['ID']>>;
};

export type CustomerCertificateTypeSearchResult = {
  count: Scalars['Int'];
  pageInfo: PageInfo;
  rows: Array<CustomerCertificateType>;
};

export type CustomerCertificateTypeUpdateInput = {
  id: Scalars['ID'];
  name?: InputMaybe<Scalars['String']>;
  viewOrder?: InputMaybe<Scalars['Int']>;
};

export type CustomerCertificateUpdateInput = {
  code?: InputMaybe<Scalars['String']>;
  effectiveDate?: InputMaybe<Scalars['Date']>;
  expiryDate?: InputMaybe<Scalars['Date']>;
  id: Scalars['ID'];
  scope?: InputMaybe<Scalars['String']>;
  typeId?: InputMaybe<Scalars['ID']>;
};

export type CustomerCreateInput = {
  /** 地址 */
  address?: InputMaybe<Scalars['String']>;
  /** 分區 */
  areaId?: InputMaybe<Scalars['ID']>;
  /** 銀行戶頭代碼 */
  bankAccountCode?: InputMaybe<Scalars['String']>;
  /** 銀行戶頭名稱 */
  bankAccountName?: InputMaybe<Scalars['String']>;
  /** 開票單位名稱 */
  billingUnitName?: InputMaybe<Scalars['String']>;
  /** 營業統一編號 */
  businessCode?: InputMaybe<Scalars['String']>;
  /** 分類 */
  categoryId?: InputMaybe<Scalars['ID']>;
  /** 市級、二級行政區 */
  cityId?: InputMaybe<Scalars['ID']>;
  contactPersonIds?: InputMaybe<Array<Scalars['ID']>>;
  /** 總負責聯絡人姓名 */
  contactPersonName?: InputMaybe<Scalars['String']>;
  /** 總負責聯絡人電話 */
  contactPersonPhone?: InputMaybe<Scalars['String']>;
  /** 信用期限（科林評定） */
  creditPeriodId?: InputMaybe<Scalars['ID']>;
  /** 信用額度（科林評定） */
  creditQuota?: InputMaybe<Scalars['Float']>;
  /** 預設付款方式 */
  defaultPaymentMethodId?: InputMaybe<Scalars['ID']>;
  /** 預設配送方式 */
  defaultShippingMethodId?: InputMaybe<Scalars['ID']>;
  /** 縣級、三級行政區 */
  districtId?: InputMaybe<Scalars['ID']>;
  /** 電子信箱 */
  email?: InputMaybe<Scalars['String']>;
  /** 傳真電話 */
  fax?: InputMaybe<Scalars['String']>;
  /** 集團 */
  groupId?: InputMaybe<Scalars['ID']>;
  /** 是否為首營客戶 */
  isGmp?: InputMaybe<Scalars['Boolean']>;
  /** 法人姓名 */
  legalPersonName?: InputMaybe<Scalars['String']>;
  levelIds?: InputMaybe<Array<Scalars['ID']>>;
  /** 醫事機構代碼 */
  medicalCode?: InputMaybe<Scalars['String']>;
  /** 備註 */
  memo?: InputMaybe<Scalars['String']>;
  /** 行動電話 */
  mobile?: InputMaybe<Scalars['String']>;
  /** 名稱 */
  name?: InputMaybe<Scalars['String']>;
  /** 編號（NAV） */
  navCode?: InputMaybe<Scalars['String']>;
  /** 上層客戶 */
  parentId?: InputMaybe<Scalars['ID']>;
  /** 座機電話 */
  phone?: InputMaybe<Scalars['String']>;
  propertyIds?: InputMaybe<Array<Scalars['ID']>>;
  /** 省級、一級行政區 */
  provinceId: Scalars['ID'];
  /** 轉介編號 */
  referenceCode?: InputMaybe<Scalars['String']>;
  regionId: Scalars['ID'];
  /** 業務團隊組織 */
  salesTeamGroupId: Scalars['ID'];
  /** 業務團隊位置(複數) */
  salesTeamUnitIds?: InputMaybe<Array<Scalars['ID']>>;
  /** 收貨地址 */
  shippingAddress?: InputMaybe<Scalars['String']>;
  /** 簡稱 */
  shortName?: InputMaybe<Scalars['String']>;
  /** 類型 */
  typeId?: InputMaybe<Scalars['ID']>;
  /** 網站 */
  website?: InputMaybe<Scalars['String']>;
};

export type CustomerGroup = ICustomerGroup & {
  /** 資料新增日期 */
  createdAt: Scalars['DateTime'];
  createdUser?: Maybe<User>;
  /** 資料建立人員 */
  createdUserId?: Maybe<Scalars['ID']>;
  id: Scalars['ID'];
  /** 名稱 */
  name?: Maybe<Scalars['String']>;
  region?: Maybe<Region>;
  salesTeamGroup?: Maybe<SalesTeamGroup>;
  /** 業務團隊組織 */
  salesTeamGroupId: Scalars['ID'];
  /** 資料修改日期 */
  updatedAt: Scalars['DateTime'];
  updatedUser?: Maybe<User>;
  /** 資料修改人員 */
  updatedUserId?: Maybe<Scalars['ID']>;
};

export type CustomerGroupCreateInput = {
  name?: InputMaybe<Scalars['String']>;
  salesTeamGroupId?: InputMaybe<Scalars['ID']>;
};

export type CustomerGroupSearchInput = {
  id?: InputMaybe<Scalars['ID']>;
  regionIds?: InputMaybe<Array<Scalars['ID']>>;
  salesTeamGroupIds?: InputMaybe<Array<Scalars['ID']>>;
};

export type CustomerGroupSearchResult = {
  count: Scalars['Int'];
  pageInfo: PageInfo;
  rows: Array<CustomerGroup>;
};

export type CustomerGroupUpdateInput = {
  id: Scalars['ID'];
  name?: InputMaybe<Scalars['String']>;
  salesTeamGroupId?: InputMaybe<Scalars['ID']>;
};

export type CustomerLevel = ICustomerLevel & {
  /** 資料新增日期 */
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  /** 名稱 */
  name: Scalars['String'];
  region?: Maybe<Region>;
  salesTeamGroup?: Maybe<SalesTeamGroup>;
  /** 業務團隊組織 */
  salesTeamGroupId: Scalars['ID'];
  type?: Maybe<User>;
  /** 類型 */
  typeId: Scalars['ID'];
  /** 資料修改日期 */
  updatedAt: Scalars['DateTime'];
  /** 檢視順序 */
  viewOrder?: Maybe<Scalars['Int']>;
};

export type CustomerLevelCreateInput = {
  name: Scalars['String'];
  salesTeamGroupId: Scalars['ID'];
  typeId: Scalars['ID'];
  viewOrder?: InputMaybe<Scalars['Int']>;
};

export type CustomerLevelSearchInput = {
  id?: InputMaybe<Scalars['ID']>;
  regionIds?: InputMaybe<Array<Scalars['ID']>>;
  salesTeamGroupIds?: InputMaybe<Array<Scalars['ID']>>;
  typeId?: InputMaybe<Scalars['ID']>;
};

export type CustomerLevelSearchResult = {
  count: Scalars['Int'];
  pageInfo: PageInfo;
  rows: Array<CustomerLevel>;
};

export type CustomerLevelType = ICustomerLevelType & {
  /** 編號 */
  code?: Maybe<EnumCustomerLevelTypeCode>;
  /** 資料新增日期 */
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  /** 名稱 */
  name: Scalars['String'];
  region?: Maybe<Region>;
  salesTeamGroup?: Maybe<SalesTeamGroup>;
  /** 業務團隊組織 */
  salesTeamGroupId: Scalars['ID'];
  /** 資料修改日期 */
  updatedAt: Scalars['DateTime'];
  /** 檢視順序 */
  viewOrder?: Maybe<Scalars['Int']>;
};

export type CustomerLevelTypeCreateInput = {
  code: EnumCustomerLevelTypeCode;
  name: Scalars['String'];
  salesTeamGroupId: Scalars['ID'];
  viewOrder?: InputMaybe<Scalars['Int']>;
};

export type CustomerLevelTypeSearchInput = {
  code?: InputMaybe<EnumCustomerLevelTypeCode>;
  id?: InputMaybe<Scalars['ID']>;
  regionIds?: InputMaybe<Array<Scalars['ID']>>;
  salesTeamGroupIds?: InputMaybe<Array<Scalars['ID']>>;
};

export type CustomerLevelTypeSearchResult = {
  count: Scalars['Int'];
  pageInfo: PageInfo;
  rows: Array<CustomerLevelType>;
};

export type CustomerLevelTypeUpdateInput = {
  code?: InputMaybe<EnumCustomerLevelTypeCode>;
  id: Scalars['ID'];
  name?: InputMaybe<Scalars['String']>;
  salesTeamGroupId?: InputMaybe<Scalars['ID']>;
  viewOrder?: InputMaybe<Scalars['Int']>;
};

export type CustomerLevelUpdateInput = {
  id: Scalars['ID'];
  name?: InputMaybe<Scalars['String']>;
  salesTeamGroupId?: InputMaybe<Scalars['ID']>;
  typeId?: InputMaybe<Scalars['ID']>;
  viewOrder?: InputMaybe<Scalars['Int']>;
};

export type CustomerProperty = ICustomerProperty & {
  /** 資料新增日期 */
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  /** 名稱 */
  name: Scalars['String'];
  region?: Maybe<Region>;
  salesTeamGroup?: Maybe<SalesTeamGroup>;
  /** 業務團隊組織 */
  salesTeamGroupId: Scalars['ID'];
  type?: Maybe<User>;
  /** 類型 */
  typeId: Scalars['ID'];
  /** 資料修改日期 */
  updatedAt: Scalars['DateTime'];
  /** 檢視順序 */
  viewOrder?: Maybe<Scalars['Int']>;
};

export type CustomerPropertyCreateInput = {
  name: Scalars['String'];
  salesTeamGroupId: Scalars['ID'];
  typeId: Scalars['ID'];
  viewOrder?: InputMaybe<Scalars['Int']>;
};

export type CustomerPropertySearchInput = {
  id?: InputMaybe<Scalars['ID']>;
  regionIds?: InputMaybe<Array<Scalars['ID']>>;
  salesTeamGroupIds?: InputMaybe<Array<Scalars['ID']>>;
  typeId?: InputMaybe<Scalars['ID']>;
};

export type CustomerPropertySearchResult = {
  count: Scalars['Int'];
  pageInfo: PageInfo;
  rows: Array<CustomerProperty>;
};

export type CustomerPropertyType = ICustomerPropertyType & {
  /** 編號 */
  code?: Maybe<Scalars['String']>;
  /** 資料新增日期 */
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  /** 名稱 */
  name: Scalars['String'];
  region?: Maybe<Region>;
  salesTeamGroup?: Maybe<SalesTeamGroup>;
  /** 業務團隊組織 */
  salesTeamGroupId: Scalars['ID'];
  /** 資料修改日期 */
  updatedAt: Scalars['DateTime'];
  /** 檢視順序 */
  viewOrder?: Maybe<Scalars['Int']>;
};

export type CustomerPropertyTypeCreateInput = {
  code: Scalars['String'];
  name: Scalars['String'];
  salesTeamGroupId: Scalars['ID'];
  viewOrder?: InputMaybe<Scalars['Int']>;
};

export type CustomerPropertyTypeSearchInput = {
  code?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['ID']>;
  regionIds?: InputMaybe<Array<Scalars['ID']>>;
  salesTeamGroupIds?: InputMaybe<Array<Scalars['ID']>>;
};

export type CustomerPropertyTypeSearchResult = {
  count: Scalars['Int'];
  pageInfo: PageInfo;
  rows: Array<CustomerPropertyType>;
};

export type CustomerPropertyTypeUpdateInput = {
  id: Scalars['ID'];
  name?: InputMaybe<Scalars['String']>;
  salesTeamGroupId?: InputMaybe<Scalars['ID']>;
  viewOrder?: InputMaybe<Scalars['Int']>;
};

export type CustomerPropertyUpdateInput = {
  id: Scalars['ID'];
  name?: InputMaybe<Scalars['String']>;
  salesTeamGroupId?: InputMaybe<Scalars['ID']>;
  typeId?: InputMaybe<Scalars['ID']>;
  viewOrder?: InputMaybe<Scalars['Int']>;
};

export type CustomerSearchInput = {
  /** 分區 */
  areaId?: InputMaybe<Scalars['ID']>;
  /** 開票單位名稱 */
  billingUnitName?: InputMaybe<Scalars['String']>;
  /** 營業統一編號 */
  businessCode?: InputMaybe<Scalars['String']>;
  /** 分類 */
  categoryId?: InputMaybe<Scalars['ID']>;
  /** 市級、二級行政區 */
  cityId?: InputMaybe<Scalars['ID']>;
  /** 編號（鼎新／內部通用） */
  code?: InputMaybe<Scalars['String']>;
  /** 總負責聯絡人姓名 */
  contactPersonName?: InputMaybe<Scalars['String']>;
  /** 總負責聯絡人電話 */
  contactPersonPhone?: InputMaybe<Scalars['String']>;
  /** 信用期限（科林評定） */
  creditPeriodId?: InputMaybe<Scalars['ID']>;
  /** CAS 類型的客戶等級 */
  customerLevelIdInCas?: InputMaybe<Scalars['ID']>;
  /** 儀器類型的客戶等級 */
  customerLevelIdInInstrument?: InputMaybe<Scalars['ID']>;
  /** IOL 類型的客戶等級 */
  customerLevelIdInIol?: InputMaybe<Scalars['ID']>;
  /** 客戶等級（複選） */
  customerLevelIds?: InputMaybe<Array<Scalars['ID']>>;
  /** 客戶等級類別 */
  customerLevelTypeId?: InputMaybe<Scalars['ID']>;
  /** 縣級、三級行政區 */
  districtId?: InputMaybe<Scalars['ID']>;
  /** 編號（道一雲） */
  do1Code?: InputMaybe<Scalars['String']>;
  /** 電子信箱 */
  email?: InputMaybe<Scalars['String']>;
  /** 集團 */
  groupId?: InputMaybe<Scalars['ID']>;
  /** 客戶憑證是否過期 */
  hasEffectiveGmpCertificate?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['ID']>;
  /** 是否啟用 */
  isActive?: InputMaybe<Scalars['Boolean']>;
  /** 是否為首營客戶 */
  isGmp?: InputMaybe<Scalars['Boolean']>;
  /** 最近 BPM 首營資料申請狀態 */
  lastGmpBpmStatus?: InputMaybe<EnumLastGmpBpmStatus>;
  /** 法人姓名 */
  legalPersonName?: InputMaybe<Scalars['String']>;
  /** 醫事機構代碼 */
  medicalCode?: InputMaybe<Scalars['String']>;
  /** 行動電話 */
  mobile?: InputMaybe<Scalars['String']>;
  /** 名稱 */
  name?: InputMaybe<Scalars['String']>;
  /** 編號（NAV） */
  navCode?: InputMaybe<Scalars['String']>;
  /** 上層客戶 */
  parentId?: InputMaybe<Scalars['ID']>;
  /** 座機電話 */
  phone?: InputMaybe<Scalars['String']>;
  /** 主要負責業務 */
  primaryUserIds?: InputMaybe<Array<Scalars['ID']>>;
  /** 省級、一級行政區 */
  provinceId?: InputMaybe<Scalars['ID']>;
  /** 轉介編號 */
  referenceCode?: InputMaybe<Scalars['String']>;
  regionIds?: InputMaybe<Array<Scalars['ID']>>;
  /** 業務團隊組織 */
  salesTeamGroupIds?: InputMaybe<Array<Scalars['ID']>>;
  /** 模糊搜尋: 客戶名稱、客戶編號 */
  searchAll?: InputMaybe<Scalars['String']>;
  /** 類型編號 */
  typeCode?: InputMaybe<EnumCustomerTypeCode>;
  /** 類型 */
  typeId?: InputMaybe<Scalars['ID']>;
  /** 編號（用友） */
  yonyouCode?: InputMaybe<Scalars['String']>;
};

export type CustomerSearchResult = {
  count: Scalars['Int'];
  pageInfo: PageInfo;
  rows: Array<Customer>;
};

export type CustomerType = ICustomerType & {
  /** 編號 */
  code?: Maybe<EnumCustomerTypeCode>;
  /** 資料新增日期 */
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  /** 名稱 */
  name: Scalars['String'];
  region?: Maybe<Region>;
  salesTeamGroup?: Maybe<SalesTeamGroup>;
  /** 業務團隊組織 */
  salesTeamGroupId: Scalars['ID'];
  /** 資料修改日期 */
  updatedAt: Scalars['DateTime'];
  /** 檢視順序 */
  viewOrder?: Maybe<Scalars['Int']>;
};

export type CustomerTypeCreateInput = {
  name: Scalars['String'];
  salesTeamGroupId: Scalars['ID'];
  viewOrder?: InputMaybe<Scalars['Int']>;
};

export type CustomerTypeSearchInput = {
  id?: InputMaybe<Scalars['ID']>;
  regionIds?: InputMaybe<Array<Scalars['ID']>>;
  salesTeamGroupIds?: InputMaybe<Array<Scalars['ID']>>;
};

export type CustomerTypeSearchResult = {
  count: Scalars['Int'];
  pageInfo: PageInfo;
  rows: Array<CustomerType>;
};

export type CustomerTypeUpdateInput = {
  id: Scalars['ID'];
  name?: InputMaybe<Scalars['String']>;
  salesTeamGroupId?: InputMaybe<Scalars['ID']>;
  viewOrder?: InputMaybe<Scalars['Int']>;
};

export type CustomerUpdateInput = {
  /** 地址 */
  address?: InputMaybe<Scalars['String']>;
  /** 分區 */
  areaId?: InputMaybe<Scalars['ID']>;
  /** 銀行戶頭代碼 */
  bankAccountCode?: InputMaybe<Scalars['String']>;
  /** 銀行戶頭名稱 */
  bankAccountName?: InputMaybe<Scalars['String']>;
  /** 開票單位名稱 */
  billingUnitName?: InputMaybe<Scalars['String']>;
  /** 營業統一編號 */
  businessCode?: InputMaybe<Scalars['String']>;
  /** 分類 */
  categoryId?: InputMaybe<Scalars['ID']>;
  /** 市級、二級行政區 */
  cityId?: InputMaybe<Scalars['ID']>;
  contactPersonIds?: InputMaybe<Array<Scalars['ID']>>;
  /** 總負責聯絡人姓名 */
  contactPersonName?: InputMaybe<Scalars['String']>;
  /** 總負責聯絡人電話 */
  contactPersonPhone?: InputMaybe<Scalars['String']>;
  /** 信用期限（科林評定） */
  creditPeriodId?: InputMaybe<Scalars['ID']>;
  /** 信用額度（科林評定） */
  creditQuota?: InputMaybe<Scalars['Float']>;
  /** 預設付款方式 */
  defaultPaymentMethodId?: InputMaybe<Scalars['ID']>;
  /** 預設配送方式 */
  defaultShippingMethodId?: InputMaybe<Scalars['ID']>;
  /** 縣級、三級行政區 */
  districtId?: InputMaybe<Scalars['ID']>;
  /** 電子信箱 */
  email?: InputMaybe<Scalars['String']>;
  /** 傳真電話 */
  fax?: InputMaybe<Scalars['String']>;
  /** 集團 */
  groupId?: InputMaybe<Scalars['ID']>;
  id: Scalars['ID'];
  /** 是否啟用 */
  isActive?: InputMaybe<Scalars['Boolean']>;
  /** 是否為首營客戶 */
  isGmp?: InputMaybe<Scalars['Boolean']>;
  /** 法人姓名 */
  legalPersonName?: InputMaybe<Scalars['String']>;
  levelIds?: InputMaybe<Array<Scalars['ID']>>;
  /** 醫事機構代碼 */
  medicalCode?: InputMaybe<Scalars['String']>;
  /** 備註 */
  memo?: InputMaybe<Scalars['String']>;
  /** 行動電話 */
  mobile?: InputMaybe<Scalars['String']>;
  /** 名稱 */
  name?: InputMaybe<Scalars['String']>;
  /** 編號（NAV） */
  navCode?: InputMaybe<Scalars['String']>;
  /** 上層客戶 */
  parentId?: InputMaybe<Scalars['ID']>;
  /** 座機電話 */
  phone?: InputMaybe<Scalars['String']>;
  propertyIds?: InputMaybe<Array<Scalars['ID']>>;
  /** 省級、一級行政區 */
  provinceId?: InputMaybe<Scalars['ID']>;
  /** 轉介編號 */
  referenceCode?: InputMaybe<Scalars['String']>;
  /** 業務團隊組織 */
  salesTeamGroupId?: InputMaybe<Scalars['ID']>;
  /** 業務團隊位置(複數) */
  salesTeamUnitIds?: InputMaybe<Array<Scalars['ID']>>;
  /** 收貨地址 */
  shippingAddress?: InputMaybe<Scalars['String']>;
  /** 類型 */
  typeId?: InputMaybe<Scalars['ID']>;
  /** 網站 */
  website?: InputMaybe<Scalars['String']>;
};

export type CustomersPrimaryUser = ICustomersPrimaryUser & {
  User?: Maybe<User>;
  /** 客戶 */
  customerId: Scalars['ID'];
  id: Scalars['ID'];
  salesTeam?: Maybe<SalesTeam>;
  /** 業務團隊 */
  salesTeamId?: Maybe<Scalars['ID']>;
  /** 負責業務 */
  userId: Scalars['ID'];
};

/** 批次調整客戶.業務代表 */
export type CustomersPrimaryUserBatchUpsertInput = {
  /** 指定客戶 */
  customerIds: Array<Scalars['ID']>;
  /** 指定業務團隊 */
  salesTeamId: Scalars['ID'];
  /** 目標更新對象 */
  targetUserId: Scalars['ID'];
};

/** 客戶關聯業務團隊負責人 */
export type CustomersPrimaryUsersInput = {
  salesTeamId: Scalars['ID'];
  userId: Scalars['ID'];
};

export type CustomersSalesTeamUnit = ICustomersSalesTeamUnit & {
  /** 客戶 */
  customer?: Maybe<Customer>;
  /** 客戶 */
  customerId: Scalars['ID'];
  /** 業務團隊位置 */
  salesTeamUnit?: Maybe<SalesTeamUnit>;
  /** 業務團隊單位 */
  salesTeamUnitId: Scalars['ID'];
};

/** 批次調整客戶.業務代表 */
export type CustomersSalesTeamUnitBatchUpsertInput = {
  /** 指定客戶（複選） */
  customerIds: Array<Scalars['ID']>;
  /** 指定更新業務團隊位置 */
  targetSalesTeamUnitId: Scalars['ID'];
};

export type CustomersSalesTeamUnitSearchInput = {
  /** 客戶（複選） */
  customerIds?: InputMaybe<Scalars['ID']>;
  /** 業務團隊位置（複選） */
  salesTeamUnitIds?: InputMaybe<Scalars['ID']>;
};

export type CustomersSalesTeamUnitSearchResult = {
  count: Scalars['Int'];
  pageInfo: PageInfo;
  rows: Array<CustomersSalesTeamUnit>;
};

export type DeleteCustomerToContactPeopleInput = {
  contactPersonIds?: Array<Scalars['ID']>;
  id?: Scalars['ID'];
};

export type Demo = {
  message: Scalars['String'];
};

export type Department = IDepartment & {
  /** 下層的部門 */
  children: Array<Department>;
  code?: Maybe<Scalars['String']>;
  company?: Maybe<Company>;
  companyId?: Maybe<Scalars['ID']>;
  /** 公司法人 */
  corporations: Array<Corporation>;
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  /** 是否啟用 */
  isActive: Scalars['Boolean'];
  /** 部門主管 */
  manager?: Maybe<User>;
  /** 主管 */
  managerId?: Maybe<Scalars['ID']>;
  name: Scalars['String'];
  /** 上層的部門 */
  parent?: Maybe<Department>;
  /** 上層部門 */
  parentId?: Maybe<Scalars['ID']>;
  region?: Maybe<Region>;
  regionId: Scalars['ID'];
  /** 帳套 */
  setOfBook?: Maybe<Scalars['String']>;
  updatedAt: Scalars['DateTime'];
  users?: Maybe<Array<User>>;
};

export type DeptSearchInput = {
  code?: InputMaybe<Scalars['String']>;
  companyId?: InputMaybe<Scalars['ID']>;
  id?: InputMaybe<Scalars['ID']>;
  /** 是否查詢部門Root */
  isRoot?: InputMaybe<Scalars['Boolean']>;
  /** 主管 */
  managerId?: InputMaybe<Scalars['ID']>;
  name?: InputMaybe<Scalars['String']>;
  /** 上層組織 */
  parentId?: InputMaybe<Scalars['ID']>;
  regionIds?: InputMaybe<Array<Scalars['ID']>>;
};

export type DeptSearchResult = {
  count: Scalars['Int'];
  pageInfo: PageInfo;
  rows: Array<Department>;
};

export type DispatcherUser = IDispatcherUser & {
  /** 資料新增日期 */
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  /** 是否啟用 */
  isActive?: Maybe<Scalars['Boolean']>;
  /** 資料修改日期 */
  updatedAt: Scalars['DateTime'];
  user?: Maybe<User>;
  /** 調度士 */
  userId: Scalars['ID'];
};

export type DispatcherUserSearchInput = {
  id?: InputMaybe<Scalars['ID']>;
  regionIds?: InputMaybe<Array<Scalars['ID']>>;
  userIds?: InputMaybe<Array<Scalars['ID']>>;
};

export type DispatcherUserSearchResult = {
  count: Scalars['Int'];
  pageInfo: PageInfo;
  rows: Array<DispatcherUser>;
};

export type District = IDistrict & {
  cityId?: Maybe<Scalars['ID']>;
  code?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  name: Scalars['String'];
  updatedAt: Scalars['DateTime'];
  viewOrder: Scalars['Int'];
};

export type DistrictSearchInput = {
  cityId?: InputMaybe<Scalars['ID']>;
  id?: InputMaybe<Scalars['ID']>;
};

export type DistrictSearchResult = {
  count: Scalars['Int'];
  pageInfo: PageInfo;
  rows: Array<District>;
};

export type EcDashboardAnalysis = {
  /** 銷售客戶排名 */
  salesCustomerRank: EcDashboardAnalysisSalesCustomerRank;
  /** 銷售商品排名 */
  salesMaterialRank: EcDashboardAnalysisSalesMaterialRank;
  /** 業績統計 */
  salesStat: EcDashboardAnalysisSalesStat;
  /** 拜訪客戶排名 */
  visitCustomerRank: EcDashboardAnalysisVisitCustomerRank;
  /** 拜訪目標排名 */
  visitGoalRank: EcDashboardAnalysisVisitGoalRank;
  /** 拜訪商品排名 */
  visitMentionProductRank: EcDashboardAnalysisVisitMentionProductRank;
  /** 拜訪統計 */
  visitStat: EcDashboardAnalysisVisitStat;
  /** 拜訪熱門時段 */
  visitsByDayOfWeek: EcDashboardAnalysisVisitByDayOfWeek;
};

export type EcDashboardAnalysisSalesCustomerRankArgs = {
  filters?: InputMaybe<EcDashboardAnalysisSalesCustomerRankFilterInput>;
};

export type EcDashboardAnalysisSalesMaterialRankArgs = {
  filters?: InputMaybe<EcDashboardAnalysisSalesMaterialRankFilterInput>;
};

export type EcDashboardAnalysisSalesStatArgs = {
  filters?: InputMaybe<EcDashboardAnalysisSalesStatFilterInput>;
};

export type EcDashboardAnalysisVisitCustomerRankArgs = {
  filters?: InputMaybe<EcDashboardAnalysisVisitCustomerRankFilterInput>;
};

export type EcDashboardAnalysisVisitGoalRankArgs = {
  filters?: InputMaybe<EcDashboardAnalysisVisitGoalRankFilterInput>;
};

export type EcDashboardAnalysisVisitMentionProductRankArgs = {
  filters?: InputMaybe<EcDashboardAnalysisVisitMentionProductRankFilterInput>;
};

export type EcDashboardAnalysisVisitStatArgs = {
  filters: EcDashboardAnalysisVisitStatFilterInput;
};

export type EcDashboardAnalysisVisitsByDayOfWeekArgs = {
  filters?: InputMaybe<EcDashboardAnalysisVisitByDayOfWeekFilterInput>;
};

export type EcDashboardAnalysisSalesCustomerRank = {
  /** 日期區間 */
  dateRange: EcDashboardAnalysisSalesCustomerRankDateRange;
  /** 細項統計 */
  details: Array<EcDashboardAnalysisSalesCustomerRankDetail>;
  /** 業務團隊組織 */
  salesTeamGroupId?: Maybe<Scalars['ID']>;
};

export type EcDashboardAnalysisSalesCustomerRankDateRange = {
  /** 當期（起） */
  currentDate1: Scalars['String'];
  /** 當期（迄） */
  currentDate2: Scalars['String'];
  /** 去年同期（起） */
  lastYearYoyDate1: Scalars['String'];
  /** 去年同期（迄） */
  lastYearYoyDate2: Scalars['String'];
  /** 同期（起） */
  yoyDate1: Scalars['String'];
  /** 同期（迄） */
  yoyDate2: Scalars['String'];
};

export type EcDashboardAnalysisSalesCustomerRankDetail = {
  /** 當期 銷售金額 */
  amountCurrent: Scalars['Float'];
  /** 去年同期 (YOY) 銷售金額 */
  amountLastYearYoy: Scalars['Float'];
  /** 同期 (YOY) 銷售金額 */
  amountYoy: Scalars['Float'];
  /** 客戶編號 */
  customerCode?: Maybe<Scalars['String']>;
  /** 客戶名稱 */
  customerName?: Maybe<Scalars['String']>;
  /** 當期 銷售數量 */
  qtyCurrent: Scalars['Float'];
  /** 去年同期 (YOY) 銷售數量 */
  qtyLastYearYoy: Scalars['Float'];
  /** 同期 (YOY) 銷售數量 */
  qtyYoy: Scalars['Float'];
  /** 排名 */
  rank: Scalars['Int'];
  /** 流水號 */
  seq: Scalars['Int'];
  /** 當期 拜訪次數 */
  visitCurrent: Scalars['Int'];
  /** 去年同期 (YOY) 拜訪次數 */
  visitLastYearYoy: Scalars['Int'];
  /** 同期 (YOY) 拜訪次數 */
  visitYoy: Scalars['Int'];
};

export type EcDashboardAnalysisSalesCustomerRankFilterInput = {
  /** 日期（起） */
  date1?: InputMaybe<Scalars['String']>;
  /** 日期（迄） */
  date2?: InputMaybe<Scalars['String']>;
  /** 主要負責業務 */
  primaryUserIds?: InputMaybe<Array<Scalars['ID']>>;
  /** 業務團隊組織 */
  salesTeamGroupId?: InputMaybe<Scalars['ID']>;
  /** 業務團隊 */
  salesTeamIds?: InputMaybe<Array<Scalars['ID']>>;
};

export type EcDashboardAnalysisSalesDetail = {
  /** 詳細 */
  details: Array<EcDashboardAnalysisSalesDetailDetail>;
  /** 業務團隊組織 */
  salesTeamGroup?: Maybe<SalesTeamGroup>;
  /** 業務團隊組織 */
  salesTeamGroupId?: Maybe<Scalars['ID']>;
  /** 銷售業績類型 */
  salesType?: Maybe<SalesType>;
  /** 銷售業績類型 */
  salesTypeId?: Maybe<Scalars['ID']>;
};

export type EcDashboardAnalysisSalesDetailDetail = {
  /** 帳款客戶編號 */
  accountCustomerCode?: Maybe<Scalars['String']>;
  /** 帳款客戶名稱 */
  accountCustomerName?: Maybe<Scalars['String']>;
  /** 通路編號 */
  companyCode: EnumEnumEcDashboardAnalysisSalesCompanyCode;
  /** 料件編號 */
  materialCode?: Maybe<Scalars['String']>;
  /** 料件名稱 */
  materialName?: Maybe<Scalars['String']>;
  /** 數量 */
  qty?: Maybe<Scalars['Float']>;
  /** 銷售／銷退客戶編號 */
  salesCustomerCode?: Maybe<Scalars['String']>;
  /** 銷售／銷退客戶名稱 */
  salesCustomerName?: Maybe<Scalars['String']>;
  /** 銷售／銷退日期 */
  salesDate?: Maybe<Scalars['String']>;
  /** 銷售／銷退單號 */
  salesOrderCode?: Maybe<Scalars['String']>;
  /** 銷售／銷退類型 */
  salesType: EnumEcDashboardAnalysisOrderType;
  /** 含稅金額（總價） */
  taxedAmount?: Maybe<Scalars['Float']>;
  /** 未稅金額（總價） */
  untaxedAmount?: Maybe<Scalars['Float']>;
  /** 開單人員編號 */
  userCode?: Maybe<Scalars['String']>;
  /** 開單人員姓名 */
  userName?: Maybe<Scalars['String']>;
};

export type EcDashboardAnalysisSalesDetailFilterInput = {
  /** 日期（起） */
  date1: Scalars['String'];
  /** 日期（迄） */
  date2: Scalars['String'];
  /** 主要負責業務 */
  primaryUserIds?: InputMaybe<Array<Scalars['ID']>>;
  /** 業務團隊組織 */
  salesTeamGroupId?: InputMaybe<Scalars['ID']>;
  /** 業務團隊 */
  salesTeamIds?: InputMaybe<Array<Scalars['ID']>>;
  /** 銷售類型 */
  salesTypeId?: InputMaybe<Scalars['ID']>;
};

export type EcDashboardAnalysisSalesMaterialRank = {
  /** 日期區間 */
  dateRange: EcDashboardAnalysisSalesMaterialRankDateRange;
  /** 細項統計 */
  details: Array<EcDashboardAnalysisSalesMaterialRankDetail>;
  /** 業務團隊組織 */
  salesTeamGroupId?: Maybe<Scalars['ID']>;
};

export type EcDashboardAnalysisSalesMaterialRankDateRange = {
  /** 當期（起） */
  currentDate1: Scalars['String'];
  /** 當期（迄） */
  currentDate2: Scalars['String'];
  /** 去年同期（起） */
  lastYearYoyDate1: Scalars['String'];
  /** 去年同期（迄） */
  lastYearYoyDate2: Scalars['String'];
  /** 同期（起） */
  yoyDate1: Scalars['String'];
  /** 同期（迄） */
  yoyDate2: Scalars['String'];
};

export type EcDashboardAnalysisSalesMaterialRankDetail = {
  /** 當期 銷售金額 */
  amountCurrent: Scalars['Float'];
  /** 去年同期 (YOY) 銷售金額 */
  amountLastYearYoy: Scalars['Float'];
  /** 同期 (YOY) 銷售金額 */
  amountYoy: Scalars['Float'];
  /** 料件編號 */
  materialCode?: Maybe<Scalars['String']>;
  /** 料件名稱 */
  materialName?: Maybe<Scalars['String']>;
  /** 當期 銷售數量 */
  qtyCurrent: Scalars['Float'];
  /** 去年同期 (YOY) 銷售數量 */
  qtyLastYearYoy: Scalars['Float'];
  /** 同期 (YOY) 銷售數量 */
  qtyYoy: Scalars['Float'];
  /** 排名 */
  rank: Scalars['Int'];
  /** 流水號 */
  seq: Scalars['Int'];
};

export type EcDashboardAnalysisSalesMaterialRankFilterInput = {
  /** 日期（起） */
  date1?: InputMaybe<Scalars['String']>;
  /** 日期（迄） */
  date2?: InputMaybe<Scalars['String']>;
  /** 主要負責業務 */
  primaryUserIds?: InputMaybe<Array<Scalars['ID']>>;
  /** 業務團隊組織 */
  salesTeamGroupId?: InputMaybe<Scalars['ID']>;
  /** 業務團隊 */
  salesTeamIds?: InputMaybe<Array<Scalars['ID']>>;
};

export type EcDashboardAnalysisSalesStat = {
  /** 日期區間 */
  dateRange: EcDashboardAnalysisSalesStatDateRange;
  /** 細項統計 */
  details: Array<EcDashboardAnalysisSalesStatDetail>;
  /** 業務團隊組織 */
  salesTeamGroupId?: Maybe<Scalars['ID']>;
};

export type EcDashboardAnalysisSalesStatDateRange = {
  /** 當期（起） */
  currentDate1: Scalars['String'];
  /** 當期（迄） */
  currentDate2: Scalars['String'];
  /** 去年至去年當天（起） */
  lastYearYtdDate1: Scalars['String'];
  /** 去年至去年當天（迄） */
  lastYearYtdDate2: Scalars['String'];
  /** 前期（起） */
  momDate1: Scalars['String'];
  /** 前期（迄） */
  momDate2: Scalars['String'];
  /** 同期（起） */
  yoyDate1: Scalars['String'];
  /** 同期（迄） */
  yoyDate2: Scalars['String'];
  /** 今年至今（起） */
  ytdDate1: Scalars['String'];
  /** 今年至今（迄） */
  ytdDate2: Scalars['String'];
};

export type EcDashboardAnalysisSalesStatDetail = {
  /** 當期 金額 */
  amountCurrent: Scalars['Float'];
  /** 當期 目標金額 */
  amountGoalCurrent?: Maybe<Scalars['Float']>;
  /** 當期 金額目標達成率（1: 100%, 0.1234: 12.34%, 0: 0%）。分母（總計金額）為 0 時，百分比顯示為空值 */
  amountGoalCurrentAchievementRate?: Maybe<Scalars['Float']>;
  /** 去年至去年當天 金額 */
  amountLastYearYtd: Scalars['Float'];
  /** 去年至去年當天 金額成長率（1: 100%, 0.1234: 12.34%, 0: 0%）。分母為 0 時，百分比顯示為空值 */
  amountLastYearYtdGrowthRate?: Maybe<Scalars['Float']>;
  /** 前期 (MOM) 金額 */
  amountMom: Scalars['Float'];
  /** 前期 (MOM) 金額成長率（1: 100%, 0.1234: 12.34%, 0: 0%）。分母為 0 時，百分比顯示為空值 */
  amountMomGrowthRate?: Maybe<Scalars['Float']>;
  /** 同期 (YOY) 金額 */
  amountYoy: Scalars['Float'];
  /** 同期 (YOY) 金額成長率（1: 100%, 0.1234: 12.34%, 0: 0%）。分母為 0 時，百分比顯示為空值 */
  amountYoyGrowthRate?: Maybe<Scalars['Float']>;
  /** 今年至今 (YTD) 金額 */
  amountYtd: Scalars['Float'];
  salesType?: Maybe<SalesType>;
  /** 銷售業績類型 */
  salesTypeId: Scalars['ID'];
};

export type EcDashboardAnalysisSalesStatFilterInput = {
  /** 日期（起） */
  date1?: InputMaybe<Scalars['String']>;
  /** 日期（迄） */
  date2?: InputMaybe<Scalars['String']>;
  /** 主要負責業務 */
  primaryUserIds?: InputMaybe<Array<Scalars['ID']>>;
  /** 業務團隊組織 */
  salesTeamGroupId?: InputMaybe<Scalars['ID']>;
  /** 業務團隊 */
  salesTeamIds?: InputMaybe<Array<Scalars['ID']>>;
};

export type EcDashboardAnalysisVisitByDayOfWeek = {
  /** 細項統計 */
  details: Array<EcDashboardAnalysisVisitByDayOfWeekDetail>;
  /** 業務團隊組織 */
  salesTeamGroupId?: Maybe<Scalars['ID']>;
};

export type EcDashboardAnalysisVisitByDayOfWeekDetail = {
  /** 星期 */
  dayOfWeek: EnumVisitByDayOfWeek;
  /** 拜訪次數 */
  visitCountCurrent: Scalars['Int'];
};

export type EcDashboardAnalysisVisitByDayOfWeekFilterInput = {
  /** 日期（起） */
  date1?: InputMaybe<Scalars['String']>;
  /** 日期（迄） */
  date2?: InputMaybe<Scalars['String']>;
  /** 主要負責業務 */
  primaryUserIds?: InputMaybe<Array<Scalars['ID']>>;
  /** 業務團隊組織 */
  salesTeamGroupId?: InputMaybe<Scalars['ID']>;
  /** 業務團隊 */
  salesTeamIds?: InputMaybe<Array<Scalars['ID']>>;
};

export type EcDashboardAnalysisVisitCustomerRank = {
  /** 日期區間 */
  dateRange: EcDashboardAnalysisVisitCustomerRankDateRange;
  /** 細項統計 */
  details: Array<EcDashboardAnalysisVisitCustomerRankDetail>;
  /** 業務團隊組織 */
  salesTeamGroupId?: Maybe<Scalars['ID']>;
};

export type EcDashboardAnalysisVisitCustomerRankDateRange = {
  /** 當期（起） */
  currentDate1: Scalars['String'];
  /** 當期（迄） */
  currentDate2: Scalars['String'];
  /** 同期（起） */
  yoyDate1: Scalars['String'];
  /** 同期（迄） */
  yoyDate2: Scalars['String'];
};

export type EcDashboardAnalysisVisitCustomerRankDetail = {
  customer?: Maybe<Customer>;
  /** 客戶 */
  customerId: Scalars['ID'];
  /** 排名 */
  rank: Scalars['Int'];
  /** 流水號 */
  seq: Scalars['Int'];
  /** 當期 拜訪次數 */
  visitCountCurrent: Scalars['Int'];
  /** 同期 (YOY) 拜訪次數 */
  visitCountYoy: Scalars['Int'];
};

export type EcDashboardAnalysisVisitCustomerRankFilterInput = {
  /** 日期（起） */
  date1?: InputMaybe<Scalars['String']>;
  /** 日期（迄） */
  date2?: InputMaybe<Scalars['String']>;
  /** 主要負責業務 */
  primaryUserIds?: InputMaybe<Array<Scalars['ID']>>;
  /** 業務團隊組織 */
  salesTeamGroupId?: InputMaybe<Scalars['ID']>;
  /** 業務團隊 */
  salesTeamIds?: InputMaybe<Array<Scalars['ID']>>;
};

export type EcDashboardAnalysisVisitGoalRank = {
  /** 日期區間 */
  dateRange: EcDashboardAnalysisVisitGoalRankDateRange;
  /** 細項統計 */
  details: Array<EcDashboardAnalysisVisitGoalRankDetail>;
  /** 業務團隊組織 */
  salesTeamGroupId?: Maybe<Scalars['ID']>;
};

export type EcDashboardAnalysisVisitGoalRankDateRange = {
  /** 當期（起） */
  currentDate1: Scalars['String'];
  /** 當期（迄） */
  currentDate2: Scalars['String'];
  /** 同期（起） */
  yoyDate1: Scalars['String'];
  /** 同期（迄） */
  yoyDate2: Scalars['String'];
};

export type EcDashboardAnalysisVisitGoalRankDetail = {
  goal?: Maybe<VisitGoal>;
  /** 涉及商品 */
  goalId: Scalars['ID'];
  /** 排名 */
  rank: Scalars['Int'];
  /** 流水號 */
  seq: Scalars['Int'];
  /** 當期 拜訪次數 */
  visitCountCurrent: Scalars['Int'];
  /** 同期 (YOY) 拜訪次數 */
  visitCountYoy: Scalars['Int'];
};

export type EcDashboardAnalysisVisitGoalRankFilterInput = {
  /** 日期（起） */
  date1?: InputMaybe<Scalars['String']>;
  /** 日期（迄） */
  date2?: InputMaybe<Scalars['String']>;
  /** 主要負責業務 */
  primaryUserIds?: InputMaybe<Array<Scalars['ID']>>;
  /** 業務團隊組織 */
  salesTeamGroupId?: InputMaybe<Scalars['ID']>;
  /** 業務團隊 */
  salesTeamIds?: InputMaybe<Array<Scalars['ID']>>;
};

export type EcDashboardAnalysisVisitMentionProductRank = {
  /** 日期區間 */
  dateRange: EcDashboardAnalysisVisitMentionProductRankDateRange;
  /** 細項統計 */
  details: Array<EcDashboardAnalysisVisitMentionProductRankDetail>;
  /** 業務團隊組織 */
  salesTeamGroupId?: Maybe<Scalars['ID']>;
};

export type EcDashboardAnalysisVisitMentionProductRankDateRange = {
  /** 當期（起） */
  currentDate1: Scalars['String'];
  /** 當期（迄） */
  currentDate2: Scalars['String'];
  /** 同期（起） */
  yoyDate1: Scalars['String'];
  /** 同期（迄） */
  yoyDate2: Scalars['String'];
};

export type EcDashboardAnalysisVisitMentionProductRankDetail = {
  mentionProduct?: Maybe<BusinessProduct>;
  /** 涉及商品 */
  mentionProductId: Scalars['ID'];
  /** 排名 */
  rank: Scalars['Int'];
  /** 流水號 */
  seq: Scalars['Int'];
  /** 當期 拜訪次數 */
  visitCountCurrent: Scalars['Int'];
  /** 同期 (YOY) 拜訪次數 */
  visitCountYoy: Scalars['Int'];
};

export type EcDashboardAnalysisVisitMentionProductRankFilterInput = {
  /** 日期（起） */
  date1?: InputMaybe<Scalars['String']>;
  /** 日期（迄） */
  date2?: InputMaybe<Scalars['String']>;
  /** 主要負責業務 */
  primaryUserIds?: InputMaybe<Array<Scalars['ID']>>;
  /** 業務團隊組織 */
  salesTeamGroupId?: InputMaybe<Scalars['ID']>;
  /** 業務團隊 */
  salesTeamIds?: InputMaybe<Array<Scalars['ID']>>;
};

export type EcDashboardAnalysisVisitStat = {
  /** 日期區間 */
  dateRange: EcDashboardAnalysisVisitStatDateRange;
  /** 總體統計 */
  overall: EcDashboardAnalysisVisitStatOverall;
  /** 業務團隊組織 */
  salesTeamGroupId?: Maybe<Scalars['ID']>;
};

export type EcDashboardAnalysisVisitStatDateRange = {
  /** 當期（起） */
  currentDate1: Scalars['String'];
  /** 當期（迄） */
  currentDate2: Scalars['String'];
  /** 去年至去年當天（起） */
  lastYearYtdDate1: Scalars['String'];
  /** 去年至去年當天（迄） */
  lastYearYtdDate2: Scalars['String'];
  /** 前期（起） */
  momDate1: Scalars['String'];
  /** 前期（迄） */
  momDate2: Scalars['String'];
  /** 同期（起） */
  yoyDate1: Scalars['String'];
  /** 同期（迄） */
  yoyDate2: Scalars['String'];
  /** 今年至今（起） */
  ytdDate1: Scalars['String'];
  /** 今年至今（迄） */
  ytdDate2: Scalars['String'];
};

export type EcDashboardAnalysisVisitStatFilterInput = {
  /** 日期（起） */
  date1?: InputMaybe<Scalars['String']>;
  /** 日期（迄） */
  date2?: InputMaybe<Scalars['String']>;
  /** 主要負責業務 */
  primaryUserIds?: InputMaybe<Array<Scalars['ID']>>;
  /** 業務團隊組織 */
  salesTeamGroupId?: InputMaybe<Scalars['ID']>;
  /** 業務團隊 */
  salesTeamIds?: InputMaybe<Array<Scalars['ID']>>;
};

export type EcDashboardAnalysisVisitStatOverall = {
  /** 當期 拜訪次數 */
  visitCountCurrent: Scalars['Int'];
  /** 去年至去年當天 拜訪次數 */
  visitCountLastYearYtd: Scalars['Int'];
  /** 去年至去年當天 拜訪次數成長率（1: 100%, 0.1234: 12.34%, 0: 0%）。分母為 0 時，百分比顯示為空值 */
  visitCountLastYearYtdGrowthRate?: Maybe<Scalars['Float']>;
  /** 前期 (MOM) 拜訪次數 */
  visitCountMom: Scalars['Int'];
  /** 前期 (MOM) 拜訪次數成長率（1: 100%, 0.1234: 12.34%, 0: 0%）。分母為 0 時，百分比顯示為空值 */
  visitCountMomGrowthRate?: Maybe<Scalars['Float']>;
  /** 同期 (YOY) 拜訪次數 */
  visitCountYoy: Scalars['Int'];
  /** 同期 (YOY) 拜訪次數成長率（1: 100%, 0.1234: 12.34%, 0: 0%）。分母為 0 時，百分比顯示為空值 */
  visitCountYoyGrowthRate?: Maybe<Scalars['Float']>;
  /** 今年至今 (YTD) 拜訪次數 */
  visitCountYtd: Scalars['Int'];
};

export type EcInventoryDetail = {
  /** 客戶編號 */
  customerCode?: Maybe<Scalars['String']>;
  /** 客戶名稱 */
  customerName?: Maybe<Scalars['String']>;
  /** 入庫日 */
  limitDate?: Maybe<Scalars['String']>;
  /** 料件編號 */
  materialCode: Scalars['String'];
  /** 料件名稱 */
  materialName: Scalars['String'];
  /** 型號 */
  model: Scalars['String'];
  /** 庫存日 */
  orderDate?: Maybe<Scalars['String']>;
  /** 數量 */
  qty: Scalars['Int'];
  /** 暫借週期 */
  rentalPeriod?: Maybe<EnumEcInventoryRentalPeriod>;
  /** 規格 */
  spec: Scalars['String'];
  /** 狀態 */
  status?: Maybe<EnumEcInventoryStatus>;
  /** 貨倉 */
  warehouse?: Maybe<EnumEcInventoryWarehouse>;
};

export type EcInventoryDetailFilterInput = {
  /** 客戶編號 */
  customerCode?: InputMaybe<Scalars['String']>;
  /** 客戶名稱 */
  customerName?: InputMaybe<Scalars['String']>;
  /** 型號 */
  modelIds?: InputMaybe<Array<Scalars['ID']>>;
  /** 暫借週期 */
  rentalPeriods?: InputMaybe<Array<EnumEcInventoryRentalPeriod>>;
  /** 規格 */
  specIds?: InputMaybe<Array<Scalars['ID']>>;
  /** 狀態 */
  statuses?: InputMaybe<Array<EnumEcInventoryStatus>>;
  /** 貨倉 */
  warehouseIds?: InputMaybe<Array<Scalars['ID']>>;
};

export type EcInventoryModel = {
  /** 編號 */
  id: Scalars['ID'];
  /** 名稱 */
  name?: Maybe<Scalars['String']>;
};

export type EcInventorySpec = {
  /** 編號 */
  id: Scalars['ID'];
  /** 型號 */
  model?: Maybe<EcInventoryModel>;
  /** 型號 */
  modelId: Scalars['ID'];
  /** 名稱 */
  name?: Maybe<Scalars['String']>;
};

export type EcInventoryStat = {
  /** 詳細 */
  details: Array<EcInventoryStatDetail>;
};

export type EcInventoryStatDetail = {
  /** 型號 */
  modelId: Scalars['ID'];
  /** 數量 */
  qty: Scalars['Int'];
  /** 暫借週期 */
  rentalPeriods: Array<EcInventoryStatDetailRentalPeriod>;
  /** 貨倉 */
  warehouses: Array<EcInventoryStatDetailWarehouse>;
};

export type EcInventoryStatDetailRentalPeriod = {
  /** 數量 */
  qty: Scalars['Int'];
  /** 暫借週期 */
  rentalPeriod: EnumEcInventoryRentalPeriod;
};

export type EcInventoryStatDetailWarehouse = {
  /** 數量 */
  qty: Scalars['Int'];
  /** 狀態 */
  statuses: Array<EcInventoryStatDetailWarehouseStatus>;
  /** 貨倉 */
  warehouse: EnumEcInventoryWarehouse;
};

export type EcInventoryStatDetailWarehouseStatus = {
  /** 數量 */
  qty: Scalars['Int'];
  /** 狀態 */
  status: EnumEcInventoryStatus;
};

export type EcInventoryStatFilterInput = {
  /** 客戶編號 */
  customerCode?: InputMaybe<Scalars['String']>;
  /** 客戶名稱 */
  customerName?: InputMaybe<Scalars['String']>;
  /** 型號 */
  modelIds?: InputMaybe<Array<Scalars['ID']>>;
  /** 規格 */
  specIds?: InputMaybe<Array<Scalars['ID']>>;
};

export type EcInventoryWarehouse = {
  /** 編號 */
  id: Scalars['ID'];
  /** 名稱 */
  name?: Maybe<Scalars['String']>;
};

export enum EnumBusinessSalesMethodCode {
  /** 直銷 */
  DirectSelling = 'DirectSelling',
  /** 分銷 */
  Distribution = 'Distribution',
}

/** 商機排序名稱 */
export enum EnumBusinessSortName {
  /** 預算商品_名稱 */
  BudgetProductName = 'BudgetProductName',
  /** 編號 */
  Code = 'Code',
  /** 客戶_名稱 */
  CustomerName = 'CustomerName',
  /** 預計結束日期 */
  ExpectedClosedDate = 'ExpectedClosedDate',
  /** 主要負責業務_編號 */
  PrimaryUserCode = 'PrimaryUserCode',
  /** 主要負責業務_姓名 */
  PrimaryUserName = 'PrimaryUserName',
  /** 主要負責業務_業務團隊_名稱 */
  PrimaryUserSalesTeamName = 'PrimaryUserSalesTeamName',
  /** 成單機會_購買機會 */
  StatusBuyingOpportunity = 'StatusBuyingOpportunity',
  /** 贏單機會_名稱 */
  WinningOpportunityName = 'WinningOpportunityName',
}

/** 商機統計類型 */
export enum EnumBusinessStatType {
  /** 跟進中（成交機會：高_99%成單_99%贏單） */
  HighOpportunity = 'HighOpportunity',
  /** 跟進中 */
  InProgress = 'InProgress',
  /** 丟單 */
  Losing = 'Losing',
  /** 跟進中（成交機會：中高_75%/99%成單_99/75%贏單） */
  MediumHighOpportunity = 'MediumHighOpportunity',
  /** 跟進中（成交機會：中高_75%成單_99%贏單） */
  MediumHighOpportunity_75_99 = 'MediumHighOpportunity_75_99',
  /** 跟進中（成交機會：中高_99%成單_75%贏單） */
  MediumHighOpportunity_99_75 = 'MediumHighOpportunity_99_75',
  /** 跟進中（成交機會：中_75%成單_75%贏單） */
  MediumOpportunity = 'MediumOpportunity',
  /** 跟進中（成交機會：其他） */
  OtherOpportunity = 'OtherOpportunity',
  /** 其他（髒資料） */
  Others = 'Others',
  /** 贏單 */
  Winning = 'Winning',
}

export enum EnumBusinessStatusType {
  /** 已結案（丟單） */
  ClosedInLosing = 'ClosedInLosing',
  /** 已結案（其他原因） */
  ClosedInOthers = 'ClosedInOthers',
  /** 已結案（贏單） */
  ClosedInWinning = 'ClosedInWinning',
  /** 進行中 */
  InProgress = 'InProgress',
}

/** 成交機會 */
export enum EnumBusinessTransactionOpportunity {
  /** 高 */
  High = 'High',
  /** 中 */
  Medium = 'Medium',
  /** 中高 */
  MediumHigh = 'MediumHigh',
  /** 其他 */
  Others = 'Others',
}

export enum EnumContactPeopleGender {
  /** 女性 */
  Female = 'Female',
  /** 男性 */
  Male = 'Male',
  /** 其他 */
  Other = 'Other',
}

export enum EnumCustomerLevelTypeCode {
  /** CAS */
  Cas = 'Cas',
  /** 儀器 */
  Instrument = 'Instrument',
  /** IOL */
  Iol = 'Iol',
}

export enum EnumCustomerTypeCode {
  /** 經銷商 */
  Dealer = 'Dealer',
  /** 醫療機構 */
  MedicalInstitution = 'MedicalInstitution',
}

/** 訂單類型 */
export enum EnumEcDashboardAnalysisOrderType {
  /** 固資 */
  FixedAsset = 'FixedAsset',
  /** 銷退 */
  SalesReturn = 'SalesReturn',
  /** 銷貨 */
  ShippingOrder = 'ShippingOrder',
}

/** 科明庫存暫借週期 */
export enum EnumEcInventoryRentalPeriod {
  /** 30 < n ≤ 45 天 */
  From31To45Days = 'From31To45Days',
  /** 45 < n 天 */
  From46Days = 'From46Days',
  /** n ≤ 30 天 */
  To30Days = 'To30Days',
}

/** 科明庫存狀態 */
export enum EnumEcInventoryStatus {
  /** 已過期 < 0 月 */
  Expired = 'Expired',
  /** 需監控 < 6 月 */
  MustBeMonitored = 'MustBeMonitored',
  /** 正常品 ≥ 12 月 */
  Normal = 'Normal',
  /** 將過期 < 6 月 */
  WillBeExpired = 'WillBeExpired',
}

/** 科明庫存貨倉 */
export enum EnumEcInventoryWarehouse {
  /** 暫借 */
  Borrow = 'Borrow',
  /** 辦公室(中) */
  Middle = 'Middle',
  /** 寄銷倉 */
  North = 'North',
  /** 辦公室(南) */
  South = 'South',
  /** 五股倉 */
  Wugu = 'Wugu',
}

/** 通路編號 */
export enum EnumEnumEcDashboardAnalysisSalesCompanyCode {
  /** 科明 */
  Ec = 'EC',
  /** 明睿 */
  Mr = 'MR',
}

export enum EnumEyeFixedAssetRentalObjectCode {
  /** 客户 */
  Customer = 'Customer',
  /** 展场 */
  Exhibition = 'Exhibition',
  /** 其他 */
  Others = 'Others',
}

export enum EnumEyeFixedAssetServiceProviderCode {
  /** 工程师 */
  Engineers = 'Engineers',
  /** 业务 */
  Sales = 'Sales',
}

export enum EnumEyeFixedAssetTypeCode {
  /** 展示機 */
  DemoMachines = 'DemoMachines',
  /** 一般固資 */
  GeneralFixedAssets = 'GeneralFixedAssets',
}

export enum EnumEyeFixedAssetsRentalStatus {
  /** 租借中 */
  Booking = 'Booking',
  /** 取消 */
  Cancel = 'Cancel',
  /** 已歸還 */
  Returned = 'Returned',
  /** 待租借 */
  Waiting = 'Waiting',
}

export enum EnumEyeFixedAssetsStatus {
  /** 無效 */
  Disabled = 'Disabled',
  /** 有效 */
  Enabled = 'Enabled',
}

export enum EnumEyeProductStatus {
  /** 不可販售 */
  Disabled = 'Disabled',
  /** 可販售 */
  Enabled = 'Enabled',
}

export enum EnumEyeQuotationOrderOfficialSealStatus {
  /** 完成 */
  Approved = 'Approved',
  /** 取消 */
  Canceled = 'Canceled',
  /** 送簽中 */
  Processing = 'Processing',
  /** 拒絕 */
  Rejected = 'Rejected',
}

export enum EnumEyeQuotationOrderStatus {
  /** 完成 */
  Approved = 'Approved',
  /** 取消 */
  Canceled = 'Canceled',
  /** 送簽中 */
  Processing = 'Processing',
  /** 拒絕 */
  Rejected = 'Rejected',
}

export enum EnumEyeQuotationOrderType {
  /** 維修 */
  Repair = 'Repair',
  /** 銷售 */
  Sale = 'Sale',
}

export enum EnumEyeServiceOrderApprovalStatus {
  /** 已核准 */
  Approved = 'Approved',
  /** 取消 */
  Canceled = 'Canceled',
  /** 核准中 */
  Processing = 'Processing',
  /** 拒絕 */
  Rejected = 'Rejected',
}

export enum EnumEyeServiceOrderExtensionApprovalStatus {
  /** 已核准 */
  Approved = 'Approved',
  /** 取消 */
  Canceled = 'Canceled',
  /** 核准中 */
  Processing = 'Processing',
  /** 拒絕 */
  Rejected = 'Rejected',
}

export enum EnumEyeServiceOrderPriority {
  /** 高 */
  High = 'High',
  /** 低 */
  Low = 'Low',
  /** 中 */
  Middle = 'Middle',
}

export enum EnumEyeServiceOrderStatus {
  /** 驗收中 */
  Acceptance = 'Acceptance',
  /** 已完工 */
  Done = 'Done',
  /** 新建立 */
  New = 'New',
  /** 等待中 */
  Pendding = 'Pendding',
  /** 處理中 */
  Processing = 'Processing',
}

export enum EnumEyeServiceOrderTypeCode {
  /** 固資租借 */
  FixedAssetRental = 'FixedAssetRental',
  /** 一般維修 */
  GeneralMaintenance = 'GeneralMaintenance',
  /** 保固保養 */
  WarrantyMaintenance = 'WarrantyMaintenance',
}

export enum EnumEyeServiceOrderWorkDiaryStatus {
  /** 取消 */
  Cancel = 'Cancel',
  /** 已完成 */
  Done = 'Done',
  /** 新建立 */
  New = 'New',
}

export enum EnumEyeWarrantyContractStatus {
  /** 草稿 */
  Draft = 'Draft',
  /** 有效 */
  Enabled = 'Enabled',
  /** 作廢 */
  Void = 'Void',
}

export enum EnumEyeWarrantyPeriodTypeUnit {
  /** 月 */
  Month = 'Month',
  /** 年 */
  Year = 'Year',
}

export enum EnumLastGmpBpmStatus {
  /** 已取消 */
  Canceled = 'Canceled',
  /** 已完成 */
  Completed = 'Completed',
  /** 再商議 */
  Reconsidered = 'Reconsidered',
  /** 已拒絕（被退件） */
  Rejected = 'Rejected',
  /** 執行中 */
  Waiting = 'Waiting',
}

export enum EnumMaterialStatus {
  /** 停用 */
  Disabled = 'Disabled',
  /** 啟用 */
  Enabled = 'Enabled',
}

/** 類別 */
export enum EnumNavSalesInvoiceType {
  /** 銷售發票 */
  SalesInvoice = 'SalesInvoice',
  /** 銷售退貨 */
  SalesReturn = 'SalesReturn',
}

/** 訊息 - 內部Component類型 */
export enum EnumNotificationActionComponent {
  /** 耳科預約 */
  Appointment = 'Appointment',
  /** 耳模訂製 */
  EarModel = 'EarModel',
  /** 會議室預約 */
  MeetingRoom = 'MeetingRoom',
  /** 耳科維修 */
  RepairOrder = 'RepairOrder',
}

export enum EnumNotificationType {
  /** 警告 */
  Alert = 'Alert',
  /** 公告 */
  Announcement = 'Announcement',
  /** 訊息 */
  Message = 'Message',
  /** 通知 */
  Remind = 'Remind',
}

export enum EnumProductAcceptanceFormStatus {
  /** 有效 */
  Enabled = 'Enabled',
  /** 作廢 */
  Void = 'Void',
}

export enum EnumQuestionnaireStatus {
  /** 有效 */
  Enabled = 'Enabled',
  /** 作廢 */
  Void = 'Void',
}

/** 時態 */
export enum EnumSalesProductOverallViewTense {
  /** 未來 */
  Future = 'Future',
  /** 過去 */
  Past = 'Past',
  /** 現在 */
  Present = 'Present',
}

export enum EnumSalesTeamGroupCode {
  /** 中國_眼科業務 */
  ChnEye = 'CHN_EYE',
  /** 臺灣_電子耳業務 */
  TwnCi = 'TWN_CI',
  /** 臺灣_科明及明睿業務 */
  TwnEcAndMr = 'TWN_EC_AND_MR',
  /** 臺灣_眼科業務 */
  TwnEye = 'TWN_EYE',
  /** 臺灣_新創業務 */
  TwnStartup = 'TWN_STARTUP',
}

/** 排序方向 */
export enum EnumSortDirection {
  /** A-Z 升序（Ascending） */
  Asc = 'ASC',
  /** Z-A 降序（Descending） */
  Desc = 'DESC',
}

/** 依星期統計拜訪 */
export enum EnumVisitByDayOfWeek {
  /** 星期五 */
  Friday = 'Friday',
  /** 星期一 */
  Monday = 'Monday',
  /** 星期六 */
  Saturday = 'Saturday',
  /** 星期日 */
  Sunday = 'Sunday',
  /** 星期四 */
  Thursday = 'Thursday',
  /** 星期二 */
  Tuesday = 'Tuesday',
  /** 星期三 */
  Wednesday = 'Wednesday',
}

export enum EnumVisitStatus {
  /** 已取消 */
  Canceled = 'Canceled',
  /** 未拜訪 */
  Unvisited = 'Unvisited',
  /** 已拜訪 */
  Visited = 'Visited',
}

export enum EnumVisitTypeCode {
  /** 商機拜訪 */
  BusinessIncluded = 'BusinessIncluded',
  /** 無商機拜訪 */
  BusinessNotIncluded = 'BusinessNotIncluded',
}

export enum EnumWarrantyBuyType {
  /** 單買合約 */
  BuySeparately = 'BuySeparately',
  /** 與商品一起買合約 */
  BuyWithProduct = 'BuyWithProduct',
  /** 不買合約 */
  NotBuy = 'NotBuy',
}

export type ExchangeRate = IExchangeRate & {
  createdAt?: Maybe<Scalars['DateTime']>;
  /** 對象幣別 */
  currency1Id: Scalars['ID'];
  /** 轉換幣別 */
  currency2Id: Scalars['ID'];
  id: Scalars['ID'];
  rate: Scalars['Float'];
  region?: Maybe<Region>;
  regionId: Scalars['ID'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ExchangeRateSearchInput = {
  /** 對象幣別 */
  currency1Id?: InputMaybe<Scalars['ID']>;
  /** 轉換幣別 */
  currency2Id?: InputMaybe<Scalars['ID']>;
  id?: InputMaybe<Scalars['ID']>;
  regionIds?: InputMaybe<Array<Scalars['ID']>>;
};

export type ExchangeRateSearchResult = {
  count: Scalars['Int'];
  pageInfo: PageInfo;
  rows: Array<ExchangeRate>;
};

export type EyeFixedAsset = IEyeFixedAsset & {
  /** 借用天數 */
  borrowingDays?: Maybe<Scalars['Int']>;
  /** 廠牌 */
  brand?: Maybe<Scalars['String']>;
  /** 編號 */
  code: Scalars['String'];
  company?: Maybe<Company>;
  /** 公司 */
  companyId?: Maybe<Scalars['ID']>;
  createdAt: Scalars['DateTime'];
  createdUser?: Maybe<User>;
  createdUserId?: Maybe<Scalars['ID']>;
  /** 目前「租借中」的租借單 */
  currEyeFixedAssetRentalRecord?: Maybe<EyeFixedAssetRentalRecord>;
  /** 最近可借日期 */
  currentAvailableRentalDate: Scalars['Date'];
  /** 目前所在位置 */
  currentLocation?: Maybe<Scalars['String']>;
  /** 運送天數 */
  deliveryDays?: Maybe<Scalars['Int']>;
  /** 保管部門 */
  dept?: Maybe<Department>;
  /** 保管部門（於 issues/101545 棄用） */
  deptId?: Maybe<Scalars['ID']>;
  documents: Array<EyeFixedAssetDocument>;
  eyeFixedAssetRentalRecords: Array<EyeFixedAssetRentalRecord>;
  /** 服務單位 */
  eyeFixedAssetServiceProvider?: Maybe<Array<EyeFixedAssetServiceProvider>>;
  id: Scalars['ID'];
  images: Array<EyeFixedAssetImage>;
  /** 是否被預約 */
  isBooking: Scalars['Boolean'];
  items: Array<EyeFixedAssetItem>;
  /** 最大展延天數 */
  maxExtendedDays?: Maybe<Scalars['Int']>;
  /** 最大展延次數 */
  maxExtendedTimes?: Maybe<Scalars['Int']>;
  memo?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  /** 下一個「待租借」的租借單 */
  nextEyeFixedAssetRentalRecord?: Maybe<EyeFixedAssetRentalRecord>;
  /** PM 負責人 */
  pmUser?: Maybe<User>;
  /** PM 負責人 */
  pmUserId?: Maybe<Scalars['ID']>;
  /** 上一個「已歸還」的租借單 */
  prevEyeFixedAssetRentalRecord?: Maybe<EyeFixedAssetRentalRecord>;
  region?: Maybe<Region>;
  regionId: Scalars['ID'];
  status: EnumEyeFixedAssetsStatus;
  /** 類別 */
  types: Array<EyeFixedAssetType>;
  updatedAt: Scalars['DateTime'];
  updatedUser?: Maybe<User>;
  updatedUserId?: Maybe<Scalars['ID']>;
  /** 保管人 */
  user?: Maybe<User>;
  /** 保管人員（於 issues/101545 棄用） */
  userId?: Maybe<Scalars['ID']>;
  /** 倉儲 */
  warehouse?: Maybe<Warehouse>;
  /** 倉儲 */
  warehouseId?: Maybe<Scalars['ID']>;
};

export type EyeFixedAssetCreateInput = {
  /** 借用天數 */
  borrowingDays?: InputMaybe<Scalars['Int']>;
  /** 廠牌 */
  brand?: InputMaybe<Scalars['String']>;
  /** 編號 */
  code: Scalars['String'];
  /** 公司 */
  companyId?: InputMaybe<Scalars['ID']>;
  /** 目前所在位置 */
  currentLocation?: InputMaybe<Scalars['String']>;
  /** 運送天數 */
  deliveryDays?: InputMaybe<Scalars['Int']>;
  /** 保管部門 */
  deptId?: InputMaybe<Scalars['ID']>;
  /** 文件 */
  documents?: InputMaybe<Array<AttachmentCreateInput>>;
  /** 服務單位 */
  eyeFixedAssetServiceProviderIds?: InputMaybe<Array<Scalars['ID']>>;
  /** 類別 */
  eyeFixedAssetTypeIds?: InputMaybe<Array<Scalars['ID']>>;
  /** 圖片 */
  images?: InputMaybe<Array<AttachmentCreateInput>>;
  items: Array<EyeFixedAssetItemCreateInput>;
  /** 最大展延天數 */
  maxExtendedDays?: InputMaybe<Scalars['Int']>;
  /** 最大展延次數 */
  maxExtendedTimes?: InputMaybe<Scalars['Int']>;
  memo?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  /** PM 負責人 */
  pmUserId?: InputMaybe<Scalars['ID']>;
  regionId: Scalars['ID'];
  status?: EnumEyeFixedAssetsStatus;
  /** 保管人 */
  userId?: InputMaybe<Scalars['ID']>;
  /** 倉儲 */
  warehouseId?: InputMaybe<Scalars['ID']>;
};

export type EyeFixedAssetDocument = IEyeFixedAssetDocument & {
  /** 資料新增日期 */
  createdAt: Scalars['DateTime'];
  createdUser?: Maybe<User>;
  /** 資料建立人員 */
  createdUserId?: Maybe<Scalars['ID']>;
  /** 副檔名 */
  extension: Scalars['String'];
  /** 固定資產 */
  eyeFixedAssetId: Scalars['ID'];
  id: Scalars['ID'];
  /** 備註 */
  memo?: Maybe<Scalars['String']>;
  /** 檔案名稱 */
  name?: Maybe<Scalars['String']>;
  /** S3 key */
  s3Key: Scalars['String'];
  /** 檔案大小(Bytes) */
  size: Scalars['Float'];
  /** 資料修改日期 */
  updatedAt: Scalars['DateTime'];
  updatedUser?: Maybe<User>;
  /** 資料修改人員 */
  updatedUserId?: Maybe<Scalars['ID']>;
  /** 短效期的S3 url */
  url?: Maybe<Scalars['URL']>;
};

export type EyeFixedAssetDocumentBulkCreateInput = {
  documents: Array<AttachmentCreateInput>;
  eyeFixedAssetId: Scalars['ID'];
};

export type EyeFixedAssetDocumentCreateInput = {
  eyeFixedAssetId: Scalars['ID'];
  file: Scalars['Upload'];
  memo?: InputMaybe<Scalars['String']>;
  /** 檔案名稱 */
  name: Scalars['String'];
};

export type EyeFixedAssetDocumentSearchInput = {
  eyeFixedAssetId?: InputMaybe<Scalars['ID']>;
  id?: InputMaybe<Scalars['ID']>;
};

export type EyeFixedAssetDocumentSearchResult = {
  count: Scalars['Int'];
  pageInfo: PageInfo;
  rows: Array<EyeFixedAssetDocument>;
};

export type EyeFixedAssetDocumentUpdateInput = {
  id: Scalars['ID'];
  memo?: InputMaybe<Scalars['String']>;
  /** 檔案名稱 */
  name?: InputMaybe<Scalars['String']>;
};

export type EyeFixedAssetImage = IEyeFixedAssetImage & {
  /** 資料新增日期 */
  createdAt: Scalars['DateTime'];
  createdUser?: Maybe<User>;
  /** 資料建立人員 */
  createdUserId?: Maybe<Scalars['ID']>;
  /** 副檔名 */
  extension: Scalars['String'];
  /** 固定資產 */
  eyeFixedAssetId: Scalars['ID'];
  id: Scalars['ID'];
  /** 備註 */
  memo?: Maybe<Scalars['String']>;
  /** 檔案名稱 */
  name?: Maybe<Scalars['String']>;
  /** S3 key */
  s3Key: Scalars['String'];
  /** 檔案大小(Bytes) */
  size: Scalars['Float'];
  /** 資料修改日期 */
  updatedAt: Scalars['DateTime'];
  updatedUser?: Maybe<User>;
  /** 資料修改人員 */
  updatedUserId?: Maybe<Scalars['ID']>;
  /** 短效期的S3 url */
  url?: Maybe<Scalars['URL']>;
};

export type EyeFixedAssetImageBulkCreateInput = {
  eyeFixedAssetId: Scalars['ID'];
  images: Array<AttachmentCreateInput>;
};

export type EyeFixedAssetImageCreateInput = {
  eyeFixedAssetId: Scalars['ID'];
  file: Scalars['Upload'];
  memo?: InputMaybe<Scalars['String']>;
  /** 檔案名稱 */
  name: Scalars['String'];
};

export type EyeFixedAssetImageSearchInput = {
  eyeFixedAssetId?: InputMaybe<Scalars['ID']>;
  id?: InputMaybe<Scalars['ID']>;
};

export type EyeFixedAssetImageSearchResult = {
  count: Scalars['Int'];
  pageInfo: PageInfo;
  rows: Array<EyeFixedAssetImage>;
};

export type EyeFixedAssetImageUpdateInput = {
  id: Scalars['ID'];
  memo?: InputMaybe<Scalars['String']>;
  /** 檔案名稱 */
  name?: InputMaybe<Scalars['String']>;
};

export type EyeFixedAssetItem = IEyeFixedAssetItem & {
  createdAt?: Maybe<Scalars['DateTime']>;
  eyeFixedAssetId: Scalars['ID'];
  id: Scalars['ID'];
  material?: Maybe<Material>;
  materialCode: Scalars['String'];
  materialId: Scalars['ID'];
  materialModel?: Maybe<Scalars['String']>;
  materialName?: Maybe<Scalars['String']>;
  materialSpec?: Maybe<Scalars['String']>;
  materialUnit?: Maybe<Scalars['String']>;
  memo?: Maybe<Scalars['String']>;
  /** 序號 */
  sn?: Maybe<Scalars['String']>;
  /** 醫療器材單一識別碼 */
  udi?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type EyeFixedAssetItemCreateInput = {
  materialId: Scalars['ID'];
  memo?: InputMaybe<Scalars['String']>;
  sn?: InputMaybe<Scalars['String']>;
  udi?: InputMaybe<Scalars['String']>;
};

export type EyeFixedAssetRentalGoal = IEyeFixedAssetRentalGoal & {
  /** 編號 */
  code?: Maybe<Scalars['String']>;
  /** 資料新增日期 */
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  /** 是否啟用 */
  isActive: Scalars['Boolean'];
  /** 名稱 */
  name: Scalars['String'];
  region?: Maybe<Region>;
  /** 區域 */
  regionId?: Maybe<Scalars['ID']>;
  /** 資料修改日期 */
  updatedAt: Scalars['DateTime'];
};

export type EyeFixedAssetRentalGoalCreateInput = {
  /** 編號 */
  code?: InputMaybe<Scalars['String']>;
  /** 是否啟用 */
  isActive?: InputMaybe<Scalars['Boolean']>;
  /** 名稱 */
  name: Scalars['String'];
  /** 區域 */
  regionId: Scalars['ID'];
};

export type EyeFixedAssetRentalGoalSearchInput = {
  /** 編號 */
  code?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['ID']>;
  /** 是否啟用 */
  isActive?: InputMaybe<Scalars['Boolean']>;
  /** 名稱 */
  name?: InputMaybe<Scalars['String']>;
  regionIds?: InputMaybe<Array<Scalars['ID']>>;
};

export type EyeFixedAssetRentalGoalSearchResult = {
  count: Scalars['Int'];
  pageInfo: PageInfo;
  rows: Array<EyeFixedAssetRentalGoal>;
};

export type EyeFixedAssetRentalGoalUpdateInput = {
  /** 編號 */
  code?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
  /** 是否啟用 */
  isActive?: InputMaybe<Scalars['Boolean']>;
  /** 名稱 */
  name?: InputMaybe<Scalars['String']>;
  /** 區域 */
  regionId?: InputMaybe<Scalars['ID']>;
};

export type EyeFixedAssetRentalObject = IEyeFixedAssetRentalObject & {
  /** 編號 */
  code?: Maybe<EnumEyeFixedAssetRentalObjectCode>;
  /** 資料新增日期 */
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  /** 是否啟用 */
  isActive: Scalars['Boolean'];
  /** 名稱 */
  name: Scalars['String'];
  region?: Maybe<Region>;
  /** 區域 */
  regionId?: Maybe<Scalars['ID']>;
  /** 資料修改日期 */
  updatedAt: Scalars['DateTime'];
};

export type EyeFixedAssetRentalObjectCreateInput = {
  /** 編號 */
  code?: InputMaybe<EnumEyeFixedAssetRentalObjectCode>;
  /** 是否啟用 */
  isActive?: InputMaybe<Scalars['Boolean']>;
  /** 名稱 */
  name: Scalars['String'];
  /** 區域 */
  regionId: Scalars['ID'];
};

export type EyeFixedAssetRentalObjectSearchInput = {
  /** 編號 */
  code?: InputMaybe<EnumEyeFixedAssetRentalObjectCode>;
  id?: InputMaybe<Scalars['ID']>;
  /** 是否啟用 */
  isActive?: InputMaybe<Scalars['Boolean']>;
  /** 名稱 */
  name?: InputMaybe<Scalars['String']>;
  regionIds?: InputMaybe<Array<Scalars['ID']>>;
};

export type EyeFixedAssetRentalObjectSearchResult = {
  count: Scalars['Int'];
  pageInfo: PageInfo;
  rows: Array<EyeFixedAssetRentalObject>;
};

export type EyeFixedAssetRentalObjectUpdateInput = {
  /** 編號 */
  code?: InputMaybe<EnumEyeFixedAssetRentalObjectCode>;
  id: Scalars['ID'];
  /** 是否啟用 */
  isActive?: InputMaybe<Scalars['Boolean']>;
  /** 名稱 */
  name?: InputMaybe<Scalars['String']>;
  /** 區域 */
  regionId?: InputMaybe<Scalars['ID']>;
};

export type EyeFixedAssetRentalRecord = IEyeFixedAssetRentalRecord & {
  company?: Maybe<Company>;
  /** 公司 */
  companyId?: Maybe<Scalars['ID']>;
  createdAt: Scalars['DateTime'];
  createdUser?: Maybe<User>;
  createdUserId?: Maybe<Scalars['ID']>;
  /** 租借日(起) */
  date1: Scalars['DateTime'];
  /** 租借日(迄) */
  date2: Scalars['DateTime'];
  /** 拆機日 */
  dismantleDate?: Maybe<Scalars['DateTime']>;
  eyeFixedAssets: Array<EyeFixedAsset>;
  eyeServiceOrder?: Maybe<EyeServiceOrder>;
  eyeServiceOrderId: Scalars['ID'];
  eyeServiceOrderWorkDiaries: Array<EyeServiceOrderWorkDiary>;
  id: Scalars['ID'];
  /** 安裝日 */
  installDate?: Maybe<Scalars['DateTime']>;
  region?: Maybe<Region>;
  regionId: Scalars['ID'];
  remark?: Maybe<Scalars['String']>;
  status: EnumEyeFixedAssetsRentalStatus;
  updatedAt: Scalars['DateTime'];
  updatedUser?: Maybe<User>;
  updatedUserId?: Maybe<Scalars['ID']>;
};

export type EyeFixedAssetRentalRecordBaseCreateInput = {
  date1: Scalars['Date'];
  date2: Scalars['Date'];
  dismantleDate?: InputMaybe<Scalars['Date']>;
  eyeFixedAssetIds: Array<Scalars['ID']>;
  installDate?: InputMaybe<Scalars['Date']>;
  remark?: InputMaybe<Scalars['String']>;
};

export type EyeFixedAssetRentalRecordCreateInput = {
  date1: Scalars['Date'];
  date2: Scalars['Date'];
  dismantleDate?: InputMaybe<Scalars['Date']>;
  eyeFixedAssetIds: Array<Scalars['ID']>;
  eyeServiceOrderId: Scalars['ID'];
  installDate?: InputMaybe<Scalars['Date']>;
  remark?: InputMaybe<Scalars['String']>;
};

export type EyeFixedAssetRentalRecordSearchInput = {
  companyIds?: InputMaybe<Array<Scalars['ID']>>;
  /** 建立人 */
  createdUserId?: InputMaybe<Scalars['ID']>;
  date1?: InputMaybe<Scalars['Date']>;
  date2?: InputMaybe<Scalars['Date']>;
  deptId?: InputMaybe<Scalars['ID']>;
  dismantledate1?: InputMaybe<Scalars['Date']>;
  dismantledate2?: InputMaybe<Scalars['Date']>;
  /** 固資 */
  eyeFixedAssetIds?: InputMaybe<Array<Scalars['ID']>>;
  /** 眼科固定資產類別Ids */
  eyeFixedAssetTypeIds?: InputMaybe<Array<Scalars['ID']>>;
  /** 服務單 */
  eyeServiceOrderIds?: InputMaybe<Array<Scalars['ID']>>;
  /** 服務單優先權 */
  eyeServiceOrderPriorities?: InputMaybe<Array<EnumEyeServiceOrderPriority>>;
  id?: InputMaybe<Scalars['ID']>;
  installdate1?: InputMaybe<Scalars['Date']>;
  installdate2?: InputMaybe<Scalars['Date']>;
  materialCode?: InputMaybe<Scalars['String']>;
  materialId?: InputMaybe<Scalars['ID']>;
  regionIds?: InputMaybe<Array<Scalars['ID']>>;
  sn?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<EnumEyeFixedAssetsRentalStatus>;
};

export type EyeFixedAssetRentalRecordSearchResult = {
  count: Scalars['Int'];
  pageInfo: PageInfo;
  rows: Array<EyeFixedAssetRentalRecord>;
};

export type EyeFixedAssetRentalRecordUpdateInput = {
  date1?: InputMaybe<Scalars['Date']>;
  date2?: InputMaybe<Scalars['Date']>;
  dismantleDate?: InputMaybe<Scalars['Date']>;
  eyeFixedAssetIds?: InputMaybe<Array<Scalars['ID']>>;
  id: Scalars['ID'];
  installDate?: InputMaybe<Scalars['Date']>;
  remark?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<EnumEyeFixedAssetsRentalStatus>;
};

export type EyeFixedAssetSearchInput = {
  /** 可租借日期(起) */
  availableRentalDate1?: InputMaybe<Scalars['Date']>;
  /** 可租借日期(迄) */
  availableRentalDate2?: InputMaybe<Scalars['Date']>;
  /** 廠牌 */
  brand?: InputMaybe<Scalars['String']>;
  /** 編號 */
  code?: InputMaybe<Scalars['String']>;
  companyIds?: InputMaybe<Array<Scalars['ID']>>;
  /** 目前所在位置 */
  currentLocation?: InputMaybe<Scalars['String']>;
  /** 保管部門 */
  deptId?: InputMaybe<Scalars['ID']>;
  /** 服務單位 */
  eyeFixedAssetServiceProviderIds?: InputMaybe<Array<Scalars['ID']>>;
  /** 類別 Code */
  eyeFixedAssetTypeCodes?: InputMaybe<Array<EnumEyeFixedAssetTypeCode>>;
  /** 類別 */
  eyeFixedAssetTypeIds?: InputMaybe<Array<Scalars['ID']>>;
  id?: InputMaybe<Scalars['ID']>;
  materialCode?: InputMaybe<Scalars['String']>;
  materialId?: InputMaybe<Scalars['ID']>;
  materialName?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  /** PM 負責人 */
  pmUserId?: InputMaybe<Scalars['ID']>;
  regionIds?: InputMaybe<Array<Scalars['ID']>>;
  salesTeamGroupIds?: InputMaybe<Array<Scalars['ID']>>;
  sn?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<EnumEyeFixedAssetsStatus>;
  udi?: InputMaybe<Scalars['String']>;
  /** 保管人 */
  userId?: InputMaybe<Scalars['ID']>;
  /** 倉儲 */
  warehouseIds?: InputMaybe<Array<Scalars['ID']>>;
};

export type EyeFixedAssetSearchResult = {
  count: Scalars['Int'];
  pageInfo: PageInfo;
  rows: Array<EyeFixedAsset>;
};

export type EyeFixedAssetServiceProvider = IEyeFixedAssetServiceProvider & {
  /** 編號 */
  code?: Maybe<EnumEyeFixedAssetServiceProviderCode>;
  /** 資料新增日期 */
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  /** 是否啟用 */
  isActive: Scalars['Boolean'];
  /** 名稱 */
  name: Scalars['String'];
  region?: Maybe<Region>;
  /** 區域 */
  regionId?: Maybe<Scalars['ID']>;
  /** 資料修改日期 */
  updatedAt: Scalars['DateTime'];
};

export type EyeFixedAssetServiceProviderCreateInput = {
  /** 編號 */
  code?: InputMaybe<EnumEyeFixedAssetServiceProviderCode>;
  /** 是否啟用 */
  isActive?: InputMaybe<Scalars['Boolean']>;
  /** 名稱 */
  name: Scalars['String'];
  /** 區域 */
  regionId: Scalars['ID'];
};

export type EyeFixedAssetServiceProviderSearchInput = {
  /** 編號 */
  code?: InputMaybe<EnumEyeFixedAssetServiceProviderCode>;
  id?: InputMaybe<Scalars['ID']>;
  /** 是否啟用 */
  isActive?: InputMaybe<Scalars['Boolean']>;
  /** 名稱 */
  name?: InputMaybe<Scalars['String']>;
  regionIds?: InputMaybe<Array<Scalars['ID']>>;
};

export type EyeFixedAssetServiceProviderSearchResult = {
  count: Scalars['Int'];
  pageInfo: PageInfo;
  rows: Array<EyeFixedAssetServiceProvider>;
};

export type EyeFixedAssetServiceProviderUpdateInput = {
  /** 編號 */
  code?: InputMaybe<EnumEyeFixedAssetServiceProviderCode>;
  id: Scalars['ID'];
  /** 是否啟用 */
  isActive?: InputMaybe<Scalars['Boolean']>;
  /** 名稱 */
  name?: InputMaybe<Scalars['String']>;
  /** 區域 */
  regionId?: InputMaybe<Scalars['ID']>;
};

export type EyeFixedAssetType = IEyeFixedAssetType & {
  /** 編號 */
  code: EnumEyeFixedAssetTypeCode;
  /** 資料新增日期 */
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  /** 是否啟用 */
  isActive: Scalars['Boolean'];
  /** 名稱 */
  name: Scalars['String'];
  region?: Maybe<Region>;
  /** 區域 */
  regionId: Scalars['ID'];
  /** 資料修改日期 */
  updatedAt: Scalars['DateTime'];
};

export type EyeFixedAssetTypeSearchInput = {
  id?: InputMaybe<Scalars['ID']>;
  regionIds?: InputMaybe<Array<Scalars['ID']>>;
};

export type EyeFixedAssetTypeSearchResult = {
  count: Scalars['Int'];
  pageInfo: PageInfo;
  rows: Array<EyeFixedAssetType>;
};

export type EyeFixedAssetUpdateInput = {
  /** 借用天數 */
  borrowingDays?: InputMaybe<Scalars['Int']>;
  /** 廠牌 */
  brand?: InputMaybe<Scalars['String']>;
  /** 編號 */
  code?: InputMaybe<Scalars['String']>;
  /** 新增文件 */
  createdDocuments?: InputMaybe<Array<AttachmentCreateInput>>;
  /** 新增圖片 */
  createdImages?: InputMaybe<Array<AttachmentCreateInput>>;
  /** 目前所在位置 */
  currentLocation?: InputMaybe<Scalars['String']>;
  /** 刪除文件 */
  deletedDocumentIds?: InputMaybe<Array<Scalars['ID']>>;
  /** 刪除圖片 */
  deletedImageIds?: InputMaybe<Array<Scalars['ID']>>;
  /** 運送天數 */
  deliveryDays?: InputMaybe<Scalars['Int']>;
  /** 保管部門 */
  deptId?: InputMaybe<Scalars['ID']>;
  /** 服務單位 */
  eyeFixedAssetServiceProviderIds?: InputMaybe<Array<Scalars['ID']>>;
  /** 類別 */
  eyeFixedAssetTypeIds?: InputMaybe<Array<Scalars['ID']>>;
  id: Scalars['ID'];
  items?: InputMaybe<Array<EyeFixedAssetItemCreateInput>>;
  /** 最大展延天數 */
  maxExtendedDays?: InputMaybe<Scalars['Int']>;
  /** 最大展延次數 */
  maxExtendedTimes?: InputMaybe<Scalars['Int']>;
  memo?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  /** PM 負責人 */
  pmUserId?: InputMaybe<Scalars['ID']>;
  status?: InputMaybe<EnumEyeFixedAssetsStatus>;
  /** 保管人 */
  userId?: InputMaybe<Scalars['ID']>;
  /** 倉儲 */
  warehouseId?: InputMaybe<Scalars['ID']>;
};

export type EyeProduct = IEyeProduct & {
  attachments: Array<EyeProductAttachment>;
  /** 台數獎金係數 */
  bonusCoefficient?: Maybe<Scalars['Float']>;
  /** 商品品牌 */
  brand?: Maybe<Scalars['String']>;
  /** 事業主管金額 */
  businessManagerPrice?: Maybe<Scalars['Float']>;
  company?: Maybe<Company>;
  /** 公司 */
  companyId: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  createdUser?: Maybe<User>;
  createdUserId?: Maybe<Scalars['ID']>;
  currency?: Maybe<Currency>;
  /** 幣別 */
  currencyId: Scalars['ID'];
  /** 有效日期1 */
  date1?: Maybe<Scalars['Date']>;
  /** 有效日期2 */
  date2?: Maybe<Scalars['Date']>;
  /** 經銷金額 */
  dealerPrice?: Maybe<Scalars['Float']>;
  /** 敘述 */
  description?: Maybe<Scalars['String']>;
  /** DSM(辦事處)金額 */
  dsmPrice?: Maybe<Scalars['Float']>;
  /** 群組 */
  eyeProductGroupId?: Maybe<Scalars['ID']>;
  eyeProductItems: Array<EyeProductItem>;
  eyeProductType?: Maybe<EyeProductType>;
  eyeProductTypeId?: Maybe<Scalars['ID']>;
  eyePromotions: Array<EyePromotion>;
  /** 總經理金額 */
  generalManagerPrice?: Maybe<Scalars['Float']>;
  id: Scalars['ID'];
  /** 商品型號 */
  model?: Maybe<Scalars['String']>;
  /** 商品名稱 */
  name: Scalars['String'];
  productUsers: Array<User>;
  region?: Maybe<Region>;
  regionId: Scalars['ID'];
  /** 大區主管金額 */
  regionalManagerPrice?: Maybe<Scalars['Float']>;
  /** 販售金額 */
  salePrice: Scalars['Float'];
  /** 標準交期（天數） */
  standardDeliveryDays?: Maybe<Scalars['Int']>;
  /** 狀態 */
  status: EnumEyeProductStatus;
  updatedAt: Scalars['DateTime'];
  updatedUser?: Maybe<User>;
  updatedUserId?: Maybe<Scalars['ID']>;
};

export type EyeProductAcceptanceForm = IEyeProductAcceptanceForm & {
  createdAt: Scalars['DateTime'];
  date1?: Maybe<Scalars['Date']>;
  date2?: Maybe<Scalars['Date']>;
  eyeProductId: Scalars['ID'];
  fields?: Maybe<Scalars['JSON']>;
  id: Scalars['ID'];
  status: EnumProductAcceptanceFormStatus;
  updatedAt: Scalars['DateTime'];
  version: Scalars['String'];
};

export type EyeProductAcceptanceFormCreateInput = {
  date1: Scalars['Date'];
  date2: Scalars['Date'];
  eyeProductId: Scalars['ID'];
  field: Scalars['JSON'];
  status: EnumProductAcceptanceFormStatus;
  version: Scalars['String'];
};

export type EyeProductAcceptanceFormDetail = IEyeProductAcceptanceFormDetail & {
  createdAt: Scalars['DateTime'];
  description?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  name: Scalars['String'];
  options?: Maybe<Scalars['JSON']>;
  required: Scalars['Boolean'];
  typeId: Scalars['ID'];
  updatedAt: Scalars['DateTime'];
};

export type EyeProductAcceptanceFormDetailCreateInput = {
  description?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  option?: InputMaybe<Scalars['JSON']>;
  required: Scalars['Boolean'];
  typeId: Scalars['ID'];
};

export type EyeProductAcceptanceFormDetailSearchInput = {
  id?: InputMaybe<Scalars['ID']>;
  name?: InputMaybe<Scalars['String']>;
  typeId?: InputMaybe<Scalars['ID']>;
};

export type EyeProductAcceptanceFormDetailSearchResult = {
  count: Scalars['Int'];
  pageInfo: PageInfo;
  rows: Array<EyeProductAcceptanceFormDetail>;
};

export type EyeProductAcceptanceFormDetailUpdateInput = {
  description?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
  name?: InputMaybe<Scalars['String']>;
  option?: InputMaybe<Scalars['JSON']>;
  required?: InputMaybe<Scalars['Boolean']>;
  typeId?: InputMaybe<Scalars['ID']>;
};

export type EyeProductAcceptanceFormSearchInput = {
  date1?: InputMaybe<Scalars['Date']>;
  date2?: InputMaybe<Scalars['Date']>;
  eyeProductId?: InputMaybe<Scalars['ID']>;
  id?: InputMaybe<Scalars['ID']>;
  status?: InputMaybe<EnumProductAcceptanceFormStatus>;
  version?: InputMaybe<Scalars['String']>;
};

export type EyeProductAcceptanceFormSearchResult = {
  count: Scalars['Int'];
  pageInfo: PageInfo;
  rows: Array<EyeProductAcceptanceForm>;
};

export type EyeProductAcceptanceFormUpdateInput = {
  date1?: InputMaybe<Scalars['Date']>;
  date2?: InputMaybe<Scalars['Date']>;
  eyeProductId?: InputMaybe<Scalars['ID']>;
  field?: InputMaybe<Scalars['JSON']>;
  id: Scalars['ID'];
  status?: InputMaybe<EnumProductAcceptanceFormStatus>;
  version?: InputMaybe<Scalars['String']>;
};

export type EyeProductAttachment = IEyeProductAttachment & {
  /** 資料新增日期 */
  createdAt: Scalars['DateTime'];
  createdUser?: Maybe<User>;
  /** 資料建立人員 */
  createdUserId?: Maybe<Scalars['ID']>;
  /** 副檔名 */
  extension: Scalars['String'];
  /** 眼科商品 */
  eyeProductId: Scalars['ID'];
  id: Scalars['ID'];
  /** 備註 */
  memo?: Maybe<Scalars['String']>;
  /** 名稱 */
  name: Scalars['String'];
  /** S3 Key */
  s3Key: Scalars['String'];
  /** 檔案大小(Bytes) */
  size: Scalars['Float'];
  /** 資料修改日期 */
  updatedAt: Scalars['DateTime'];
  updatedUser?: Maybe<User>;
  /** 資料修改人員 */
  updatedUserId?: Maybe<Scalars['ID']>;
  /** 短效期的S3 url */
  url?: Maybe<Scalars['URL']>;
};

export type EyeProductAttachmentBulkCreateInput = {
  attachments: Array<AttachmentCreateInput>;
  eyeProductId: Scalars['ID'];
};

export type EyeProductAttachmentCreateInput = {
  eyeProductId: Scalars['ID'];
  file: Scalars['Upload'];
  memo?: InputMaybe<Scalars['String']>;
  /** 檔案名稱 */
  name: Scalars['String'];
};

export type EyeProductAttachmentSearchInput = {
  eyeProductId?: InputMaybe<Scalars['ID']>;
  id?: InputMaybe<Scalars['ID']>;
};

export type EyeProductAttachmentSearchResult = {
  count: Scalars['Int'];
  pageInfo: PageInfo;
  rows: Array<EyeProductAttachment>;
};

export type EyeProductAttachmentUpdateInput = {
  id: Scalars['ID'];
  memo?: InputMaybe<Scalars['String']>;
  /** 檔案名稱 */
  name?: InputMaybe<Scalars['String']>;
};

export type EyeProductCreateInput = {
  /** 附件 */
  attachments?: InputMaybe<Array<AttachmentCreateInput>>;
  /** 台數獎金係數 */
  bonusCoefficient?: InputMaybe<Scalars['Float']>;
  /** 商品品牌 */
  brand?: InputMaybe<Scalars['String']>;
  /** 事業主管金額 */
  businessManagerPrice?: InputMaybe<Scalars['Float']>;
  companyId: Scalars['ID'];
  /** 幣別 */
  currencyId?: InputMaybe<Scalars['ID']>;
  /** 有效日期1 */
  date1?: InputMaybe<Scalars['Date']>;
  /** 有效日期2 */
  date2?: InputMaybe<Scalars['Date']>;
  /** 經銷金額 */
  dealerPrice?: InputMaybe<Scalars['Float']>;
  /** 敘述 */
  description?: InputMaybe<Scalars['String']>;
  /** DSM（辦事處）金額 */
  dsmPrice?: InputMaybe<Scalars['Float']>;
  /** 類別 */
  eyeProductTypeId?: InputMaybe<Scalars['ID']>;
  eyePromotionIds?: InputMaybe<Array<Scalars['ID']>>;
  /** 總經理金額 */
  generalManagerPrice?: InputMaybe<Scalars['Float']>;
  /** 商品型號 */
  model?: InputMaybe<Scalars['String']>;
  /** 商品名稱 */
  name: Scalars['String'];
  /** 商品負責人員 */
  productUserIds?: InputMaybe<Array<Scalars['ID']>>;
  regionId: Scalars['ID'];
  /** 大區主管金額 */
  regionalManagerPrice?: InputMaybe<Scalars['Float']>;
  /** 販售金額 */
  salePrice?: InputMaybe<Scalars['Float']>;
  /** 標準交期（天數） */
  standardDeliveryDays?: InputMaybe<Scalars['Int']>;
  /** 狀態 */
  status?: EnumEyeProductStatus;
};

export type EyeProductItem = IEyeProductItem & {
  /** 資料新增日期 */
  createdAt: Scalars['DateTime'];
  createdUser?: Maybe<User>;
  /** 資料建立人員 */
  createdUserId?: Maybe<Scalars['ID']>;
  /** 敘述 */
  description?: Maybe<Scalars['String']>;
  /** 商品 */
  eyeProductId: Scalars['ID'];
  eyeProductItemType: EyeProductItemType;
  /** 商品項目類別 */
  eyeProductItemTypeId: Scalars['ID'];
  id: Scalars['ID'];
  /** 是否為可選的 */
  isOptional: Scalars['Boolean'];
  material: Material;
  /** 料號 */
  materialId: Scalars['ID'];
  /** 名稱 */
  materialName: Scalars['String'];
  /** 料號_銷售計量單位 */
  materialSellingUnit?: Maybe<Scalars['String']>;
  /** 數量 */
  qty: Scalars['Int'];
  /** 資料修改日期 */
  updatedAt: Scalars['DateTime'];
  updatedUser?: Maybe<User>;
  /** 資料修改人員 */
  updatedUserId?: Maybe<Scalars['ID']>;
  /** 檢視順序 */
  viewOrder?: Maybe<Scalars['Int']>;
};

export type EyeProductItemBulkUpdateInput = {
  eyeProductItemUpdateInputs?: InputMaybe<Array<EyeProductItemUpdateInput>>;
};

export type EyeProductItemCreateInput = {
  /** 敘述 */
  description?: InputMaybe<Scalars['String']>;
  /** 商品 */
  eyeProductId: Scalars['ID'];
  /** 項目類別 */
  eyeProductItemTypeId: Scalars['ID'];
  /** 是否為可選的 */
  isOptional: Scalars['Boolean'];
  /** 料號 */
  materialId: Scalars['ID'];
  /** 料號名稱 */
  materialName?: InputMaybe<Scalars['String']>;
  /** 料號銷售計量單位 */
  materialSellingUnit?: InputMaybe<Scalars['String']>;
  /** 數量 */
  qty: Scalars['Int'];
  /** 檢視順序 */
  viewOrder?: InputMaybe<Scalars['Int']>;
};

export type EyeProductItemSearchInput = {
  eyeProductId?: InputMaybe<Scalars['ID']>;
  eyeProductItemTypeId?: InputMaybe<Scalars['ID']>;
  id?: InputMaybe<Scalars['ID']>;
  isOptional?: InputMaybe<Scalars['Boolean']>;
  materialId?: InputMaybe<Scalars['ID']>;
  qty?: InputMaybe<Scalars['Int']>;
  regionIds?: InputMaybe<Array<Scalars['ID']>>;
};

export type EyeProductItemSearchResult = {
  count: Scalars['Int'];
  pageInfo: PageInfo;
  rows: Array<EyeProductItem>;
};

export type EyeProductItemType = IEyeProductItemType & {
  /** 1: 主商品, 2: 配件, 3: 贈品, 4: 其他 */
  category: Scalars['Int'];
  id: Scalars['ID'];
  name: Scalars['String'];
  viewOrder?: Maybe<Scalars['Int']>;
};

export type EyeProductItemTypeCreateInput = {
  name?: InputMaybe<Scalars['String']>;
};

export type EyeProductItemTypeSearchInput = {
  id?: InputMaybe<Scalars['ID']>;
};

export type EyeProductItemTypeSearchResult = {
  count: Scalars['Int'];
  pageInfo: PageInfo;
  rows: Array<EyeProductItemType>;
};

export type EyeProductItemTypeUpdateInput = {
  id: Scalars['ID'];
};

export type EyeProductItemUpdateInput = {
  /** 敘述 */
  description?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
  /** 是否為可選的 */
  isOptional?: InputMaybe<Scalars['Boolean']>;
  /** 料號名稱 */
  materialName?: InputMaybe<Scalars['String']>;
  /** 料號銷售計量單位 */
  materialSellingUnit?: InputMaybe<Scalars['String']>;
  /** 數量 */
  qty?: InputMaybe<Scalars['Int']>;
  /** 檢視順序 */
  viewOrder?: InputMaybe<Scalars['Int']>;
};

export type EyeProductSearchInput = {
  /** 商品品牌 */
  brand?: InputMaybe<Scalars['String']>;
  companyIds?: InputMaybe<Array<Scalars['ID']>>;
  /** 幣別 */
  currencyId?: InputMaybe<Scalars['ID']>;
  date1?: InputMaybe<Scalars['Date']>;
  date2?: InputMaybe<Scalars['Date']>;
  /** 經銷金額1 */
  dealerPrice1?: InputMaybe<Scalars['Float']>;
  /** 經銷金額2 */
  dealerPrice2?: InputMaybe<Scalars['Float']>;
  eyeProductTypeId?: InputMaybe<Scalars['ID']>;
  eyePromotionId?: InputMaybe<Scalars['ID']>;
  id?: InputMaybe<Scalars['ID']>;
  ids?: InputMaybe<Array<Scalars['ID']>>;
  /** 商品型號 */
  model?: InputMaybe<Scalars['String']>;
  /** 商品名稱 */
  name?: InputMaybe<Scalars['String']>;
  regionIds?: InputMaybe<Array<Scalars['ID']>>;
  /** 販售金額1 */
  salePrice1?: InputMaybe<Scalars['Float']>;
  /** 販售金額2 */
  salePrice2?: InputMaybe<Scalars['Float']>;
  /** 狀態 */
  status?: InputMaybe<EnumEyeProductStatus>;
};

export type EyeProductSearchResult = {
  count: Scalars['Int'];
  pageInfo: PageInfo;
  rows: Array<EyeProduct>;
};

export type EyeProductType = IEyeProductType & {
  company?: Maybe<Company>;
  /** 公司 */
  companyId: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  name: Scalars['String'];
  region?: Maybe<Region>;
  regionId: Scalars['ID'];
  updatedAt: Scalars['DateTime'];
  viewOrder?: Maybe<Scalars['Int']>;
};

export type EyeProductTypeSearchInput = {
  companyIds?: InputMaybe<Array<Scalars['ID']>>;
  id?: InputMaybe<Scalars['ID']>;
  regionIds?: InputMaybe<Array<Scalars['ID']>>;
};

export type EyeProductTypeSearchResult = {
  count: Scalars['Int'];
  pageInfo: PageInfo;
  rows: Array<EyeProductType>;
};

export type EyeProductUpdateInput = {
  /** 台數獎金係數 */
  bonusCoefficient?: InputMaybe<Scalars['Float']>;
  /** 商品品牌 */
  brand?: InputMaybe<Scalars['String']>;
  /** 事業主管金額 */
  businessManagerPrice?: InputMaybe<Scalars['Float']>;
  /** 新增附件 */
  createdAttachments?: InputMaybe<Array<AttachmentCreateInput>>;
  /** 幣別 */
  currencyId?: InputMaybe<Scalars['ID']>;
  /** 有效日期1 */
  date1?: InputMaybe<Scalars['Date']>;
  /** 有效日期2 */
  date2?: InputMaybe<Scalars['Date']>;
  /** 經銷金額 */
  dealerPrice?: InputMaybe<Scalars['Float']>;
  /** 刪除附件 */
  deletedAttachmentIds?: InputMaybe<Array<Scalars['ID']>>;
  /** 敘述 */
  description?: InputMaybe<Scalars['String']>;
  /** DSM（辦事處）金額 */
  dsmPrice?: InputMaybe<Scalars['Float']>;
  /** 類別 */
  eyeProductTypeId?: InputMaybe<Scalars['ID']>;
  eyePromotionIds?: InputMaybe<Array<Scalars['ID']>>;
  /** 總經理金額 */
  generalManagerPrice?: InputMaybe<Scalars['Float']>;
  id: Scalars['ID'];
  /** 商品型號 */
  model?: InputMaybe<Scalars['String']>;
  /** 商品名稱 */
  name?: InputMaybe<Scalars['String']>;
  /** 商品負責人員 */
  productUserIds?: InputMaybe<Array<Scalars['ID']>>;
  regionId?: InputMaybe<Scalars['ID']>;
  /** 大區主管金額 */
  regionalManagerPrice?: InputMaybe<Scalars['Float']>;
  /** 販售金額 */
  salePrice?: InputMaybe<Scalars['Float']>;
  /** 標準交期（天數） */
  standardDeliveryDays?: InputMaybe<Scalars['Int']>;
  /** 狀態 */
  status?: InputMaybe<EnumEyeProductStatus>;
};

export type EyePromotion = IEyePromotion & {
  company?: Maybe<Company>;
  /** 公司 */
  companyId: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  createdUser?: Maybe<User>;
  createdUserId?: Maybe<Scalars['ID']>;
  currency?: Maybe<Currency>;
  /** 幣別 */
  currencyId: Scalars['ID'];
  /** 有效日期1 */
  date1: Scalars['Date'];
  /** 有效日期2 */
  date2?: Maybe<Scalars['Date']>;
  /** 敘述 */
  description?: Maybe<Scalars['String']>;
  /** 依照金額折扣 */
  discountAmount?: Maybe<Scalars['Float']>;
  /** 依照比率折扣 */
  discountRate?: Maybe<Scalars['Float']>;
  eyeProducts: Array<EyeProduct>;
  eyePromotionAddonProductItems: Array<EyePromotionAddonProductItem>;
  id: Scalars['ID'];
  /** 是否加贈 */
  isAddon: Scalars['Boolean'];
  /** 優惠名稱 */
  name?: Maybe<Scalars['String']>;
  region?: Maybe<Region>;
  regionId: Scalars['ID'];
  updatedAt: Scalars['DateTime'];
  updatedUser?: Maybe<User>;
  updatedUserId?: Maybe<Scalars['ID']>;
};

export type EyePromotionAddonProductItem = IEyePromotionAddonProductItem & {
  /** 資料新增日期 */
  createdAt: Scalars['DateTime'];
  createdUser?: Maybe<User>;
  /** 資料建立人員 */
  createdUserId?: Maybe<Scalars['ID']>;
  /** 敘述 */
  description?: Maybe<Scalars['String']>;
  /** 商品項目類別 */
  eyeProductItemTypeId: Scalars['ID'];
  /** 促銷活動 */
  eyePromotionId: Scalars['ID'];
  id: Scalars['ID'];
  /** 是否為可選的 */
  isOptional: Scalars['Boolean'];
  material: Material;
  /** 料號 */
  materialId: Scalars['ID'];
  /** 料號_計量單位 */
  materialUnit?: Maybe<Scalars['String']>;
  /** 數量 */
  qty: Scalars['Int'];
  /** 資料修改日期 */
  updatedAt: Scalars['DateTime'];
  updatedUser?: Maybe<User>;
  /** 資料修改人員 */
  updatedUserId?: Maybe<Scalars['ID']>;
};

export type EyePromotionAddonProductItemCreateInput = {
  description?: InputMaybe<Scalars['String']>;
  eyeProductItemTypeId: Scalars['ID'];
  eyePromotionId: Scalars['ID'];
  /** 是否為可選的 */
  isOptional: Scalars['Boolean'];
  materialId: Scalars['ID'];
  /** 料號_計量單位 */
  materialUnit?: InputMaybe<Scalars['String']>;
  qty: Scalars['Int'];
};

export type EyePromotionAddonProductItemSearchInput = {
  eyeProductItemTypeId?: InputMaybe<Scalars['ID']>;
  eyePromotionId?: InputMaybe<Scalars['ID']>;
  id?: InputMaybe<Scalars['ID']>;
  /** 是否為可選的 */
  isOptional?: InputMaybe<Scalars['Boolean']>;
  materialId?: InputMaybe<Scalars['ID']>;
  qty?: InputMaybe<Scalars['Int']>;
};

export type EyePromotionAddonProductItemSearchResult = {
  count: Scalars['Int'];
  pageInfo: PageInfo;
  rows: Array<EyePromotionAddonProductItem>;
};

export type EyePromotionAddonProductItemUpdateInput = {
  description?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
  /** 是否為可選的 */
  isOptional?: InputMaybe<Scalars['Boolean']>;
  /** 料號_計量單位 */
  materialUnit?: InputMaybe<Scalars['String']>;
  qty?: InputMaybe<Scalars['Int']>;
};

export type EyePromotionCreateInput = {
  companyId: Scalars['ID'];
  /** 幣別 */
  currencyId: Scalars['ID'];
  date1: Scalars['Date'];
  date2?: InputMaybe<Scalars['Date']>;
  description?: InputMaybe<Scalars['String']>;
  discountAmount?: InputMaybe<Scalars['Float']>;
  discountRate?: InputMaybe<Scalars['Float']>;
  eyeProductIds?: InputMaybe<Array<Scalars['ID']>>;
  name: Scalars['String'];
  regionId: Scalars['ID'];
};

export type EyePromotionSearchInput = {
  companyIds?: InputMaybe<Array<Scalars['ID']>>;
  /** 幣別 */
  currencyId?: InputMaybe<Scalars['ID']>;
  date1?: InputMaybe<Scalars['Date']>;
  date2?: InputMaybe<Scalars['Date']>;
  discountAmount1?: InputMaybe<Scalars['Float']>;
  discountAmount2?: InputMaybe<Scalars['Float']>;
  discountRate1?: InputMaybe<Scalars['Float']>;
  discountRate2?: InputMaybe<Scalars['Float']>;
  eyeProductId?: InputMaybe<Scalars['ID']>;
  eyeProductIds?: InputMaybe<Array<Scalars['ID']>>;
  id?: InputMaybe<Scalars['ID']>;
  ids?: InputMaybe<Array<Scalars['ID']>>;
  /** 是否加贈 */
  isAddon?: InputMaybe<Scalars['Boolean']>;
  /** 全域優惠 */
  isGlobalPromotion?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
  regionIds?: InputMaybe<Array<Scalars['ID']>>;
};

export type EyePromotionSearchResult = {
  count: Scalars['Int'];
  pageInfo: PageInfo;
  rows: Array<EyePromotion>;
};

export type EyePromotionUpdateInput = {
  /** 幣別 */
  currencyId?: InputMaybe<Scalars['ID']>;
  date1?: InputMaybe<Scalars['Date']>;
  date2?: InputMaybe<Scalars['Date']>;
  description?: InputMaybe<Scalars['String']>;
  discountAmount?: InputMaybe<Scalars['Float']>;
  discountRate?: InputMaybe<Scalars['Float']>;
  eyeProductIds?: InputMaybe<Array<Scalars['ID']>>;
  id: Scalars['ID'];
  name?: InputMaybe<Scalars['String']>;
};

export type EyeQuotationOrder = IEyeQuotationOrder & {
  /** 地址 */
  address: Scalars['String'];
  /** 招標金額 */
  biddingPrice?: Maybe<Scalars['Float']>;
  /** BPM Instance id */
  bpmInstanceId?: Maybe<Scalars['ID']>;
  /** 用印BPM id */
  bpmOfficialSealInstanceId?: Maybe<Scalars['ID']>;
  /** BPM連結 */
  bpmUrl?: Maybe<Scalars['URL']>;
  /** @deprecated 「商機」改用「多對一關聯」 */
  business?: Maybe<Business>;
  /** 商機（棄用；改用多對一關聯） */
  businessId?: Maybe<Scalars['ID']>;
  city?: Maybe<City>;
  /** 市級、二級行政區 */
  cityId?: Maybe<Scalars['ID']>;
  /** 編號 */
  code: Scalars['String'];
  /** 佣金 (研究費) */
  commissionAmount: Scalars['Float'];
  company?: Maybe<Company>;
  /** 公司 */
  companyId: Scalars['ID'];
  /** 聯絡人 */
  contactPerson: Scalars['String'];
  /** 聯絡電話 */
  contactPhone: Scalars['String'];
  /** 資料新增日期 */
  createdAt: Scalars['DateTime'];
  createdUser?: Maybe<User>;
  /** 資料建立人員 */
  createdUserId?: Maybe<Scalars['ID']>;
  creditPeriod?: Maybe<CreditPeriod>;
  /** 信用期間 */
  creditPeriodId: Scalars['ID'];
  currency?: Maybe<Currency>;
  /** 幣別 */
  currencyId: Scalars['ID'];
  customer?: Maybe<Customer>;
  /** 裝機客戶 */
  customerId: Scalars['ID'];
  dept?: Maybe<Department>;
  /** 負責部門 */
  deptId: Scalars['ID'];
  /** 描述 */
  description?: Maybe<Scalars['String']>;
  /** 折扣金額 */
  discountAmount: Scalars['Float'];
  /** 折扣率 */
  discountRate: Scalars['Float'];
  /** 不包含佣金折扣率 */
  discountRateWithoutCommission: Scalars['Float'];
  district?: Maybe<District>;
  /** 縣級、三級行政區 */
  districtId?: Maybe<Scalars['ID']>;
  /** 匯率 */
  exchangeRate?: Maybe<Scalars['Float']>;
  /** 預計交付日 */
  expectDeliveryDate: Scalars['DateTime'];
  /** 預計收款日 */
  expectPaymentDate: Scalars['DateTime'];
  /** 其他折扣金額 */
  extraDiscountAmount: Scalars['Float'];
  /** 眼科報價單商機 */
  eyeQuotationOrderBusinesses: Array<EyeQuotationOrderBusiness>;
  eyeQuotationOrderProductItems: Array<EyeQuotationOrderProductItem>;
  eyeQuotationOrderProducts: Array<EyeQuotationOrderProduct>;
  eyeQuotationOrderPromotions: Array<EyeQuotationOrderPromotion>;
  eyeQuotationOrderType?: Maybe<EyeQuotationOrderType>;
  /** 眼科報價單類別 */
  eyeQuotationOrderTypeId?: Maybe<Scalars['ID']>;
  eyeQuotationOrderWarrantyItems: Array<EyeQuotationOrderWarrantyItem>;
  eyeServiceOrder?: Maybe<EyeServiceOrder>;
  /** 服務單 */
  eyeServiceOrderId?: Maybe<Scalars['ID']>;
  eyeWarrantyPeriodType?: Maybe<EyeWarrantyPeriodType>;
  id: Scalars['ID'];
  invoicingCustomer?: Maybe<Customer>;
  /** 發票客戶 */
  invoicingCustomerId: Scalars['ID'];
  /** 當地幣別 */
  localCurrencyId: Scalars['ID'];
  /** 當地幣別原價 */
  localStandardAmount: Scalars['Float'];
  /** BPM連結 */
  officialSealBpmUrl?: Maybe<Scalars['URL']>;
  /** 用印狀態 */
  officialSealStatus?: Maybe<EnumEyeQuotationOrderOfficialSealStatus>;
  /** 僅配件及耗材 */
  onlyMaterials: Scalars['Boolean'];
  /** 訂單編號 */
  orderCode?: Maybe<Scalars['String']>;
  province?: Maybe<Province>;
  /** 省級、一級行政區 */
  provinceId?: Maybe<Scalars['ID']>;
  /** 實際金額 */
  realAmount: Scalars['Float'];
  /** 實際折扣金額 */
  realDiscountAmount: Scalars['Float'];
  /** 實際折扣率 */
  realDiscountRate: Scalars['Float'];
  region?: Maybe<Region>;
  /** 區域 */
  regionId: Scalars['ID'];
  /** 原價 */
  standardAmount: Scalars['Float'];
  /** 狀態 */
  status: EnumEyeQuotationOrderStatus;
  /** 稅率 */
  taxRate: Scalars['Float'];
  /** 未稅金額 */
  untaxedAmount: Scalars['Float'];
  /** 資料修改日期 */
  updatedAt: Scalars['DateTime'];
  updatedUser?: Maybe<User>;
  /** 資料修改人員 */
  updatedUserId?: Maybe<Scalars['ID']>;
  /** 負責人 */
  user?: Maybe<User>;
  /** 負責人 */
  userId: Scalars['ID'];
  /** 保固購買方式 */
  warrantyBuyType: EnumWarrantyBuyType;
  /** 保固月份 */
  warrantyMonths?: Maybe<Scalars['Int']>;
  /** 保固類型週期 */
  warrantyPeriodTypeId?: Maybe<Scalars['ID']>;
};

export type EyeQuotationOrderBusiness = IEyeQuotationOrderBusiness & {
  /** 商機 */
  business?: Maybe<Business>;
  /** 商機 */
  businessId: Scalars['ID'];
  /** 資料新增日期 */
  createdAt: Scalars['DateTime'];
  /** 眼科報價單 */
  eyeQuotationOrder?: Maybe<EyeQuotationOrder>;
  /** 眼科報價單 */
  eyeQuotationOrderId: Scalars['ID'];
  id: Scalars['ID'];
  /** 資料修改日期 */
  updatedAt: Scalars['DateTime'];
};

export type EyeQuotationOrderCreateInput = {
  address?: InputMaybe<Scalars['String']>;
  /** 招標金額 */
  biddingPrice: Scalars['Float'];
  /** 商機 */
  businessIds?: InputMaybe<Array<Scalars['ID']>>;
  /** 市級、二級行政區 */
  cityId?: InputMaybe<Scalars['ID']>;
  /** 佣金 (研究費) */
  commissionAmount: Scalars['Float'];
  companyId: Scalars['ID'];
  /** 聯絡人 */
  contactPerson: Scalars['String'];
  /** 聯絡電話 */
  contactPhone: Scalars['String'];
  /** 信用期間 */
  creditPeriodId?: InputMaybe<Scalars['ID']>;
  /** 幣別 */
  currencyId: Scalars['ID'];
  customerId: Scalars['ID'];
  /** 負責部門 */
  deptId: Scalars['ID'];
  description?: InputMaybe<Scalars['String']>;
  /** 折扣金額 */
  discountAmount: Scalars['Float'];
  /** 折扣率 */
  discountRate: Scalars['Float'];
  /** 不包含佣金折扣率 */
  discountRateWithoutCommission: Scalars['Float'];
  /** 縣級、三級行政區 */
  districtId?: InputMaybe<Scalars['ID']>;
  /** 預計交貨日期 */
  expectDeliveryDate: Scalars['Date'];
  /** 其他折扣金額 */
  extraDiscountAmount: Scalars['Float'];
  /** 報價單單選商品品項 （額外選擇，與商品無關） */
  eyeQuotationOrderProductItems: Array<EyeQuotationOrderProductItemInput>;
  /** 報價單選擇商品 & 商品品項 */
  eyeQuotationOrderProducts: Array<EyeQuotationOrderProductInput>;
  /** 報價單選擇優惠 & 優惠加贈品項 */
  eyeQuotationOrderPromotions: Array<EyeQuotationOrderPromotionInput>;
  /** 眼科報價單類別 */
  eyeQuotationOrderTypeId?: InputMaybe<Scalars['ID']>;
  /** 單買合約時，合約內需保固的對象商品品項 */
  eyeQuotationOrderWarrantyItems: Array<EyeQuotationOrderWarrantyItemInput>;
  eyeServiceOrderId?: InputMaybe<Scalars['ID']>;
  /** 發票客戶 */
  invoicingCustomerId: Scalars['ID'];
  /** 當地幣別原價 */
  localStandardAmount: Scalars['Float'];
  /** 僅配件及耗材 */
  onlyMaterials?: InputMaybe<Scalars['Boolean']>;
  /** 省級、一級行政區 */
  provinceId?: InputMaybe<Scalars['ID']>;
  /** 實際金額 */
  realAmount: Scalars['Float'];
  /** 實際折扣金額 */
  realDiscountAmount: Scalars['Float'];
  /** 實際折扣率 */
  realDiscountRate: Scalars['Float'];
  regionId: Scalars['ID'];
  /** 原價 */
  standardAmount: Scalars['Float'];
  /** 稅率 */
  taxRateId: Scalars['ID'];
  /** 未稅金額 */
  untaxedAmount: Scalars['Float'];
  /** 負責人 */
  userId: Scalars['ID'];
  /** 保固購買類別 */
  warrantyBuyType: EnumWarrantyBuyType;
  /** 保固月份 */
  warrantyMonths?: InputMaybe<Scalars['Int']>;
  /** 保固週期類型 */
  warrantyPeriodTypeId?: InputMaybe<Scalars['ID']>;
};

export type EyeQuotationOrderOfficialSealInput = {
  /** 報價單 */
  eyeQuotationOrderId: Scalars['ID'];
};

export type EyeQuotationOrderProduct = IEyeQuotationOrderProduct & {
  /** 商品品牌 */
  brand?: Maybe<Scalars['String']>;
  /** 資料新增日期 */
  createdAt: Scalars['DateTime'];
  /** 幣別 */
  currencyId: Scalars['ID'];
  /** 敘述 */
  description?: Maybe<Scalars['String']>;
  /** 匯率 */
  exchangeRate: Scalars['Float'];
  eyeProduct?: Maybe<EyeProduct>;
  /** 眼科商品 */
  eyeProductId: Scalars['ID'];
  /** 眼科報價單 */
  eyeQuotationOrderId: Scalars['ID'];
  id: Scalars['ID'];
  items: Array<EyeQuotationOrderProductItem>;
  /** 商品型號 */
  model?: Maybe<Scalars['String']>;
  /** 商品名稱 */
  name: Scalars['String'];
  /** 數量 */
  qty: Scalars['Int'];
  /** 販售金額 */
  salePrice: Scalars['Float'];
  /** 稅率 */
  taxRate: Scalars['Float'];
  /** 不含稅金額 */
  unitPrice: Scalars['Float'];
  /** 含稅金額 */
  unitPriceVat: Scalars['Float'];
  /** 資料修改日期 */
  updatedAt: Scalars['DateTime'];
};

/** 眼科報價單商品品項 */
export type EyeQuotationOrderProductEyeProductItemInput = {
  /** 眼科商品品項 */
  id: Scalars['ID'];
  /** 數量 */
  qty: Scalars['Int'];
};

/** 眼科報價單商品 */
export type EyeQuotationOrderProductInput = {
  /** 匯率 */
  exchangeRateId?: InputMaybe<Scalars['ID']>;
  /** 眼科商品 */
  eyeProductId: Scalars['ID'];
  /** 眼科商品品項（複數） */
  eyeProductItems: Array<EyeQuotationOrderProductEyeProductItemInput>;
  qty: Scalars['Int'];
};

export type EyeQuotationOrderProductItem = IEyeQuotationOrderProductItem & {
  /** 資料新增日期 */
  createdAt: Scalars['DateTime'];
  /** 敘述 */
  description?: Maybe<Scalars['String']>;
  /** 折扣金額 */
  discountPrice?: Maybe<Scalars['Float']>;
  /** 折扣後販售金額 */
  discountedSellingPrice?: Maybe<Scalars['Float']>;
  /** 匯率 */
  exchangeRate: Scalars['Float'];
  eyeProductItem?: Maybe<EyeProductItem>;
  /** 眼科商品項目 */
  eyeProductItemId?: Maybe<Scalars['ID']>;
  eyePromotionAddonProductItem?: Maybe<EyePromotionAddonProductItem>;
  /** 眼科優惠加購商品項目 */
  eyePromotionAddonProductItemId?: Maybe<Scalars['ID']>;
  /** 眼科報價單 */
  eyeQuotationOrderId: Scalars['ID'];
  eyeQuotationOrderProduct?: Maybe<EyeQuotationOrderProduct>;
  /** 眼科報價單商品 */
  eyeQuotationOrderProductId?: Maybe<Scalars['ID']>;
  eyeQuotationOrderPromotion?: Maybe<EyeQuotationOrderPromotion>;
  /** 眼科報價單優惠 */
  eyeQuotationOrderPromotionId?: Maybe<Scalars['ID']>;
  id: Scalars['ID'];
  /** 料號_編號 */
  materialCode: Scalars['String'];
  /** 料號 */
  materialId: Scalars['ID'];
  /** 料號_型號 */
  materialModel?: Maybe<Scalars['String']>;
  /** 料號_名稱 */
  materialName?: Maybe<Scalars['String']>;
  /** 販售幣別 */
  materialSellingCurrencyId?: Maybe<Scalars['ID']>;
  /** 販售金額 */
  materialSellingPrice?: Maybe<Scalars['Float']>;
  /** 料號_規格／機型 */
  materialSpec?: Maybe<Scalars['String']>;
  /** 料號_計量單位 */
  materialUnit?: Maybe<Scalars['String']>;
  /** 實際數量 */
  qty: Scalars['Int'];
  /** 稅率 */
  taxRate: Scalars['Float'];
  /** 不含稅金額 */
  unitPrice: Scalars['Float'];
  /** 含稅金額 */
  unitPriceVat: Scalars['Float'];
  /** 商品項目單位數量 */
  unitQty: Scalars['Int'];
  /** 資料修改日期 */
  updatedAt: Scalars['DateTime'];
};

/** 眼科報價單商品品項 [額外單選料號] */
export type EyeQuotationOrderProductItemInput = {
  /** 匯率 */
  exchangeRateId?: InputMaybe<Scalars['ID']>;
  materialId: Scalars['ID'];
  /** 販售價格 */
  materialSellingPriceId: Scalars['ID'];
  qty?: Scalars['Int'];
};

export type EyeQuotationOrderPromotion = IEyeQuotationOrderPromotion & {
  /** 資料新增日期 */
  createdAt: Scalars['DateTime'];
  /** 幣別 */
  currencyId: Scalars['ID'];
  /** 敘述 */
  description?: Maybe<Scalars['String']>;
  /** 依照金額折扣 */
  discountAmount?: Maybe<Scalars['Float']>;
  /** 依照比率折扣 */
  discountRate?: Maybe<Scalars['Float']>;
  eyePromotion?: Maybe<EyePromotion>;
  /** 眼科優惠 */
  eyePromotionId: Scalars['ID'];
  /** 眼科報價單 */
  eyeQuotationOrderId: Scalars['ID'];
  id: Scalars['ID'];
  items: Array<EyeQuotationOrderProductItem>;
  /** 優惠名稱 */
  name: Scalars['String'];
  /** 資料修改日期 */
  updatedAt: Scalars['DateTime'];
};

/** 眼科報價單優惠 */
export type EyeQuotationOrderPromotionInput = {
  /** 匯率 */
  exchangeRateId?: InputMaybe<Scalars['ID']>;
  /** 眼科優惠加贈商品（複數） */
  eyePromotionAddonProductItemIds: Array<Scalars['ID']>;
  /** 眼科優惠 */
  eyePromotionId: Scalars['ID'];
};

export type EyeQuotationOrderSearchInput = {
  /** 商機 */
  businessId?: InputMaybe<Scalars['ID']>;
  /** 市級、二級行政區 */
  cityId?: InputMaybe<Scalars['ID']>;
  code?: InputMaybe<Scalars['String']>;
  companyIds?: InputMaybe<Array<Scalars['ID']>>;
  /** 聯絡人 */
  contactPerson?: InputMaybe<Scalars['String']>;
  /** 信用期間 */
  creditPeriodId?: InputMaybe<Scalars['ID']>;
  /** 幣別 */
  currencyId?: InputMaybe<Scalars['ID']>;
  customerCode?: InputMaybe<Scalars['String']>;
  customerId?: InputMaybe<Scalars['ID']>;
  customerName?: InputMaybe<Scalars['String']>;
  /** 負責部門 */
  deptId?: InputMaybe<Scalars['ID']>;
  /** 縣級、三級行政區 */
  districtId?: InputMaybe<Scalars['ID']>;
  /** 預計交貨日期 起 */
  expectDeliveryDate1?: InputMaybe<Scalars['Date']>;
  /** 預計交貨日期 迄 */
  expectDeliveryDate2?: InputMaybe<Scalars['Date']>;
  /** 預計收款日期 起 */
  expectPaymentDate1?: InputMaybe<Scalars['Date']>;
  /** 預計收款日期 迄 */
  expectPaymentDate2?: InputMaybe<Scalars['Date']>;
  /** 眼科報價單類別 */
  eyeQuotationOrderTypeId?: InputMaybe<Scalars['ID']>;
  eyeServiceOrderId?: InputMaybe<Scalars['ID']>;
  /** 合約單單號 */
  eyeWarrantyContractCode?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['ID']>;
  /** 發票客戶 */
  invoicingCustomerId?: InputMaybe<Scalars['ID']>;
  /** 訂單單號 */
  orderCode?: InputMaybe<Scalars['String']>;
  /** 省級、一級行政區 */
  provinceId?: InputMaybe<Scalars['ID']>;
  regionIds?: InputMaybe<Array<Scalars['ID']>>;
  status?: InputMaybe<EnumEyeQuotationOrderStatus>;
  /** 負責人 */
  userId?: InputMaybe<Scalars['ID']>;
  /** 保固購買類別 */
  warrantyBuyType?: InputMaybe<EnumWarrantyBuyType>;
  /** 保固月份 */
  warrantyMonths?: InputMaybe<Scalars['Int']>;
  /** 保固週期類型 */
  warrantyPeriodTypeId?: InputMaybe<Scalars['ID']>;
};

export type EyeQuotationOrderSearchResult = {
  count: Scalars['Int'];
  pageInfo: PageInfo;
  rows: Array<EyeQuotationOrder>;
};

export type EyeQuotationOrderType = IEyeQuotationOrderType & {
  /** 編號 */
  code?: Maybe<EnumEyeQuotationOrderType>;
  company?: Maybe<Company>;
  /** 公司 */
  companyId: Scalars['ID'];
  /** 資料新增日期 */
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  /** 名稱 */
  name: Scalars['String'];
  region?: Maybe<Region>;
  /** 區域 */
  regionId: Scalars['ID'];
  /** 資料修改日期 */
  updatedAt: Scalars['DateTime'];
  /** 檢視順序 */
  viewOrder?: Maybe<Scalars['Int']>;
};

export type EyeQuotationOrderTypeSearchInput = {
  companyIds?: InputMaybe<Array<Scalars['ID']>>;
  id?: InputMaybe<Scalars['ID']>;
  regionIds?: InputMaybe<Array<Scalars['ID']>>;
};

export type EyeQuotationOrderTypeSearchResult = {
  count: Scalars['Int'];
  pageInfo: PageInfo;
  rows: Array<EyeQuotationOrderType>;
};

export type EyeQuotationOrderWarrantyItem = IEyeQuotationOrderWarrantyItem & {
  createdAt?: Maybe<Scalars['DateTime']>;
  eyeQuotationOrderId: Scalars['ID'];
  id: Scalars['ID'];
  materialCode: Scalars['String'];
  materialId: Scalars['ID'];
  materialModel?: Maybe<Scalars['String']>;
  materialName?: Maybe<Scalars['String']>;
  materialSpec?: Maybe<Scalars['String']>;
  materialUnit?: Maybe<Scalars['String']>;
  memo?: Maybe<Scalars['String']>;
  /** 實際數量 */
  qty: Scalars['Int'];
  /** 序號 */
  sn?: Maybe<Scalars['String']>;
  /** 醫療器材單一識別碼 */
  udi?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

/** 眼科報價單保固品項 */
export type EyeQuotationOrderWarrantyItemInput = {
  materialId: Scalars['ID'];
  qty?: Scalars['Int'];
  sn: Scalars['String'];
  udi: Scalars['String'];
};

export type EyeServiceOrder = IEyeServiceOrder & {
  /** 地址 */
  address: Scalars['String'];
  /** 核准狀態 */
  approvalStatus: EnumEyeServiceOrderApprovalStatus;
  /** 核准人 */
  approverUser?: Maybe<User>;
  /** 核准人 */
  approverUserId?: Maybe<Scalars['ID']>;
  /** 指派部門 */
  assigneeDept?: Maybe<Department>;
  /** 指派部門 */
  assigneeDeptId: Scalars['ID'];
  /** 被指派人 */
  assigneeUser?: Maybe<User>;
  /** 被分派人 */
  assigneeUserId?: Maybe<Scalars['ID']>;
  /** BPM Instance id */
  bpmInstanceId?: Maybe<Scalars['ID']>;
  /** BPM連結 */
  bpmUrl?: Maybe<Scalars['URL']>;
  business?: Maybe<Business>;
  /** 商機 */
  businessId?: Maybe<Scalars['ID']>;
  city?: Maybe<City>;
  /** 市級、二級行政區 */
  cityId?: Maybe<Scalars['ID']>;
  code: Scalars['String'];
  company?: Maybe<Company>;
  /** 公司 */
  companyId?: Maybe<Scalars['ID']>;
  /** 聯絡人 */
  contactPerson: Scalars['String'];
  /** 聯絡電話 */
  contactPhone: Scalars['String'];
  /** 成本中心 */
  costCenter?: Maybe<CostCenter>;
  /** 成本中心 */
  costCenterId?: Maybe<Scalars['ID']>;
  createdAt: Scalars['DateTime'];
  createdUser?: Maybe<User>;
  createdUserId?: Maybe<Scalars['ID']>;
  /** 客戶 */
  customer?: Maybe<Customer>;
  /** 對象客戶 */
  customerId?: Maybe<Scalars['ID']>;
  description?: Maybe<Scalars['String']>;
  /** 調度士 */
  dispatcherUser?: Maybe<User>;
  /** 調度士 */
  dispatcherUserId?: Maybe<Scalars['ID']>;
  district?: Maybe<District>;
  /** 縣級、三級行政區 */
  districtId?: Maybe<Scalars['ID']>;
  /** 預計派工日 */
  estimatedDate: Scalars['Date'];
  /** 展延紀錄 */
  extension?: Maybe<EyeServiceOrderExtension>;
  /** 展延次數 */
  extensionCount?: Maybe<Scalars['Int']>;
  /** 眼科固定資產租借目的 */
  eyeFixedAssetRentalGoal?: Maybe<EyeFixedAssetRentalGoal>;
  /** 眼科固定資產租借目的 */
  eyeFixedAssetRentalGoalId?: Maybe<Scalars['ID']>;
  /** 眼科固定資產租借對象 */
  eyeFixedAssetRentalObject?: Maybe<EyeFixedAssetRentalObject>;
  /** 眼科固定資產租借對象 */
  eyeFixedAssetRentalObjectId?: Maybe<Scalars['ID']>;
  eyeFixedAssetRentalRecords: Array<EyeFixedAssetRentalRecord>;
  eyeQuotationOrders: Array<EyeQuotationOrder>;
  eyeServiceOrderAcceptances: Array<EyeServiceOrderAcceptance>;
  eyeServiceOrderAttachFiles: Array<EyeServiceOrderAttachFile>;
  eyeServiceOrderItems: Array<EyeServiceOrderItem>;
  eyeServiceOrderQuestionnaires: Array<EyeServiceOrderQuestionnaire>;
  eyeServiceOrderType?: Maybe<EyeServiceOrderType>;
  eyeServiceOrderTypeId: Scalars['ID'];
  eyeServiceOrderWorkDiaries: Array<EyeServiceOrderWorkDiary>;
  eyeWarrantyContract?: Maybe<EyeWarrantyContract>;
  eyeWarrantyContractId?: Maybe<Scalars['ID']>;
  id: Scalars['ID'];
  memo?: Maybe<Scalars['String']>;
  /** 優先權 */
  priority: EnumEyeServiceOrderPriority;
  province?: Maybe<Province>;
  /** 省級、一級行政區 */
  provinceId?: Maybe<Scalars['ID']>;
  region?: Maybe<Region>;
  regionId: Scalars['ID'];
  status: EnumEyeServiceOrderStatus;
  updatedAt: Scalars['DateTime'];
  updatedUser?: Maybe<User>;
  updatedUserId?: Maybe<Scalars['ID']>;
  /** 申請人 */
  user?: Maybe<User>;
  /** 對象使用者 */
  userId?: Maybe<Scalars['ID']>;
};

export type EyeServiceOrderAcceptance = IEyeServiceOrderAcceptance & {
  answers?: Maybe<Scalars['JSON']>;
  createdAt: Scalars['DateTime'];
  createdUser?: Maybe<User>;
  createdUserId?: Maybe<Scalars['ID']>;
  extension: Scalars['String'];
  eyeProductAcceptanceFormId: Scalars['ID'];
  eyeServiceOrderId: Scalars['ID'];
  eyeServiceOrderItemId?: Maybe<Scalars['ID']>;
  id: Scalars['ID'];
  memo?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  s3Key: Scalars['String'];
  updatedAt: Scalars['DateTime'];
  updatedUser?: Maybe<User>;
  updatedUserId?: Maybe<Scalars['ID']>;
};

export type EyeServiceOrderAcceptanceCreateInput = {
  answer: Scalars['JSON'];
  extension: Scalars['String'];
  eyeProductAcceptanceFormId: Scalars['ID'];
  eyeServiceOrderId: Scalars['ID'];
  eyeServiceOrderItemId: Scalars['ID'];
  memo?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  s3Key: Scalars['String'];
};

export type EyeServiceOrderAcceptanceSearchInput = {
  eyeProductAcceptanceFormId?: InputMaybe<Scalars['ID']>;
  eyeServiceOrderId?: InputMaybe<Scalars['ID']>;
  eyeServiceOrderItemId?: InputMaybe<Scalars['ID']>;
  id?: InputMaybe<Scalars['ID']>;
  name?: InputMaybe<Scalars['String']>;
};

export type EyeServiceOrderAcceptanceSearchResult = {
  count: Scalars['Int'];
  pageInfo: PageInfo;
  rows: Array<EyeServiceOrderAcceptance>;
};

export type EyeServiceOrderAcceptanceUpdateInput = {
  answer?: InputMaybe<Scalars['JSON']>;
  extension?: InputMaybe<Scalars['String']>;
  eyeProductAcceptanceFormId?: InputMaybe<Scalars['ID']>;
  eyeServiceOrderId?: InputMaybe<Scalars['ID']>;
  eyeServiceOrderItemId?: InputMaybe<Scalars['ID']>;
  id: Scalars['ID'];
  memo?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  s3Key?: InputMaybe<Scalars['String']>;
};

export type EyeServiceOrderApprovalStatusUpdateInput = {
  /** 核准狀態 */
  approvalStatus?: InputMaybe<EnumEyeServiceOrderApprovalStatus>;
  id: Scalars['ID'];
};

export type EyeServiceOrderAttachFile = IEyeServiceOrderAttachFile & {
  createdAt: Scalars['DateTime'];
  createdUser?: Maybe<User>;
  createdUserId?: Maybe<Scalars['ID']>;
  extension: Scalars['String'];
  eyeServiceOrderId: Scalars['ID'];
  id: Scalars['ID'];
  memo?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  s3Key: Scalars['String'];
  /** 檔案大小(Bytes) */
  size: Scalars['Float'];
  updatedAt: Scalars['DateTime'];
  updatedUser?: Maybe<User>;
  updatedUserId?: Maybe<Scalars['ID']>;
  /** 短效期的S3 url */
  url?: Maybe<Scalars['URL']>;
};

export type EyeServiceOrderAttachFileCreateInput = {
  eyeServiceOrderId: Scalars['ID'];
  file: Scalars['Upload'];
  memo?: InputMaybe<Scalars['String']>;
  /** 檔案名稱 */
  name: Scalars['String'];
};

export type EyeServiceOrderAttachFileSearchInput = {
  eyeServiceOrderId?: InputMaybe<Scalars['ID']>;
  id?: InputMaybe<Scalars['ID']>;
};

export type EyeServiceOrderAttachFileSearchResult = {
  count: Scalars['Int'];
  pageInfo: PageInfo;
  rows: Array<EyeServiceOrderAttachFile>;
};

export type EyeServiceOrderAttachFileUpdateInput = {
  id: Scalars['ID'];
  memo?: InputMaybe<Scalars['String']>;
  /** 檔案名稱 */
  name?: InputMaybe<Scalars['String']>;
};

export type EyeServiceOrderCreateInput = {
  /** 地址 */
  address: Scalars['String'];
  /** 核准人 */
  approverUserId?: InputMaybe<Scalars['ID']>;
  /** 指派部門 */
  assigneeDeptId: Scalars['ID'];
  /** 被分派人 */
  assigneeUserId?: InputMaybe<Scalars['ID']>;
  /** 商機 */
  businessId?: InputMaybe<Scalars['ID']>;
  /** 市級、二級行政區 */
  cityId?: InputMaybe<Scalars['ID']>;
  companyId?: InputMaybe<Scalars['ID']>;
  /** 聯絡人 */
  contactPerson: Scalars['String'];
  /** 聯絡電話 */
  contactPhone: Scalars['String'];
  /** 成本中心 */
  costCenterId?: InputMaybe<Scalars['ID']>;
  customerId?: InputMaybe<Scalars['ID']>;
  description?: InputMaybe<Scalars['String']>;
  /** 調度士 */
  dispatcherUserId?: InputMaybe<Scalars['ID']>;
  /** 縣級、三級行政區 */
  districtId?: InputMaybe<Scalars['ID']>;
  /** 預計派工日 */
  estimatedDate: Scalars['Date'];
  /** 眼科固定資產租借目的 */
  eyeFixedAssetRentalGoalId?: InputMaybe<Scalars['ID']>;
  /** 眼科固定資產租借對象 */
  eyeFixedAssetRentalObjectId?: InputMaybe<Scalars['ID']>;
  /** 固資租借 */
  eyeFixedAssetRentalRecords?: InputMaybe<
    Array<EyeFixedAssetRentalRecordBaseCreateInput>
  >;
  eyeServiceOrderTypeId: Scalars['ID'];
  eyeWarrantyContractId?: InputMaybe<Scalars['ID']>;
  /** 服務單項目（維修商品） */
  items?: InputMaybe<Array<EyeServiceOrderItemBaseCreateInput>>;
  memo?: InputMaybe<Scalars['String']>;
  /** 優先權 */
  priority: EnumEyeServiceOrderPriority;
  /** 省級、一級行政區 */
  provinceId?: InputMaybe<Scalars['ID']>;
  regionId: Scalars['ID'];
  /** 申請人 */
  userId?: InputMaybe<Scalars['ID']>;
};

export type EyeServiceOrderExtension = IEyeServiceOrderExtension & {
  approvalStatus: EnumEyeServiceOrderExtensionApprovalStatus;
  bpmInstanceId?: Maybe<Scalars['ID']>;
  /** 展延BPM連結 */
  bpmUrl?: Maybe<Scalars['URL']>;
  createdAt: Scalars['DateTime'];
  extendDate1: Scalars['Date'];
  extendDate2: Scalars['Date'];
  eyeServiceOrderId: Scalars['ID'];
  id: Scalars['ID'];
  memo?: Maybe<Scalars['String']>;
  updatedAt: Scalars['DateTime'];
};

export type EyeServiceOrderItem = IEyeServiceOrderItem & {
  createdAt: Scalars['DateTime'];
  createdUser?: Maybe<User>;
  createdUserId?: Maybe<Scalars['ID']>;
  eyeServiceOrderId: Scalars['ID'];
  id: Scalars['ID'];
  materialCode: Scalars['String'];
  materialId: Scalars['ID'];
  materialModel?: Maybe<Scalars['String']>;
  materialName?: Maybe<Scalars['String']>;
  materialSpec?: Maybe<Scalars['String']>;
  materialUnit?: Maybe<Scalars['String']>;
  memo?: Maybe<Scalars['String']>;
  qty: Scalars['Int'];
  sn?: Maybe<Scalars['String']>;
  /** 醫療器材單一識別碼 */
  udi: Scalars['String'];
  updatedAt: Scalars['DateTime'];
  updatedUser?: Maybe<User>;
  updatedUserId?: Maybe<Scalars['ID']>;
};

export type EyeServiceOrderItemBaseCreateInput = {
  materialId: Scalars['ID'];
  memo?: InputMaybe<Scalars['String']>;
  qty: Scalars['Int'];
  sn?: InputMaybe<Scalars['String']>;
  /** 醫療器材單一識別碼 */
  udi: Scalars['String'];
};

export type EyeServiceOrderItemCreateInput = {
  eyeServiceOrderId: Scalars['ID'];
  materialId: Scalars['ID'];
  memo?: InputMaybe<Scalars['String']>;
  qty: Scalars['Int'];
  sn?: InputMaybe<Scalars['String']>;
  /** 醫療器材單一識別碼 */
  udi: Scalars['String'];
};

export type EyeServiceOrderItemSearchInput = {
  eyeServiceOrderId?: InputMaybe<Scalars['ID']>;
  id?: InputMaybe<Scalars['ID']>;
  materialCode?: InputMaybe<Scalars['String']>;
  materialId?: InputMaybe<Scalars['ID']>;
  qty?: InputMaybe<Scalars['Int']>;
  sn?: InputMaybe<Scalars['String']>;
  udi?: InputMaybe<Scalars['String']>;
};

export type EyeServiceOrderItemSearchResult = {
  count: Scalars['Int'];
  pageInfo: PageInfo;
  rows: Array<EyeServiceOrderItem>;
};

export type EyeServiceOrderItemUpdateInput = {
  id: Scalars['ID'];
  memo?: InputMaybe<Scalars['String']>;
  qty?: InputMaybe<Scalars['Int']>;
  sn?: InputMaybe<Scalars['String']>;
  udi?: InputMaybe<Scalars['String']>;
};

export type EyeServiceOrderQuestionnaire = IEyeServiceOrderQuestionnaire & {
  answers?: Maybe<Scalars['JSON']>;
  createdAt: Scalars['DateTime'];
  createdUser?: Maybe<User>;
  createdUserId?: Maybe<Scalars['ID']>;
  eyeServiceOrderId: Scalars['ID'];
  id: Scalars['ID'];
  questionnaire?: Maybe<Questionnaire>;
  questionnaireId: Scalars['ID'];
  score?: Maybe<Scalars['Int']>;
  status: Scalars['Int'];
  updatedAt: Scalars['DateTime'];
  updatedUser?: Maybe<User>;
  updatedUserId?: Maybe<Scalars['ID']>;
};

export type EyeServiceOrderQuestionnaireCreateInput = {
  answer: Scalars['JSON'];
  eyeServiceOrderId: Scalars['ID'];
  questionnaireId: Scalars['ID'];
  score: Scalars['Int'];
  status: Scalars['Int'];
};

export type EyeServiceOrderQuestionnaireSearchInput = {
  eyeServiceOrderId?: InputMaybe<Scalars['ID']>;
  id?: InputMaybe<Scalars['ID']>;
  questionnaireId?: InputMaybe<Scalars['ID']>;
};

export type EyeServiceOrderQuestionnaireSearchResult = {
  count: Scalars['Int'];
  pageInfo: PageInfo;
  rows: Array<EyeServiceOrderQuestionnaire>;
};

export type EyeServiceOrderQuestionnaireUpdateInput = {
  answer?: InputMaybe<Scalars['JSON']>;
  eyeServiceOrderId?: InputMaybe<Scalars['ID']>;
  id: Scalars['ID'];
  questionnaireId?: InputMaybe<Scalars['ID']>;
  score?: InputMaybe<Scalars['Int']>;
  status?: InputMaybe<Scalars['Int']>;
};

export type EyeServiceOrderRequestExtensionInput = {
  /** 展延日 起 */
  extendDate1: Scalars['Date'];
  /** 展延日 迄 */
  extendDate2: Scalars['Date'];
  eyeServiceOrderId: Scalars['ID'];
  /** 展延備註 */
  memo?: InputMaybe<Scalars['String']>;
};

export type EyeServiceOrderSearchInput = {
  /** 核准狀態 */
  approvalStatus?: InputMaybe<EnumEyeServiceOrderApprovalStatus>;
  /** 核准人 */
  approverUserId?: InputMaybe<Scalars['ID']>;
  /** 指派部門 */
  assigneeDeptId?: InputMaybe<Scalars['ID']>;
  assigneeDeptIds?: InputMaybe<Array<Scalars['ID']>>;
  /** 被分派人 */
  assigneeUserId?: InputMaybe<Scalars['ID']>;
  /** 商機 */
  businessId?: InputMaybe<Scalars['ID']>;
  /** 市級、二級行政區 */
  cityId?: InputMaybe<Scalars['ID']>;
  code?: InputMaybe<Scalars['String']>;
  companyIds?: InputMaybe<Array<Scalars['ID']>>;
  /** 聯絡人 */
  contactPerson?: InputMaybe<Scalars['String']>;
  /** 聯絡電話 */
  contactPhone?: InputMaybe<Scalars['String']>;
  /** 成本中心 */
  costCenterId?: InputMaybe<Scalars['ID']>;
  /** 建立人 */
  createdUserId?: InputMaybe<Scalars['ID']>;
  customerCode?: InputMaybe<Scalars['String']>;
  customerId?: InputMaybe<Scalars['ID']>;
  customerName?: InputMaybe<Scalars['String']>;
  /** 縣級、三級行政區 */
  districtId?: InputMaybe<Scalars['ID']>;
  /** 預計派工日 起 */
  estimatedDate1?: InputMaybe<Scalars['Date']>;
  /** 預計派工日 迄 */
  estimatedDate2?: InputMaybe<Scalars['Date']>;
  /** 眼科固定資產租借目的 */
  eyeFixedAssetRentalGoalId?: InputMaybe<Scalars['ID']>;
  /** 眼科固定資產租借對象 */
  eyeFixedAssetRentalObjectId?: InputMaybe<Scalars['ID']>;
  /** 固資租借狀態 */
  eyeFixedAssetsRentalStatuses?: InputMaybe<
    Array<EnumEyeFixedAssetsRentalStatus>
  >;
  /** 服務單類別code */
  eyeServiceOrderTypeCodes?: InputMaybe<Array<EnumEyeServiceOrderTypeCode>>;
  eyeServiceOrderTypeId?: InputMaybe<Scalars['ID']>;
  eyeWarrantyContractCode?: InputMaybe<Scalars['String']>;
  eyeWarrantyContractId?: InputMaybe<Scalars['ID']>;
  id?: InputMaybe<Scalars['ID']>;
  /** 優先權 */
  priority?: InputMaybe<EnumEyeServiceOrderPriority>;
  /** 省級、一級行政區 */
  provinceId?: InputMaybe<Scalars['ID']>;
  regionIds?: InputMaybe<Array<Scalars['ID']>>;
  /** 模糊搜尋: 服務單單號、預計派工日 */
  searchAll?: InputMaybe<Scalars['String']>;
  statuses?: InputMaybe<Array<EnumEyeServiceOrderStatus>>;
  /** 申請人 */
  userId?: InputMaybe<Scalars['ID']>;
};

export type EyeServiceOrderSearchResult = {
  count: Scalars['Int'];
  pageInfo: PageInfo;
  rows: Array<EyeServiceOrder>;
};

export type EyeServiceOrderType = IEyeServiceOrderType & {
  /** 編號 */
  code?: Maybe<EnumEyeServiceOrderTypeCode>;
  id: Scalars['ID'];
  name: Scalars['String'];
  viewOrder?: Maybe<Scalars['Int']>;
};

export type EyeServiceOrderTypeSearchInput = {
  codes?: InputMaybe<Array<EnumEyeServiceOrderTypeCode>>;
  id?: InputMaybe<Scalars['ID']>;
  regionIds?: InputMaybe<Array<Scalars['ID']>>;
};

export type EyeServiceOrderTypeSearchResult = {
  count: Scalars['Int'];
  pageInfo: PageInfo;
  rows: Array<EyeServiceOrderType>;
};

export type EyeServiceOrderUpdateInput = {
  /** 地址 */
  address?: InputMaybe<Scalars['String']>;
  /** 核准人 */
  approverUserId?: InputMaybe<Scalars['ID']>;
  /** 指派部門 */
  assigneeDeptId?: InputMaybe<Scalars['ID']>;
  /** 被分派人 */
  assigneeUserId?: InputMaybe<Scalars['ID']>;
  /** 商機 */
  businessId?: InputMaybe<Scalars['ID']>;
  /** 市級、二級行政區 */
  cityId?: InputMaybe<Scalars['ID']>;
  /** 聯絡人 */
  contactPerson?: InputMaybe<Scalars['String']>;
  /** 聯絡電話 */
  contactPhone?: InputMaybe<Scalars['String']>;
  /** 成本中心 */
  costCenterId?: InputMaybe<Scalars['ID']>;
  description?: InputMaybe<Scalars['String']>;
  /** 調度士 */
  dispatcherUserId?: InputMaybe<Scalars['ID']>;
  /** 縣級、三級行政區 */
  districtId?: InputMaybe<Scalars['ID']>;
  /** 預計派工日 */
  estimatedDate?: InputMaybe<Scalars['Date']>;
  /** 眼科固定資產租借目的 */
  eyeFixedAssetRentalGoalId?: InputMaybe<Scalars['ID']>;
  /** 眼科固定資產租借對象 */
  eyeFixedAssetRentalObjectId?: InputMaybe<Scalars['ID']>;
  eyeServiceOrderTypeId?: InputMaybe<Scalars['ID']>;
  id: Scalars['ID'];
  memo?: InputMaybe<Scalars['String']>;
  /** 優先權 */
  priority?: InputMaybe<EnumEyeServiceOrderPriority>;
  /** 省級、一級行政區 */
  provinceId?: InputMaybe<Scalars['ID']>;
  status?: InputMaybe<EnumEyeServiceOrderStatus>;
};

export type EyeServiceOrderWorkDiary = IEyeServiceOrderWorkDiary & {
  /** 公司 */
  companyId?: Maybe<Scalars['ID']>;
  createdAt: Scalars['DateTime'];
  createdUser?: Maybe<User>;
  createdUserId?: Maybe<Scalars['ID']>;
  /** 實際服務日期 */
  date?: Maybe<Scalars['DateTime']>;
  /** 預計服務日期 */
  estimatedDate?: Maybe<Scalars['DateTime']>;
  /** 預計服務時數 */
  estimatedHours?: Maybe<Scalars['Int']>;
  eyeFixedAssetRentalRecordId?: Maybe<Scalars['ID']>;
  eyeServiceOrder?: Maybe<EyeServiceOrder>;
  eyeServiceOrderId: Scalars['ID'];
  eyeServiceOrderWorkDiaryAttachFile: Array<EyeServiceOrderWorkDiaryAttachFile>;
  eyeServiceOrderWorkDiaryItems: Array<EyeServiceOrderWorkDiaryItem>;
  eyeServiceOrderWorkDiaryType?: Maybe<EyeServiceOrderWorkDiaryType>;
  eyeServiceOrderWorkDiaryTypeId: Scalars['ID'];
  /** 實際服務時數 */
  hours?: Maybe<Scalars['Int']>;
  id: Scalars['ID'];
  region?: Maybe<Region>;
  regionId: Scalars['ID'];
  remark?: Maybe<Scalars['String']>;
  status: EnumEyeServiceOrderWorkDiaryStatus;
  updatedAt: Scalars['DateTime'];
  updatedUser?: Maybe<User>;
  updatedUserId?: Maybe<Scalars['ID']>;
  /** 眼科服務單工作記錄/相關人員 */
  users: Array<User>;
};

export type EyeServiceOrderWorkDiaryAttachFile =
  IEyeServiceOrderWorkDiaryAttachFile & {
    createdAt: Scalars['DateTime'];
    createdUser?: Maybe<User>;
    createdUserId?: Maybe<Scalars['ID']>;
    extension: Scalars['String'];
    eyeServiceOrderWorkDiaryId: Scalars['ID'];
    id: Scalars['ID'];
    memo?: Maybe<Scalars['String']>;
    name: Scalars['String'];
    s3Key: Scalars['String'];
    /** 檔案大小(Bytes) */
    size: Scalars['Float'];
    updatedAt: Scalars['DateTime'];
    updatedUser?: Maybe<User>;
    updatedUserId?: Maybe<Scalars['ID']>;
    /** 短效期的S3 url */
    url?: Maybe<Scalars['URL']>;
  };

export type EyeServiceOrderWorkDiaryAttachFileCreateInput = {
  eyeServiceOrderWorkDiaryId: Scalars['ID'];
  file: Scalars['Upload'];
  memo?: InputMaybe<Scalars['String']>;
  /** 檔案名稱 */
  name: Scalars['String'];
};

export type EyeServiceOrderWorkDiaryAttachFileSearchInput = {
  eyeServiceOrderWorkDiaryId?: InputMaybe<Scalars['ID']>;
  id?: InputMaybe<Scalars['ID']>;
  regionIds?: InputMaybe<Array<Scalars['ID']>>;
};

export type EyeServiceOrderWorkDiaryAttachFileSearchResult = {
  count: Scalars['Int'];
  pageInfo: PageInfo;
  rows: Array<EyeServiceOrderWorkDiaryAttachFile>;
};

export type EyeServiceOrderWorkDiaryAttachFileUpdateInput = {
  id: Scalars['ID'];
  memo?: InputMaybe<Scalars['String']>;
  /** 檔案名稱 */
  name?: InputMaybe<Scalars['String']>;
};

export type EyeServiceOrderWorkDiaryCheckIn =
  IEyeServiceOrderWorkDiaryCheckIn & {
    /** 內容 */
    content?: Maybe<Scalars['String']>;
    /** 資料新增日期 */
    createdAt: Scalars['DateTime'];
    createdUser?: Maybe<User>;
    /** 資料建立人員 */
    createdUserId?: Maybe<Scalars['ID']>;
    eyeServiceOrderWorkDiary?: Maybe<EyeServiceOrderWorkDiary>;
    /** 眼科服務單工作記錄 */
    eyeServiceOrderWorkDiaryId: Scalars['ID'];
    id: Scalars['ID'];
    /** WGS84 緯度 */
    lat?: Maybe<Scalars['Float']>;
    /** WGS84 經度 */
    lng?: Maybe<Scalars['Float']>;
    /** 資料修改日期 */
    updatedAt: Scalars['DateTime'];
    updatedUser?: Maybe<User>;
    /** 資料修改人員 */
    updatedUserId?: Maybe<Scalars['ID']>;
  };

export type EyeServiceOrderWorkDiaryCheckInCreateInput = {
  /** 內容 */
  content?: InputMaybe<Scalars['String']>;
  /** 眼科服務單工作記錄 */
  eyeServiceOrderWorkDiaryId: Scalars['ID'];
  /** WGS84 緯度 */
  lat: Scalars['Float'];
  /** WGS84 經度 */
  lng: Scalars['Float'];
};

export type EyeServiceOrderWorkDiaryCheckInSearchInput = {
  /** 眼科服務單工作記錄 */
  eyeServiceOrderWorkDiaryId?: InputMaybe<Scalars['ID']>;
  id?: InputMaybe<Scalars['ID']>;
  regionIds?: InputMaybe<Array<Scalars['ID']>>;
};

export type EyeServiceOrderWorkDiaryCheckInSearchResult = {
  count: Scalars['Int'];
  pageInfo: PageInfo;
  rows: Array<EyeServiceOrderWorkDiaryCheckIn>;
};

export type EyeServiceOrderWorkDiaryCheckInUpdateInput = {
  /** 內容 */
  content?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
};

export type EyeServiceOrderWorkDiaryCreateInput = {
  /** 實際服務日期 */
  date?: InputMaybe<Scalars['DateTime']>;
  /** 預計服務日期 */
  estimatedDate?: InputMaybe<Scalars['DateTime']>;
  /** 預計服務時數 */
  estimatedHours?: InputMaybe<Scalars['Int']>;
  eyeFixedAssetRentalRecordId?: InputMaybe<Scalars['ID']>;
  eyeServiceOrderId: Scalars['ID'];
  eyeServiceOrderWorkDiaryTypeId: Scalars['ID'];
  /** 實際服務時數 */
  hours?: InputMaybe<Scalars['Int']>;
  remark?: InputMaybe<Scalars['String']>;
  status?: EnumEyeServiceOrderWorkDiaryStatus;
  userIds: Array<Scalars['ID']>;
};

export type EyeServiceOrderWorkDiaryItem = IEyeServiceOrderWorkDiaryItem & {
  createdAt?: Maybe<Scalars['DateTime']>;
  eyeServiceOrderWorkDiary?: Maybe<EyeServiceOrderWorkDiary>;
  eyeServiceOrderWorkDiaryId: Scalars['ID'];
  id: Scalars['ID'];
  material?: Maybe<Material>;
  materialCode: Scalars['String'];
  materialId: Scalars['ID'];
  materialModel?: Maybe<Scalars['String']>;
  materialName?: Maybe<Scalars['String']>;
  materialSpec?: Maybe<Scalars['String']>;
  materialUnit?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type EyeServiceOrderWorkDiaryItemCreateInput = {
  eyeServiceOrderWorkDiaryId: Scalars['ID'];
  materialId: Scalars['ID'];
};

export type EyeServiceOrderWorkDiaryItemSearchInput = {
  eyeServiceOrderWorkDiaryId?: InputMaybe<Scalars['ID']>;
  id?: InputMaybe<Scalars['ID']>;
  materialId?: InputMaybe<Scalars['ID']>;
};

export type EyeServiceOrderWorkDiaryItemSearchResult = {
  count: Scalars['Int'];
  pageInfo: PageInfo;
  rows: Array<EyeServiceOrderWorkDiaryItem>;
};

export type EyeServiceOrderWorkDiaryItemUpdateInput = {
  id: Scalars['ID'];
};

export type EyeServiceOrderWorkDiarySearchInput = {
  /** 被指派部門 */
  assigneeDeptId?: InputMaybe<Scalars['ID']>;
  /** 服務單客戶編號 */
  customerCode?: InputMaybe<Scalars['String']>;
  /** 服務單客戶姓名 */
  customerName?: InputMaybe<Scalars['String']>;
  /** 實際服務日期 起 */
  date1?: InputMaybe<Scalars['DateTime']>;
  /** 實際服務日期 迄 */
  date2?: InputMaybe<Scalars['DateTime']>;
  /** 預計服務日期 起 */
  estimatedDate1?: InputMaybe<Scalars['DateTime']>;
  /** 預計服務日期 迄 */
  estimatedDate2?: InputMaybe<Scalars['DateTime']>;
  /** 眼科固資租借Id */
  eyeFixedAssetRentalRecordId?: InputMaybe<Scalars['ID']>;
  /** 眼科服務單Id */
  eyeServiceOrderId?: InputMaybe<Scalars['ID']>;
  eyeServiceOrderStatus?: InputMaybe<EnumEyeServiceOrderStatus>;
  eyeServiceOrderWorkDiaryTypeId?: InputMaybe<Scalars['ID']>;
  /** 眼科保固合約Id */
  eyeWarrantyContractId?: InputMaybe<Scalars['ID']>;
  id?: InputMaybe<Scalars['ID']>;
  regionIds?: InputMaybe<Array<Scalars['ID']>>;
  status?: InputMaybe<EnumEyeServiceOrderWorkDiaryStatus>;
  /** 工作記錄相關人員 */
  userIds?: InputMaybe<Array<Scalars['ID']>>;
};

export type EyeServiceOrderWorkDiarySearchResult = {
  count: Scalars['Int'];
  pageInfo: PageInfo;
  rows: Array<EyeServiceOrderWorkDiary>;
};

export type EyeServiceOrderWorkDiaryType = IEyeServiceOrderWorkDiaryType & {
  createdAt?: Maybe<Scalars['DateTime']>;
  eyeServiceOrderWorkDiary: Array<EyeServiceOrderWorkDiary>;
  id: Scalars['ID'];
  name: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type EyeServiceOrderWorkDiaryTypeCreateInput = {
  name: Scalars['String'];
};

export type EyeServiceOrderWorkDiaryTypeSearchInput = {
  id?: InputMaybe<Scalars['ID']>;
  name?: InputMaybe<Scalars['String']>;
};

export type EyeServiceOrderWorkDiaryTypeSearchResult = {
  count: Scalars['Int'];
  pageInfo: PageInfo;
  rows: Array<EyeServiceOrderWorkDiaryType>;
};

export type EyeServiceOrderWorkDiaryTypeUpdateInput = {
  id: Scalars['ID'];
  name?: InputMaybe<Scalars['String']>;
};

export type EyeServiceOrderWorkDiaryUpdateInput = {
  date?: InputMaybe<Scalars['DateTime']>;
  /** 預計服務日期 */
  estimatedDate?: InputMaybe<Scalars['DateTime']>;
  /** 預計服務時數 */
  estimatedHours?: InputMaybe<Scalars['Int']>;
  eyeServiceOrderWorkDiaryTypeId?: InputMaybe<Scalars['ID']>;
  hours?: InputMaybe<Scalars['Int']>;
  id: Scalars['ID'];
  remark?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<EnumEyeServiceOrderWorkDiaryStatus>;
  userIds?: InputMaybe<Array<Scalars['ID']>>;
};

export type EyeSoldProduct = IEyeSoldProduct & {
  /** 公司 */
  companyId: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  createdUser?: Maybe<User>;
  createdUserId?: Maybe<Scalars['ID']>;
  customer?: Maybe<Customer>;
  customerCode: Scalars['String'];
  customerId: Scalars['ID'];
  deptCode: Scalars['String'];
  deptId: Scalars['ID'];
  description?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  materialCode: Scalars['String'];
  materialId: Scalars['ID'];
  materialModel?: Maybe<Scalars['String']>;
  materialName?: Maybe<Scalars['String']>;
  materialSpec?: Maybe<Scalars['String']>;
  materialUnit?: Maybe<Scalars['String']>;
  memo?: Maybe<Scalars['String']>;
  orderCode: Scalars['String'];
  region?: Maybe<Region>;
  regionId: Scalars['ID'];
  shippingDate: Scalars['Date'];
  shippingOrderCode: Scalars['String'];
  sn?: Maybe<Scalars['String']>;
  status: Scalars['Int'];
  /** 醫療器材單一識別碼 */
  udi?: Maybe<Scalars['String']>;
  updatedAt: Scalars['DateTime'];
  updatedUser?: Maybe<User>;
  updatedUserId?: Maybe<Scalars['ID']>;
  userCode: Scalars['String'];
  userId: Scalars['ID'];
};

export type EyeSoldProductSearchInput = {
  id?: InputMaybe<Scalars['ID']>;
};

export type EyeSoldProductSearchResult = {
  count: Scalars['Int'];
  pageInfo: PageInfo;
  rows: Array<EyeSoldProduct>;
};

export type EyeWarrantyContract = IEyeWarrantyContract & {
  /** 地址 */
  address: Scalars['String'];
  /** 合約審查號 */
  approvalCode: Scalars['String'];
  city?: Maybe<City>;
  /** 市級、二級行政區 */
  cityId?: Maybe<Scalars['ID']>;
  /** 合約編號 */
  code: Scalars['String'];
  company?: Maybe<Company>;
  /** 公司 */
  companyId: Scalars['ID'];
  /** 聯絡人 */
  contactPerson: Scalars['String'];
  /** 聯絡電話 */
  contactPhone: Scalars['String'];
  createdAt: Scalars['DateTime'];
  createdUser?: Maybe<User>;
  createdUserId?: Maybe<Scalars['ID']>;
  customer?: Maybe<Customer>;
  /** 客戶 */
  customerId: Scalars['ID'];
  dept?: Maybe<Department>;
  /** 負責部門 */
  deptId: Scalars['ID'];
  description?: Maybe<Scalars['String']>;
  district?: Maybe<District>;
  /** 縣級、三級行政區 */
  districtId?: Maybe<Scalars['ID']>;
  /** 眼科服務單 */
  eyeServiceOrder: Array<EyeServiceOrder>;
  eyeWarrantyContractItems: Array<EyeWarrantyContractItem>;
  eyeWarrantyPeriodType?: Maybe<EyeWarrantyPeriodType>;
  id: Scalars['ID'];
  memo?: Maybe<Scalars['String']>;
  /** 訂單編號 */
  orderCode: Scalars['String'];
  province?: Maybe<Province>;
  /** 省級、一級行政區 */
  provinceId?: Maybe<Scalars['ID']>;
  region?: Maybe<Region>;
  regionId: Scalars['ID'];
  status: EnumEyeWarrantyContractStatus;
  updatedAt: Scalars['DateTime'];
  updatedUser?: Maybe<User>;
  updatedUserId?: Maybe<Scalars['ID']>;
  /** 主要負責人 */
  user?: Maybe<User>;
  /** 負責人 */
  userId: Scalars['ID'];
  /** 保固起始日 */
  warrantyDate1: Scalars['Date'];
  /** 保固結束日 */
  warrantyDate2: Scalars['Date'];
  /** 保固月份 */
  warrantyMonths: Scalars['Int'];
  /** 保固週期類型 */
  warrantyPeriodTypeId: Scalars['ID'];
};

export type EyeWarrantyContractCreateInput = {
  /** 地址 */
  address: Scalars['String'];
  /** 合約審查號 */
  approvalCode: Scalars['String'];
  /** 市級、二級行政區 */
  cityId?: InputMaybe<Scalars['ID']>;
  companyId: Scalars['ID'];
  /** 聯絡人 */
  contactPerson: Scalars['String'];
  /** 聯絡電話 */
  contactPhone: Scalars['String'];
  customerId: Scalars['ID'];
  deptId: Scalars['ID'];
  description?: InputMaybe<Scalars['String']>;
  /** 縣級、三級行政區 */
  districtId?: InputMaybe<Scalars['ID']>;
  items?: InputMaybe<Array<EyeWarrantyContractItemBaseCreateInput>>;
  memo?: InputMaybe<Scalars['String']>;
  /** 訂單單號 */
  orderCode: Scalars['String'];
  /** 省級、一級行政區 */
  provinceId?: InputMaybe<Scalars['ID']>;
  regionId: Scalars['ID'];
  userId: Scalars['ID'];
  warrantyDate1: Scalars['Date'];
  warrantyDate2: Scalars['Date'];
  warrantyMonths: Scalars['Int'];
  warrantyPeriodTypeId: Scalars['ID'];
};

export type EyeWarrantyContractItem = IEyeWarrantyContractItem & {
  createdAt: Scalars['DateTime'];
  eyeWarrantyContract?: Maybe<EyeWarrantyContract>;
  eyeWarrantyContractId: Scalars['ID'];
  id: Scalars['ID'];
  materialCode: Scalars['String'];
  materialId: Scalars['ID'];
  materialModel?: Maybe<Scalars['String']>;
  materialName?: Maybe<Scalars['String']>;
  materialSpec?: Maybe<Scalars['String']>;
  materialUnit?: Maybe<Scalars['String']>;
  memo?: Maybe<Scalars['String']>;
  qty: Scalars['Int'];
  /** 序號 */
  sn?: Maybe<Scalars['String']>;
  /** 醫療器材單一識別碼 */
  udi?: Maybe<Scalars['String']>;
  updatedAt: Scalars['DateTime'];
};

export type EyeWarrantyContractItemBaseCreateInput = {
  materialId: Scalars['ID'];
  memo?: InputMaybe<Scalars['String']>;
  qty?: Scalars['Int'];
  sn?: InputMaybe<Scalars['String']>;
  udi?: InputMaybe<Scalars['String']>;
};

export type EyeWarrantyContractItemCreateInput = {
  eyeWarrantyContractId: Scalars['ID'];
  materialId: Scalars['ID'];
  memo?: InputMaybe<Scalars['String']>;
  qty?: Scalars['Int'];
  sn?: InputMaybe<Scalars['String']>;
  udi?: InputMaybe<Scalars['String']>;
};

export type EyeWarrantyContractItemSearchInput = {
  eyeWarrantyContractId?: InputMaybe<Scalars['ID']>;
  id?: InputMaybe<Scalars['ID']>;
  materialId?: InputMaybe<Scalars['ID']>;
};

export type EyeWarrantyContractItemSearchResult = {
  count: Scalars['Int'];
  pageInfo: PageInfo;
  rows: Array<EyeWarrantyContractItem>;
};

export type EyeWarrantyContractItemUpdateInput = {
  id: Scalars['ID'];
  memo?: InputMaybe<Scalars['String']>;
  qty?: InputMaybe<Scalars['Int']>;
  sn?: InputMaybe<Scalars['String']>;
  udi?: InputMaybe<Scalars['String']>;
};

export type EyeWarrantyContractSearchInput = {
  approvalCode?: InputMaybe<Scalars['String']>;
  /** 市級、二級行政區 */
  cityId?: InputMaybe<Scalars['ID']>;
  code?: InputMaybe<Scalars['String']>;
  companyIds?: InputMaybe<Array<Scalars['ID']>>;
  /** 聯絡人 */
  contactPerson?: InputMaybe<Scalars['String']>;
  customerId?: InputMaybe<Scalars['ID']>;
  customerName?: InputMaybe<Scalars['String']>;
  deptId?: InputMaybe<Scalars['ID']>;
  /** 縣級、三級行政區 */
  districtId?: InputMaybe<Scalars['ID']>;
  id?: InputMaybe<Scalars['ID']>;
  orderCode?: InputMaybe<Scalars['String']>;
  /** 省級、一級行政區 */
  provinceId?: InputMaybe<Scalars['ID']>;
  regionIds?: InputMaybe<Array<Scalars['ID']>>;
  /** 保固合約項目序號 */
  sn?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<EnumEyeWarrantyContractStatus>;
  userId?: InputMaybe<Scalars['ID']>;
  warrantyDate1?: InputMaybe<Scalars['Date']>;
  warrantyDate2?: InputMaybe<Scalars['Date']>;
  warrantyPeriodTypeId?: InputMaybe<Scalars['ID']>;
};

export type EyeWarrantyContractSearchResult = {
  count: Scalars['Int'];
  pageInfo: PageInfo;
  rows: Array<EyeWarrantyContract>;
};

export type EyeWarrantyContractUpdateInput = {
  /** 地址 */
  address?: InputMaybe<Scalars['String']>;
  /** 合約審查號 */
  approvalCode?: InputMaybe<Scalars['String']>;
  /** 市級、二級行政區 */
  cityId?: InputMaybe<Scalars['ID']>;
  /** 聯絡人 */
  contactPerson?: InputMaybe<Scalars['String']>;
  /** 聯絡電話 */
  contactPhone?: InputMaybe<Scalars['String']>;
  customerId?: InputMaybe<Scalars['ID']>;
  deptId?: InputMaybe<Scalars['ID']>;
  description?: InputMaybe<Scalars['String']>;
  /** 縣級、三級行政區 */
  districtId?: InputMaybe<Scalars['ID']>;
  id: Scalars['ID'];
  memo?: InputMaybe<Scalars['String']>;
  /** 訂單單號 */
  orderCode?: InputMaybe<Scalars['String']>;
  /** 省級、一級行政區 */
  provinceId?: InputMaybe<Scalars['ID']>;
  status?: InputMaybe<EnumEyeWarrantyContractStatus>;
  userId?: InputMaybe<Scalars['ID']>;
  warrantyDate1?: InputMaybe<Scalars['Date']>;
  warrantyDate2?: InputMaybe<Scalars['Date']>;
  warrantyMonths?: InputMaybe<Scalars['Int']>;
  warrantyPeriodTypeId?: InputMaybe<Scalars['ID']>;
};

export type EyeWarrantyPeriodType = IEyeWarrantyPeriodType & {
  code: Scalars['String'];
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  name: Scalars['String'];
  unit: EnumEyeWarrantyPeriodTypeUnit;
  updatedAt: Scalars['DateTime'];
  value: Scalars['Int'];
};

export type EyeWarrantyPeriodTypeCreateInput = {
  code: Scalars['String'];
  name: Scalars['String'];
  unit: EnumEyeWarrantyPeriodTypeUnit;
  value: Scalars['Int'];
};

export type EyeWarrantyPeriodTypeSearchInput = {
  code?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['ID']>;
  name?: InputMaybe<Scalars['String']>;
  unit?: InputMaybe<EnumEyeWarrantyPeriodTypeUnit>;
  value?: InputMaybe<Scalars['Int']>;
};

export type EyeWarrantyPeriodTypeSearchResult = {
  count: Scalars['Int'];
  pageInfo: PageInfo;
  rows: Array<EyeWarrantyPeriodType>;
};

export type EyeWarrantyPeriodTypeUpdateInput = {
  code?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
  name?: InputMaybe<Scalars['String']>;
  unit?: InputMaybe<EnumEyeWarrantyPeriodTypeUnit>;
  value?: InputMaybe<Scalars['Int']>;
};

export type IAppVersion = {
  code: Scalars['String'];
  currentVersion: Scalars['String'];
  id: Scalars['ID'];
  name: Scalars['String'];
  type: Scalars['String'];
};

export type IBusiness = {
  /** 客戶預算金額 */
  budgetAmount?: Maybe<Scalars['Float']>;
  /** 購買機會 */
  buyingOpportunityId?: Maybe<Scalars['ID']>;
  /** 實際結束日期 */
  closedDate?: Maybe<Scalars['Date']>;
  /** 編號 */
  code?: Maybe<Scalars['String']>;
  /** 內容 */
  content?: Maybe<Scalars['String']>;
  /** 資料新增日期 */
  createdAt: Scalars['DateTime'];
  /** 資料建立人員 */
  createdUserId?: Maybe<Scalars['ID']>;
  /** 客戶 */
  customerId?: Maybe<Scalars['ID']>;
  /** 雙方成交金額 */
  dealAmount?: Maybe<Scalars['Float']>;
  /** 經銷商 */
  dealerId?: Maybe<Scalars['ID']>;
  /** 預計結束日期 */
  expectedClosedDate?: Maybe<Scalars['Date']>;
  /** 眼科報價單編號 */
  eyeQuotationOrderCode?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  /** 丟單改善計畫 */
  losingImprovementPlan?: Maybe<Scalars['String']>;
  /** 丟單原因 */
  losingReason?: Maybe<Scalars['String']>;
  /** 訂單編號（鼎新、道一雲、用友） */
  orderCode?: Maybe<Scalars['String']>;
  /** 主要負責業務（棄用；改用 sales_team_unit_id ） */
  primaryUserId?: Maybe<Scalars['ID']>;
  /** 業務銷售金額 */
  saleAmount?: Maybe<Scalars['Float']>;
  /** 銷售方式 */
  salesMethodId?: Maybe<Scalars['ID']>;
  /** 業務團隊組織 */
  salesTeamGroupId: Scalars['ID'];
  /** 負責業務團隊 */
  salesTeamId?: Maybe<Scalars['ID']>;
  /** 業務團隊單位 */
  salesTeamUnitId?: Maybe<Scalars['ID']>;
  /** 狀態 */
  statusId?: Maybe<Scalars['ID']>;
  /** 標題 */
  title?: Maybe<Scalars['String']>;
  /** 類型 */
  typeId?: Maybe<Scalars['ID']>;
  /** 資料修改日期 */
  updatedAt: Scalars['DateTime'];
  /** 資料修改人員 */
  updatedUserId?: Maybe<Scalars['ID']>;
  /** 贏單機會 */
  winningOpportunityId?: Maybe<Scalars['ID']>;
};

export type IBusinessLosingReason = {
  /** 資料新增日期 */
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  /** 名稱 */
  name: Scalars['String'];
  /** 業務團隊組織 */
  salesTeamGroupId: Scalars['ID'];
  /** 資料修改日期 */
  updatedAt: Scalars['DateTime'];
  /** 檢視順序 */
  viewOrder?: Maybe<Scalars['Int']>;
};

export type IBusinessMemo = {
  /** 商機 */
  businessId: Scalars['ID'];
  /** 內容 */
  content?: Maybe<Scalars['String']>;
  /** 資料新增日期 */
  createdAt: Scalars['DateTime'];
  /** 資料建立人員 */
  createdUserId?: Maybe<Scalars['ID']>;
  id: Scalars['ID'];
  /** 標題 */
  title?: Maybe<Scalars['String']>;
  /** 資料修改日期 */
  updatedAt: Scalars['DateTime'];
  /** 資料修改人員 */
  updatedUserId?: Maybe<Scalars['ID']>;
};

export type IBusinessOpportunity = {
  /** 編號 */
  code?: Maybe<Scalars['String']>;
  /** 資料新增日期 */
  createdAt: Scalars['DateTime'];
  /** 是否啟用於前端畫面顯示 */
  enabled: Scalars['Boolean'];
  id: Scalars['ID'];
  /** 名稱 */
  name: Scalars['String'];
  /** 業務團隊組織 */
  salesTeamGroupId: Scalars['ID'];
  /** 資料修改日期 */
  updatedAt: Scalars['DateTime'];
  /** 檢視順序 */
  viewOrder?: Maybe<Scalars['Int']>;
};

export type IBusinessProduct = {
  /** 廠牌 */
  brand?: Maybe<Scalars['String']>;
  /** 資料新增日期 */
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  /** 是否啟用 */
  isActive: Scalars['Boolean'];
  /** 名稱 */
  name: Scalars['String'];
  /** 業務團隊組織 */
  salesTeamGroupId: Scalars['ID'];
  /** 類型 */
  typeId?: Maybe<Scalars['ID']>;
  /** 資料修改日期 */
  updatedAt: Scalars['DateTime'];
  /** 檢視順序 */
  viewOrder?: Maybe<Scalars['Int']>;
};

export type IBusinessProductType = {
  /** 資料新增日期 */
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  /** 名稱 */
  name: Scalars['String'];
  /** 業務團隊組織 */
  salesTeamGroupId: Scalars['ID'];
  /** 資料修改日期 */
  updatedAt: Scalars['DateTime'];
  /** 檢視順序 */
  viewOrder?: Maybe<Scalars['Int']>;
};

export type IBusinessProperty = {
  /** 資料新增日期 */
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  /** 名稱 */
  name: Scalars['String'];
  /** 業務團隊組織 */
  salesTeamGroupId: Scalars['ID'];
  /** 類型 */
  typeId: Scalars['ID'];
  /** 資料修改日期 */
  updatedAt: Scalars['DateTime'];
  /** 檢視順序 */
  viewOrder?: Maybe<Scalars['Int']>;
};

export type IBusinessPropertyType = {
  /** 編號 */
  code?: Maybe<Scalars['String']>;
  /** 資料新增日期 */
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  /** 名稱 */
  name: Scalars['String'];
  /** 業務團隊組織 */
  salesTeamGroupId: Scalars['ID'];
  /** 資料修改日期 */
  updatedAt: Scalars['DateTime'];
  /** 檢視順序 */
  viewOrder?: Maybe<Scalars['Int']>;
};

export type IBusinessSalesMethod = {
  /** 編號 */
  code?: Maybe<EnumBusinessSalesMethodCode>;
  /** 資料新增日期 */
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  /** 名稱 */
  name: Scalars['String'];
  /** 業務團隊組織 */
  salesTeamGroupId: Scalars['ID'];
  /** 資料修改日期 */
  updatedAt: Scalars['DateTime'];
  /** 檢視順序 */
  viewOrder?: Maybe<Scalars['Int']>;
};

export type IBusinessStatus = {
  /** 購買機會 */
  buyingOpportunity?: Maybe<Scalars['String']>;
  /** 資料新增日期 */
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  /** 名稱 */
  name: Scalars['String'];
  /** 業務團隊組織 */
  salesTeamGroupId: Scalars['ID'];
  /** 類型 */
  type?: Maybe<EnumBusinessStatusType>;
  /** 資料修改日期 */
  updatedAt: Scalars['DateTime'];
  /** 檢視順序 */
  viewOrder?: Maybe<Scalars['Int']>;
};

export type IBusinessType = {
  /** 資料新增日期 */
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  /** 名稱 */
  name: Scalars['String'];
  /** 業務團隊組織 */
  salesTeamGroupId: Scalars['ID'];
  /** 資料修改日期 */
  updatedAt: Scalars['DateTime'];
  /** 檢視順序 */
  viewOrder?: Maybe<Scalars['Int']>;
};

export type IBusinessesToBudgetProduct = {
  /** 預算商品 */
  budgetProductId: Scalars['ID'];
  /** 商機 */
  businessId: Scalars['ID'];
  id: Scalars['ID'];
  /** 數量 */
  qty: Scalars['Int'];
};

export type IBusinessesToDealProduct = {
  /** 商機 */
  businessId: Scalars['ID'];
  /** 成交商品 */
  dealProductId: Scalars['ID'];
  id: Scalars['ID'];
  /** 數量 */
  qty: Scalars['Int'];
};

export type IBusinessesUser = {
  /** 商機 */
  businessId: Scalars['ID'];
  id: Scalars['ID'];
  /** 業務團隊 */
  salesTeamId?: Maybe<Scalars['ID']>;
  /** 負責（協助）業務 */
  userId: Scalars['ID'];
};

export type ICity = {
  areaId?: Maybe<Scalars['ID']>;
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  name: Scalars['String'];
  provinceId: Scalars['ID'];
  regionId: Scalars['ID'];
  updatedAt: Scalars['DateTime'];
  viewOrder: Scalars['Int'];
};

export type ICompany = {
  /** 公司編號 */
  code?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  enabled?: Maybe<Scalars['Boolean']>;
  hrCode?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  name: Scalars['String'];
  phone?: Maybe<Scalars['String']>;
  regionId: Scalars['ID'];
  updatedAt: Scalars['DateTime'];
};

export type ICompetitor = {
  /** 對方優勢 */
  advantage?: Maybe<Scalars['String']>;
  /** 代理商 */
  agents?: Maybe<Scalars['String']>;
  /** 廠牌 */
  brand?: Maybe<Scalars['String']>;
  /** 資料新增日期 */
  createdAt: Scalars['DateTime'];
  /** 資料建立人員 */
  createdUserId?: Maybe<Scalars['ID']>;
  /** 對方劣勢 */
  disadvantage?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  /** 備註 */
  memo?: Maybe<Scalars['String']>;
  /** 機型 */
  model?: Maybe<Scalars['String']>;
  /** 名稱 */
  name: Scalars['String'];
  /** 業務團隊組織 */
  salesTeamGroupId: Scalars['ID'];
  /** 應對策略 */
  strategy?: Maybe<Scalars['String']>;
  /** 資料修改日期 */
  updatedAt: Scalars['DateTime'];
  /** 資料修改人員 */
  updatedUserId?: Maybe<Scalars['ID']>;
};

export type IContactPerson = {
  /** 地址 */
  address?: Maybe<Scalars['String']>;
  /** 身分證字號／公民身份號碼 */
  citizenCode?: Maybe<Scalars['String']>;
  /** 編號（內勤系統自動添加） */
  code?: Maybe<Scalars['String']>;
  /** 資料新增日期 */
  createdAt: Scalars['DateTime'];
  /** 資料建立人員 */
  createdUserId?: Maybe<Scalars['ID']>;
  /** 部門 */
  departmentId?: Maybe<Scalars['ID']>;
  /** 部門（棄用） */
  dept?: Maybe<Scalars['String']>;
  /** 醫師代碼 */
  doctorCode?: Maybe<Scalars['String']>;
  /** 電子信箱 */
  email?: Maybe<Scalars['String']>;
  /** 傳真電話 */
  fax?: Maybe<Scalars['String']>;
  /** 性別 */
  gender?: Maybe<EnumContactPeopleGender>;
  /** 興趣愛好 */
  hobby?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  /** 職稱 */
  jobTitle?: Maybe<Scalars['String']>;
  /** 備註 */
  memo?: Maybe<Scalars['String']>;
  /** 行動電話 */
  mobile?: Maybe<Scalars['String']>;
  /** 姓名 */
  name: Scalars['String'];
  /** 暱稱 */
  nickname?: Maybe<Scalars['String']>;
  /** 座機電話 */
  phone?: Maybe<Scalars['String']>;
  /** 轉介編號 */
  referenceCode?: Maybe<Scalars['String']>;
  /** 業務團隊組織 */
  salesTeamGroupId: Scalars['ID'];
  /** 專長技能 */
  skill?: Maybe<Scalars['String']>;
  /** 資料修改日期 */
  updatedAt: Scalars['DateTime'];
  /** 資料修改人員 */
  updatedUserId?: Maybe<Scalars['ID']>;
  /** 網站 */
  website?: Maybe<Scalars['URL']>;
};

export type IContactPersonDepartment = {
  /** 資料新增日期 */
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  /** 名稱 */
  name: Scalars['String'];
  /** 業務團隊組織 */
  salesTeamGroupId: Scalars['ID'];
  /** 資料修改日期 */
  updatedAt: Scalars['DateTime'];
  /** 檢視順序 */
  viewOrder?: Maybe<Scalars['Int']>;
};

export type ICorporation = {
  /** 地址 */
  address?: Maybe<Scalars['String']>;
  code: Scalars['String'];
  createdAt: Scalars['DateTime'];
  disabled: Scalars['Boolean'];
  /** email */
  email?: Maybe<Scalars['EmailAddress']>;
  /** Facebook */
  facebook?: Maybe<Scalars['URL']>;
  /** 傳真 */
  fax?: Maybe<Scalars['PhoneNumber']>;
  /** 統一編號 */
  guiNumber?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  /** Instagram */
  instagram?: Maybe<Scalars['URL']>;
  name: Scalars['String'];
  /** 電話 */
  phone?: Maybe<Scalars['PhoneNumber']>;
  regionId: Scalars['ID'];
  /** 抖音 */
  tiktok?: Maybe<Scalars['URL']>;
  /** Twitter */
  twitter?: Maybe<Scalars['URL']>;
  updatedAt: Scalars['DateTime'];
  /** 官網 */
  website?: Maybe<Scalars['URL']>;
  /** 微博 */
  weibo?: Maybe<Scalars['URL']>;
  /** 用友帳套 */
  yonyouSetOfBook?: Maybe<Scalars['String']>;
};

export type ICostCenter = {
  /** 編號 */
  code: Scalars['String'];
  /** 公司 */
  companyId: Scalars['ID'];
  /** 資料新增日期 */
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  /** 是否啟用 */
  isActive?: Maybe<Scalars['Boolean']>;
  /** 名稱 */
  name: Scalars['String'];
  /** 區域 */
  regionId: Scalars['ID'];
  /** 資料修改日期 */
  updatedAt: Scalars['DateTime'];
};

export type ICountingUnit = {
  /** 編號 */
  code?: Maybe<Scalars['String']>;
  /** 資料新增日期 */
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  /** 名稱 */
  name: Scalars['String'];
  /** 區域 */
  regionId: Scalars['ID'];
  /** 資料修改日期 */
  updatedAt: Scalars['DateTime'];
  /** 檢視順序 */
  viewOrder?: Maybe<Scalars['Int']>;
};

export type ICreditPeriod = {
  createdAt?: Maybe<Scalars['DateTime']>;
  /** 天數 */
  days: Scalars['Int'];
  description?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  name: Scalars['String'];
  regionId: Scalars['ID'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ICurrency = {
  /** ISO 4217 currency codes */
  code: Scalars['String'];
  /** 小數點後幾位 */
  decimalPlace: Scalars['Int'];
  id: Scalars['ID'];
  name: Scalars['String'];
  /** 貨幣符號 */
  symbol: Scalars['String'];
};

export type ICustomer = {
  /** 地址 */
  address?: Maybe<Scalars['String']>;
  /** 分區 */
  areaId?: Maybe<Scalars['ID']>;
  /** 銀行戶頭代碼 */
  bankAccountCode?: Maybe<Scalars['String']>;
  /** 銀行戶頭名稱 */
  bankAccountName?: Maybe<Scalars['String']>;
  /** 開票單位名稱 */
  billingUnitName?: Maybe<Scalars['String']>;
  /** 營業統一編號 */
  businessCode?: Maybe<Scalars['String']>;
  /** 分類 */
  categoryId?: Maybe<Scalars['ID']>;
  /** 市級、二級行政區 */
  cityId?: Maybe<Scalars['ID']>;
  /** 編號（鼎新／內部通用） */
  code?: Maybe<Scalars['String']>;
  /** 總負責聯絡人姓名 */
  contactPersonName?: Maybe<Scalars['String']>;
  /** 總負責聯絡人電話 */
  contactPersonPhone?: Maybe<Scalars['String']>;
  /** 資料新增日期 */
  createdAt: Scalars['DateTime'];
  /** 資料建立人員 */
  createdUserId?: Maybe<Scalars['ID']>;
  /** 信用期限（科林評定） */
  creditPeriodId?: Maybe<Scalars['ID']>;
  /** 信用額度（科林評定） */
  creditQuota?: Maybe<Scalars['Float']>;
  /** 預設付款方式 */
  defaultPaymentMethodId?: Maybe<Scalars['ID']>;
  /** 預設配送方式 */
  defaultShippingMethodId?: Maybe<Scalars['ID']>;
  /** 縣級、三級行政區 */
  districtId?: Maybe<Scalars['ID']>;
  /** 編號（道一雲） */
  do1Code?: Maybe<Scalars['String']>;
  /** 電子信箱 */
  email?: Maybe<Scalars['String']>;
  /** 傳真電話 */
  fax?: Maybe<Scalars['String']>;
  /** 集團 */
  groupId?: Maybe<Scalars['ID']>;
  /** 是否有生效的首營資料證照 */
  hasEffectiveGmpCertificate?: Maybe<Scalars['Boolean']>;
  id: Scalars['ID'];
  /** 是否啟用 */
  isActive: Scalars['Boolean'];
  /** 是否為首營客戶 */
  isGmp?: Maybe<Scalars['Boolean']>;
  /** 最近 BPM 首營資料 Instance ID */
  lastGmpBpmInstanceId?: Maybe<Scalars['ID']>;
  /** 最近 BPM 首營資料申請狀態 */
  lastGmpBpmStatus?: Maybe<EnumLastGmpBpmStatus>;
  /** 最近 BPM 首營資料申請人員 */
  lastGmpBpmUserId?: Maybe<Scalars['ID']>;
  /** 法人姓名 */
  legalPersonName?: Maybe<Scalars['String']>;
  /** 醫事機構代碼 */
  medicalCode?: Maybe<Scalars['String']>;
  /** 備註 */
  memo?: Maybe<Scalars['String']>;
  /** 行動電話 */
  mobile?: Maybe<Scalars['String']>;
  /** 名稱 */
  name: Scalars['String'];
  /** 編號（NAV） */
  navCode?: Maybe<Scalars['String']>;
  /** 上層客戶 */
  parentId?: Maybe<Scalars['ID']>;
  /** 座機電話 */
  phone?: Maybe<Scalars['String']>;
  /** 省級、一級行政區 */
  provinceId?: Maybe<Scalars['ID']>;
  /** 轉介編號 */
  referenceCode?: Maybe<Scalars['String']>;
  /** 業務團隊組織 */
  salesTeamGroupId: Scalars['ID'];
  /** 收貨地址 */
  shippingAddress?: Maybe<Scalars['String']>;
  /** 簡稱 */
  shortName?: Maybe<Scalars['String']>;
  /** 同步代碼 */
  syncCode?: Maybe<Scalars['String']>;
  /** 同步來源 */
  syncSourceId?: Maybe<Scalars['ID']>;
  /** 類型 */
  typeId?: Maybe<Scalars['ID']>;
  /** 資料修改日期 */
  updatedAt: Scalars['DateTime'];
  /** 資料修改人員 */
  updatedUserId?: Maybe<Scalars['ID']>;
  /** 網站 */
  website?: Maybe<Scalars['URL']>;
  /** 編號（用友） */
  yonyouCode?: Maybe<Scalars['String']>;
};

export type ICustomerArea = {
  /** 資料新增日期 */
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  /** 名稱 */
  name: Scalars['String'];
  /** 業務團隊組織 */
  salesTeamGroupId: Scalars['ID'];
  /** 資料修改日期 */
  updatedAt: Scalars['DateTime'];
  /** 檢視順序 */
  viewOrder?: Maybe<Scalars['Int']>;
};

export type ICustomerAttachment = {
  /** 資料新增日期 */
  createdAt: Scalars['DateTime'];
  /** 資料建立人員 */
  createdUserId?: Maybe<Scalars['ID']>;
  /** 客戶 */
  customerId: Scalars['ID'];
  /** 副檔名 */
  extension: Scalars['String'];
  id: Scalars['ID'];
  /** 備註 */
  memo?: Maybe<Scalars['String']>;
  /** 名稱 */
  name: Scalars['String'];
  /** S3 Key */
  s3Key: Scalars['String'];
  /** 資料修改日期 */
  updatedAt: Scalars['DateTime'];
  /** 資料修改人員 */
  updatedUserId?: Maybe<Scalars['ID']>;
};

export type ICustomerCategory = {
  /** 資料新增日期 */
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  /** 名稱 */
  name: Scalars['String'];
  /** 業務團隊組織 */
  salesTeamGroupId: Scalars['ID'];
  /** 資料修改日期 */
  updatedAt: Scalars['DateTime'];
  /** 檢視順序 */
  viewOrder?: Maybe<Scalars['Int']>;
};

export type ICustomerCertificate = {
  /** 證號 */
  code?: Maybe<Scalars['String']>;
  /** 資料新增日期 */
  createdAt: Scalars['DateTime'];
  /** 資料建立人員 */
  createdUserId?: Maybe<Scalars['ID']>;
  /** 客戶 */
  customerId: Scalars['ID'];
  /** 是否禁用 */
  disabled: Scalars['Boolean'];
  /** 生效日期 */
  effectiveDate?: Maybe<Scalars['Date']>;
  /** 失效日期 */
  expiryDate?: Maybe<Scalars['Date']>;
  id: Scalars['ID'];
  /** 經營範圍 */
  scope?: Maybe<Scalars['String']>;
  /** 類型 */
  typeId: Scalars['ID'];
  /** 資料修改日期 */
  updatedAt: Scalars['DateTime'];
  /** 資料修改人員 */
  updatedUserId?: Maybe<Scalars['ID']>;
};

export type ICustomerCertificateAttachment = {
  /** 證照 */
  certificateId: Scalars['ID'];
  /** 資料新增日期 */
  createdAt: Scalars['DateTime'];
  /** 資料建立人員 */
  createdUserId?: Maybe<Scalars['ID']>;
  /** 副檔名 */
  extension: Scalars['String'];
  id: Scalars['ID'];
  /** 備註 */
  memo?: Maybe<Scalars['String']>;
  /** 名稱 */
  name: Scalars['String'];
  /** S3 Key */
  s3Key: Scalars['String'];
  /** 資料修改日期 */
  updatedAt: Scalars['DateTime'];
  /** 資料修改人員 */
  updatedUserId?: Maybe<Scalars['ID']>;
};

export type ICustomerCertificateType = {
  /** 編號 */
  code?: Maybe<Scalars['String']>;
  /** 資料新增日期 */
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  /** 名稱 */
  name: Scalars['String'];
  /** 業務團隊組織 */
  salesTeamGroupId: Scalars['ID'];
  /** 資料修改日期 */
  updatedAt: Scalars['DateTime'];
  /** 檢視順序 */
  viewOrder?: Maybe<Scalars['Int']>;
};

export type ICustomerGroup = {
  /** 資料新增日期 */
  createdAt: Scalars['DateTime'];
  /** 資料建立人員 */
  createdUserId?: Maybe<Scalars['ID']>;
  id: Scalars['ID'];
  /** 名稱 */
  name?: Maybe<Scalars['String']>;
  /** 業務團隊組織 */
  salesTeamGroupId: Scalars['ID'];
  /** 資料修改日期 */
  updatedAt: Scalars['DateTime'];
  /** 資料修改人員 */
  updatedUserId?: Maybe<Scalars['ID']>;
};

export type ICustomerLevel = {
  /** 資料新增日期 */
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  /** 名稱 */
  name: Scalars['String'];
  /** 業務團隊組織 */
  salesTeamGroupId: Scalars['ID'];
  /** 類型 */
  typeId: Scalars['ID'];
  /** 資料修改日期 */
  updatedAt: Scalars['DateTime'];
  /** 檢視順序 */
  viewOrder?: Maybe<Scalars['Int']>;
};

export type ICustomerLevelType = {
  /** 編號 */
  code?: Maybe<EnumCustomerLevelTypeCode>;
  /** 資料新增日期 */
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  /** 名稱 */
  name: Scalars['String'];
  /** 業務團隊組織 */
  salesTeamGroupId: Scalars['ID'];
  /** 資料修改日期 */
  updatedAt: Scalars['DateTime'];
  /** 檢視順序 */
  viewOrder?: Maybe<Scalars['Int']>;
};

export type ICustomerProperty = {
  /** 資料新增日期 */
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  /** 名稱 */
  name: Scalars['String'];
  /** 業務團隊組織 */
  salesTeamGroupId: Scalars['ID'];
  /** 類型 */
  typeId: Scalars['ID'];
  /** 資料修改日期 */
  updatedAt: Scalars['DateTime'];
  /** 檢視順序 */
  viewOrder?: Maybe<Scalars['Int']>;
};

export type ICustomerPropertyType = {
  /** 編號 */
  code?: Maybe<Scalars['String']>;
  /** 資料新增日期 */
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  /** 名稱 */
  name: Scalars['String'];
  /** 業務團隊組織 */
  salesTeamGroupId: Scalars['ID'];
  /** 資料修改日期 */
  updatedAt: Scalars['DateTime'];
  /** 檢視順序 */
  viewOrder?: Maybe<Scalars['Int']>;
};

export type ICustomerType = {
  /** 編號 */
  code?: Maybe<EnumCustomerTypeCode>;
  /** 資料新增日期 */
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  /** 名稱 */
  name: Scalars['String'];
  /** 業務團隊組織 */
  salesTeamGroupId: Scalars['ID'];
  /** 資料修改日期 */
  updatedAt: Scalars['DateTime'];
  /** 檢視順序 */
  viewOrder?: Maybe<Scalars['Int']>;
};

export type ICustomersPrimaryUser = {
  /** 客戶 */
  customerId: Scalars['ID'];
  id: Scalars['ID'];
  /** 業務團隊 */
  salesTeamId?: Maybe<Scalars['ID']>;
  /** 負責業務 */
  userId: Scalars['ID'];
};

export type ICustomersSalesTeamUnit = {
  /** 客戶 */
  customerId: Scalars['ID'];
  /** 業務團隊單位 */
  salesTeamUnitId: Scalars['ID'];
};

export type IDepartment = {
  code?: Maybe<Scalars['String']>;
  companyId?: Maybe<Scalars['ID']>;
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  /** 是否啟用 */
  isActive: Scalars['Boolean'];
  /** 主管 */
  managerId?: Maybe<Scalars['ID']>;
  name: Scalars['String'];
  /** 上層部門 */
  parentId?: Maybe<Scalars['ID']>;
  regionId: Scalars['ID'];
  /** 帳套 */
  setOfBook?: Maybe<Scalars['String']>;
  updatedAt: Scalars['DateTime'];
};

export type IDispatcherUser = {
  /** 資料新增日期 */
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  /** 是否啟用 */
  isActive?: Maybe<Scalars['Boolean']>;
  /** 資料修改日期 */
  updatedAt: Scalars['DateTime'];
  /** 調度士 */
  userId: Scalars['ID'];
};

export type IDistrict = {
  cityId?: Maybe<Scalars['ID']>;
  code?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  name: Scalars['String'];
  updatedAt: Scalars['DateTime'];
  viewOrder: Scalars['Int'];
};

export type IExchangeRate = {
  createdAt?: Maybe<Scalars['DateTime']>;
  /** 對象幣別 */
  currency1Id: Scalars['ID'];
  /** 轉換幣別 */
  currency2Id: Scalars['ID'];
  id: Scalars['ID'];
  rate: Scalars['Float'];
  regionId: Scalars['ID'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type IEyeFixedAsset = {
  /** 借用天數 */
  borrowingDays?: Maybe<Scalars['Int']>;
  /** 廠牌 */
  brand?: Maybe<Scalars['String']>;
  /** 編號 */
  code: Scalars['String'];
  /** 公司 */
  companyId?: Maybe<Scalars['ID']>;
  createdAt: Scalars['DateTime'];
  createdUserId?: Maybe<Scalars['ID']>;
  /** 目前所在位置 */
  currentLocation?: Maybe<Scalars['String']>;
  /** 運送天數 */
  deliveryDays?: Maybe<Scalars['Int']>;
  /** 保管部門（於 issues/101545 棄用） */
  deptId?: Maybe<Scalars['ID']>;
  id: Scalars['ID'];
  /** 最大展延天數 */
  maxExtendedDays?: Maybe<Scalars['Int']>;
  /** 最大展延次數 */
  maxExtendedTimes?: Maybe<Scalars['Int']>;
  memo?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  /** PM 負責人 */
  pmUserId?: Maybe<Scalars['ID']>;
  regionId: Scalars['ID'];
  status: EnumEyeFixedAssetsStatus;
  updatedAt: Scalars['DateTime'];
  updatedUserId?: Maybe<Scalars['ID']>;
  /** 保管人員（於 issues/101545 棄用） */
  userId?: Maybe<Scalars['ID']>;
  /** 倉儲 */
  warehouseId?: Maybe<Scalars['ID']>;
};

export type IEyeFixedAssetDocument = {
  /** 資料新增日期 */
  createdAt: Scalars['DateTime'];
  /** 資料建立人員 */
  createdUserId?: Maybe<Scalars['ID']>;
  /** 副檔名 */
  extension: Scalars['String'];
  /** 固定資產 */
  eyeFixedAssetId: Scalars['ID'];
  id: Scalars['ID'];
  /** 備註 */
  memo?: Maybe<Scalars['String']>;
  /** 檔案名稱 */
  name?: Maybe<Scalars['String']>;
  /** S3 key */
  s3Key: Scalars['String'];
  /** 資料修改日期 */
  updatedAt: Scalars['DateTime'];
  /** 資料修改人員 */
  updatedUserId?: Maybe<Scalars['ID']>;
};

export type IEyeFixedAssetImage = {
  /** 資料新增日期 */
  createdAt: Scalars['DateTime'];
  /** 資料建立人員 */
  createdUserId?: Maybe<Scalars['ID']>;
  /** 副檔名 */
  extension: Scalars['String'];
  /** 固定資產 */
  eyeFixedAssetId: Scalars['ID'];
  id: Scalars['ID'];
  /** 備註 */
  memo?: Maybe<Scalars['String']>;
  /** 檔案名稱 */
  name?: Maybe<Scalars['String']>;
  /** S3 key */
  s3Key: Scalars['String'];
  /** 資料修改日期 */
  updatedAt: Scalars['DateTime'];
  /** 資料修改人員 */
  updatedUserId?: Maybe<Scalars['ID']>;
};

export type IEyeFixedAssetItem = {
  createdAt?: Maybe<Scalars['DateTime']>;
  eyeFixedAssetId: Scalars['ID'];
  id: Scalars['ID'];
  materialCode: Scalars['String'];
  materialId: Scalars['ID'];
  materialModel?: Maybe<Scalars['String']>;
  materialName?: Maybe<Scalars['String']>;
  materialSpec?: Maybe<Scalars['String']>;
  materialUnit?: Maybe<Scalars['String']>;
  memo?: Maybe<Scalars['String']>;
  /** 序號 */
  sn?: Maybe<Scalars['String']>;
  /** 醫療器材單一識別碼 */
  udi?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type IEyeFixedAssetRentalGoal = {
  /** 編號 */
  code?: Maybe<Scalars['String']>;
  /** 資料新增日期 */
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  /** 是否啟用 */
  isActive: Scalars['Boolean'];
  /** 名稱 */
  name: Scalars['String'];
  /** 區域 */
  regionId?: Maybe<Scalars['ID']>;
  /** 資料修改日期 */
  updatedAt: Scalars['DateTime'];
};

export type IEyeFixedAssetRentalObject = {
  /** 編號 */
  code?: Maybe<EnumEyeFixedAssetRentalObjectCode>;
  /** 資料新增日期 */
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  /** 是否啟用 */
  isActive: Scalars['Boolean'];
  /** 名稱 */
  name: Scalars['String'];
  /** 區域 */
  regionId?: Maybe<Scalars['ID']>;
  /** 資料修改日期 */
  updatedAt: Scalars['DateTime'];
};

export type IEyeFixedAssetRentalRecord = {
  /** 公司 */
  companyId?: Maybe<Scalars['ID']>;
  createdAt: Scalars['DateTime'];
  createdUserId?: Maybe<Scalars['ID']>;
  /** 租借日(起) */
  date1: Scalars['DateTime'];
  /** 租借日(迄) */
  date2: Scalars['DateTime'];
  /** 拆機日 */
  dismantleDate?: Maybe<Scalars['DateTime']>;
  eyeServiceOrderId: Scalars['ID'];
  id: Scalars['ID'];
  /** 安裝日 */
  installDate?: Maybe<Scalars['DateTime']>;
  regionId: Scalars['ID'];
  remark?: Maybe<Scalars['String']>;
  status: EnumEyeFixedAssetsRentalStatus;
  updatedAt: Scalars['DateTime'];
  updatedUserId?: Maybe<Scalars['ID']>;
};

export type IEyeFixedAssetServiceProvider = {
  /** 編號 */
  code?: Maybe<EnumEyeFixedAssetServiceProviderCode>;
  /** 資料新增日期 */
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  /** 是否啟用 */
  isActive: Scalars['Boolean'];
  /** 名稱 */
  name: Scalars['String'];
  /** 區域 */
  regionId?: Maybe<Scalars['ID']>;
  /** 資料修改日期 */
  updatedAt: Scalars['DateTime'];
};

export type IEyeFixedAssetType = {
  /** 編號 */
  code: EnumEyeFixedAssetTypeCode;
  /** 資料新增日期 */
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  /** 是否啟用 */
  isActive: Scalars['Boolean'];
  /** 名稱 */
  name: Scalars['String'];
  /** 區域 */
  regionId: Scalars['ID'];
  /** 資料修改日期 */
  updatedAt: Scalars['DateTime'];
};

export type IEyeProduct = {
  /** 台數獎金係數 */
  bonusCoefficient?: Maybe<Scalars['Float']>;
  /** 商品品牌 */
  brand?: Maybe<Scalars['String']>;
  /** 事業主管金額 */
  businessManagerPrice?: Maybe<Scalars['Float']>;
  /** 公司 */
  companyId: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  createdUserId?: Maybe<Scalars['ID']>;
  /** 幣別 */
  currencyId: Scalars['ID'];
  /** 有效日期1 */
  date1?: Maybe<Scalars['Date']>;
  /** 有效日期2 */
  date2?: Maybe<Scalars['Date']>;
  /** 經銷金額 */
  dealerPrice?: Maybe<Scalars['Float']>;
  /** 敘述 */
  description?: Maybe<Scalars['String']>;
  /** DSM(辦事處)金額 */
  dsmPrice?: Maybe<Scalars['Float']>;
  /** 群組 */
  eyeProductGroupId?: Maybe<Scalars['ID']>;
  eyeProductTypeId?: Maybe<Scalars['ID']>;
  /** 總經理金額 */
  generalManagerPrice?: Maybe<Scalars['Float']>;
  id: Scalars['ID'];
  /** 商品型號 */
  model?: Maybe<Scalars['String']>;
  /** 商品名稱 */
  name: Scalars['String'];
  regionId: Scalars['ID'];
  /** 大區主管金額 */
  regionalManagerPrice?: Maybe<Scalars['Float']>;
  /** 販售金額 */
  salePrice: Scalars['Float'];
  /** 標準交期（天數） */
  standardDeliveryDays?: Maybe<Scalars['Int']>;
  /** 狀態 */
  status: EnumEyeProductStatus;
  updatedAt: Scalars['DateTime'];
  updatedUserId?: Maybe<Scalars['ID']>;
};

export type IEyeProductAcceptanceForm = {
  createdAt: Scalars['DateTime'];
  date1?: Maybe<Scalars['Date']>;
  date2?: Maybe<Scalars['Date']>;
  eyeProductId: Scalars['ID'];
  fields?: Maybe<Scalars['JSON']>;
  id: Scalars['ID'];
  status: EnumProductAcceptanceFormStatus;
  updatedAt: Scalars['DateTime'];
  version: Scalars['String'];
};

export type IEyeProductAcceptanceFormDetail = {
  createdAt: Scalars['DateTime'];
  description?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  name: Scalars['String'];
  options?: Maybe<Scalars['JSON']>;
  required: Scalars['Boolean'];
  typeId: Scalars['ID'];
  updatedAt: Scalars['DateTime'];
};

export type IEyeProductAttachment = {
  /** 資料新增日期 */
  createdAt: Scalars['DateTime'];
  /** 資料建立人員 */
  createdUserId?: Maybe<Scalars['ID']>;
  /** 副檔名 */
  extension: Scalars['String'];
  /** 眼科商品 */
  eyeProductId: Scalars['ID'];
  id: Scalars['ID'];
  /** 備註 */
  memo?: Maybe<Scalars['String']>;
  /** 名稱 */
  name: Scalars['String'];
  /** S3 Key */
  s3Key: Scalars['String'];
  /** 資料修改日期 */
  updatedAt: Scalars['DateTime'];
  /** 資料修改人員 */
  updatedUserId?: Maybe<Scalars['ID']>;
};

export type IEyeProductItem = {
  /** 資料新增日期 */
  createdAt: Scalars['DateTime'];
  /** 資料建立人員 */
  createdUserId?: Maybe<Scalars['ID']>;
  /** 敘述 */
  description?: Maybe<Scalars['String']>;
  /** 商品 */
  eyeProductId: Scalars['ID'];
  /** 商品項目類別 */
  eyeProductItemTypeId: Scalars['ID'];
  id: Scalars['ID'];
  /** 是否為可選的 */
  isOptional: Scalars['Boolean'];
  /** 料號 */
  materialId: Scalars['ID'];
  /** 名稱 */
  materialName: Scalars['String'];
  /** 料號_銷售計量單位 */
  materialSellingUnit?: Maybe<Scalars['String']>;
  /** 數量 */
  qty: Scalars['Int'];
  /** 資料修改日期 */
  updatedAt: Scalars['DateTime'];
  /** 資料修改人員 */
  updatedUserId?: Maybe<Scalars['ID']>;
  /** 檢視順序 */
  viewOrder?: Maybe<Scalars['Int']>;
};

export type IEyeProductItemType = {
  /** 1: 主商品, 2: 配件, 3: 贈品, 4: 其他 */
  category: Scalars['Int'];
  id: Scalars['ID'];
  name: Scalars['String'];
  viewOrder?: Maybe<Scalars['Int']>;
};

export type IEyeProductType = {
  /** 公司 */
  companyId: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  name: Scalars['String'];
  regionId: Scalars['ID'];
  updatedAt: Scalars['DateTime'];
  viewOrder?: Maybe<Scalars['Int']>;
};

export type IEyePromotion = {
  /** 公司 */
  companyId: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  createdUserId?: Maybe<Scalars['ID']>;
  /** 幣別 */
  currencyId: Scalars['ID'];
  /** 有效日期1 */
  date1: Scalars['Date'];
  /** 有效日期2 */
  date2?: Maybe<Scalars['Date']>;
  /** 敘述 */
  description?: Maybe<Scalars['String']>;
  /** 依照金額折扣 */
  discountAmount?: Maybe<Scalars['Float']>;
  /** 依照比率折扣 */
  discountRate?: Maybe<Scalars['Float']>;
  id: Scalars['ID'];
  /** 是否加贈 */
  isAddon: Scalars['Boolean'];
  /** 優惠名稱 */
  name?: Maybe<Scalars['String']>;
  regionId: Scalars['ID'];
  updatedAt: Scalars['DateTime'];
  updatedUserId?: Maybe<Scalars['ID']>;
};

export type IEyePromotionAddonProductItem = {
  /** 資料新增日期 */
  createdAt: Scalars['DateTime'];
  /** 資料建立人員 */
  createdUserId?: Maybe<Scalars['ID']>;
  /** 敘述 */
  description?: Maybe<Scalars['String']>;
  /** 商品項目類別 */
  eyeProductItemTypeId: Scalars['ID'];
  /** 促銷活動 */
  eyePromotionId: Scalars['ID'];
  id: Scalars['ID'];
  /** 是否為可選的 */
  isOptional: Scalars['Boolean'];
  /** 料號 */
  materialId: Scalars['ID'];
  /** 料號_計量單位 */
  materialUnit?: Maybe<Scalars['String']>;
  /** 數量 */
  qty: Scalars['Int'];
  /** 資料修改日期 */
  updatedAt: Scalars['DateTime'];
  /** 資料修改人員 */
  updatedUserId?: Maybe<Scalars['ID']>;
};

export type IEyeQuotationOrder = {
  /** 地址 */
  address: Scalars['String'];
  /** 招標金額 */
  biddingPrice?: Maybe<Scalars['Float']>;
  /** BPM Instance id */
  bpmInstanceId?: Maybe<Scalars['ID']>;
  /** 用印BPM id */
  bpmOfficialSealInstanceId?: Maybe<Scalars['ID']>;
  /** 商機（棄用；改用多對一關聯） */
  businessId?: Maybe<Scalars['ID']>;
  /** 市級、二級行政區 */
  cityId?: Maybe<Scalars['ID']>;
  /** 編號 */
  code: Scalars['String'];
  /** 佣金 (研究費) */
  commissionAmount: Scalars['Float'];
  /** 公司 */
  companyId: Scalars['ID'];
  /** 聯絡人 */
  contactPerson: Scalars['String'];
  /** 聯絡電話 */
  contactPhone: Scalars['String'];
  /** 資料新增日期 */
  createdAt: Scalars['DateTime'];
  /** 資料建立人員 */
  createdUserId?: Maybe<Scalars['ID']>;
  /** 信用期間 */
  creditPeriodId: Scalars['ID'];
  /** 幣別 */
  currencyId: Scalars['ID'];
  /** 裝機客戶 */
  customerId: Scalars['ID'];
  /** 負責部門 */
  deptId: Scalars['ID'];
  /** 描述 */
  description?: Maybe<Scalars['String']>;
  /** 折扣金額 */
  discountAmount: Scalars['Float'];
  /** 折扣率 */
  discountRate: Scalars['Float'];
  /** 不包含佣金折扣率 */
  discountRateWithoutCommission: Scalars['Float'];
  /** 縣級、三級行政區 */
  districtId?: Maybe<Scalars['ID']>;
  /** 匯率 */
  exchangeRate?: Maybe<Scalars['Float']>;
  /** 預計交付日 */
  expectDeliveryDate: Scalars['DateTime'];
  /** 預計收款日 */
  expectPaymentDate: Scalars['DateTime'];
  /** 其他折扣金額 */
  extraDiscountAmount: Scalars['Float'];
  /** 眼科報價單類別 */
  eyeQuotationOrderTypeId?: Maybe<Scalars['ID']>;
  /** 服務單 */
  eyeServiceOrderId?: Maybe<Scalars['ID']>;
  id: Scalars['ID'];
  /** 發票客戶 */
  invoicingCustomerId: Scalars['ID'];
  /** 當地幣別 */
  localCurrencyId: Scalars['ID'];
  /** 當地幣別原價 */
  localStandardAmount: Scalars['Float'];
  /** 用印狀態 */
  officialSealStatus?: Maybe<EnumEyeQuotationOrderOfficialSealStatus>;
  /** 僅配件及耗材 */
  onlyMaterials: Scalars['Boolean'];
  /** 訂單編號 */
  orderCode?: Maybe<Scalars['String']>;
  /** 省級、一級行政區 */
  provinceId?: Maybe<Scalars['ID']>;
  /** 實際金額 */
  realAmount: Scalars['Float'];
  /** 實際折扣金額 */
  realDiscountAmount: Scalars['Float'];
  /** 實際折扣率 */
  realDiscountRate: Scalars['Float'];
  /** 區域 */
  regionId: Scalars['ID'];
  /** 原價 */
  standardAmount: Scalars['Float'];
  /** 狀態 */
  status: EnumEyeQuotationOrderStatus;
  /** 稅率 */
  taxRate: Scalars['Float'];
  /** 未稅金額 */
  untaxedAmount: Scalars['Float'];
  /** 資料修改日期 */
  updatedAt: Scalars['DateTime'];
  /** 資料修改人員 */
  updatedUserId?: Maybe<Scalars['ID']>;
  /** 負責人 */
  userId: Scalars['ID'];
  /** 保固購買方式 */
  warrantyBuyType: EnumWarrantyBuyType;
  /** 保固月份 */
  warrantyMonths?: Maybe<Scalars['Int']>;
  /** 保固類型週期 */
  warrantyPeriodTypeId?: Maybe<Scalars['ID']>;
};

export type IEyeQuotationOrderBusiness = {
  /** 商機 */
  businessId: Scalars['ID'];
  /** 資料新增日期 */
  createdAt: Scalars['DateTime'];
  /** 眼科報價單 */
  eyeQuotationOrderId: Scalars['ID'];
  id: Scalars['ID'];
  /** 資料修改日期 */
  updatedAt: Scalars['DateTime'];
};

export type IEyeQuotationOrderProduct = {
  /** 商品品牌 */
  brand?: Maybe<Scalars['String']>;
  /** 資料新增日期 */
  createdAt: Scalars['DateTime'];
  /** 幣別 */
  currencyId: Scalars['ID'];
  /** 敘述 */
  description?: Maybe<Scalars['String']>;
  /** 匯率 */
  exchangeRate: Scalars['Float'];
  /** 眼科商品 */
  eyeProductId: Scalars['ID'];
  /** 眼科報價單 */
  eyeQuotationOrderId: Scalars['ID'];
  id: Scalars['ID'];
  /** 商品型號 */
  model?: Maybe<Scalars['String']>;
  /** 商品名稱 */
  name: Scalars['String'];
  /** 數量 */
  qty: Scalars['Int'];
  /** 販售金額 */
  salePrice: Scalars['Float'];
  /** 稅率 */
  taxRate: Scalars['Float'];
  /** 不含稅金額 */
  unitPrice: Scalars['Float'];
  /** 含稅金額 */
  unitPriceVat: Scalars['Float'];
  /** 資料修改日期 */
  updatedAt: Scalars['DateTime'];
};

export type IEyeQuotationOrderProductItem = {
  /** 資料新增日期 */
  createdAt: Scalars['DateTime'];
  /** 敘述 */
  description?: Maybe<Scalars['String']>;
  /** 折扣金額 */
  discountPrice?: Maybe<Scalars['Float']>;
  /** 折扣後販售金額 */
  discountedSellingPrice?: Maybe<Scalars['Float']>;
  /** 匯率 */
  exchangeRate: Scalars['Float'];
  /** 眼科商品項目 */
  eyeProductItemId?: Maybe<Scalars['ID']>;
  /** 眼科優惠加購商品項目 */
  eyePromotionAddonProductItemId?: Maybe<Scalars['ID']>;
  /** 眼科報價單 */
  eyeQuotationOrderId: Scalars['ID'];
  /** 眼科報價單商品 */
  eyeQuotationOrderProductId?: Maybe<Scalars['ID']>;
  /** 眼科報價單優惠 */
  eyeQuotationOrderPromotionId?: Maybe<Scalars['ID']>;
  id: Scalars['ID'];
  /** 料號_編號 */
  materialCode: Scalars['String'];
  /** 料號 */
  materialId: Scalars['ID'];
  /** 料號_型號 */
  materialModel?: Maybe<Scalars['String']>;
  /** 料號_名稱 */
  materialName?: Maybe<Scalars['String']>;
  /** 販售幣別 */
  materialSellingCurrencyId?: Maybe<Scalars['ID']>;
  /** 販售金額 */
  materialSellingPrice?: Maybe<Scalars['Float']>;
  /** 料號_規格／機型 */
  materialSpec?: Maybe<Scalars['String']>;
  /** 料號_計量單位 */
  materialUnit?: Maybe<Scalars['String']>;
  /** 實際數量 */
  qty: Scalars['Int'];
  /** 稅率 */
  taxRate: Scalars['Float'];
  /** 不含稅金額 */
  unitPrice: Scalars['Float'];
  /** 含稅金額 */
  unitPriceVat: Scalars['Float'];
  /** 商品項目單位數量 */
  unitQty: Scalars['Int'];
  /** 資料修改日期 */
  updatedAt: Scalars['DateTime'];
};

export type IEyeQuotationOrderPromotion = {
  /** 資料新增日期 */
  createdAt: Scalars['DateTime'];
  /** 幣別 */
  currencyId: Scalars['ID'];
  /** 敘述 */
  description?: Maybe<Scalars['String']>;
  /** 依照金額折扣 */
  discountAmount?: Maybe<Scalars['Float']>;
  /** 依照比率折扣 */
  discountRate?: Maybe<Scalars['Float']>;
  /** 眼科優惠 */
  eyePromotionId: Scalars['ID'];
  /** 眼科報價單 */
  eyeQuotationOrderId: Scalars['ID'];
  id: Scalars['ID'];
  /** 優惠名稱 */
  name: Scalars['String'];
  /** 資料修改日期 */
  updatedAt: Scalars['DateTime'];
};

export type IEyeQuotationOrderType = {
  /** 編號 */
  code?: Maybe<EnumEyeQuotationOrderType>;
  /** 公司 */
  companyId: Scalars['ID'];
  /** 資料新增日期 */
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  /** 名稱 */
  name: Scalars['String'];
  /** 區域 */
  regionId: Scalars['ID'];
  /** 資料修改日期 */
  updatedAt: Scalars['DateTime'];
  /** 檢視順序 */
  viewOrder?: Maybe<Scalars['Int']>;
};

export type IEyeQuotationOrderWarrantyItem = {
  createdAt?: Maybe<Scalars['DateTime']>;
  eyeQuotationOrderId: Scalars['ID'];
  id: Scalars['ID'];
  materialCode: Scalars['String'];
  materialId: Scalars['ID'];
  materialModel?: Maybe<Scalars['String']>;
  materialName?: Maybe<Scalars['String']>;
  materialSpec?: Maybe<Scalars['String']>;
  materialUnit?: Maybe<Scalars['String']>;
  memo?: Maybe<Scalars['String']>;
  /** 實際數量 */
  qty: Scalars['Int'];
  /** 序號 */
  sn?: Maybe<Scalars['String']>;
  /** 醫療器材單一識別碼 */
  udi?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type IEyeServiceOrder = {
  /** 地址 */
  address: Scalars['String'];
  /** 核准狀態 */
  approvalStatus: EnumEyeServiceOrderApprovalStatus;
  /** 核准人 */
  approverUserId?: Maybe<Scalars['ID']>;
  /** 指派部門 */
  assigneeDeptId: Scalars['ID'];
  /** 被分派人 */
  assigneeUserId?: Maybe<Scalars['ID']>;
  /** BPM Instance id */
  bpmInstanceId?: Maybe<Scalars['ID']>;
  /** 商機 */
  businessId?: Maybe<Scalars['ID']>;
  /** 市級、二級行政區 */
  cityId?: Maybe<Scalars['ID']>;
  code: Scalars['String'];
  /** 公司 */
  companyId?: Maybe<Scalars['ID']>;
  /** 聯絡人 */
  contactPerson: Scalars['String'];
  /** 聯絡電話 */
  contactPhone: Scalars['String'];
  /** 成本中心 */
  costCenterId?: Maybe<Scalars['ID']>;
  createdAt: Scalars['DateTime'];
  createdUserId?: Maybe<Scalars['ID']>;
  /** 對象客戶 */
  customerId?: Maybe<Scalars['ID']>;
  description?: Maybe<Scalars['String']>;
  /** 調度士 */
  dispatcherUserId?: Maybe<Scalars['ID']>;
  /** 縣級、三級行政區 */
  districtId?: Maybe<Scalars['ID']>;
  /** 預計派工日 */
  estimatedDate: Scalars['Date'];
  /** 眼科固定資產租借目的 */
  eyeFixedAssetRentalGoalId?: Maybe<Scalars['ID']>;
  /** 眼科固定資產租借對象 */
  eyeFixedAssetRentalObjectId?: Maybe<Scalars['ID']>;
  eyeServiceOrderTypeId: Scalars['ID'];
  eyeWarrantyContractId?: Maybe<Scalars['ID']>;
  id: Scalars['ID'];
  memo?: Maybe<Scalars['String']>;
  /** 優先權 */
  priority: EnumEyeServiceOrderPriority;
  /** 省級、一級行政區 */
  provinceId?: Maybe<Scalars['ID']>;
  regionId: Scalars['ID'];
  status: EnumEyeServiceOrderStatus;
  updatedAt: Scalars['DateTime'];
  updatedUserId?: Maybe<Scalars['ID']>;
  /** 對象使用者 */
  userId?: Maybe<Scalars['ID']>;
};

export type IEyeServiceOrderAcceptance = {
  answers?: Maybe<Scalars['JSON']>;
  createdAt: Scalars['DateTime'];
  createdUserId?: Maybe<Scalars['ID']>;
  extension: Scalars['String'];
  eyeProductAcceptanceFormId: Scalars['ID'];
  eyeServiceOrderId: Scalars['ID'];
  eyeServiceOrderItemId?: Maybe<Scalars['ID']>;
  id: Scalars['ID'];
  memo?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  s3Key: Scalars['String'];
  updatedAt: Scalars['DateTime'];
  updatedUserId?: Maybe<Scalars['ID']>;
};

export type IEyeServiceOrderAttachFile = {
  createdAt: Scalars['DateTime'];
  createdUserId?: Maybe<Scalars['ID']>;
  extension: Scalars['String'];
  eyeServiceOrderId: Scalars['ID'];
  id: Scalars['ID'];
  memo?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  s3Key: Scalars['String'];
  updatedAt: Scalars['DateTime'];
  updatedUserId?: Maybe<Scalars['ID']>;
};

export type IEyeServiceOrderExtension = {
  approvalStatus: EnumEyeServiceOrderExtensionApprovalStatus;
  bpmInstanceId?: Maybe<Scalars['ID']>;
  createdAt: Scalars['DateTime'];
  extendDate1: Scalars['Date'];
  extendDate2: Scalars['Date'];
  eyeServiceOrderId: Scalars['ID'];
  id: Scalars['ID'];
  memo?: Maybe<Scalars['String']>;
  updatedAt: Scalars['DateTime'];
};

export type IEyeServiceOrderItem = {
  createdAt: Scalars['DateTime'];
  createdUserId?: Maybe<Scalars['ID']>;
  eyeServiceOrderId: Scalars['ID'];
  id: Scalars['ID'];
  materialCode: Scalars['String'];
  materialId: Scalars['ID'];
  materialModel?: Maybe<Scalars['String']>;
  materialName?: Maybe<Scalars['String']>;
  materialSpec?: Maybe<Scalars['String']>;
  materialUnit?: Maybe<Scalars['String']>;
  memo?: Maybe<Scalars['String']>;
  qty: Scalars['Int'];
  sn?: Maybe<Scalars['String']>;
  /** 醫療器材單一識別碼 */
  udi: Scalars['String'];
  updatedAt: Scalars['DateTime'];
  updatedUserId?: Maybe<Scalars['ID']>;
};

export type IEyeServiceOrderQuestionnaire = {
  answers?: Maybe<Scalars['JSON']>;
  createdAt: Scalars['DateTime'];
  createdUserId?: Maybe<Scalars['ID']>;
  eyeServiceOrderId: Scalars['ID'];
  id: Scalars['ID'];
  questionnaireId: Scalars['ID'];
  score?: Maybe<Scalars['Int']>;
  status: Scalars['Int'];
  updatedAt: Scalars['DateTime'];
  updatedUserId?: Maybe<Scalars['ID']>;
};

export type IEyeServiceOrderType = {
  /** 編號 */
  code?: Maybe<EnumEyeServiceOrderTypeCode>;
  id: Scalars['ID'];
  name: Scalars['String'];
  viewOrder?: Maybe<Scalars['Int']>;
};

export type IEyeServiceOrderWorkDiary = {
  /** 公司 */
  companyId?: Maybe<Scalars['ID']>;
  createdAt: Scalars['DateTime'];
  createdUserId?: Maybe<Scalars['ID']>;
  /** 實際服務日期 */
  date?: Maybe<Scalars['DateTime']>;
  /** 預計服務日期 */
  estimatedDate?: Maybe<Scalars['DateTime']>;
  /** 預計服務時數 */
  estimatedHours?: Maybe<Scalars['Int']>;
  eyeFixedAssetRentalRecordId?: Maybe<Scalars['ID']>;
  eyeServiceOrderId: Scalars['ID'];
  eyeServiceOrderWorkDiaryTypeId: Scalars['ID'];
  /** 實際服務時數 */
  hours?: Maybe<Scalars['Int']>;
  id: Scalars['ID'];
  regionId: Scalars['ID'];
  remark?: Maybe<Scalars['String']>;
  status: EnumEyeServiceOrderWorkDiaryStatus;
  updatedAt: Scalars['DateTime'];
  updatedUserId?: Maybe<Scalars['ID']>;
};

export type IEyeServiceOrderWorkDiaryAttachFile = {
  createdAt: Scalars['DateTime'];
  createdUserId?: Maybe<Scalars['ID']>;
  extension: Scalars['String'];
  eyeServiceOrderWorkDiaryId: Scalars['ID'];
  id: Scalars['ID'];
  memo?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  s3Key: Scalars['String'];
  updatedAt: Scalars['DateTime'];
  updatedUserId?: Maybe<Scalars['ID']>;
};

export type IEyeServiceOrderWorkDiaryCheckIn = {
  /** 內容 */
  content?: Maybe<Scalars['String']>;
  /** 資料新增日期 */
  createdAt: Scalars['DateTime'];
  /** 資料建立人員 */
  createdUserId?: Maybe<Scalars['ID']>;
  /** 眼科服務單工作記錄 */
  eyeServiceOrderWorkDiaryId: Scalars['ID'];
  id: Scalars['ID'];
  /** WGS84 緯度 */
  lat?: Maybe<Scalars['Float']>;
  /** WGS84 經度 */
  lng?: Maybe<Scalars['Float']>;
  /** 資料修改日期 */
  updatedAt: Scalars['DateTime'];
  /** 資料修改人員 */
  updatedUserId?: Maybe<Scalars['ID']>;
};

export type IEyeServiceOrderWorkDiaryItem = {
  createdAt?: Maybe<Scalars['DateTime']>;
  eyeServiceOrderWorkDiaryId: Scalars['ID'];
  id: Scalars['ID'];
  materialCode: Scalars['String'];
  materialId: Scalars['ID'];
  materialModel?: Maybe<Scalars['String']>;
  materialName?: Maybe<Scalars['String']>;
  materialSpec?: Maybe<Scalars['String']>;
  materialUnit?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type IEyeServiceOrderWorkDiaryType = {
  createdAt?: Maybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  name: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type IEyeSoldProduct = {
  /** 公司 */
  companyId: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  createdUserId?: Maybe<Scalars['ID']>;
  customerCode: Scalars['String'];
  customerId: Scalars['ID'];
  deptCode: Scalars['String'];
  deptId: Scalars['ID'];
  description?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  materialCode: Scalars['String'];
  materialId: Scalars['ID'];
  materialModel?: Maybe<Scalars['String']>;
  materialName?: Maybe<Scalars['String']>;
  materialSpec?: Maybe<Scalars['String']>;
  materialUnit?: Maybe<Scalars['String']>;
  memo?: Maybe<Scalars['String']>;
  orderCode: Scalars['String'];
  regionId: Scalars['ID'];
  shippingDate: Scalars['Date'];
  shippingOrderCode: Scalars['String'];
  sn?: Maybe<Scalars['String']>;
  status: Scalars['Int'];
  /** 醫療器材單一識別碼 */
  udi?: Maybe<Scalars['String']>;
  updatedAt: Scalars['DateTime'];
  updatedUserId?: Maybe<Scalars['ID']>;
  userCode: Scalars['String'];
  userId: Scalars['ID'];
};

export type IEyeWarrantyContract = {
  /** 地址 */
  address: Scalars['String'];
  /** 合約審查號 */
  approvalCode: Scalars['String'];
  /** 市級、二級行政區 */
  cityId?: Maybe<Scalars['ID']>;
  /** 合約編號 */
  code: Scalars['String'];
  /** 公司 */
  companyId: Scalars['ID'];
  /** 聯絡人 */
  contactPerson: Scalars['String'];
  /** 聯絡電話 */
  contactPhone: Scalars['String'];
  createdAt: Scalars['DateTime'];
  createdUserId?: Maybe<Scalars['ID']>;
  /** 客戶 */
  customerId: Scalars['ID'];
  /** 負責部門 */
  deptId: Scalars['ID'];
  description?: Maybe<Scalars['String']>;
  /** 縣級、三級行政區 */
  districtId?: Maybe<Scalars['ID']>;
  id: Scalars['ID'];
  memo?: Maybe<Scalars['String']>;
  /** 訂單編號 */
  orderCode: Scalars['String'];
  /** 省級、一級行政區 */
  provinceId?: Maybe<Scalars['ID']>;
  regionId: Scalars['ID'];
  status: EnumEyeWarrantyContractStatus;
  updatedAt: Scalars['DateTime'];
  updatedUserId?: Maybe<Scalars['ID']>;
  /** 負責人 */
  userId: Scalars['ID'];
  /** 保固起始日 */
  warrantyDate1: Scalars['Date'];
  /** 保固結束日 */
  warrantyDate2: Scalars['Date'];
  /** 保固月份 */
  warrantyMonths: Scalars['Int'];
  /** 保固週期類型 */
  warrantyPeriodTypeId: Scalars['ID'];
};

export type IEyeWarrantyContractItem = {
  createdAt: Scalars['DateTime'];
  eyeWarrantyContractId: Scalars['ID'];
  id: Scalars['ID'];
  materialCode: Scalars['String'];
  materialId: Scalars['ID'];
  materialModel?: Maybe<Scalars['String']>;
  materialName?: Maybe<Scalars['String']>;
  materialSpec?: Maybe<Scalars['String']>;
  materialUnit?: Maybe<Scalars['String']>;
  memo?: Maybe<Scalars['String']>;
  qty: Scalars['Int'];
  /** 序號 */
  sn?: Maybe<Scalars['String']>;
  /** 醫療器材單一識別碼 */
  udi?: Maybe<Scalars['String']>;
  updatedAt: Scalars['DateTime'];
};

export type IEyeWarrantyPeriodType = {
  code: Scalars['String'];
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  name: Scalars['String'];
  unit: EnumEyeWarrantyPeriodTypeUnit;
  updatedAt: Scalars['DateTime'];
  value: Scalars['Int'];
};

export type IMaterial = {
  /** 廠牌 */
  brand?: Maybe<Scalars['String']>;
  /** 編號 */
  code: Scalars['String'];
  /** 顏色 */
  color?: Maybe<Scalars['String']>;
  /** 公司 */
  companyId: Scalars['ID'];
  /** 成本幣別 */
  costCurrencyId?: Maybe<Scalars['ID']>;
  /** 成本金額 */
  costPrice?: Maybe<Scalars['Float']>;
  /** 庫存單位 */
  countingUnitId?: Maybe<Scalars['ID']>;
  /** 資料新增日期 */
  createdAt: Scalars['DateTime'];
  /** 資料建立人員 */
  createdUserId?: Maybe<Scalars['ID']>;
  id: Scalars['ID'];
  /** 是否可用（棄用；改用 status） */
  isActive: Scalars['Boolean'];
  /** 維修類別 */
  materialRepairTypeId?: Maybe<Scalars['ID']>;
  /** 類別 */
  materialTypeId?: Maybe<Scalars['ID']>;
  /** 型號 */
  model?: Maybe<Scalars['String']>;
  /** 名稱 */
  name: Scalars['String'];
  /** 名稱（中文） */
  name2?: Maybe<Scalars['String']>;
  /** 區域 */
  regionId: Scalars['ID'];
  /** 銷售幣別 */
  saleCurrencyId?: Maybe<Scalars['ID']>;
  /** 銷售金額 */
  salePrice?: Maybe<Scalars['Float']>;
  /** 規格／機型 */
  spec?: Maybe<Scalars['String']>;
  /** 狀態（0 = 停用, 1 = 啟用） */
  status: EnumMaterialStatus;
  /** 供應商 */
  supplierId?: Maybe<Scalars['ID']>;
  /** 同步代碼 */
  syncCode?: Maybe<Scalars['String']>;
  /** 同步來源 */
  syncSourceId?: Maybe<Scalars['ID']>;
  /** 稅率 */
  taxRate: Scalars['Float'];
  /** 庫存單位（棄用；改用 counting_unit_id） */
  unit?: Maybe<Scalars['String']>;
  /** 資料修改日期 */
  updatedAt: Scalars['DateTime'];
  /** 資料修改人員 */
  updatedUserId?: Maybe<Scalars['ID']>;
};

export type IMaterialImage = {
  createdAt: Scalars['DateTime'];
  createdUserId?: Maybe<Scalars['ID']>;
  /** 副檔名 */
  extension: Scalars['String'];
  id: Scalars['ID'];
  materialId: Scalars['ID'];
  /** 備註 */
  memo?: Maybe<Scalars['String']>;
  /** 檔案名稱 */
  name?: Maybe<Scalars['String']>;
  /** S3 key */
  s3Key: Scalars['String'];
  updatedAt: Scalars['DateTime'];
  updatedUserId?: Maybe<Scalars['ID']>;
};

export type IMaterialRepairType = {
  code: Scalars['String'];
  companyId: Scalars['ID'];
  id: Scalars['ID'];
  name: Scalars['String'];
  regionId: Scalars['ID'];
};

export type IMaterialType = {
  code: Scalars['String'];
  companyId: Scalars['ID'];
  id: Scalars['ID'];
  name: Scalars['String'];
  regionId: Scalars['ID'];
};

export type IMedicalDeviceClassification = {
  code: Scalars['String'];
  /** 敘述 */
  description?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  name: Scalars['String'];
  regionId: Scalars['ID'];
};

export type INotification = {
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  isRead: Scalars['Boolean'];
  message?: Maybe<Scalars['String']>;
  type?: Maybe<EnumNotificationType>;
  updatedAt: Scalars['DateTime'];
  userId?: Maybe<Scalars['ID']>;
  values?: Maybe<Scalars['JSON']>;
};

export type IPaymentMethod = {
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  name: Scalars['String'];
  regionId: Scalars['ID'];
  updatedAt: Scalars['DateTime'];
};

export type IPermission = {
  applicationId: Scalars['ID'];
  /** 編號 */
  code: Scalars['String'];
  /** 敘述 */
  description?: Maybe<Scalars['String']>;
  groupId: Scalars['ID'];
  id: Scalars['ID'];
  /** 名稱 */
  name: Scalars['String'];
};

export type IPermissionApplication = {
  /** 編號 */
  code: Scalars['String'];
  /** 敘述 */
  description?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  /** 名稱 */
  name: Scalars['String'];
};

export type IPermissionGroup = {
  /** 編號 */
  code: Scalars['String'];
  /** 敘述 */
  description?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  /** 名稱 */
  name: Scalars['String'];
};

export type IProductTeam = {
  /** 編號 */
  code?: Maybe<Scalars['String']>;
  /** 資料新增日期 */
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  /** 名稱 */
  name: Scalars['String'];
  /** 資料修改日期 */
  updatedAt: Scalars['DateTime'];
  /** 檢視順序 */
  viewOrder?: Maybe<Scalars['Int']>;
};

export type IProvince = {
  code?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  regionId: Scalars['ID'];
  updatedAt: Scalars['DateTime'];
  viewOrder: Scalars['Int'];
};

export type IQuestionnaire = {
  createdAt: Scalars['DateTime'];
  createdUserId?: Maybe<Scalars['ID']>;
  date1?: Maybe<Scalars['Date']>;
  date2?: Maybe<Scalars['Date']>;
  fields?: Maybe<Scalars['JSON']>;
  id: Scalars['ID'];
  name: Scalars['String'];
  status: EnumQuestionnaireStatus;
  typeId: Scalars['ID'];
  updatedAt: Scalars['DateTime'];
  updatedUserId?: Maybe<Scalars['ID']>;
};

export type IRegion = {
  code?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  /** 當地幣別 */
  localCurrencyId: Scalars['ID'];
  name: Scalars['String'];
  updatedAt: Scalars['DateTime'];
};

export type IRentProduct = {
  /** 資料新增日期 */
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  /** 名稱 */
  name: Scalars['String'];
  /** 業務團隊組織 */
  salesTeamGroupId: Scalars['ID'];
  /** 序號 */
  sn?: Maybe<Scalars['String']>;
  /** 資料修改日期 */
  updatedAt: Scalars['DateTime'];
  /** 檢視順序 */
  viewOrder?: Maybe<Scalars['Int']>;
};

export type IRole = {
  /** 允許的公司ids */
  allowCompanyIds: Array<Scalars['Int']>;
  /** 允許的商品團隊ids */
  allowProductTeamIds: Array<Scalars['Int']>;
  /** 允許的區域ids */
  allowRegionIds: Array<Scalars['Int']>;
  /** 允許的業務團隊組織ids */
  allowSalesTeamGroupIds: Array<Scalars['Int']>;
  /** 允許的業務團隊ids */
  allowSalesTeamIds: Array<Scalars['Int']>;
  /** 允許的使用者ids */
  allowUserIds: Array<Scalars['Int']>;
  /** 編號 */
  code?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  /** 是否允許所有業務團隊(中国眼科) */
  isAllowAllChnEyeSalesTeam: Scalars['Boolean'];
  /** 是否允許所有公司(cn) */
  isAllowAllCnCompanies: Scalars['Boolean'];
  /** 是否允許所有區域 */
  isAllowAllRegions: Scalars['Boolean'];
  /** 是否允許所有業務團隊組織 */
  isAllowAllSalesTeamGroups: Scalars['Boolean'];
  /** 是否允許所有公司(tw) */
  isAllowAllTwCompanies: Scalars['Boolean'];
  /** 是否允許所有業務團隊(電子耳) */
  isAllowAllTwnClSalesTeam: Scalars['Boolean'];
  /** 是否允許所有業務團隊(科明／明睿) */
  isAllowAllTwnEcAndMrSalesTeam: Scalars['Boolean'];
  /** 是否允許所有業務團隊(臺灣眼科) */
  isAllowAllTwnEyeSalesTeam: Scalars['Boolean'];
  /** 是否只允許自己的資料 */
  isOnlyOwnUser: Scalars['Boolean'];
  /** 是否為系統管理者 */
  isSystemAdmin: Scalars['Boolean'];
  /** 名稱 */
  name: Scalars['String'];
};

export type IRolesUser = {
  roleId: Scalars['ID'];
  userId: Scalars['ID'];
};

export type ISalesProductOverallForecastItem = {
  /** 金額 */
  amount: Scalars['Float'];
  /** 資料新增日期 */
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  /** 月份 */
  month: Scalars['Int'];
  /** 單頭 */
  overallForecastId: Scalars['ID'];
  /** 數量 */
  qty: Scalars['Float'];
  /** 單價 */
  unitPrice: Scalars['Float'];
  /** 資料修改日期 */
  updatedAt: Scalars['DateTime'];
};

export type ISalesProductOverallGoalItem = {
  /** 金額 */
  amount: Scalars['Float'];
  /** 資料新增日期 */
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  /** 月份 */
  month: Scalars['Int'];
  /** 單頭 */
  overallGoalId: Scalars['ID'];
  /** 數量 */
  qty: Scalars['Float'];
  /** 單價 */
  unitPrice: Scalars['Float'];
  /** 資料修改日期 */
  updatedAt: Scalars['DateTime'];
};

export type ISalesProductTeamGoalItem = {
  /** 金額 */
  amount: Scalars['Int'];
  /** 資料新增日期 */
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  /** 月份 */
  month: Scalars['Int'];
  /** 數量 */
  qty: Scalars['Int'];
  /** 單頭 */
  teamGoalId: Scalars['ID'];
  /** 資料修改日期 */
  updatedAt: Scalars['DateTime'];
};

export type ISalesProductType = {
  /** 編號 */
  code?: Maybe<Scalars['String']>;
  /** 資料新增日期 */
  createdAt: Scalars['DateTime'];
  /** 群組 */
  groupId?: Maybe<Scalars['ID']>;
  id: Scalars['ID'];
  /** 名稱 */
  name: Scalars['String'];
  /** 業務團隊組織 */
  salesTeamGroupId: Scalars['ID'];
  /** 資料修改日期 */
  updatedAt: Scalars['DateTime'];
  /** 檢視順序 */
  viewOrder?: Maybe<Scalars['Int']>;
};

export type ISalesProductTypeGroup = {
  /** 編號 */
  code?: Maybe<Scalars['String']>;
  /** 資料新增日期 */
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  /** 名稱 */
  name: Scalars['String'];
  /** 業務團隊組織 */
  salesTeamGroupId: Scalars['ID'];
  /** 資料修改日期 */
  updatedAt: Scalars['DateTime'];
  /** 檢視順序 */
  viewOrder?: Maybe<Scalars['Int']>;
};

export type ISalesProductUserForecastItem = {
  /** 資料新增日期 */
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  /** 月份 */
  month: Scalars['Int'];
  /** 月初預測數量 */
  qtyEarlyMonth: Scalars['Int'];
  /** 月底預測數量 */
  qtyLateMonth: Scalars['Int'];
  /** Quota */
  quota: Scalars['Int'];
  /** 資料修改日期 */
  updatedAt: Scalars['DateTime'];
  /** 單頭 */
  userForecastId: Scalars['ID'];
};

export type ISalesProductUserGoalItem = {
  /** 金額 */
  amount: Scalars['Int'];
  /** 資料新增日期 */
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  /** 月份 */
  month: Scalars['Int'];
  /** 數量 */
  qty: Scalars['Int'];
  /** 資料修改日期 */
  updatedAt: Scalars['DateTime'];
  /** 單頭 */
  userGoalId: Scalars['ID'];
};

export type ISalesTeam = {
  /** 編號 */
  code?: Maybe<Scalars['String']>;
  /** 資料新增日期 */
  createdAt: Scalars['DateTime'];
  /** 組織 */
  groupId?: Maybe<Scalars['ID']>;
  id: Scalars['ID'];
  /** 主管 */
  managerId?: Maybe<Scalars['ID']>;
  /** 名稱 */
  name: Scalars['String'];
  /** 上層團隊 */
  parentId?: Maybe<Scalars['ID']>;
  /** 需要為客戶指定主要負責業務 */
  requiredForPrimaryCustomer: Scalars['Boolean'];
  /** 資料修改日期 */
  updatedAt: Scalars['DateTime'];
  /** 檢視順序 */
  viewOrder?: Maybe<Scalars['Int']>;
};

export type ISalesTeamGoalItem = {
  /** 金額 */
  amount: Scalars['Int'];
  /** 資料新增日期 */
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  /** 月份 */
  month: Scalars['Int'];
  /** 單頭 */
  teamGoalId: Scalars['ID'];
  /** 資料修改日期 */
  updatedAt: Scalars['DateTime'];
};

export type ISalesTeamGroup = {
  /** 編號 */
  code?: Maybe<EnumSalesTeamGroupCode>;
  /** 公司 */
  companyId?: Maybe<Scalars['ID']>;
  /** 資料新增日期 */
  createdAt: Scalars['DateTime'];
  /** 主管 */
  directorId?: Maybe<Scalars['ID']>;
  id: Scalars['ID'];
  /** 名稱 */
  name: Scalars['String'];
  /** 區域 */
  regionId?: Maybe<Scalars['ID']>;
  /** 資料修改日期 */
  updatedAt: Scalars['DateTime'];
  /** 檢視順序 */
  viewOrder?: Maybe<Scalars['Int']>;
};

export type ISalesTeamUnit = {
  /** 資料新增日期 */
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  /** 名稱 */
  name: Scalars['String'];
  /** 業務團隊 */
  salesTeamId: Scalars['ID'];
  /** 資料修改日期 */
  updatedAt: Scalars['DateTime'];
  /** 負責業務 */
  userId?: Maybe<Scalars['ID']>;
  /** 檢視順序 */
  viewOrder?: Maybe<Scalars['Int']>;
};

export type ISalesType = {
  /** 編號 */
  code?: Maybe<Scalars['String']>;
  /** 資料新增日期 */
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  /** 名稱 */
  name: Scalars['String'];
  /** 業務團隊組織 */
  salesTeamGroupId: Scalars['ID'];
  /** 資料修改日期 */
  updatedAt: Scalars['DateTime'];
  /** 檢視順序 */
  viewOrder?: Maybe<Scalars['Int']>;
};

export type ISalesUserForecastItem = {
  /** 月初預測金額 */
  amountEarlyMonth: Scalars['Int'];
  /** 月底預測金額 */
  amountLateMonth: Scalars['Int'];
  /** 資料新增日期 */
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  /** 月份 */
  month: Scalars['Int'];
  /** Quota */
  quota: Scalars['Int'];
  /** 資料修改日期 */
  updatedAt: Scalars['DateTime'];
  /** 單頭 */
  userForecastId: Scalars['ID'];
};

export type ISalesUserGoalItem = {
  /** 金額 */
  amount: Scalars['Int'];
  /** 資料新增日期 */
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  /** 月份 */
  month: Scalars['Int'];
  /** 資料修改日期 */
  updatedAt: Scalars['DateTime'];
  /** 單頭 */
  userGoalId: Scalars['ID'];
};

export type IShippingMethod = {
  code: Scalars['String'];
  companyId: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  isActive: Scalars['Boolean'];
  name: Scalars['String'];
  regionId: Scalars['ID'];
  updatedAt: Scalars['DateTime'];
  viewOrder?: Maybe<Scalars['Int']>;
};

export type IStore = {
  address?: Maybe<Scalars['String']>;
  areaId?: Maybe<Scalars['ID']>;
  cityId?: Maybe<Scalars['ID']>;
  /** 閉幕日期 */
  closingDate?: Maybe<Scalars['Date']>;
  code: Scalars['String'];
  companyId?: Maybe<Scalars['ID']>;
  createdAt: Scalars['DateTime'];
  createdUserId?: Maybe<Scalars['ID']>;
  departmentId?: Maybe<Scalars['ID']>;
  districtId?: Maybe<Scalars['ID']>;
  email?: Maybe<Scalars['String']>;
  /** 統編 */
  guiNumber?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  /** 是否為維修據點 */
  isMaintenanceCenter: Scalars['Boolean'];
  /** 是否有POS */
  isPosAvailable: Scalars['Boolean'];
  /** 是否顯示於Morear合作夥伴 */
  isShowInMorear: Scalars['Boolean'];
  /** 是否顯示在睡得美 （ ResMed ） 預約頁面 */
  isShowInResmedBooking: Scalars['Boolean'];
  /** 緯度 */
  lat?: Maybe<Scalars['Float']>;
  /** 經度 */
  lng?: Maybe<Scalars['Float']>;
  name: Scalars['String'];
  /** 開幕日期 */
  openingDate?: Maybe<Scalars['Date']>;
  phone?: Maybe<Scalars['String']>;
  /** 圖片（連結） */
  picture?: Maybe<Scalars['String']>;
  regionId: Scalars['ID'];
  /** 睡眠技師 */
  sleepTechnologistUserId?: Maybe<Scalars['ID']>;
  updatedAt: Scalars['DateTime'];
  updatedUserId?: Maybe<Scalars['ID']>;
  zone2Id?: Maybe<Scalars['ID']>;
  zoneId?: Maybe<Scalars['ID']>;
};

export type ISupplier = {
  code: Scalars['String'];
  companyId: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  createdUserId?: Maybe<Scalars['ID']>;
  id: Scalars['ID'];
  /** 名稱 */
  name: Scalars['String'];
  /** 簡稱 */
  name2?: Maybe<Scalars['String']>;
  regionId: Scalars['ID'];
  updatedAt: Scalars['DateTime'];
  updatedUserId?: Maybe<Scalars['ID']>;
};

export type ISupplierProperty = {
  code: Scalars['String'];
  companyId: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  name: Scalars['String'];
  regionId: Scalars['ID'];
  updatedAt: Scalars['DateTime'];
};

export type ISyncSource = {
  code?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  name: Scalars['String'];
};

export type ITaxRate = {
  createdAt?: Maybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  rate: Scalars['Float'];
  regionId: Scalars['ID'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type IToken = {
  createdAt: Scalars['DateTime'];
  expiredAt?: Maybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  ip?: Maybe<Scalars['String']>;
  userAgent?: Maybe<Scalars['String']>;
  userCode: Scalars['String'];
};

export type IUser = {
  active?: Maybe<Scalars['String']>;
  /** 驗證方式(1. LDAP, 2. password) */
  authType: Scalars['Int'];
  code?: Maybe<Scalars['String']>;
  companyId?: Maybe<Scalars['ID']>;
  createdAt: Scalars['DateTime'];
  departmentId?: Maybe<Scalars['ID']>;
  email?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  /** 在職狀態 */
  isActive: Scalars['Boolean'];
  /** 聽力師 */
  isAudiologist: Scalars['Boolean'];
  /** 助聽器工程師 */
  isHearingAidEngineer: Scalars['Boolean'];
  isLocked: Scalars['Boolean'];
  /** 外包人員 */
  isOutsourcing: Scalars['Boolean'];
  /** 使用系統，最後選擇的門市 */
  lastSelectStoreId?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
  regionId: Scalars['ID'];
  storeId?: Maybe<Scalars['ID']>;
  updatedAt: Scalars['DateTime'];
};

export type IVisit = {
  /** 行為內容 */
  actionContent?: Maybe<Scalars['String']>;
  /** 行為 */
  actionId?: Maybe<Scalars['ID']>;
  /** 協訪主管 */
  assistedVisitSupervisorId?: Maybe<Scalars['ID']>;
  /** 商機 */
  businessId?: Maybe<Scalars['ID']>;
  /** 內容 */
  content?: Maybe<Scalars['String']>;
  /** 資料新增日期 */
  createdAt: Scalars['DateTime'];
  /** 資料建立人員 */
  createdUserId?: Maybe<Scalars['ID']>;
  /** 客戶 */
  customerId?: Maybe<Scalars['ID']>;
  /** 預估刀量 */
  estimatedSurgeryCount?: Maybe<Scalars['String']>;
  /** 是否主管協訪 */
  hasAssistedVisitSupervisor: Scalars['Boolean'];
  id: Scalars['ID'];
  /** 主要負責業務（棄用；改用 sales_team_unit_id ） */
  primaryUserId?: Maybe<Scalars['ID']>;
  /** 優先順序 */
  priorityOrder?: Maybe<Scalars['Int']>;
  /** 業務團隊組織 */
  salesTeamGroupId: Scalars['ID'];
  /** 負責業務團隊 */
  salesTeamId?: Maybe<Scalars['ID']>;
  /** 業務團隊單位 */
  salesTeamUnitId?: Maybe<Scalars['ID']>;
  /** 狀態 */
  status: EnumVisitStatus;
  /** 實際刀量 */
  surgeryCount?: Maybe<Scalars['String']>;
  /** 標題 */
  title?: Maybe<Scalars['String']>;
  /** 類型 */
  typeId?: Maybe<Scalars['ID']>;
  /** 資料修改日期 */
  updatedAt: Scalars['DateTime'];
  /** 資料修改人員 */
  updatedUserId?: Maybe<Scalars['ID']>;
  /** 拜訪地址 */
  visitAddress?: Maybe<Scalars['String']>;
  /** 拜訪日期 */
  visitDate: Scalars['Date'];
};

export type IVisitAction = {
  /** 資料新增日期 */
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  /** 名稱 */
  name: Scalars['String'];
  /** 業務團隊組織 */
  salesTeamGroupId: Scalars['ID'];
  /** 資料修改日期 */
  updatedAt: Scalars['DateTime'];
  /** 檢視順序 */
  viewOrder?: Maybe<Scalars['Int']>;
};

export type IVisitCheckIn = {
  /** 內容 */
  content?: Maybe<Scalars['String']>;
  /** 資料新增日期 */
  createdAt: Scalars['DateTime'];
  /** 資料建立人員 */
  createdUserId?: Maybe<Scalars['ID']>;
  id: Scalars['ID'];
  /** WGS84 緯度 */
  lat: Scalars['Float'];
  /** WGS84 經度 */
  lng: Scalars['Float'];
  /** 資料修改日期 */
  updatedAt: Scalars['DateTime'];
  /** 資料修改人員 */
  updatedUserId?: Maybe<Scalars['ID']>;
  /** 拜訪 */
  visitId: Scalars['ID'];
};

export type IVisitGoal = {
  /** 資料新增日期 */
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  /** 名稱 */
  name: Scalars['String'];
  /** 業務團隊組織 */
  salesTeamGroupId: Scalars['ID'];
  /** 資料修改日期 */
  updatedAt: Scalars['DateTime'];
  /** 檢視順序 */
  viewOrder?: Maybe<Scalars['Int']>;
};

export type IVisitMemo = {
  /** 內容 */
  content?: Maybe<Scalars['String']>;
  /** 資料新增日期 */
  createdAt: Scalars['DateTime'];
  /** 資料建立人員 */
  createdUserId?: Maybe<Scalars['ID']>;
  id: Scalars['ID'];
  /** 標題 */
  title?: Maybe<Scalars['String']>;
  /** 資料修改日期 */
  updatedAt: Scalars['DateTime'];
  /** 資料修改人員 */
  updatedUserId?: Maybe<Scalars['ID']>;
  /** 拜訪 */
  visitId: Scalars['ID'];
};

export type IVisitProperty = {
  /** 資料新增日期 */
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  /** 名稱 */
  name: Scalars['String'];
  /** 業務團隊組織 */
  salesTeamGroupId: Scalars['ID'];
  /** 類型 */
  typeId: Scalars['ID'];
  /** 資料修改日期 */
  updatedAt: Scalars['DateTime'];
  /** 檢視順序 */
  viewOrder?: Maybe<Scalars['Int']>;
};

export type IVisitPropertyType = {
  /** 編號 */
  code?: Maybe<Scalars['String']>;
  /** 資料新增日期 */
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  /** 名稱 */
  name: Scalars['String'];
  /** 業務團隊組織 */
  salesTeamGroupId: Scalars['ID'];
  /** 資料修改日期 */
  updatedAt: Scalars['DateTime'];
  /** 檢視順序 */
  viewOrder?: Maybe<Scalars['Int']>;
};

export type IVisitTimePeriod = {
  /** 資料新增日期 */
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  /** 名稱 */
  name: Scalars['String'];
  /** 業務團隊組織 */
  salesTeamGroupId: Scalars['ID'];
  /** 資料修改日期 */
  updatedAt: Scalars['DateTime'];
  /** 檢視順序 */
  viewOrder?: Maybe<Scalars['Int']>;
};

export type IVisitType = {
  /** 編號 */
  code?: Maybe<EnumVisitTypeCode>;
  /** 資料新增日期 */
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  /** 名稱 */
  name: Scalars['String'];
  /** 業務團隊組織 */
  salesTeamGroupId: Scalars['ID'];
  /** 資料修改日期 */
  updatedAt: Scalars['DateTime'];
  /** 檢視順序 */
  viewOrder?: Maybe<Scalars['Int']>;
};

export type IWarehouse = {
  /** 編號 */
  code?: Maybe<Scalars['String']>;
  /** 資料新增日期 */
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  /** 是否啟用 */
  isActive: Scalars['Boolean'];
  /** 名稱 */
  name: Scalars['String'];
  /** 區域 */
  regionId?: Maybe<Scalars['ID']>;
  /** 資料修改日期 */
  updatedAt: Scalars['DateTime'];
};

export type IZone = {
  companyId?: Maybe<Scalars['ID']>;
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  name: Scalars['String'];
  regionId: Scalars['ID'];
  updatedAt: Scalars['DateTime'];
  viewOrder: Scalars['Int'];
};

export type IZone2 = {
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  name: Scalars['String'];
  regionId: Scalars['ID'];
  updatedAt: Scalars['DateTime'];
  viewOrder: Scalars['Int'];
  zoneId?: Maybe<Scalars['ID']>;
};

export type InventorySyncSourceSearchInput = {
  code?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['ID']>;
  name?: InputMaybe<Scalars['String']>;
};

export type InventorySyncSourceSearchResult = {
  count: Scalars['Int'];
  pageInfo: PageInfo;
  rows: Array<SyncSource>;
};

export type Material = IMaterial & {
  /** 廠牌 */
  brand?: Maybe<Scalars['String']>;
  /** 編號 */
  code: Scalars['String'];
  /** 顏色 */
  color?: Maybe<Scalars['String']>;
  company?: Maybe<Company>;
  /** 公司 */
  companyId: Scalars['ID'];
  costCurrency?: Maybe<Currency>;
  /** 成本幣別 */
  costCurrencyId?: Maybe<Scalars['ID']>;
  /** 成本金額 */
  costPrice?: Maybe<Scalars['Float']>;
  countingUnit?: Maybe<CountingUnit>;
  /** 庫存單位 */
  countingUnitId?: Maybe<Scalars['ID']>;
  /** 資料新增日期 */
  createdAt: Scalars['DateTime'];
  createdUser?: Maybe<User>;
  /** 資料建立人員 */
  createdUserId?: Maybe<Scalars['ID']>;
  id: Scalars['ID'];
  images: Array<MaterialImage>;
  /** 是否可用（棄用；改用 status） */
  isActive: Scalars['Boolean'];
  /** 維修類別 */
  materialRepairTypeId?: Maybe<Scalars['ID']>;
  /** 類別 */
  materialTypeId?: Maybe<Scalars['ID']>;
  medicalDeviceClassifications: Array<MedicalDeviceClassification>;
  /** 型號 */
  model?: Maybe<Scalars['String']>;
  /** 名稱 */
  name: Scalars['String'];
  /** 名稱（中文） */
  name2?: Maybe<Scalars['String']>;
  region?: Maybe<Region>;
  /** 區域 */
  regionId: Scalars['ID'];
  repairType?: Maybe<MaterialRepairType>;
  saleCurrency?: Maybe<Currency>;
  /** 銷售幣別 */
  saleCurrencyId?: Maybe<Scalars['ID']>;
  /** 銷售金額 */
  salePrice?: Maybe<Scalars['Float']>;
  salesProductTypes: Array<SalesProductType>;
  salesTypes: Array<SalesType>;
  /** 規格／機型 */
  spec?: Maybe<Scalars['String']>;
  /** 狀態（0 = 停用, 1 = 啟用） */
  status: EnumMaterialStatus;
  supplier?: Maybe<Supplier>;
  /** 供應商 */
  supplierId?: Maybe<Scalars['ID']>;
  /** 同步代碼 */
  syncCode?: Maybe<Scalars['String']>;
  /** 資料來源 */
  syncSource?: Maybe<SyncSource>;
  /** 同步來源 */
  syncSourceId?: Maybe<Scalars['ID']>;
  /** 稅率 */
  taxRate: Scalars['Float'];
  type?: Maybe<MaterialType>;
  /** 庫存單位（棄用；改用 counting_unit_id） */
  unit?: Maybe<Scalars['String']>;
  /** 資料修改日期 */
  updatedAt: Scalars['DateTime'];
  updatedUser?: Maybe<User>;
  /** 資料修改人員 */
  updatedUserId?: Maybe<Scalars['ID']>;
};

export type MaterialCreateInput = {
  /** 廠牌 */
  brand?: InputMaybe<Scalars['String']>;
  /** 料號 */
  code: Scalars['String'];
  /** 顏色 */
  color?: InputMaybe<Scalars['String']>;
  /** 公司 */
  companyId: Scalars['ID'];
  /** 成本幣別 */
  costCurrencyId?: InputMaybe<Scalars['ID']>;
  /** 成本金額 */
  costPrice?: InputMaybe<Scalars['Float']>;
  /** 庫存單位 */
  countingUnitId?: InputMaybe<Scalars['ID']>;
  /** 圖片 */
  images?: InputMaybe<Array<AttachmentCreateInput>>;
  /** 維修類別 */
  materialRepairTypeId?: InputMaybe<Scalars['ID']>;
  /** 類別 */
  materialTypeId?: InputMaybe<Scalars['ID']>;
  /** 型號 */
  model?: InputMaybe<Scalars['String']>;
  /** 名稱 */
  name: Scalars['String'];
  /** 簡稱 */
  name2?: InputMaybe<Scalars['String']>;
  /** 地區 */
  regionId: Scalars['ID'];
  /** 銷售幣別 */
  saleCurrencyId?: InputMaybe<Scalars['ID']>;
  /** 銷售金額 */
  salePrice?: InputMaybe<Scalars['Float']>;
  /** 商品業績類型 */
  salesProductTypeIds?: InputMaybe<Array<Scalars['ID']>>;
  /** 銷售業績類型 */
  salesTypeIds?: InputMaybe<Array<Scalars['ID']>>;
  /** 規格／機型 */
  spec?: InputMaybe<Scalars['String']>;
  /** 狀態 */
  status?: InputMaybe<EnumMaterialStatus>;
  /** 供應商 */
  supplierId?: InputMaybe<Scalars['ID']>;
  /** 稅率 */
  taxRate?: InputMaybe<Scalars['Float']>;
};

export type MaterialImage = IMaterialImage & {
  createdAt: Scalars['DateTime'];
  createdUser?: Maybe<User>;
  createdUserId?: Maybe<Scalars['ID']>;
  /** 副檔名 */
  extension: Scalars['String'];
  id: Scalars['ID'];
  materialId: Scalars['ID'];
  /** 備註 */
  memo?: Maybe<Scalars['String']>;
  /** 檔案名稱 */
  name?: Maybe<Scalars['String']>;
  /** S3 key */
  s3Key: Scalars['String'];
  /** 檔案大小(Bytes) */
  size: Scalars['Float'];
  updatedAt: Scalars['DateTime'];
  updatedUser?: Maybe<User>;
  updatedUserId?: Maybe<Scalars['ID']>;
  /** 短效期的S3 url */
  url?: Maybe<Scalars['URL']>;
};

export type MaterialImageCreateInput = {
  /** 只支援 png, jpg, jpeg圖片上傳 */
  file: Scalars['Upload'];
  materialId: Scalars['ID'];
  memo?: InputMaybe<Scalars['String']>;
  /** 檔案名稱 */
  name?: InputMaybe<Scalars['String']>;
};

export type MaterialImageSearchInput = {
  id?: InputMaybe<Scalars['ID']>;
  materialId?: InputMaybe<Scalars['ID']>;
};

export type MaterialImageSearchResult = {
  count: Scalars['Int'];
  pageInfo: PageInfo;
  rows: Array<MaterialImage>;
};

export type MaterialImageUpdateInput = {
  id: Scalars['ID'];
  memo?: InputMaybe<Scalars['String']>;
  /** 檔案名稱 */
  name?: InputMaybe<Scalars['String']>;
};

export type MaterialRepairType = IMaterialRepairType & {
  code: Scalars['String'];
  companyId: Scalars['ID'];
  id: Scalars['ID'];
  name: Scalars['String'];
  region?: Maybe<Region>;
  regionId: Scalars['ID'];
};

export type MaterialRepairTypeSearchInput = {
  code?: InputMaybe<Scalars['String']>;
  companyIds?: InputMaybe<Array<Scalars['ID']>>;
  id?: InputMaybe<Scalars['ID']>;
  name?: InputMaybe<Scalars['String']>;
  regionIds?: InputMaybe<Array<Scalars['ID']>>;
};

export type MaterialRepairTypeSearchResult = {
  count: Scalars['Int'];
  pageInfo: PageInfo;
  rows: Array<MaterialRepairType>;
};

export type MaterialSearchInput = {
  /** 廠牌 */
  brand?: InputMaybe<Scalars['String']>;
  /** 料號 */
  code?: InputMaybe<Scalars['String']>;
  /** 顏色 */
  color?: InputMaybe<Scalars['String']>;
  /** 公司 */
  companyIds?: InputMaybe<Array<Scalars['ID']>>;
  /** 庫存單位 */
  countingUnitId?: InputMaybe<Scalars['ID']>;
  id?: InputMaybe<Scalars['ID']>;
  /** 維修類別 */
  materialRepairTypeId?: InputMaybe<Scalars['ID']>;
  /** 類別 */
  materialTypeId?: InputMaybe<Scalars['ID']>;
  /** 型號 */
  model?: InputMaybe<Scalars['String']>;
  /** 名稱 */
  name?: InputMaybe<Scalars['String']>;
  /** 簡稱 */
  name2?: InputMaybe<Scalars['String']>;
  regionIds?: InputMaybe<Array<Scalars['ID']>>;
  /** 商品業績類型 */
  salesProductTypeIds?: InputMaybe<Array<Scalars['ID']>>;
  /** 銷售業績類型 */
  salesTypeIds?: InputMaybe<Array<Scalars['ID']>>;
  /** 規格／機型 */
  spec?: InputMaybe<Scalars['String']>;
  /** 狀態 */
  status?: InputMaybe<EnumMaterialStatus>;
  /** 供應商 */
  supplierId?: InputMaybe<Scalars['ID']>;
  /** 同步來源 */
  syncSourceId?: InputMaybe<Scalars['ID']>;
};

export type MaterialSearchResult = {
  count: Scalars['Int'];
  pageInfo: PageInfo;
  rows: Array<Material>;
};

export type MaterialType = IMaterialType & {
  code: Scalars['String'];
  companyId: Scalars['ID'];
  id: Scalars['ID'];
  name: Scalars['String'];
  region?: Maybe<Region>;
  regionId: Scalars['ID'];
};

export type MaterialTypeSearchInput = {
  code?: InputMaybe<Scalars['String']>;
  companyIds?: InputMaybe<Array<Scalars['ID']>>;
  id?: InputMaybe<Scalars['ID']>;
  name?: InputMaybe<Scalars['String']>;
  regionIds?: InputMaybe<Array<Scalars['ID']>>;
};

export type MaterialTypeSearchResult = {
  count: Scalars['Int'];
  pageInfo: PageInfo;
  rows: Array<MaterialType>;
};

export type MaterialUpdateInput = {
  /** 廠牌 */
  brand?: InputMaybe<Scalars['String']>;
  /** 料號 */
  code?: InputMaybe<Scalars['String']>;
  /** 顏色 */
  color?: InputMaybe<Scalars['String']>;
  /** 公司 */
  companyId?: InputMaybe<Scalars['ID']>;
  /** 成本幣別 */
  costCurrencyId?: InputMaybe<Scalars['ID']>;
  /** 成本金額 */
  costPrice?: InputMaybe<Scalars['Float']>;
  /** 庫存單位 */
  countingUnitId?: InputMaybe<Scalars['ID']>;
  /** 新增圖片 */
  createdImages?: InputMaybe<Array<AttachmentCreateInput>>;
  /** 刪除圖片 */
  deletedImageIds?: InputMaybe<Array<Scalars['ID']>>;
  id: Scalars['ID'];
  /** 維修類別 */
  materialRepairTypeId?: InputMaybe<Scalars['ID']>;
  /** 類別 */
  materialTypeId?: InputMaybe<Scalars['ID']>;
  /** 型號 */
  model?: InputMaybe<Scalars['String']>;
  /** 名稱 */
  name?: InputMaybe<Scalars['String']>;
  /** 簡稱 */
  name2?: InputMaybe<Scalars['String']>;
  /** 地區 */
  regionId?: InputMaybe<Scalars['ID']>;
  /** 銷售幣別 */
  saleCurrencyId?: InputMaybe<Scalars['ID']>;
  /** 銷售金額 */
  salePrice?: InputMaybe<Scalars['Float']>;
  /** 商品業績類型 */
  salesProductTypeIds?: InputMaybe<Array<Scalars['ID']>>;
  /** 銷售業績類型 */
  salesTypeIds?: InputMaybe<Array<Scalars['ID']>>;
  /** 規格／機型 */
  spec?: InputMaybe<Scalars['String']>;
  /** 狀態 */
  status?: InputMaybe<EnumMaterialStatus>;
  /** 供應商 */
  supplierId?: InputMaybe<Scalars['ID']>;
  /** 稅率 */
  taxRate?: InputMaybe<Scalars['Float']>;
};

export type Me = IUser & {
  active?: Maybe<Scalars['String']>;
  /** 驗證方式(1. LDAP, 2. password) */
  authType: Scalars['Int'];
  /** 可選取的業務團隊組織 */
  availableSalesTeamGroups: Array<SalesTeamGroup>;
  code?: Maybe<Scalars['String']>;
  company?: Maybe<Company>;
  companyId?: Maybe<Scalars['ID']>;
  createdAt: Scalars['DateTime'];
  /** 預設選取業務團隊組織 */
  defaultAvailableSalesTeamGroup?: Maybe<SalesTeamGroup>;
  departmentId?: Maybe<Scalars['ID']>;
  dept?: Maybe<Department>;
  email?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  /** 在職狀態 */
  isActive: Scalars['Boolean'];
  /** 聽力師 */
  isAudiologist: Scalars['Boolean'];
  /** 助聽器工程師 */
  isHearingAidEngineer: Scalars['Boolean'];
  isLocked: Scalars['Boolean'];
  /** 外包人員 */
  isOutsourcing: Scalars['Boolean'];
  /** 使用系統，最後選擇的門市 */
  lastSelectStoreId?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
  region?: Maybe<Region>;
  regionId: Scalars['ID'];
  roles: Array<Role>;
  salesTeamUnits?: Maybe<Array<SalesTeamUnit>>;
  salesTeams?: Maybe<Array<SalesTeam>>;
  ssoTokens: Array<Token>;
  store?: Maybe<Store>;
  storeId?: Maybe<Scalars['ID']>;
  updatedAt: Scalars['DateTime'];
  userPermission: UserPermission;
};

export type MedicalDeviceClassification = IMedicalDeviceClassification & {
  code: Scalars['String'];
  /** 敘述 */
  description?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  name: Scalars['String'];
  region?: Maybe<Region>;
  regionId: Scalars['ID'];
};

export type MedicalDeviceClassificationSearchInput = {
  code?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['ID']>;
  regionIds?: InputMaybe<Array<Scalars['ID']>>;
};

export type MedicalDeviceClassificationSearchResult = {
  count: Scalars['Int'];
  pageInfo: PageInfo;
  rows: Array<MedicalDeviceClassification>;
};

export type MenuBadge = {
  /** 眼科服務單 - 待驗收 */
  eyeServiceOrderWaitForAcceptance?: Maybe<Scalars['Int']>;
  /** 眼科服務單 - 待核准 */
  eyeServiceOrderWaitForApproval?: Maybe<Scalars['Int']>;
  /** 眼科服務單 - 待指派 */
  eyeServiceOrderWaitForAssign?: Maybe<Scalars['Int']>;
};

export type Mutation = {
  BatchUpsertCustomersPrimaryUser: Array<CustomersPrimaryUser>;
  BatchUpsertCustomersSalesTeamUnit: Array<CustomersSalesTeamUnit>;
  /** 批次修改商機負責人 */
  batchUpdateBusinessPrimaryUser: Array<Business>;
  /** 批次修改商機負責業務位置 */
  batchUpdateBusinessSalesTeamUnit: Array<Business>;
  bulkCreateCustomerAttachment: Array<CustomerAttachment>;
  bulkCreateEyeFixedAssetDocument: Array<EyeFixedAssetDocument>;
  bulkCreateEyeFixedAssetImage: Array<EyeFixedAssetImage>;
  bulkCreateEyeProductAttachment: Array<EyeProductAttachment>;
  bulkCreateRolesUser: Array<RolesUser>;
  bulkUpdateEyeProductItems: Array<EyeProductItem>;
  bulkUpdateSalesPerformanceSalesProductOverallView: SalesPerformanceSalesProductOverallViewBulkUpdateResult;
  bulkUpdateSalesPerformanceSalesProductTeamGoalView: Array<SalesProductTeamGoalItem>;
  bulkUpdateSalesPerformanceSalesProductUserForecastView: Array<SalesProductUserForecastItem>;
  bulkUpdateSalesPerformanceSalesProductUserGoalView: Array<SalesProductUserGoalItem>;
  bulkUpdateSalesPerformanceSalesTeamGoalView: Array<SalesTeamGoalItem>;
  bulkUpdateSalesPerformanceSalesUserForecastView: Array<SalesUserForecastItem>;
  bulkUpdateSalesPerformanceSalesUserGoalView: Array<SalesUserGoalItem>;
  bulkUpdateSalesTeam: Array<SalesTeam>;
  bulkUpdateSalesTeamUnit: Array<SalesTeamUnit>;
  createBusiness: Business;
  createBusinessLosingReason: BusinessLosingReason;
  createBusinessMemo: BusinessMemo;
  createBusinessOpportunity: BusinessOpportunity;
  createBusinessProduct: BusinessProduct;
  createBusinessProperty: BusinessProperty;
  createBusinessPropertyType: BusinessPropertyType;
  createBusinessStatus: BusinessStatus;
  createBusinessType: BusinessType;
  createCompetitor: Competitor;
  createContactPerson: ContactPerson;
  createContactPersonDepartment: ContactPersonDepartment;
  createCorporation: Corporation;
  createCustomer: Customer;
  createCustomerArea: CustomerArea;
  createCustomerAttachment: CustomerAttachment;
  createCustomerCertificate: CustomerCertificate;
  createCustomerCertificateAttachment: CustomerCertificateAttachment;
  createCustomerCertificateType: CustomerCertificateType;
  createCustomerGroup: CustomerGroup;
  createCustomerLevel: CustomerLevel;
  createCustomerLevelType: CustomerLevelType;
  createCustomerProperty: CustomerProperty;
  createCustomerPropertyType: CustomerPropertyType;
  createCustomerToContactPeople: Customer;
  createCustomerType: CustomerType;
  createEyeFixedAsset: EyeFixedAsset;
  createEyeFixedAssetDocument: EyeFixedAssetDocument;
  createEyeFixedAssetImage: EyeFixedAssetImage;
  createEyeFixedAssetRentalGoal: EyeFixedAssetRentalGoal;
  createEyeFixedAssetRentalObject: EyeFixedAssetRentalObject;
  createEyeFixedAssetRentalRecord: EyeFixedAssetRentalRecord;
  createEyeFixedAssetServiceProvider: EyeFixedAssetServiceProvider;
  createEyeProduct: EyeProduct;
  createEyeProductAcceptanceForm: EyeProductAcceptanceForm;
  createEyeProductAcceptanceFormDetail: EyeProductAcceptanceFormDetail;
  createEyeProductAttachment: EyeProductAttachment;
  createEyeProductItem: EyeProductItem;
  createEyePromotion: EyePromotion;
  createEyePromotionAddonProductItem: EyePromotionAddonProductItem;
  createEyeQuotationOrder: EyeQuotationOrder;
  createEyeServiceOrder: EyeServiceOrder;
  createEyeServiceOrderAcceptance: EyeServiceOrderAcceptance;
  createEyeServiceOrderAttachFile: EyeServiceOrderAttachFile;
  createEyeServiceOrderItem: EyeServiceOrderItem;
  createEyeServiceOrderQuestionnaire: EyeServiceOrderQuestionnaire;
  createEyeServiceOrderWorkDiary: EyeServiceOrderWorkDiary;
  createEyeServiceOrderWorkDiaryAttachFile: EyeServiceOrderWorkDiaryAttachFile;
  createEyeServiceOrderWorkDiaryCheckIn: EyeServiceOrderWorkDiaryCheckIn;
  createEyeServiceOrderWorkDiaryItem: EyeServiceOrderWorkDiaryItem;
  createEyeServiceOrderWorkDiaryType: EyeServiceOrderWorkDiaryType;
  createEyeWarrantyContract: EyeWarrantyContract;
  createEyeWarrantyContractItem: EyeWarrantyContractItem;
  createEyeWarrantyPeriodType: EyeWarrantyPeriodType;
  createMaterial: Material;
  createMaterialImage: MaterialImage;
  createNotification: Notification;
  createProductTeam: ProductTeam;
  createQuestionnaire: Questionnaire;
  createRentProduct: RentProduct;
  createRole: Role;
  createSalesTeam: SalesTeam;
  createSalesTeamUnit: SalesTeamUnit;
  createTaxRate: TaxRate;
  createVisit: Visit;
  createVisitAction: VisitAction;
  createVisitCheckIn: VisitCheckIn;
  createVisitGoal: VisitGoal;
  createVisitMemo: VisitMemo;
  createVisitProperty: VisitProperty;
  createVisitPropertyType: VisitPropertyType;
  createVisitTimePeriod: VisitTimePeriod;
  createVisitType: VisitType;
  createWarehouse: Warehouse;
  deleteBusiness: Scalars['Boolean'];
  deleteBusinessLosingReason: Scalars['Boolean'];
  deleteBusinessMemo: Scalars['Boolean'];
  deleteBusinessOpportunity: Scalars['Boolean'];
  deleteBusinessProduct: Scalars['Boolean'];
  deleteBusinessProperty: Scalars['Boolean'];
  deleteBusinessPropertyType: Scalars['Boolean'];
  deleteBusinessStatus: Scalars['Boolean'];
  deleteBusinessType: Scalars['Boolean'];
  deleteCompetitor: Scalars['Boolean'];
  deleteContactPerson: Scalars['Boolean'];
  deleteContactPersonDepartment: Scalars['Boolean'];
  deleteCostCenter: Scalars['Boolean'];
  deleteCustomer: Scalars['Boolean'];
  deleteCustomerArea: Scalars['Boolean'];
  deleteCustomerAttachment: Scalars['Boolean'];
  deleteCustomerCertificate: Scalars['Boolean'];
  deleteCustomerCertificateAttachment: Scalars['Boolean'];
  deleteCustomerCertificateType: Scalars['Boolean'];
  deleteCustomerGroup: Scalars['Boolean'];
  deleteCustomerLevel: Scalars['Boolean'];
  deleteCustomerLevelType: Scalars['Boolean'];
  deleteCustomerProperty: Scalars['Boolean'];
  deleteCustomerPropertyType: Scalars['Boolean'];
  deleteCustomerToContactPeople: Customer;
  deleteCustomerType: Scalars['Boolean'];
  deleteExchangeRate: Scalars['Boolean'];
  deleteEyeFixedAssetDocument: Scalars['Boolean'];
  deleteEyeFixedAssetImage: Scalars['Boolean'];
  deleteEyeProduct: Scalars['Boolean'];
  deleteEyeProductAcceptanceForm: Scalars['Boolean'];
  deleteEyeProductAcceptanceFormDetail: Scalars['Boolean'];
  deleteEyeProductAttachment: Scalars['Boolean'];
  deleteEyeProductItem: Scalars['Boolean'];
  deleteEyePromotion: Scalars['Boolean'];
  deleteEyePromotionAddonProductItem: Scalars['Boolean'];
  deleteEyeServiceOrder: Scalars['Boolean'];
  deleteEyeServiceOrderAcceptance: Scalars['Boolean'];
  deleteEyeServiceOrderAttachFile: Scalars['Boolean'];
  deleteEyeServiceOrderItem: Scalars['Boolean'];
  deleteEyeServiceOrderQuestionnaire: Scalars['Boolean'];
  deleteEyeServiceOrderWorkDiary: Scalars['Boolean'];
  deleteEyeServiceOrderWorkDiaryAttachFile: Scalars['Boolean'];
  deleteEyeServiceOrderWorkDiaryCheckIn: Scalars['Boolean'];
  deleteEyeServiceOrderWorkDiaryItem: Scalars['Boolean'];
  deleteEyeServiceOrderWorkDiaryType: Scalars['Boolean'];
  deleteEyeWarrantyContract: Scalars['Boolean'];
  deleteEyeWarrantyContractItem: Scalars['Boolean'];
  deleteMaterialImage: Scalars['Boolean'];
  deleteQuestionnaire: Scalars['Boolean'];
  deleteRentProduct: Scalars['Boolean'];
  deleteRole: Scalars['Boolean'];
  deleteRolesUser: Scalars['Boolean'];
  deleteSalesTeamUnit: Scalars['Boolean'];
  deleteTaxRate: Scalars['Boolean'];
  deleteToken: Scalars['Boolean'];
  deleteVisit: Scalars['Boolean'];
  deleteVisitAction: Scalars['Boolean'];
  deleteVisitGoal: Scalars['Boolean'];
  deleteVisitMemo: Scalars['Boolean'];
  deleteVisitProperty: Scalars['Boolean'];
  deleteVisitPropertyType: Scalars['Boolean'];
  deleteVisitTimePeriod: Scalars['Boolean'];
  deleteVisitType: Scalars['Boolean'];
  disableCorporation: Scalars['Boolean'];
  readNotifications: Array<Notification>;
  /** 申請固資租借展延 */
  requestEyeFixedAssetRentalExtension: Scalars['Boolean'];
  /** 申請報價單合約用印 */
  requestEyeQuotationOrderOfficialSeal: Scalars['Boolean'];
  updateBusiness: Business;
  updateBusinessLosingReason: BusinessLosingReason;
  updateBusinessMemo: BusinessMemo;
  updateBusinessOpportunity: BusinessOpportunity;
  updateBusinessProduct: BusinessProduct;
  updateBusinessProperty: BusinessProperty;
  updateBusinessPropertyType: BusinessPropertyType;
  updateBusinessStatus: BusinessStatus;
  updateBusinessType: BusinessType;
  updateCompetitor: Competitor;
  updateContactPerson: ContactPerson;
  updateContactPersonDepartment: ContactPersonDepartment;
  updateCorporation: Corporation;
  updateCustomer: Customer;
  updateCustomerArea: CustomerArea;
  updateCustomerAttachment: CustomerAttachment;
  updateCustomerCertificate: CustomerCertificate;
  updateCustomerCertificateAttachment: CustomerCertificateAttachment;
  updateCustomerCertificateType: CustomerCertificateType;
  updateCustomerGroup: CustomerGroup;
  updateCustomerLevel: CustomerLevel;
  updateCustomerLevelType: CustomerLevelType;
  updateCustomerProperty: CustomerProperty;
  updateCustomerPropertyType: CustomerPropertyType;
  updateCustomerType: CustomerType;
  updateEyeFixedAsset: EyeFixedAsset;
  updateEyeFixedAssetDocument: EyeFixedAssetDocument;
  updateEyeFixedAssetImage: EyeFixedAssetImage;
  updateEyeFixedAssetRentalGoal: EyeFixedAssetRentalGoal;
  updateEyeFixedAssetRentalObject: EyeFixedAssetRentalObject;
  updateEyeFixedAssetRentalRecord: EyeFixedAssetRentalRecord;
  updateEyeFixedAssetServiceProvider: EyeFixedAssetServiceProvider;
  updateEyeProduct: EyeProduct;
  updateEyeProductAcceptanceForm: EyeProductAcceptanceForm;
  updateEyeProductAcceptanceFormDetail: EyeProductAcceptanceFormDetail;
  updateEyeProductAttachment: EyeProductAttachment;
  updateEyeProductItem: EyeProductItem;
  updateEyePromotion: EyePromotion;
  updateEyePromotionAddonProductItem: EyePromotionAddonProductItem;
  updateEyeServiceOrder: EyeServiceOrder;
  updateEyeServiceOrderAcceptance: EyeServiceOrderAcceptance;
  updateEyeServiceOrderApprovalStatus: EyeServiceOrder;
  updateEyeServiceOrderAttachFile: EyeServiceOrderAttachFile;
  updateEyeServiceOrderItem: EyeServiceOrderItem;
  updateEyeServiceOrderQuestionnaire: EyeServiceOrderQuestionnaire;
  updateEyeServiceOrderWorkDiary: EyeServiceOrderWorkDiary;
  updateEyeServiceOrderWorkDiaryAttachFile: EyeServiceOrderWorkDiaryAttachFile;
  updateEyeServiceOrderWorkDiaryCheckIn: EyeServiceOrderWorkDiaryCheckIn;
  updateEyeServiceOrderWorkDiaryItem: EyeServiceOrderWorkDiaryItem;
  updateEyeServiceOrderWorkDiaryType: EyeServiceOrderWorkDiaryType;
  updateEyeWarrantyContract: EyeWarrantyContract;
  updateEyeWarrantyContractItem: EyeWarrantyContractItem;
  updateEyeWarrantyPeriodType: EyeWarrantyPeriodType;
  updateMaterial: Material;
  updateMaterialImage: MaterialImage;
  updateProductTeam: ProductTeam;
  updateQuestionnaire: Questionnaire;
  updateRentProduct: RentProduct;
  updateRole: Role;
  updateSalesTeam: SalesTeam;
  updateSalesTeamGroup: SalesTeamGroup;
  updateSalesTeamUnit: SalesTeamUnit;
  updateVisit: Visit;
  updateVisitAction: VisitAction;
  updateVisitCheckIn: VisitCheckIn;
  updateVisitGoal: VisitGoal;
  updateVisitMemo: VisitMemo;
  updateVisitProperty: VisitProperty;
  updateVisitPropertyType: VisitPropertyType;
  updateVisitTimePeriod: VisitTimePeriod;
  updateVisitType: VisitType;
  updateWarehouse: Warehouse;
  userLogin: UserLoginResult;
};

export type MutationBatchUpsertCustomersPrimaryUserArgs = {
  input: CustomersPrimaryUserBatchUpsertInput;
};

export type MutationBatchUpsertCustomersSalesTeamUnitArgs = {
  input: CustomersSalesTeamUnitBatchUpsertInput;
};

export type MutationBatchUpdateBusinessPrimaryUserArgs = {
  input: BusinessPrimaryUserBatchUpdateInput;
};

export type MutationBatchUpdateBusinessSalesTeamUnitArgs = {
  input: BusinessSalesTeamUnitBatchUpdateInput;
};

export type MutationBulkCreateCustomerAttachmentArgs = {
  input: CustomerAttachmentBulkCreateInput;
};

export type MutationBulkCreateEyeFixedAssetDocumentArgs = {
  input: EyeFixedAssetDocumentBulkCreateInput;
};

export type MutationBulkCreateEyeFixedAssetImageArgs = {
  input: EyeFixedAssetImageBulkCreateInput;
};

export type MutationBulkCreateEyeProductAttachmentArgs = {
  input: EyeProductAttachmentBulkCreateInput;
};

export type MutationBulkCreateRolesUserArgs = {
  input: RolesUserBulkCreateInput;
};

export type MutationBulkUpdateEyeProductItemsArgs = {
  input: EyeProductItemBulkUpdateInput;
};

export type MutationBulkUpdateSalesPerformanceSalesProductOverallViewArgs = {
  input: SalesPerformanceSalesProductOverallViewBulkUpdateInput;
};

export type MutationBulkUpdateSalesPerformanceSalesProductTeamGoalViewArgs = {
  input: SalesPerformanceSalesProductTeamGoalViewBulkUpdateInput;
};

export type MutationBulkUpdateSalesPerformanceSalesProductUserForecastViewArgs =
  {
    input: SalesPerformanceSalesProductUserForecastViewBulkUpdateInput;
  };

export type MutationBulkUpdateSalesPerformanceSalesProductUserGoalViewArgs = {
  input: SalesPerformanceSalesProductUserGoalViewBulkUpdateInput;
};

export type MutationBulkUpdateSalesPerformanceSalesTeamGoalViewArgs = {
  input: SalesPerformanceSalesTeamGoalViewBulkUpdateInput;
};

export type MutationBulkUpdateSalesPerformanceSalesUserForecastViewArgs = {
  input: SalesPerformanceSalesUserForecastViewBulkUpdateInput;
};

export type MutationBulkUpdateSalesPerformanceSalesUserGoalViewArgs = {
  input: SalesPerformanceSalesUserGoalViewBulkUpdateInput;
};

export type MutationBulkUpdateSalesTeamArgs = {
  input: SalesTeamBulkUpdateInput;
};

export type MutationBulkUpdateSalesTeamUnitArgs = {
  input: SalesTeamUnitBulkUpdateInput;
};

export type MutationCreateBusinessArgs = {
  input: BusinessCreateInput;
};

export type MutationCreateBusinessLosingReasonArgs = {
  input: BusinessLosingReasonCreateInput;
};

export type MutationCreateBusinessMemoArgs = {
  input: BusinessMemoCreateInput;
};

export type MutationCreateBusinessOpportunityArgs = {
  input: BusinessOpportunityCreateInput;
};

export type MutationCreateBusinessProductArgs = {
  input: BusinessProductCreateInput;
};

export type MutationCreateBusinessPropertyArgs = {
  input: BusinessPropertyCreateInput;
};

export type MutationCreateBusinessPropertyTypeArgs = {
  input: BusinessPropertyTypeCreateInput;
};

export type MutationCreateBusinessStatusArgs = {
  input: BusinessStatusCreateInput;
};

export type MutationCreateBusinessTypeArgs = {
  input: BusinessTypeCreateInput;
};

export type MutationCreateCompetitorArgs = {
  input: CompetitorCreateInput;
};

export type MutationCreateContactPersonArgs = {
  input: ContactPersonCreateInput;
};

export type MutationCreateContactPersonDepartmentArgs = {
  input: ContactPersonDepartmentCreateInput;
};

export type MutationCreateCorporationArgs = {
  input: CorporationCreateInput;
};

export type MutationCreateCustomerArgs = {
  input: CustomerCreateInput;
};

export type MutationCreateCustomerAreaArgs = {
  input: CustomerAreaCreateInput;
};

export type MutationCreateCustomerAttachmentArgs = {
  input: CustomerAttachmentCreateInput;
};

export type MutationCreateCustomerCertificateArgs = {
  input: CustomerCertificateCreateInput;
};

export type MutationCreateCustomerCertificateAttachmentArgs = {
  input: CustomerCertificateAttachmentCreateInput;
};

export type MutationCreateCustomerCertificateTypeArgs = {
  input: CustomerCertificateTypeCreateInput;
};

export type MutationCreateCustomerGroupArgs = {
  input: CustomerGroupCreateInput;
};

export type MutationCreateCustomerLevelArgs = {
  input: CustomerLevelCreateInput;
};

export type MutationCreateCustomerLevelTypeArgs = {
  input: CustomerLevelTypeCreateInput;
};

export type MutationCreateCustomerPropertyArgs = {
  input: CustomerPropertyCreateInput;
};

export type MutationCreateCustomerPropertyTypeArgs = {
  input: CustomerPropertyTypeCreateInput;
};

export type MutationCreateCustomerToContactPeopleArgs = {
  input: CreateCustomerToContactPeopleInput;
};

export type MutationCreateCustomerTypeArgs = {
  input: CustomerTypeCreateInput;
};

export type MutationCreateEyeFixedAssetArgs = {
  input: EyeFixedAssetCreateInput;
};

export type MutationCreateEyeFixedAssetDocumentArgs = {
  input: EyeFixedAssetDocumentCreateInput;
};

export type MutationCreateEyeFixedAssetImageArgs = {
  input: EyeFixedAssetImageCreateInput;
};

export type MutationCreateEyeFixedAssetRentalGoalArgs = {
  input: EyeFixedAssetRentalGoalCreateInput;
};

export type MutationCreateEyeFixedAssetRentalObjectArgs = {
  input: EyeFixedAssetRentalObjectCreateInput;
};

export type MutationCreateEyeFixedAssetRentalRecordArgs = {
  input: EyeFixedAssetRentalRecordCreateInput;
};

export type MutationCreateEyeFixedAssetServiceProviderArgs = {
  input: EyeFixedAssetServiceProviderCreateInput;
};

export type MutationCreateEyeProductArgs = {
  input: EyeProductCreateInput;
};

export type MutationCreateEyeProductAcceptanceFormArgs = {
  input: EyeProductAcceptanceFormCreateInput;
};

export type MutationCreateEyeProductAcceptanceFormDetailArgs = {
  input: EyeProductAcceptanceFormDetailCreateInput;
};

export type MutationCreateEyeProductAttachmentArgs = {
  input: EyeProductAttachmentCreateInput;
};

export type MutationCreateEyeProductItemArgs = {
  input: EyeProductItemCreateInput;
};

export type MutationCreateEyePromotionArgs = {
  input: EyePromotionCreateInput;
};

export type MutationCreateEyePromotionAddonProductItemArgs = {
  input: EyePromotionAddonProductItemCreateInput;
};

export type MutationCreateEyeQuotationOrderArgs = {
  input: EyeQuotationOrderCreateInput;
};

export type MutationCreateEyeServiceOrderArgs = {
  input: EyeServiceOrderCreateInput;
};

export type MutationCreateEyeServiceOrderAcceptanceArgs = {
  input: EyeServiceOrderAcceptanceCreateInput;
};

export type MutationCreateEyeServiceOrderAttachFileArgs = {
  input: EyeServiceOrderAttachFileCreateInput;
};

export type MutationCreateEyeServiceOrderItemArgs = {
  input: EyeServiceOrderItemCreateInput;
};

export type MutationCreateEyeServiceOrderQuestionnaireArgs = {
  input: EyeServiceOrderQuestionnaireCreateInput;
};

export type MutationCreateEyeServiceOrderWorkDiaryArgs = {
  input: EyeServiceOrderWorkDiaryCreateInput;
};

export type MutationCreateEyeServiceOrderWorkDiaryAttachFileArgs = {
  input: EyeServiceOrderWorkDiaryAttachFileCreateInput;
};

export type MutationCreateEyeServiceOrderWorkDiaryCheckInArgs = {
  input: EyeServiceOrderWorkDiaryCheckInCreateInput;
};

export type MutationCreateEyeServiceOrderWorkDiaryItemArgs = {
  input: EyeServiceOrderWorkDiaryItemCreateInput;
};

export type MutationCreateEyeServiceOrderWorkDiaryTypeArgs = {
  input: EyeServiceOrderWorkDiaryTypeCreateInput;
};

export type MutationCreateEyeWarrantyContractArgs = {
  input: EyeWarrantyContractCreateInput;
};

export type MutationCreateEyeWarrantyContractItemArgs = {
  input: EyeWarrantyContractItemCreateInput;
};

export type MutationCreateEyeWarrantyPeriodTypeArgs = {
  input: EyeWarrantyPeriodTypeCreateInput;
};

export type MutationCreateMaterialArgs = {
  input: MaterialCreateInput;
};

export type MutationCreateMaterialImageArgs = {
  input: MaterialImageCreateInput;
};

export type MutationCreateNotificationArgs = {
  input: NotificationCreateInput;
};

export type MutationCreateProductTeamArgs = {
  input: ProductTeamCreateInput;
};

export type MutationCreateQuestionnaireArgs = {
  input: QuestionnaireCreateInput;
};

export type MutationCreateRentProductArgs = {
  input: RentProductCreateInput;
};

export type MutationCreateRoleArgs = {
  input: RoleCreateInput;
};

export type MutationCreateSalesTeamArgs = {
  input: SalesTeamCreateInput;
};

export type MutationCreateSalesTeamUnitArgs = {
  input: SalesTeamUnitCreateInput;
};

export type MutationCreateTaxRateArgs = {
  input: TaxRateCreateInput;
};

export type MutationCreateVisitArgs = {
  input: VisitCreateInput;
};

export type MutationCreateVisitActionArgs = {
  input: VisitActionCreateInput;
};

export type MutationCreateVisitCheckInArgs = {
  input: VisitCheckInCreateInput;
};

export type MutationCreateVisitGoalArgs = {
  input: VisitGoalCreateInput;
};

export type MutationCreateVisitMemoArgs = {
  input: VisitMemoCreateInput;
};

export type MutationCreateVisitPropertyArgs = {
  input: VisitPropertyCreateInput;
};

export type MutationCreateVisitPropertyTypeArgs = {
  input: VisitPropertyTypeCreateInput;
};

export type MutationCreateVisitTimePeriodArgs = {
  input: VisitTimePeriodCreateInput;
};

export type MutationCreateVisitTypeArgs = {
  input: VisitTypeCreateInput;
};

export type MutationCreateWarehouseArgs = {
  input: WarehouseCreateInput;
};

export type MutationDeleteBusinessArgs = {
  id: Scalars['ID'];
};

export type MutationDeleteBusinessLosingReasonArgs = {
  id: Scalars['ID'];
};

export type MutationDeleteBusinessMemoArgs = {
  id: Scalars['ID'];
};

export type MutationDeleteBusinessOpportunityArgs = {
  id: Scalars['ID'];
};

export type MutationDeleteBusinessProductArgs = {
  id: Scalars['ID'];
};

export type MutationDeleteBusinessPropertyArgs = {
  id: Scalars['ID'];
};

export type MutationDeleteBusinessPropertyTypeArgs = {
  id: Scalars['ID'];
};

export type MutationDeleteBusinessStatusArgs = {
  id: Scalars['ID'];
};

export type MutationDeleteBusinessTypeArgs = {
  id: Scalars['ID'];
};

export type MutationDeleteCompetitorArgs = {
  id: Scalars['ID'];
};

export type MutationDeleteContactPersonArgs = {
  id: Scalars['ID'];
};

export type MutationDeleteContactPersonDepartmentArgs = {
  id: Scalars['ID'];
};

export type MutationDeleteCostCenterArgs = {
  id: Scalars['ID'];
};

export type MutationDeleteCustomerArgs = {
  id: Scalars['ID'];
};

export type MutationDeleteCustomerAreaArgs = {
  id: Scalars['ID'];
};

export type MutationDeleteCustomerAttachmentArgs = {
  id: Scalars['ID'];
};

export type MutationDeleteCustomerCertificateArgs = {
  id: Scalars['ID'];
};

export type MutationDeleteCustomerCertificateAttachmentArgs = {
  id: Scalars['ID'];
};

export type MutationDeleteCustomerCertificateTypeArgs = {
  id: Scalars['ID'];
};

export type MutationDeleteCustomerGroupArgs = {
  id: Scalars['ID'];
};

export type MutationDeleteCustomerLevelArgs = {
  id: Scalars['ID'];
};

export type MutationDeleteCustomerLevelTypeArgs = {
  id: Scalars['ID'];
};

export type MutationDeleteCustomerPropertyArgs = {
  id: Scalars['ID'];
};

export type MutationDeleteCustomerPropertyTypeArgs = {
  id: Scalars['ID'];
};

export type MutationDeleteCustomerToContactPeopleArgs = {
  input: DeleteCustomerToContactPeopleInput;
};

export type MutationDeleteCustomerTypeArgs = {
  id: Scalars['ID'];
};

export type MutationDeleteExchangeRateArgs = {
  id: Scalars['ID'];
};

export type MutationDeleteEyeFixedAssetDocumentArgs = {
  id: Scalars['ID'];
};

export type MutationDeleteEyeFixedAssetImageArgs = {
  id: Scalars['ID'];
};

export type MutationDeleteEyeProductArgs = {
  id: Scalars['ID'];
};

export type MutationDeleteEyeProductAcceptanceFormArgs = {
  id: Scalars['ID'];
};

export type MutationDeleteEyeProductAcceptanceFormDetailArgs = {
  id: Scalars['ID'];
};

export type MutationDeleteEyeProductAttachmentArgs = {
  id: Scalars['ID'];
};

export type MutationDeleteEyeProductItemArgs = {
  id: Scalars['ID'];
};

export type MutationDeleteEyePromotionArgs = {
  id: Scalars['ID'];
};

export type MutationDeleteEyePromotionAddonProductItemArgs = {
  id: Scalars['ID'];
};

export type MutationDeleteEyeServiceOrderArgs = {
  id: Scalars['ID'];
};

export type MutationDeleteEyeServiceOrderAcceptanceArgs = {
  id: Scalars['ID'];
};

export type MutationDeleteEyeServiceOrderAttachFileArgs = {
  id: Scalars['ID'];
};

export type MutationDeleteEyeServiceOrderItemArgs = {
  id: Scalars['ID'];
};

export type MutationDeleteEyeServiceOrderQuestionnaireArgs = {
  id: Scalars['ID'];
};

export type MutationDeleteEyeServiceOrderWorkDiaryArgs = {
  id: Scalars['ID'];
};

export type MutationDeleteEyeServiceOrderWorkDiaryAttachFileArgs = {
  id: Scalars['ID'];
};

export type MutationDeleteEyeServiceOrderWorkDiaryCheckInArgs = {
  id: Scalars['ID'];
};

export type MutationDeleteEyeServiceOrderWorkDiaryItemArgs = {
  id: Scalars['ID'];
};

export type MutationDeleteEyeServiceOrderWorkDiaryTypeArgs = {
  id: Scalars['ID'];
};

export type MutationDeleteEyeWarrantyContractArgs = {
  id: Scalars['ID'];
};

export type MutationDeleteEyeWarrantyContractItemArgs = {
  id: Scalars['ID'];
};

export type MutationDeleteMaterialImageArgs = {
  id: Scalars['ID'];
};

export type MutationDeleteQuestionnaireArgs = {
  id: Scalars['ID'];
};

export type MutationDeleteRentProductArgs = {
  id: Scalars['ID'];
};

export type MutationDeleteRoleArgs = {
  id: Scalars['ID'];
};

export type MutationDeleteRolesUserArgs = {
  input: RolesUserDeleteInput;
};

export type MutationDeleteSalesTeamUnitArgs = {
  id: Scalars['ID'];
};

export type MutationDeleteTaxRateArgs = {
  id: Scalars['ID'];
};

export type MutationDeleteTokenArgs = {
  id: Scalars['ID'];
};

export type MutationDeleteVisitArgs = {
  id: Scalars['ID'];
};

export type MutationDeleteVisitActionArgs = {
  id: Scalars['ID'];
};

export type MutationDeleteVisitGoalArgs = {
  id: Scalars['ID'];
};

export type MutationDeleteVisitMemoArgs = {
  id: Scalars['ID'];
};

export type MutationDeleteVisitPropertyArgs = {
  id: Scalars['ID'];
};

export type MutationDeleteVisitPropertyTypeArgs = {
  id: Scalars['ID'];
};

export type MutationDeleteVisitTimePeriodArgs = {
  id: Scalars['ID'];
};

export type MutationDeleteVisitTypeArgs = {
  id: Scalars['ID'];
};

export type MutationDisableCorporationArgs = {
  id: Scalars['ID'];
};

export type MutationReadNotificationsArgs = {
  ids: Array<Scalars['ID']>;
};

export type MutationRequestEyeFixedAssetRentalExtensionArgs = {
  input: EyeServiceOrderRequestExtensionInput;
};

export type MutationRequestEyeQuotationOrderOfficialSealArgs = {
  input: EyeQuotationOrderOfficialSealInput;
};

export type MutationUpdateBusinessArgs = {
  input: BusinessUpdateInput;
};

export type MutationUpdateBusinessLosingReasonArgs = {
  input: BusinessLosingReasonUpdateInput;
};

export type MutationUpdateBusinessMemoArgs = {
  input: BusinessMemoUpdateInput;
};

export type MutationUpdateBusinessOpportunityArgs = {
  input: BusinessOpportunityUpdateInput;
};

export type MutationUpdateBusinessProductArgs = {
  input: BusinessProductUpdateInput;
};

export type MutationUpdateBusinessPropertyArgs = {
  input: BusinessPropertyUpdateInput;
};

export type MutationUpdateBusinessPropertyTypeArgs = {
  input: BusinessPropertyTypeUpdateInput;
};

export type MutationUpdateBusinessStatusArgs = {
  input: BusinessStatusUpdateInput;
};

export type MutationUpdateBusinessTypeArgs = {
  input: BusinessTypeUpdateInput;
};

export type MutationUpdateCompetitorArgs = {
  input: CompetitorUpdateInput;
};

export type MutationUpdateContactPersonArgs = {
  input: ContactPersonUpdateInput;
};

export type MutationUpdateContactPersonDepartmentArgs = {
  input: ContactPersonDepartmentUpdateInput;
};

export type MutationUpdateCorporationArgs = {
  input: CorporationUpdateInput;
};

export type MutationUpdateCustomerArgs = {
  input: CustomerUpdateInput;
};

export type MutationUpdateCustomerAreaArgs = {
  input: CustomerAreaUpdateInput;
};

export type MutationUpdateCustomerAttachmentArgs = {
  input: CustomerAttachmentUpdateInput;
};

export type MutationUpdateCustomerCertificateArgs = {
  input: CustomerCertificateUpdateInput;
};

export type MutationUpdateCustomerCertificateAttachmentArgs = {
  input: CustomerCertificateAttachmentUpdateInput;
};

export type MutationUpdateCustomerCertificateTypeArgs = {
  input: CustomerCertificateTypeUpdateInput;
};

export type MutationUpdateCustomerGroupArgs = {
  input: CustomerGroupUpdateInput;
};

export type MutationUpdateCustomerLevelArgs = {
  input: CustomerLevelUpdateInput;
};

export type MutationUpdateCustomerLevelTypeArgs = {
  input: CustomerLevelTypeUpdateInput;
};

export type MutationUpdateCustomerPropertyArgs = {
  input: CustomerPropertyUpdateInput;
};

export type MutationUpdateCustomerPropertyTypeArgs = {
  input: CustomerPropertyTypeUpdateInput;
};

export type MutationUpdateCustomerTypeArgs = {
  input: CustomerTypeUpdateInput;
};

export type MutationUpdateEyeFixedAssetArgs = {
  input: EyeFixedAssetUpdateInput;
};

export type MutationUpdateEyeFixedAssetDocumentArgs = {
  input: EyeFixedAssetDocumentUpdateInput;
};

export type MutationUpdateEyeFixedAssetImageArgs = {
  input: EyeFixedAssetImageUpdateInput;
};

export type MutationUpdateEyeFixedAssetRentalGoalArgs = {
  input: EyeFixedAssetRentalGoalUpdateInput;
};

export type MutationUpdateEyeFixedAssetRentalObjectArgs = {
  input: EyeFixedAssetRentalObjectUpdateInput;
};

export type MutationUpdateEyeFixedAssetRentalRecordArgs = {
  input: EyeFixedAssetRentalRecordUpdateInput;
};

export type MutationUpdateEyeFixedAssetServiceProviderArgs = {
  input: EyeFixedAssetServiceProviderUpdateInput;
};

export type MutationUpdateEyeProductArgs = {
  input: EyeProductUpdateInput;
};

export type MutationUpdateEyeProductAcceptanceFormArgs = {
  input: EyeProductAcceptanceFormUpdateInput;
};

export type MutationUpdateEyeProductAcceptanceFormDetailArgs = {
  input: EyeProductAcceptanceFormDetailUpdateInput;
};

export type MutationUpdateEyeProductAttachmentArgs = {
  input: EyeProductAttachmentUpdateInput;
};

export type MutationUpdateEyeProductItemArgs = {
  input: EyeProductItemUpdateInput;
};

export type MutationUpdateEyePromotionArgs = {
  input: EyePromotionUpdateInput;
};

export type MutationUpdateEyePromotionAddonProductItemArgs = {
  input: EyePromotionAddonProductItemUpdateInput;
};

export type MutationUpdateEyeServiceOrderArgs = {
  input: EyeServiceOrderUpdateInput;
};

export type MutationUpdateEyeServiceOrderAcceptanceArgs = {
  input: EyeServiceOrderAcceptanceUpdateInput;
};

export type MutationUpdateEyeServiceOrderApprovalStatusArgs = {
  input: EyeServiceOrderApprovalStatusUpdateInput;
};

export type MutationUpdateEyeServiceOrderAttachFileArgs = {
  input: EyeServiceOrderAttachFileUpdateInput;
};

export type MutationUpdateEyeServiceOrderItemArgs = {
  input: EyeServiceOrderItemUpdateInput;
};

export type MutationUpdateEyeServiceOrderQuestionnaireArgs = {
  input: EyeServiceOrderQuestionnaireUpdateInput;
};

export type MutationUpdateEyeServiceOrderWorkDiaryArgs = {
  input: EyeServiceOrderWorkDiaryUpdateInput;
};

export type MutationUpdateEyeServiceOrderWorkDiaryAttachFileArgs = {
  input: EyeServiceOrderWorkDiaryAttachFileUpdateInput;
};

export type MutationUpdateEyeServiceOrderWorkDiaryCheckInArgs = {
  input: EyeServiceOrderWorkDiaryCheckInUpdateInput;
};

export type MutationUpdateEyeServiceOrderWorkDiaryItemArgs = {
  input: EyeServiceOrderWorkDiaryItemUpdateInput;
};

export type MutationUpdateEyeServiceOrderWorkDiaryTypeArgs = {
  input: EyeServiceOrderWorkDiaryTypeUpdateInput;
};

export type MutationUpdateEyeWarrantyContractArgs = {
  input: EyeWarrantyContractUpdateInput;
};

export type MutationUpdateEyeWarrantyContractItemArgs = {
  input: EyeWarrantyContractItemUpdateInput;
};

export type MutationUpdateEyeWarrantyPeriodTypeArgs = {
  input: EyeWarrantyPeriodTypeUpdateInput;
};

export type MutationUpdateMaterialArgs = {
  input: MaterialUpdateInput;
};

export type MutationUpdateMaterialImageArgs = {
  input: MaterialImageUpdateInput;
};

export type MutationUpdateProductTeamArgs = {
  input: ProductTeamUpdateInput;
};

export type MutationUpdateQuestionnaireArgs = {
  input: QuestionnaireUpdateInput;
};

export type MutationUpdateRentProductArgs = {
  input: RentProductUpdateInput;
};

export type MutationUpdateRoleArgs = {
  input: RoleUpdateInput;
};

export type MutationUpdateSalesTeamArgs = {
  input: SalesTeamUpdateInput;
};

export type MutationUpdateSalesTeamGroupArgs = {
  input: SalesTeamGroupUpdateInput;
};

export type MutationUpdateSalesTeamUnitArgs = {
  input: SalesTeamUnitUpdateInput;
};

export type MutationUpdateVisitArgs = {
  input: VisitUpdateInput;
};

export type MutationUpdateVisitActionArgs = {
  input: VisitActionUpdateInput;
};

export type MutationUpdateVisitCheckInArgs = {
  input: VisitCheckInUpdateInput;
};

export type MutationUpdateVisitGoalArgs = {
  input: VisitGoalUpdateInput;
};

export type MutationUpdateVisitMemoArgs = {
  input: VisitMemoUpdateInput;
};

export type MutationUpdateVisitPropertyArgs = {
  input: VisitPropertyUpdateInput;
};

export type MutationUpdateVisitPropertyTypeArgs = {
  input: VisitPropertyTypeUpdateInput;
};

export type MutationUpdateVisitTimePeriodArgs = {
  input: VisitTimePeriodUpdateInput;
};

export type MutationUpdateVisitTypeArgs = {
  input: VisitTypeUpdateInput;
};

export type MutationUpdateWarehouseArgs = {
  input: WarehouseUpdateInput;
};

export type MutationUserLoginArgs = {
  SSOToken: Scalars['String'];
};

export type NavOrder = {
  /** 訂單金額 */
  amount: Scalars['Float'];
  /** 報價單號 */
  approvalCode?: Maybe<Scalars['String']>;
  /** 開票客戶 */
  billCustomer?: Maybe<Customer>;
  /** 開票客戶 */
  billCustomerNavCode: Scalars['String'];
  /** 商機 */
  businesses: Array<Business>;
  /** 單據編號 */
  code: Scalars['String'];
  /** 清單 */
  details: Array<NavOrderDetail>;
  /** 訂單日期 */
  orderDate: Scalars['DateTime'];
  /** 送貨客戶 */
  sellCustomer?: Maybe<Customer>;
  /** 送貨客戶 */
  sellCustomerNavCode: Scalars['String'];
  /** 出貨以結金額 */
  shipInvoicedAmount: Scalars['Float'];
  /** 負責業務 */
  user?: Maybe<User>;
  /** 負責業務 */
  userCode: Scalars['String'];
};

export type NavOrderDetail = {
  /** 品項金額 */
  amount: Scalars['Float'];
  /** 料號 */
  materialCode: Scalars['String'];
  /** 料號名稱 */
  materialName: Scalars['String'];
  /** 訂單編號 */
  orderCode: Scalars['String'];
  /** 數量 */
  qty: Scalars['Int'];
};

export type NavOrderDetailSearchInput = {
  /** 訂單編號 */
  orderCode?: InputMaybe<Scalars['String']>;
};

export type NavOrderDetailSearchResult = {
  count: Scalars['Int'];
  pageInfo: PageInfo;
  rows: Array<NavOrderDetail>;
};

export type NavOrderSearchInput = {
  /** 報價單號 */
  approvalCode?: InputMaybe<Scalars['String']>;
  /** 開票客戶編號 */
  billCustomerCode?: InputMaybe<Scalars['String']>;
  /** 開票客戶ID */
  billCustomerId?: InputMaybe<Scalars['ID']>;
  /** 開票客戶名稱 */
  billCustomerName?: InputMaybe<Scalars['String']>;
  /** 開票客戶編號(NAV) */
  billCustomerNavCode?: InputMaybe<Scalars['String']>;
  /** 商機編號 */
  businessCode?: InputMaybe<Scalars['String']>;
  /** 單據編號 */
  code?: InputMaybe<Scalars['String']>;
  /** 開票客戶 or 送貨客戶 */
  customerId?: InputMaybe<Scalars['ID']>;
  /** 訂單日期(起) */
  orderDate1?: InputMaybe<Scalars['Date']>;
  /** 訂單日期(迄) */
  orderDate2?: InputMaybe<Scalars['Date']>;
  /** 送貨客戶編號 */
  sellCustomerCode?: InputMaybe<Scalars['String']>;
  /** 送貨客戶ID */
  sellCustomerId?: InputMaybe<Scalars['ID']>;
  /** 送貨客戶名稱 */
  sellCustomerName?: InputMaybe<Scalars['String']>;
  /** 送貨客戶編號(NAV) */
  sellCustomerNavCode?: InputMaybe<Scalars['String']>;
  /** 負責業務 */
  userId?: InputMaybe<Scalars['ID']>;
};

export type NavOrderSearchResult = {
  count: Scalars['Int'];
  pageInfo: PageInfo;
  rows: Array<NavOrder>;
};

export type NavSalesInvoice = {
  /** 訂單金額 */
  amount: Scalars['Float'];
  /** 報價單號 */
  approvalCode?: Maybe<Scalars['String']>;
  /** 開票客戶 */
  billCustomer?: Maybe<Customer>;
  /** 開票客戶 */
  billCustomerNavCode: Scalars['String'];
  /** 單據編號 */
  code: Scalars['String'];
  /** 清單 */
  details: Array<NavSalesInvoiceDetail>;
  /** 訂單編號 */
  orderCode: Scalars['String'];
  /** 銷售日期 */
  postingDate: Scalars['DateTime'];
  /** 送貨客戶 */
  sellCustomer?: Maybe<Customer>;
  /** 送貨客戶 */
  sellCustomerNavCode: Scalars['String'];
  /** 類別 */
  type: EnumNavSalesInvoiceType;
  /** 負責業務 */
  user?: Maybe<User>;
  /** 負責業務 */
  userCode: Scalars['String'];
};

export type NavSalesInvoiceDetail = {
  /** 品項金額 */
  amount?: Maybe<Scalars['Float']>;
  /** 料號 */
  materialCode?: Maybe<Scalars['String']>;
  /** 料號名稱 */
  materialName?: Maybe<Scalars['String']>;
  /** 數量 */
  qty?: Maybe<Scalars['Int']>;
  /** 銷售單編號 */
  salesInvoiceCode: Scalars['String'];
};

export type NavSalesInvoiceDetailSearchInput = {
  /** 銷售單編號 */
  salesInvoiceCode?: InputMaybe<Scalars['String']>;
};

export type NavSalesInvoiceDetailSearchResult = {
  count: Scalars['Int'];
  pageInfo: PageInfo;
  rows: Array<NavSalesInvoiceDetail>;
};

export type NavSalesInvoiceSearchInput = {
  /** 報價單號 */
  approvalCode?: InputMaybe<Scalars['String']>;
  /** 開票客戶編號 */
  billCustomerCode?: InputMaybe<Scalars['String']>;
  /** 開票客戶ID */
  billCustomerId?: InputMaybe<Scalars['ID']>;
  /** 開票客戶名稱 */
  billCustomerName?: InputMaybe<Scalars['String']>;
  /** 開票客戶編號(NAV) */
  billCustomerNavCode?: InputMaybe<Scalars['String']>;
  /** 單據編號 */
  code?: InputMaybe<Scalars['String']>;
  /** 開票客戶 or 送貨客戶 */
  customerId?: InputMaybe<Scalars['ID']>;
  /** 訂單編號 */
  orderCode?: InputMaybe<Scalars['String']>;
  /** 銷售日期(起) */
  postingDate1?: InputMaybe<Scalars['Date']>;
  /** 銷售日期(迄) */
  postingDate2?: InputMaybe<Scalars['Date']>;
  /** 送貨客戶編號 */
  sellCustomerCode?: InputMaybe<Scalars['String']>;
  /** 送貨客戶ID */
  sellCustomerId?: InputMaybe<Scalars['ID']>;
  /** 送貨客戶名稱 */
  sellCustomerName?: InputMaybe<Scalars['String']>;
  /** 送貨客戶編號(NAV) */
  sellCustomerNavCode?: InputMaybe<Scalars['String']>;
  /** 類別 */
  type?: InputMaybe<EnumNavSalesInvoiceType>;
  /** 負責業務 */
  userId?: InputMaybe<Scalars['ID']>;
};

export type NavSalesInvoiceSearchResult = {
  count: Scalars['Int'];
  pageInfo: PageInfo;
  rows: Array<NavSalesInvoice>;
};

export type Notification = {
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  isRead: Scalars['Boolean'];
  message?: Maybe<Scalars['String']>;
  type?: Maybe<EnumNotificationType>;
  updatedAt: Scalars['DateTime'];
  userId?: Maybe<Scalars['ID']>;
  values?: Maybe<NotificationAction>;
};

export type NotificationAction =
  | NotificationActionComponent
  | NotificationActionInnerPath
  | NotificationActionLink;

/** 訊息行為 - 內部Component */
export type NotificationActionComponent = {
  key?: Maybe<EnumNotificationActionComponent>;
  payload?: Maybe<Scalars['JSON']>;
};

/** 訊息行為 - 內部連結(不包含HostNmae) */
export type NotificationActionInnerPath = {
  path?: Maybe<Scalars['String']>;
};

/** 訊息行為 - 連外網站 */
export type NotificationActionLink = {
  url?: Maybe<Scalars['String']>;
};

export type NotificationCreateInput = {
  message: Scalars['String'];
  type: EnumNotificationType;
  userId: Scalars['ID'];
  values?: InputMaybe<Scalars['JSON']>;
};

export type NotificationSearchInput = {
  id?: InputMaybe<Scalars['ID']>;
  isRead?: InputMaybe<Scalars['Boolean']>;
  regionIds?: InputMaybe<Array<Scalars['ID']>>;
  type?: InputMaybe<EnumNotificationType>;
  userId?: InputMaybe<Scalars['ID']>;
};

export type NotificationSearchResult = {
  count: Scalars['Int'];
  pageInfo: PageInfo;
  rows: Array<Notification>;
};

export type PageInfo = {
  hasNextPage: Scalars['Boolean'];
  hasPreviousPage: Scalars['Boolean'];
};

export type PaymentMethod = IPaymentMethod & {
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  name: Scalars['String'];
  regionId: Scalars['ID'];
  updatedAt: Scalars['DateTime'];
};

export type PaymentMethodSearchInput = {
  id?: InputMaybe<Scalars['ID']>;
  regionIds?: InputMaybe<Array<Scalars['ID']>>;
};

export type PaymentMethodSearchResult = {
  count: Scalars['Int'];
  pageInfo: PageInfo;
  rows: Array<PaymentMethod>;
};

export type Permission = IPermission & {
  applicationId: Scalars['ID'];
  /** 編號 */
  code: Scalars['String'];
  /** 敘述 */
  description?: Maybe<Scalars['String']>;
  group?: Maybe<PermissionGroup>;
  groupId: Scalars['ID'];
  id: Scalars['ID'];
  /** 名稱 */
  name: Scalars['String'];
};

export type PermissionApplication = IPermissionApplication & {
  /** 編號 */
  code: Scalars['String'];
  /** 敘述 */
  description?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  /** 名稱 */
  name: Scalars['String'];
  permissions: Array<Permission>;
};

export type PermissionApplicationSearchInput = {
  code?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['ID']>;
};

export type PermissionApplicationSearchResult = {
  count: Scalars['Int'];
  pageInfo: PageInfo;
  rows: Array<PermissionApplication>;
};

export type PermissionGroup = IPermissionGroup & {
  /** 編號 */
  code: Scalars['String'];
  /** 敘述 */
  description?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  /** 名稱 */
  name: Scalars['String'];
  permissions: Array<Permission>;
};

export type PermissionGroupSearchInput = {
  code?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['ID']>;
  name?: InputMaybe<Scalars['String']>;
};

export type PermissionGroupSearchResult = {
  count: Scalars['Int'];
  pageInfo: PageInfo;
  rows: Array<PermissionGroup>;
};

export type PermissionSearchInput = {
  /** 編號 */
  code?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['ID']>;
  /** 名稱 */
  name?: InputMaybe<Scalars['String']>;
};

export type PermissionSearchResult = {
  count: Scalars['Int'];
  pageInfo: PageInfo;
  rows: Array<Permission>;
};

export type ProductTeam = IProductTeam & {
  businessProducts: Array<BusinessProduct>;
  /** 編號 */
  code?: Maybe<Scalars['String']>;
  /** 資料新增日期 */
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  /** 名稱 */
  name: Scalars['String'];
  /** 資料修改日期 */
  updatedAt: Scalars['DateTime'];
  users: Array<User>;
  /** 檢視順序 */
  viewOrder?: Maybe<Scalars['Int']>;
};

export type ProductTeamCreateInput = {
  /** 商機商品 */
  businessProductIds?: InputMaybe<Array<Scalars['ID']>>;
  /** 名稱 */
  name: Scalars['String'];
  /** PM */
  userIds?: InputMaybe<Array<Scalars['ID']>>;
  /** 檢視順序 */
  viewOrder?: InputMaybe<Scalars['Int']>;
};

export type ProductTeamSearchInput = {
  /** 商機商品 */
  businessProductIds?: InputMaybe<Array<Scalars['ID']>>;
  /** 編號 */
  code?: InputMaybe<Scalars['String']>;
  ids?: InputMaybe<Array<Scalars['ID']>>;
  /** PM */
  userIds?: InputMaybe<Array<Scalars['ID']>>;
};

export type ProductTeamSearchResult = {
  count: Scalars['Int'];
  pageInfo: PageInfo;
  rows: Array<ProductTeam>;
};

export type ProductTeamUpdateInput = {
  /** 商機商品 */
  businessProductIds?: InputMaybe<Array<Scalars['ID']>>;
  id: Scalars['ID'];
  /** 名稱 */
  name?: InputMaybe<Scalars['String']>;
  /** PM */
  userIds?: InputMaybe<Array<Scalars['ID']>>;
  /** 檢視順序 */
  viewOrder?: InputMaybe<Scalars['Int']>;
};

export type Province = IProvince & {
  cities: Array<City>;
  code?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  region?: Maybe<Region>;
  regionId: Scalars['ID'];
  updatedAt: Scalars['DateTime'];
  viewOrder: Scalars['Int'];
};

export type ProvinceCreateInput = {
  /** 這是TODO */
  todo: Scalars['String'];
};

export type ProvinceSearchInput = {
  code?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['ID']>;
  name?: InputMaybe<Scalars['String']>;
  regionIds?: InputMaybe<Array<Scalars['ID']>>;
};

export type ProvinceSearchResult = {
  count: Scalars['Int'];
  pageInfo: PageInfo;
  rows: Array<Province>;
};

export type ProvinceUpdateInput = {
  id: Scalars['ID'];
};

export type Query = {
  EcDashboardAnalysis: EcDashboardAnalysis;
  EcDashboardAnalysisDetail: EcDashboardAnalysisSalesDetail;
  EcInventoryDetails: Array<EcInventoryDetail>;
  EcInventoryModels: Array<EcInventoryModel>;
  EcInventorySpecs: Array<EcInventorySpec>;
  EcInventoryStat: EcInventoryStat;
  EcInventoryWarehouses: Array<EcInventoryWarehouse>;
  appVersion: AppVersion;
  businessAnalysis: BusinessAnalysis;
  /** 欲出貨金額總數 */
  businessBudgetAmountSum: Scalars['Float'];
  businessLosingReasons: BusinessLosingReasonSearchResult;
  businessMemos: BusinessMemoSearchResult;
  businessOpportunities: BusinessOpportunitySearchResult;
  businessProducts: BusinessProductSearchResult;
  businessProperties: BusinessPropertySearchResult;
  businessPropertyTypes: BusinessPropertyTypeSearchResult;
  businessSalesMethods: BusinessSalesMethodSearchResult;
  businessStatuses: BusinessStatusSearchResult;
  businessTypes: BusinessTypeSearchResult;
  businesses: BusinessSearchResult;
  businessesUsers: BusinessesUserSearchResult;
  cities: CitySearchResult;
  companies: CompanySearchResult;
  competitors: CompetitorSearchResult;
  contactPeople: ContactPersonSearchResult;
  contactPersonDepartments: ContactPersonDepartmentSearchResult;
  corporations: CorporationSearchResult;
  costCenters: CostCenterSearchResult;
  countingUnits: CountingUnitSearchResult;
  creditPeriods: CreditPeriodSearchResult;
  currencies: CurrencySearchResult;
  customerAreas: CustomerAreaSearchResult;
  customerAttachments: CustomerAttachmentSearchResult;
  customerCategories: CustomerCategorySearchResult;
  customerCertificateAttachments: CustomerCertificateAttachmentSearchResult;
  customerCertificateTypes: CustomerCertificateTypeSearchResult;
  customerCertificates: CustomerCertificateSearchResult;
  customerGroups: CustomerGroupSearchResult;
  customerLevelTypes: CustomerLevelTypeSearchResult;
  customerLevels: CustomerLevelSearchResult;
  customerProperties: CustomerPropertySearchResult;
  customerPropertyTypes: CustomerPropertyTypeSearchResult;
  customerTypes: CustomerTypeSearchResult;
  customers: CustomerSearchResult;
  demo: Demo;
  depts: DeptSearchResult;
  dispatcherUsers: DispatcherUserSearchResult;
  districts: DistrictSearchResult;
  exchangeRates: ExchangeRateSearchResult;
  eyeFixedAssetDocuments: EyeFixedAssetDocumentSearchResult;
  eyeFixedAssetImages: EyeFixedAssetImageSearchResult;
  eyeFixedAssetRentalGoals: EyeFixedAssetRentalGoalSearchResult;
  eyeFixedAssetRentalObjects: EyeFixedAssetRentalObjectSearchResult;
  eyeFixedAssetRentalRecords: EyeFixedAssetRentalRecordSearchResult;
  eyeFixedAssetServiceProviders: EyeFixedAssetServiceProviderSearchResult;
  eyeFixedAssetTypes: EyeFixedAssetTypeSearchResult;
  eyeFixedAssets: EyeFixedAssetSearchResult;
  eyeProductAcceptanceFormDetails: EyeProductAcceptanceFormDetailSearchResult;
  eyeProductAcceptanceForms: EyeProductAcceptanceFormSearchResult;
  eyeProductAttachments: EyeProductAttachmentSearchResult;
  eyeProductItemTypes: EyeProductItemTypeSearchResult;
  eyeProductItems: EyeProductItemSearchResult;
  eyeProductTypes: EyeProductTypeSearchResult;
  eyeProducts: EyeProductSearchResult;
  eyePromotionAddonProductItems: EyePromotionAddonProductItemSearchResult;
  eyePromotions: EyePromotionSearchResult;
  eyeQuotationOrderTypes: EyeQuotationOrderTypeSearchResult;
  eyeQuotationOrders: EyeQuotationOrderSearchResult;
  eyeServiceOrderAcceptances: EyeServiceOrderAcceptanceSearchResult;
  eyeServiceOrderAttachFiles: EyeServiceOrderAttachFileSearchResult;
  eyeServiceOrderItems: EyeServiceOrderItemSearchResult;
  /** 服務單-待指派 */
  eyeServiceOrderPendingAssignment: EyeServiceOrderSearchResult;
  eyeServiceOrderQuestionnaires: EyeServiceOrderQuestionnaireSearchResult;
  eyeServiceOrderTypes: EyeServiceOrderTypeSearchResult;
  eyeServiceOrderWorkDiaries: EyeServiceOrderWorkDiarySearchResult;
  eyeServiceOrderWorkDiaryAttachFiles: EyeServiceOrderWorkDiaryAttachFileSearchResult;
  eyeServiceOrderWorkDiaryCheckIns: EyeServiceOrderWorkDiaryCheckInSearchResult;
  eyeServiceOrderWorkDiaryItems: EyeServiceOrderWorkDiaryItemSearchResult;
  eyeServiceOrderWorkDiaryTypes: EyeServiceOrderWorkDiaryTypeSearchResult;
  eyeServiceOrders: EyeServiceOrderSearchResult;
  eyeSoldProducts: EyeSoldProductSearchResult;
  eyeWarrantyContractItems: EyeWarrantyContractItemSearchResult;
  eyeWarrantyContracts: EyeWarrantyContractSearchResult;
  eyeWarrantyPeriodTypes: EyeWarrantyPeriodTypeSearchResult;
  inventorySyncSources: InventorySyncSourceSearchResult;
  materialImages: MaterialImageSearchResult;
  materialRepairTypes: MaterialRepairTypeSearchResult;
  materialTypes: MaterialTypeSearchResult;
  materials: MaterialSearchResult;
  me: Me;
  medicalDeviceClassifications: MedicalDeviceClassificationSearchResult;
  menuBadge: MenuBadge;
  navOrders: NavOrderSearchResult;
  navSalesInvoices: NavSalesInvoiceSearchResult;
  notifications: NotificationSearchResult;
  paymentMethods: PaymentMethodSearchResult;
  permissionApplications: PermissionApplicationSearchResult;
  permissionGroups: PermissionGroupSearchResult;
  permissions: PermissionSearchResult;
  productTeams: ProductTeamSearchResult;
  provinces: ProvinceSearchResult;
  questionnaires: QuestionnaireSearchResult;
  quickSearchEyeProduct: QuickSearchEyeProductSearchResult;
  regions: RegionSearchResult;
  rentProducts: RentProductSearchResult;
  roles: RoleSearchResult;
  rolesUsers: RolesUserSearchResult;
  salesPerformanceSalesProductOverallView: SalesPerformanceSalesProductOverallView;
  salesPerformanceSalesProductTeamGoalView: SalesPerformanceSalesProductTeamGoalView;
  salesPerformanceSalesProductTypeGroups: SalesPerformanceSalesProductTypeGroupSearchResult;
  salesPerformanceSalesProductTypes: SalesPerformanceSalesProductTypeSearchResult;
  salesPerformanceSalesProductUserForecastView: SalesPerformanceSalesProductUserForecastView;
  salesPerformanceSalesProductUserGoalView: SalesPerformanceSalesProductUserGoalView;
  salesPerformanceSalesTeamGoalView: SalesPerformanceSalesTeamGoalView;
  salesPerformanceSalesTypes: SalesPerformanceSalesTypeSearchResult;
  salesPerformanceSalesUserForecastView: SalesPerformanceSalesUserForecastView;
  salesPerformanceSalesUserGoalView: SalesPerformanceSalesUserGoalView;
  salesProductTypeSalesAnalysis: SalesProductTypeSalesAnalysis;
  salesTeamGroups: SalesTeamGroupSearchResult;
  salesTeamUnits: SalesTeamUnitSearchResult;
  salesTeams: SalesTeamSearchResult;
  shippingMethods: ShippingMethodSearchResult;
  stores: StoreSearchResult;
  supplierProperties: SupplierPropertySearchResult;
  suppliers: SupplierSearchResult;
  taxRates: TaxRateSearchResult;
  users: UserSearchResult;
  visitActions: VisitActionSearchResult;
  visitCheckIns: VisitCheckInSearchResult;
  visitGoals: VisitGoalSearchResult;
  visitMemos: VisitMemoSearchResult;
  visitProperties: VisitPropertySearchResult;
  visitPropertyTypes: VisitPropertyTypeSearchResult;
  visitTimePeriods: VisitTimePeriodSearchResult;
  visitTypes: VisitTypeSearchResult;
  visits: VisitSearchResult;
  warehouses: WarehouseSearchResult;
  zone2s: Zone2SearchResult;
  zones: ZoneSearchResult;
};

export type QueryEcDashboardAnalysisDetailArgs = {
  filters: EcDashboardAnalysisSalesDetailFilterInput;
};

export type QueryEcInventoryDetailsArgs = {
  filter: EcInventoryDetailFilterInput;
};

export type QueryEcInventoryStatArgs = {
  filter: EcInventoryStatFilterInput;
};

export type QueryAppVersionArgs = {
  code: Scalars['String'];
};

export type QueryBusinessBudgetAmountSumArgs = {
  filters: BusinessSearchInput;
};

export type QueryBusinessLosingReasonsArgs = {
  filters: BusinessLosingReasonSearchInput;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
};

export type QueryBusinessMemosArgs = {
  filters: BusinessMemoSearchInput;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
};

export type QueryBusinessOpportunitiesArgs = {
  filters: BusinessOpportunitySearchInput;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
};

export type QueryBusinessProductsArgs = {
  filters: BusinessProductSearchInput;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
};

export type QueryBusinessPropertiesArgs = {
  filters: BusinessPropertySearchInput;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
};

export type QueryBusinessPropertyTypesArgs = {
  filters: BusinessPropertyTypeSearchInput;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
};

export type QueryBusinessSalesMethodsArgs = {
  filters: BusinessSalesMethodSearchInput;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
};

export type QueryBusinessStatusesArgs = {
  filters: BusinessStatusSearchInput;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
};

export type QueryBusinessTypesArgs = {
  filters: BusinessTypeSearchInput;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
};

export type QueryBusinessesArgs = {
  filters: BusinessSearchInput;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  sorts?: InputMaybe<Array<BusinessSortInput>>;
};

export type QueryBusinessesUsersArgs = {
  filters: BusinessesUserSearchInput;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
};

export type QueryCitiesArgs = {
  filters: CitySearchInput;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
};

export type QueryCompaniesArgs = {
  filters: CompanySearchInput;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
};

export type QueryCompetitorsArgs = {
  filters: CompetitorSearchInput;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
};

export type QueryContactPeopleArgs = {
  filters: ContactPersonSearchInput;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
};

export type QueryContactPersonDepartmentsArgs = {
  filters: ContactPersonDepartmentSearchInput;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
};

export type QueryCorporationsArgs = {
  filters: CorporationSearchInput;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
};

export type QueryCostCentersArgs = {
  filters: CostCenterSearchInput;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
};

export type QueryCountingUnitsArgs = {
  filters: CountingUnitSearchInput;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
};

export type QueryCreditPeriodsArgs = {
  filters: CreditPeriodSearchInput;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
};

export type QueryCurrenciesArgs = {
  filters: CurrencySearchInput;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
};

export type QueryCustomerAreasArgs = {
  filters: CustomerAreaSearchInput;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
};

export type QueryCustomerAttachmentsArgs = {
  filters: CustomerAttachmentSearchInput;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
};

export type QueryCustomerCategoriesArgs = {
  filters: CustomerCategorySearchInput;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
};

export type QueryCustomerCertificateAttachmentsArgs = {
  filters: CustomerCertificateAttachmentSearchInput;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
};

export type QueryCustomerCertificateTypesArgs = {
  filters: CustomerCertificateTypeSearchInput;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
};

export type QueryCustomerCertificatesArgs = {
  filters: CustomerCertificateSearchInput;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
};

export type QueryCustomerGroupsArgs = {
  filters: CustomerGroupSearchInput;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
};

export type QueryCustomerLevelTypesArgs = {
  filters: CustomerLevelTypeSearchInput;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
};

export type QueryCustomerLevelsArgs = {
  filters: CustomerLevelSearchInput;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
};

export type QueryCustomerPropertiesArgs = {
  filters: CustomerPropertySearchInput;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
};

export type QueryCustomerPropertyTypesArgs = {
  filters: CustomerPropertyTypeSearchInput;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
};

export type QueryCustomerTypesArgs = {
  filters: CustomerTypeSearchInput;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
};

export type QueryCustomersArgs = {
  filters: CustomerSearchInput;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
};

export type QueryDeptsArgs = {
  filters: DeptSearchInput;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
};

export type QueryDispatcherUsersArgs = {
  filters: DispatcherUserSearchInput;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
};

export type QueryDistrictsArgs = {
  filters: DistrictSearchInput;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
};

export type QueryExchangeRatesArgs = {
  filters: ExchangeRateSearchInput;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
};

export type QueryEyeFixedAssetDocumentsArgs = {
  filters: EyeFixedAssetDocumentSearchInput;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
};

export type QueryEyeFixedAssetImagesArgs = {
  filters: EyeFixedAssetImageSearchInput;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
};

export type QueryEyeFixedAssetRentalGoalsArgs = {
  filters: EyeFixedAssetRentalGoalSearchInput;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
};

export type QueryEyeFixedAssetRentalObjectsArgs = {
  filters: EyeFixedAssetRentalObjectSearchInput;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
};

export type QueryEyeFixedAssetRentalRecordsArgs = {
  filters: EyeFixedAssetRentalRecordSearchInput;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
};

export type QueryEyeFixedAssetServiceProvidersArgs = {
  filters: EyeFixedAssetServiceProviderSearchInput;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
};

export type QueryEyeFixedAssetTypesArgs = {
  filters: EyeFixedAssetTypeSearchInput;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
};

export type QueryEyeFixedAssetsArgs = {
  filters: EyeFixedAssetSearchInput;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
};

export type QueryEyeProductAcceptanceFormDetailsArgs = {
  filters: EyeProductAcceptanceFormDetailSearchInput;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
};

export type QueryEyeProductAcceptanceFormsArgs = {
  filters: EyeProductAcceptanceFormSearchInput;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
};

export type QueryEyeProductAttachmentsArgs = {
  filters: EyeProductAttachmentSearchInput;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
};

export type QueryEyeProductItemTypesArgs = {
  filters: EyeProductItemTypeSearchInput;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
};

export type QueryEyeProductItemsArgs = {
  filters: EyeProductItemSearchInput;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
};

export type QueryEyeProductTypesArgs = {
  filters: EyeProductTypeSearchInput;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
};

export type QueryEyeProductsArgs = {
  filters: EyeProductSearchInput;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
};

export type QueryEyePromotionAddonProductItemsArgs = {
  filters: EyePromotionAddonProductItemSearchInput;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
};

export type QueryEyePromotionsArgs = {
  filters: EyePromotionSearchInput;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
};

export type QueryEyeQuotationOrderTypesArgs = {
  filters: EyeQuotationOrderTypeSearchInput;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
};

export type QueryEyeQuotationOrdersArgs = {
  filters: EyeQuotationOrderSearchInput;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
};

export type QueryEyeServiceOrderAcceptancesArgs = {
  filters: EyeServiceOrderAcceptanceSearchInput;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
};

export type QueryEyeServiceOrderAttachFilesArgs = {
  filters: EyeServiceOrderAttachFileSearchInput;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
};

export type QueryEyeServiceOrderItemsArgs = {
  filters: EyeServiceOrderItemSearchInput;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
};

export type QueryEyeServiceOrderPendingAssignmentArgs = {
  filters: EyeServiceOrderSearchInput;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
};

export type QueryEyeServiceOrderQuestionnairesArgs = {
  filters: EyeServiceOrderQuestionnaireSearchInput;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
};

export type QueryEyeServiceOrderTypesArgs = {
  filters: EyeServiceOrderTypeSearchInput;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
};

export type QueryEyeServiceOrderWorkDiariesArgs = {
  filters: EyeServiceOrderWorkDiarySearchInput;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
};

export type QueryEyeServiceOrderWorkDiaryAttachFilesArgs = {
  filters: EyeServiceOrderWorkDiaryAttachFileSearchInput;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
};

export type QueryEyeServiceOrderWorkDiaryCheckInsArgs = {
  filters: EyeServiceOrderWorkDiaryCheckInSearchInput;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
};

export type QueryEyeServiceOrderWorkDiaryItemsArgs = {
  filters: EyeServiceOrderWorkDiaryItemSearchInput;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
};

export type QueryEyeServiceOrderWorkDiaryTypesArgs = {
  filters: EyeServiceOrderWorkDiaryTypeSearchInput;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
};

export type QueryEyeServiceOrdersArgs = {
  filters: EyeServiceOrderSearchInput;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
};

export type QueryEyeSoldProductsArgs = {
  filters: EyeSoldProductSearchInput;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
};

export type QueryEyeWarrantyContractItemsArgs = {
  filters: EyeWarrantyContractItemSearchInput;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
};

export type QueryEyeWarrantyContractsArgs = {
  filters: EyeWarrantyContractSearchInput;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
};

export type QueryEyeWarrantyPeriodTypesArgs = {
  filters: EyeWarrantyPeriodTypeSearchInput;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
};

export type QueryInventorySyncSourcesArgs = {
  filters: InventorySyncSourceSearchInput;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
};

export type QueryMaterialImagesArgs = {
  filters: MaterialImageSearchInput;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
};

export type QueryMaterialRepairTypesArgs = {
  filters: MaterialRepairTypeSearchInput;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
};

export type QueryMaterialTypesArgs = {
  filters: MaterialTypeSearchInput;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
};

export type QueryMaterialsArgs = {
  filters: MaterialSearchInput;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
};

export type QueryMedicalDeviceClassificationsArgs = {
  filters: MedicalDeviceClassificationSearchInput;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
};

export type QueryNavOrdersArgs = {
  filters: NavOrderSearchInput;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
};

export type QueryNavSalesInvoicesArgs = {
  filters: NavSalesInvoiceSearchInput;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
};

export type QueryNotificationsArgs = {
  filters: NotificationSearchInput;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
};

export type QueryPaymentMethodsArgs = {
  filters: PaymentMethodSearchInput;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
};

export type QueryPermissionApplicationsArgs = {
  filters: PermissionApplicationSearchInput;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
};

export type QueryPermissionGroupsArgs = {
  filters: PermissionGroupSearchInput;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
};

export type QueryPermissionsArgs = {
  filters: PermissionSearchInput;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
};

export type QueryProductTeamsArgs = {
  filters: ProductTeamSearchInput;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
};

export type QueryProvincesArgs = {
  filters: ProvinceSearchInput;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
};

export type QueryQuestionnairesArgs = {
  filters: QuestionnaireSearchInput;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
};

export type QueryQuickSearchEyeProductArgs = {
  filters: QuickSearchEyeProductSearchInput;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
};

export type QueryRegionsArgs = {
  filters: RegionSearchInput;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
};

export type QueryRentProductsArgs = {
  filters: RentProductSearchInput;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
};

export type QueryRolesArgs = {
  filters: RoleSearchInput;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
};

export type QueryRolesUsersArgs = {
  filters: RolesUserSearchInput;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
};

export type QuerySalesPerformanceSalesProductOverallViewArgs = {
  salesTeamGroupId?: InputMaybe<Scalars['ID']>;
  year: Scalars['Int'];
};

export type QuerySalesPerformanceSalesProductTeamGoalViewArgs = {
  month1?: InputMaybe<Scalars['Int']>;
  month2?: InputMaybe<Scalars['Int']>;
  salesTeamGroupId?: InputMaybe<Scalars['ID']>;
  salesTeamIds?: InputMaybe<Array<Scalars['ID']>>;
  year: Scalars['Int'];
};

export type QuerySalesPerformanceSalesProductTypeGroupsArgs = {
  filters: SalesPerformanceSalesProductTypeGroupSearchInput;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
};

export type QuerySalesPerformanceSalesProductTypesArgs = {
  filters: SalesPerformanceSalesProductTypeSearchInput;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
};

export type QuerySalesPerformanceSalesProductUserForecastViewArgs = {
  month1?: InputMaybe<Scalars['Int']>;
  month2?: InputMaybe<Scalars['Int']>;
  salesTeamGroupId?: InputMaybe<Scalars['ID']>;
  userIds?: InputMaybe<Array<Scalars['ID']>>;
  year: Scalars['Int'];
};

export type QuerySalesPerformanceSalesProductUserGoalViewArgs = {
  month1?: InputMaybe<Scalars['Int']>;
  month2?: InputMaybe<Scalars['Int']>;
  salesTeamGroupId?: InputMaybe<Scalars['ID']>;
  userIds?: InputMaybe<Array<Scalars['ID']>>;
  year: Scalars['Int'];
};

export type QuerySalesPerformanceSalesTeamGoalViewArgs = {
  month1?: InputMaybe<Scalars['Int']>;
  month2?: InputMaybe<Scalars['Int']>;
  salesTeamGroupId?: InputMaybe<Scalars['ID']>;
  salesTeamIds?: InputMaybe<Array<Scalars['ID']>>;
  year: Scalars['Int'];
};

export type QuerySalesPerformanceSalesTypesArgs = {
  filters: SalesPerformanceSalesTypeSearchInput;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
};

export type QuerySalesPerformanceSalesUserForecastViewArgs = {
  month1?: InputMaybe<Scalars['Int']>;
  month2?: InputMaybe<Scalars['Int']>;
  salesTeamGroupId?: InputMaybe<Scalars['ID']>;
  userIds?: InputMaybe<Array<Scalars['ID']>>;
  year: Scalars['Int'];
};

export type QuerySalesPerformanceSalesUserGoalViewArgs = {
  month1?: InputMaybe<Scalars['Int']>;
  month2?: InputMaybe<Scalars['Int']>;
  salesTeamGroupId?: InputMaybe<Scalars['ID']>;
  userIds?: InputMaybe<Array<Scalars['ID']>>;
  year: Scalars['Int'];
};

export type QuerySalesTeamGroupsArgs = {
  filters: SalesTeamGroupSearchInput;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
};

export type QuerySalesTeamUnitsArgs = {
  filters: SalesTeamUnitSearchInput;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
};

export type QuerySalesTeamsArgs = {
  filters: SalesTeamSearchInput;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
};

export type QueryShippingMethodsArgs = {
  filters: ShippingMethodSearchInput;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
};

export type QueryStoresArgs = {
  filters: StoreSearchInput;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
};

export type QuerySupplierPropertiesArgs = {
  filters: SupplierPropertySearchInput;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
};

export type QuerySuppliersArgs = {
  filters: SupplierSearchInput;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
};

export type QueryTaxRatesArgs = {
  filters: TaxRateSearchInput;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
};

export type QueryUsersArgs = {
  filters: UserSearchInput;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
};

export type QueryVisitActionsArgs = {
  filters: VisitActionSearchInput;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
};

export type QueryVisitCheckInsArgs = {
  filters: VisitCheckInSearchInput;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
};

export type QueryVisitGoalsArgs = {
  filters: VisitGoalSearchInput;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
};

export type QueryVisitMemosArgs = {
  filters: VisitMemoSearchInput;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
};

export type QueryVisitPropertiesArgs = {
  filters: VisitPropertySearchInput;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
};

export type QueryVisitPropertyTypesArgs = {
  filters: VisitPropertyTypeSearchInput;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
};

export type QueryVisitTimePeriodsArgs = {
  filters: VisitTimePeriodSearchInput;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
};

export type QueryVisitTypesArgs = {
  filters: VisitTypeSearchInput;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
};

export type QueryVisitsArgs = {
  filters: VisitSearchInput;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
};

export type QueryWarehousesArgs = {
  filters: WarehouseSearchInput;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
};

export type QueryZone2sArgs = {
  filters: Zone2SearchInput;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
};

export type QueryZonesArgs = {
  filters: ZoneSearchInput;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
};

export type Questionnaire = IQuestionnaire & {
  createdAt: Scalars['DateTime'];
  createdUser?: Maybe<User>;
  createdUserId?: Maybe<Scalars['ID']>;
  date1?: Maybe<Scalars['Date']>;
  date2?: Maybe<Scalars['Date']>;
  fields?: Maybe<Scalars['JSON']>;
  id: Scalars['ID'];
  name: Scalars['String'];
  status: EnumQuestionnaireStatus;
  typeId: Scalars['ID'];
  updatedAt: Scalars['DateTime'];
  updatedUser?: Maybe<User>;
  updatedUserId?: Maybe<Scalars['ID']>;
};

export type QuestionnaireCreateInput = {
  date1?: InputMaybe<Scalars['Date']>;
  date2?: InputMaybe<Scalars['Date']>;
  field: Scalars['JSON'];
  name: Scalars['String'];
  status: EnumQuestionnaireStatus;
  typeId: Scalars['ID'];
};

export type QuestionnaireSearchInput = {
  id?: InputMaybe<Scalars['ID']>;
  name?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<EnumQuestionnaireStatus>;
  typeId?: InputMaybe<Scalars['ID']>;
};

export type QuestionnaireSearchResult = {
  count: Scalars['Int'];
  pageInfo: PageInfo;
  rows: Array<Questionnaire>;
};

export type QuestionnaireUpdateInput = {
  date1?: InputMaybe<Scalars['Date']>;
  date2?: InputMaybe<Scalars['Date']>;
  field?: InputMaybe<Scalars['JSON']>;
  id: Scalars['ID'];
  name?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<EnumQuestionnaireStatus>;
  typeId?: InputMaybe<Scalars['ID']>;
};

export type QuickSearchEyeProductSearchInput = {
  /** 商品品牌 */
  brand?: InputMaybe<Scalars['String']>;
  companyIds?: InputMaybe<Array<Scalars['ID']>>;
  /** 幣別 */
  currencyId?: InputMaybe<Scalars['ID']>;
  date1?: InputMaybe<Scalars['Date']>;
  date2?: InputMaybe<Scalars['Date']>;
  /** 經銷金額1 */
  dealerPrice1?: InputMaybe<Scalars['Float']>;
  /** 經銷金額2 */
  dealerPrice2?: InputMaybe<Scalars['Float']>;
  eyeProductTypeId?: InputMaybe<Scalars['ID']>;
  eyePromotionId?: InputMaybe<Scalars['ID']>;
  id?: InputMaybe<Scalars['ID']>;
  ids?: InputMaybe<Array<Scalars['ID']>>;
  /** 商品型號 */
  model?: InputMaybe<Scalars['String']>;
  /** 商品名稱 */
  name?: InputMaybe<Scalars['String']>;
  regionIds?: InputMaybe<Array<Scalars['ID']>>;
  /** 販售金額1 */
  salePrice1?: InputMaybe<Scalars['Float']>;
  /** 販售金額2 */
  salePrice2?: InputMaybe<Scalars['Float']>;
  /** 狀態 */
  status?: InputMaybe<EnumEyeProductStatus>;
};

export type QuickSearchEyeProductSearchResult = {
  brand: Array<Scalars['String']>;
  ids: Array<Scalars['ID']>;
};

export type Region = IRegion & {
  code?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  localCurrency?: Maybe<Currency>;
  /** 當地幣別 */
  localCurrencyId: Scalars['ID'];
  name: Scalars['String'];
  taxRates: Array<TaxRate>;
  updatedAt: Scalars['DateTime'];
};

export type RegionSearchInput = {
  ids?: InputMaybe<Array<Scalars['ID']>>;
};

export type RegionSearchResult = {
  count: Scalars['Int'];
  pageInfo: PageInfo;
  rows: Array<Region>;
};

export type RentProduct = IRentProduct & {
  /** 資料新增日期 */
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  /** 名稱 */
  name: Scalars['String'];
  region?: Maybe<Region>;
  salesTeamGroup?: Maybe<SalesTeamGroup>;
  /** 業務團隊組織 */
  salesTeamGroupId: Scalars['ID'];
  /** 序號 */
  sn?: Maybe<Scalars['String']>;
  /** 資料修改日期 */
  updatedAt: Scalars['DateTime'];
  /** 檢視順序 */
  viewOrder?: Maybe<Scalars['Int']>;
};

export type RentProductCreateInput = {
  name: Scalars['String'];
  salesTeamGroupId: Scalars['ID'];
  sn?: InputMaybe<Scalars['String']>;
  viewOrder?: InputMaybe<Scalars['Int']>;
};

export type RentProductSearchInput = {
  id?: InputMaybe<Scalars['ID']>;
  name?: InputMaybe<Scalars['String']>;
  regionIds?: InputMaybe<Array<Scalars['ID']>>;
  salesTeamGroupIds?: InputMaybe<Array<Scalars['ID']>>;
};

export type RentProductSearchResult = {
  count: Scalars['Int'];
  pageInfo: PageInfo;
  rows: Array<RentProduct>;
};

export type RentProductUpdateInput = {
  id: Scalars['ID'];
  name?: InputMaybe<Scalars['String']>;
  sn?: InputMaybe<Scalars['String']>;
  viewOrder?: InputMaybe<Scalars['Int']>;
};

export type Role = IRole & {
  /** 允許的公司ids */
  allowCompanyIds: Array<Scalars['Int']>;
  /** 允許的商品團隊ids */
  allowProductTeamIds: Array<Scalars['Int']>;
  allowProductTeams: Array<ProductTeam>;
  /** 允許的區域ids */
  allowRegionIds: Array<Scalars['Int']>;
  allowRegions: Array<Region>;
  /** 允許的業務團隊組織ids */
  allowSalesTeamGroupIds: Array<Scalars['Int']>;
  allowSalesTeamGroups: Array<SalesTeamGroup>;
  /** 允許的業務團隊ids */
  allowSalesTeamIds: Array<Scalars['Int']>;
  allowSalesTeams: Array<SalesTeam>;
  /** 允許的使用者ids */
  allowUserIds: Array<Scalars['Int']>;
  allowUsers: Array<User>;
  /** 編號 */
  code?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  /** 是否允許所有業務團隊(中国眼科) */
  isAllowAllChnEyeSalesTeam: Scalars['Boolean'];
  /** 是否允許所有公司(cn) */
  isAllowAllCnCompanies: Scalars['Boolean'];
  /** 是否允許所有區域 */
  isAllowAllRegions: Scalars['Boolean'];
  /** 是否允許所有業務團隊組織 */
  isAllowAllSalesTeamGroups: Scalars['Boolean'];
  /** 是否允許所有公司(tw) */
  isAllowAllTwCompanies: Scalars['Boolean'];
  /** 是否允許所有業務團隊(電子耳) */
  isAllowAllTwnClSalesTeam: Scalars['Boolean'];
  /** 是否允許所有業務團隊(科明／明睿) */
  isAllowAllTwnEcAndMrSalesTeam: Scalars['Boolean'];
  /** 是否允許所有業務團隊(臺灣眼科) */
  isAllowAllTwnEyeSalesTeam: Scalars['Boolean'];
  /** 是否只允許自己的資料 */
  isOnlyOwnUser: Scalars['Boolean'];
  /** 是否為系統管理者 */
  isSystemAdmin: Scalars['Boolean'];
  /** 名稱 */
  name: Scalars['String'];
  permissions: Array<Permission>;
  users: Array<User>;
};

export type RoleCreateInput = {
  /** 允許的公司ids */
  allowCompanyIds?: InputMaybe<Array<Scalars['ID']>>;
  /** 允許的PM團隊ids */
  allowProductTeamIds?: InputMaybe<Array<Scalars['ID']>>;
  /** 允許的區域ids */
  allowRegionIds?: InputMaybe<Array<Scalars['ID']>>;
  /** 允許的業務團隊組織ids */
  allowSalesTeamGroupIds?: InputMaybe<Array<Scalars['ID']>>;
  /** 允許的業務團隊ids */
  allowSalesTeamIds?: InputMaybe<Array<Scalars['ID']>>;
  /** 允許的使用者ids */
  allowUserIds?: InputMaybe<Array<Scalars['ID']>>;
  /** 編碼 */
  code?: InputMaybe<Scalars['String']>;
  /** 是否允許所有中國公司 */
  isAllowAllCnCompanies?: InputMaybe<Scalars['Boolean']>;
  /** 是否允許所有區域 */
  isAllowAllRegions?: InputMaybe<Scalars['Boolean']>;
  /** 是否允許所有業務團隊組織 */
  isAllowAllSalesTeamGroups?: InputMaybe<Scalars['Boolean']>;
  /** 是否允許所有台灣公司 */
  isAllowAllTwCompanies?: InputMaybe<Scalars['Boolean']>;
  /** 是否只允許自己的資料 */
  isOnlyOwnUser?: InputMaybe<Scalars['Boolean']>;
  /** 是否為系統管理者 */
  isSystemAdmin?: InputMaybe<Scalars['Boolean']>;
  name: Scalars['String'];
  /** 角色 <> 權限 */
  permissionIds?: InputMaybe<Array<Scalars['ID']>>;
};

export type RoleSearchInput = {
  code?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['ID']>;
  /** 是否允許所有中國公司 */
  isAllowAllCnCompanies?: InputMaybe<Scalars['Boolean']>;
  /** 是否允許所有區域 */
  isAllowAllRegions?: InputMaybe<Scalars['Boolean']>;
  /** 是否允許所有業務團隊組織 */
  isAllowAllSalesTeamGroups?: InputMaybe<Scalars['Boolean']>;
  /** 是否允許所有台灣公司 */
  isAllowAllTwCompanies?: InputMaybe<Scalars['Boolean']>;
  /** 是否只允許自己的資料 */
  isOnlyOwnUser?: InputMaybe<Scalars['Boolean']>;
  /** 是否為系統管理者 */
  isSystemAdmin?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
  userId?: InputMaybe<Scalars['ID']>;
};

export type RoleSearchResult = {
  count: Scalars['Int'];
  pageInfo: PageInfo;
  rows: Array<Role>;
};

export type RoleUpdateInput = {
  /** 允許的公司ids */
  allowCompanyIds?: InputMaybe<Array<Scalars['ID']>>;
  /** 允許的PM團隊ids */
  allowProductTeamIds?: InputMaybe<Array<Scalars['ID']>>;
  /** 允許的區域ids */
  allowRegionIds?: InputMaybe<Array<Scalars['ID']>>;
  /** 允許的業務團隊組織ids */
  allowSalesTeamGroupIds?: InputMaybe<Array<Scalars['ID']>>;
  /** 允許的業務團隊ids */
  allowSalesTeamIds?: InputMaybe<Array<Scalars['ID']>>;
  /** 允許的使用者ids */
  allowUserIds?: InputMaybe<Array<Scalars['ID']>>;
  /** 編碼 */
  code?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
  /** 是否允許所有中國公司 */
  isAllowAllCnCompanies?: InputMaybe<Scalars['Boolean']>;
  /** 是否允許所有區域 */
  isAllowAllRegions?: InputMaybe<Scalars['Boolean']>;
  /** 是否允許所有業務團隊組織 */
  isAllowAllSalesTeamGroups?: InputMaybe<Scalars['Boolean']>;
  /** 是否允許所有台灣公司 */
  isAllowAllTwCompanies?: InputMaybe<Scalars['Boolean']>;
  /** 是否只允許自己的資料 */
  isOnlyOwnUser?: InputMaybe<Scalars['Boolean']>;
  /** 是否為系統管理者 */
  isSystemAdmin?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
  /** 角色 <> 權限 */
  permissionIds?: InputMaybe<Array<Scalars['ID']>>;
};

export type RolesUser = IRolesUser & {
  role?: Maybe<Role>;
  roleId: Scalars['ID'];
  user?: Maybe<User>;
  userId: Scalars['ID'];
};

export type RolesUserBulkCreateInput = {
  roleIds: Array<Scalars['ID']>;
  userIds: Array<Scalars['ID']>;
};

export type RolesUserDeleteInput = {
  roleId: Scalars['ID'];
  userId: Scalars['ID'];
};

export type RolesUserSearchInput = {
  id?: InputMaybe<Scalars['ID']>;
  roleId?: InputMaybe<Scalars['ID']>;
  userId?: InputMaybe<Scalars['ID']>;
};

export type RolesUserSearchResult = {
  count: Scalars['Int'];
  pageInfo: PageInfo;
  rows: Array<RolesUser>;
};

export type SalesPerformanceSalesProductOverallView = {
  /** 整體 */
  overall: SalesPerformanceSalesProductOverallViewOverall;
  salesTeamGroup?: Maybe<SalesTeamGroup>;
  /** 業務團隊組織 */
  salesTeamGroupId?: Maybe<Scalars['ID']>;
  /** 商品業績類型清單 */
  types: Array<SalesPerformanceSalesProductOverallViewType>;
  /** 年份 */
  year: Scalars['Int'];
};

export type SalesPerformanceSalesProductOverallViewBulkUpdateInput = {
  inputs: Array<SalesPerformanceSalesProductOverallViewUpdateInput>;
};

export type SalesPerformanceSalesProductOverallViewBulkUpdateResult = {
  /** 總體預測 */
  forecasts: Array<SalesProductOverallForecastItem>;
  /** 總體目標 */
  goals: Array<SalesProductOverallGoalItem>;
};

export type SalesPerformanceSalesProductOverallViewOverall = {
  /** 目前金額 */
  currentAmount: Scalars['Float'];
  /** 目前數量 */
  currentQty: Scalars['Float'];
  /** 目前單價 */
  currentUnitPrice: Scalars['Float'];
  /** 預測金額 */
  forecastAmount: Scalars['Float'];
  /** 預測數量 */
  forecastQty: Scalars['Float'];
  /** 預測單價 */
  forecastUnitPrice: Scalars['Float'];
  /** 目標金額 */
  goalAmount: Scalars['Float'];
  /** 目標金額達成率（1: 100%, 0.1234: 12.34%, 0: 0%）。分母為 0 時，百分比顯示為空值 */
  goalAmountAchievementRate?: Maybe<Scalars['Float']>;
  /** 目標數量 */
  goalQty: Scalars['Float'];
  /** 目標數量達成率（1: 100%, 0.1234: 12.34%, 0: 0%）。分母為 0 時，百分比顯示為空值 */
  goalQtyAchievementRate?: Maybe<Scalars['Float']>;
  /** 目標單價 */
  goalUnitPrice: Scalars['Float'];
  /** 目標單價達成率（1: 100%, 0.1234: 12.34%, 0: 0%）。分母為 0 時，百分比顯示為空值 */
  goalUnitPriceAchievementRate?: Maybe<Scalars['Float']>;
  /** 月份清單 */
  months: Array<SalesPerformanceSalesProductOverallViewOverallMonth>;
  /** 實際金額 */
  salesAmount: Scalars['Float'];
  /** 實際數量 */
  salesQty: Scalars['Float'];
  /** 實際單價 */
  salesUnitPrice: Scalars['Float'];
  /** 今年至今 目前金額 */
  ytdCurrentAmount: Scalars['Float'];
  /** 今年至今 目前數量 */
  ytdCurrentQty: Scalars['Float'];
  /** 今年至今 目前單價 */
  ytdCurrentUnitPrice: Scalars['Float'];
  /** 今年至今 目標金額 */
  ytdGoalAmount: Scalars['Float'];
  /** 今年至今 目標金額達成率（1: 100%, 0.1234: 12.34%, 0: 0%）。分母為 0 時，百分比顯示為空值 */
  ytdGoalAmountAchievementRate?: Maybe<Scalars['Float']>;
  /** 今年至今 目標數量 */
  ytdGoalQty: Scalars['Float'];
  /** 今年至今 目標數量達成率（1: 100%, 0.1234: 12.34%, 0: 0%）。分母為 0 時，百分比顯示為空值 */
  ytdGoalQtyAchievementRate?: Maybe<Scalars['Float']>;
  /** 今年至今 目標單價 */
  ytdGoalUnitPrice: Scalars['Float'];
  /** 今年至今 目標單價達成率（1: 100%, 0.1234: 12.34%, 0: 0%）。分母為 0 時，百分比顯示為空值 */
  ytdGoalUnitPriceAchievementRate?: Maybe<Scalars['Float']>;
};

export type SalesPerformanceSalesProductOverallViewOverallMonth = {
  /** 目前金額 */
  currentAmount: Scalars['Float'];
  /** 目前數量 */
  currentQty: Scalars['Float'];
  /** 目前單價 */
  currentUnitPrice: Scalars['Float'];
  /** 預測金額 */
  forecastAmount: Scalars['Float'];
  /** 預測數量 */
  forecastQty: Scalars['Float'];
  /** 預測單價 */
  forecastUnitPrice: Scalars['Float'];
  /** 目標金額 */
  goalAmount: Scalars['Float'];
  /** 目標金額達成率（1: 100%, 0.1234: 12.34%, 0: 0%）。分母為 0 時，百分比顯示為空值 */
  goalAmountAchievementRate?: Maybe<Scalars['Float']>;
  /** 目標數量 */
  goalQty: Scalars['Float'];
  /** 目標數量達成率（1: 100%, 0.1234: 12.34%, 0: 0%）。分母為 0 時，百分比顯示為空值 */
  goalQtyAchievementRate?: Maybe<Scalars['Float']>;
  /** 目標單價 */
  goalUnitPrice: Scalars['Float'];
  /** 目標單價達成率（1: 100%, 0.1234: 12.34%, 0: 0%）。分母為 0 時，百分比顯示為空值 */
  goalUnitPriceAchievementRate?: Maybe<Scalars['Float']>;
  /** 月份 */
  month: Scalars['Int'];
  /** 實際金額 */
  salesAmount: Scalars['Float'];
  /** 實際數量 */
  salesQty: Scalars['Float'];
  /** 實際單價 */
  salesUnitPrice: Scalars['Float'];
  /** 時態 */
  tense: EnumSalesProductOverallViewTense;
};

export type SalesPerformanceSalesProductOverallViewType = {
  /** 目前金額 */
  currentAmount: Scalars['Float'];
  /** 目前數量 */
  currentQty: Scalars['Float'];
  /** 目前單價 */
  currentUnitPrice: Scalars['Float'];
  /** 預測金額 */
  forecastAmount: Scalars['Float'];
  /** 預測數量 */
  forecastQty: Scalars['Float'];
  /** 預測單價 */
  forecastUnitPrice: Scalars['Float'];
  /** 目標金額 */
  goalAmount: Scalars['Float'];
  /** 目標金額達成率（1: 100%, 0.1234: 12.34%, 0: 0%）。分母為 0 時，百分比顯示為空值 */
  goalAmountAchievementRate?: Maybe<Scalars['Float']>;
  /** 目標數量 */
  goalQty: Scalars['Float'];
  /** 目標數量達成率（1: 100%, 0.1234: 12.34%, 0: 0%）。分母為 0 時，百分比顯示為空值 */
  goalQtyAchievementRate?: Maybe<Scalars['Float']>;
  /** 目標單價 */
  goalUnitPrice: Scalars['Float'];
  /** 目標單價達成率（1: 100%, 0.1234: 12.34%, 0: 0%）。分母為 0 時，百分比顯示為空值 */
  goalUnitPriceAchievementRate?: Maybe<Scalars['Float']>;
  /** 月份清單 */
  months: Array<SalesPerformanceSalesProductOverallViewTypeMonth>;
  /** 實際金額 */
  salesAmount: Scalars['Float'];
  salesProductType?: Maybe<SalesProductType>;
  /** 商品業績類型 */
  salesProductTypeId: Scalars['ID'];
  /** 實際數量 */
  salesQty: Scalars['Float'];
  /** 實際單價 */
  salesUnitPrice: Scalars['Float'];
  /** 今年至今 目前金額 */
  ytdCurrentAmount: Scalars['Float'];
  /** 今年至今 目前數量 */
  ytdCurrentQty: Scalars['Float'];
  /** 今年至今 目前單價 */
  ytdCurrentUnitPrice: Scalars['Float'];
  /** 今年至今 目標金額 */
  ytdGoalAmount: Scalars['Float'];
  /** 今年至今 目標金額達成率（1: 100%, 0.1234: 12.34%, 0: 0%）。分母為 0 時，百分比顯示為空值 */
  ytdGoalAmountAchievementRate?: Maybe<Scalars['Float']>;
  /** 今年至今 目標數量 */
  ytdGoalQty: Scalars['Float'];
  /** 今年至今 目標數量達成率（1: 100%, 0.1234: 12.34%, 0: 0%）。分母為 0 時，百分比顯示為空值 */
  ytdGoalQtyAchievementRate?: Maybe<Scalars['Float']>;
  /** 今年至今 目標單價 */
  ytdGoalUnitPrice: Scalars['Float'];
  /** 今年至今 目標單價達成率（1: 100%, 0.1234: 12.34%, 0: 0%）。分母為 0 時，百分比顯示為空值 */
  ytdGoalUnitPriceAchievementRate?: Maybe<Scalars['Float']>;
};

export type SalesPerformanceSalesProductOverallViewTypeMonth = {
  /** 目前金額（過去時態：映射「實際數量」、現在、未來時態：映射「預測數量」） */
  currentAmount: Scalars['Float'];
  /** 目前數量（過去時態：映射「實際數量」、現在、未來時態：映射「預測數量」） */
  currentQty: Scalars['Float'];
  /** 目前單價（過去時態：映射「實際數量」、現在、未來時態：映射「預測數量」） */
  currentUnitPrice: Scalars['Float'];
  /** 預測金額 */
  forecastAmount: Scalars['Float'];
  /** 預測數量 */
  forecastQty: Scalars['Float'];
  /** 預測單價 */
  forecastUnitPrice: Scalars['Float'];
  /** 目標金額 */
  goalAmount: Scalars['Float'];
  /** 目標數量 */
  goalQty: Scalars['Float'];
  /** 目標單價 */
  goalUnitPrice: Scalars['Float'];
  /** 月份 */
  month: Scalars['Int'];
  /** 實際金額 */
  salesAmount: Scalars['Float'];
  /** 實際數量 */
  salesQty: Scalars['Float'];
  /** 實際單價 */
  salesUnitPrice: Scalars['Float'];
  /** 時態 */
  tense: EnumSalesProductOverallViewTense;
};

export type SalesPerformanceSalesProductOverallViewUpdateInput = {
  /** 預測數量 */
  forecastQty?: InputMaybe<Scalars['Float']>;
  /** 預測單價 */
  forecastUnitPrice?: InputMaybe<Scalars['Float']>;
  /** 目標金額 */
  goalAmount?: InputMaybe<Scalars['Float']>;
  /** 目標數量 */
  goalQty?: InputMaybe<Scalars['Float']>;
  /** 月份 */
  month: Scalars['Int'];
  /** 銷售商品類型 */
  salesProductTypeId: Scalars['ID'];
  /** 業務團隊組織 */
  salesTeamGroupId: Scalars['ID'];
  /** 年份 */
  year: Scalars['Int'];
};

export type SalesPerformanceSalesProductTeamGoalView = {
  salesTeamGroup?: Maybe<SalesTeamGroup>;
  /** 業務團隊組織 */
  salesTeamGroupId: Scalars['ID'];
  /** 團隊清單 */
  teams: Array<SalesPerformanceSalesProductTeamGoalViewTeam>;
  /** 年份 */
  year: Scalars['Int'];
};

export type SalesPerformanceSalesProductTeamGoalViewBulkUpdateInput = {
  inputs: Array<SalesPerformanceSalesProductTeamGoalViewUpdateInput>;
};

export type SalesPerformanceSalesProductTeamGoalViewTeam = {
  /** 月份清單 */
  months: Array<SalesPerformanceSalesProductTeamGoalViewTeamMonth>;
  salesTeam?: Maybe<SalesTeam>;
  /** 業務團隊 */
  salesTeamId: Scalars['ID'];
};

export type SalesPerformanceSalesProductTeamGoalViewTeamMonth = {
  /** 月份 */
  month: Scalars['Int'];
  /** 銷售商品類型清單 */
  types: Array<SalesPerformanceSalesProductTeamGoalViewTeamMonthType>;
};

export type SalesPerformanceSalesProductTeamGoalViewTeamMonthType = {
  /** 金额 */
  amount: Scalars['Int'];
  /** 數量 */
  qty: Scalars['Int'];
  salesProductType?: Maybe<SalesProductType>;
  /** 銷售商品類型 */
  salesProductTypeId: Scalars['ID'];
};

export type SalesPerformanceSalesProductTeamGoalViewUpdateInput = {
  /** 金额 */
  amount: Scalars['Int'];
  /** 月份 */
  month: Scalars['Int'];
  /** 數量 */
  qty: Scalars['Int'];
  /** 銷售商品類型 */
  salesProductTypeId: Scalars['ID'];
  /** 業務團隊組織 */
  salesTeamGroupId: Scalars['ID'];
  /** 業務團隊 */
  salesTeamId: Scalars['ID'];
  /** 年份 */
  year: Scalars['Int'];
};

export type SalesPerformanceSalesProductTypeGroupSearchInput = {
  id?: InputMaybe<Scalars['ID']>;
  ids?: InputMaybe<Array<Scalars['ID']>>;
  salesTeamGroupId?: InputMaybe<Scalars['ID']>;
};

export type SalesPerformanceSalesProductTypeGroupSearchResult = {
  count: Scalars['Int'];
  pageInfo: PageInfo;
  rows: Array<SalesProductTypeGroup>;
};

export type SalesPerformanceSalesProductTypeSearchInput = {
  id?: InputMaybe<Scalars['ID']>;
  ids?: InputMaybe<Array<Scalars['ID']>>;
  salesTeamGroupId?: InputMaybe<Scalars['ID']>;
};

export type SalesPerformanceSalesProductTypeSearchResult = {
  count: Scalars['Int'];
  pageInfo: PageInfo;
  rows: Array<SalesProductType>;
};

export type SalesPerformanceSalesProductUserForecastView = {
  /** 整體 */
  overall: SalesPerformanceSalesProductUserForecastViewOverall;
  salesTeamGroup?: Maybe<SalesTeamGroup>;
  /** 業務團隊組織 */
  salesTeamGroupId: Scalars['ID'];
  /** 個人清單 */
  users: Array<SalesPerformanceSalesProductUserForecastViewUser>;
  /** 年份 */
  year: Scalars['Int'];
};

export type SalesPerformanceSalesProductUserForecastViewBulkUpdateInput = {
  inputs: Array<SalesPerformanceSalesProductUserForecastViewUpdateInput>;
};

export type SalesPerformanceSalesProductUserForecastViewOverall = {
  /** 月份清單 */
  months: Array<SalesPerformanceSalesProductUserForecastViewOverallMonth>;
};

export type SalesPerformanceSalesProductUserForecastViewOverallMonth = {
  /** 月份 */
  month: Scalars['Int'];
  /** 銷售商品類型清單 */
  types: Array<SalesPerformanceSalesProductUserForecastViewOverallMonthType>;
};

export type SalesPerformanceSalesProductUserForecastViewOverallMonthType = {
  /** 實際業績 */
  qtyActual: Scalars['Int'];
  /** 月初預測 */
  qtyEarlyMonth: Scalars['Int'];
  /** 月初預測準確率（1: 100%, 0.1234: 12.34%, 0: 0%）。分母（月初預測）為 0 時，百分比顯示為空值 */
  qtyEarlyMonthAccuracyRate?: Maybe<Scalars['Float']>;
  /** 月底預測 */
  qtyLateMonth: Scalars['Int'];
  /** 月底預測準確率（1: 100%, 0.1234: 12.34%, 0: 0%）。分母（月底預測）為 0 時，百分比顯示為空值 */
  qtyLateMonthAccuracyRate?: Maybe<Scalars['Float']>;
  /** Quota */
  quota: Scalars['Int'];
  /** Quota 達成率（1: 100%, 0.1234: 12.34%, 0: 0%）。分母（Quota）為 0 時，百分比顯示為空值 */
  quotaAchievementRate?: Maybe<Scalars['Float']>;
  salesProductType?: Maybe<SalesProductType>;
  /** 銷售商品類型 */
  salesProductTypeId: Scalars['ID'];
};

export type SalesPerformanceSalesProductUserForecastViewUpdateInput = {
  /** 月份 */
  month: Scalars['Int'];
  /** 月初預測 */
  qtyEarlyMonth: Scalars['Int'];
  /** 月底預測 */
  qtyLateMonth: Scalars['Int'];
  /** Quota */
  quota: Scalars['Int'];
  /** 銷售商品類型 */
  salesProductTypeId: Scalars['ID'];
  /** 業務團隊組織 */
  salesTeamGroupId: Scalars['ID'];
  /** 負責業務位置 */
  salesTeamUnitId: Scalars['ID'];
  /** 年份 */
  year: Scalars['Int'];
};

export type SalesPerformanceSalesProductUserForecastViewUser = {
  /** 月份清單 */
  months: Array<SalesPerformanceSalesProductUserForecastViewUserMonth>;
  salesTeamUnit?: Maybe<SalesTeamUnit>;
  /** 負責業務位置 */
  salesTeamUnitId: Scalars['ID'];
};

export type SalesPerformanceSalesProductUserForecastViewUserMonth = {
  /** 月份 */
  month: Scalars['Int'];
  /** 銷售商品類型清單 */
  types: Array<SalesPerformanceSalesProductUserForecastViewUserMonthType>;
};

export type SalesPerformanceSalesProductUserForecastViewUserMonthType = {
  /** 實際業績 */
  qtyActual: Scalars['Int'];
  /** 月初預測 */
  qtyEarlyMonth: Scalars['Int'];
  /** 月初預測準確率（1: 100%, 0.1234: 12.34%, 0: 0%）。分母（月初預測）為 0 時，百分比顯示為空值 */
  qtyEarlyMonthAccuracyRate?: Maybe<Scalars['Float']>;
  /** 月底預測 */
  qtyLateMonth: Scalars['Int'];
  /** 月底預測準確率（1: 100%, 0.1234: 12.34%, 0: 0%）。分母（月底預測）為 0 時，百分比顯示為空值 */
  qtyLateMonthAccuracyRate?: Maybe<Scalars['Float']>;
  /** Quota */
  quota: Scalars['Int'];
  /** Quota 達成率（1: 100%, 0.1234: 12.34%, 0: 0%）。分母（Quota）為 0 時，百分比顯示為空值 */
  quotaAchievementRate?: Maybe<Scalars['Float']>;
  salesProductType?: Maybe<SalesProductType>;
  /** 銷售商品類型 */
  salesProductTypeId: Scalars['ID'];
};

export type SalesPerformanceSalesProductUserGoalView = {
  salesTeamGroup?: Maybe<SalesTeamGroup>;
  /** 業務團隊組織 */
  salesTeamGroupId: Scalars['ID'];
  /** 個人清單 */
  users: Array<SalesPerformanceSalesProductUserGoalViewUser>;
  /** 年份 */
  year: Scalars['Int'];
};

export type SalesPerformanceSalesProductUserGoalViewBulkUpdateInput = {
  inputs: Array<SalesPerformanceSalesProductUserGoalViewUpdateInput>;
};

export type SalesPerformanceSalesProductUserGoalViewUpdateInput = {
  /** 金额 */
  amount: Scalars['Int'];
  /** 月份 */
  month: Scalars['Int'];
  /** 數量 */
  qty: Scalars['Int'];
  /** 銷售商品類型 */
  salesProductTypeId: Scalars['ID'];
  /** 業務團隊組織 */
  salesTeamGroupId: Scalars['ID'];
  /** 負責業務位置 */
  salesTeamUnitId: Scalars['ID'];
  /** 年份 */
  year: Scalars['Int'];
};

export type SalesPerformanceSalesProductUserGoalViewUser = {
  /** 月份清單 */
  months: Array<SalesPerformanceSalesProductUserGoalViewUserMonth>;
  salesTeamUnit?: Maybe<SalesTeamUnit>;
  /** 負責業務位置 */
  salesTeamUnitId: Scalars['ID'];
};

export type SalesPerformanceSalesProductUserGoalViewUserMonth = {
  /** 月份 */
  month: Scalars['Int'];
  /** 銷售商品類型清單 */
  types: Array<SalesPerformanceSalesProductUserGoalViewUserMonthType>;
};

export type SalesPerformanceSalesProductUserGoalViewUserMonthType = {
  /** 金额 */
  amount: Scalars['Int'];
  /** 數量 */
  qty: Scalars['Int'];
  salesProductType?: Maybe<SalesProductType>;
  /** 銷售商品類型 */
  salesProductTypeId: Scalars['ID'];
};

export type SalesPerformanceSalesTeamGoalView = {
  salesTeamGroup?: Maybe<SalesTeamGroup>;
  /** 業務團隊組織 */
  salesTeamGroupId: Scalars['ID'];
  /** 團隊清單 */
  teams: Array<SalesPerformanceSalesTeamGoalViewTeam>;
  /** 年份 */
  year: Scalars['Int'];
};

export type SalesPerformanceSalesTeamGoalViewBulkUpdateInput = {
  inputs: Array<SalesPerformanceSalesTeamGoalViewUpdateInput>;
};

export type SalesPerformanceSalesTeamGoalViewTeam = {
  /** 月份清單 */
  months: Array<SalesPerformanceSalesTeamGoalViewTeamMonth>;
  salesTeam?: Maybe<SalesTeam>;
  /** 業務團隊 */
  salesTeamId: Scalars['ID'];
};

export type SalesPerformanceSalesTeamGoalViewTeamMonth = {
  /** 月份 */
  month: Scalars['Int'];
  /** 銷售業績類型清單 */
  types: Array<SalesPerformanceSalesTeamGoalViewTeamMonthType>;
};

export type SalesPerformanceSalesTeamGoalViewTeamMonthType = {
  /** 金额 */
  amount: Scalars['Int'];
  salesType?: Maybe<SalesType>;
  /** 銷售業績類型 */
  salesTypeId: Scalars['ID'];
};

export type SalesPerformanceSalesTeamGoalViewUpdateInput = {
  /** 金额 */
  amount: Scalars['Int'];
  /** 月份 */
  month: Scalars['Int'];
  /** 業務團隊組織 */
  salesTeamGroupId: Scalars['ID'];
  /** 業務團隊 */
  salesTeamId: Scalars['ID'];
  /** 銷售業績類型 */
  salesTypeId: Scalars['ID'];
  /** 年份 */
  year: Scalars['Int'];
};

export type SalesPerformanceSalesTypeSearchInput = {
  id?: InputMaybe<Scalars['ID']>;
  ids?: InputMaybe<Array<Scalars['ID']>>;
  salesTeamGroupId?: InputMaybe<Scalars['ID']>;
};

export type SalesPerformanceSalesTypeSearchResult = {
  count: Scalars['Int'];
  pageInfo: PageInfo;
  rows: Array<SalesType>;
};

export type SalesPerformanceSalesUserForecastView = {
  /** 整體 */
  overall: SalesPerformanceSalesUserForecastViewOverall;
  salesTeamGroup?: Maybe<SalesTeamGroup>;
  /** 業務團隊組織 */
  salesTeamGroupId: Scalars['ID'];
  /** 個人清單 */
  users: Array<SalesPerformanceSalesUserForecastViewUser>;
  /** 年份 */
  year: Scalars['Int'];
};

export type SalesPerformanceSalesUserForecastViewBulkUpdateInput = {
  inputs: Array<SalesPerformanceSalesUserForecastViewUpdateInput>;
};

export type SalesPerformanceSalesUserForecastViewOverall = {
  /** 月份清單 */
  months: Array<SalesPerformanceSalesUserForecastViewOverallMonth>;
};

export type SalesPerformanceSalesUserForecastViewOverallMonth = {
  /** 月份 */
  month: Scalars['Int'];
  /** 銷售業績類型清單 */
  types: Array<SalesPerformanceSalesUserForecastViewOverallMonthType>;
};

export type SalesPerformanceSalesUserForecastViewOverallMonthType = {
  /** 實際業績 */
  amountActual: Scalars['Int'];
  /** 月初預測 */
  amountEarlyMonth: Scalars['Int'];
  /** 月初預測準確率（1: 100%, 0.1234: 12.34%, 0: 0%）。分母（月初預測）為 0 時，百分比顯示為空值 */
  amountEarlyMonthAccuracyRate?: Maybe<Scalars['Float']>;
  /** 月底預測 */
  amountLateMonth: Scalars['Int'];
  /** 月底預測準確率（1: 100%, 0.1234: 12.34%, 0: 0%）。分母（月底預測）為 0 時，百分比顯示為空值 */
  amountLateMonthAccuracyRate?: Maybe<Scalars['Float']>;
  /** Quota */
  quota: Scalars['Int'];
  /** Quota 達成率（1: 100%, 0.1234: 12.34%, 0: 0%）。分母（Quota）為 0 時，百分比顯示為空值 */
  quotaAchievementRate?: Maybe<Scalars['Float']>;
  salesType?: Maybe<SalesType>;
  /** 銷售業績類型 */
  salesTypeId: Scalars['ID'];
};

export type SalesPerformanceSalesUserForecastViewUpdateInput = {
  /** 月初預測 */
  amountEarlyMonth: Scalars['Int'];
  /** 月底預測 */
  amountLateMonth: Scalars['Int'];
  /** 月份 */
  month: Scalars['Int'];
  /** Quota */
  quota: Scalars['Int'];
  /** 業務團隊組織 */
  salesTeamGroupId: Scalars['ID'];
  /** 負責業務位置 */
  salesTeamUnitId: Scalars['ID'];
  /** 銷售業績類型 */
  salesTypeId: Scalars['ID'];
  /** 年份 */
  year: Scalars['Int'];
};

export type SalesPerformanceSalesUserForecastViewUser = {
  /** 月份清單 */
  months: Array<SalesPerformanceSalesUserForecastViewUserMonth>;
  salesTeamUnit?: Maybe<SalesTeamUnit>;
  /** 負責業務位置 */
  salesTeamUnitId: Scalars['ID'];
};

export type SalesPerformanceSalesUserForecastViewUserMonth = {
  /** 月份 */
  month: Scalars['Int'];
  /** 銷售業績類型清單 */
  types: Array<SalesPerformanceSalesUserForecastViewUserMonthType>;
};

export type SalesPerformanceSalesUserForecastViewUserMonthType = {
  /** 實際業績 */
  amountActual: Scalars['Int'];
  /** 月初預測 */
  amountEarlyMonth: Scalars['Int'];
  /** 月初預測準確率（1: 100%, 0.1234: 12.34%, 0: 0%）。分母（月初預測）為 0 時，百分比顯示為空值 */
  amountEarlyMonthAccuracyRate?: Maybe<Scalars['Float']>;
  /** 月底預測 */
  amountLateMonth: Scalars['Int'];
  /** 月底預測準確率（1: 100%, 0.1234: 12.34%, 0: 0%）。分母（月底預測）為 0 時，百分比顯示為空值 */
  amountLateMonthAccuracyRate?: Maybe<Scalars['Float']>;
  /** Quota */
  quota: Scalars['Int'];
  /** Quota 達成率（1: 100%, 0.1234: 12.34%, 0: 0%）。分母（Quota）為 0 時，百分比顯示為空值 */
  quotaAchievementRate?: Maybe<Scalars['Float']>;
  salesType?: Maybe<SalesType>;
  /** 銷售業績類型 */
  salesTypeId: Scalars['ID'];
};

export type SalesPerformanceSalesUserGoalView = {
  salesTeamGroup?: Maybe<SalesTeamGroup>;
  /** 業務團隊組織 */
  salesTeamGroupId: Scalars['ID'];
  /** 個人清單 */
  users: Array<SalesPerformanceSalesUserGoalViewUser>;
  /** 年份 */
  year: Scalars['Int'];
};

export type SalesPerformanceSalesUserGoalViewBulkUpdateInput = {
  inputs: Array<SalesPerformanceSalesUserGoalViewUpdateInput>;
};

export type SalesPerformanceSalesUserGoalViewUpdateInput = {
  /** 金额 */
  amount: Scalars['Int'];
  /** 月份 */
  month: Scalars['Int'];
  /** 業務團隊組織 */
  salesTeamGroupId: Scalars['ID'];
  /** 負責業務位置 */
  salesTeamUnitId: Scalars['ID'];
  /** 銷售業績類型 */
  salesTypeId: Scalars['ID'];
  /** 年份 */
  year: Scalars['Int'];
};

export type SalesPerformanceSalesUserGoalViewUser = {
  /** 月份清單 */
  months: Array<SalesPerformanceSalesUserGoalViewUserMonth>;
  salesTeamUnit?: Maybe<SalesTeamUnit>;
  /** 負責業務位置 */
  salesTeamUnitId: Scalars['ID'];
};

export type SalesPerformanceSalesUserGoalViewUserMonth = {
  /** 月份 */
  month: Scalars['Int'];
  /** 銷售業績類型清單 */
  types: Array<SalesPerformanceSalesUserGoalViewUserMonthType>;
};

export type SalesPerformanceSalesUserGoalViewUserMonthType = {
  /** 金额 */
  amount: Scalars['Int'];
  salesType?: Maybe<SalesType>;
  /** 銷售業績類型 */
  salesTypeId: Scalars['ID'];
};

export type SalesProductOverallForecastItem =
  ISalesProductOverallForecastItem & {
    /** 金額 */
    amount: Scalars['Float'];
    /** 資料新增日期 */
    createdAt: Scalars['DateTime'];
    id: Scalars['ID'];
    /** 月份 */
    month: Scalars['Int'];
    /** 單頭 */
    overallForecastId: Scalars['ID'];
    /** 數量 */
    qty: Scalars['Float'];
    /** 單價 */
    unitPrice: Scalars['Float'];
    /** 資料修改日期 */
    updatedAt: Scalars['DateTime'];
  };

export type SalesProductOverallGoalItem = ISalesProductOverallGoalItem & {
  /** 金額 */
  amount: Scalars['Float'];
  /** 資料新增日期 */
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  /** 月份 */
  month: Scalars['Int'];
  /** 單頭 */
  overallGoalId: Scalars['ID'];
  /** 數量 */
  qty: Scalars['Float'];
  /** 單價 */
  unitPrice: Scalars['Float'];
  /** 資料修改日期 */
  updatedAt: Scalars['DateTime'];
};

export type SalesProductTeamGoalItem = ISalesProductTeamGoalItem & {
  /** 金額 */
  amount: Scalars['Int'];
  /** 資料新增日期 */
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  /** 月份 */
  month: Scalars['Int'];
  /** 數量 */
  qty: Scalars['Int'];
  /** 單頭 */
  teamGoalId: Scalars['ID'];
  /** 資料修改日期 */
  updatedAt: Scalars['DateTime'];
};

export type SalesProductType = ISalesProductType & {
  /** 編號 */
  code?: Maybe<Scalars['String']>;
  /** 資料新增日期 */
  createdAt: Scalars['DateTime'];
  /** 群組 */
  groupId?: Maybe<Scalars['ID']>;
  id: Scalars['ID'];
  /** 名稱 */
  name: Scalars['String'];
  salesTeamGroup?: Maybe<SalesTeamGroup>;
  /** 業務團隊組織 */
  salesTeamGroupId: Scalars['ID'];
  /** 資料修改日期 */
  updatedAt: Scalars['DateTime'];
  /** 檢視順序 */
  viewOrder?: Maybe<Scalars['Int']>;
};

export type SalesProductTypeGroup = ISalesProductTypeGroup & {
  /** 編號 */
  code?: Maybe<Scalars['String']>;
  /** 資料新增日期 */
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  /** 名稱 */
  name: Scalars['String'];
  salesTeamGroup?: Maybe<SalesTeamGroup>;
  /** 業務團隊組織 */
  salesTeamGroupId: Scalars['ID'];
  /** 資料修改日期 */
  updatedAt: Scalars['DateTime'];
  /** 檢視順序 */
  viewOrder?: Maybe<Scalars['Int']>;
};

export type SalesProductTypeSalesAnalysis = {
  /** 銷售統計 */
  salesStat: SalesProductTypeSalesAnalysisSalesStat;
};

export type SalesProductTypeSalesAnalysisSalesStatArgs = {
  filters: SalesProductTypeSalesAnalysisSalesStatFilterInput;
};

export type SalesProductTypeSalesAnalysisSalesStat = {
  /** 日期區間 */
  dateRange: SalesProductTypeSalesAnalysisSalesStatDateRange;
  /** 細項統計 */
  details: Array<SalesProductTypeSalesAnalysisSalesStatDetail>;
  salesTeamGroup?: Maybe<SalesTeamGroup>;
  /** 業務團隊組織 */
  salesTeamGroupId?: Maybe<Scalars['ID']>;
};

export type SalesProductTypeSalesAnalysisSalesStatDateRange = {
  /** 當期（起） */
  currentDate1: Scalars['String'];
  /** 當期（迄） */
  currentDate2: Scalars['String'];
  /** 去年至去年當天（起） */
  lastYearYtdDate1: Scalars['String'];
  /** 去年至去年當天（迄） */
  lastYearYtdDate2: Scalars['String'];
  /** 前期（起） */
  momDate1: Scalars['String'];
  /** 前期（迄） */
  momDate2: Scalars['String'];
  /** 同期（起） */
  yoyDate1: Scalars['String'];
  /** 同期（迄） */
  yoyDate2: Scalars['String'];
  /** 今年至今（起） */
  ytdDate1: Scalars['String'];
  /** 今年至今（迄） */
  ytdDate2: Scalars['String'];
};

export type SalesProductTypeSalesAnalysisSalesStatDetail = {
  salesProductTypeGroup?: Maybe<SalesProductTypeGroup>;
  /** 商品業績類型群組 */
  salesProductTypeGroupId: Scalars['ID'];
  /** 商品業績類型清單 */
  types: Array<SalesProductTypeSalesAnalysisSalesStatDetailType>;
};

export type SalesProductTypeSalesAnalysisSalesStatDetailType = {
  /** 當期 銷售金額 */
  amountCurrent: Scalars['Int'];
  /** 預測目標金額 */
  amountForecastGoal: Scalars['Int'];
  /** 銷售目標金額 */
  amountGoal: Scalars['Int'];
  /** 去年至去年當天 銷售金額 */
  amountLastYearYtd: Scalars['Int'];
  /** 去年至去年當天 銷售金額成長率（1: 100%, 0.1234: 12.34%, 0: 0%）。分母為 0 時，百分比顯示為空值 */
  amountLastYearYtdGrowthRate?: Maybe<Scalars['Float']>;
  /** 前期 (MOM) 銷售金額 */
  amountMom: Scalars['Int'];
  /** 前期 (MOM) 銷售金額成長率（1: 100%, 0.1234: 12.34%, 0: 0%）。分母為 0 時，百分比顯示為空值 */
  amountMomGrowthRate?: Maybe<Scalars['Float']>;
  /** 同期 (YOY) 銷售金額 */
  amountYoy: Scalars['Int'];
  /** 同期 (YOY) 銷售金額成長率（1: 100%, 0.1234: 12.34%, 0: 0%）。分母為 0 時，百分比顯示為空值 */
  amountYoyGrowthRate?: Maybe<Scalars['Float']>;
  /** 今年至今 (YTD) 銷售金額 */
  amountYtd: Scalars['Int'];
  /** 當期 銷售數量 */
  qtyCurrent: Scalars['Int'];
  /** 預測目標數量 */
  qtyForecastGoal: Scalars['Int'];
  /** 銷售目標數量 */
  qtyGoal: Scalars['Int'];
  /** 去年至去年當天 銷售數量 */
  qtyLastYearYtd: Scalars['Int'];
  /** 去年至去年當天 銷售數量成長率（1: 100%, 0.1234: 12.34%, 0: 0%）。分母為 0 時，百分比顯示為空值 */
  qtyLastYearYtdGrowthRate?: Maybe<Scalars['Float']>;
  /** 前期 (MOM) 銷售數量 */
  qtyMom: Scalars['Int'];
  /** 前期 (MOM) 銷售數量成長率（1: 100%, 0.1234: 12.34%, 0: 0%）。分母為 0 時，百分比顯示為空值 */
  qtyMomGrowthRate?: Maybe<Scalars['Float']>;
  /** 同期 (YOY) 銷售數量 */
  qtyYoy: Scalars['Int'];
  /** 同期 (YOY) 銷售數量成長率（1: 100%, 0.1234: 12.34%, 0: 0%）。分母為 0 時，百分比顯示為空值 */
  qtyYoyGrowthRate?: Maybe<Scalars['Float']>;
  /** 今年至今 (YTD) 銷售數量 */
  qtyYtd: Scalars['Int'];
  salesProductType?: Maybe<SalesProductType>;
  /** 商品業績類型 */
  salesProductTypeId: Scalars['ID'];
};

export type SalesProductTypeSalesAnalysisSalesStatFilterInput = {
  /** 日期（起） */
  date1?: InputMaybe<Scalars['String']>;
  /** 日期（迄） */
  date2?: InputMaybe<Scalars['String']>;
  /** 業務團隊組織 */
  salesTeamGroupId?: InputMaybe<Scalars['ID']>;
};

export type SalesProductUserForecastItem = ISalesProductUserForecastItem & {
  /** 資料新增日期 */
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  /** 月份 */
  month: Scalars['Int'];
  /** 月初預測數量 */
  qtyEarlyMonth: Scalars['Int'];
  /** 月底預測數量 */
  qtyLateMonth: Scalars['Int'];
  /** Quota */
  quota: Scalars['Int'];
  /** 資料修改日期 */
  updatedAt: Scalars['DateTime'];
  /** 單頭 */
  userForecastId: Scalars['ID'];
};

export type SalesProductUserGoalItem = ISalesProductUserGoalItem & {
  /** 金額 */
  amount: Scalars['Int'];
  /** 資料新增日期 */
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  /** 月份 */
  month: Scalars['Int'];
  /** 數量 */
  qty: Scalars['Int'];
  /** 資料修改日期 */
  updatedAt: Scalars['DateTime'];
  /** 單頭 */
  userGoalId: Scalars['ID'];
};

export type SalesTeam = ISalesTeam & {
  /** 下層的業務團隊 */
  children: Array<SalesTeam>;
  /** 編號 */
  code?: Maybe<Scalars['String']>;
  /** 資料新增日期 */
  createdAt: Scalars['DateTime'];
  group?: Maybe<SalesTeamGroup>;
  /** 組織 */
  groupId?: Maybe<Scalars['ID']>;
  id: Scalars['ID'];
  manager: User;
  /** 主管 */
  managerId?: Maybe<Scalars['ID']>;
  /** 名稱 */
  name: Scalars['String'];
  /** 該層底下（包含）所有的 Units */
  nestedUnits: Array<SalesTeamUnit>;
  /** 該層底下（包含）所有的 Users */
  nestedUsers: Array<User>;
  /** 上層的業務團隊 */
  parent?: Maybe<SalesTeam>;
  /** 上層團隊 */
  parentId?: Maybe<Scalars['ID']>;
  region?: Maybe<Region>;
  /** 需要為客戶指定主要負責業務 */
  requiredForPrimaryCustomer: Scalars['Boolean'];
  /** 最高層的業務團隊 */
  top?: Maybe<SalesTeam>;
  /** 業務團隊位置 */
  units: Array<SalesTeamUnit>;
  /** 資料修改日期 */
  updatedAt: Scalars['DateTime'];
  users: Array<User>;
  /** 檢視順序 */
  viewOrder?: Maybe<Scalars['Int']>;
};

export type SalesTeamBulkUpdateInput = {
  salesTeamUpdateInputs?: InputMaybe<Array<SalesTeamUpdateInput>>;
};

export type SalesTeamCreateInput = {
  /** 主管 */
  managerId?: InputMaybe<Scalars['ID']>;
  /** 名稱 */
  name: Scalars['String'];
  /** 上層團隊 */
  parentId?: InputMaybe<Scalars['ID']>;
  /** 需要為客戶指定主要負責業務 */
  requiredForPrimaryCustomer?: InputMaybe<Scalars['Boolean']>;
  /** 組織 */
  salesTeamGroupId: Scalars['ID'];
  /** 業務 */
  userIds?: InputMaybe<Array<Scalars['ID']>>;
  /** 檢視順序 */
  viewOrder?: InputMaybe<Scalars['Int']>;
};

export type SalesTeamGoalItem = ISalesTeamGoalItem & {
  /** 金額 */
  amount: Scalars['Int'];
  /** 資料新增日期 */
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  /** 月份 */
  month: Scalars['Int'];
  /** 單頭 */
  teamGoalId: Scalars['ID'];
  /** 資料修改日期 */
  updatedAt: Scalars['DateTime'];
};

export type SalesTeamGroup = ISalesTeamGroup & {
  /** 編號 */
  code?: Maybe<EnumSalesTeamGroupCode>;
  company?: Maybe<Company>;
  /** 公司 */
  companyId?: Maybe<Scalars['ID']>;
  /** 資料新增日期 */
  createdAt: Scalars['DateTime'];
  customers: Array<Customer>;
  /** 主管 */
  directorId?: Maybe<Scalars['ID']>;
  id: Scalars['ID'];
  /** 名稱 */
  name: Scalars['String'];
  region?: Maybe<Region>;
  /** 區域 */
  regionId?: Maybe<Scalars['ID']>;
  salesTeams: Array<SalesTeam>;
  /** 資料修改日期 */
  updatedAt: Scalars['DateTime'];
  /** 檢視順序 */
  viewOrder?: Maybe<Scalars['Int']>;
};

export type SalesTeamGroupSearchInput = {
  /** 編號 */
  code?: InputMaybe<EnumSalesTeamGroupCode>;
  /** 主管 */
  directorId?: InputMaybe<Scalars['ID']>;
  ids?: InputMaybe<Array<Scalars['ID']>>;
  regionIds?: InputMaybe<Array<Scalars['ID']>>;
};

export type SalesTeamGroupSearchResult = {
  count: Scalars['Int'];
  pageInfo: PageInfo;
  rows: Array<SalesTeamGroup>;
};

export type SalesTeamGroupUpdateInput = {
  /** 主管 */
  directorId?: InputMaybe<Scalars['ID']>;
  id: Scalars['ID'];
  /** 名稱 */
  name?: InputMaybe<Scalars['String']>;
  /** 檢視順序 */
  viewOrder?: InputMaybe<Scalars['Int']>;
};

export type SalesTeamSearchInput = {
  ids?: InputMaybe<Array<Scalars['ID']>>;
  /** 是否查詢業務團隊Root */
  isRoot?: InputMaybe<Scalars['Boolean']>;
  /** 主管 */
  managerId?: InputMaybe<Scalars['ID']>;
  /** 名稱 */
  name?: InputMaybe<Scalars['String']>;
  /** 上層組織 */
  parentId?: InputMaybe<Scalars['ID']>;
  regionIds?: InputMaybe<Array<Scalars['ID']>>;
  /** 需要為客戶指定主要負責業務 */
  requiredForPrimaryCustomer?: InputMaybe<Scalars['Boolean']>;
  salesTeamGroupIds?: InputMaybe<Array<Scalars['ID']>>;
  /** 業務 */
  userIds?: InputMaybe<Array<Scalars['ID']>>;
};

export type SalesTeamSearchResult = {
  count: Scalars['Int'];
  pageInfo: PageInfo;
  rows: Array<SalesTeam>;
};

export type SalesTeamUnit = ISalesTeamUnit & {
  /** 資料新增日期 */
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  /** 名稱 */
  name: Scalars['String'];
  /** 業務團隊 */
  salesTeam?: Maybe<SalesTeam>;
  /** 業務團隊 */
  salesTeamId: Scalars['ID'];
  /** 資料修改日期 */
  updatedAt: Scalars['DateTime'];
  /** 負責業務 */
  user?: Maybe<User>;
  /** 負責業務 */
  userId?: Maybe<Scalars['ID']>;
  /** 檢視順序 */
  viewOrder?: Maybe<Scalars['Int']>;
};

export type SalesTeamUnitBulkUpdateInput = {
  salesTeamUnitUpdateInputs?: InputMaybe<Array<SalesTeamUnitUpdateInput>>;
};

export type SalesTeamUnitCreateInput = {
  /** 名稱 */
  name: Scalars['String'];
  /** 業務團隊 */
  salesTeamId: Scalars['ID'];
  /** 負責業務 */
  userId?: InputMaybe<Scalars['ID']>;
  /** 檢視順序 */
  viewOrder?: InputMaybe<Scalars['Int']>;
};

export type SalesTeamUnitSearchInput = {
  /** 客戶 */
  customerId?: InputMaybe<Scalars['ID']>;
  ids?: InputMaybe<Array<Scalars['ID']>>;
  /** 名稱 */
  name?: InputMaybe<Scalars['String']>;
  /** 業務團隊(複數) */
  salesTeamUnitIds?: InputMaybe<Array<Scalars['ID']>>;
  /** 負責業務(複數) */
  userIds?: InputMaybe<Array<Scalars['ID']>>;
};

export type SalesTeamUnitSearchResult = {
  count: Scalars['Int'];
  pageInfo: PageInfo;
  rows: Array<SalesTeamUnit>;
};

export type SalesTeamUnitUpdateInput = {
  id: Scalars['ID'];
  /** 名稱 */
  name?: InputMaybe<Scalars['String']>;
  /** 業務團隊 */
  salesTeamId?: InputMaybe<Scalars['ID']>;
  /** 負責業務 */
  userId?: InputMaybe<Scalars['ID']>;
  /** 檢視順序 */
  viewOrder?: InputMaybe<Scalars['Int']>;
};

export type SalesTeamUpdateInput = {
  id: Scalars['ID'];
  /** 是否為業務團隊Root */
  isRoot?: InputMaybe<Scalars['Boolean']>;
  /** 主管 */
  managerId?: InputMaybe<Scalars['ID']>;
  /** 名稱 */
  name?: InputMaybe<Scalars['String']>;
  /** 上層團隊 */
  parentId?: InputMaybe<Scalars['ID']>;
  /** 需要為客戶指定主要負責業務 */
  requiredForPrimaryCustomer?: InputMaybe<Scalars['Boolean']>;
  /** 業務 */
  userIds?: InputMaybe<Array<Scalars['ID']>>;
  /** 檢視順序 */
  viewOrder?: InputMaybe<Scalars['Int']>;
};

export type SalesType = ISalesType & {
  /** 編號 */
  code?: Maybe<Scalars['String']>;
  /** 資料新增日期 */
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  /** 名稱 */
  name: Scalars['String'];
  salesTeamGroup?: Maybe<SalesTeamGroup>;
  /** 業務團隊組織 */
  salesTeamGroupId: Scalars['ID'];
  /** 資料修改日期 */
  updatedAt: Scalars['DateTime'];
  /** 檢視順序 */
  viewOrder?: Maybe<Scalars['Int']>;
};

export type SalesUserForecastItem = ISalesUserForecastItem & {
  /** 月初預測金額 */
  amountEarlyMonth: Scalars['Int'];
  /** 月底預測金額 */
  amountLateMonth: Scalars['Int'];
  /** 資料新增日期 */
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  /** 月份 */
  month: Scalars['Int'];
  /** Quota */
  quota: Scalars['Int'];
  /** 資料修改日期 */
  updatedAt: Scalars['DateTime'];
  /** 單頭 */
  userForecastId: Scalars['ID'];
};

export type SalesUserGoalItem = ISalesUserGoalItem & {
  /** 金額 */
  amount: Scalars['Int'];
  /** 資料新增日期 */
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  /** 月份 */
  month: Scalars['Int'];
  /** 資料修改日期 */
  updatedAt: Scalars['DateTime'];
  /** 單頭 */
  userGoalId: Scalars['ID'];
};

export type ShippingMethod = IShippingMethod & {
  code: Scalars['String'];
  companyId: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  isActive: Scalars['Boolean'];
  name: Scalars['String'];
  region?: Maybe<Region>;
  regionId: Scalars['ID'];
  updatedAt: Scalars['DateTime'];
  viewOrder?: Maybe<Scalars['Int']>;
};

export type ShippingMethodSearchInput = {
  id?: InputMaybe<Scalars['ID']>;
  regionIds?: InputMaybe<Array<Scalars['ID']>>;
};

export type ShippingMethodSearchResult = {
  count: Scalars['Int'];
  pageInfo: PageInfo;
  rows: Array<ShippingMethod>;
};

export type Store = IStore & {
  address?: Maybe<Scalars['String']>;
  areaId?: Maybe<Scalars['ID']>;
  cityId?: Maybe<Scalars['ID']>;
  /** 閉幕日期 */
  closingDate?: Maybe<Scalars['Date']>;
  code: Scalars['String'];
  company?: Maybe<Company>;
  companyId?: Maybe<Scalars['ID']>;
  createdAt: Scalars['DateTime'];
  createdUserId?: Maybe<Scalars['ID']>;
  departmentId?: Maybe<Scalars['ID']>;
  districtId?: Maybe<Scalars['ID']>;
  email?: Maybe<Scalars['String']>;
  /** 統編 */
  guiNumber?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  /** 是否為維修據點 */
  isMaintenanceCenter: Scalars['Boolean'];
  /** 是否有POS */
  isPosAvailable: Scalars['Boolean'];
  /** 是否顯示於Morear合作夥伴 */
  isShowInMorear: Scalars['Boolean'];
  /** 是否顯示在睡得美 （ ResMed ） 預約頁面 */
  isShowInResmedBooking: Scalars['Boolean'];
  /** 緯度 */
  lat?: Maybe<Scalars['Float']>;
  /** 經度 */
  lng?: Maybe<Scalars['Float']>;
  name: Scalars['String'];
  /** 開幕日期 */
  openingDate?: Maybe<Scalars['Date']>;
  phone?: Maybe<Scalars['String']>;
  /** 圖片（連結） */
  picture?: Maybe<Scalars['String']>;
  regionId: Scalars['ID'];
  /** 睡眠技師 */
  sleepTechnologistUserId?: Maybe<Scalars['ID']>;
  updatedAt: Scalars['DateTime'];
  updatedUserId?: Maybe<Scalars['ID']>;
  zone2Id?: Maybe<Scalars['ID']>;
  zoneId?: Maybe<Scalars['ID']>;
};

export type StoreSearchInput = {
  companyIds?: InputMaybe<Array<Scalars['ID']>>;
  id?: InputMaybe<Scalars['ID']>;
  regionIds?: InputMaybe<Array<Scalars['ID']>>;
};

export type StoreSearchResult = {
  count: Scalars['Int'];
  pageInfo: PageInfo;
  rows: Array<Store>;
};

export type Subscription = {
  menuBadgeSubscription: MenuBadge;
  notificationSubscription: Notification;
};

export type Supplier = ISupplier & {
  code: Scalars['String'];
  companyId: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  createdUser?: Maybe<User>;
  createdUserId?: Maybe<Scalars['ID']>;
  id: Scalars['ID'];
  /** 名稱 */
  name: Scalars['String'];
  /** 簡稱 */
  name2?: Maybe<Scalars['String']>;
  properties: Array<SupplierProperty>;
  region?: Maybe<Region>;
  regionId: Scalars['ID'];
  updatedAt: Scalars['DateTime'];
  updatedUser?: Maybe<User>;
  updatedUserId?: Maybe<Scalars['ID']>;
};

export type SupplierProperty = ISupplierProperty & {
  code: Scalars['String'];
  companyId: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  name: Scalars['String'];
  region?: Maybe<Region>;
  regionId: Scalars['ID'];
  updatedAt: Scalars['DateTime'];
  values: Array<Scalars['String']>;
};

export type SupplierPropertySearchInput = {
  code?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['ID']>;
  regionIds?: InputMaybe<Array<Scalars['ID']>>;
  supplierId?: InputMaybe<Scalars['ID']>;
};

export type SupplierPropertySearchResult = {
  count: Scalars['Int'];
  pageInfo: PageInfo;
  rows: Array<SupplierProperty>;
};

export type SupplierSearchInput = {
  code?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['ID']>;
  name?: InputMaybe<Scalars['String']>;
  regionIds?: InputMaybe<Array<Scalars['ID']>>;
};

export type SupplierSearchResult = {
  count: Scalars['Int'];
  pageInfo: PageInfo;
  rows: Array<Supplier>;
};

export type SyncSource = ISyncSource & {
  code?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  name: Scalars['String'];
};

export type TaxRate = ITaxRate & {
  createdAt?: Maybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  rate: Scalars['Float'];
  region?: Maybe<Region>;
  regionId: Scalars['ID'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type TaxRateCreateInput = {
  regionId: Scalars['ID'];
  taxRate: Scalars['Float'];
};

export type TaxRateSearchInput = {
  id?: InputMaybe<Scalars['ID']>;
  regionIds?: InputMaybe<Array<Scalars['ID']>>;
};

export type TaxRateSearchResult = {
  count: Scalars['Int'];
  pageInfo: PageInfo;
  rows: Array<TaxRate>;
};

export type Token = IToken & {
  createdAt: Scalars['DateTime'];
  expiredAt?: Maybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  ip?: Maybe<Scalars['String']>;
  userAgent?: Maybe<Scalars['String']>;
  userAgentDetails: UserAgents;
  userCode: Scalars['String'];
};

export type User = IUser & {
  active?: Maybe<Scalars['String']>;
  /** 驗證方式(1. LDAP, 2. password) */
  authType: Scalars['Int'];
  code?: Maybe<Scalars['String']>;
  company?: Maybe<Company>;
  companyId?: Maybe<Scalars['ID']>;
  createdAt: Scalars['DateTime'];
  departmentId?: Maybe<Scalars['ID']>;
  dept?: Maybe<Department>;
  email?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  /** 在職狀態 */
  isActive: Scalars['Boolean'];
  /** 聽力師 */
  isAudiologist: Scalars['Boolean'];
  /** 助聽器工程師 */
  isHearingAidEngineer: Scalars['Boolean'];
  isLocked: Scalars['Boolean'];
  /** 外包人員 */
  isOutsourcing: Scalars['Boolean'];
  /** 使用系統，最後選擇的門市 */
  lastSelectStoreId?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
  region?: Maybe<Region>;
  regionId: Scalars['ID'];
  roles: Array<Role>;
  salesTeamUnits?: Maybe<Array<SalesTeamUnit>>;
  salesTeams?: Maybe<Array<SalesTeam>>;
  ssoTokens: Array<Token>;
  store?: Maybe<Store>;
  storeId?: Maybe<Scalars['ID']>;
  updatedAt: Scalars['DateTime'];
};

export type UserAgents = {
  /** 瀏覽器名稱 */
  browserName?: Maybe<Scalars['String']>;
  /** 瀏覽器版本 */
  browserVersion?: Maybe<Scalars['String']>;
  /** 裝置型號 */
  deviceModel?: Maybe<Scalars['String']>;
  /** 裝置類別 */
  deviceType?: Maybe<Scalars['String']>;
  /** 裝置品牌 */
  deviceVendor?: Maybe<Scalars['String']>;
  /** 作業系統名稱 */
  osName?: Maybe<Scalars['String']>;
  /** 作業系統版本 */
  osVersion?: Maybe<Scalars['String']>;
};

export type UserLoginResult = {
  code: Scalars['String'];
  email: Scalars['String'];
  id: Scalars['ID'];
  name: Scalars['String'];
  token: Scalars['JWT'];
};

export type UserPermission = {
  /** 允許的公司ids */
  allowCompanyIds: Array<Scalars['ID']>;
  /** 允許的PM團隊ids */
  allowProductTeamIds: Array<Scalars['ID']>;
  /** 允許的區域ids */
  allowRegionIds: Array<Scalars['ID']>;
  /** 允許的業務團隊組織ids */
  allowSalesTeamGroupIds: Array<Scalars['ID']>;
  /** 允許的業務團隊ids */
  allowSalesTeamIds: Array<Scalars['ID']>;
  /** 允許的使用者ids */
  allowUserIds: Array<Scalars['ID']>;
  /** 權限codes */
  codes: Array<Scalars['String']>;
};

export type UserSearchInput = {
  code?: InputMaybe<Scalars['String']>;
  email?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['ID']>;
  /** 是否在職 */
  isActive?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
  regionIds?: InputMaybe<Array<Scalars['ID']>>;
  /** 角色 */
  roleIds?: InputMaybe<Array<Scalars['ID']>>;
  salesTeamGroupId?: InputMaybe<Scalars['ID']>;
  /** 模糊搜尋: 使用者姓名、使用者編號、門市名稱、門市編號 */
  searchAll?: InputMaybe<Scalars['String']>;
};

export type UserSearchResult = {
  count: Scalars['Int'];
  pageInfo: PageInfo;
  rows: Array<User>;
};

export type Visit = IVisit & {
  action?: Maybe<VisitAction>;
  /** 行為內容 */
  actionContent?: Maybe<Scalars['String']>;
  /** 行為 */
  actionId?: Maybe<Scalars['ID']>;
  assistedVisitSupervisor?: Maybe<User>;
  /** 協訪主管 */
  assistedVisitSupervisorId?: Maybe<Scalars['ID']>;
  business?: Maybe<Business>;
  /** 商機 */
  businessId?: Maybe<Scalars['ID']>;
  checkIns: Array<VisitCheckIn>;
  /** 內容 */
  content?: Maybe<Scalars['String']>;
  /** 資料新增日期 */
  createdAt: Scalars['DateTime'];
  createdUser?: Maybe<User>;
  /** 資料建立人員 */
  createdUserId?: Maybe<Scalars['ID']>;
  customer?: Maybe<Customer>;
  /** 客戶 */
  customerId?: Maybe<Scalars['ID']>;
  /** 預估刀量 */
  estimatedSurgeryCount?: Maybe<Scalars['String']>;
  goals: Array<VisitGoal>;
  /** 是否主管協訪 */
  hasAssistedVisitSupervisor: Scalars['Boolean'];
  id: Scalars['ID'];
  memos: Array<VisitMemo>;
  mentionProducts: Array<BusinessProduct>;
  primaryContactPeople: Array<ContactPerson>;
  /** @deprecated 改用 salesTeamUnit */
  primaryUser?: Maybe<User>;
  /** 主要負責業務（棄用；改用 sales_team_unit_id ） */
  primaryUserId?: Maybe<Scalars['ID']>;
  /** 優先順序 */
  priorityOrder?: Maybe<Scalars['Int']>;
  properties: Array<VisitProperty>;
  region?: Maybe<Region>;
  rentFixedAssets: Array<EyeFixedAsset>;
  /** @deprecated 改用 rentFixedAssets */
  rentProducts: Array<BusinessProduct>;
  salesTeam?: Maybe<SalesTeam>;
  salesTeamGroup?: Maybe<SalesTeamGroup>;
  /** 業務團隊組織 */
  salesTeamGroupId: Scalars['ID'];
  /** 負責業務團隊 */
  salesTeamId?: Maybe<Scalars['ID']>;
  salesTeamUnit?: Maybe<SalesTeamUnit>;
  /** 業務團隊單位 */
  salesTeamUnitId?: Maybe<Scalars['ID']>;
  /** 狀態 */
  status: EnumVisitStatus;
  /** 實際刀量 */
  surgeryCount?: Maybe<Scalars['String']>;
  timePeriods: Array<VisitTimePeriod>;
  /** 標題 */
  title?: Maybe<Scalars['String']>;
  type?: Maybe<VisitType>;
  /** 類型 */
  typeId?: Maybe<Scalars['ID']>;
  /** 資料修改日期 */
  updatedAt: Scalars['DateTime'];
  updatedUser?: Maybe<User>;
  /** 資料修改人員 */
  updatedUserId?: Maybe<Scalars['ID']>;
  /** 拜訪地址 */
  visitAddress?: Maybe<Scalars['String']>;
  /** 拜訪日期 */
  visitDate: Scalars['Date'];
};

export type VisitAction = IVisitAction & {
  /** 資料新增日期 */
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  /** 名稱 */
  name: Scalars['String'];
  region?: Maybe<Region>;
  salesTeamGroup?: Maybe<SalesTeamGroup>;
  /** 業務團隊組織 */
  salesTeamGroupId: Scalars['ID'];
  /** 資料修改日期 */
  updatedAt: Scalars['DateTime'];
  /** 檢視順序 */
  viewOrder?: Maybe<Scalars['Int']>;
};

export type VisitActionCreateInput = {
  name: Scalars['String'];
  salesTeamGroupId: Scalars['ID'];
  viewOrder?: InputMaybe<Scalars['Int']>;
};

export type VisitActionSearchInput = {
  id?: InputMaybe<Scalars['ID']>;
  regionIds?: InputMaybe<Array<Scalars['ID']>>;
  salesTeamGroupIds?: InputMaybe<Array<Scalars['ID']>>;
};

export type VisitActionSearchResult = {
  count: Scalars['Int'];
  pageInfo: PageInfo;
  rows: Array<VisitAction>;
};

export type VisitActionUpdateInput = {
  id: Scalars['ID'];
  name?: InputMaybe<Scalars['String']>;
  viewOrder?: InputMaybe<Scalars['Int']>;
};

export type VisitCheckIn = IVisitCheckIn & {
  /** 內容 */
  content?: Maybe<Scalars['String']>;
  /** 資料新增日期 */
  createdAt: Scalars['DateTime'];
  createdUser?: Maybe<User>;
  /** 資料建立人員 */
  createdUserId?: Maybe<Scalars['ID']>;
  id: Scalars['ID'];
  /** WGS84 緯度 */
  lat: Scalars['Float'];
  /** WGS84 經度 */
  lng: Scalars['Float'];
  /** 資料修改日期 */
  updatedAt: Scalars['DateTime'];
  updatedUser?: Maybe<User>;
  /** 資料修改人員 */
  updatedUserId?: Maybe<Scalars['ID']>;
  visit?: Maybe<Visit>;
  /** 拜訪 */
  visitId: Scalars['ID'];
};

export type VisitCheckInCreateInput = {
  /** 內容 */
  content?: InputMaybe<Scalars['String']>;
  /** WGS84 緯度 */
  lat: Scalars['Float'];
  /** WGS84 經度 */
  lng: Scalars['Float'];
  /** 拜訪 */
  visitId: Scalars['ID'];
};

export type VisitCheckInSearchInput = {
  id?: InputMaybe<Scalars['ID']>;
  regionIds?: InputMaybe<Array<Scalars['ID']>>;
  /** 拜訪 */
  visitId?: InputMaybe<Scalars['ID']>;
};

export type VisitCheckInSearchResult = {
  count: Scalars['Int'];
  pageInfo: PageInfo;
  rows: Array<VisitCheckIn>;
};

export type VisitCheckInUpdateInput = {
  /** 內容 */
  content?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
};

export type VisitCreateInput = {
  /** 行為內容 */
  actionContent?: InputMaybe<Scalars['ID']>;
  /** 行為 */
  actionId?: InputMaybe<Scalars['ID']>;
  /** 協訪主管 */
  assistedVisitSupervisorId?: InputMaybe<Scalars['ID']>;
  /** 商機 */
  businessId?: InputMaybe<Scalars['ID']>;
  /** 內容 */
  content?: InputMaybe<Scalars['String']>;
  /** 客戶 */
  customerId?: InputMaybe<Scalars['ID']>;
  /** 預估刀量 */
  estimatedSurgeryCount?: InputMaybe<Scalars['String']>;
  /** 目標 */
  goalIds?: InputMaybe<Array<Scalars['ID']>>;
  /** 是否主管協訪 */
  hasAssistedVisitSupervisor?: InputMaybe<Scalars['Boolean']>;
  /** 涉及商品 */
  mentionProductIds?: InputMaybe<Array<Scalars['ID']>>;
  /** 主要聯絡人 */
  primaryContactPersonIds?: InputMaybe<Array<Scalars['ID']>>;
  /** 優先順序 */
  priorityOrder?: InputMaybe<Scalars['Float']>;
  /** 屬性 */
  propertyIds?: InputMaybe<Array<Scalars['ID']>>;
  /** 借用固定資產 */
  rentFixedAssetIds?: InputMaybe<Array<Scalars['ID']>>;
  /** 業務團隊組織 */
  salesTeamGroupId: Scalars['ID'];
  /** 業務團隊 */
  salesTeamId?: InputMaybe<Scalars['ID']>;
  /** 業務團隊位置 */
  salesTeamUnitId?: InputMaybe<Scalars['ID']>;
  /** 狀態 */
  status?: EnumVisitStatus;
  /** 實際刀量 */
  surgeryCount?: InputMaybe<Scalars['String']>;
  /** 時段 */
  timePeriodIds?: InputMaybe<Array<Scalars['ID']>>;
  /** 標題 */
  title?: InputMaybe<Scalars['String']>;
  /** 類型 */
  typeId?: InputMaybe<Scalars['ID']>;
  /** 拜訪地址 */
  visitAddress?: InputMaybe<Scalars['String']>;
  /** 拜訪日期 */
  visitDate: Scalars['Date'];
};

export type VisitGoal = IVisitGoal & {
  /** 資料新增日期 */
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  /** 名稱 */
  name: Scalars['String'];
  region?: Maybe<Region>;
  salesTeamGroup?: Maybe<SalesTeamGroup>;
  /** 業務團隊組織 */
  salesTeamGroupId: Scalars['ID'];
  /** 資料修改日期 */
  updatedAt: Scalars['DateTime'];
  /** 檢視順序 */
  viewOrder?: Maybe<Scalars['Int']>;
};

export type VisitGoalCreateInput = {
  name: Scalars['String'];
  salesTeamGroupId: Scalars['ID'];
  viewOrder?: InputMaybe<Scalars['Int']>;
};

export type VisitGoalSearchInput = {
  id?: InputMaybe<Scalars['ID']>;
  regionIds?: InputMaybe<Array<Scalars['ID']>>;
  salesTeamGroupIds?: InputMaybe<Array<Scalars['ID']>>;
};

export type VisitGoalSearchResult = {
  count: Scalars['Int'];
  pageInfo: PageInfo;
  rows: Array<VisitGoal>;
};

export type VisitGoalUpdateInput = {
  id: Scalars['ID'];
  name?: InputMaybe<Scalars['String']>;
  viewOrder?: InputMaybe<Scalars['Int']>;
};

export type VisitMemo = IVisitMemo & {
  /** 內容 */
  content?: Maybe<Scalars['String']>;
  /** 資料新增日期 */
  createdAt: Scalars['DateTime'];
  createdUser?: Maybe<User>;
  /** 資料建立人員 */
  createdUserId?: Maybe<Scalars['ID']>;
  id: Scalars['ID'];
  /** 標題 */
  title?: Maybe<Scalars['String']>;
  /** 資料修改日期 */
  updatedAt: Scalars['DateTime'];
  updatedUser?: Maybe<User>;
  /** 資料修改人員 */
  updatedUserId?: Maybe<Scalars['ID']>;
  visit?: Maybe<Visit>;
  /** 拜訪 */
  visitId: Scalars['ID'];
};

export type VisitMemoCreateInput = {
  content?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
  visitId?: InputMaybe<Scalars['ID']>;
};

export type VisitMemoSearchInput = {
  id?: InputMaybe<Scalars['ID']>;
  title?: InputMaybe<Scalars['ID']>;
  visitId?: InputMaybe<Scalars['ID']>;
};

export type VisitMemoSearchResult = {
  count: Scalars['Int'];
  pageInfo: PageInfo;
  rows: Array<VisitMemo>;
};

export type VisitMemoUpdateInput = {
  content?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
  title?: InputMaybe<Scalars['String']>;
  visitId?: InputMaybe<Scalars['ID']>;
};

export type VisitProperty = IVisitProperty & {
  /** 資料新增日期 */
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  /** 名稱 */
  name: Scalars['String'];
  region?: Maybe<Region>;
  salesTeamGroup?: Maybe<SalesTeamGroup>;
  /** 業務團隊組織 */
  salesTeamGroupId: Scalars['ID'];
  type?: Maybe<VisitPropertyType>;
  /** 類型 */
  typeId: Scalars['ID'];
  /** 資料修改日期 */
  updatedAt: Scalars['DateTime'];
  /** 檢視順序 */
  viewOrder?: Maybe<Scalars['Int']>;
};

export type VisitPropertyCreateInput = {
  name: Scalars['String'];
  salesTeamGroupId: Scalars['ID'];
  typeId: Scalars['ID'];
  viewOrder?: InputMaybe<Scalars['Int']>;
};

export type VisitPropertySearchInput = {
  id?: InputMaybe<Scalars['ID']>;
  regionIds?: InputMaybe<Array<Scalars['ID']>>;
  salesTeamGroupIds?: InputMaybe<Array<Scalars['ID']>>;
  typeId?: InputMaybe<Scalars['ID']>;
};

export type VisitPropertySearchResult = {
  count: Scalars['Int'];
  pageInfo: PageInfo;
  rows: Array<VisitProperty>;
};

export type VisitPropertyType = IVisitPropertyType & {
  /** 編號 */
  code?: Maybe<Scalars['String']>;
  /** 資料新增日期 */
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  /** 名稱 */
  name: Scalars['String'];
  properties?: Maybe<Array<VisitProperty>>;
  region?: Maybe<Region>;
  salesTeamGroup?: Maybe<SalesTeamGroup>;
  /** 業務團隊組織 */
  salesTeamGroupId: Scalars['ID'];
  /** 資料修改日期 */
  updatedAt: Scalars['DateTime'];
  /** 檢視順序 */
  viewOrder?: Maybe<Scalars['Int']>;
};

export type VisitPropertyTypeCreateInput = {
  code: Scalars['String'];
  name: Scalars['String'];
  salesTeamGroupId: Scalars['ID'];
  viewOrder?: InputMaybe<Scalars['Int']>;
};

export type VisitPropertyTypeSearchInput = {
  code?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['ID']>;
  regionIds?: InputMaybe<Array<Scalars['ID']>>;
  salesTeamGroupIds?: InputMaybe<Array<Scalars['ID']>>;
};

export type VisitPropertyTypeSearchResult = {
  count: Scalars['Int'];
  pageInfo: PageInfo;
  rows: Array<VisitPropertyType>;
};

export type VisitPropertyTypeUpdateInput = {
  id: Scalars['ID'];
  name?: InputMaybe<Scalars['String']>;
  viewOrder?: InputMaybe<Scalars['Int']>;
};

export type VisitPropertyUpdateInput = {
  id: Scalars['ID'];
  name?: InputMaybe<Scalars['String']>;
  typeId?: InputMaybe<Scalars['ID']>;
  viewOrder?: InputMaybe<Scalars['Int']>;
};

export type VisitSearchInput = {
  actionId?: InputMaybe<Scalars['ID']>;
  /** 協訪主管 */
  assistedVisitSupervisorId?: InputMaybe<Scalars['ID']>;
  businessId?: InputMaybe<Scalars['ID']>;
  /** 客戶編號 */
  customerCode?: InputMaybe<Scalars['String']>;
  /** 客戶id */
  customerId?: InputMaybe<Scalars['ID']>;
  /** 客戶醫事機構代碼 */
  customerMedicalCode?: InputMaybe<Scalars['String']>;
  /** 客戶名稱 */
  customerName?: InputMaybe<Scalars['String']>;
  /** 是否主管協訪 */
  hasAssistedVisitSupervisor?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['ID']>;
  /** 涉及商品 */
  mentionProductIds?: InputMaybe<Array<Scalars['ID']>>;
  primaryUserId?: InputMaybe<Scalars['ID']>;
  /** 屬性 */
  propertyIds?: InputMaybe<Array<Scalars['ID']>>;
  /** 屬性類型 */
  propertyTypeIds?: InputMaybe<Array<Scalars['ID']>>;
  regionIds?: InputMaybe<Array<Scalars['ID']>>;
  salesTeamGroupIds?: InputMaybe<Array<Scalars['ID']>>;
  salesTeamId?: InputMaybe<Scalars['ID']>;
  /** 狀態 */
  statuses?: InputMaybe<Array<EnumVisitStatus>>;
  /** 時段 */
  timePeriodIds?: InputMaybe<Array<Scalars['ID']>>;
  typeId?: InputMaybe<Scalars['ID']>;
  /** 拜訪日期(起) */
  visitDate1?: InputMaybe<Scalars['Date']>;
  /** 拜訪日期(迄) */
  visitDate2?: InputMaybe<Scalars['Date']>;
};

export type VisitSearchResult = {
  count: Scalars['Int'];
  pageInfo: PageInfo;
  rows: Array<Visit>;
};

export type VisitTimePeriod = IVisitTimePeriod & {
  /** 資料新增日期 */
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  /** 名稱 */
  name: Scalars['String'];
  region?: Maybe<Region>;
  salesTeamGroup?: Maybe<SalesTeamGroup>;
  /** 業務團隊組織 */
  salesTeamGroupId: Scalars['ID'];
  /** 資料修改日期 */
  updatedAt: Scalars['DateTime'];
  /** 檢視順序 */
  viewOrder?: Maybe<Scalars['Int']>;
};

export type VisitTimePeriodCreateInput = {
  name: Scalars['String'];
  salesTeamGroupId: Scalars['ID'];
  viewOrder?: InputMaybe<Scalars['Int']>;
};

export type VisitTimePeriodSearchInput = {
  id?: InputMaybe<Scalars['ID']>;
  regionIds?: InputMaybe<Array<Scalars['ID']>>;
  salesTeamGroupIds?: InputMaybe<Array<Scalars['ID']>>;
};

export type VisitTimePeriodSearchResult = {
  count: Scalars['Int'];
  pageInfo: PageInfo;
  rows: Array<VisitTimePeriod>;
};

export type VisitTimePeriodUpdateInput = {
  id: Scalars['ID'];
  name?: InputMaybe<Scalars['String']>;
  viewOrder?: InputMaybe<Scalars['Int']>;
};

export type VisitType = IVisitType & {
  /** 編號 */
  code?: Maybe<EnumVisitTypeCode>;
  /** 資料新增日期 */
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  /** 名稱 */
  name: Scalars['String'];
  region?: Maybe<Region>;
  salesTeamGroup?: Maybe<SalesTeamGroup>;
  /** 業務團隊組織 */
  salesTeamGroupId: Scalars['ID'];
  /** 資料修改日期 */
  updatedAt: Scalars['DateTime'];
  /** 檢視順序 */
  viewOrder?: Maybe<Scalars['Int']>;
  visit: Array<Visit>;
};

export type VisitTypeCreateInput = {
  name: Scalars['String'];
  salesTeamGroupId: Scalars['ID'];
  viewOrder?: InputMaybe<Scalars['Int']>;
};

export type VisitTypeSearchInput = {
  /** 編號 */
  codes?: InputMaybe<Array<EnumVisitTypeCode>>;
  id?: InputMaybe<Scalars['ID']>;
  regionIds?: InputMaybe<Array<Scalars['ID']>>;
  salesTeamGroupIds?: InputMaybe<Array<Scalars['ID']>>;
};

export type VisitTypeSearchResult = {
  count: Scalars['Int'];
  pageInfo: PageInfo;
  rows: Array<VisitType>;
};

export type VisitTypeUpdateInput = {
  id: Scalars['ID'];
  name?: InputMaybe<Scalars['String']>;
  viewOrder?: InputMaybe<Scalars['Int']>;
};

export type VisitUpdateInput = {
  /** 行為內容 */
  actionContent?: InputMaybe<Scalars['ID']>;
  /** 行為 */
  actionId?: InputMaybe<Scalars['ID']>;
  /** 協訪主管 */
  assistedVisitSupervisorId?: InputMaybe<Scalars['ID']>;
  /** 商機 */
  businessId?: InputMaybe<Scalars['ID']>;
  /** 內容 */
  content?: InputMaybe<Scalars['String']>;
  /** 客戶 */
  customerId?: InputMaybe<Scalars['ID']>;
  /** 預估刀量 */
  estimatedSurgeryCount?: InputMaybe<Scalars['String']>;
  /** 目標 */
  goalIds?: InputMaybe<Array<Scalars['ID']>>;
  /** 是否主管協訪 */
  hasAssistedVisitSupervisor?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['ID']>;
  /** 涉及商品 */
  mentionProductIds?: InputMaybe<Array<Scalars['ID']>>;
  /** 主要聯絡人 */
  primaryContactPersonIds?: InputMaybe<Array<Scalars['ID']>>;
  /** 優先順序 */
  priorityOrder?: InputMaybe<Scalars['Float']>;
  /** 屬性 */
  propertyIds?: InputMaybe<Array<Scalars['ID']>>;
  /** 借用固定資產 */
  rentFixedAssetIds?: InputMaybe<Array<Scalars['ID']>>;
  /** 業務團隊 */
  salesTeamId?: InputMaybe<Scalars['ID']>;
  /** 業務團隊位置 */
  salesTeamUnitId?: InputMaybe<Scalars['ID']>;
  /** 狀態 */
  status?: InputMaybe<EnumVisitStatus>;
  /** 實際刀量 */
  surgeryCount?: InputMaybe<Scalars['String']>;
  /** 時段 */
  timePeriodIds?: InputMaybe<Array<Scalars['ID']>>;
  /** 標題 */
  title?: InputMaybe<Scalars['String']>;
  /** 類型 */
  typeId?: InputMaybe<Scalars['ID']>;
  /** 拜訪地址 */
  visitAddress?: InputMaybe<Scalars['String']>;
  /** 拜訪日期 */
  visitDate?: InputMaybe<Scalars['Date']>;
};

export type Warehouse = IWarehouse & {
  /** 編號 */
  code?: Maybe<Scalars['String']>;
  /** 資料新增日期 */
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  /** 是否啟用 */
  isActive: Scalars['Boolean'];
  /** 名稱 */
  name: Scalars['String'];
  region?: Maybe<Region>;
  /** 區域 */
  regionId?: Maybe<Scalars['ID']>;
  /** 資料修改日期 */
  updatedAt: Scalars['DateTime'];
};

export type WarehouseCreateInput = {
  /** 編號 */
  code?: InputMaybe<Scalars['String']>;
  /** 是否啟用 */
  isActive?: InputMaybe<Scalars['Boolean']>;
  /** 名稱 */
  name: Scalars['String'];
  /** 區域 */
  regionId: Scalars['ID'];
};

export type WarehouseSearchInput = {
  /** 編號 */
  code?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['ID']>;
  /** 是否啟用 */
  isActive?: InputMaybe<Scalars['Boolean']>;
  /** 名稱 */
  name?: InputMaybe<Scalars['String']>;
  /** 區域 */
  regionIds?: InputMaybe<Array<Scalars['ID']>>;
};

export type WarehouseSearchResult = {
  count: Scalars['Int'];
  pageInfo: PageInfo;
  rows: Array<Warehouse>;
};

export type WarehouseUpdateInput = {
  /** 編號 */
  code?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
  /** 是否啟用 */
  isActive?: InputMaybe<Scalars['Boolean']>;
  /** 名稱 */
  name?: InputMaybe<Scalars['String']>;
  /** 區域 */
  regionId?: InputMaybe<Scalars['ID']>;
};

export type Zone = IZone & {
  company?: Maybe<Company>;
  companyId?: Maybe<Scalars['ID']>;
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  name: Scalars['String'];
  region?: Maybe<Region>;
  regionId: Scalars['ID'];
  stores?: Maybe<Array<Store>>;
  updatedAt: Scalars['DateTime'];
  viewOrder: Scalars['Int'];
  zone2s?: Maybe<Array<Zone2>>;
};

export type Zone2 = IZone2 & {
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  name: Scalars['String'];
  regionId: Scalars['ID'];
  stores?: Maybe<Array<Store>>;
  updatedAt: Scalars['DateTime'];
  viewOrder: Scalars['Int'];
  zoneId?: Maybe<Scalars['ID']>;
};

export type Zone2SearchInput = {
  id?: InputMaybe<Scalars['ID']>;
  regionIds?: InputMaybe<Array<Scalars['ID']>>;
};

export type Zone2SearchResult = {
  count: Scalars['Int'];
  pageInfo: PageInfo;
  rows: Array<Zone2>;
};

export type ZoneSearchInput = {
  id?: InputMaybe<Scalars['ID']>;
  regionIds?: InputMaybe<Array<Scalars['ID']>>;
};

export type ZoneSearchResult = {
  count: Scalars['Int'];
  pageInfo: PageInfo;
  rows: Array<Zone>;
};

export type MenuBadgeQueryVariables = Exact<{ [key: string]: never }>;

export type MenuBadgeQuery = {
  menuBadge: {
    eyeServiceOrderWaitForAcceptance?: number | null;
    eyeServiceOrderWaitForApproval?: number | null;
    eyeServiceOrderWaitForAssign?: number | null;
  };
};

export type MenuBadgeSubscriptionSubscriptionVariables = Exact<{
  [key: string]: never;
}>;

export type MenuBadgeSubscriptionSubscription = {
  menuBadgeSubscription: {
    eyeServiceOrderWaitForAcceptance?: number | null;
    eyeServiceOrderWaitForApproval?: number | null;
    eyeServiceOrderWaitForAssign?: number | null;
  };
};

export type SalesPerformanceSalesProductTypeGroupsQueryVariables = Exact<{
  filters: SalesPerformanceSalesProductTypeGroupSearchInput;
  offset?: InputMaybe<Scalars['Int']>;
  limit?: InputMaybe<Scalars['Int']>;
}>;

export type SalesPerformanceSalesProductTypeGroupsQuery = {
  salesPerformanceSalesProductTypeGroups: {
    count: number;
    rows: Array<{
      id: string;
      salesTeamGroupId: string;
      name: string;
      code?: string | null;
      viewOrder?: number | null;
      createdAt: any;
      updatedAt: any;
      salesTeamGroup?: {
        code?: EnumSalesTeamGroupCode | null;
        id: string;
        name: string;
      } | null;
    }>;
  };
};

export type SalesProductTypeSalesAnalysisQueryVariables = Exact<{
  filters: SalesProductTypeSalesAnalysisSalesStatFilterInput;
}>;

export type SalesProductTypeSalesAnalysisQuery = {
  salesProductTypeSalesAnalysis: {
    salesStat: {
      salesTeamGroupId?: string | null;
      dateRange: {
        currentDate1: string;
        currentDate2: string;
        yoyDate1: string;
        yoyDate2: string;
        momDate1: string;
        momDate2: string;
        ytdDate1: string;
        ytdDate2: string;
        lastYearYtdDate1: string;
        lastYearYtdDate2: string;
      };
      details: Array<{
        salesProductTypeGroupId: string;
        types: Array<{
          salesProductTypeId: string;
          amountGoal: number;
          amountForecastGoal: number;
          amountCurrent: number;
          amountYoy: number;
          amountYoyGrowthRate?: number | null;
          amountMom: number;
          amountMomGrowthRate?: number | null;
          amountYtd: number;
          amountLastYearYtd: number;
          amountLastYearYtdGrowthRate?: number | null;
          qtyGoal: number;
          qtyForecastGoal: number;
          qtyCurrent: number;
          qtyYoy: number;
          qtyYoyGrowthRate?: number | null;
          qtyMom: number;
          qtyMomGrowthRate?: number | null;
          qtyYtd: number;
          qtyLastYearYtd: number;
          qtyLastYearYtdGrowthRate?: number | null;
          salesProductType?: {
            id: string;
            name: string;
            code?: string | null;
          } | null;
        }>;
        salesProductTypeGroup?: {
          id: string;
          name: string;
          code?: string | null;
        } | null;
      }>;
    };
  };
};

export type EcDashboardAnalysisQueryVariables = Exact<{
  salesMaterialRankFilters2?: InputMaybe<EcDashboardAnalysisSalesMaterialRankFilterInput>;
  filters?: InputMaybe<EcDashboardAnalysisSalesCustomerRankFilterInput>;
  visitCustomerRankFilters2?: InputMaybe<EcDashboardAnalysisVisitCustomerRankFilterInput>;
  visitGoalRankFilters2?: InputMaybe<EcDashboardAnalysisVisitGoalRankFilterInput>;
  visitMentionProductRankFilters2?: InputMaybe<EcDashboardAnalysisVisitMentionProductRankFilterInput>;
  visitsByDayOfWeekFilters2?: InputMaybe<EcDashboardAnalysisVisitByDayOfWeekFilterInput>;
  visitStatFilters2: EcDashboardAnalysisVisitStatFilterInput;
  salesStatFilters2?: InputMaybe<EcDashboardAnalysisSalesStatFilterInput>;
}>;

export type EcDashboardAnalysisQuery = {
  EcDashboardAnalysis: {
    salesCustomerRank: {
      dateRange: {
        currentDate1: string;
        yoyDate1: string;
        lastYearYoyDate1: string;
        currentDate2: string;
        yoyDate2: string;
        lastYearYoyDate2: string;
      };
      details: Array<{
        seq: number;
        rank: number;
        customerCode?: string | null;
        customerName?: string | null;
        amountCurrent: number;
        amountYoy: number;
        amountLastYearYoy: number;
        qtyCurrent: number;
        qtyLastYearYoy: number;
        qtyYoy: number;
        visitCurrent: number;
        visitLastYearYoy: number;
        visitYoy: number;
      }>;
    };
    salesMaterialRank: {
      dateRange: {
        currentDate1: string;
        yoyDate1: string;
        lastYearYoyDate1: string;
        currentDate2: string;
        yoyDate2: string;
        lastYearYoyDate2: string;
      };
      details: Array<{
        seq: number;
        rank: number;
        materialCode?: string | null;
        materialName?: string | null;
        amountCurrent: number;
        amountYoy: number;
        amountLastYearYoy: number;
        qtyCurrent: number;
        qtyLastYearYoy: number;
        qtyYoy: number;
      }>;
    };
    visitCustomerRank: {
      dateRange: {
        currentDate1: string;
        yoyDate1: string;
        currentDate2: string;
        yoyDate2: string;
      };
      details: Array<{
        seq: number;
        rank: number;
        customerId: string;
        visitCountCurrent: number;
        visitCountYoy: number;
        customer?: { id: string; name: string } | null;
      }>;
    };
    visitMentionProductRank: {
      dateRange: {
        currentDate1: string;
        yoyDate1: string;
        currentDate2: string;
        yoyDate2: string;
      };
      details: Array<{
        seq: number;
        rank: number;
        mentionProductId: string;
        visitCountCurrent: number;
        visitCountYoy: number;
        mentionProduct?: { id: string; name: string } | null;
      }>;
    };
    visitGoalRank: {
      dateRange: {
        currentDate1: string;
        yoyDate1: string;
        currentDate2: string;
        yoyDate2: string;
      };
      details: Array<{
        seq: number;
        rank: number;
        goalId: string;
        visitCountCurrent: number;
        visitCountYoy: number;
        goal?: { id: string; name: string } | null;
      }>;
    };
    visitsByDayOfWeek: {
      details: Array<{
        dayOfWeek: EnumVisitByDayOfWeek;
        visitCountCurrent: number;
      }>;
    };
    salesStat: {
      salesTeamGroupId?: string | null;
      dateRange: {
        currentDate1: string;
        currentDate2: string;
        yoyDate1: string;
        yoyDate2: string;
        momDate1: string;
        momDate2: string;
        ytdDate1: string;
        ytdDate2: string;
        lastYearYtdDate1: string;
        lastYearYtdDate2: string;
      };
      details: Array<{
        salesTypeId: string;
        amountCurrent: number;
        amountGoalCurrent?: number | null;
        amountGoalCurrentAchievementRate?: number | null;
        amountYoy: number;
        amountYoyGrowthRate?: number | null;
        amountMom: number;
        amountMomGrowthRate?: number | null;
        amountYtd: number;
        amountLastYearYtd: number;
        amountLastYearYtdGrowthRate?: number | null;
        salesType?: { name: string; code?: string | null } | null;
      }>;
    };
    visitStat: {
      salesTeamGroupId?: string | null;
      dateRange: {
        currentDate1: string;
        currentDate2: string;
        yoyDate1: string;
        yoyDate2: string;
        momDate1: string;
        momDate2: string;
        ytdDate1: string;
        ytdDate2: string;
        lastYearYtdDate1: string;
        lastYearYtdDate2: string;
      };
      overall: {
        visitCountCurrent: number;
        visitCountYoy: number;
        visitCountYoyGrowthRate?: number | null;
        visitCountMom: number;
        visitCountMomGrowthRate?: number | null;
        visitCountYtd: number;
        visitCountLastYearYtd: number;
        visitCountLastYearYtdGrowthRate?: number | null;
      };
    };
  };
};

export type EcDashboardAnalysisDetailQueryVariables = Exact<{
  filters: EcDashboardAnalysisSalesDetailFilterInput;
}>;

export type EcDashboardAnalysisDetailQuery = {
  EcDashboardAnalysisDetail: {
    salesTeamGroup?: { id: string; name: string } | null;
    salesType?: { id: string; name: string } | null;
    details: Array<{
      companyCode: EnumEnumEcDashboardAnalysisSalesCompanyCode;
      salesType: EnumEcDashboardAnalysisOrderType;
      salesOrderCode?: string | null;
      salesDate?: string | null;
      salesCustomerCode?: string | null;
      salesCustomerName?: string | null;
      accountCustomerCode?: string | null;
      accountCustomerName?: string | null;
      materialCode?: string | null;
      materialName?: string | null;
      qty?: number | null;
      untaxedAmount?: number | null;
      taxedAmount?: number | null;
      userCode?: string | null;
      userName?: string | null;
    }>;
  };
};

export type BulkUpdateSalesPerformanceSalesProductOverallViewMutationVariables =
  Exact<{
    input: SalesPerformanceSalesProductOverallViewBulkUpdateInput;
  }>;

export type BulkUpdateSalesPerformanceSalesProductOverallViewMutation = {
  bulkUpdateSalesPerformanceSalesProductOverallView: {
    forecasts: Array<{
      id: string;
      unitPrice: number;
      qty: number;
      amount: number;
    }>;
    goals: Array<{
      id: string;
      unitPrice: number;
      qty: number;
      amount: number;
    }>;
  };
};

export type SalesPerformanceSalesProductOverallViewQueryVariables = Exact<{
  salesTeamGroupId: Scalars['ID'];
  year: Scalars['Int'];
}>;

export type SalesPerformanceSalesProductOverallViewQuery = {
  salesPerformanceSalesProductOverallView: {
    year: number;
    salesTeamGroupId?: string | null;
    overall: {
      forecastAmount: number;
      forecastUnitPrice: number;
      forecastQty: number;
      salesAmount: number;
      salesUnitPrice: number;
      salesQty: number;
      currentAmount: number;
      currentUnitPrice: number;
      currentQty: number;
      goalAmount: number;
      goalAmountAchievementRate?: number | null;
      goalUnitPrice: number;
      goalUnitPriceAchievementRate?: number | null;
      goalQty: number;
      goalQtyAchievementRate?: number | null;
      ytdCurrentAmount: number;
      ytdCurrentUnitPrice: number;
      ytdCurrentQty: number;
      ytdGoalAmount: number;
      ytdGoalAmountAchievementRate?: number | null;
      ytdGoalUnitPrice: number;
      ytdGoalUnitPriceAchievementRate?: number | null;
      ytdGoalQty: number;
      ytdGoalQtyAchievementRate?: number | null;
      months: Array<{
        month: number;
        tense: EnumSalesProductOverallViewTense;
        forecastAmount: number;
        forecastUnitPrice: number;
        forecastQty: number;
        salesAmount: number;
        salesUnitPrice: number;
        salesQty: number;
        currentAmount: number;
        currentUnitPrice: number;
        currentQty: number;
        goalAmount: number;
        goalUnitPrice: number;
        goalQty: number;
      }>;
    };
    types: Array<{
      salesProductTypeId: string;
      forecastAmount: number;
      forecastUnitPrice: number;
      forecastQty: number;
      salesAmount: number;
      salesUnitPrice: number;
      salesQty: number;
      currentAmount: number;
      currentUnitPrice: number;
      currentQty: number;
      goalAmount: number;
      goalAmountAchievementRate?: number | null;
      goalUnitPrice: number;
      goalUnitPriceAchievementRate?: number | null;
      goalQty: number;
      goalQtyAchievementRate?: number | null;
      ytdCurrentAmount: number;
      ytdCurrentUnitPrice: number;
      ytdCurrentQty: number;
      ytdGoalAmount: number;
      ytdGoalAmountAchievementRate?: number | null;
      ytdGoalUnitPrice: number;
      ytdGoalUnitPriceAchievementRate?: number | null;
      ytdGoalQty: number;
      ytdGoalQtyAchievementRate?: number | null;
      months: Array<{
        month: number;
        tense: EnumSalesProductOverallViewTense;
        forecastAmount: number;
        forecastUnitPrice: number;
        forecastQty: number;
        salesAmount: number;
        salesUnitPrice: number;
        salesQty: number;
        currentAmount: number;
        currentUnitPrice: number;
        currentQty: number;
        goalAmount: number;
        goalUnitPrice: number;
        goalQty: number;
      }>;
      salesProductType?: {
        id: string;
        code?: string | null;
        name: string;
      } | null;
    }>;
    salesTeamGroup?: {
      id: string;
      name: string;
      code?: EnumSalesTeamGroupCode | null;
      salesTeams: Array<{ code?: string | null; id: string; name: string }>;
    } | null;
  };
};

export type NavOrdersQueryVariables = Exact<{
  filters: NavOrderSearchInput;
  offset?: InputMaybe<Scalars['Int']>;
  limit?: InputMaybe<Scalars['Int']>;
}>;

export type NavOrdersQuery = {
  navOrders: {
    count: number;
    rows: Array<{
      code: string;
      approvalCode?: string | null;
      orderDate: any;
      amount: number;
      shipInvoicedAmount: number;
      businesses: Array<{ code?: string | null }>;
      billCustomer?: {
        code?: string | null;
        navCode?: string | null;
        name: string;
        address?: string | null;
      } | null;
      sellCustomer?: {
        code?: string | null;
        navCode?: string | null;
        name: string;
        address?: string | null;
      } | null;
      user?: { code?: string | null; name?: string | null } | null;
      details: Array<{
        materialCode: string;
        materialName: string;
        qty: number;
        amount: number;
      }>;
    }>;
  };
};

export type NavSalesInvoicesQueryVariables = Exact<{
  filters: NavSalesInvoiceSearchInput;
  offset?: InputMaybe<Scalars['Int']>;
  limit?: InputMaybe<Scalars['Int']>;
}>;

export type NavSalesInvoicesQuery = {
  navSalesInvoices: {
    count: number;
    rows: Array<{
      code: string;
      orderCode: string;
      approvalCode?: string | null;
      type: EnumNavSalesInvoiceType;
      postingDate: any;
      amount: number;
      billCustomer?: {
        code?: string | null;
        navCode?: string | null;
        name: string;
        address?: string | null;
      } | null;
      sellCustomer?: {
        code?: string | null;
        navCode?: string | null;
        name: string;
        address?: string | null;
      } | null;
      user?: { code?: string | null; name?: string | null } | null;
      details: Array<{
        materialCode?: string | null;
        materialName?: string | null;
        qty?: number | null;
        amount?: number | null;
      }>;
    }>;
  };
};

export type BusinessAnalysisRankQueryVariables = Exact<{
  regionIds?: InputMaybe<Array<Scalars['ID']> | Scalars['ID']>;
  primaryUserIds?: InputMaybe<Array<Scalars['ID']> | Scalars['ID']>;
  expectedClosedDate1?: InputMaybe<Scalars['Date']>;
  expectedClosedDate2?: InputMaybe<Scalars['Date']>;
  budgetProductIds?: InputMaybe<Array<Scalars['ID']> | Scalars['ID']>;
  salesTeamTopId?: InputMaybe<Scalars['ID']>;
}>;

export type BusinessAnalysisRankQuery = {
  businessAnalysis: {
    High: Array<{
      rank: number;
      qty: number;
      businessProductId: string;
      businessProduct: {
        id: string;
        name: string;
        isActive: boolean;
        salesTeams: Array<{
          id: string;
          name: string;
          children: Array<{ id: string; name: string }>;
        }>;
      };
    }>;
    MediumHigh: Array<{
      rank: number;
      qty: number;
      businessProductId: string;
      businessProduct: {
        id: string;
        name: string;
        salesTeams: Array<{
          id: string;
          name: string;
          children: Array<{ id: string; name: string }>;
        }>;
      };
    }>;
    Medium: Array<{
      rank: number;
      qty: number;
      businessProductId: string;
      businessProduct: {
        id: string;
        name: string;
        salesTeams: Array<{
          id: string;
          name: string;
          children: Array<{ id: string; name: string }>;
        }>;
      };
    }>;
  };
};

export type BusinessAnalysisRankSalesTeamQueryVariables = Exact<{
  regionIds?: InputMaybe<Array<Scalars['ID']> | Scalars['ID']>;
  primaryUserIds?: InputMaybe<Array<Scalars['ID']> | Scalars['ID']>;
  expectedClosedDate1?: InputMaybe<Scalars['Date']>;
  expectedClosedDate2?: InputMaybe<Scalars['Date']>;
  budgetProductIds?: InputMaybe<Array<Scalars['ID']> | Scalars['ID']>;
  salesTeamTopId?: InputMaybe<Scalars['ID']>;
}>;

export type BusinessAnalysisRankSalesTeamQuery = {
  businessAnalysis: {
    High: Array<{
      rank: number;
      qty: number;
      businessProductId: string;
      businessProduct: {
        id: string;
        name: string;
        salesTeams: Array<{ id: string; name: string }>;
      };
    }>;
    MediumHigh: Array<{
      rank: number;
      qty: number;
      businessProductId: string;
      businessProduct: {
        id: string;
        name: string;
        salesTeams: Array<{ id: string; name: string }>;
      };
    }>;
    Medium: Array<{
      rank: number;
      qty: number;
      businessProductId: string;
      businessProduct: {
        id: string;
        name: string;
        salesTeams: Array<{ id: string; name: string }>;
      };
    }>;
  };
};

export type BusinessAnalysisStatQueryVariables = Exact<{
  regionIds?: InputMaybe<Array<Scalars['ID']> | Scalars['ID']>;
  primaryUserIds?: InputMaybe<Array<Scalars['ID']> | Scalars['ID']>;
  expectedClosedDate1?: InputMaybe<Scalars['Date']>;
  expectedClosedDate2?: InputMaybe<Scalars['Date']>;
  budgetProductIds?: InputMaybe<Array<Scalars['ID']> | Scalars['ID']>;
}>;

export type BusinessAnalysisStatQuery = {
  businessAnalysis: {
    High: Array<{
      seq: number;
      month1: number;
      month2: number;
      month3: number;
      month4: number;
      month5: number;
      month6: number;
      month7: number;
      month8: number;
      month9: number;
      month10: number;
      month11: number;
      month12: number;
      year: string;
      total: number;
      businessProductId: string;
      businessProduct: { id: string; name: string };
    }>;
    MediumHigh: Array<{
      seq: number;
      month1: number;
      month2: number;
      month3: number;
      month4: number;
      month5: number;
      month6: number;
      month7: number;
      month8: number;
      month9: number;
      month10: number;
      month11: number;
      month12: number;
      year: string;
      total: number;
      businessProductId: string;
      businessProduct: { id: string; name: string };
    }>;
    Medium: Array<{
      seq: number;
      month1: number;
      month2: number;
      month3: number;
      month4: number;
      month5: number;
      month6: number;
      month7: number;
      month8: number;
      month9: number;
      month10: number;
      month11: number;
      month12: number;
      year: string;
      total: number;
      businessProductId: string;
      businessProduct: { id: string; name: string };
    }>;
  };
};

export type BusinessAnalysisQueryVariables = Exact<{
  filters?: InputMaybe<BusinessAnalysisBusinessStatFilterInput>;
}>;

export type BusinessAnalysisQuery = {
  businessAnalysis: {
    businessStat: {
      details: Array<{
        type: EnumBusinessStatType;
        countPercent?: number | null;
        count: number;
      }>;
    };
  };
};

export type CreateBusinessProductMutationVariables = Exact<{
  input: BusinessProductCreateInput;
}>;

export type CreateBusinessProductMutation = {
  createBusinessProduct: { id: string };
};

export type UpdateBusinessProductMutationVariables = Exact<{
  input: BusinessProductUpdateInput;
}>;

export type UpdateBusinessProductMutation = {
  updateBusinessProduct: { id: string };
};

export type BusinessProductsQueryVariables = Exact<{
  filters: BusinessProductSearchInput;
  offset?: InputMaybe<Scalars['Int']>;
  limit?: InputMaybe<Scalars['Int']>;
}>;

export type BusinessProductsQuery = {
  businessProducts: {
    count: number;
    rows: Array<{
      id: string;
      brand?: string | null;
      name: string;
      isActive: boolean;
      salesTeamGroup?: { id: string } | null;
      materials: Array<{ id: string; code: string; name: string }>;
      salesTeams: Array<{ id: string; name: string }>;
      productTeams: Array<{ id: string; name: string }>;
    }>;
  };
};

export type EyeFixedAssetTypesQueryVariables = Exact<{
  filters: EyeFixedAssetTypeSearchInput;
  limit?: InputMaybe<Scalars['Int']>;
}>;

export type EyeFixedAssetTypesQuery = {
  eyeFixedAssetTypes: {
    rows: Array<{ id: string; name: string; code: EnumEyeFixedAssetTypeCode }>;
  };
};

export type DeptsQueryVariables = Exact<{
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  filters: DeptSearchInput;
}>;

export type DeptsQuery = {
  depts: {
    count: number;
    pageInfo: { hasNextPage: boolean };
    rows: Array<{
      id: string;
      companyId?: string | null;
      name: string;
      code?: string | null;
      createdAt: any;
      updatedAt: any;
      regionId: string;
      setOfBook?: string | null;
      isActive: boolean;
      company?: {
        id: string;
        code?: string | null;
        name: string;
        createdAt: any;
        updatedAt: any;
        regionId: string;
      } | null;
      users?: Array<{
        id: string;
        code?: string | null;
        name?: string | null;
        dept?: { id: string; name: string; code?: string | null } | null;
        company?: { id: string; name: string } | null;
      }> | null;
      children: Array<{
        id: string;
        name: string;
        code?: string | null;
        children: Array<{
          id: string;
          name: string;
          code?: string | null;
          children: Array<{
            id: string;
            name: string;
            code?: string | null;
            children: Array<{
              id: string;
              name: string;
              code?: string | null;
              children: Array<{
                id: string;
                name: string;
                code?: string | null;
                children: Array<{
                  id: string;
                  name: string;
                  code?: string | null;
                  company?: {
                    id: string;
                    code?: string | null;
                    name: string;
                  } | null;
                  users?: Array<{
                    id: string;
                    code?: string | null;
                    name?: string | null;
                    dept?: {
                      id: string;
                      name: string;
                      code?: string | null;
                    } | null;
                    company?: { id: string; name: string } | null;
                  }> | null;
                }>;
                company?: {
                  id: string;
                  code?: string | null;
                  name: string;
                } | null;
                users?: Array<{
                  id: string;
                  code?: string | null;
                  name?: string | null;
                  dept?: {
                    id: string;
                    name: string;
                    code?: string | null;
                  } | null;
                  company?: { id: string; name: string } | null;
                }> | null;
              }>;
              company?: {
                id: string;
                code?: string | null;
                name: string;
              } | null;
              users?: Array<{
                id: string;
                code?: string | null;
                name?: string | null;
                dept?: {
                  id: string;
                  name: string;
                  code?: string | null;
                } | null;
                company?: { id: string; name: string } | null;
              }> | null;
            }>;
            company?: { id: string; code?: string | null; name: string } | null;
            users?: Array<{
              id: string;
              code?: string | null;
              name?: string | null;
              dept?: { id: string; name: string; code?: string | null } | null;
              company?: { id: string; name: string } | null;
            }> | null;
          }>;
          company?: { id: string; code?: string | null; name: string } | null;
          users?: Array<{
            id: string;
            code?: string | null;
            name?: string | null;
            dept?: { id: string; name: string; code?: string | null } | null;
            company?: { id: string; name: string } | null;
          }> | null;
        }>;
        company?: { id: string; code?: string | null; name: string } | null;
        users?: Array<{
          id: string;
          code?: string | null;
          name?: string | null;
          dept?: { id: string; name: string; code?: string | null } | null;
          company?: { id: string; name: string } | null;
        }> | null;
      }>;
    }>;
  };
};

export type ChildrensFragment = {
  id: string;
  name: string;
  code?: string | null;
  company?: { id: string; code?: string | null; name: string } | null;
  users?: Array<{
    id: string;
    code?: string | null;
    name?: string | null;
    dept?: { id: string; name: string; code?: string | null } | null;
    company?: { id: string; name: string } | null;
  }> | null;
};

export type EyeProductSelectQueryVariables = Exact<{
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  filters: EyeProductSearchInput;
}>;

export type EyeProductSelectQuery = {
  eyeProducts: { rows: Array<{ id: string; name: string }> };
};

export type EyeProductTypesQueryVariables = Exact<{
  filters: EyeProductTypeSearchInput;
}>;

export type EyeProductTypesQuery = {
  eyeProductTypes: {
    count: number;
    pageInfo: { hasNextPage: boolean };
    rows: Array<{ id: string; regionId: string; name: string }>;
  };
};

export type CurrenciesQueryVariables = Exact<{
  filters: CurrencySearchInput;
}>;

export type CurrenciesQuery = {
  currencies: {
    count: number;
    pageInfo: { hasNextPage: boolean };
    rows: Array<{ id: string; name: string; code: string }>;
  };
};

export type CustomersSelectQueryVariables = Exact<{
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  filters: CustomerSearchInput;
}>;

export type CustomersSelectQuery = {
  customers: {
    count: number;
    rows: Array<{
      id: string;
      code?: string | null;
      name: string;
      address?: string | null;
      contactPersonName?: string | null;
      contactPersonPhone?: string | null;
      provinceId?: string | null;
      cityId?: string | null;
      districtId?: string | null;
      creditQuota?: number | null;
      isGmp?: boolean | null;
      province?: {
        id: string;
        name?: string | null;
        cities: Array<{
          id: string;
          name: string;
          districts: Array<{ id: string; name: string }>;
        }>;
      } | null;
      region?: {
        id: string;
        name: string;
        taxRates: Array<{ id: string; regionId: string; rate: number }>;
        localCurrency?: { id: string; name: string; code: string } | null;
      } | null;
      contactPeople: Array<{ id: string; name: string }>;
      eyeWarrantyContracts: Array<{ id: string; code: string }>;
      eyeServiceOrders: Array<{ id: string; code: string }>;
      salesTeamGroup?: {
        id: string;
        code?: EnumSalesTeamGroupCode | null;
      } | null;
    }>;
  };
};

export type CustomersCodeSelectQueryVariables = Exact<{
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  filters: CustomerSearchInput;
}>;

export type CustomersCodeSelectQuery = {
  customers: {
    count: number;
    rows: Array<{
      id: string;
      code?: string | null;
      name: string;
      address?: string | null;
      contactPersonName?: string | null;
      contactPersonPhone?: string | null;
      districtId?: string | null;
      creditQuota?: number | null;
      contactPeople: Array<{ id: string; name: string }>;
      eyeWarrantyContracts: Array<{ id: string; code: string }>;
    }>;
  };
};

export type CustomerTypeCodeSelectQueryVariables = Exact<{
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  filters: CustomerSearchInput;
}>;

export type CustomerTypeCodeSelectQuery = {
  customers: {
    count: number;
    rows: Array<{ id: string; code?: string | null; name: string }>;
  };
};

export type EyeProductItemTypesQueryVariables = Exact<{
  filters: EyeProductItemTypeSearchInput;
}>;

export type EyeProductItemTypesQuery = {
  eyeProductItemTypes: {
    count: number;
    pageInfo: { hasNextPage: boolean };
    rows: Array<{
      id: string;
      name: string;
      viewOrder?: number | null;
      category: number;
    }>;
  };
};

export type PaymentMethodsQueryVariables = Exact<{
  filters: PaymentMethodSearchInput;
}>;

export type PaymentMethodsQuery = {
  paymentMethods: { rows: Array<{ id: string; name: string }> };
};

export type RegionsQueryVariables = Exact<{
  filters: RegionSearchInput;
}>;

export type RegionsQuery = {
  regions: {
    count: number;
    rows: Array<{
      id: string;
      code?: string | null;
      name: string;
      createdAt: any;
      updatedAt: any;
      taxRates: Array<{ id: string; regionId: string; rate: number }>;
      localCurrency?: { id: string; name: string; code: string } | null;
    }>;
  };
};

export type MaterialTypesQueryVariables = Exact<{
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  filters: MaterialTypeSearchInput;
}>;

export type MaterialTypesQuery = {
  materialTypes: {
    rows: Array<{ id: string; code: string; companyId: string; name: string }>;
  };
};

export type SalesTeamsPaginationQueryVariables = Exact<{
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  filters: SalesTeamSearchInput;
}>;

export type SalesTeamsPaginationQuery = {
  salesTeams: {
    count: number;
    rows: Array<{
      id: string;
      name: string;
      users: Array<{ id: string; code?: string | null; name?: string | null }>;
      children: Array<{
        id: string;
        name: string;
        code?: string | null;
        children: Array<{
          id: string;
          name: string;
          code?: string | null;
          children: Array<{
            id: string;
            name: string;
            code?: string | null;
            children: Array<{
              id: string;
              name: string;
              code?: string | null;
              children: Array<{
                id: string;
                name: string;
                code?: string | null;
                children: Array<{
                  id: string;
                  name: string;
                  code?: string | null;
                  users: Array<{
                    id: string;
                    name?: string | null;
                    code?: string | null;
                  }>;
                }>;
                users: Array<{
                  id: string;
                  name?: string | null;
                  code?: string | null;
                }>;
              }>;
              users: Array<{
                id: string;
                name?: string | null;
                code?: string | null;
              }>;
            }>;
            users: Array<{
              id: string;
              name?: string | null;
              code?: string | null;
            }>;
          }>;
          users: Array<{
            id: string;
            name?: string | null;
            code?: string | null;
          }>;
        }>;
        users: Array<{
          id: string;
          name?: string | null;
          code?: string | null;
        }>;
      }>;
    }>;
  };
};

export type TeamsFragment = {
  id: string;
  name: string;
  code?: string | null;
  children: Array<{
    id: string;
    name: string;
    code?: string | null;
    children: Array<{
      id: string;
      name: string;
      code?: string | null;
      children: Array<{
        id: string;
        name: string;
        code?: string | null;
        children: Array<{
          id: string;
          name: string;
          code?: string | null;
          children: Array<{
            id: string;
            name: string;
            code?: string | null;
            children: Array<{
              id: string;
              name: string;
              code?: string | null;
              users: Array<{
                id: string;
                name?: string | null;
                code?: string | null;
              }>;
            }>;
            users: Array<{
              id: string;
              name?: string | null;
              code?: string | null;
            }>;
          }>;
          users: Array<{
            id: string;
            name?: string | null;
            code?: string | null;
          }>;
        }>;
        users: Array<{
          id: string;
          name?: string | null;
          code?: string | null;
        }>;
      }>;
      users: Array<{ id: string; name?: string | null; code?: string | null }>;
    }>;
    users: Array<{ id: string; name?: string | null; code?: string | null }>;
  }>;
  users: Array<{ id: string; name?: string | null; code?: string | null }>;
};

export type ChildrenFragment = {
  id: string;
  name: string;
  code?: string | null;
  users: Array<{ id: string; name?: string | null; code?: string | null }>;
};

export type CreateCompetitorMutationVariables = Exact<{
  input: CompetitorCreateInput;
}>;

export type CreateCompetitorMutation = {
  createCompetitor: { id: string; name: string };
};

export type UpdateCompetitorMutationVariables = Exact<{
  input: CompetitorUpdateInput;
}>;

export type UpdateCompetitorMutation = {
  updateCompetitor: { id: string; name: string };
};

export type UpdateBusinessMutationVariables = Exact<{
  input: BusinessUpdateInput;
}>;

export type UpdateBusinessMutation = { updateBusiness: { id: string } };

export type CreateBusinessMutationVariables = Exact<{
  input: BusinessCreateInput;
}>;

export type CreateBusinessMutation = {
  createBusiness: { id: string; code?: string | null };
};

export type BatchUpdateBusinessSalesTeamUnitMutationVariables = Exact<{
  input: BusinessSalesTeamUnitBatchUpdateInput;
}>;

export type BatchUpdateBusinessSalesTeamUnitMutation = {
  batchUpdateBusinessSalesTeamUnit: Array<{ id: string }>;
};

export type DeleteBusinessMutationVariables = Exact<{
  deleteBusinessId: Scalars['ID'];
}>;

export type DeleteBusinessMutation = { deleteBusiness: boolean };

export type BusinessesQueryVariables = Exact<{
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  filters: BusinessSearchInput;
  sorts?: InputMaybe<Array<BusinessSortInput> | BusinessSortInput>;
}>;

export type BusinessesQuery = {
  businesses: {
    count: number;
    rows: Array<{
      id: string;
      code?: string | null;
      title?: string | null;
      content?: string | null;
      eyeQuotationOrderCode?: string | null;
      budgetAmount?: number | null;
      dealAmount?: number | null;
      expectedClosedDate?: any | null;
      closedDate?: any | null;
      orderCode?: string | null;
      losingReason?: string | null;
      losingImprovementPlan?: string | null;
      createdAt: any;
      updatedAt: any;
      salesMethodId?: string | null;
      dealerId?: string | null;
      salesTeamUnitId?: string | null;
      type?: { id: string; name: string } | null;
      salesTeamGroup?: {
        id: string;
        code?: EnumSalesTeamGroupCode | null;
        name: string;
      } | null;
      winningOpportunity?: {
        id: string;
        code?: string | null;
        name: string;
      } | null;
      buyingOpportunity?: {
        id: string;
        code?: string | null;
        name: string;
      } | null;
      status?: {
        id: string;
        name: string;
        type?: EnumBusinessStatusType | null;
        buyingOpportunity?: string | null;
      } | null;
      customer?: {
        id: string;
        code?: string | null;
        name: string;
        address?: string | null;
        region?: { id: string; name: string } | null;
      } | null;
      primaryUser?: {
        id: string;
        code?: string | null;
        name?: string | null;
        dept?: { id: string; name: string } | null;
        salesTeams?: Array<{
          id: string;
          name: string;
          top?: { id: string; name: string } | null;
        }> | null;
      } | null;
      primaryContactPeople: Array<{ id: string; name: string }>;
      createdUser?: {
        id: string;
        name?: string | null;
        code?: string | null;
      } | null;
      updatedUser?: {
        id: string;
        name?: string | null;
        code?: string | null;
      } | null;
      businessesToDealProducts: Array<{
        id: string;
        dealProductId: string;
        dealProduct?: { id: string; name: string } | null;
      }>;
      competitors: Array<{ id: string; name: string }>;
      businessesToBudgetProducts: Array<{
        id: string;
        budgetProductId: string;
        qty: number;
        budgetProduct?: {
          id: string;
          name: string;
          isActive: boolean;
          brand?: string | null;
        } | null;
      }>;
      businessesUsers: Array<{
        id: string;
        userId: string;
        salesTeamId?: string | null;
        user?: { id: string; name?: string | null } | null;
        salesTeam?: { id: string; name: string } | null;
      }>;
      losingReasons: Array<{ id: string; name: string }>;
      properties: Array<{ id: string }>;
      salesMethod?: { id: string; name: string } | null;
      salesTeamUnit?: { id: string; name: string } | null;
    }>;
  };
};

export type BusinessOpportunitiesQueryVariables = Exact<{
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  filters: BusinessOpportunitySearchInput;
}>;

export type BusinessOpportunitiesQuery = {
  businessOpportunities: {
    count: number;
    rows: Array<{
      id: string;
      name: string;
      code?: string | null;
      enabled: boolean;
      salesTeamGroup?: { id: string; name: string } | null;
    }>;
  };
};

export type BusinessStatusesQueryVariables = Exact<{
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  filters: BusinessStatusSearchInput;
}>;

export type BusinessStatusesQuery = {
  businessStatuses: {
    count: number;
    rows: Array<{
      name: string;
      id: string;
      type?: EnumBusinessStatusType | null;
      buyingOpportunity?: string | null;
      salesTeamGroup?: { id: string; name: string } | null;
    }>;
  };
};

export type BusinessLosingReasonsQueryVariables = Exact<{
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  filters: BusinessLosingReasonSearchInput;
}>;

export type BusinessLosingReasonsQuery = {
  businessLosingReasons: {
    count: number;
    rows: Array<{ name: string; id: string; salesTeamGroupId: string }>;
  };
};

export type CompetitorsQueryVariables = Exact<{
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  filters: CompetitorSearchInput;
}>;

export type CompetitorsQuery = {
  competitors: {
    count: number;
    rows: Array<{
      name: string;
      id: string;
      advantage?: string | null;
      disadvantage?: string | null;
      strategy?: string | null;
      memo?: string | null;
      brand?: string | null;
      model?: string | null;
      agents?: string | null;
      createdAt: any;
      updatedAt: any;
      salesTeamGroup?: { id: string; name: string } | null;
      businessProducts: Array<{ id: string; name: string }>;
      createdUser?: { id: string; name?: string | null } | null;
      updatedUser?: { id: string; name?: string | null } | null;
      region?: { id: string; name: string } | null;
    }>;
  };
};

export type BusinessPropertyTypesQueryVariables = Exact<{
  filters: BusinessPropertyTypeSearchInput;
  limit?: InputMaybe<Scalars['Int']>;
}>;

export type BusinessPropertyTypesQuery = {
  businessPropertyTypes: {
    rows: Array<{
      id: string;
      code?: string | null;
      properties: Array<{ id: string; name: string }>;
    }>;
  };
};

export type BusinessSalesMethodsQueryVariables = Exact<{
  filters: BusinessSalesMethodSearchInput;
}>;

export type BusinessSalesMethodsQuery = {
  businessSalesMethods: {
    rows: Array<{
      id: string;
      code?: EnumBusinessSalesMethodCode | null;
      name: string;
    }>;
  };
};

export type BusinessIdsQueryVariables = Exact<{
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  filters: BusinessSearchInput;
}>;

export type BusinessIdsQuery = {
  businesses: { count: number; rows: Array<{ id: string }> };
};

export type BusinessBudgetAmountSumQueryVariables = Exact<{
  filters: BusinessSearchInput;
}>;

export type BusinessBudgetAmountSumQuery = { businessBudgetAmountSum: number };

export type EyeServiceOrdersCodesQueryVariables = Exact<{
  filters: EyeServiceOrderSearchInput;
}>;

export type EyeServiceOrdersCodesQuery = {
  eyeServiceOrders: {
    count: number;
    rows: Array<{ id: string; code: string }>;
  };
};

export type CitiesQueryVariables = Exact<{
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  filters: CitySearchInput;
}>;

export type CitiesQuery = {
  cities: { count: number; rows: Array<{ id: string; name: string }> };
};

export type DistrictsQueryVariables = Exact<{
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  filters: DistrictSearchInput;
}>;

export type DistrictsQuery = {
  districts: { count: number; rows: Array<{ id: string; name: string }> };
};

export type ProvincesQueryVariables = Exact<{
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  filters: ProvinceSearchInput;
}>;

export type ProvincesQuery = {
  provinces: {
    count: number;
    rows: Array<{
      id: string;
      name?: string | null;
      cities: Array<{
        id: string;
        name: string;
        districts: Array<{ id: string; name: string }>;
      }>;
    }>;
  };
};

export type CreateCustomerCertificateMutationVariables = Exact<{
  input: CustomerCertificateCreateInput;
}>;

export type CreateCustomerCertificateMutation = {
  createCustomerCertificate: { id: string; code?: string | null };
};

export type UpdateCustomerCertificateMutationVariables = Exact<{
  input: CustomerCertificateUpdateInput;
}>;

export type UpdateCustomerCertificateMutation = {
  updateCustomerCertificate: { id: string; code?: string | null };
};

export type CreateCustomerAttachmentMutationVariables = Exact<{
  input: CustomerAttachmentCreateInput;
}>;

export type CreateCustomerAttachmentMutation = {
  createCustomerAttachment: {
    id: string;
    url?: any | null;
    name: string;
    s3Key: string;
  };
};

export type UpdateCustomerAttachmentMutationVariables = Exact<{
  input: CustomerAttachmentUpdateInput;
}>;

export type UpdateCustomerAttachmentMutation = {
  updateCustomerAttachment: {
    id: string;
    url?: any | null;
    name: string;
    s3Key: string;
  };
};

export type CreateCustomerCertificateAttachmentMutationVariables = Exact<{
  input: CustomerCertificateAttachmentCreateInput;
}>;

export type CreateCustomerCertificateAttachmentMutation = {
  createCustomerCertificateAttachment: {
    id: string;
    url?: any | null;
    name: string;
    s3Key: string;
  };
};

export type UpdateCustomerCertificateAttachmentMutationVariables = Exact<{
  input: CustomerCertificateAttachmentUpdateInput;
}>;

export type UpdateCustomerCertificateAttachmentMutation = {
  updateCustomerCertificateAttachment: {
    id: string;
    url?: any | null;
    name: string;
    s3Key: string;
  };
};

export type DeleteCustomerCertificateAttachmentMutationVariables = Exact<{
  id: Scalars['ID'];
}>;

export type DeleteCustomerCertificateAttachmentMutation = {
  deleteCustomerCertificateAttachment: boolean;
};

export type DeleteCustomerAttachmentMutationVariables = Exact<{
  id: Scalars['ID'];
}>;

export type DeleteCustomerAttachmentMutation = {
  deleteCustomerAttachment: boolean;
};

export type BulkCreateCustomerAttachmentMutationVariables = Exact<{
  input: CustomerAttachmentBulkCreateInput;
}>;

export type BulkCreateCustomerAttachmentMutation = {
  bulkCreateCustomerAttachment: Array<{
    id: string;
    url?: any | null;
    name: string;
    s3Key: string;
  }>;
};

export type CustomerCertificatesQueryVariables = Exact<{
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  filters: CustomerCertificateSearchInput;
}>;

export type CustomerCertificatesQuery = {
  customerCertificates: {
    count: number;
    rows: Array<{
      id: string;
      code?: string | null;
      scope?: string | null;
      effectiveDate?: any | null;
      expiryDate?: any | null;
      customer?: { id: string; name: string } | null;
      type?: { id: string; name: string } | null;
      attachments: Array<{
        id: string;
        name: string;
        s3Key: string;
        url?: any | null;
        memo?: string | null;
      }>;
    }>;
  };
};

export type CustomerCertificateTypesQueryVariables = Exact<{
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  filters: CustomerCertificateTypeSearchInput;
}>;

export type CustomerCertificateTypesQuery = {
  customerCertificateTypes: {
    count: number;
    rows: Array<{ id: string; name: string }>;
  };
};

export type CustomerAttachmentsQueryVariables = Exact<{
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  filters: CustomerAttachmentSearchInput;
}>;

export type CustomerAttachmentsQuery = {
  customerAttachments: {
    count: number;
    rows: Array<{
      id: string;
      url?: any | null;
      s3Key: string;
      customerId: string;
      name: string;
    }>;
  };
};

export type CreateCustomerMutationVariables = Exact<{
  input: CustomerCreateInput;
}>;

export type CreateCustomerMutation = {
  createCustomer: {
    id: string;
    code?: string | null;
    name: string;
    createdAt: any;
    updatedAt: any;
  };
};

export type UpdateCustomerMutationVariables = Exact<{
  input: CustomerUpdateInput;
}>;

export type UpdateCustomerMutation = {
  updateCustomer: {
    id: string;
    code?: string | null;
    name: string;
    createdAt: any;
    updatedAt: any;
  };
};

export type CreateContactPersonMutationVariables = Exact<{
  input: ContactPersonCreateInput;
}>;

export type CreateContactPersonMutation = {
  createContactPerson: {
    id: string;
    code?: string | null;
    name: string;
    createdAt: any;
    updatedAt: any;
  };
};

export type UpdateContactPersonMutationVariables = Exact<{
  input: ContactPersonUpdateInput;
}>;

export type UpdateContactPersonMutation = {
  updateContactPerson: {
    id: string;
    code?: string | null;
    name: string;
    createdAt: any;
    updatedAt: any;
  };
};

export type CreateCustomerToContactPeopleMutationVariables = Exact<{
  input: CreateCustomerToContactPeopleInput;
}>;

export type CreateCustomerToContactPeopleMutation = {
  createCustomerToContactPeople: { id: string };
};

export type DeleteCustomerToContactPeopleMutationVariables = Exact<{
  input: DeleteCustomerToContactPeopleInput;
}>;

export type DeleteCustomerToContactPeopleMutation = {
  deleteCustomerToContactPeople: { id: string };
};

export type DeleteCustomerCertificateMutationVariables = Exact<{
  id: Scalars['ID'];
}>;

export type DeleteCustomerCertificateMutation = {
  deleteCustomerCertificate: boolean;
};

export type BatchUpsertCustomersPrimaryUserMutationVariables = Exact<{
  input: CustomersPrimaryUserBatchUpsertInput;
}>;

export type BatchUpsertCustomersPrimaryUserMutation = {
  BatchUpsertCustomersPrimaryUser: Array<{ id: string }>;
};

export type BatchUpsertCustomersSalesTeamUnitMutationVariables = Exact<{
  input: CustomersSalesTeamUnitBatchUpsertInput;
}>;

export type BatchUpsertCustomersSalesTeamUnitMutation = {
  BatchUpsertCustomersSalesTeamUnit: Array<{ customerId: string }>;
};

export type CustomersQueryVariables = Exact<{
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  filters: CustomerSearchInput;
}>;

export type CustomersQuery = {
  customers: {
    count: number;
    rows: Array<{
      id: string;
      name: string;
      code?: string | null;
      address?: string | null;
      salesTeamGroupId: string;
      businessCode?: string | null;
      medicalCode?: string | null;
      yonyouCode?: string | null;
      do1Code?: string | null;
      navCode?: string | null;
      referenceCode?: string | null;
      phone?: string | null;
      mobile?: string | null;
      email?: string | null;
      fax?: string | null;
      memo?: string | null;
      isGmp?: boolean | null;
      lastGmpBpmInstanceId?: string | null;
      lastGmpBpmStatus?: EnumLastGmpBpmStatus | null;
      lastGmpBpmUrl?: any | null;
      createdAt: any;
      updatedAt: any;
      hasEffectiveGmpCertificate?: boolean | null;
      typeId?: string | null;
      contactPersonPhone?: string | null;
      creditQuota?: number | null;
      creditPeriodId?: string | null;
      shippingAddress?: string | null;
      contactPersonName?: string | null;
      bankAccountCode?: string | null;
      bankAccountName?: string | null;
      legalPersonName?: string | null;
      billingUnitName?: string | null;
      districtId?: string | null;
      isActive: boolean;
      cityId?: string | null;
      categoryId?: string | null;
      area?: { id: string; name: string } | null;
      primaryUsers: Array<{
        id: string;
        userId: string;
        salesTeamId?: string | null;
        salesTeam?: { id: string; name: string } | null;
        User?: { id: string; name?: string | null } | null;
      }>;
      salesTeamGroup?: {
        regionId?: string | null;
        id: string;
        code?: EnumSalesTeamGroupCode | null;
        name: string;
        viewOrder?: number | null;
        createdAt: any;
        updatedAt: any;
        salesTeams: Array<{
          id: string;
          name: string;
          users: Array<{
            id: string;
            name?: string | null;
            code?: string | null;
          }>;
        }>;
      } | null;
      lastGmpBpmUser?: { id: string; name?: string | null } | null;
      type?: {
        id: string;
        name: string;
        code?: EnumCustomerTypeCode | null;
      } | null;
      levels: Array<{
        id: string;
        name: string;
        type?: { code?: string | null } | null;
      }>;
      region?: { id: string; code?: string | null; name: string } | null;
      province?: { id: string; name?: string | null } | null;
      createdUser?: {
        id: string;
        name?: string | null;
        createdAt: any;
        updatedAt: any;
      } | null;
      updatedUser?: {
        id: string;
        name?: string | null;
        createdAt: any;
        updatedAt: any;
      } | null;
      contactPeople: Array<{
        id: string;
        name: string;
        phone?: string | null;
        mobile?: string | null;
        fax?: string | null;
        gender?: EnumContactPeopleGender | null;
        doctorCode?: string | null;
        jobTitle?: string | null;
        dept?: string | null;
      }>;
      attachments: Array<{ id: string; name: string; s3Key: string }>;
      salesTeamUnits: Array<{
        id: string;
        salesTeamId: string;
        salesTeam?: { id: string; name: string } | null;
        user?: { id: string; name?: string | null } | null;
      }>;
    }>;
  };
};

export type CustomerTypesQueryVariables = Exact<{
  filters: CustomerTypeSearchInput;
}>;

export type CustomerTypesQuery = {
  customerTypes: {
    rows: Array<{
      id: string;
      name: string;
      code?: EnumCustomerTypeCode | null;
      salesTeamGroupId: string;
      salesTeamGroup?: { id: string; name: string } | null;
    }>;
  };
};

export type ContactPersonsQueryVariables = Exact<{
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  filters: ContactPersonSearchInput;
}>;

export type ContactPersonsQuery = {
  contactPeople: {
    count: number;
    rows: Array<{
      id: string;
      code?: string | null;
      name: string;
      nickname?: string | null;
      jobTitle?: string | null;
      salesTeamGroupId: string;
      gender?: EnumContactPeopleGender | null;
      doctorCode?: string | null;
      phone?: string | null;
      mobile?: string | null;
      email?: string | null;
      fax?: string | null;
      address?: string | null;
      departmentId?: string | null;
      hobby?: string | null;
      skill?: string | null;
      referenceCode?: string | null;
      memo?: string | null;
      createdAt: any;
      updatedAt: any;
      citizenCode?: string | null;
      department?: { id: string; name: string } | null;
      primaryUsers: Array<{
        id: string;
        code?: string | null;
        name?: string | null;
        dept?: { id: string; code?: string | null; name: string } | null;
      }>;
      createdUser?: {
        id: string;
        code?: string | null;
        name?: string | null;
        dept?: { id: string; code?: string | null; name: string } | null;
      } | null;
      updatedUser?: {
        id: string;
        code?: string | null;
        name?: string | null;
        dept?: { id: string; code?: string | null; name: string } | null;
      } | null;
      customers: Array<{ name: string; id: string; code?: string | null }>;
      region?: { id: string; name: string } | null;
      salesTeamGroup?: { id: string; name: string } | null;
      salesTeamUnits: Array<{ id: string }>;
    }>;
  };
};

export type ContactPeopleSelectQueryVariables = Exact<{
  filters: ContactPersonSearchInput;
}>;

export type ContactPeopleSelectQuery = {
  contactPeople: { rows: Array<{ id: string; name: string }> };
};

export type ContactPersonDepartmentsQueryVariables = Exact<{
  filters: ContactPersonDepartmentSearchInput;
}>;

export type ContactPersonDepartmentsQuery = {
  contactPersonDepartments: {
    rows: Array<{ id: string; name: string; viewOrder?: number | null }>;
  };
};

export type SalesTeamGroupsQueryVariables = Exact<{
  filters: SalesTeamGroupSearchInput;
}>;

export type SalesTeamGroupsQuery = {
  salesTeamGroups: {
    count: number;
    rows: Array<{
      regionId?: string | null;
      id: string;
      code?: EnumSalesTeamGroupCode | null;
      name: string;
      viewOrder?: number | null;
      createdAt: any;
      updatedAt: any;
      salesTeams: Array<{
        id: string;
        name: string;
        users: Array<{
          id: string;
          name?: string | null;
          code?: string | null;
        }>;
      }>;
    }>;
  };
};

export type CreditPeriodsQueryVariables = Exact<{
  filters: CreditPeriodSearchInput;
}>;

export type CreditPeriodsQuery = {
  creditPeriods: { rows: Array<{ id: string; name: string }> };
};

export type CustomerCategoriesQueryVariables = Exact<{
  filters: CustomerCategorySearchInput;
}>;

export type CustomerCategoriesQuery = {
  customerCategories: {
    rows: Array<{
      id: string;
      name: string;
      salesTeamGroupId: string;
      viewOrder?: number | null;
    }>;
  };
};

export type CustomerIdsQueryVariables = Exact<{
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  filters: CustomerSearchInput;
}>;

export type CustomerIdsQuery = {
  customers: { count: number; rows: Array<{ id: string }> };
};

export type CustomerAreasQueryVariables = Exact<{
  filters: CustomerAreaSearchInput;
}>;

export type CustomerAreasQuery = {
  customerAreas: { count: number; rows: Array<{ id: string; name: string }> };
};

export type CustomerLevelsQueryVariables = Exact<{
  filters: CustomerLevelSearchInput;
}>;

export type CustomerLevelsQuery = {
  customerLevels: {
    count: number;
    rows: Array<{
      id: string;
      name: string;
      type?: { code?: string | null } | null;
    }>;
  };
};

export type CreateVisitMutationVariables = Exact<{
  input: VisitCreateInput;
}>;

export type CreateVisitMutation = { createVisit: { id: string } };

export type UpdateVisitMutationVariables = Exact<{
  input: VisitUpdateInput;
}>;

export type UpdateVisitMutation = { updateVisit: { id: string } };

export type VisitsQueryVariables = Exact<{
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  filters: VisitSearchInput;
}>;

export type VisitsQuery = {
  visits: {
    count: number;
    rows: Array<{
      id: string;
      createdAt: any;
      updatedAt: any;
      title?: string | null;
      content?: string | null;
      visitAddress?: string | null;
      visitDate: any;
      priorityOrder?: number | null;
      status: EnumVisitStatus;
      typeId?: string | null;
      actionContent?: string | null;
      salesTeamUnitId?: string | null;
      surgeryCount?: string | null;
      estimatedSurgeryCount?: string | null;
      hasAssistedVisitSupervisor: boolean;
      assistedVisitSupervisorId?: string | null;
      salesTeamGroup?: { id: string; name: string } | null;
      type?: { id: string; name: string } | null;
      business?: {
        id: string;
        title?: string | null;
        content?: string | null;
        budgetAmount?: number | null;
        saleAmount?: number | null;
        dealAmount?: number | null;
        expectedClosedDate?: any | null;
        closedDate?: any | null;
        orderCode?: string | null;
        winningOpportunity?: { id: string; name: string } | null;
        buyingOpportunity?: { id: string; name: string } | null;
        businessesToBudgetProducts: Array<{
          id: string;
          qty: number;
          budgetProduct?: { id: string; name: string } | null;
        }>;
        businessesToDealProducts: Array<{
          id: string;
          qty: number;
          dealProduct?: { id: string; name: string } | null;
        }>;
        customer?: {
          id: string;
          name: string;
          code?: string | null;
          cityId?: string | null;
          districtId?: string | null;
          address?: string | null;
          salesTeamGroupId: string;
          medicalCode?: string | null;
          type?: { id: string; name: string } | null;
          region?: { id: string; name: string } | null;
        } | null;
        status?: { id: string; name: string } | null;
      } | null;
      action?: { id: string; name: string } | null;
      customer?: {
        id: string;
        name: string;
        code?: string | null;
        cityId?: string | null;
        districtId?: string | null;
        address?: string | null;
        salesTeamGroupId: string;
        medicalCode?: string | null;
        type?: { id: string; name: string } | null;
        region?: { id: string; name: string } | null;
      } | null;
      salesTeam?: { id: string; name: string } | null;
      primaryUser?: {
        id: string;
        code?: string | null;
        name?: string | null;
        email?: string | null;
        dept?: { id: string; name: string } | null;
      } | null;
      timePeriods: Array<{ id: string; name: string }>;
      goals: Array<{ id: string; name: string }>;
      properties: Array<{
        id: string;
        name: string;
        type?: { id: string; name: string; code?: string | null } | null;
      }>;
      mentionProducts: Array<{ id: string; name: string }>;
      rentProducts: Array<{ id: string; name: string }>;
      rentFixedAssets: Array<{ id: string; name: string }>;
      createdUser?: {
        id: string;
        code?: string | null;
        name?: string | null;
        email?: string | null;
        dept?: { id: string; name: string } | null;
      } | null;
      updatedUser?: {
        id: string;
        code?: string | null;
        name?: string | null;
        email?: string | null;
        dept?: { id: string; name: string } | null;
      } | null;
      primaryContactPeople: Array<{
        id: string;
        name: string;
        jobTitle?: string | null;
      }>;
      assistedVisitSupervisor?: {
        id: string;
        name?: string | null;
        departmentId?: string | null;
      } | null;
      memos: Array<{
        id: string;
        title?: string | null;
        content?: string | null;
        createdAt: any;
        updatedAt: any;
        createdUser?: {
          id: string;
          code?: string | null;
          name?: string | null;
          email?: string | null;
          dept?: { id: string; name: string } | null;
        } | null;
        updatedUser?: {
          id: string;
          code?: string | null;
          name?: string | null;
          email?: string | null;
          dept?: { id: string; name: string } | null;
        } | null;
      }>;
    }>;
  };
};

export type VisitBasicInfoFragment = {
  id: string;
  createdAt: any;
  updatedAt: any;
  title?: string | null;
  content?: string | null;
  visitAddress?: string | null;
  visitDate: any;
  priorityOrder?: number | null;
  status: EnumVisitStatus;
  typeId?: string | null;
  actionContent?: string | null;
  salesTeamUnitId?: string | null;
  surgeryCount?: string | null;
  estimatedSurgeryCount?: string | null;
  hasAssistedVisitSupervisor: boolean;
  assistedVisitSupervisorId?: string | null;
  salesTeamGroup?: { id: string; name: string } | null;
  type?: { id: string; name: string } | null;
  business?: {
    id: string;
    title?: string | null;
    content?: string | null;
    budgetAmount?: number | null;
    saleAmount?: number | null;
    dealAmount?: number | null;
    expectedClosedDate?: any | null;
    closedDate?: any | null;
    orderCode?: string | null;
    winningOpportunity?: { id: string; name: string } | null;
    buyingOpportunity?: { id: string; name: string } | null;
    businessesToBudgetProducts: Array<{
      id: string;
      qty: number;
      budgetProduct?: { id: string; name: string } | null;
    }>;
    businessesToDealProducts: Array<{
      id: string;
      qty: number;
      dealProduct?: { id: string; name: string } | null;
    }>;
    customer?: {
      id: string;
      name: string;
      code?: string | null;
      cityId?: string | null;
      districtId?: string | null;
      address?: string | null;
      salesTeamGroupId: string;
      medicalCode?: string | null;
      type?: { id: string; name: string } | null;
      region?: { id: string; name: string } | null;
    } | null;
    status?: { id: string; name: string } | null;
  } | null;
  action?: { id: string; name: string } | null;
  customer?: {
    id: string;
    name: string;
    code?: string | null;
    cityId?: string | null;
    districtId?: string | null;
    address?: string | null;
    salesTeamGroupId: string;
    medicalCode?: string | null;
    type?: { id: string; name: string } | null;
    region?: { id: string; name: string } | null;
  } | null;
  salesTeam?: { id: string; name: string } | null;
  primaryUser?: {
    id: string;
    code?: string | null;
    name?: string | null;
    email?: string | null;
    dept?: { id: string; name: string } | null;
  } | null;
  timePeriods: Array<{ id: string; name: string }>;
  goals: Array<{ id: string; name: string }>;
  properties: Array<{
    id: string;
    name: string;
    type?: { id: string; name: string; code?: string | null } | null;
  }>;
  mentionProducts: Array<{ id: string; name: string }>;
  rentProducts: Array<{ id: string; name: string }>;
  rentFixedAssets: Array<{ id: string; name: string }>;
  createdUser?: {
    id: string;
    code?: string | null;
    name?: string | null;
    email?: string | null;
    dept?: { id: string; name: string } | null;
  } | null;
  updatedUser?: {
    id: string;
    code?: string | null;
    name?: string | null;
    email?: string | null;
    dept?: { id: string; name: string } | null;
  } | null;
  primaryContactPeople: Array<{
    id: string;
    name: string;
    jobTitle?: string | null;
  }>;
  assistedVisitSupervisor?: {
    id: string;
    name?: string | null;
    departmentId?: string | null;
  } | null;
};

export type VisitDetailDataFragment = {
  memos: Array<{
    id: string;
    title?: string | null;
    content?: string | null;
    createdAt: any;
    updatedAt: any;
    createdUser?: {
      id: string;
      code?: string | null;
      name?: string | null;
      email?: string | null;
      dept?: { id: string; name: string } | null;
    } | null;
    updatedUser?: {
      id: string;
      code?: string | null;
      name?: string | null;
      email?: string | null;
      dept?: { id: string; name: string } | null;
    } | null;
  }>;
};

export type BusinessBasicInfoFragment = {
  id: string;
  title?: string | null;
  content?: string | null;
  budgetAmount?: number | null;
  saleAmount?: number | null;
  dealAmount?: number | null;
  expectedClosedDate?: any | null;
  closedDate?: any | null;
  orderCode?: string | null;
  winningOpportunity?: { id: string; name: string } | null;
  buyingOpportunity?: { id: string; name: string } | null;
  businessesToBudgetProducts: Array<{
    id: string;
    qty: number;
    budgetProduct?: { id: string; name: string } | null;
  }>;
  businessesToDealProducts: Array<{
    id: string;
    qty: number;
    dealProduct?: { id: string; name: string } | null;
  }>;
  customer?: {
    id: string;
    name: string;
    code?: string | null;
    cityId?: string | null;
    districtId?: string | null;
    address?: string | null;
    salesTeamGroupId: string;
    medicalCode?: string | null;
    type?: { id: string; name: string } | null;
    region?: { id: string; name: string } | null;
  } | null;
  status?: { id: string; name: string } | null;
};

export type CustomerBasicInfoFragment = {
  id: string;
  name: string;
  code?: string | null;
  cityId?: string | null;
  districtId?: string | null;
  address?: string | null;
  salesTeamGroupId: string;
  medicalCode?: string | null;
  type?: { id: string; name: string } | null;
  region?: { id: string; name: string } | null;
};

export type StaffBasicInfoFragment = {
  id: string;
  code?: string | null;
  name?: string | null;
  email?: string | null;
  dept?: { id: string; name: string } | null;
};

export type VisitMemoBasicInfoFragment = {
  id: string;
  title?: string | null;
  content?: string | null;
  createdAt: any;
  updatedAt: any;
  createdUser?: {
    id: string;
    code?: string | null;
    name?: string | null;
    email?: string | null;
    dept?: { id: string; name: string } | null;
  } | null;
  updatedUser?: {
    id: string;
    code?: string | null;
    name?: string | null;
    email?: string | null;
    dept?: { id: string; name: string } | null;
  } | null;
};

export type VisitActionBasicInfoFragment = { id: string; name: string };

export type VisitGoalBasicInfoFragment = { id: string; name: string };

export type VisitPropertyTypeBasicInfoFragment = {
  id: string;
  name: string;
  code?: string | null;
};

export type VisitPropertyBasicInfoFragment = { id: string; name: string };

export type VisitTimePeriodBasicInfoFragment = { id: string; name: string };

export type VisitTypeBasicInfoFragment = { id: string; name: string };

export type VisitProductBasicInfoFragment = { id: string; name: string };

export type VisitTypesQueryVariables = Exact<{
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  filters: VisitTypeSearchInput;
}>;

export type VisitTypesQuery = {
  visitTypes: {
    count: number;
    rows: Array<{
      id: string;
      name: string;
      code?: EnumVisitTypeCode | null;
      salesTeamGroup?: {
        id: string;
        name: string;
        code?: EnumSalesTeamGroupCode | null;
      } | null;
    }>;
  };
};

export type VisitTimePeriodsQueryVariables = Exact<{
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  filters: VisitTimePeriodSearchInput;
}>;

export type VisitTimePeriodsQuery = {
  visitTimePeriods: {
    count: number;
    rows: Array<{
      id: string;
      name: string;
      salesTeamGroup?: {
        id: string;
        name: string;
        code?: EnumSalesTeamGroupCode | null;
      } | null;
    }>;
  };
};

export type VisitPropertiesQueryVariables = Exact<{
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  filters: VisitPropertySearchInput;
}>;

export type VisitPropertiesQuery = {
  visitProperties: {
    count: number;
    rows: Array<{
      id: string;
      name: string;
      salesTeamGroup?: {
        id: string;
        name: string;
        code?: EnumSalesTeamGroupCode | null;
      } | null;
    }>;
  };
};

export type VisitPropertyTypesQueryVariables = Exact<{
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  filters: VisitPropertyTypeSearchInput;
}>;

export type VisitPropertyTypesQuery = {
  visitPropertyTypes: {
    count: number;
    rows: Array<{
      id: string;
      name: string;
      salesTeamGroup?: {
        id: string;
        name: string;
        code?: EnumSalesTeamGroupCode | null;
      } | null;
      properties?: Array<{ id: string; name: string }> | null;
    }>;
  };
};

export type VisitGoalsQueryVariables = Exact<{
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  filters: VisitGoalSearchInput;
}>;

export type VisitGoalsQuery = {
  visitGoals: { count: number; rows: Array<{ id: string; name: string }> };
};

export type VisitActionsQueryVariables = Exact<{
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  filters: VisitActionSearchInput;
}>;

export type VisitActionsQuery = {
  visitActions: {
    count: number;
    rows: Array<{
      id: string;
      name: string;
      salesTeamGroup?: {
        id: string;
        name: string;
        code?: EnumSalesTeamGroupCode | null;
      } | null;
    }>;
  };
};

export type SalesTeamGroupFragment = {
  id: string;
  name: string;
  code?: EnumSalesTeamGroupCode | null;
};

export type VisitCheckInsQueryVariables = Exact<{
  filters: VisitCheckInSearchInput;
  offset?: InputMaybe<Scalars['Int']>;
  limit?: InputMaybe<Scalars['Int']>;
}>;

export type VisitCheckInsQuery = {
  visitCheckIns: {
    count: number;
    rows: Array<{
      id: string;
      content?: string | null;
      createdAt: any;
      lat: number;
      lng: number;
      visit?: { id: string } | null;
    }>;
  };
};

export type RentProductsQueryVariables = Exact<{
  filters: RentProductSearchInput;
  offset?: InputMaybe<Scalars['Int']>;
  limit?: InputMaybe<Scalars['Int']>;
}>;

export type RentProductsQuery = {
  rentProducts: { count: number; rows: Array<{ id: string; name: string }> };
};

export type CreateEyeFixedAssetMutationVariables = Exact<{
  input: EyeFixedAssetCreateInput;
}>;

export type CreateEyeFixedAssetMutation = {
  createEyeFixedAsset: { id: string };
};

export type UpdateEyeFixedAssetMutationVariables = Exact<{
  input: EyeFixedAssetUpdateInput;
}>;

export type UpdateEyeFixedAssetMutation = {
  updateEyeFixedAsset: { id: string };
};

export type EyeFixedAssetsQueryVariables = Exact<{
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  filters: EyeFixedAssetSearchInput;
}>;

export type EyeFixedAssetsQuery = {
  eyeFixedAssets: {
    count: number;
    pageInfo: { hasNextPage: boolean };
    rows: Array<{
      id: string;
      name: string;
      code: string;
      isBooking: boolean;
      status: EnumEyeFixedAssetsStatus;
      memo?: string | null;
      pmUserId?: string | null;
      createdAt: any;
      updatedAt: any;
      currentAvailableRentalDate: any;
      currentLocation?: string | null;
      warehouseId?: string | null;
      borrowingDays?: number | null;
      deliveryDays?: number | null;
      maxExtendedDays?: number | null;
      maxExtendedTimes?: number | null;
      region?: { id: string; name: string } | null;
      company?: { id: string; name: string } | null;
      dept?: { id: string; code?: string | null; name: string } | null;
      pmUser?: {
        id: string;
        name?: string | null;
        dept?: { id: string; name: string } | null;
      } | null;
      createdUser?: { id: string; name?: string | null } | null;
      updatedUser?: { id: string; name?: string | null } | null;
      items: Array<{
        materialId: string;
        materialCode: string;
        materialName?: string | null;
        materialModel?: string | null;
        materialSpec?: string | null;
        materialUnit?: string | null;
        udi?: string | null;
        sn?: string | null;
        memo?: string | null;
      }>;
      eyeFixedAssetRentalRecords: Array<{
        id: string;
        eyeServiceOrderId: string;
        date1: any;
        date2: any;
        installDate?: any | null;
        dismantleDate?: any | null;
        status: EnumEyeFixedAssetsRentalStatus;
        remark?: string | null;
        eyeServiceOrder?: {
          id: string;
          code: string;
          assigneeUser?: {
            id: string;
            code?: string | null;
            name?: string | null;
          } | null;
        } | null;
        eyeFixedAssets: Array<{ id: string; name: string }>;
        region?: { id: string; name: string } | null;
      }>;
      currEyeFixedAssetRentalRecord?: {
        eyeServiceOrder?: {
          customer?: {
            address?: string | null;
            name: string;
            id: string;
            code?: string | null;
          } | null;
        } | null;
      } | null;
      nextEyeFixedAssetRentalRecord?: {
        id: string;
        date1: any;
        date2: any;
        eyeServiceOrder?: {
          customer?: { code?: string | null; id: string; name: string } | null;
        } | null;
      } | null;
      prevEyeFixedAssetRentalRecord?: {
        id: string;
        date1: any;
        date2: any;
        eyeServiceOrder?: {
          customer?: { code?: string | null; id: string; name: string } | null;
        } | null;
      } | null;
      images: Array<{
        id: string;
        url?: any | null;
        name?: string | null;
        size: number;
        s3Key: string;
      }>;
      documents: Array<{
        id: string;
        url?: any | null;
        name?: string | null;
        size: number;
        s3Key: string;
      }>;
      types: Array<{
        id: string;
        name: string;
        code: EnumEyeFixedAssetTypeCode;
      }>;
      eyeFixedAssetServiceProvider?: Array<{
        id: string;
        code?: EnumEyeFixedAssetServiceProviderCode | null;
      }> | null;
    }>;
  };
};

export type WarehousesQueryVariables = Exact<{
  filters: WarehouseSearchInput;
}>;

export type WarehousesQuery = {
  warehouses: {
    rows: Array<{
      id: string;
      createdAt: any;
      updatedAt: any;
      isActive: boolean;
      code?: string | null;
      name: string;
    }>;
  };
};

export type EyeFixedAssetServiceProvidersQueryVariables = Exact<{
  filters: EyeFixedAssetServiceProviderSearchInput;
}>;

export type EyeFixedAssetServiceProvidersQuery = {
  eyeFixedAssetServiceProviders: {
    rows: Array<{
      id: string;
      code?: EnumEyeFixedAssetServiceProviderCode | null;
      name: string;
    }>;
  };
};

export type EyeFixedAssetRentalRecordsQueryVariables = Exact<{
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  filters: EyeFixedAssetRentalRecordSearchInput;
}>;

export type EyeFixedAssetRentalRecordsQuery = {
  eyeFixedAssetRentalRecords: {
    count: number;
    rows: Array<{
      id: string;
      date1: any;
      date2: any;
      eyeServiceOrderId: string;
      installDate?: any | null;
      dismantleDate?: any | null;
      status: EnumEyeFixedAssetsRentalStatus;
      remark?: string | null;
      createdAt: any;
      updatedAt: any;
      region?: { id: string; name: string } | null;
      createdUser?: {
        id: string;
        code?: string | null;
        name?: string | null;
      } | null;
      updatedUser?: {
        id: string;
        code?: string | null;
        name?: string | null;
      } | null;
      eyeFixedAssets: Array<{
        id: string;
        name: string;
        items: Array<{ id: string; materialName?: string | null }>;
      }>;
      eyeServiceOrder?: {
        code: string;
        priority: EnumEyeServiceOrderPriority;
        customer?: { name: string; address?: string | null } | null;
      } | null;
      eyeServiceOrderWorkDiaries: Array<{
        id: string;
        hours?: number | null;
        eyeServiceOrderId: string;
        remark?: string | null;
        users: Array<{
          id: string;
          code?: string | null;
          name?: string | null;
        }>;
      }>;
    }>;
  };
};

export type EyeServiceOrdersSelectQueryVariables = Exact<{
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  filters: EyeServiceOrderSearchInput;
}>;

export type EyeServiceOrdersSelectQuery = {
  eyeServiceOrders: {
    count: number;
    rows: Array<{ id: string; code: string }>;
  };
};

export type EyeFixedAssetsSelectQueryVariables = Exact<{
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  filters: EyeFixedAssetSearchInput;
}>;

export type EyeFixedAssetsSelectQuery = {
  eyeFixedAssets: { count: number; rows: Array<{ id: string; name: string }> };
};

export type CreateEyeProductMutationVariables = Exact<{
  input: EyeProductCreateInput;
}>;

export type CreateEyeProductMutation = {
  createEyeProduct: {
    id: string;
    eyeProductTypeId?: string | null;
    regionId: string;
    name: string;
    salePrice: number;
    brand?: string | null;
    status: EnumEyeProductStatus;
    createdAt: any;
    updatedAt: any;
    eyeProductType?: { id: string; regionId: string; name: string } | null;
  };
};

export type CreateEyeProductItemMutationVariables = Exact<{
  input: EyeProductItemCreateInput;
}>;

export type CreateEyeProductItemMutation = {
  createEyeProductItem: {
    id: string;
    eyeProductId: string;
    eyeProductItemTypeId: string;
    qty: number;
    isOptional: boolean;
    createdAt: any;
    materialName: string;
    materialSellingUnit?: string | null;
    material: {
      id: string;
      code: string;
      name: string;
      model?: string | null;
      spec?: string | null;
      unit?: string | null;
      countingUnit?: { id: string; name: string } | null;
    };
  };
};

export type UpdateEyeProductItemMutationVariables = Exact<{
  input: EyeProductItemUpdateInput;
}>;

export type UpdateEyeProductItemMutation = {
  updateEyeProductItem: {
    id: string;
    materialName: string;
    qty: number;
    materialSellingUnit?: string | null;
  };
};

export type BulkUpdateEyeProductItemsMutationVariables = Exact<{
  input: EyeProductItemBulkUpdateInput;
}>;

export type BulkUpdateEyeProductItemsMutation = {
  bulkUpdateEyeProductItems: Array<{ id: string }>;
};

export type UpdateEyeProductMutationVariables = Exact<{
  input: EyeProductUpdateInput;
}>;

export type UpdateEyeProductMutation = {
  updateEyeProduct: {
    id: string;
    name: string;
    salePrice: number;
    dealerPrice?: number | null;
    status: EnumEyeProductStatus;
    description?: string | null;
    currencyId: string;
    model?: string | null;
    regionId: string;
    brand?: string | null;
    createdAt: any;
    updatedAt: any;
    eyeProductTypeId?: string | null;
    standardDeliveryDays?: number | null;
    eyeProductType?: {
      id: string;
      regionId: string;
      name: string;
      createdAt: any;
      updatedAt: any;
    } | null;
    eyeProductItems: Array<{
      id: string;
      eyeProductId: string;
      eyeProductItemTypeId: string;
      qty: number;
      isOptional: boolean;
      material: {
        id: string;
        code: string;
        name: string;
        model?: string | null;
        spec?: string | null;
        unit?: string | null;
      };
    }>;
    attachments: Array<{
      id: string;
      url?: any | null;
      name: string;
      size: number;
      s3Key: string;
    }>;
    productUsers: Array<{ id: string; name?: string | null }>;
  };
};

export type DeleteEyeProductMutationVariables = Exact<{
  id: Scalars['ID'];
}>;

export type DeleteEyeProductMutation = { deleteEyeProduct: boolean };

export type DeleteEyeProductItemMutationVariables = Exact<{
  id: Scalars['ID'];
}>;

export type DeleteEyeProductItemMutation = { deleteEyeProductItem: boolean };

export type EyeProductsQueryVariables = Exact<{
  offset?: InputMaybe<Scalars['Int']>;
  limit?: InputMaybe<Scalars['Int']>;
  filters: EyeProductSearchInput;
}>;

export type EyeProductsQuery = {
  eyeProducts: {
    count: number;
    pageInfo: { hasNextPage: boolean };
    rows: Array<{
      id: string;
      name: string;
      salePrice: number;
      dealerPrice?: number | null;
      brand?: string | null;
      status: EnumEyeProductStatus;
      description?: string | null;
      currencyId: string;
      model?: string | null;
      regionId: string;
      createdAt: any;
      updatedAt: any;
      eyeProductTypeId?: string | null;
      standardDeliveryDays?: number | null;
      bonusCoefficient?: number | null;
      businessManagerPrice?: number | null;
      dsmPrice?: number | null;
      generalManagerPrice?: number | null;
      regionalManagerPrice?: number | null;
      eyeProductType?: {
        id: string;
        regionId: string;
        name: string;
        createdAt: any;
        updatedAt: any;
      } | null;
      company?: { id: string; name: string } | null;
      currency?: { id: string; name: string } | null;
      eyeProductItems: Array<{
        id: string;
        eyeProductId: string;
        eyeProductItemTypeId: string;
        qty: number;
        isOptional: boolean;
        createdAt: any;
        viewOrder?: number | null;
        materialName: string;
        materialSellingUnit?: string | null;
        material: {
          id: string;
          code: string;
          name: string;
          model?: string | null;
          spec?: string | null;
          salePrice?: number | null;
          saleCurrencyId?: string | null;
          countingUnit?: { id: string; name: string } | null;
        };
      }>;
      region?: { id: string; name: string } | null;
      attachments: Array<{
        id: string;
        url?: any | null;
        name: string;
        size: number;
        s3Key: string;
      }>;
      productUsers: Array<{ id: string; name?: string | null }>;
      eyePromotions: Array<{
        id: string;
        name?: string | null;
        isAddon: boolean;
        discountRate?: number | null;
        discountAmount?: number | null;
        createdAt: any;
        date1: any;
        regionId: string;
        updatedAt: any;
        currencyId: string;
        eyePromotionAddonProductItems: Array<{
          id: string;
          qty: number;
          isOptional: boolean;
          material: { id: string; unit?: string | null };
        }>;
      }>;
    }>;
  };
};

export type EyeProductNamesQueryVariables = Exact<{
  offset?: InputMaybe<Scalars['Int']>;
  limit?: InputMaybe<Scalars['Int']>;
  filters: EyeProductSearchInput;
}>;

export type EyeProductNamesQuery = {
  eyeProducts: {
    count: number;
    pageInfo: { hasNextPage: boolean };
    rows: Array<{ id: string; name: string }>;
  };
};

export type EyeProductItemsQueryVariables = Exact<{
  limit?: InputMaybe<Scalars['Int']>;
  filters: EyeProductSearchInput;
}>;

export type EyeProductItemsQuery = {
  eyeProducts: {
    rows: Array<{
      eyeProductItems: Array<{
        id: string;
        eyeProductId: string;
        eyeProductItemTypeId: string;
        qty: number;
        isOptional: boolean;
        createdAt: any;
        viewOrder?: number | null;
        materialName: string;
        materialSellingUnit?: string | null;
        material: {
          id: string;
          code: string;
          name: string;
          model?: string | null;
          spec?: string | null;
          countingUnit?: { id: string; name: string } | null;
        };
      }>;
    }>;
  };
};

export type EyeProductsBrandQueryVariables = Exact<{
  limit?: InputMaybe<Scalars['Int']>;
  filters: EyeProductSearchInput;
}>;

export type EyeProductsBrandQuery = {
  eyeProducts: { rows: Array<{ brand?: string | null }> };
};

export type CreateEyePromotionMutationVariables = Exact<{
  input: EyePromotionCreateInput;
}>;

export type CreateEyePromotionMutation = {
  createEyePromotion: {
    id: string;
    date1: any;
    date2?: any | null;
    regionId: string;
    name?: string | null;
    discountRate?: number | null;
    discountAmount?: number | null;
    createdUserId?: string | null;
    createdAt: any;
    updatedAt: any;
    createdUser?: {
      id: string;
      code?: string | null;
      name?: string | null;
      companyId?: string | null;
      departmentId?: string | null;
    } | null;
    updatedUser?: {
      id: string;
      code?: string | null;
      name?: string | null;
      companyId?: string | null;
      departmentId?: string | null;
    } | null;
    eyeProducts: Array<{
      id: string;
      eyeProductTypeId?: string | null;
      regionId: string;
      name: string;
    }>;
  };
};

export type UpdateEyePromotionMutationVariables = Exact<{
  input: EyePromotionUpdateInput;
}>;

export type UpdateEyePromotionMutation = {
  updateEyePromotion: {
    id: string;
    name?: string | null;
    regionId: string;
    date1: any;
    date2?: any | null;
    createdAt: any;
    updatedAt: any;
    description?: string | null;
    discountRate?: number | null;
    discountAmount?: number | null;
    createdUserId?: string | null;
    updatedUserId?: string | null;
    eyeProducts: Array<{
      id: string;
      name: string;
      salePrice: number;
      dealerPrice?: number | null;
      status: EnumEyeProductStatus;
      description?: string | null;
      currencyId: string;
      model?: string | null;
      regionId: string;
      brand?: string | null;
      createdAt: any;
      updatedAt: any;
      eyeProductTypeId?: string | null;
      standardDeliveryDays?: number | null;
      eyeProductType?: {
        id: string;
        regionId: string;
        name: string;
        createdAt: any;
        updatedAt: any;
      } | null;
      productUsers: Array<{ id: string; name?: string | null }>;
      attachments: Array<{
        id: string;
        url?: any | null;
        name: string;
        size: number;
        s3Key: string;
      }>;
      eyeProductItems: Array<{
        id: string;
        eyeProductId: string;
        eyeProductItemTypeId: string;
        qty: number;
        isOptional: boolean;
        material: {
          id: string;
          code: string;
          name: string;
          model?: string | null;
          spec?: string | null;
          unit?: string | null;
        };
      }>;
    }>;
    createdUser?: {
      id: string;
      name?: string | null;
      code?: string | null;
    } | null;
  };
};

export type CreateEyePromotionAddonProductItemMutationVariables = Exact<{
  input: EyePromotionAddonProductItemCreateInput;
}>;

export type CreateEyePromotionAddonProductItemMutation = {
  createEyePromotionAddonProductItem: {
    id: string;
    eyePromotionId: string;
    eyeProductItemTypeId: string;
    qty: number;
    isOptional: boolean;
    material: {
      id: string;
      code: string;
      name: string;
      model?: string | null;
      spec?: string | null;
      unit?: string | null;
    };
  };
};

export type DeleteEyePromotionAddonProductItemMutationVariables = Exact<{
  id: Scalars['ID'];
}>;

export type DeleteEyePromotionAddonProductItemMutation = {
  deleteEyePromotionAddonProductItem: boolean;
};

export type EyePromotionsQueryVariables = Exact<{
  filters: EyePromotionSearchInput;
  offset?: InputMaybe<Scalars['Int']>;
  limit?: InputMaybe<Scalars['Int']>;
}>;

export type EyePromotionsQuery = {
  eyePromotions: {
    count: number;
    pageInfo: { hasNextPage: boolean };
    rows: Array<{
      id: string;
      name?: string | null;
      regionId: string;
      currencyId: string;
      date1: any;
      date2?: any | null;
      isAddon: boolean;
      createdAt: any;
      updatedAt: any;
      description?: string | null;
      discountRate?: number | null;
      discountAmount?: number | null;
      createdUserId?: string | null;
      updatedUserId?: string | null;
      region?: { id: string; name: string } | null;
      company?: { id: string; name: string } | null;
      eyePromotionAddonProductItems: Array<{
        id: string;
        eyePromotionId: string;
        eyeProductItemTypeId: string;
        qty: number;
        isOptional: boolean;
        material: {
          id: string;
          code: string;
          name: string;
          model?: string | null;
          spec?: string | null;
          unit?: string | null;
        };
      }>;
      eyeProducts: Array<{
        id: string;
        name: string;
        salePrice: number;
        dealerPrice?: number | null;
        status: EnumEyeProductStatus;
        description?: string | null;
        currencyId: string;
        model?: string | null;
        regionId: string;
        brand?: string | null;
        createdAt: any;
        updatedAt: any;
        eyeProductTypeId?: string | null;
        standardDeliveryDays?: number | null;
        eyeProductType?: {
          id: string;
          regionId: string;
          name: string;
          createdAt: any;
          updatedAt: any;
        } | null;
        eyeProductItems: Array<{
          id: string;
          eyeProductId: string;
          eyeProductItemTypeId: string;
          qty: number;
          isOptional: boolean;
          createdAt: any;
          materialName: string;
          materialSellingUnit?: string | null;
          material: {
            id: string;
            code: string;
            name: string;
            model?: string | null;
            spec?: string | null;
            unit?: string | null;
          };
        }>;
        attachments: Array<{
          id: string;
          url?: any | null;
          name: string;
          size: number;
          s3Key: string;
        }>;
        productUsers: Array<{ id: string; name?: string | null }>;
        eyePromotions: Array<{
          id: string;
          name?: string | null;
          isAddon: boolean;
          discountRate?: number | null;
          discountAmount?: number | null;
          createdAt: any;
          date1: any;
          regionId: string;
          updatedAt: any;
          currencyId: string;
          eyePromotionAddonProductItems: Array<{
            id: string;
            qty: number;
            isOptional: boolean;
            material: { id: string; unit?: string | null };
          }>;
        }>;
      }>;
      createdUser?: {
        id: string;
        name?: string | null;
        code?: string | null;
      } | null;
    }>;
  };
};

export type CreateEyeQuotationOrderMutationVariables = Exact<{
  input: EyeQuotationOrderCreateInput;
}>;

export type CreateEyeQuotationOrderMutation = {
  createEyeQuotationOrder: {
    id: string;
    code: string;
    regionId: string;
    eyeServiceOrderId?: string | null;
    customerId: string;
    invoicingCustomerId: string;
    contactPerson: string;
    contactPhone: string;
    deptId: string;
    address: string;
  };
};

export type RequestEyeQuotationOrderOfficialSealMutationVariables = Exact<{
  input: EyeQuotationOrderOfficialSealInput;
}>;

export type RequestEyeQuotationOrderOfficialSealMutation = {
  requestEyeQuotationOrderOfficialSeal: boolean;
};

export type EyeQuotationOrdersQueryVariables = Exact<{
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  filters: EyeQuotationOrderSearchInput;
}>;

export type EyeQuotationOrdersQuery = {
  eyeQuotationOrders: {
    count: number;
    rows: Array<{
      bpmUrl?: any | null;
      localStandardAmount: number;
      officialSealBpmUrl?: any | null;
      officialSealStatus?: EnumEyeQuotationOrderOfficialSealStatus | null;
      id: string;
      code: string;
      userId: string;
      customerId: string;
      invoicingCustomerId: string;
      eyeServiceOrderId?: string | null;
      bpmInstanceId?: string | null;
      exchangeRate?: number | null;
      warrantyMonths?: number | null;
      warrantyPeriodTypeId?: string | null;
      expectDeliveryDate: any;
      expectPaymentDate: any;
      warrantyBuyType: EnumWarrantyBuyType;
      orderCode?: string | null;
      realAmount: number;
      untaxedAmount: number;
      contactPerson: string;
      contactPhone: string;
      regionId: string;
      status: EnumEyeQuotationOrderStatus;
      address: string;
      currencyId: string;
      standardAmount: number;
      discountRate: number;
      discountAmount: number;
      extraDiscountAmount: number;
      realDiscountAmount: number;
      realDiscountRate: number;
      taxRate: number;
      commissionAmount: number;
      discountRateWithoutCommission: number;
      description?: string | null;
      createdAt: any;
      biddingPrice?: number | null;
      province?: { id: string; name?: string | null } | null;
      city?: { id: string; name: string } | null;
      district?: { id: string; name: string } | null;
      eyeQuotationOrderBusinesses: Array<{
        business?: {
          id: string;
          code?: string | null;
          title?: string | null;
          businessesUsers: Array<{
            id: string;
            user?: { id: string; name?: string | null } | null;
            salesTeam?: { id: string; name: string } | null;
          }>;
        } | null;
      }>;
      creditPeriod?: { id: string; name: string } | null;
      eyeQuotationOrderType?: {
        code?: EnumEyeQuotationOrderType | null;
        name: string;
        id: string;
      } | null;
      region?: {
        id: string;
        name: string;
        localCurrency?: { id: string; name: string } | null;
      } | null;
      eyeQuotationOrderProductItems: Array<{
        id: string;
        materialId: string;
        materialCode: string;
        materialModel?: string | null;
        materialSpec?: string | null;
        materialUnit?: string | null;
        materialName?: string | null;
        qty: number;
        unitPrice: number;
        unitPriceVat: number;
        eyeQuotationOrderProductId?: string | null;
        eyeQuotationOrderPromotionId?: string | null;
        discountedSellingPrice?: number | null;
      }>;
      eyeServiceOrder?: {
        id: string;
        code: string;
        eyeWarrantyContract?: { approvalCode: string } | null;
      } | null;
      customer?: { id: string; code?: string | null; name: string } | null;
      currency?: { id: string; name: string; code: string } | null;
      dept?: {
        id: string;
        code?: string | null;
        name: string;
        company?: { id: string; name: string } | null;
      } | null;
      invoicingCustomer?: {
        id: string;
        code?: string | null;
        name: string;
      } | null;
      createdUser?: {
        id: string;
        code?: string | null;
        name?: string | null;
      } | null;
      user?: { id: string; code?: string | null; name?: string | null } | null;
      eyeQuotationOrderProducts: Array<{
        id: string;
        name: string;
        qty: number;
        model?: string | null;
        brand?: string | null;
        unitPriceVat: number;
        eyeProduct?: {
          id: string;
          name: string;
          salePrice: number;
          status: EnumEyeProductStatus;
          description?: string | null;
          currencyId: string;
          model?: string | null;
          regionId: string;
          brand?: string | null;
          createdAt: any;
          updatedAt: any;
          eyeProductTypeId?: string | null;
          eyeProductType?: {
            id: string;
            regionId: string;
            name: string;
            createdAt: any;
            updatedAt: any;
          } | null;
          eyeProductItems: Array<{
            id: string;
            eyeProductId: string;
            eyeProductItemTypeId: string;
            qty: number;
            isOptional: boolean;
            createdAt: any;
            material: {
              id: string;
              code: string;
              name: string;
              model?: string | null;
              spec?: string | null;
              unit?: string | null;
            };
          }>;
          eyePromotions: Array<{
            id: string;
            name?: string | null;
            isAddon: boolean;
            discountRate?: number | null;
            discountAmount?: number | null;
            createdAt: any;
            date1: any;
            regionId: string;
            updatedAt: any;
            eyePromotionAddonProductItems: Array<{
              id: string;
              qty: number;
              isOptional: boolean;
              material: { id: string; unit?: string | null };
            }>;
          }>;
        } | null;
        items: Array<{
          id: string;
          materialName?: string | null;
          materialCode: string;
          unitPriceVat: number;
          materialUnit?: string | null;
          materialSellingPrice?: number | null;
          discountedSellingPrice?: number | null;
          qty: number;
          unitQty: number;
        }>;
      }>;
      eyeQuotationOrderPromotions: Array<{
        id: string;
        name: string;
        eyePromotionId: string;
        discountRate?: number | null;
        discountAmount?: number | null;
        currencyId: string;
        eyePromotion?: {
          id: string;
          name?: string | null;
          isAddon: boolean;
          discountRate?: number | null;
          discountAmount?: number | null;
          currencyId: string;
          eyePromotionAddonProductItems: Array<{
            id: string;
            qty: number;
            isOptional: boolean;
            material: { id: string; name: string; unit?: string | null };
          }>;
        } | null;
        items: Array<{
          id: string;
          materialName?: string | null;
          materialCode: string;
          unitPriceVat: number;
          materialUnit?: string | null;
          qty: number;
        }>;
      }>;
      eyeQuotationOrderWarrantyItems: Array<{
        id: string;
        materialId: string;
        materialCode: string;
        materialModel?: string | null;
        materialUnit?: string | null;
        materialName?: string | null;
        sn?: string | null;
        udi?: string | null;
        qty: number;
        memo?: string | null;
      }>;
      eyeWarrantyPeriodType?: { id: string; code: string; name: string } | null;
    }>;
  };
};

export type ExchangeRatesQueryVariables = Exact<{
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  filters: ExchangeRateSearchInput;
}>;

export type ExchangeRatesQuery = {
  exchangeRates: {
    count: number;
    rows: Array<{
      id: string;
      rate: number;
      currency1Id: string;
      currency2Id: string;
    }>;
  };
};

export type EyeQuotationOrderTypesQueryVariables = Exact<{
  filters: EyeQuotationOrderTypeSearchInput;
}>;

export type EyeQuotationOrderTypesQuery = {
  eyeQuotationOrderTypes: {
    rows: Array<{
      code?: EnumEyeQuotationOrderType | null;
      id: string;
      name: string;
    }>;
  };
};

export type CreateEyeServiceOrderMutationVariables = Exact<{
  input: EyeServiceOrderCreateInput;
}>;

export type CreateEyeServiceOrderMutation = {
  createEyeServiceOrder: { id: string; code: string };
};

export type UpdateEyeServiceOrderMutationVariables = Exact<{
  input: EyeServiceOrderUpdateInput;
}>;

export type UpdateEyeServiceOrderMutation = {
  updateEyeServiceOrder: { id: string };
};

export type DeleteEyeServiceOrderMutationVariables = Exact<{
  deleteEyeServiceOrderId: Scalars['ID'];
}>;

export type DeleteEyeServiceOrderMutation = { deleteEyeServiceOrder: boolean };

export type CreateEyeServiceOrderItemMutationVariables = Exact<{
  input: EyeServiceOrderItemCreateInput;
}>;

export type CreateEyeServiceOrderItemMutation = {
  createEyeServiceOrderItem: { id: string };
};

export type UpdateEyeServiceOrderItemMutationVariables = Exact<{
  input: EyeServiceOrderItemUpdateInput;
}>;

export type UpdateEyeServiceOrderItemMutation = {
  updateEyeServiceOrderItem: { id: string };
};

export type DeleteEyeServiceOrderItemMutationVariables = Exact<{
  deleteEyeServiceOrderItemId: Scalars['ID'];
}>;

export type DeleteEyeServiceOrderItemMutation = {
  deleteEyeServiceOrderItem: boolean;
};

export type CreateEyeServiceOrderAttachFileMutationVariables = Exact<{
  input: EyeServiceOrderAttachFileCreateInput;
}>;

export type CreateEyeServiceOrderAttachFileMutation = {
  createEyeServiceOrderAttachFile: { id: string };
};

export type DeleteEyeServiceOrderAttachFileMutationVariables = Exact<{
  deleteEyeServiceOrderAttachFileId: Scalars['ID'];
}>;

export type DeleteEyeServiceOrderAttachFileMutation = {
  deleteEyeServiceOrderAttachFile: boolean;
};

export type CreateEyeFixedAssetRentalRecordMutationVariables = Exact<{
  input: EyeFixedAssetRentalRecordCreateInput;
}>;

export type CreateEyeFixedAssetRentalRecordMutation = {
  createEyeFixedAssetRentalRecord: {
    id: string;
    date1: any;
    date2: any;
    status: EnumEyeFixedAssetsRentalStatus;
    remark?: string | null;
    regionId: string;
    company?: { id: string; name: string } | null;
    eyeFixedAssets: Array<{
      id: string;
      name: string;
      isBooking: boolean;
      status: EnumEyeFixedAssetsStatus;
      memo?: string | null;
      createdAt: any;
      updatedAt: any;
      region?: { id: string; name: string } | null;
      dept?: { id: string; code?: string | null; name: string } | null;
      createdUser?: { id: string } | null;
      updatedUser?: { id: string } | null;
      items: Array<{
        materialId: string;
        materialCode: string;
        materialName?: string | null;
        materialModel?: string | null;
        materialSpec?: string | null;
        materialUnit?: string | null;
        udi?: string | null;
        sn?: string | null;
        memo?: string | null;
      }>;
      eyeFixedAssetRentalRecords: Array<{
        id: string;
        eyeServiceOrderId: string;
        date1: any;
        date2: any;
        installDate?: any | null;
        dismantleDate?: any | null;
        status: EnumEyeFixedAssetsRentalStatus;
        remark?: string | null;
        eyeFixedAssets: Array<{ id: string; name: string }>;
        region?: { id: string; name: string } | null;
      }>;
    }>;
    region?: { id: string; name: string } | null;
  };
};

export type UpdateEyeFixedAssetRentalRecordMutationVariables = Exact<{
  input: EyeFixedAssetRentalRecordUpdateInput;
}>;

export type UpdateEyeFixedAssetRentalRecordMutation = {
  updateEyeFixedAssetRentalRecord: {
    id: string;
    date1: any;
    date2: any;
    status: EnumEyeFixedAssetsRentalStatus;
    remark?: string | null;
    regionId: string;
    company?: { id: string; name: string } | null;
    eyeFixedAssets: Array<{
      id: string;
      name: string;
      isBooking: boolean;
      status: EnumEyeFixedAssetsStatus;
      memo?: string | null;
      createdAt: any;
      updatedAt: any;
      region?: { id: string; name: string } | null;
      dept?: { id: string; code?: string | null; name: string } | null;
      createdUser?: { id: string } | null;
      updatedUser?: { id: string } | null;
      items: Array<{
        materialId: string;
        materialCode: string;
        materialName?: string | null;
        materialModel?: string | null;
        materialSpec?: string | null;
        materialUnit?: string | null;
        udi?: string | null;
        sn?: string | null;
        memo?: string | null;
      }>;
      eyeFixedAssetRentalRecords: Array<{
        id: string;
        eyeServiceOrderId: string;
        date1: any;
        date2: any;
        installDate?: any | null;
        dismantleDate?: any | null;
        status: EnumEyeFixedAssetsRentalStatus;
        remark?: string | null;
        eyeFixedAssets: Array<{ id: string; name: string }>;
        region?: { id: string; name: string } | null;
      }>;
    }>;
    region?: { id: string; name: string } | null;
  };
};

export type UpdateEyeServiceOrderApprovalStatusMutationVariables = Exact<{
  input: EyeServiceOrderApprovalStatusUpdateInput;
}>;

export type UpdateEyeServiceOrderApprovalStatusMutation = {
  updateEyeServiceOrderApprovalStatus: { id: string };
};

export type RequestEyeFixedAssetRentalExtensionMutationVariables = Exact<{
  input: EyeServiceOrderRequestExtensionInput;
}>;

export type RequestEyeFixedAssetRentalExtensionMutation = {
  requestEyeFixedAssetRentalExtension: boolean;
};

export type EyeServiceOrdersQueryVariables = Exact<{
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  filters: EyeServiceOrderSearchInput;
}>;

export type EyeServiceOrdersQuery = {
  eyeServiceOrders: {
    count: number;
    rows: Array<{
      id: string;
      priority: EnumEyeServiceOrderPriority;
      code: string;
      businessId?: string | null;
      createdAt: any;
      updatedAt: any;
      contactPerson: string;
      contactPhone: string;
      address: string;
      estimatedDate: any;
      regionId: string;
      status: EnumEyeServiceOrderStatus;
      approvalStatus: EnumEyeServiceOrderApprovalStatus;
      costCenterId?: string | null;
      dispatcherUserId?: string | null;
      memo?: string | null;
      description?: string | null;
      eyeFixedAssetRentalGoalId?: string | null;
      bpmUrl?: any | null;
      extensionCount?: number | null;
      region?: { id: string; name: string } | null;
      createdUser?: {
        id: string;
        name?: string | null;
        createdAt: any;
        updatedAt: any;
      } | null;
      updatedUser?: {
        id: string;
        name?: string | null;
        createdAt: any;
        updatedAt: any;
      } | null;
      eyeServiceOrderType?: {
        id: string;
        name: string;
        code?: EnumEyeServiceOrderTypeCode | null;
      } | null;
      customer?: {
        id: string;
        code?: string | null;
        name: string;
        businessCode?: string | null;
        medicalCode?: string | null;
      } | null;
      company?: { id: string; name: string } | null;
      assigneeDept?: {
        id: string;
        code?: string | null;
        name: string;
        company?: { id: string; name: string } | null;
      } | null;
      assigneeUser?: { id: string; name?: string | null } | null;
      approverUser?: { id: string; name?: string | null } | null;
      eyeWarrantyContract?: { id: string; code: string } | null;
      eyeServiceOrderWorkDiaries: Array<{
        id: string;
        date?: any | null;
        hours?: number | null;
        status: EnumEyeServiceOrderWorkDiaryStatus;
        remark?: string | null;
        users: Array<{ id: string; name?: string | null }>;
        eyeServiceOrder?: {
          code: string;
          status: EnumEyeServiceOrderStatus;
          eyeServiceOrderType?: { name: string } | null;
          customer?: { id: string; name: string } | null;
          assigneeDept?: {
            id: string;
            name: string;
            company?: { id: string; name: string } | null;
          } | null;
        } | null;
      }>;
      eyeQuotationOrders: Array<{
        id: string;
        code: string;
        orderCode?: string | null;
        realAmount: number;
        untaxedAmount: number;
        contactPerson: string;
        contactPhone: string;
        address: string;
        status: EnumEyeQuotationOrderStatus;
        createdAt: any;
        customer?: { id: string; code?: string | null; name: string } | null;
        currency?: { id: string; name: string } | null;
        dept?: { id: string; code?: string | null; name: string } | null;
        invoicingCustomer?: {
          id: string;
          code?: string | null;
          name: string;
        } | null;
        eyeServiceOrder?: {
          eyeWarrantyContract?: { approvalCode: string } | null;
        } | null;
        eyeQuotationOrderBusinesses: Array<{
          business?: {
            id: string;
            code?: string | null;
            title?: string | null;
          } | null;
        }>;
        user?: { id: string; name?: string | null } | null;
      }>;
      eyeFixedAssetRentalRecords: Array<{
        id: string;
        date1: any;
        date2: any;
        installDate?: any | null;
        dismantleDate?: any | null;
        status: EnumEyeFixedAssetsRentalStatus;
        remark?: string | null;
        regionId: string;
        eyeFixedAssets: Array<{
          id: string;
          name: string;
          status: EnumEyeFixedAssetsStatus;
          memo?: string | null;
          isBooking: boolean;
          createdAt: any;
          updatedAt: any;
          currentLocation?: string | null;
          region?: { id: string; name: string } | null;
          dept?: { id: string; code?: string | null; name: string } | null;
          createdUser?: { id: string } | null;
          updatedUser?: { id: string } | null;
          items: Array<{
            materialId: string;
            materialCode: string;
            materialName?: string | null;
            materialModel?: string | null;
            materialSpec?: string | null;
            materialUnit?: string | null;
            udi?: string | null;
            sn?: string | null;
            memo?: string | null;
          }>;
          eyeFixedAssetRentalRecords: Array<{
            id: string;
            eyeServiceOrderId: string;
            date1: any;
            date2: any;
            installDate?: any | null;
            dismantleDate?: any | null;
            status: EnumEyeFixedAssetsRentalStatus;
            remark?: string | null;
            eyeServiceOrder?: {
              id: string;
              code: string;
              estimatedDate: any;
              assigneeUser?: {
                id: string;
                code?: string | null;
                name?: string | null;
              } | null;
            } | null;
            eyeFixedAssets: Array<{ id: string; name: string }>;
            region?: { id: string; name: string } | null;
          }>;
        }>;
        region?: { id: string; name: string } | null;
        company?: { id: string; name: string } | null;
      }>;
      eyeFixedAssetRentalGoal?: {
        id: string;
        code?: string | null;
        name: string;
      } | null;
      extension?: {
        extendDate1: any;
        extendDate2: any;
        bpmUrl?: any | null;
        approvalStatus: EnumEyeServiceOrderExtensionApprovalStatus;
      } | null;
      user?: {
        id: string;
        code?: string | null;
        name?: string | null;
        dept?: { id: string; name: string } | null;
      } | null;
    }>;
  };
};

export type EyeServiceOrderPendingAssignmentQueryVariables = Exact<{
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  filters: EyeServiceOrderSearchInput;
}>;

export type EyeServiceOrderPendingAssignmentQuery = {
  eyeServiceOrderPendingAssignment: {
    count: number;
    rows: Array<{
      id: string;
      priority: EnumEyeServiceOrderPriority;
      code: string;
      businessId?: string | null;
      createdAt: any;
      updatedAt: any;
      contactPerson: string;
      contactPhone: string;
      address: string;
      estimatedDate: any;
      regionId: string;
      status: EnumEyeServiceOrderStatus;
      approvalStatus: EnumEyeServiceOrderApprovalStatus;
      costCenterId?: string | null;
      dispatcherUserId?: string | null;
      memo?: string | null;
      description?: string | null;
      eyeFixedAssetRentalGoalId?: string | null;
      bpmUrl?: any | null;
      extensionCount?: number | null;
      region?: { id: string; name: string } | null;
      createdUser?: {
        id: string;
        name?: string | null;
        createdAt: any;
        updatedAt: any;
      } | null;
      updatedUser?: {
        id: string;
        name?: string | null;
        createdAt: any;
        updatedAt: any;
      } | null;
      eyeServiceOrderType?: {
        id: string;
        name: string;
        code?: EnumEyeServiceOrderTypeCode | null;
      } | null;
      customer?: {
        id: string;
        code?: string | null;
        name: string;
        businessCode?: string | null;
        medicalCode?: string | null;
      } | null;
      company?: { id: string; name: string } | null;
      assigneeDept?: {
        id: string;
        code?: string | null;
        name: string;
        company?: { id: string; name: string } | null;
      } | null;
      assigneeUser?: { id: string; name?: string | null } | null;
      approverUser?: { id: string; name?: string | null } | null;
      eyeWarrantyContract?: { id: string; code: string } | null;
      eyeServiceOrderWorkDiaries: Array<{
        id: string;
        date?: any | null;
        hours?: number | null;
        status: EnumEyeServiceOrderWorkDiaryStatus;
        remark?: string | null;
        users: Array<{ id: string; name?: string | null }>;
        eyeServiceOrder?: {
          code: string;
          status: EnumEyeServiceOrderStatus;
          eyeServiceOrderType?: { name: string } | null;
          customer?: { id: string; name: string } | null;
          assigneeDept?: {
            id: string;
            name: string;
            company?: { id: string; name: string } | null;
          } | null;
        } | null;
      }>;
      eyeQuotationOrders: Array<{
        id: string;
        code: string;
        orderCode?: string | null;
        realAmount: number;
        untaxedAmount: number;
        contactPerson: string;
        contactPhone: string;
        address: string;
        status: EnumEyeQuotationOrderStatus;
        createdAt: any;
        customer?: { id: string; code?: string | null; name: string } | null;
        currency?: { id: string; name: string } | null;
        dept?: { id: string; code?: string | null; name: string } | null;
        invoicingCustomer?: {
          id: string;
          code?: string | null;
          name: string;
        } | null;
        eyeServiceOrder?: {
          eyeWarrantyContract?: { approvalCode: string } | null;
        } | null;
        eyeQuotationOrderBusinesses: Array<{
          business?: {
            id: string;
            code?: string | null;
            title?: string | null;
          } | null;
        }>;
        user?: { id: string; name?: string | null } | null;
      }>;
      eyeFixedAssetRentalRecords: Array<{
        id: string;
        date1: any;
        date2: any;
        installDate?: any | null;
        dismantleDate?: any | null;
        status: EnumEyeFixedAssetsRentalStatus;
        remark?: string | null;
        regionId: string;
        eyeFixedAssets: Array<{
          id: string;
          name: string;
          status: EnumEyeFixedAssetsStatus;
          memo?: string | null;
          isBooking: boolean;
          createdAt: any;
          updatedAt: any;
          currentLocation?: string | null;
          region?: { id: string; name: string } | null;
          dept?: { id: string; code?: string | null; name: string } | null;
          createdUser?: { id: string } | null;
          updatedUser?: { id: string } | null;
          items: Array<{
            materialId: string;
            materialCode: string;
            materialName?: string | null;
            materialModel?: string | null;
            materialSpec?: string | null;
            materialUnit?: string | null;
            udi?: string | null;
            sn?: string | null;
            memo?: string | null;
          }>;
          eyeFixedAssetRentalRecords: Array<{
            id: string;
            eyeServiceOrderId: string;
            date1: any;
            date2: any;
            installDate?: any | null;
            dismantleDate?: any | null;
            status: EnumEyeFixedAssetsRentalStatus;
            remark?: string | null;
            eyeServiceOrder?: {
              id: string;
              code: string;
              estimatedDate: any;
              assigneeUser?: {
                id: string;
                code?: string | null;
                name?: string | null;
              } | null;
            } | null;
            eyeFixedAssets: Array<{ id: string; name: string }>;
            region?: { id: string; name: string } | null;
          }>;
        }>;
        region?: { id: string; name: string } | null;
        company?: { id: string; name: string } | null;
      }>;
      eyeFixedAssetRentalGoal?: {
        id: string;
        code?: string | null;
        name: string;
      } | null;
      extension?: {
        extendDate1: any;
        extendDate2: any;
        bpmUrl?: any | null;
        approvalStatus: EnumEyeServiceOrderExtensionApprovalStatus;
      } | null;
      user?: {
        id: string;
        code?: string | null;
        name?: string | null;
        dept?: { id: string; name: string } | null;
      } | null;
    }>;
  };
};

export type EyeServiceOrderTypesQueryVariables = Exact<{
  filters: EyeServiceOrderTypeSearchInput;
}>;

export type EyeServiceOrderTypesQuery = {
  eyeServiceOrderTypes: {
    rows: Array<{
      id: string;
      code?: EnumEyeServiceOrderTypeCode | null;
      name: string;
    }>;
  };
};

export type CompaniesQueryVariables = Exact<{
  filters: CompanySearchInput;
}>;

export type CompaniesQuery = {
  companies: {
    rows: Array<{
      id: string;
      name: string;
      depts?: Array<{
        id: string;
        companyId?: string | null;
        name: string;
        code?: string | null;
      }> | null;
    }>;
  };
};

export type EyeServiceOrderItemsQueryVariables = Exact<{
  filters: EyeServiceOrderItemSearchInput;
}>;

export type EyeServiceOrderItemsQuery = {
  eyeServiceOrderItems: {
    rows: Array<{
      id: string;
      materialId: string;
      materialCode: string;
      materialModel?: string | null;
      materialSpec?: string | null;
      materialUnit?: string | null;
      materialName?: string | null;
      sn?: string | null;
      udi: string;
      qty: number;
      memo?: string | null;
    }>;
  };
};

export type EyeServiceOrderAttachFilesQueryVariables = Exact<{
  filters: EyeServiceOrderAttachFileSearchInput;
  offset?: InputMaybe<Scalars['Int']>;
  limit?: InputMaybe<Scalars['Int']>;
}>;

export type EyeServiceOrderAttachFilesQuery = {
  eyeServiceOrderAttachFiles: {
    rows: Array<{ url?: any | null; id: string; name: string }>;
  };
};

export type CostCentersQueryVariables = Exact<{
  filters: CostCenterSearchInput;
  limit?: InputMaybe<Scalars['Int']>;
}>;

export type CostCentersQuery = {
  costCenters: {
    count: number;
    rows: Array<{ id: string; name: string; isActive?: boolean | null }>;
  };
};

export type DispatcherUsersQueryVariables = Exact<{
  filters: DispatcherUserSearchInput;
  limit?: InputMaybe<Scalars['Int']>;
}>;

export type DispatcherUsersQuery = {
  dispatcherUsers: {
    rows: Array<{
      id: string;
      isActive?: boolean | null;
      user?: { name?: string | null; code?: string | null } | null;
    }>;
  };
};

export type EyeFixedAssetsItemQueryVariables = Exact<{
  filters: EyeFixedAssetSearchInput;
}>;

export type EyeFixedAssetsItemQuery = {
  eyeFixedAssets: {
    rows: Array<{
      id: string;
      name: string;
      currentLocation?: string | null;
      memo?: string | null;
      region?: { id: string; name: string } | null;
      company?: { id: string; name: string } | null;
      eyeFixedAssetRentalRecords: Array<{
        status: EnumEyeFixedAssetsRentalStatus;
      }>;
      items: Array<{ materialId: string; materialCode: string }>;
    }>;
  };
};

export type EyeFixedAssetRentalObjectsQueryVariables = Exact<{
  filters: EyeFixedAssetRentalObjectSearchInput;
  limit?: InputMaybe<Scalars['Int']>;
}>;

export type EyeFixedAssetRentalObjectsQuery = {
  eyeFixedAssetRentalObjects: {
    rows: Array<{
      name: string;
      id: string;
      code?: EnumEyeFixedAssetRentalObjectCode | null;
    }>;
  };
};

export type EyeFixedAssetRentalGoalsQueryVariables = Exact<{
  filters: EyeFixedAssetRentalGoalSearchInput;
  limit?: InputMaybe<Scalars['Int']>;
}>;

export type EyeFixedAssetRentalGoalsQuery = {
  eyeFixedAssetRentalGoals: {
    rows: Array<{
      id: string;
      code?: string | null;
      name: string;
      isActive: boolean;
    }>;
  };
};

export type CreateEyeServiceOrderWorkDiaryMutationVariables = Exact<{
  input: EyeServiceOrderWorkDiaryCreateInput;
}>;

export type CreateEyeServiceOrderWorkDiaryMutation = {
  createEyeServiceOrderWorkDiary: { id: string };
};

export type UpdateEyeServiceOrderWorkDiaryMutationVariables = Exact<{
  input: EyeServiceOrderWorkDiaryUpdateInput;
}>;

export type UpdateEyeServiceOrderWorkDiaryMutation = {
  updateEyeServiceOrderWorkDiary: { id: string };
};

export type DeleteEyeServiceOrderWorkDiaryMutationVariables = Exact<{
  deleteEyeServiceOrderWorkDiaryId: Scalars['ID'];
}>;

export type DeleteEyeServiceOrderWorkDiaryMutation = {
  deleteEyeServiceOrderWorkDiary: boolean;
};

export type CreateEyeServiceOrderWorkDiaryItemMutationVariables = Exact<{
  input: EyeServiceOrderWorkDiaryItemCreateInput;
}>;

export type CreateEyeServiceOrderWorkDiaryItemMutation = {
  createEyeServiceOrderWorkDiaryItem: { id: string };
};

export type UpdateEyeServiceOrderWorkDiaryItemMutationVariables = Exact<{
  input: EyeServiceOrderWorkDiaryItemUpdateInput;
}>;

export type UpdateEyeServiceOrderWorkDiaryItemMutation = {
  updateEyeServiceOrderWorkDiaryItem: { id: string };
};

export type DeleteEyeServiceOrderWorkDiaryItemMutationVariables = Exact<{
  deleteEyeServiceOrderWorkDiaryItemId: Scalars['ID'];
}>;

export type DeleteEyeServiceOrderWorkDiaryItemMutation = {
  deleteEyeServiceOrderWorkDiaryItem: boolean;
};

export type CreateEyeServiceOrderWorkDiaryAttachFileMutationVariables = Exact<{
  input: EyeServiceOrderWorkDiaryAttachFileCreateInput;
}>;

export type CreateEyeServiceOrderWorkDiaryAttachFileMutation = {
  createEyeServiceOrderWorkDiaryAttachFile: { id: string };
};

export type UpdateEyeServiceOrderWorkDiaryAttachFileMutationVariables = Exact<{
  input: EyeServiceOrderWorkDiaryAttachFileUpdateInput;
}>;

export type UpdateEyeServiceOrderWorkDiaryAttachFileMutation = {
  updateEyeServiceOrderWorkDiaryAttachFile: { id: string };
};

export type DeleteEyeServiceOrderWorkDiaryAttachFileMutationVariables = Exact<{
  deleteEyeServiceOrderWorkDiaryAttachFileId: Scalars['ID'];
}>;

export type DeleteEyeServiceOrderWorkDiaryAttachFileMutation = {
  deleteEyeServiceOrderWorkDiaryAttachFile: boolean;
};

export type CreateEyeServiceOrderWorkDiaryCheckInMutationVariables = Exact<{
  input: EyeServiceOrderWorkDiaryCheckInCreateInput;
}>;

export type CreateEyeServiceOrderWorkDiaryCheckInMutation = {
  createEyeServiceOrderWorkDiaryCheckIn: { id: string };
};

export type EyeServiceOrderWorkDiariesQueryVariables = Exact<{
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  filters: EyeServiceOrderWorkDiarySearchInput;
}>;

export type EyeServiceOrderWorkDiariesQuery = {
  eyeServiceOrderWorkDiaries: {
    count: number;
    rows: Array<{
      id: string;
      date?: any | null;
      hours?: number | null;
      status: EnumEyeServiceOrderWorkDiaryStatus;
      remark?: string | null;
      estimatedHours?: number | null;
      region?: { id: string; name: string } | null;
      users: Array<{
        id: string;
        code?: string | null;
        name?: string | null;
        dept?: { id: string; code?: string | null; name: string } | null;
      }>;
      eyeServiceOrder?: {
        id: string;
        code: string;
        eyeServiceOrderTypeId: string;
        status: EnumEyeServiceOrderStatus;
        contactPerson: string;
        contactPhone: string;
        address: string;
        eyeServiceOrderType?: { name: string } | null;
        customer?: { id: string; name: string } | null;
        assigneeDept?: { id: string; name: string } | null;
      } | null;
      eyeServiceOrderWorkDiaryType?: { id: string; name: string } | null;
    }>;
  };
};

export type EyeServiceOrderWorkDiaryTypesQueryVariables = Exact<{
  filters: EyeServiceOrderWorkDiaryTypeSearchInput;
}>;

export type EyeServiceOrderWorkDiaryTypesQuery = {
  eyeServiceOrderWorkDiaryTypes: { rows: Array<{ id: string; name: string }> };
};

export type EyeServiceOrderWorkDiaryItemsQueryVariables = Exact<{
  filters: EyeServiceOrderWorkDiaryItemSearchInput;
  offset?: InputMaybe<Scalars['Int']>;
  limit?: InputMaybe<Scalars['Int']>;
}>;

export type EyeServiceOrderWorkDiaryItemsQuery = {
  eyeServiceOrderWorkDiaryItems: {
    rows: Array<{
      id: string;
      materialId: string;
      materialCode: string;
      materialModel?: string | null;
      materialSpec?: string | null;
      materialUnit?: string | null;
      materialName?: string | null;
    }>;
  };
};

export type EyeServiceOrderWorkDiaryAttachFilesQueryVariables = Exact<{
  filters: EyeServiceOrderWorkDiaryAttachFileSearchInput;
  offset?: InputMaybe<Scalars['Int']>;
  limit?: InputMaybe<Scalars['Int']>;
}>;

export type EyeServiceOrderWorkDiaryAttachFilesQuery = {
  eyeServiceOrderWorkDiaryAttachFiles: {
    rows: Array<{ url?: any | null; id: string; name: string }>;
  };
};

export type EyeServiceOrderWorkDiaryCheckInsQueryVariables = Exact<{
  filters: EyeServiceOrderWorkDiaryCheckInSearchInput;
}>;

export type EyeServiceOrderWorkDiaryCheckInsQuery = {
  eyeServiceOrderWorkDiaryCheckIns: {
    count: number;
    rows: Array<{
      id: string;
      content?: string | null;
      lat?: number | null;
      lng?: number | null;
      createdAt: any;
    }>;
  };
};

export type CreateEyeWarrantyContractMutationVariables = Exact<{
  input: EyeWarrantyContractCreateInput;
}>;

export type CreateEyeWarrantyContractMutation = {
  createEyeWarrantyContract: { id: string; code: string };
};

export type UpdateEyeWarrantyContractMutationVariables = Exact<{
  input: EyeWarrantyContractUpdateInput;
}>;

export type UpdateEyeWarrantyContractMutation = {
  updateEyeWarrantyContract: { id: string };
};

export type DeleteEyeWarrantyContractMutationVariables = Exact<{
  deleteEyeWarrantyContractId: Scalars['ID'];
}>;

export type DeleteEyeWarrantyContractMutation = {
  deleteEyeWarrantyContract: boolean;
};

export type CreateEyeWarrantyContractItemMutationVariables = Exact<{
  input: EyeWarrantyContractItemCreateInput;
}>;

export type CreateEyeWarrantyContractItemMutation = {
  createEyeWarrantyContractItem: { id: string };
};

export type UpdateEyeWarrantyContractItemMutationVariables = Exact<{
  input: EyeWarrantyContractItemUpdateInput;
}>;

export type UpdateEyeWarrantyContractItemMutation = {
  updateEyeWarrantyContractItem: { id: string };
};

export type DeleteEyeWarrantyContractItemMutationVariables = Exact<{
  deleteEyeWarrantyContractItemId: Scalars['ID'];
}>;

export type DeleteEyeWarrantyContractItemMutation = {
  deleteEyeWarrantyContractItem: boolean;
};

export type EyeWarrantyContractsQueryVariables = Exact<{
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  filters: EyeWarrantyContractSearchInput;
}>;

export type EyeWarrantyContractsQuery = {
  eyeWarrantyContracts: {
    count: number;
    rows: Array<{
      id: string;
      code: string;
      orderCode: string;
      approvalCode: string;
      customerId: string;
      createdAt: any;
      updatedAt: any;
      contactPerson: string;
      contactPhone: string;
      warrantyMonths: number;
      warrantyDate1: any;
      warrantyDate2: any;
      warrantyPeriodTypeId: string;
      status: EnumEyeWarrantyContractStatus;
      description?: string | null;
      memo?: string | null;
      customer?: {
        code?: string | null;
        name: string;
        address?: string | null;
      } | null;
      createdUser?: {
        id: string;
        name?: string | null;
        createdAt: any;
        updatedAt: any;
      } | null;
      updatedUser?: {
        id: string;
        name?: string | null;
        createdAt: any;
        updatedAt: any;
      } | null;
      company?: { id: string; name: string } | null;
      dept?: { id: string; code?: string | null; name: string } | null;
      eyeWarrantyPeriodType?: { name: string } | null;
      region?: { id: string; name: string } | null;
      user?: {
        id: string;
        name?: string | null;
        dept?: { id: string; name: string } | null;
      } | null;
      eyeWarrantyContractItems: Array<{
        materialId: string;
        materialCode: string;
        materialName?: string | null;
        materialModel?: string | null;
        materialSpec?: string | null;
        materialUnit?: string | null;
        qty: number;
        udi?: string | null;
        sn?: string | null;
        memo?: string | null;
      }>;
      eyeServiceOrder: Array<{
        id: string;
        priority: EnumEyeServiceOrderPriority;
        code: string;
        estimatedDate: any;
        status: EnumEyeServiceOrderStatus;
        approvalStatus: EnumEyeServiceOrderApprovalStatus;
        eyeServiceOrderType?: { id: string; name: string } | null;
        customer?: { id: string; code?: string | null; name: string } | null;
        assigneeDept?: { id: string; name: string } | null;
        assigneeUser?: { id: string; name?: string | null } | null;
        approverUser?: { id: string; name?: string | null } | null;
        eyeServiceOrderWorkDiaries: Array<{
          id: string;
          date?: any | null;
          hours?: number | null;
          status: EnumEyeServiceOrderWorkDiaryStatus;
          remark?: string | null;
          users: Array<{ id: string; name?: string | null }>;
          eyeServiceOrder?: {
            code: string;
            status: EnumEyeServiceOrderStatus;
            eyeServiceOrderType?: { name: string } | null;
            customer?: { id: string; name: string } | null;
            assigneeDept?: { id: string; name: string } | null;
          } | null;
        }>;
      }>;
    }>;
  };
};

export type EyeWarrantyPeriodTypesQueryVariables = Exact<{
  filters: EyeWarrantyPeriodTypeSearchInput;
}>;

export type EyeWarrantyPeriodTypesQuery = {
  eyeWarrantyPeriodTypes: { rows: Array<{ name: string; id: string }> };
};

export type EyeWarrantyContractItemsQueryVariables = Exact<{
  filters: EyeWarrantyContractItemSearchInput;
  offset?: InputMaybe<Scalars['Int']>;
  limit?: InputMaybe<Scalars['Int']>;
}>;

export type EyeWarrantyContractItemsQuery = {
  eyeWarrantyContractItems: {
    rows: Array<{
      id: string;
      materialId: string;
      materialCode: string;
      materialModel?: string | null;
      materialSpec?: string | null;
      materialUnit?: string | null;
      materialName?: string | null;
      sn?: string | null;
      udi?: string | null;
      qty: number;
      memo?: string | null;
    }>;
  };
};

export type EcInventoryModelsQueryVariables = Exact<{ [key: string]: never }>;

export type EcInventoryModelsQuery = {
  EcInventoryModels: Array<{ id: string; name?: string | null }>;
};

export type EcInventorySpecsQueryVariables = Exact<{ [key: string]: never }>;

export type EcInventorySpecsQuery = {
  EcInventorySpecs: Array<{
    id: string;
    modelId: string;
    name?: string | null;
    model?: { id: string; name?: string | null } | null;
  }>;
};

export type EcInventoryWarehousesQueryVariables = Exact<{
  [key: string]: never;
}>;

export type EcInventoryWarehousesQuery = {
  EcInventoryWarehouses: Array<{ id: string; name?: string | null }>;
};

export type EcInventoryStatQueryVariables = Exact<{
  filter: EcInventoryStatFilterInput;
}>;

export type EcInventoryStatQuery = {
  EcInventoryStat: {
    details: Array<{
      modelId: string;
      qty: number;
      warehouses: Array<{
        warehouse: EnumEcInventoryWarehouse;
        qty: number;
        statuses: Array<{ status: EnumEcInventoryStatus; qty: number }>;
      }>;
      rentalPeriods: Array<{
        rentalPeriod: EnumEcInventoryRentalPeriod;
        qty: number;
      }>;
    }>;
  };
};

export type EcInventoryDetailsQueryVariables = Exact<{
  filter: EcInventoryDetailFilterInput;
}>;

export type EcInventoryDetailsQuery = {
  EcInventoryDetails: Array<{
    model: string;
    spec: string;
    status?: EnumEcInventoryStatus | null;
    rentalPeriod?: EnumEcInventoryRentalPeriod | null;
    warehouse?: EnumEcInventoryWarehouse | null;
    materialCode: string;
    materialName: string;
    customerCode?: string | null;
    customerName?: string | null;
    limitDate?: string | null;
    orderDate?: string | null;
    qty: number;
  }>;
};

export type CreateMaterialMutationVariables = Exact<{
  input: MaterialCreateInput;
}>;

export type CreateMaterialMutation = {
  createMaterial: { id: string; name: string };
};

export type UpdateMaterialMutationVariables = Exact<{
  input: MaterialUpdateInput;
}>;

export type UpdateMaterialMutation = {
  updateMaterial: { id: string; name: string };
};

export type MaterialsQueryVariables = Exact<{
  offset?: InputMaybe<Scalars['Int']>;
  limit?: InputMaybe<Scalars['Int']>;
  filters: MaterialSearchInput;
}>;

export type MaterialsQuery = {
  materials: {
    count: number;
    pageInfo: { hasNextPage: boolean };
    rows: Array<{
      id: string;
      code: string;
      brand?: string | null;
      model?: string | null;
      name: string;
      name2?: string | null;
      spec?: string | null;
      unit?: string | null;
      color?: string | null;
      status: EnumMaterialStatus;
      companyId: string;
      regionId: string;
      taxRate: number;
      salePrice?: number | null;
      saleCurrencyId?: string | null;
      costPrice?: number | null;
      costCurrencyId?: string | null;
      createdAt: any;
      updatedAt: any;
      type?: { id: string; name: string } | null;
      company?: { id: string; name: string; code?: string | null } | null;
      region?: { id: string; name: string; code?: string | null } | null;
      repairType?: { id: string; name: string } | null;
      supplier?: { id: string; name: string } | null;
      images: Array<{
        id: string;
        url?: any | null;
        name?: string | null;
        size: number;
        s3Key: string;
      }>;
      salesTypes: Array<{ id: string; name: string }>;
      salesProductTypes: Array<{ id: string; name: string }>;
      countingUnit?: { id: string; name: string } | null;
      syncSource?: { id: string; name: string } | null;
      createdUser?: { id: string; name?: string | null } | null;
      updatedUser?: { id: string; name?: string | null } | null;
    }>;
  };
};

export type MaterialRepairTypesQueryVariables = Exact<{
  filters: MaterialRepairTypeSearchInput;
}>;

export type MaterialRepairTypesQuery = {
  materialRepairTypes: {
    rows: Array<{ id: string; name: string; code: string }>;
  };
};

export type SuppliersQueryVariables = Exact<{
  filters: SupplierSearchInput;
}>;

export type SuppliersQuery = {
  suppliers: {
    rows: Array<{
      id: string;
      code: string;
      name: string;
      name2?: string | null;
    }>;
  };
};

export type CountingUnitsQueryVariables = Exact<{
  filters: CountingUnitSearchInput;
  limit?: InputMaybe<Scalars['Int']>;
}>;

export type CountingUnitsQuery = {
  countingUnits: {
    rows: Array<{ id: string; name: string; code?: string | null }>;
  };
};

export type ReadNotificationsMutationVariables = Exact<{
  ids: Array<Scalars['ID']> | Scalars['ID'];
}>;

export type ReadNotificationsMutation = {
  readNotifications: Array<{
    id: string;
    userId?: string | null;
    isRead: boolean;
    createdAt: any;
    message?: string | null;
    type?: EnumNotificationType | null;
  }>;
};

export type NotificationsQueryVariables = Exact<{
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  filters: NotificationSearchInput;
}>;

export type NotificationsQuery = {
  notifications: {
    count: number;
    rows: Array<{
      id: string;
      userId?: string | null;
      type?: EnumNotificationType | null;
      message?: string | null;
      isRead: boolean;
      createdAt: any;
      updatedAt: any;
      values?: { path?: string | null } | {} | null;
    }>;
  };
};

export type NotificationSubscriptionSubscriptionVariables = Exact<{
  [key: string]: never;
}>;

export type NotificationSubscriptionSubscription = {
  notificationSubscription: {
    id: string;
    userId?: string | null;
    isRead: boolean;
    createdAt: any;
    message?: string | null;
    type?: EnumNotificationType | null;
    values?: { path?: string | null } | {} | null;
  };
};

export type BulkUpdateSalesPerformanceSalesProductUserGoalViewMutationVariables =
  Exact<{
    input: SalesPerformanceSalesProductUserGoalViewBulkUpdateInput;
  }>;

export type BulkUpdateSalesPerformanceSalesProductUserGoalViewMutation = {
  bulkUpdateSalesPerformanceSalesProductUserGoalView: Array<{
    amount: number;
    createdAt: any;
    id: string;
    month: number;
    qty: number;
    updatedAt: any;
    userGoalId: string;
  }>;
};

export type BulkUpdateSalesPerformanceSalesProductTeamGoalViewMutationVariables =
  Exact<{
    input: SalesPerformanceSalesProductTeamGoalViewBulkUpdateInput;
  }>;

export type BulkUpdateSalesPerformanceSalesProductTeamGoalViewMutation = {
  bulkUpdateSalesPerformanceSalesProductTeamGoalView: Array<{
    id: string;
    teamGoalId: string;
    month: number;
    amount: number;
    qty: number;
    createdAt: any;
    updatedAt: any;
  }>;
};

export type BulkUpdateSalesPerformanceSalesProductUserForecastViewMutationVariables =
  Exact<{
    input: SalesPerformanceSalesProductUserForecastViewBulkUpdateInput;
  }>;

export type BulkUpdateSalesPerformanceSalesProductUserForecastViewMutation = {
  bulkUpdateSalesPerformanceSalesProductUserForecastView: Array<{
    id: string;
    userForecastId: string;
    month: number;
    qtyEarlyMonth: number;
    qtyLateMonth: number;
    createdAt: any;
    updatedAt: any;
    quota: number;
  }>;
};

export type SalesPerformanceSalesProductTypesQueryVariables = Exact<{
  filters: SalesPerformanceSalesProductTypeSearchInput;
  offset?: InputMaybe<Scalars['Int']>;
  limit?: InputMaybe<Scalars['Int']>;
}>;

export type SalesPerformanceSalesProductTypesQuery = {
  salesPerformanceSalesProductTypes: {
    count: number;
    rows: Array<{
      id: string;
      name: string;
      code?: string | null;
      createdAt: any;
      salesTeamGroupId: string;
      updatedAt: any;
      viewOrder?: number | null;
      salesTeamGroup?: { id: string; name: string } | null;
    }>;
  };
};

export type SalesPerformanceSalesProductUserGoalViewQueryVariables = Exact<{
  salesTeamGroupId: Scalars['ID'];
  year: Scalars['Int'];
  userIds?: InputMaybe<Array<Scalars['ID']> | Scalars['ID']>;
}>;

export type SalesPerformanceSalesProductUserGoalViewQuery = {
  salesPerformanceSalesProductUserGoalView: {
    year: number;
    salesTeamGroupId: string;
    users: Array<{
      salesTeamUnitId: string;
      months: Array<{
        month: number;
        types: Array<{
          salesProductTypeId: string;
          amount: number;
          qty: number;
          salesProductType?: {
            id: string;
            name: string;
            code?: string | null;
          } | null;
        }>;
      }>;
      salesTeamUnit?: { id: string; name: string } | null;
    }>;
    salesTeamGroup?: {
      id: string;
      name: string;
      code?: EnumSalesTeamGroupCode | null;
      directorId?: string | null;
      regionId?: string | null;
      companyId?: string | null;
    } | null;
  };
};

export type SalesPerformanceSalesProductTeamGoalViewQueryVariables = Exact<{
  salesTeamGroupId: Scalars['ID'];
  year: Scalars['Int'];
  salesTeamIds?: InputMaybe<Array<Scalars['ID']> | Scalars['ID']>;
}>;

export type SalesPerformanceSalesProductTeamGoalViewQuery = {
  salesPerformanceSalesProductTeamGoalView: {
    year: number;
    salesTeamGroupId: string;
    teams: Array<{
      salesTeamId: string;
      months: Array<{
        month: number;
        types: Array<{
          salesProductTypeId: string;
          amount: number;
          qty: number;
          salesProductType?: {
            id: string;
            name: string;
            code?: string | null;
          } | null;
        }>;
      }>;
      salesTeam?: { id: string; name: string } | null;
    }>;
    salesTeamGroup?: {
      id: string;
      name: string;
      code?: EnumSalesTeamGroupCode | null;
      directorId?: string | null;
      regionId?: string | null;
      companyId?: string | null;
    } | null;
  };
};

export type SalesPerformanceSalesProductUserForecastViewQueryVariables = Exact<{
  salesTeamGroupId: Scalars['ID'];
  year: Scalars['Int'];
  month1?: InputMaybe<Scalars['Int']>;
  month2?: InputMaybe<Scalars['Int']>;
  userIds?: InputMaybe<Array<Scalars['ID']> | Scalars['ID']>;
}>;

export type SalesPerformanceSalesProductUserForecastViewQuery = {
  salesPerformanceSalesProductUserForecastView: {
    year: number;
    salesTeamGroupId: string;
    salesTeamGroup?: { id: string; name: string } | null;
    overall: {
      months: Array<{
        month: number;
        types: Array<{
          salesProductTypeId: string;
          quota: number;
          qtyEarlyMonth: number;
          qtyEarlyMonthAccuracyRate?: number | null;
          qtyLateMonth: number;
          qtyLateMonthAccuracyRate?: number | null;
          qtyActual: number;
          salesProductType?: {
            id: string;
            name: string;
            code?: string | null;
          } | null;
        }>;
      }>;
    };
    users: Array<{
      salesTeamUnit?: { id: string; name: string } | null;
      months: Array<{
        month: number;
        types: Array<{
          salesProductTypeId: string;
          quota: number;
          quotaAchievementRate?: number | null;
          qtyEarlyMonth: number;
          qtyEarlyMonthAccuracyRate?: number | null;
          qtyLateMonth: number;
          qtyLateMonthAccuracyRate?: number | null;
          qtyActual: number;
          salesProductType?: {
            id: string;
            name: string;
            code?: string | null;
          } | null;
        }>;
      }>;
    }>;
  };
};

export type BulkUpdateSalesPerformanceSalesUserGoalViewMutationVariables =
  Exact<{
    input: SalesPerformanceSalesUserGoalViewBulkUpdateInput;
  }>;

export type BulkUpdateSalesPerformanceSalesUserGoalViewMutation = {
  bulkUpdateSalesPerformanceSalesUserGoalView: Array<{
    id: string;
    month: number;
    userGoalId: string;
    amount: number;
    createdAt: any;
    updatedAt: any;
  }>;
};

export type BulkUpdateSalesPerformanceSalesTeamGoalViewMutationVariables =
  Exact<{
    input: SalesPerformanceSalesTeamGoalViewBulkUpdateInput;
  }>;

export type BulkUpdateSalesPerformanceSalesTeamGoalViewMutation = {
  bulkUpdateSalesPerformanceSalesTeamGoalView: Array<{
    id: string;
    teamGoalId: string;
    month: number;
    amount: number;
    createdAt: any;
    updatedAt: any;
  }>;
};

export type BulkUpdateSalesPerformanceSalesUserForecastViewMutationVariables =
  Exact<{
    input: SalesPerformanceSalesUserForecastViewBulkUpdateInput;
  }>;

export type BulkUpdateSalesPerformanceSalesUserForecastViewMutation = {
  bulkUpdateSalesPerformanceSalesUserForecastView: Array<{
    id: string;
    userForecastId: string;
    month: number;
    amountEarlyMonth: number;
    amountLateMonth: number;
    createdAt: any;
    updatedAt: any;
    quota: number;
  }>;
};

export type SalesPerformanceSalesTypesQueryVariables = Exact<{
  filters: SalesPerformanceSalesTypeSearchInput;
  offset?: InputMaybe<Scalars['Int']>;
  limit?: InputMaybe<Scalars['Int']>;
}>;

export type SalesPerformanceSalesTypesQuery = {
  salesPerformanceSalesTypes: {
    count: number;
    rows: Array<{
      id: string;
      salesTeamGroupId: string;
      name: string;
      code?: string | null;
      viewOrder?: number | null;
      createdAt: any;
      updatedAt: any;
      salesTeamGroup?: { id: string; name: string } | null;
    }>;
  };
};

export type SalesPerformanceSalesUserGoalViewQueryVariables = Exact<{
  salesTeamGroupId: Scalars['ID'];
  year: Scalars['Int'];
  userIds?: InputMaybe<Array<Scalars['ID']> | Scalars['ID']>;
}>;

export type SalesPerformanceSalesUserGoalViewQuery = {
  salesPerformanceSalesUserGoalView: {
    salesTeamGroupId: string;
    year: number;
    salesTeamGroup?: {
      id: string;
      name: string;
      code?: EnumSalesTeamGroupCode | null;
      directorId?: string | null;
      regionId?: string | null;
      companyId?: string | null;
    } | null;
    users: Array<{
      salesTeamUnitId: string;
      months: Array<{
        month: number;
        types: Array<{
          salesTypeId: string;
          amount: number;
          salesType?: { id: string; name: string; code?: string | null } | null;
        }>;
      }>;
      salesTeamUnit?: { id: string; name: string } | null;
    }>;
  };
};

export type SalesPerformanceSalesTeamGoalViewQueryVariables = Exact<{
  salesTeamGroupId: Scalars['ID'];
  year: Scalars['Int'];
  salesTeamIds?: InputMaybe<Array<Scalars['ID']> | Scalars['ID']>;
}>;

export type SalesPerformanceSalesTeamGoalViewQuery = {
  salesPerformanceSalesTeamGoalView: {
    salesTeamGroupId: string;
    year: number;
    salesTeamGroup?: {
      id: string;
      name: string;
      code?: EnumSalesTeamGroupCode | null;
      directorId?: string | null;
      regionId?: string | null;
      companyId?: string | null;
    } | null;
    teams: Array<{
      salesTeamId: string;
      months: Array<{
        month: number;
        types: Array<{
          salesTypeId: string;
          amount: number;
          salesType?: { id: string; name: string; code?: string | null } | null;
        }>;
      }>;
      salesTeam?: { id: string; name: string } | null;
    }>;
  };
};

export type SalesPerformanceSalesUserForecastViewQueryVariables = Exact<{
  salesTeamGroupId: Scalars['ID'];
  year: Scalars['Int'];
  month1?: InputMaybe<Scalars['Int']>;
  month2?: InputMaybe<Scalars['Int']>;
  userIds?: InputMaybe<Array<Scalars['ID']> | Scalars['ID']>;
}>;

export type SalesPerformanceSalesUserForecastViewQuery = {
  salesPerformanceSalesUserForecastView: {
    year: number;
    salesTeamGroupId: string;
    salesTeamGroup?: { id: string; name: string } | null;
    overall: {
      months: Array<{
        month: number;
        types: Array<{
          salesTypeId: string;
          quota: number;
          quotaAchievementRate?: number | null;
          amountEarlyMonth: number;
          amountEarlyMonthAccuracyRate?: number | null;
          amountLateMonth: number;
          amountLateMonthAccuracyRate?: number | null;
          amountActual: number;
          salesType?: { id: string; name: string } | null;
        }>;
      }>;
    };
    users: Array<{
      salesTeamUnitId: string;
      salesTeamUnit?: { id: string; name: string } | null;
      months: Array<{
        month: number;
        types: Array<{
          salesTypeId: string;
          quota: number;
          quotaAchievementRate?: number | null;
          amountEarlyMonth: number;
          amountEarlyMonthAccuracyRate?: number | null;
          amountLateMonth: number;
          amountLateMonthAccuracyRate?: number | null;
          amountActual: number;
          salesType?: { id: string; name: string } | null;
        }>;
      }>;
    }>;
  };
};

export type CreateRoleMutationVariables = Exact<{
  input: RoleCreateInput;
}>;

export type CreateRoleMutation = { createRole: { id: string } };

export type UpdateRoleMutationVariables = Exact<{
  input: RoleUpdateInput;
}>;

export type UpdateRoleMutation = { updateRole: { id: string } };

export type BulkCreateRolesUserMutationVariables = Exact<{
  input: RolesUserBulkCreateInput;
}>;

export type BulkCreateRolesUserMutation = {
  bulkCreateRolesUser: Array<{ userId: string; roleId: string }>;
};

export type DeleteRolesUserMutationVariables = Exact<{
  input: RolesUserDeleteInput;
}>;

export type DeleteRolesUserMutation = { deleteRolesUser: boolean };

export type RolesQueryVariables = Exact<{
  filters: RoleSearchInput;
  offset?: InputMaybe<Scalars['Int']>;
  limit?: InputMaybe<Scalars['Int']>;
}>;

export type RolesQuery = {
  roles: {
    count: number;
    rows: Array<{
      allowCompanyIds: Array<number>;
      allowProductTeamIds: Array<number>;
      id: string;
      isSystemAdmin: boolean;
      isOnlyOwnUser: boolean;
      allowUserIds: Array<number>;
      isAllowAllCnCompanies: boolean;
      isAllowAllTwCompanies: boolean;
      isAllowAllRegions: boolean;
      isAllowAllSalesTeamGroups: boolean;
      name: string;
      allowRegions: Array<{ id: string; name: string }>;
      allowSalesTeamGroups: Array<{ id: string; name: string }>;
      allowSalesTeams: Array<{
        id: string;
        name: string;
        group?: { id: string; name: string } | null;
      }>;
      allowUsers: Array<{
        id: string;
        name?: string | null;
        dept?: { id: string; name: string } | null;
      }>;
      permissions: Array<{
        applicationId: string;
        code: string;
        description?: string | null;
        name: string;
        id: string;
        groupId: string;
        group?: { id: string; name: string } | null;
      }>;
    }>;
  };
};

export type SalesTeamsQueryVariables = Exact<{
  filters: SalesTeamSearchInput;
}>;

export type SalesTeamsQuery = {
  salesTeams: {
    rows: Array<{
      id: string;
      code?: string | null;
      name: string;
      parentId?: string | null;
      viewOrder?: number | null;
      group?: { id: string; name: string } | null;
      children: Array<{
        id: string;
        code?: string | null;
        name: string;
        parentId?: string | null;
        viewOrder?: number | null;
        children: Array<{
          id: string;
          code?: string | null;
          name: string;
          parentId?: string | null;
          viewOrder?: number | null;
          nestedUsers: Array<{
            id: string;
            name?: string | null;
            code?: string | null;
          }>;
          children: Array<{
            id: string;
            code?: string | null;
            name: string;
            parentId?: string | null;
            viewOrder?: number | null;
            nestedUsers: Array<{
              id: string;
              name?: string | null;
              code?: string | null;
            }>;
            children: Array<{
              id: string;
              code?: string | null;
              name: string;
              parentId?: string | null;
              viewOrder?: number | null;
              nestedUsers: Array<{
                id: string;
                name?: string | null;
                code?: string | null;
              }>;
              children: Array<{
                id: string;
                code?: string | null;
                name: string;
                parentId?: string | null;
                viewOrder?: number | null;
                nestedUsers: Array<{
                  id: string;
                  name?: string | null;
                  code?: string | null;
                }>;
                children: Array<{
                  id: string;
                  code?: string | null;
                  name: string;
                  parentId?: string | null;
                  viewOrder?: number | null;
                  nestedUsers: Array<{
                    id: string;
                    name?: string | null;
                    code?: string | null;
                  }>;
                }>;
              }>;
            }>;
          }>;
        }>;
        nestedUsers: Array<{
          id: string;
          name?: string | null;
          code?: string | null;
        }>;
      }>;
      nestedUsers: Array<{
        id: string;
        name?: string | null;
        code?: string | null;
      }>;
      nestedUnits: Array<{
        id: string;
        name: string;
        user?: { id: string; name?: string | null } | null;
      }>;
    }>;
  };
};

export type PermissionsQueryVariables = Exact<{
  filters: PermissionSearchInput;
}>;

export type PermissionsQuery = {
  permissions: {
    rows: Array<{
      id: string;
      name: string;
      applicationId: string;
      code: string;
      description?: string | null;
      groupId: string;
      group?: { id: string; name: string } | null;
    }>;
  };
};

export type PermissionGroupsQueryVariables = Exact<{
  filters: PermissionGroupSearchInput;
}>;

export type PermissionGroupsQuery = {
  permissionGroups: {
    rows: Array<{
      id: string;
      name: string;
      permissions: Array<{
        id: string;
        name: string;
        description?: string | null;
        applicationId: string;
      }>;
    }>;
  };
};

export type PermissionApplicationsQueryVariables = Exact<{
  filters: PermissionApplicationSearchInput;
}>;

export type PermissionApplicationsQuery = {
  permissionApplications: { rows: Array<{ id: string; name: string }> };
};

export type CreateProductTeamMutationVariables = Exact<{
  input: ProductTeamCreateInput;
}>;

export type CreateProductTeamMutation = { createProductTeam: { id: string } };

export type UpdateProductTeamMutationVariables = Exact<{
  input: ProductTeamUpdateInput;
}>;

export type UpdateProductTeamMutation = { updateProductTeam: { id: string } };

export type ProductTeamsQueryVariables = Exact<{
  filters: ProductTeamSearchInput;
  offset?: InputMaybe<Scalars['Int']>;
  limit?: InputMaybe<Scalars['Int']>;
}>;

export type ProductTeamsQuery = {
  productTeams: {
    count: number;
    rows: Array<{
      id: string;
      name: string;
      users: Array<{
        id: string;
        name?: string | null;
        dept?: { id: string; name: string } | null;
      }>;
      businessProducts: Array<{ id: string; name: string }>;
    }>;
  };
};

export type CreateSalesTeamMutationVariables = Exact<{
  input: SalesTeamCreateInput;
}>;

export type CreateSalesTeamMutation = { createSalesTeam: { id: string } };

export type UpdateSalesTeamMutationVariables = Exact<{
  input: SalesTeamUpdateInput;
}>;

export type UpdateSalesTeamMutation = { updateSalesTeam: { id: string } };

export type BulkUpdateSalesTeamMutationVariables = Exact<{
  input: SalesTeamBulkUpdateInput;
}>;

export type BulkUpdateSalesTeamMutation = {
  bulkUpdateSalesTeam: Array<{ id: string }>;
};

export type CreateSalesTeamUnitMutationVariables = Exact<{
  input: SalesTeamUnitCreateInput;
}>;

export type CreateSalesTeamUnitMutation = {
  createSalesTeamUnit: { id: string };
};

export type UpdateSalesTeamUnitMutationVariables = Exact<{
  input: SalesTeamUnitUpdateInput;
}>;

export type UpdateSalesTeamUnitMutation = {
  updateSalesTeamUnit: { id: string };
};

export type SalesTeamsTableQueryVariables = Exact<{
  filters: SalesTeamSearchInput;
  offset?: InputMaybe<Scalars['Int']>;
  limit?: InputMaybe<Scalars['Int']>;
}>;

export type SalesTeamsTableQuery = {
  salesTeams: {
    count: number;
    rows: Array<{
      id: string;
      name: string;
      managerId?: string | null;
      requiredForPrimaryCustomer: boolean;
      group?: { name: string; id: string } | null;
      manager: {
        id: string;
        name?: string | null;
        departmentId?: string | null;
      };
      parent?: { id: string; name: string } | null;
      users: Array<{
        id: string;
        name?: string | null;
        dept?: { id: string; name: string } | null;
      }>;
    }>;
  };
};

export type SalesTeamUnitsQueryVariables = Exact<{
  filters: SalesTeamUnitSearchInput;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
}>;

export type SalesTeamUnitsQuery = {
  salesTeamUnits: {
    count: number;
    rows: Array<{
      id: string;
      name: string;
      userId?: string | null;
      salesTeamId: string;
      user?: {
        id: string;
        name?: string | null;
        departmentId?: string | null;
      } | null;
      salesTeam?: { id: string; name: string } | null;
    }>;
  };
};

export type LoginMutationVariables = Exact<{
  ssoToken: Scalars['String'];
}>;

export type LoginMutation = {
  userLogin: {
    id: string;
    name: string;
    code: string;
    email: string;
    token: any;
  };
};

export type MeQueryVariables = Exact<{ [key: string]: never }>;

export type MeQuery = {
  me: {
    id: string;
    code?: string | null;
    email?: string | null;
    name?: string | null;
    authType: number;
    region?: { id: string; name: string } | null;
    company?: {
      id: string;
      code?: string | null;
      region?: { id: string; name: string } | null;
    } | null;
    dept?: {
      id: string;
      code?: string | null;
      name: string;
      company?: {
        id: string;
        code?: string | null;
        name: string;
        region?: { id: string; name: string } | null;
      } | null;
      manager?: { id: string; name?: string | null } | null;
    } | null;
    salesTeamUnits?: Array<{ id: string; name: string }> | null;
    salesTeams?: Array<{ id: string; top?: { id: string } | null }> | null;
    userPermission: { codes: Array<string>; allowRegionIds: Array<string> };
    availableSalesTeamGroups: Array<{
      code?: EnumSalesTeamGroupCode | null;
      id: string;
      name: string;
      region?: { id: string; name: string; code?: string | null } | null;
    }>;
    defaultAvailableSalesTeamGroup?: {
      id: string;
      name: string;
      code?: EnumSalesTeamGroupCode | null;
      region?: { id: string; name: string; code?: string | null } | null;
    } | null;
  };
};

export type UsersQueryVariables = Exact<{
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  filters: UserSearchInput;
}>;

export type UsersQuery = {
  users: {
    count: number;
    rows: Array<{
      id: string;
      name?: string | null;
      code?: string | null;
      email?: string | null;
      isAudiologist: boolean;
      isHearingAidEngineer: boolean;
      isOutsourcing: boolean;
      isLocked: boolean;
      isActive: boolean;
      region?: { id: string } | null;
      company?: { id: string; code?: string | null; name: string } | null;
      dept?: { id: string; code?: string | null; name: string } | null;
      store?: { id: string; code: string; name: string } | null;
      roles: Array<{ id: string; name: string; code?: string | null }>;
    }>;
  };
};

export type UsersSelectQueryVariables = Exact<{
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  filters: UserSearchInput;
}>;

export type UsersSelectQuery = {
  users: {
    rows: Array<{
      id: string;
      name?: string | null;
      code?: string | null;
      companyId?: string | null;
      dept?: { id: string; code?: string | null; name: string } | null;
    }>;
  };
};

export const ChildrensFragmentDoc = gql`
  fragment childrens on Department {
    id
    name
    code
    company {
      id
      code
      name
    }
    users {
      id
      code
      name
      dept {
        id
        name
        code
      }
      company {
        id
        name
      }
    }
  }
`;
export const ChildrenFragmentDoc = gql`
  fragment children on SalesTeam {
    id
    name
    code
    users {
      id
      name
      code
    }
  }
`;
export const TeamsFragmentDoc = gql`
  fragment teams on SalesTeam {
    id
    name
    code
    children {
      ...children
      children {
        ...children
        children {
          ...children
          children {
            ...children
            children {
              ...children
              children {
                ...children
              }
            }
          }
        }
      }
    }
    users {
      id
      name
      code
    }
  }
  ${ChildrenFragmentDoc}
`;
export const VisitTypeBasicInfoFragmentDoc = gql`
  fragment VisitTypeBasicInfo on VisitType {
    id
    name
  }
`;
export const CustomerBasicInfoFragmentDoc = gql`
  fragment CustomerBasicInfo on Customer {
    id
    name
    code
    type {
      id
      name
    }
    cityId
    districtId
    address
    salesTeamGroupId
    medicalCode
    region {
      id
      name
    }
  }
`;
export const BusinessBasicInfoFragmentDoc = gql`
  fragment BusinessBasicInfo on Business {
    id
    title
    content
    budgetAmount
    saleAmount
    dealAmount
    expectedClosedDate
    closedDate
    orderCode
    winningOpportunity {
      id
      name
    }
    buyingOpportunity {
      id
      name
    }
    businessesToBudgetProducts {
      id
      budgetProduct {
        id
        name
      }
      qty
    }
    businessesToDealProducts {
      id
      dealProduct {
        id
        name
      }
      qty
    }
    customer {
      ...CustomerBasicInfo
    }
    status {
      id
      name
    }
  }
  ${CustomerBasicInfoFragmentDoc}
`;
export const VisitActionBasicInfoFragmentDoc = gql`
  fragment VisitActionBasicInfo on VisitAction {
    id
    name
  }
`;
export const StaffBasicInfoFragmentDoc = gql`
  fragment StaffBasicInfo on User {
    id
    code
    name
    email
    dept {
      id
      name
    }
  }
`;
export const VisitTimePeriodBasicInfoFragmentDoc = gql`
  fragment VisitTimePeriodBasicInfo on VisitTimePeriod {
    id
    name
  }
`;
export const VisitGoalBasicInfoFragmentDoc = gql`
  fragment VisitGoalBasicInfo on VisitGoal {
    id
    name
  }
`;
export const VisitPropertyBasicInfoFragmentDoc = gql`
  fragment VisitPropertyBasicInfo on VisitProperty {
    id
    name
  }
`;
export const VisitPropertyTypeBasicInfoFragmentDoc = gql`
  fragment VisitPropertyTypeBasicInfo on VisitPropertyType {
    id
    name
    code
  }
`;
export const VisitBasicInfoFragmentDoc = gql`
  fragment VisitBasicInfo on Visit {
    id
    createdAt
    updatedAt
    salesTeamGroup {
      id
      name
    }
    title
    content
    visitAddress
    visitDate
    priorityOrder
    status
    type {
      ...VisitTypeBasicInfo
    }
    typeId
    business {
      ...BusinessBasicInfo
    }
    action {
      ...VisitActionBasicInfo
    }
    actionContent
    customer {
      ...CustomerBasicInfo
    }
    salesTeam {
      id
      name
    }
    primaryUser {
      ...StaffBasicInfo
    }
    timePeriods {
      ...VisitTimePeriodBasicInfo
    }
    goals {
      ...VisitGoalBasicInfo
    }
    properties {
      ...VisitPropertyBasicInfo
      type {
        ...VisitPropertyTypeBasicInfo
      }
    }
    id
    mentionProducts {
      id
      name
    }
    rentProducts {
      id
      name
    }
    rentFixedAssets {
      id
      name
    }
    createdUser {
      ...StaffBasicInfo
    }
    updatedUser {
      ...StaffBasicInfo
    }
    primaryContactPeople {
      id
      name
      jobTitle
    }
    salesTeamUnitId
    surgeryCount
    estimatedSurgeryCount
    hasAssistedVisitSupervisor
    assistedVisitSupervisorId
    assistedVisitSupervisor {
      id
      name
      departmentId
    }
  }
  ${VisitTypeBasicInfoFragmentDoc}
  ${BusinessBasicInfoFragmentDoc}
  ${VisitActionBasicInfoFragmentDoc}
  ${CustomerBasicInfoFragmentDoc}
  ${StaffBasicInfoFragmentDoc}
  ${VisitTimePeriodBasicInfoFragmentDoc}
  ${VisitGoalBasicInfoFragmentDoc}
  ${VisitPropertyBasicInfoFragmentDoc}
  ${VisitPropertyTypeBasicInfoFragmentDoc}
`;
export const VisitMemoBasicInfoFragmentDoc = gql`
  fragment VisitMemoBasicInfo on VisitMemo {
    id
    title
    content
    createdUser {
      ...StaffBasicInfo
    }
    updatedUser {
      ...StaffBasicInfo
    }
    createdAt
    updatedAt
  }
  ${StaffBasicInfoFragmentDoc}
`;
export const VisitDetailDataFragmentDoc = gql`
  fragment VisitDetailData on Visit {
    memos {
      ...VisitMemoBasicInfo
    }
  }
  ${VisitMemoBasicInfoFragmentDoc}
`;
export const VisitProductBasicInfoFragmentDoc = gql`
  fragment VisitProductBasicInfo on BusinessProduct {
    id
    name
  }
`;
export const SalesTeamGroupFragmentDoc = gql`
  fragment SalesTeamGroup on SalesTeamGroup {
    id
    name
    code
  }
`;
export const MenuBadgeDocument = gql`
  query MenuBadge {
    menuBadge {
      eyeServiceOrderWaitForAcceptance
      eyeServiceOrderWaitForApproval
      eyeServiceOrderWaitForAssign
    }
  }
`;

/**
 * __useMenuBadgeQuery__
 *
 * To run a query within a React component, call `useMenuBadgeQuery` and pass it any options that fit your needs.
 * When your component renders, `useMenuBadgeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMenuBadgeQuery({
 *   variables: {
 *   },
 * });
 */
export function useMenuBadgeQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    MenuBadgeQuery,
    MenuBadgeQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<MenuBadgeQuery, MenuBadgeQueryVariables>(
    MenuBadgeDocument,
    options,
  );
}
export function useMenuBadgeLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    MenuBadgeQuery,
    MenuBadgeQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<MenuBadgeQuery, MenuBadgeQueryVariables>(
    MenuBadgeDocument,
    options,
  );
}
export type MenuBadgeQueryHookResult = ReturnType<typeof useMenuBadgeQuery>;
export type MenuBadgeLazyQueryHookResult = ReturnType<
  typeof useMenuBadgeLazyQuery
>;
export type MenuBadgeQueryResult = ApolloReactCommon.QueryResult<
  MenuBadgeQuery,
  MenuBadgeQueryVariables
>;
export const MenuBadgeSubscriptionDocument = gql`
  subscription MenuBadgeSubscription {
    menuBadgeSubscription {
      eyeServiceOrderWaitForAcceptance
      eyeServiceOrderWaitForApproval
      eyeServiceOrderWaitForAssign
    }
  }
`;

/**
 * __useMenuBadgeSubscriptionSubscription__
 *
 * To run a query within a React component, call `useMenuBadgeSubscriptionSubscription` and pass it any options that fit your needs.
 * When your component renders, `useMenuBadgeSubscriptionSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMenuBadgeSubscriptionSubscription({
 *   variables: {
 *   },
 * });
 */
export function useMenuBadgeSubscriptionSubscription(
  baseOptions?: ApolloReactHooks.SubscriptionHookOptions<
    MenuBadgeSubscriptionSubscription,
    MenuBadgeSubscriptionSubscriptionVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useSubscription<
    MenuBadgeSubscriptionSubscription,
    MenuBadgeSubscriptionSubscriptionVariables
  >(MenuBadgeSubscriptionDocument, options);
}
export type MenuBadgeSubscriptionSubscriptionHookResult = ReturnType<
  typeof useMenuBadgeSubscriptionSubscription
>;
export type MenuBadgeSubscriptionSubscriptionResult =
  ApolloReactCommon.SubscriptionResult<MenuBadgeSubscriptionSubscription>;
export const SalesPerformanceSalesProductTypeGroupsDocument = gql`
  query SalesPerformanceSalesProductTypeGroups(
    $filters: SalesPerformanceSalesProductTypeGroupSearchInput!
    $offset: Int
    $limit: Int
  ) {
    salesPerformanceSalesProductTypeGroups(
      filters: $filters
      offset: $offset
      limit: $limit
    ) {
      count
      rows {
        id
        salesTeamGroupId
        name
        code
        viewOrder
        createdAt
        updatedAt
        salesTeamGroup {
          code
          id
          name
        }
      }
    }
  }
`;

/**
 * __useSalesPerformanceSalesProductTypeGroupsQuery__
 *
 * To run a query within a React component, call `useSalesPerformanceSalesProductTypeGroupsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSalesPerformanceSalesProductTypeGroupsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSalesPerformanceSalesProductTypeGroupsQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useSalesPerformanceSalesProductTypeGroupsQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    SalesPerformanceSalesProductTypeGroupsQuery,
    SalesPerformanceSalesProductTypeGroupsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    SalesPerformanceSalesProductTypeGroupsQuery,
    SalesPerformanceSalesProductTypeGroupsQueryVariables
  >(SalesPerformanceSalesProductTypeGroupsDocument, options);
}
export function useSalesPerformanceSalesProductTypeGroupsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    SalesPerformanceSalesProductTypeGroupsQuery,
    SalesPerformanceSalesProductTypeGroupsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    SalesPerformanceSalesProductTypeGroupsQuery,
    SalesPerformanceSalesProductTypeGroupsQueryVariables
  >(SalesPerformanceSalesProductTypeGroupsDocument, options);
}
export type SalesPerformanceSalesProductTypeGroupsQueryHookResult = ReturnType<
  typeof useSalesPerformanceSalesProductTypeGroupsQuery
>;
export type SalesPerformanceSalesProductTypeGroupsLazyQueryHookResult =
  ReturnType<typeof useSalesPerformanceSalesProductTypeGroupsLazyQuery>;
export type SalesPerformanceSalesProductTypeGroupsQueryResult =
  ApolloReactCommon.QueryResult<
    SalesPerformanceSalesProductTypeGroupsQuery,
    SalesPerformanceSalesProductTypeGroupsQueryVariables
  >;
export const SalesProductTypeSalesAnalysisDocument = gql`
  query SalesProductTypeSalesAnalysis(
    $filters: SalesProductTypeSalesAnalysisSalesStatFilterInput!
  ) {
    salesProductTypeSalesAnalysis {
      salesStat(filters: $filters) {
        salesTeamGroupId
        dateRange {
          currentDate1
          currentDate2
          yoyDate1
          yoyDate2
          momDate1
          momDate2
          ytdDate1
          ytdDate2
          lastYearYtdDate1
          lastYearYtdDate2
        }
        details {
          salesProductTypeGroupId
          types {
            salesProductTypeId
            amountGoal
            amountForecastGoal
            amountCurrent
            amountYoy
            amountYoyGrowthRate
            amountMom
            amountMomGrowthRate
            amountYtd
            amountLastYearYtd
            amountLastYearYtdGrowthRate
            qtyGoal
            qtyForecastGoal
            qtyCurrent
            qtyYoy
            qtyYoyGrowthRate
            qtyMom
            qtyMomGrowthRate
            qtyYtd
            qtyLastYearYtd
            qtyLastYearYtdGrowthRate
            salesProductType {
              id
              name
              code
            }
          }
          salesProductTypeGroup {
            id
            name
            code
          }
        }
      }
    }
  }
`;

/**
 * __useSalesProductTypeSalesAnalysisQuery__
 *
 * To run a query within a React component, call `useSalesProductTypeSalesAnalysisQuery` and pass it any options that fit your needs.
 * When your component renders, `useSalesProductTypeSalesAnalysisQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSalesProductTypeSalesAnalysisQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function useSalesProductTypeSalesAnalysisQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    SalesProductTypeSalesAnalysisQuery,
    SalesProductTypeSalesAnalysisQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    SalesProductTypeSalesAnalysisQuery,
    SalesProductTypeSalesAnalysisQueryVariables
  >(SalesProductTypeSalesAnalysisDocument, options);
}
export function useSalesProductTypeSalesAnalysisLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    SalesProductTypeSalesAnalysisQuery,
    SalesProductTypeSalesAnalysisQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    SalesProductTypeSalesAnalysisQuery,
    SalesProductTypeSalesAnalysisQueryVariables
  >(SalesProductTypeSalesAnalysisDocument, options);
}
export type SalesProductTypeSalesAnalysisQueryHookResult = ReturnType<
  typeof useSalesProductTypeSalesAnalysisQuery
>;
export type SalesProductTypeSalesAnalysisLazyQueryHookResult = ReturnType<
  typeof useSalesProductTypeSalesAnalysisLazyQuery
>;
export type SalesProductTypeSalesAnalysisQueryResult =
  ApolloReactCommon.QueryResult<
    SalesProductTypeSalesAnalysisQuery,
    SalesProductTypeSalesAnalysisQueryVariables
  >;
export const EcDashboardAnalysisDocument = gql`
  query EcDashboardAnalysis(
    $salesMaterialRankFilters2: EcDashboardAnalysisSalesMaterialRankFilterInput
    $filters: EcDashboardAnalysisSalesCustomerRankFilterInput
    $visitCustomerRankFilters2: EcDashboardAnalysisVisitCustomerRankFilterInput
    $visitGoalRankFilters2: EcDashboardAnalysisVisitGoalRankFilterInput
    $visitMentionProductRankFilters2: EcDashboardAnalysisVisitMentionProductRankFilterInput
    $visitsByDayOfWeekFilters2: EcDashboardAnalysisVisitByDayOfWeekFilterInput
    $visitStatFilters2: EcDashboardAnalysisVisitStatFilterInput!
    $salesStatFilters2: EcDashboardAnalysisSalesStatFilterInput
  ) {
    EcDashboardAnalysis {
      salesCustomerRank(filters: $filters) {
        dateRange {
          currentDate1
          yoyDate1
          lastYearYoyDate1
          currentDate2
          yoyDate2
          lastYearYoyDate2
        }
        details {
          seq
          rank
          customerCode
          customerName
          amountCurrent
          amountYoy
          amountLastYearYoy
          qtyCurrent
          qtyLastYearYoy
          qtyYoy
          visitCurrent
          visitLastYearYoy
          visitYoy
        }
      }
      salesMaterialRank(filters: $salesMaterialRankFilters2) {
        dateRange {
          currentDate1
          yoyDate1
          lastYearYoyDate1
          currentDate2
          yoyDate2
          lastYearYoyDate2
        }
        details {
          seq
          rank
          materialCode
          materialName
          amountCurrent
          amountYoy
          amountLastYearYoy
          qtyCurrent
          qtyLastYearYoy
          qtyYoy
        }
      }
      visitCustomerRank(filters: $visitCustomerRankFilters2) {
        dateRange {
          currentDate1
          yoyDate1
          currentDate2
          yoyDate2
        }
        details {
          seq
          rank
          customerId
          customer {
            id
            name
          }
          visitCountCurrent
          visitCountYoy
        }
      }
      visitMentionProductRank(filters: $visitMentionProductRankFilters2) {
        dateRange {
          currentDate1
          yoyDate1
          currentDate2
          yoyDate2
        }
        details {
          seq
          rank
          mentionProductId
          mentionProduct {
            id
            name
          }
          visitCountCurrent
          visitCountYoy
        }
      }
      visitGoalRank(filters: $visitGoalRankFilters2) {
        dateRange {
          currentDate1
          yoyDate1
          currentDate2
          yoyDate2
        }
        details {
          seq
          rank
          goalId
          goal {
            id
            name
          }
          visitCountCurrent
          visitCountYoy
        }
      }
      visitsByDayOfWeek(filters: $visitsByDayOfWeekFilters2) {
        details {
          dayOfWeek
          visitCountCurrent
        }
      }
      salesStat(filters: $salesStatFilters2) {
        dateRange {
          currentDate1
          currentDate2
          yoyDate1
          yoyDate2
          momDate1
          momDate2
          ytdDate1
          ytdDate2
          lastYearYtdDate1
          lastYearYtdDate2
        }
        details {
          salesTypeId
          amountCurrent
          amountGoalCurrent
          amountGoalCurrentAchievementRate
          amountYoy
          amountYoyGrowthRate
          amountMom
          amountMomGrowthRate
          amountYtd
          amountLastYearYtd
          amountLastYearYtdGrowthRate
          salesType {
            name
            code
          }
        }
        salesTeamGroupId
      }
      visitStat(filters: $visitStatFilters2) {
        dateRange {
          currentDate1
          currentDate2
          yoyDate1
          yoyDate2
          momDate1
          momDate2
          ytdDate1
          ytdDate2
          lastYearYtdDate1
          lastYearYtdDate2
        }
        overall {
          visitCountCurrent
          visitCountYoy
          visitCountYoyGrowthRate
          visitCountMom
          visitCountMomGrowthRate
          visitCountYtd
          visitCountLastYearYtd
          visitCountLastYearYtdGrowthRate
        }
        salesTeamGroupId
      }
    }
  }
`;

/**
 * __useEcDashboardAnalysisQuery__
 *
 * To run a query within a React component, call `useEcDashboardAnalysisQuery` and pass it any options that fit your needs.
 * When your component renders, `useEcDashboardAnalysisQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEcDashboardAnalysisQuery({
 *   variables: {
 *      salesMaterialRankFilters2: // value for 'salesMaterialRankFilters2'
 *      filters: // value for 'filters'
 *      visitCustomerRankFilters2: // value for 'visitCustomerRankFilters2'
 *      visitGoalRankFilters2: // value for 'visitGoalRankFilters2'
 *      visitMentionProductRankFilters2: // value for 'visitMentionProductRankFilters2'
 *      visitsByDayOfWeekFilters2: // value for 'visitsByDayOfWeekFilters2'
 *      visitStatFilters2: // value for 'visitStatFilters2'
 *      salesStatFilters2: // value for 'salesStatFilters2'
 *   },
 * });
 */
export function useEcDashboardAnalysisQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    EcDashboardAnalysisQuery,
    EcDashboardAnalysisQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    EcDashboardAnalysisQuery,
    EcDashboardAnalysisQueryVariables
  >(EcDashboardAnalysisDocument, options);
}
export function useEcDashboardAnalysisLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    EcDashboardAnalysisQuery,
    EcDashboardAnalysisQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    EcDashboardAnalysisQuery,
    EcDashboardAnalysisQueryVariables
  >(EcDashboardAnalysisDocument, options);
}
export type EcDashboardAnalysisQueryHookResult = ReturnType<
  typeof useEcDashboardAnalysisQuery
>;
export type EcDashboardAnalysisLazyQueryHookResult = ReturnType<
  typeof useEcDashboardAnalysisLazyQuery
>;
export type EcDashboardAnalysisQueryResult = ApolloReactCommon.QueryResult<
  EcDashboardAnalysisQuery,
  EcDashboardAnalysisQueryVariables
>;
export const EcDashboardAnalysisDetailDocument = gql`
  query EcDashboardAnalysisDetail(
    $filters: EcDashboardAnalysisSalesDetailFilterInput!
  ) {
    EcDashboardAnalysisDetail(filters: $filters) {
      salesTeamGroup {
        id
        name
      }
      salesType {
        id
        name
      }
      details {
        companyCode
        salesType
        salesOrderCode
        salesDate
        salesCustomerCode
        salesCustomerName
        accountCustomerCode
        accountCustomerName
        materialCode
        materialName
        qty
        untaxedAmount
        taxedAmount
        userCode
        userName
      }
    }
  }
`;

/**
 * __useEcDashboardAnalysisDetailQuery__
 *
 * To run a query within a React component, call `useEcDashboardAnalysisDetailQuery` and pass it any options that fit your needs.
 * When your component renders, `useEcDashboardAnalysisDetailQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEcDashboardAnalysisDetailQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function useEcDashboardAnalysisDetailQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    EcDashboardAnalysisDetailQuery,
    EcDashboardAnalysisDetailQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    EcDashboardAnalysisDetailQuery,
    EcDashboardAnalysisDetailQueryVariables
  >(EcDashboardAnalysisDetailDocument, options);
}
export function useEcDashboardAnalysisDetailLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    EcDashboardAnalysisDetailQuery,
    EcDashboardAnalysisDetailQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    EcDashboardAnalysisDetailQuery,
    EcDashboardAnalysisDetailQueryVariables
  >(EcDashboardAnalysisDetailDocument, options);
}
export type EcDashboardAnalysisDetailQueryHookResult = ReturnType<
  typeof useEcDashboardAnalysisDetailQuery
>;
export type EcDashboardAnalysisDetailLazyQueryHookResult = ReturnType<
  typeof useEcDashboardAnalysisDetailLazyQuery
>;
export type EcDashboardAnalysisDetailQueryResult =
  ApolloReactCommon.QueryResult<
    EcDashboardAnalysisDetailQuery,
    EcDashboardAnalysisDetailQueryVariables
  >;
export const BulkUpdateSalesPerformanceSalesProductOverallViewDocument = gql`
  mutation BulkUpdateSalesPerformanceSalesProductOverallView(
    $input: SalesPerformanceSalesProductOverallViewBulkUpdateInput!
  ) {
    bulkUpdateSalesPerformanceSalesProductOverallView(input: $input) {
      forecasts {
        id
        unitPrice
        qty
        amount
      }
      goals {
        id
        unitPrice
        qty
        amount
      }
    }
  }
`;
export type BulkUpdateSalesPerformanceSalesProductOverallViewMutationFn =
  ApolloReactCommon.MutationFunction<
    BulkUpdateSalesPerformanceSalesProductOverallViewMutation,
    BulkUpdateSalesPerformanceSalesProductOverallViewMutationVariables
  >;

/**
 * __useBulkUpdateSalesPerformanceSalesProductOverallViewMutation__
 *
 * To run a mutation, you first call `useBulkUpdateSalesPerformanceSalesProductOverallViewMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBulkUpdateSalesPerformanceSalesProductOverallViewMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [bulkUpdateSalesPerformanceSalesProductOverallViewMutation, { data, loading, error }] = useBulkUpdateSalesPerformanceSalesProductOverallViewMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useBulkUpdateSalesPerformanceSalesProductOverallViewMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    BulkUpdateSalesPerformanceSalesProductOverallViewMutation,
    BulkUpdateSalesPerformanceSalesProductOverallViewMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    BulkUpdateSalesPerformanceSalesProductOverallViewMutation,
    BulkUpdateSalesPerformanceSalesProductOverallViewMutationVariables
  >(BulkUpdateSalesPerformanceSalesProductOverallViewDocument, options);
}
export type BulkUpdateSalesPerformanceSalesProductOverallViewMutationHookResult =
  ReturnType<
    typeof useBulkUpdateSalesPerformanceSalesProductOverallViewMutation
  >;
export type BulkUpdateSalesPerformanceSalesProductOverallViewMutationResult =
  ApolloReactCommon.MutationResult<BulkUpdateSalesPerformanceSalesProductOverallViewMutation>;
export type BulkUpdateSalesPerformanceSalesProductOverallViewMutationOptions =
  ApolloReactCommon.BaseMutationOptions<
    BulkUpdateSalesPerformanceSalesProductOverallViewMutation,
    BulkUpdateSalesPerformanceSalesProductOverallViewMutationVariables
  >;
export const SalesPerformanceSalesProductOverallViewDocument = gql`
  query SalesPerformanceSalesProductOverallView(
    $salesTeamGroupId: ID!
    $year: Int!
  ) {
    salesPerformanceSalesProductOverallView(
      salesTeamGroupId: $salesTeamGroupId
      year: $year
    ) {
      year
      salesTeamGroupId
      overall {
        months {
          month
          tense
          forecastAmount
          forecastUnitPrice
          forecastQty
          salesAmount
          salesUnitPrice
          salesQty
          currentAmount
          currentUnitPrice
          currentQty
          goalAmount
          goalUnitPrice
          goalQty
        }
        forecastAmount
        forecastUnitPrice
        forecastQty
        salesAmount
        salesUnitPrice
        salesQty
        currentAmount
        currentUnitPrice
        currentQty
        goalAmount
        goalAmountAchievementRate
        goalUnitPrice
        goalUnitPriceAchievementRate
        goalQty
        goalQtyAchievementRate
        ytdCurrentAmount
        ytdCurrentUnitPrice
        ytdCurrentQty
        ytdGoalAmount
        ytdGoalAmountAchievementRate
        ytdGoalUnitPrice
        ytdGoalUnitPriceAchievementRate
        ytdGoalQty
        ytdGoalQtyAchievementRate
      }
      types {
        salesProductTypeId
        forecastAmount
        forecastUnitPrice
        forecastQty
        salesAmount
        salesUnitPrice
        salesQty
        currentAmount
        currentUnitPrice
        currentQty
        goalAmount
        goalAmountAchievementRate
        goalUnitPrice
        goalUnitPriceAchievementRate
        goalQty
        goalQtyAchievementRate
        ytdCurrentAmount
        ytdCurrentUnitPrice
        ytdCurrentQty
        ytdGoalAmount
        ytdGoalAmountAchievementRate
        ytdGoalUnitPrice
        ytdGoalUnitPriceAchievementRate
        ytdGoalQty
        ytdGoalQtyAchievementRate
        months {
          month
          tense
          forecastAmount
          forecastUnitPrice
          forecastQty
          salesAmount
          salesUnitPrice
          salesQty
          currentAmount
          currentUnitPrice
          currentQty
          goalAmount
          goalUnitPrice
          goalQty
        }
        salesProductType {
          id
          code
          name
        }
      }
      salesTeamGroup {
        id
        name
        code
        salesTeams {
          code
          id
          name
        }
      }
    }
  }
`;

/**
 * __useSalesPerformanceSalesProductOverallViewQuery__
 *
 * To run a query within a React component, call `useSalesPerformanceSalesProductOverallViewQuery` and pass it any options that fit your needs.
 * When your component renders, `useSalesPerformanceSalesProductOverallViewQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSalesPerformanceSalesProductOverallViewQuery({
 *   variables: {
 *      salesTeamGroupId: // value for 'salesTeamGroupId'
 *      year: // value for 'year'
 *   },
 * });
 */
export function useSalesPerformanceSalesProductOverallViewQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    SalesPerformanceSalesProductOverallViewQuery,
    SalesPerformanceSalesProductOverallViewQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    SalesPerformanceSalesProductOverallViewQuery,
    SalesPerformanceSalesProductOverallViewQueryVariables
  >(SalesPerformanceSalesProductOverallViewDocument, options);
}
export function useSalesPerformanceSalesProductOverallViewLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    SalesPerformanceSalesProductOverallViewQuery,
    SalesPerformanceSalesProductOverallViewQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    SalesPerformanceSalesProductOverallViewQuery,
    SalesPerformanceSalesProductOverallViewQueryVariables
  >(SalesPerformanceSalesProductOverallViewDocument, options);
}
export type SalesPerformanceSalesProductOverallViewQueryHookResult = ReturnType<
  typeof useSalesPerformanceSalesProductOverallViewQuery
>;
export type SalesPerformanceSalesProductOverallViewLazyQueryHookResult =
  ReturnType<typeof useSalesPerformanceSalesProductOverallViewLazyQuery>;
export type SalesPerformanceSalesProductOverallViewQueryResult =
  ApolloReactCommon.QueryResult<
    SalesPerformanceSalesProductOverallViewQuery,
    SalesPerformanceSalesProductOverallViewQueryVariables
  >;
export const NavOrdersDocument = gql`
  query navOrders($filters: NavOrderSearchInput!, $offset: Int, $limit: Int) {
    navOrders(filters: $filters, offset: $offset, limit: $limit) {
      count
      rows {
        code
        businesses {
          code
        }
        approvalCode
        orderDate
        amount
        shipInvoicedAmount
        billCustomer {
          code
          navCode
          name
          address
        }
        sellCustomer {
          code
          navCode
          name
          address
        }
        user {
          code
          name
        }
        details {
          materialCode
          materialName
          qty
          amount
        }
      }
    }
  }
`;

/**
 * __useNavOrdersQuery__
 *
 * To run a query within a React component, call `useNavOrdersQuery` and pass it any options that fit your needs.
 * When your component renders, `useNavOrdersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNavOrdersQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useNavOrdersQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    NavOrdersQuery,
    NavOrdersQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<NavOrdersQuery, NavOrdersQueryVariables>(
    NavOrdersDocument,
    options,
  );
}
export function useNavOrdersLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    NavOrdersQuery,
    NavOrdersQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<NavOrdersQuery, NavOrdersQueryVariables>(
    NavOrdersDocument,
    options,
  );
}
export type NavOrdersQueryHookResult = ReturnType<typeof useNavOrdersQuery>;
export type NavOrdersLazyQueryHookResult = ReturnType<
  typeof useNavOrdersLazyQuery
>;
export type NavOrdersQueryResult = ApolloReactCommon.QueryResult<
  NavOrdersQuery,
  NavOrdersQueryVariables
>;
export const NavSalesInvoicesDocument = gql`
  query NavSalesInvoices(
    $filters: NavSalesInvoiceSearchInput!
    $offset: Int
    $limit: Int
  ) {
    navSalesInvoices(filters: $filters, offset: $offset, limit: $limit) {
      count
      rows {
        code
        orderCode
        approvalCode
        type
        postingDate
        amount
        billCustomer {
          code
          navCode
          name
          address
        }
        sellCustomer {
          code
          navCode
          name
          address
        }
        user {
          code
          name
        }
        details {
          materialCode
          materialName
          qty
          amount
        }
      }
    }
  }
`;

/**
 * __useNavSalesInvoicesQuery__
 *
 * To run a query within a React component, call `useNavSalesInvoicesQuery` and pass it any options that fit your needs.
 * When your component renders, `useNavSalesInvoicesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNavSalesInvoicesQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useNavSalesInvoicesQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    NavSalesInvoicesQuery,
    NavSalesInvoicesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    NavSalesInvoicesQuery,
    NavSalesInvoicesQueryVariables
  >(NavSalesInvoicesDocument, options);
}
export function useNavSalesInvoicesLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    NavSalesInvoicesQuery,
    NavSalesInvoicesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    NavSalesInvoicesQuery,
    NavSalesInvoicesQueryVariables
  >(NavSalesInvoicesDocument, options);
}
export type NavSalesInvoicesQueryHookResult = ReturnType<
  typeof useNavSalesInvoicesQuery
>;
export type NavSalesInvoicesLazyQueryHookResult = ReturnType<
  typeof useNavSalesInvoicesLazyQuery
>;
export type NavSalesInvoicesQueryResult = ApolloReactCommon.QueryResult<
  NavSalesInvoicesQuery,
  NavSalesInvoicesQueryVariables
>;
export const BusinessAnalysisRankDocument = gql`
  query businessAnalysisRank(
    $regionIds: [ID!]
    $primaryUserIds: [ID!]
    $expectedClosedDate1: Date
    $expectedClosedDate2: Date
    $budgetProductIds: [ID!]
    $salesTeamTopId: ID
  ) {
    businessAnalysis {
      High: businessProductRank(
        filters: {
          regionIds: $regionIds
          primaryUserIds: $primaryUserIds
          expectedClosedDate1: $expectedClosedDate1
          expectedClosedDate2: $expectedClosedDate2
          statusTypes: InProgress
          transactionOpportunity: High
          budgetProductIds: $budgetProductIds
          salesTeamTopId: $salesTeamTopId
        }
      ) {
        businessProduct {
          id
          name
          isActive
          salesTeams {
            id
            name
            children {
              id
              name
            }
          }
        }
        rank
        qty
        businessProductId
      }
      MediumHigh: businessProductRank(
        filters: {
          regionIds: $regionIds
          primaryUserIds: $primaryUserIds
          expectedClosedDate1: $expectedClosedDate1
          expectedClosedDate2: $expectedClosedDate2
          statusTypes: InProgress
          transactionOpportunity: MediumHigh
          budgetProductIds: $budgetProductIds
          salesTeamTopId: $salesTeamTopId
        }
      ) {
        businessProduct {
          id
          name
          salesTeams {
            id
            name
            children {
              id
              name
            }
          }
        }
        rank
        qty
        businessProductId
      }
      Medium: businessProductRank(
        filters: {
          regionIds: $regionIds
          primaryUserIds: $primaryUserIds
          expectedClosedDate1: $expectedClosedDate1
          expectedClosedDate2: $expectedClosedDate2
          statusTypes: InProgress
          transactionOpportunity: Medium
          budgetProductIds: $budgetProductIds
          salesTeamTopId: $salesTeamTopId
        }
      ) {
        businessProduct {
          id
          name
          salesTeams {
            id
            name
            children {
              id
              name
            }
          }
        }
        rank
        qty
        businessProductId
      }
    }
  }
`;

/**
 * __useBusinessAnalysisRankQuery__
 *
 * To run a query within a React component, call `useBusinessAnalysisRankQuery` and pass it any options that fit your needs.
 * When your component renders, `useBusinessAnalysisRankQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBusinessAnalysisRankQuery({
 *   variables: {
 *      regionIds: // value for 'regionIds'
 *      primaryUserIds: // value for 'primaryUserIds'
 *      expectedClosedDate1: // value for 'expectedClosedDate1'
 *      expectedClosedDate2: // value for 'expectedClosedDate2'
 *      budgetProductIds: // value for 'budgetProductIds'
 *      salesTeamTopId: // value for 'salesTeamTopId'
 *   },
 * });
 */
export function useBusinessAnalysisRankQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    BusinessAnalysisRankQuery,
    BusinessAnalysisRankQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    BusinessAnalysisRankQuery,
    BusinessAnalysisRankQueryVariables
  >(BusinessAnalysisRankDocument, options);
}
export function useBusinessAnalysisRankLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    BusinessAnalysisRankQuery,
    BusinessAnalysisRankQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    BusinessAnalysisRankQuery,
    BusinessAnalysisRankQueryVariables
  >(BusinessAnalysisRankDocument, options);
}
export type BusinessAnalysisRankQueryHookResult = ReturnType<
  typeof useBusinessAnalysisRankQuery
>;
export type BusinessAnalysisRankLazyQueryHookResult = ReturnType<
  typeof useBusinessAnalysisRankLazyQuery
>;
export type BusinessAnalysisRankQueryResult = ApolloReactCommon.QueryResult<
  BusinessAnalysisRankQuery,
  BusinessAnalysisRankQueryVariables
>;
export const BusinessAnalysisRankSalesTeamDocument = gql`
  query businessAnalysisRankSalesTeam(
    $regionIds: [ID!]
    $primaryUserIds: [ID!]
    $expectedClosedDate1: Date
    $expectedClosedDate2: Date
    $budgetProductIds: [ID!]
    $salesTeamTopId: ID
  ) {
    businessAnalysis {
      High: businessProductRank(
        filters: {
          regionIds: $regionIds
          primaryUserIds: $primaryUserIds
          expectedClosedDate1: $expectedClosedDate1
          expectedClosedDate2: $expectedClosedDate2
          statusTypes: InProgress
          transactionOpportunity: High
          budgetProductIds: $budgetProductIds
          salesTeamTopId: $salesTeamTopId
        }
      ) {
        businessProduct {
          id
          name
          salesTeams {
            id
            name
          }
        }
        rank
        qty
        businessProductId
      }
      MediumHigh: businessProductRank(
        filters: {
          regionIds: $regionIds
          primaryUserIds: $primaryUserIds
          expectedClosedDate1: $expectedClosedDate1
          expectedClosedDate2: $expectedClosedDate2
          statusTypes: InProgress
          transactionOpportunity: MediumHigh
          budgetProductIds: $budgetProductIds
          salesTeamTopId: $salesTeamTopId
        }
      ) {
        businessProduct {
          id
          name
          salesTeams {
            id
            name
          }
        }
        rank
        qty
        businessProductId
      }
      Medium: businessProductRank(
        filters: {
          regionIds: $regionIds
          primaryUserIds: $primaryUserIds
          expectedClosedDate1: $expectedClosedDate1
          expectedClosedDate2: $expectedClosedDate2
          statusTypes: InProgress
          transactionOpportunity: Medium
          budgetProductIds: $budgetProductIds
          salesTeamTopId: $salesTeamTopId
        }
      ) {
        businessProduct {
          id
          name
          salesTeams {
            id
            name
          }
        }
        rank
        qty
        businessProductId
      }
    }
  }
`;

/**
 * __useBusinessAnalysisRankSalesTeamQuery__
 *
 * To run a query within a React component, call `useBusinessAnalysisRankSalesTeamQuery` and pass it any options that fit your needs.
 * When your component renders, `useBusinessAnalysisRankSalesTeamQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBusinessAnalysisRankSalesTeamQuery({
 *   variables: {
 *      regionIds: // value for 'regionIds'
 *      primaryUserIds: // value for 'primaryUserIds'
 *      expectedClosedDate1: // value for 'expectedClosedDate1'
 *      expectedClosedDate2: // value for 'expectedClosedDate2'
 *      budgetProductIds: // value for 'budgetProductIds'
 *      salesTeamTopId: // value for 'salesTeamTopId'
 *   },
 * });
 */
export function useBusinessAnalysisRankSalesTeamQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    BusinessAnalysisRankSalesTeamQuery,
    BusinessAnalysisRankSalesTeamQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    BusinessAnalysisRankSalesTeamQuery,
    BusinessAnalysisRankSalesTeamQueryVariables
  >(BusinessAnalysisRankSalesTeamDocument, options);
}
export function useBusinessAnalysisRankSalesTeamLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    BusinessAnalysisRankSalesTeamQuery,
    BusinessAnalysisRankSalesTeamQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    BusinessAnalysisRankSalesTeamQuery,
    BusinessAnalysisRankSalesTeamQueryVariables
  >(BusinessAnalysisRankSalesTeamDocument, options);
}
export type BusinessAnalysisRankSalesTeamQueryHookResult = ReturnType<
  typeof useBusinessAnalysisRankSalesTeamQuery
>;
export type BusinessAnalysisRankSalesTeamLazyQueryHookResult = ReturnType<
  typeof useBusinessAnalysisRankSalesTeamLazyQuery
>;
export type BusinessAnalysisRankSalesTeamQueryResult =
  ApolloReactCommon.QueryResult<
    BusinessAnalysisRankSalesTeamQuery,
    BusinessAnalysisRankSalesTeamQueryVariables
  >;
export const BusinessAnalysisStatDocument = gql`
  query businessAnalysisStat(
    $regionIds: [ID!]
    $primaryUserIds: [ID!]
    $expectedClosedDate1: Date
    $expectedClosedDate2: Date
    $budgetProductIds: [ID!]
  ) {
    businessAnalysis {
      High: businessProductStat(
        filters: {
          regionIds: $regionIds
          primaryUserIds: $primaryUserIds
          expectedClosedDate1: $expectedClosedDate1
          expectedClosedDate2: $expectedClosedDate2
          statusTypes: InProgress
          transactionOpportunity: High
          budgetProductIds: $budgetProductIds
        }
      ) {
        seq
        month1
        month2
        month3
        month4
        month5
        month6
        month7
        month8
        month9
        month10
        month11
        month12
        year
        total
        businessProductId
        businessProduct {
          id
          name
        }
      }
      MediumHigh: businessProductStat(
        filters: {
          regionIds: $regionIds
          primaryUserIds: $primaryUserIds
          expectedClosedDate1: $expectedClosedDate1
          expectedClosedDate2: $expectedClosedDate2
          statusTypes: InProgress
          transactionOpportunity: MediumHigh
          budgetProductIds: $budgetProductIds
        }
      ) {
        seq
        month1
        month2
        month3
        month4
        month5
        month6
        month7
        month8
        month9
        month10
        month11
        month12
        year
        total
        businessProductId
        businessProduct {
          id
          name
        }
      }
      Medium: businessProductStat(
        filters: {
          regionIds: $regionIds
          primaryUserIds: $primaryUserIds
          expectedClosedDate1: $expectedClosedDate1
          expectedClosedDate2: $expectedClosedDate2
          statusTypes: InProgress
          transactionOpportunity: Medium
          budgetProductIds: $budgetProductIds
        }
      ) {
        seq
        month1
        month2
        month3
        month4
        month5
        month6
        month7
        month8
        month9
        month10
        month11
        month12
        year
        total
        businessProductId
        businessProduct {
          id
          name
        }
      }
    }
  }
`;

/**
 * __useBusinessAnalysisStatQuery__
 *
 * To run a query within a React component, call `useBusinessAnalysisStatQuery` and pass it any options that fit your needs.
 * When your component renders, `useBusinessAnalysisStatQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBusinessAnalysisStatQuery({
 *   variables: {
 *      regionIds: // value for 'regionIds'
 *      primaryUserIds: // value for 'primaryUserIds'
 *      expectedClosedDate1: // value for 'expectedClosedDate1'
 *      expectedClosedDate2: // value for 'expectedClosedDate2'
 *      budgetProductIds: // value for 'budgetProductIds'
 *   },
 * });
 */
export function useBusinessAnalysisStatQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    BusinessAnalysisStatQuery,
    BusinessAnalysisStatQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    BusinessAnalysisStatQuery,
    BusinessAnalysisStatQueryVariables
  >(BusinessAnalysisStatDocument, options);
}
export function useBusinessAnalysisStatLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    BusinessAnalysisStatQuery,
    BusinessAnalysisStatQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    BusinessAnalysisStatQuery,
    BusinessAnalysisStatQueryVariables
  >(BusinessAnalysisStatDocument, options);
}
export type BusinessAnalysisStatQueryHookResult = ReturnType<
  typeof useBusinessAnalysisStatQuery
>;
export type BusinessAnalysisStatLazyQueryHookResult = ReturnType<
  typeof useBusinessAnalysisStatLazyQuery
>;
export type BusinessAnalysisStatQueryResult = ApolloReactCommon.QueryResult<
  BusinessAnalysisStatQuery,
  BusinessAnalysisStatQueryVariables
>;
export const BusinessAnalysisDocument = gql`
  query businessAnalysis($filters: BusinessAnalysisBusinessStatFilterInput) {
    businessAnalysis {
      businessStat(filters: $filters) {
        details {
          type
          countPercent
          count
        }
      }
    }
  }
`;

/**
 * __useBusinessAnalysisQuery__
 *
 * To run a query within a React component, call `useBusinessAnalysisQuery` and pass it any options that fit your needs.
 * When your component renders, `useBusinessAnalysisQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBusinessAnalysisQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function useBusinessAnalysisQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    BusinessAnalysisQuery,
    BusinessAnalysisQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    BusinessAnalysisQuery,
    BusinessAnalysisQueryVariables
  >(BusinessAnalysisDocument, options);
}
export function useBusinessAnalysisLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    BusinessAnalysisQuery,
    BusinessAnalysisQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    BusinessAnalysisQuery,
    BusinessAnalysisQueryVariables
  >(BusinessAnalysisDocument, options);
}
export type BusinessAnalysisQueryHookResult = ReturnType<
  typeof useBusinessAnalysisQuery
>;
export type BusinessAnalysisLazyQueryHookResult = ReturnType<
  typeof useBusinessAnalysisLazyQuery
>;
export type BusinessAnalysisQueryResult = ApolloReactCommon.QueryResult<
  BusinessAnalysisQuery,
  BusinessAnalysisQueryVariables
>;
export const CreateBusinessProductDocument = gql`
  mutation CreateBusinessProduct($input: BusinessProductCreateInput!) {
    createBusinessProduct(input: $input) {
      id
    }
  }
`;
export type CreateBusinessProductMutationFn =
  ApolloReactCommon.MutationFunction<
    CreateBusinessProductMutation,
    CreateBusinessProductMutationVariables
  >;

/**
 * __useCreateBusinessProductMutation__
 *
 * To run a mutation, you first call `useCreateBusinessProductMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateBusinessProductMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createBusinessProductMutation, { data, loading, error }] = useCreateBusinessProductMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateBusinessProductMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    CreateBusinessProductMutation,
    CreateBusinessProductMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    CreateBusinessProductMutation,
    CreateBusinessProductMutationVariables
  >(CreateBusinessProductDocument, options);
}
export type CreateBusinessProductMutationHookResult = ReturnType<
  typeof useCreateBusinessProductMutation
>;
export type CreateBusinessProductMutationResult =
  ApolloReactCommon.MutationResult<CreateBusinessProductMutation>;
export type CreateBusinessProductMutationOptions =
  ApolloReactCommon.BaseMutationOptions<
    CreateBusinessProductMutation,
    CreateBusinessProductMutationVariables
  >;
export const UpdateBusinessProductDocument = gql`
  mutation UpdateBusinessProduct($input: BusinessProductUpdateInput!) {
    updateBusinessProduct(input: $input) {
      id
    }
  }
`;
export type UpdateBusinessProductMutationFn =
  ApolloReactCommon.MutationFunction<
    UpdateBusinessProductMutation,
    UpdateBusinessProductMutationVariables
  >;

/**
 * __useUpdateBusinessProductMutation__
 *
 * To run a mutation, you first call `useUpdateBusinessProductMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateBusinessProductMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateBusinessProductMutation, { data, loading, error }] = useUpdateBusinessProductMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateBusinessProductMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    UpdateBusinessProductMutation,
    UpdateBusinessProductMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    UpdateBusinessProductMutation,
    UpdateBusinessProductMutationVariables
  >(UpdateBusinessProductDocument, options);
}
export type UpdateBusinessProductMutationHookResult = ReturnType<
  typeof useUpdateBusinessProductMutation
>;
export type UpdateBusinessProductMutationResult =
  ApolloReactCommon.MutationResult<UpdateBusinessProductMutation>;
export type UpdateBusinessProductMutationOptions =
  ApolloReactCommon.BaseMutationOptions<
    UpdateBusinessProductMutation,
    UpdateBusinessProductMutationVariables
  >;
export const BusinessProductsDocument = gql`
  query BusinessProducts(
    $filters: BusinessProductSearchInput!
    $offset: Int
    $limit: Int
  ) {
    businessProducts(filters: $filters, offset: $offset, limit: $limit) {
      count
      rows {
        id
        brand
        name
        salesTeamGroup {
          id
        }
        materials {
          id
          code
          name
        }
        salesTeams {
          id
          name
        }
        productTeams {
          id
          name
        }
        isActive
      }
    }
  }
`;

/**
 * __useBusinessProductsQuery__
 *
 * To run a query within a React component, call `useBusinessProductsQuery` and pass it any options that fit your needs.
 * When your component renders, `useBusinessProductsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBusinessProductsQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useBusinessProductsQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    BusinessProductsQuery,
    BusinessProductsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    BusinessProductsQuery,
    BusinessProductsQueryVariables
  >(BusinessProductsDocument, options);
}
export function useBusinessProductsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    BusinessProductsQuery,
    BusinessProductsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    BusinessProductsQuery,
    BusinessProductsQueryVariables
  >(BusinessProductsDocument, options);
}
export type BusinessProductsQueryHookResult = ReturnType<
  typeof useBusinessProductsQuery
>;
export type BusinessProductsLazyQueryHookResult = ReturnType<
  typeof useBusinessProductsLazyQuery
>;
export type BusinessProductsQueryResult = ApolloReactCommon.QueryResult<
  BusinessProductsQuery,
  BusinessProductsQueryVariables
>;
export const EyeFixedAssetTypesDocument = gql`
  query EyeFixedAssetTypes(
    $filters: EyeFixedAssetTypeSearchInput!
    $limit: Int
  ) {
    eyeFixedAssetTypes(filters: $filters, limit: $limit) {
      rows {
        id
        name
        code
      }
    }
  }
`;

/**
 * __useEyeFixedAssetTypesQuery__
 *
 * To run a query within a React component, call `useEyeFixedAssetTypesQuery` and pass it any options that fit your needs.
 * When your component renders, `useEyeFixedAssetTypesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEyeFixedAssetTypesQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useEyeFixedAssetTypesQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    EyeFixedAssetTypesQuery,
    EyeFixedAssetTypesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    EyeFixedAssetTypesQuery,
    EyeFixedAssetTypesQueryVariables
  >(EyeFixedAssetTypesDocument, options);
}
export function useEyeFixedAssetTypesLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    EyeFixedAssetTypesQuery,
    EyeFixedAssetTypesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    EyeFixedAssetTypesQuery,
    EyeFixedAssetTypesQueryVariables
  >(EyeFixedAssetTypesDocument, options);
}
export type EyeFixedAssetTypesQueryHookResult = ReturnType<
  typeof useEyeFixedAssetTypesQuery
>;
export type EyeFixedAssetTypesLazyQueryHookResult = ReturnType<
  typeof useEyeFixedAssetTypesLazyQuery
>;
export type EyeFixedAssetTypesQueryResult = ApolloReactCommon.QueryResult<
  EyeFixedAssetTypesQuery,
  EyeFixedAssetTypesQueryVariables
>;
export const DeptsDocument = gql`
  query depts($limit: Int, $offset: Int, $filters: DeptSearchInput!) {
    depts(limit: $limit, offset: $offset, filters: $filters) {
      count
      pageInfo {
        hasNextPage
      }
      rows {
        id
        companyId
        name
        code
        createdAt
        updatedAt
        regionId
        setOfBook
        isActive
        company {
          id
          code
          name
          createdAt
          updatedAt
          regionId
        }
        users {
          id
          code
          name
          dept {
            id
            name
            code
          }
          company {
            id
            name
          }
        }
        children {
          ...childrens
          children {
            ...childrens
            children {
              ...childrens
              children {
                ...childrens
                children {
                  ...childrens
                  children {
                    ...childrens
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  ${ChildrensFragmentDoc}
`;

/**
 * __useDeptsQuery__
 *
 * To run a query within a React component, call `useDeptsQuery` and pass it any options that fit your needs.
 * When your component renders, `useDeptsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDeptsQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function useDeptsQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    DeptsQuery,
    DeptsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<DeptsQuery, DeptsQueryVariables>(
    DeptsDocument,
    options,
  );
}
export function useDeptsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    DeptsQuery,
    DeptsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<DeptsQuery, DeptsQueryVariables>(
    DeptsDocument,
    options,
  );
}
export type DeptsQueryHookResult = ReturnType<typeof useDeptsQuery>;
export type DeptsLazyQueryHookResult = ReturnType<typeof useDeptsLazyQuery>;
export type DeptsQueryResult = ApolloReactCommon.QueryResult<
  DeptsQuery,
  DeptsQueryVariables
>;
export const EyeProductSelectDocument = gql`
  query eyeProductSelect(
    $limit: Int
    $offset: Int
    $filters: EyeProductSearchInput!
  ) {
    eyeProducts(limit: $limit, offset: $offset, filters: $filters) {
      rows {
        id
        name
      }
    }
  }
`;

/**
 * __useEyeProductSelectQuery__
 *
 * To run a query within a React component, call `useEyeProductSelectQuery` and pass it any options that fit your needs.
 * When your component renders, `useEyeProductSelectQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEyeProductSelectQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function useEyeProductSelectQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    EyeProductSelectQuery,
    EyeProductSelectQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    EyeProductSelectQuery,
    EyeProductSelectQueryVariables
  >(EyeProductSelectDocument, options);
}
export function useEyeProductSelectLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    EyeProductSelectQuery,
    EyeProductSelectQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    EyeProductSelectQuery,
    EyeProductSelectQueryVariables
  >(EyeProductSelectDocument, options);
}
export type EyeProductSelectQueryHookResult = ReturnType<
  typeof useEyeProductSelectQuery
>;
export type EyeProductSelectLazyQueryHookResult = ReturnType<
  typeof useEyeProductSelectLazyQuery
>;
export type EyeProductSelectQueryResult = ApolloReactCommon.QueryResult<
  EyeProductSelectQuery,
  EyeProductSelectQueryVariables
>;
export const EyeProductTypesDocument = gql`
  query eyeProductTypes($filters: EyeProductTypeSearchInput!) {
    eyeProductTypes(filters: $filters) {
      count
      pageInfo {
        hasNextPage
      }
      rows {
        id
        regionId
        name
      }
    }
  }
`;

/**
 * __useEyeProductTypesQuery__
 *
 * To run a query within a React component, call `useEyeProductTypesQuery` and pass it any options that fit your needs.
 * When your component renders, `useEyeProductTypesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEyeProductTypesQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function useEyeProductTypesQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    EyeProductTypesQuery,
    EyeProductTypesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    EyeProductTypesQuery,
    EyeProductTypesQueryVariables
  >(EyeProductTypesDocument, options);
}
export function useEyeProductTypesLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    EyeProductTypesQuery,
    EyeProductTypesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    EyeProductTypesQuery,
    EyeProductTypesQueryVariables
  >(EyeProductTypesDocument, options);
}
export type EyeProductTypesQueryHookResult = ReturnType<
  typeof useEyeProductTypesQuery
>;
export type EyeProductTypesLazyQueryHookResult = ReturnType<
  typeof useEyeProductTypesLazyQuery
>;
export type EyeProductTypesQueryResult = ApolloReactCommon.QueryResult<
  EyeProductTypesQuery,
  EyeProductTypesQueryVariables
>;
export const CurrenciesDocument = gql`
  query currencies($filters: CurrencySearchInput!) {
    currencies(filters: $filters) {
      count
      pageInfo {
        hasNextPage
      }
      rows {
        id
        name
        code
      }
    }
  }
`;

/**
 * __useCurrenciesQuery__
 *
 * To run a query within a React component, call `useCurrenciesQuery` and pass it any options that fit your needs.
 * When your component renders, `useCurrenciesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCurrenciesQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function useCurrenciesQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    CurrenciesQuery,
    CurrenciesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<CurrenciesQuery, CurrenciesQueryVariables>(
    CurrenciesDocument,
    options,
  );
}
export function useCurrenciesLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    CurrenciesQuery,
    CurrenciesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    CurrenciesQuery,
    CurrenciesQueryVariables
  >(CurrenciesDocument, options);
}
export type CurrenciesQueryHookResult = ReturnType<typeof useCurrenciesQuery>;
export type CurrenciesLazyQueryHookResult = ReturnType<
  typeof useCurrenciesLazyQuery
>;
export type CurrenciesQueryResult = ApolloReactCommon.QueryResult<
  CurrenciesQuery,
  CurrenciesQueryVariables
>;
export const CustomersSelectDocument = gql`
  query customersSelect(
    $limit: Int
    $offset: Int
    $filters: CustomerSearchInput!
  ) {
    customers(limit: $limit, offset: $offset, filters: $filters) {
      count
      rows {
        id
        code
        name
        address
        contactPersonName
        contactPersonPhone
        provinceId
        cityId
        districtId
        creditQuota
        province {
          id
          name
          cities {
            id
            name
            districts {
              id
              name
            }
          }
        }
        region {
          id
          name
          taxRates {
            id
            regionId
            rate
          }
          localCurrency {
            id
            name
            code
          }
        }
        contactPeople {
          id
          name
        }
        eyeWarrantyContracts {
          id
          code
        }
        eyeServiceOrders {
          id
          code
        }
        salesTeamGroup {
          id
          code
        }
        isGmp
      }
    }
  }
`;

/**
 * __useCustomersSelectQuery__
 *
 * To run a query within a React component, call `useCustomersSelectQuery` and pass it any options that fit your needs.
 * When your component renders, `useCustomersSelectQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCustomersSelectQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function useCustomersSelectQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    CustomersSelectQuery,
    CustomersSelectQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    CustomersSelectQuery,
    CustomersSelectQueryVariables
  >(CustomersSelectDocument, options);
}
export function useCustomersSelectLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    CustomersSelectQuery,
    CustomersSelectQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    CustomersSelectQuery,
    CustomersSelectQueryVariables
  >(CustomersSelectDocument, options);
}
export type CustomersSelectQueryHookResult = ReturnType<
  typeof useCustomersSelectQuery
>;
export type CustomersSelectLazyQueryHookResult = ReturnType<
  typeof useCustomersSelectLazyQuery
>;
export type CustomersSelectQueryResult = ApolloReactCommon.QueryResult<
  CustomersSelectQuery,
  CustomersSelectQueryVariables
>;
export const CustomersCodeSelectDocument = gql`
  query customersCodeSelect(
    $limit: Int
    $offset: Int
    $filters: CustomerSearchInput!
  ) {
    customers(limit: $limit, offset: $offset, filters: $filters) {
      count
      rows {
        id
        code
        name
        address
        contactPersonName
        contactPersonPhone
        districtId
        creditQuota
        contactPeople {
          id
          name
        }
        eyeWarrantyContracts {
          id
          code
        }
      }
    }
  }
`;

/**
 * __useCustomersCodeSelectQuery__
 *
 * To run a query within a React component, call `useCustomersCodeSelectQuery` and pass it any options that fit your needs.
 * When your component renders, `useCustomersCodeSelectQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCustomersCodeSelectQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function useCustomersCodeSelectQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    CustomersCodeSelectQuery,
    CustomersCodeSelectQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    CustomersCodeSelectQuery,
    CustomersCodeSelectQueryVariables
  >(CustomersCodeSelectDocument, options);
}
export function useCustomersCodeSelectLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    CustomersCodeSelectQuery,
    CustomersCodeSelectQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    CustomersCodeSelectQuery,
    CustomersCodeSelectQueryVariables
  >(CustomersCodeSelectDocument, options);
}
export type CustomersCodeSelectQueryHookResult = ReturnType<
  typeof useCustomersCodeSelectQuery
>;
export type CustomersCodeSelectLazyQueryHookResult = ReturnType<
  typeof useCustomersCodeSelectLazyQuery
>;
export type CustomersCodeSelectQueryResult = ApolloReactCommon.QueryResult<
  CustomersCodeSelectQuery,
  CustomersCodeSelectQueryVariables
>;
export const CustomerTypeCodeSelectDocument = gql`
  query customerTypeCodeSelect(
    $limit: Int
    $offset: Int
    $filters: CustomerSearchInput!
  ) {
    customers(limit: $limit, offset: $offset, filters: $filters) {
      count
      rows {
        id
        code
        name
      }
    }
  }
`;

/**
 * __useCustomerTypeCodeSelectQuery__
 *
 * To run a query within a React component, call `useCustomerTypeCodeSelectQuery` and pass it any options that fit your needs.
 * When your component renders, `useCustomerTypeCodeSelectQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCustomerTypeCodeSelectQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function useCustomerTypeCodeSelectQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    CustomerTypeCodeSelectQuery,
    CustomerTypeCodeSelectQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    CustomerTypeCodeSelectQuery,
    CustomerTypeCodeSelectQueryVariables
  >(CustomerTypeCodeSelectDocument, options);
}
export function useCustomerTypeCodeSelectLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    CustomerTypeCodeSelectQuery,
    CustomerTypeCodeSelectQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    CustomerTypeCodeSelectQuery,
    CustomerTypeCodeSelectQueryVariables
  >(CustomerTypeCodeSelectDocument, options);
}
export type CustomerTypeCodeSelectQueryHookResult = ReturnType<
  typeof useCustomerTypeCodeSelectQuery
>;
export type CustomerTypeCodeSelectLazyQueryHookResult = ReturnType<
  typeof useCustomerTypeCodeSelectLazyQuery
>;
export type CustomerTypeCodeSelectQueryResult = ApolloReactCommon.QueryResult<
  CustomerTypeCodeSelectQuery,
  CustomerTypeCodeSelectQueryVariables
>;
export const EyeProductItemTypesDocument = gql`
  query eyeProductItemTypes($filters: EyeProductItemTypeSearchInput!) {
    eyeProductItemTypes(filters: $filters) {
      count
      pageInfo {
        hasNextPage
      }
      rows {
        id
        name
        viewOrder
        category
      }
    }
  }
`;

/**
 * __useEyeProductItemTypesQuery__
 *
 * To run a query within a React component, call `useEyeProductItemTypesQuery` and pass it any options that fit your needs.
 * When your component renders, `useEyeProductItemTypesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEyeProductItemTypesQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function useEyeProductItemTypesQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    EyeProductItemTypesQuery,
    EyeProductItemTypesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    EyeProductItemTypesQuery,
    EyeProductItemTypesQueryVariables
  >(EyeProductItemTypesDocument, options);
}
export function useEyeProductItemTypesLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    EyeProductItemTypesQuery,
    EyeProductItemTypesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    EyeProductItemTypesQuery,
    EyeProductItemTypesQueryVariables
  >(EyeProductItemTypesDocument, options);
}
export type EyeProductItemTypesQueryHookResult = ReturnType<
  typeof useEyeProductItemTypesQuery
>;
export type EyeProductItemTypesLazyQueryHookResult = ReturnType<
  typeof useEyeProductItemTypesLazyQuery
>;
export type EyeProductItemTypesQueryResult = ApolloReactCommon.QueryResult<
  EyeProductItemTypesQuery,
  EyeProductItemTypesQueryVariables
>;
export const PaymentMethodsDocument = gql`
  query paymentMethods($filters: PaymentMethodSearchInput!) {
    paymentMethods(filters: $filters) {
      rows {
        id
        name
      }
    }
  }
`;

/**
 * __usePaymentMethodsQuery__
 *
 * To run a query within a React component, call `usePaymentMethodsQuery` and pass it any options that fit your needs.
 * When your component renders, `usePaymentMethodsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePaymentMethodsQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function usePaymentMethodsQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    PaymentMethodsQuery,
    PaymentMethodsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    PaymentMethodsQuery,
    PaymentMethodsQueryVariables
  >(PaymentMethodsDocument, options);
}
export function usePaymentMethodsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    PaymentMethodsQuery,
    PaymentMethodsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    PaymentMethodsQuery,
    PaymentMethodsQueryVariables
  >(PaymentMethodsDocument, options);
}
export type PaymentMethodsQueryHookResult = ReturnType<
  typeof usePaymentMethodsQuery
>;
export type PaymentMethodsLazyQueryHookResult = ReturnType<
  typeof usePaymentMethodsLazyQuery
>;
export type PaymentMethodsQueryResult = ApolloReactCommon.QueryResult<
  PaymentMethodsQuery,
  PaymentMethodsQueryVariables
>;
export const RegionsDocument = gql`
  query regions($filters: RegionSearchInput!) {
    regions(filters: $filters) {
      count
      rows {
        id
        code
        name
        createdAt
        updatedAt
        taxRates {
          id
          regionId
          rate
        }
        localCurrency {
          id
          name
          code
        }
      }
    }
  }
`;

/**
 * __useRegionsQuery__
 *
 * To run a query within a React component, call `useRegionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useRegionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRegionsQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function useRegionsQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    RegionsQuery,
    RegionsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<RegionsQuery, RegionsQueryVariables>(
    RegionsDocument,
    options,
  );
}
export function useRegionsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    RegionsQuery,
    RegionsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<RegionsQuery, RegionsQueryVariables>(
    RegionsDocument,
    options,
  );
}
export type RegionsQueryHookResult = ReturnType<typeof useRegionsQuery>;
export type RegionsLazyQueryHookResult = ReturnType<typeof useRegionsLazyQuery>;
export type RegionsQueryResult = ApolloReactCommon.QueryResult<
  RegionsQuery,
  RegionsQueryVariables
>;
export const MaterialTypesDocument = gql`
  query materialTypes(
    $limit: Int
    $offset: Int
    $filters: MaterialTypeSearchInput!
  ) {
    materialTypes(limit: $limit, offset: $offset, filters: $filters) {
      rows {
        id
        code
        companyId
        name
      }
    }
  }
`;

/**
 * __useMaterialTypesQuery__
 *
 * To run a query within a React component, call `useMaterialTypesQuery` and pass it any options that fit your needs.
 * When your component renders, `useMaterialTypesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMaterialTypesQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function useMaterialTypesQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    MaterialTypesQuery,
    MaterialTypesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    MaterialTypesQuery,
    MaterialTypesQueryVariables
  >(MaterialTypesDocument, options);
}
export function useMaterialTypesLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    MaterialTypesQuery,
    MaterialTypesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    MaterialTypesQuery,
    MaterialTypesQueryVariables
  >(MaterialTypesDocument, options);
}
export type MaterialTypesQueryHookResult = ReturnType<
  typeof useMaterialTypesQuery
>;
export type MaterialTypesLazyQueryHookResult = ReturnType<
  typeof useMaterialTypesLazyQuery
>;
export type MaterialTypesQueryResult = ApolloReactCommon.QueryResult<
  MaterialTypesQuery,
  MaterialTypesQueryVariables
>;
export const SalesTeamsPaginationDocument = gql`
  query salesTeamsPagination(
    $limit: Int
    $offset: Int
    $filters: SalesTeamSearchInput!
  ) {
    salesTeams(limit: $limit, offset: $offset, filters: $filters) {
      count
      rows {
        id
        name
        users {
          id
          code
          name
        }
        children {
          ...children
          children {
            ...children
            children {
              ...children
              children {
                ...children
                children {
                  ...children
                  children {
                    ...children
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  ${ChildrenFragmentDoc}
`;

/**
 * __useSalesTeamsPaginationQuery__
 *
 * To run a query within a React component, call `useSalesTeamsPaginationQuery` and pass it any options that fit your needs.
 * When your component renders, `useSalesTeamsPaginationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSalesTeamsPaginationQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function useSalesTeamsPaginationQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    SalesTeamsPaginationQuery,
    SalesTeamsPaginationQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    SalesTeamsPaginationQuery,
    SalesTeamsPaginationQueryVariables
  >(SalesTeamsPaginationDocument, options);
}
export function useSalesTeamsPaginationLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    SalesTeamsPaginationQuery,
    SalesTeamsPaginationQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    SalesTeamsPaginationQuery,
    SalesTeamsPaginationQueryVariables
  >(SalesTeamsPaginationDocument, options);
}
export type SalesTeamsPaginationQueryHookResult = ReturnType<
  typeof useSalesTeamsPaginationQuery
>;
export type SalesTeamsPaginationLazyQueryHookResult = ReturnType<
  typeof useSalesTeamsPaginationLazyQuery
>;
export type SalesTeamsPaginationQueryResult = ApolloReactCommon.QueryResult<
  SalesTeamsPaginationQuery,
  SalesTeamsPaginationQueryVariables
>;
export const CreateCompetitorDocument = gql`
  mutation createCompetitor($input: CompetitorCreateInput!) {
    createCompetitor(input: $input) {
      id
      name
    }
  }
`;
export type CreateCompetitorMutationFn = ApolloReactCommon.MutationFunction<
  CreateCompetitorMutation,
  CreateCompetitorMutationVariables
>;

/**
 * __useCreateCompetitorMutation__
 *
 * To run a mutation, you first call `useCreateCompetitorMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCompetitorMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCompetitorMutation, { data, loading, error }] = useCreateCompetitorMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateCompetitorMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    CreateCompetitorMutation,
    CreateCompetitorMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    CreateCompetitorMutation,
    CreateCompetitorMutationVariables
  >(CreateCompetitorDocument, options);
}
export type CreateCompetitorMutationHookResult = ReturnType<
  typeof useCreateCompetitorMutation
>;
export type CreateCompetitorMutationResult =
  ApolloReactCommon.MutationResult<CreateCompetitorMutation>;
export type CreateCompetitorMutationOptions =
  ApolloReactCommon.BaseMutationOptions<
    CreateCompetitorMutation,
    CreateCompetitorMutationVariables
  >;
export const UpdateCompetitorDocument = gql`
  mutation updateCompetitor($input: CompetitorUpdateInput!) {
    updateCompetitor(input: $input) {
      id
      name
    }
  }
`;
export type UpdateCompetitorMutationFn = ApolloReactCommon.MutationFunction<
  UpdateCompetitorMutation,
  UpdateCompetitorMutationVariables
>;

/**
 * __useUpdateCompetitorMutation__
 *
 * To run a mutation, you first call `useUpdateCompetitorMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCompetitorMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCompetitorMutation, { data, loading, error }] = useUpdateCompetitorMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateCompetitorMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    UpdateCompetitorMutation,
    UpdateCompetitorMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    UpdateCompetitorMutation,
    UpdateCompetitorMutationVariables
  >(UpdateCompetitorDocument, options);
}
export type UpdateCompetitorMutationHookResult = ReturnType<
  typeof useUpdateCompetitorMutation
>;
export type UpdateCompetitorMutationResult =
  ApolloReactCommon.MutationResult<UpdateCompetitorMutation>;
export type UpdateCompetitorMutationOptions =
  ApolloReactCommon.BaseMutationOptions<
    UpdateCompetitorMutation,
    UpdateCompetitorMutationVariables
  >;
export const UpdateBusinessDocument = gql`
  mutation updateBusiness($input: BusinessUpdateInput!) {
    updateBusiness(input: $input) {
      id
    }
  }
`;
export type UpdateBusinessMutationFn = ApolloReactCommon.MutationFunction<
  UpdateBusinessMutation,
  UpdateBusinessMutationVariables
>;

/**
 * __useUpdateBusinessMutation__
 *
 * To run a mutation, you first call `useUpdateBusinessMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateBusinessMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateBusinessMutation, { data, loading, error }] = useUpdateBusinessMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateBusinessMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    UpdateBusinessMutation,
    UpdateBusinessMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    UpdateBusinessMutation,
    UpdateBusinessMutationVariables
  >(UpdateBusinessDocument, options);
}
export type UpdateBusinessMutationHookResult = ReturnType<
  typeof useUpdateBusinessMutation
>;
export type UpdateBusinessMutationResult =
  ApolloReactCommon.MutationResult<UpdateBusinessMutation>;
export type UpdateBusinessMutationOptions =
  ApolloReactCommon.BaseMutationOptions<
    UpdateBusinessMutation,
    UpdateBusinessMutationVariables
  >;
export const CreateBusinessDocument = gql`
  mutation createBusiness($input: BusinessCreateInput!) {
    createBusiness(input: $input) {
      id
      code
    }
  }
`;
export type CreateBusinessMutationFn = ApolloReactCommon.MutationFunction<
  CreateBusinessMutation,
  CreateBusinessMutationVariables
>;

/**
 * __useCreateBusinessMutation__
 *
 * To run a mutation, you first call `useCreateBusinessMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateBusinessMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createBusinessMutation, { data, loading, error }] = useCreateBusinessMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateBusinessMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    CreateBusinessMutation,
    CreateBusinessMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    CreateBusinessMutation,
    CreateBusinessMutationVariables
  >(CreateBusinessDocument, options);
}
export type CreateBusinessMutationHookResult = ReturnType<
  typeof useCreateBusinessMutation
>;
export type CreateBusinessMutationResult =
  ApolloReactCommon.MutationResult<CreateBusinessMutation>;
export type CreateBusinessMutationOptions =
  ApolloReactCommon.BaseMutationOptions<
    CreateBusinessMutation,
    CreateBusinessMutationVariables
  >;
export const BatchUpdateBusinessSalesTeamUnitDocument = gql`
  mutation batchUpdateBusinessSalesTeamUnit(
    $input: BusinessSalesTeamUnitBatchUpdateInput!
  ) {
    batchUpdateBusinessSalesTeamUnit(input: $input) {
      id
    }
  }
`;
export type BatchUpdateBusinessSalesTeamUnitMutationFn =
  ApolloReactCommon.MutationFunction<
    BatchUpdateBusinessSalesTeamUnitMutation,
    BatchUpdateBusinessSalesTeamUnitMutationVariables
  >;

/**
 * __useBatchUpdateBusinessSalesTeamUnitMutation__
 *
 * To run a mutation, you first call `useBatchUpdateBusinessSalesTeamUnitMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBatchUpdateBusinessSalesTeamUnitMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [batchUpdateBusinessSalesTeamUnitMutation, { data, loading, error }] = useBatchUpdateBusinessSalesTeamUnitMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useBatchUpdateBusinessSalesTeamUnitMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    BatchUpdateBusinessSalesTeamUnitMutation,
    BatchUpdateBusinessSalesTeamUnitMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    BatchUpdateBusinessSalesTeamUnitMutation,
    BatchUpdateBusinessSalesTeamUnitMutationVariables
  >(BatchUpdateBusinessSalesTeamUnitDocument, options);
}
export type BatchUpdateBusinessSalesTeamUnitMutationHookResult = ReturnType<
  typeof useBatchUpdateBusinessSalesTeamUnitMutation
>;
export type BatchUpdateBusinessSalesTeamUnitMutationResult =
  ApolloReactCommon.MutationResult<BatchUpdateBusinessSalesTeamUnitMutation>;
export type BatchUpdateBusinessSalesTeamUnitMutationOptions =
  ApolloReactCommon.BaseMutationOptions<
    BatchUpdateBusinessSalesTeamUnitMutation,
    BatchUpdateBusinessSalesTeamUnitMutationVariables
  >;
export const DeleteBusinessDocument = gql`
  mutation DeleteBusiness($deleteBusinessId: ID!) {
    deleteBusiness(id: $deleteBusinessId)
  }
`;
export type DeleteBusinessMutationFn = ApolloReactCommon.MutationFunction<
  DeleteBusinessMutation,
  DeleteBusinessMutationVariables
>;

/**
 * __useDeleteBusinessMutation__
 *
 * To run a mutation, you first call `useDeleteBusinessMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteBusinessMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteBusinessMutation, { data, loading, error }] = useDeleteBusinessMutation({
 *   variables: {
 *      deleteBusinessId: // value for 'deleteBusinessId'
 *   },
 * });
 */
export function useDeleteBusinessMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    DeleteBusinessMutation,
    DeleteBusinessMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    DeleteBusinessMutation,
    DeleteBusinessMutationVariables
  >(DeleteBusinessDocument, options);
}
export type DeleteBusinessMutationHookResult = ReturnType<
  typeof useDeleteBusinessMutation
>;
export type DeleteBusinessMutationResult =
  ApolloReactCommon.MutationResult<DeleteBusinessMutation>;
export type DeleteBusinessMutationOptions =
  ApolloReactCommon.BaseMutationOptions<
    DeleteBusinessMutation,
    DeleteBusinessMutationVariables
  >;
export const BusinessesDocument = gql`
  query businesses(
    $limit: Int
    $offset: Int
    $filters: BusinessSearchInput!
    $sorts: [BusinessSortInput!]
  ) {
    businesses(
      limit: $limit
      offset: $offset
      filters: $filters
      sorts: $sorts
    ) {
      count
      rows {
        id
        code
        type {
          id
          name
        }
        salesTeamGroup {
          id
          code
          name
        }
        title
        content
        eyeQuotationOrderCode
        budgetAmount
        dealAmount
        expectedClosedDate
        closedDate
        orderCode
        winningOpportunity {
          id
          code
          name
        }
        buyingOpportunity {
          id
          code
          name
        }
        losingReason
        losingImprovementPlan
        status {
          id
          name
          type
          buyingOpportunity
        }
        customer {
          id
          code
          name
          address
          region {
            id
            name
          }
        }
        primaryUser {
          id
          code
          name
          dept {
            id
            name
          }
          salesTeams {
            id
            name
            top {
              id
              name
            }
          }
        }
        primaryContactPeople {
          id
          name
        }
        createdAt
        updatedAt
        createdUser {
          id
          name
          code
        }
        updatedUser {
          id
          name
          code
        }
        businessesToDealProducts {
          id
          dealProductId
          dealProduct {
            id
            name
          }
        }
        competitors {
          id
          name
        }
        businessesToBudgetProducts {
          id
          budgetProductId
          qty
          budgetProduct {
            id
            name
            isActive
            brand
          }
        }
        businessesUsers {
          id
          userId
          salesTeamId
          user {
            id
            name
          }
          salesTeam {
            id
            name
          }
        }
        losingReasons {
          id
          name
        }
        properties {
          id
        }
        salesMethodId
        salesMethod {
          id
          name
        }
        dealerId
        salesTeamUnitId
        salesTeamUnit {
          id
          name
        }
      }
    }
  }
`;

/**
 * __useBusinessesQuery__
 *
 * To run a query within a React component, call `useBusinessesQuery` and pass it any options that fit your needs.
 * When your component renders, `useBusinessesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBusinessesQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      filters: // value for 'filters'
 *      sorts: // value for 'sorts'
 *   },
 * });
 */
export function useBusinessesQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    BusinessesQuery,
    BusinessesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<BusinessesQuery, BusinessesQueryVariables>(
    BusinessesDocument,
    options,
  );
}
export function useBusinessesLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    BusinessesQuery,
    BusinessesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    BusinessesQuery,
    BusinessesQueryVariables
  >(BusinessesDocument, options);
}
export type BusinessesQueryHookResult = ReturnType<typeof useBusinessesQuery>;
export type BusinessesLazyQueryHookResult = ReturnType<
  typeof useBusinessesLazyQuery
>;
export type BusinessesQueryResult = ApolloReactCommon.QueryResult<
  BusinessesQuery,
  BusinessesQueryVariables
>;
export const BusinessOpportunitiesDocument = gql`
  query businessOpportunities(
    $limit: Int
    $offset: Int
    $filters: BusinessOpportunitySearchInput!
  ) {
    businessOpportunities(limit: $limit, offset: $offset, filters: $filters) {
      count
      rows {
        id
        name
        code
        enabled
        salesTeamGroup {
          id
          name
        }
      }
    }
  }
`;

/**
 * __useBusinessOpportunitiesQuery__
 *
 * To run a query within a React component, call `useBusinessOpportunitiesQuery` and pass it any options that fit your needs.
 * When your component renders, `useBusinessOpportunitiesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBusinessOpportunitiesQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function useBusinessOpportunitiesQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    BusinessOpportunitiesQuery,
    BusinessOpportunitiesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    BusinessOpportunitiesQuery,
    BusinessOpportunitiesQueryVariables
  >(BusinessOpportunitiesDocument, options);
}
export function useBusinessOpportunitiesLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    BusinessOpportunitiesQuery,
    BusinessOpportunitiesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    BusinessOpportunitiesQuery,
    BusinessOpportunitiesQueryVariables
  >(BusinessOpportunitiesDocument, options);
}
export type BusinessOpportunitiesQueryHookResult = ReturnType<
  typeof useBusinessOpportunitiesQuery
>;
export type BusinessOpportunitiesLazyQueryHookResult = ReturnType<
  typeof useBusinessOpportunitiesLazyQuery
>;
export type BusinessOpportunitiesQueryResult = ApolloReactCommon.QueryResult<
  BusinessOpportunitiesQuery,
  BusinessOpportunitiesQueryVariables
>;
export const BusinessStatusesDocument = gql`
  query businessStatuses(
    $limit: Int
    $offset: Int
    $filters: BusinessStatusSearchInput!
  ) {
    businessStatuses(limit: $limit, offset: $offset, filters: $filters) {
      count
      rows {
        name
        id
        type
        buyingOpportunity
        salesTeamGroup {
          id
          name
        }
      }
    }
  }
`;

/**
 * __useBusinessStatusesQuery__
 *
 * To run a query within a React component, call `useBusinessStatusesQuery` and pass it any options that fit your needs.
 * When your component renders, `useBusinessStatusesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBusinessStatusesQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function useBusinessStatusesQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    BusinessStatusesQuery,
    BusinessStatusesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    BusinessStatusesQuery,
    BusinessStatusesQueryVariables
  >(BusinessStatusesDocument, options);
}
export function useBusinessStatusesLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    BusinessStatusesQuery,
    BusinessStatusesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    BusinessStatusesQuery,
    BusinessStatusesQueryVariables
  >(BusinessStatusesDocument, options);
}
export type BusinessStatusesQueryHookResult = ReturnType<
  typeof useBusinessStatusesQuery
>;
export type BusinessStatusesLazyQueryHookResult = ReturnType<
  typeof useBusinessStatusesLazyQuery
>;
export type BusinessStatusesQueryResult = ApolloReactCommon.QueryResult<
  BusinessStatusesQuery,
  BusinessStatusesQueryVariables
>;
export const BusinessLosingReasonsDocument = gql`
  query businessLosingReasons(
    $limit: Int
    $offset: Int
    $filters: BusinessLosingReasonSearchInput!
  ) {
    businessLosingReasons(limit: $limit, offset: $offset, filters: $filters) {
      count
      rows {
        name
        id
        salesTeamGroupId
      }
    }
  }
`;

/**
 * __useBusinessLosingReasonsQuery__
 *
 * To run a query within a React component, call `useBusinessLosingReasonsQuery` and pass it any options that fit your needs.
 * When your component renders, `useBusinessLosingReasonsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBusinessLosingReasonsQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function useBusinessLosingReasonsQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    BusinessLosingReasonsQuery,
    BusinessLosingReasonsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    BusinessLosingReasonsQuery,
    BusinessLosingReasonsQueryVariables
  >(BusinessLosingReasonsDocument, options);
}
export function useBusinessLosingReasonsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    BusinessLosingReasonsQuery,
    BusinessLosingReasonsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    BusinessLosingReasonsQuery,
    BusinessLosingReasonsQueryVariables
  >(BusinessLosingReasonsDocument, options);
}
export type BusinessLosingReasonsQueryHookResult = ReturnType<
  typeof useBusinessLosingReasonsQuery
>;
export type BusinessLosingReasonsLazyQueryHookResult = ReturnType<
  typeof useBusinessLosingReasonsLazyQuery
>;
export type BusinessLosingReasonsQueryResult = ApolloReactCommon.QueryResult<
  BusinessLosingReasonsQuery,
  BusinessLosingReasonsQueryVariables
>;
export const CompetitorsDocument = gql`
  query competitors(
    $limit: Int
    $offset: Int
    $filters: CompetitorSearchInput!
  ) {
    competitors(limit: $limit, offset: $offset, filters: $filters) {
      count
      rows {
        name
        id
        salesTeamGroup {
          id
          name
        }
        advantage
        disadvantage
        strategy
        memo
        brand
        model
        agents
        businessProducts {
          id
          name
        }
        createdUser {
          id
          name
        }
        updatedUser {
          id
          name
        }
        createdAt
        updatedAt
        region {
          id
          name
        }
      }
    }
  }
`;

/**
 * __useCompetitorsQuery__
 *
 * To run a query within a React component, call `useCompetitorsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCompetitorsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCompetitorsQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function useCompetitorsQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    CompetitorsQuery,
    CompetitorsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<CompetitorsQuery, CompetitorsQueryVariables>(
    CompetitorsDocument,
    options,
  );
}
export function useCompetitorsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    CompetitorsQuery,
    CompetitorsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    CompetitorsQuery,
    CompetitorsQueryVariables
  >(CompetitorsDocument, options);
}
export type CompetitorsQueryHookResult = ReturnType<typeof useCompetitorsQuery>;
export type CompetitorsLazyQueryHookResult = ReturnType<
  typeof useCompetitorsLazyQuery
>;
export type CompetitorsQueryResult = ApolloReactCommon.QueryResult<
  CompetitorsQuery,
  CompetitorsQueryVariables
>;
export const BusinessPropertyTypesDocument = gql`
  query businessPropertyTypes(
    $filters: BusinessPropertyTypeSearchInput!
    $limit: Int
  ) {
    businessPropertyTypes(filters: $filters, limit: $limit) {
      rows {
        id
        code
        properties {
          id
          name
        }
      }
    }
  }
`;

/**
 * __useBusinessPropertyTypesQuery__
 *
 * To run a query within a React component, call `useBusinessPropertyTypesQuery` and pass it any options that fit your needs.
 * When your component renders, `useBusinessPropertyTypesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBusinessPropertyTypesQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useBusinessPropertyTypesQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    BusinessPropertyTypesQuery,
    BusinessPropertyTypesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    BusinessPropertyTypesQuery,
    BusinessPropertyTypesQueryVariables
  >(BusinessPropertyTypesDocument, options);
}
export function useBusinessPropertyTypesLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    BusinessPropertyTypesQuery,
    BusinessPropertyTypesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    BusinessPropertyTypesQuery,
    BusinessPropertyTypesQueryVariables
  >(BusinessPropertyTypesDocument, options);
}
export type BusinessPropertyTypesQueryHookResult = ReturnType<
  typeof useBusinessPropertyTypesQuery
>;
export type BusinessPropertyTypesLazyQueryHookResult = ReturnType<
  typeof useBusinessPropertyTypesLazyQuery
>;
export type BusinessPropertyTypesQueryResult = ApolloReactCommon.QueryResult<
  BusinessPropertyTypesQuery,
  BusinessPropertyTypesQueryVariables
>;
export const BusinessSalesMethodsDocument = gql`
  query businessSalesMethods($filters: BusinessSalesMethodSearchInput!) {
    businessSalesMethods(filters: $filters) {
      rows {
        id
        code
        name
      }
    }
  }
`;

/**
 * __useBusinessSalesMethodsQuery__
 *
 * To run a query within a React component, call `useBusinessSalesMethodsQuery` and pass it any options that fit your needs.
 * When your component renders, `useBusinessSalesMethodsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBusinessSalesMethodsQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function useBusinessSalesMethodsQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    BusinessSalesMethodsQuery,
    BusinessSalesMethodsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    BusinessSalesMethodsQuery,
    BusinessSalesMethodsQueryVariables
  >(BusinessSalesMethodsDocument, options);
}
export function useBusinessSalesMethodsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    BusinessSalesMethodsQuery,
    BusinessSalesMethodsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    BusinessSalesMethodsQuery,
    BusinessSalesMethodsQueryVariables
  >(BusinessSalesMethodsDocument, options);
}
export type BusinessSalesMethodsQueryHookResult = ReturnType<
  typeof useBusinessSalesMethodsQuery
>;
export type BusinessSalesMethodsLazyQueryHookResult = ReturnType<
  typeof useBusinessSalesMethodsLazyQuery
>;
export type BusinessSalesMethodsQueryResult = ApolloReactCommon.QueryResult<
  BusinessSalesMethodsQuery,
  BusinessSalesMethodsQueryVariables
>;
export const BusinessIdsDocument = gql`
  query BusinessIds($limit: Int, $offset: Int, $filters: BusinessSearchInput!) {
    businesses(limit: $limit, offset: $offset, filters: $filters) {
      count
      rows {
        id
      }
    }
  }
`;

/**
 * __useBusinessIdsQuery__
 *
 * To run a query within a React component, call `useBusinessIdsQuery` and pass it any options that fit your needs.
 * When your component renders, `useBusinessIdsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBusinessIdsQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function useBusinessIdsQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    BusinessIdsQuery,
    BusinessIdsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<BusinessIdsQuery, BusinessIdsQueryVariables>(
    BusinessIdsDocument,
    options,
  );
}
export function useBusinessIdsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    BusinessIdsQuery,
    BusinessIdsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    BusinessIdsQuery,
    BusinessIdsQueryVariables
  >(BusinessIdsDocument, options);
}
export type BusinessIdsQueryHookResult = ReturnType<typeof useBusinessIdsQuery>;
export type BusinessIdsLazyQueryHookResult = ReturnType<
  typeof useBusinessIdsLazyQuery
>;
export type BusinessIdsQueryResult = ApolloReactCommon.QueryResult<
  BusinessIdsQuery,
  BusinessIdsQueryVariables
>;
export const BusinessBudgetAmountSumDocument = gql`
  query businessBudgetAmountSum($filters: BusinessSearchInput!) {
    businessBudgetAmountSum(filters: $filters)
  }
`;

/**
 * __useBusinessBudgetAmountSumQuery__
 *
 * To run a query within a React component, call `useBusinessBudgetAmountSumQuery` and pass it any options that fit your needs.
 * When your component renders, `useBusinessBudgetAmountSumQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBusinessBudgetAmountSumQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function useBusinessBudgetAmountSumQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    BusinessBudgetAmountSumQuery,
    BusinessBudgetAmountSumQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    BusinessBudgetAmountSumQuery,
    BusinessBudgetAmountSumQueryVariables
  >(BusinessBudgetAmountSumDocument, options);
}
export function useBusinessBudgetAmountSumLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    BusinessBudgetAmountSumQuery,
    BusinessBudgetAmountSumQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    BusinessBudgetAmountSumQuery,
    BusinessBudgetAmountSumQueryVariables
  >(BusinessBudgetAmountSumDocument, options);
}
export type BusinessBudgetAmountSumQueryHookResult = ReturnType<
  typeof useBusinessBudgetAmountSumQuery
>;
export type BusinessBudgetAmountSumLazyQueryHookResult = ReturnType<
  typeof useBusinessBudgetAmountSumLazyQuery
>;
export type BusinessBudgetAmountSumQueryResult = ApolloReactCommon.QueryResult<
  BusinessBudgetAmountSumQuery,
  BusinessBudgetAmountSumQueryVariables
>;
export const EyeServiceOrdersCodesDocument = gql`
  query EyeServiceOrdersCodes($filters: EyeServiceOrderSearchInput!) {
    eyeServiceOrders(filters: $filters) {
      count
      rows {
        id
        code
      }
    }
  }
`;

/**
 * __useEyeServiceOrdersCodesQuery__
 *
 * To run a query within a React component, call `useEyeServiceOrdersCodesQuery` and pass it any options that fit your needs.
 * When your component renders, `useEyeServiceOrdersCodesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEyeServiceOrdersCodesQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function useEyeServiceOrdersCodesQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    EyeServiceOrdersCodesQuery,
    EyeServiceOrdersCodesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    EyeServiceOrdersCodesQuery,
    EyeServiceOrdersCodesQueryVariables
  >(EyeServiceOrdersCodesDocument, options);
}
export function useEyeServiceOrdersCodesLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    EyeServiceOrdersCodesQuery,
    EyeServiceOrdersCodesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    EyeServiceOrdersCodesQuery,
    EyeServiceOrdersCodesQueryVariables
  >(EyeServiceOrdersCodesDocument, options);
}
export type EyeServiceOrdersCodesQueryHookResult = ReturnType<
  typeof useEyeServiceOrdersCodesQuery
>;
export type EyeServiceOrdersCodesLazyQueryHookResult = ReturnType<
  typeof useEyeServiceOrdersCodesLazyQuery
>;
export type EyeServiceOrdersCodesQueryResult = ApolloReactCommon.QueryResult<
  EyeServiceOrdersCodesQuery,
  EyeServiceOrdersCodesQueryVariables
>;
export const CitiesDocument = gql`
  query cities($limit: Int, $offset: Int, $filters: CitySearchInput!) {
    cities(limit: $limit, offset: $offset, filters: $filters) {
      count
      rows {
        id
        name
      }
    }
  }
`;

/**
 * __useCitiesQuery__
 *
 * To run a query within a React component, call `useCitiesQuery` and pass it any options that fit your needs.
 * When your component renders, `useCitiesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCitiesQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function useCitiesQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    CitiesQuery,
    CitiesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<CitiesQuery, CitiesQueryVariables>(
    CitiesDocument,
    options,
  );
}
export function useCitiesLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    CitiesQuery,
    CitiesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<CitiesQuery, CitiesQueryVariables>(
    CitiesDocument,
    options,
  );
}
export type CitiesQueryHookResult = ReturnType<typeof useCitiesQuery>;
export type CitiesLazyQueryHookResult = ReturnType<typeof useCitiesLazyQuery>;
export type CitiesQueryResult = ApolloReactCommon.QueryResult<
  CitiesQuery,
  CitiesQueryVariables
>;
export const DistrictsDocument = gql`
  query districts($limit: Int, $offset: Int, $filters: DistrictSearchInput!) {
    districts(limit: $limit, offset: $offset, filters: $filters) {
      count
      rows {
        id
        name
      }
    }
  }
`;

/**
 * __useDistrictsQuery__
 *
 * To run a query within a React component, call `useDistrictsQuery` and pass it any options that fit your needs.
 * When your component renders, `useDistrictsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDistrictsQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function useDistrictsQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    DistrictsQuery,
    DistrictsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<DistrictsQuery, DistrictsQueryVariables>(
    DistrictsDocument,
    options,
  );
}
export function useDistrictsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    DistrictsQuery,
    DistrictsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<DistrictsQuery, DistrictsQueryVariables>(
    DistrictsDocument,
    options,
  );
}
export type DistrictsQueryHookResult = ReturnType<typeof useDistrictsQuery>;
export type DistrictsLazyQueryHookResult = ReturnType<
  typeof useDistrictsLazyQuery
>;
export type DistrictsQueryResult = ApolloReactCommon.QueryResult<
  DistrictsQuery,
  DistrictsQueryVariables
>;
export const ProvincesDocument = gql`
  query provinces($limit: Int, $offset: Int, $filters: ProvinceSearchInput!) {
    provinces(limit: $limit, offset: $offset, filters: $filters) {
      count
      rows {
        id
        name
        cities {
          id
          name
          districts {
            id
            name
          }
        }
      }
    }
  }
`;

/**
 * __useProvincesQuery__
 *
 * To run a query within a React component, call `useProvincesQuery` and pass it any options that fit your needs.
 * When your component renders, `useProvincesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProvincesQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function useProvincesQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    ProvincesQuery,
    ProvincesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<ProvincesQuery, ProvincesQueryVariables>(
    ProvincesDocument,
    options,
  );
}
export function useProvincesLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    ProvincesQuery,
    ProvincesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<ProvincesQuery, ProvincesQueryVariables>(
    ProvincesDocument,
    options,
  );
}
export type ProvincesQueryHookResult = ReturnType<typeof useProvincesQuery>;
export type ProvincesLazyQueryHookResult = ReturnType<
  typeof useProvincesLazyQuery
>;
export type ProvincesQueryResult = ApolloReactCommon.QueryResult<
  ProvincesQuery,
  ProvincesQueryVariables
>;
export const CreateCustomerCertificateDocument = gql`
  mutation createCustomerCertificate($input: CustomerCertificateCreateInput!) {
    createCustomerCertificate(input: $input) {
      id
      code
    }
  }
`;
export type CreateCustomerCertificateMutationFn =
  ApolloReactCommon.MutationFunction<
    CreateCustomerCertificateMutation,
    CreateCustomerCertificateMutationVariables
  >;

/**
 * __useCreateCustomerCertificateMutation__
 *
 * To run a mutation, you first call `useCreateCustomerCertificateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCustomerCertificateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCustomerCertificateMutation, { data, loading, error }] = useCreateCustomerCertificateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateCustomerCertificateMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    CreateCustomerCertificateMutation,
    CreateCustomerCertificateMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    CreateCustomerCertificateMutation,
    CreateCustomerCertificateMutationVariables
  >(CreateCustomerCertificateDocument, options);
}
export type CreateCustomerCertificateMutationHookResult = ReturnType<
  typeof useCreateCustomerCertificateMutation
>;
export type CreateCustomerCertificateMutationResult =
  ApolloReactCommon.MutationResult<CreateCustomerCertificateMutation>;
export type CreateCustomerCertificateMutationOptions =
  ApolloReactCommon.BaseMutationOptions<
    CreateCustomerCertificateMutation,
    CreateCustomerCertificateMutationVariables
  >;
export const UpdateCustomerCertificateDocument = gql`
  mutation updateCustomerCertificate($input: CustomerCertificateUpdateInput!) {
    updateCustomerCertificate(input: $input) {
      id
      code
    }
  }
`;
export type UpdateCustomerCertificateMutationFn =
  ApolloReactCommon.MutationFunction<
    UpdateCustomerCertificateMutation,
    UpdateCustomerCertificateMutationVariables
  >;

/**
 * __useUpdateCustomerCertificateMutation__
 *
 * To run a mutation, you first call `useUpdateCustomerCertificateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCustomerCertificateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCustomerCertificateMutation, { data, loading, error }] = useUpdateCustomerCertificateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateCustomerCertificateMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    UpdateCustomerCertificateMutation,
    UpdateCustomerCertificateMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    UpdateCustomerCertificateMutation,
    UpdateCustomerCertificateMutationVariables
  >(UpdateCustomerCertificateDocument, options);
}
export type UpdateCustomerCertificateMutationHookResult = ReturnType<
  typeof useUpdateCustomerCertificateMutation
>;
export type UpdateCustomerCertificateMutationResult =
  ApolloReactCommon.MutationResult<UpdateCustomerCertificateMutation>;
export type UpdateCustomerCertificateMutationOptions =
  ApolloReactCommon.BaseMutationOptions<
    UpdateCustomerCertificateMutation,
    UpdateCustomerCertificateMutationVariables
  >;
export const CreateCustomerAttachmentDocument = gql`
  mutation createCustomerAttachment($input: CustomerAttachmentCreateInput!) {
    createCustomerAttachment(input: $input) {
      id
      url
      name
      s3Key
    }
  }
`;
export type CreateCustomerAttachmentMutationFn =
  ApolloReactCommon.MutationFunction<
    CreateCustomerAttachmentMutation,
    CreateCustomerAttachmentMutationVariables
  >;

/**
 * __useCreateCustomerAttachmentMutation__
 *
 * To run a mutation, you first call `useCreateCustomerAttachmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCustomerAttachmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCustomerAttachmentMutation, { data, loading, error }] = useCreateCustomerAttachmentMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateCustomerAttachmentMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    CreateCustomerAttachmentMutation,
    CreateCustomerAttachmentMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    CreateCustomerAttachmentMutation,
    CreateCustomerAttachmentMutationVariables
  >(CreateCustomerAttachmentDocument, options);
}
export type CreateCustomerAttachmentMutationHookResult = ReturnType<
  typeof useCreateCustomerAttachmentMutation
>;
export type CreateCustomerAttachmentMutationResult =
  ApolloReactCommon.MutationResult<CreateCustomerAttachmentMutation>;
export type CreateCustomerAttachmentMutationOptions =
  ApolloReactCommon.BaseMutationOptions<
    CreateCustomerAttachmentMutation,
    CreateCustomerAttachmentMutationVariables
  >;
export const UpdateCustomerAttachmentDocument = gql`
  mutation updateCustomerAttachment($input: CustomerAttachmentUpdateInput!) {
    updateCustomerAttachment(input: $input) {
      id
      url
      name
      s3Key
    }
  }
`;
export type UpdateCustomerAttachmentMutationFn =
  ApolloReactCommon.MutationFunction<
    UpdateCustomerAttachmentMutation,
    UpdateCustomerAttachmentMutationVariables
  >;

/**
 * __useUpdateCustomerAttachmentMutation__
 *
 * To run a mutation, you first call `useUpdateCustomerAttachmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCustomerAttachmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCustomerAttachmentMutation, { data, loading, error }] = useUpdateCustomerAttachmentMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateCustomerAttachmentMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    UpdateCustomerAttachmentMutation,
    UpdateCustomerAttachmentMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    UpdateCustomerAttachmentMutation,
    UpdateCustomerAttachmentMutationVariables
  >(UpdateCustomerAttachmentDocument, options);
}
export type UpdateCustomerAttachmentMutationHookResult = ReturnType<
  typeof useUpdateCustomerAttachmentMutation
>;
export type UpdateCustomerAttachmentMutationResult =
  ApolloReactCommon.MutationResult<UpdateCustomerAttachmentMutation>;
export type UpdateCustomerAttachmentMutationOptions =
  ApolloReactCommon.BaseMutationOptions<
    UpdateCustomerAttachmentMutation,
    UpdateCustomerAttachmentMutationVariables
  >;
export const CreateCustomerCertificateAttachmentDocument = gql`
  mutation createCustomerCertificateAttachment(
    $input: CustomerCertificateAttachmentCreateInput!
  ) {
    createCustomerCertificateAttachment(input: $input) {
      id
      url
      name
      s3Key
    }
  }
`;
export type CreateCustomerCertificateAttachmentMutationFn =
  ApolloReactCommon.MutationFunction<
    CreateCustomerCertificateAttachmentMutation,
    CreateCustomerCertificateAttachmentMutationVariables
  >;

/**
 * __useCreateCustomerCertificateAttachmentMutation__
 *
 * To run a mutation, you first call `useCreateCustomerCertificateAttachmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCustomerCertificateAttachmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCustomerCertificateAttachmentMutation, { data, loading, error }] = useCreateCustomerCertificateAttachmentMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateCustomerCertificateAttachmentMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    CreateCustomerCertificateAttachmentMutation,
    CreateCustomerCertificateAttachmentMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    CreateCustomerCertificateAttachmentMutation,
    CreateCustomerCertificateAttachmentMutationVariables
  >(CreateCustomerCertificateAttachmentDocument, options);
}
export type CreateCustomerCertificateAttachmentMutationHookResult = ReturnType<
  typeof useCreateCustomerCertificateAttachmentMutation
>;
export type CreateCustomerCertificateAttachmentMutationResult =
  ApolloReactCommon.MutationResult<CreateCustomerCertificateAttachmentMutation>;
export type CreateCustomerCertificateAttachmentMutationOptions =
  ApolloReactCommon.BaseMutationOptions<
    CreateCustomerCertificateAttachmentMutation,
    CreateCustomerCertificateAttachmentMutationVariables
  >;
export const UpdateCustomerCertificateAttachmentDocument = gql`
  mutation updateCustomerCertificateAttachment(
    $input: CustomerCertificateAttachmentUpdateInput!
  ) {
    updateCustomerCertificateAttachment(input: $input) {
      id
      url
      name
      s3Key
    }
  }
`;
export type UpdateCustomerCertificateAttachmentMutationFn =
  ApolloReactCommon.MutationFunction<
    UpdateCustomerCertificateAttachmentMutation,
    UpdateCustomerCertificateAttachmentMutationVariables
  >;

/**
 * __useUpdateCustomerCertificateAttachmentMutation__
 *
 * To run a mutation, you first call `useUpdateCustomerCertificateAttachmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCustomerCertificateAttachmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCustomerCertificateAttachmentMutation, { data, loading, error }] = useUpdateCustomerCertificateAttachmentMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateCustomerCertificateAttachmentMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    UpdateCustomerCertificateAttachmentMutation,
    UpdateCustomerCertificateAttachmentMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    UpdateCustomerCertificateAttachmentMutation,
    UpdateCustomerCertificateAttachmentMutationVariables
  >(UpdateCustomerCertificateAttachmentDocument, options);
}
export type UpdateCustomerCertificateAttachmentMutationHookResult = ReturnType<
  typeof useUpdateCustomerCertificateAttachmentMutation
>;
export type UpdateCustomerCertificateAttachmentMutationResult =
  ApolloReactCommon.MutationResult<UpdateCustomerCertificateAttachmentMutation>;
export type UpdateCustomerCertificateAttachmentMutationOptions =
  ApolloReactCommon.BaseMutationOptions<
    UpdateCustomerCertificateAttachmentMutation,
    UpdateCustomerCertificateAttachmentMutationVariables
  >;
export const DeleteCustomerCertificateAttachmentDocument = gql`
  mutation deleteCustomerCertificateAttachment($id: ID!) {
    deleteCustomerCertificateAttachment(id: $id)
  }
`;
export type DeleteCustomerCertificateAttachmentMutationFn =
  ApolloReactCommon.MutationFunction<
    DeleteCustomerCertificateAttachmentMutation,
    DeleteCustomerCertificateAttachmentMutationVariables
  >;

/**
 * __useDeleteCustomerCertificateAttachmentMutation__
 *
 * To run a mutation, you first call `useDeleteCustomerCertificateAttachmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteCustomerCertificateAttachmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteCustomerCertificateAttachmentMutation, { data, loading, error }] = useDeleteCustomerCertificateAttachmentMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteCustomerCertificateAttachmentMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    DeleteCustomerCertificateAttachmentMutation,
    DeleteCustomerCertificateAttachmentMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    DeleteCustomerCertificateAttachmentMutation,
    DeleteCustomerCertificateAttachmentMutationVariables
  >(DeleteCustomerCertificateAttachmentDocument, options);
}
export type DeleteCustomerCertificateAttachmentMutationHookResult = ReturnType<
  typeof useDeleteCustomerCertificateAttachmentMutation
>;
export type DeleteCustomerCertificateAttachmentMutationResult =
  ApolloReactCommon.MutationResult<DeleteCustomerCertificateAttachmentMutation>;
export type DeleteCustomerCertificateAttachmentMutationOptions =
  ApolloReactCommon.BaseMutationOptions<
    DeleteCustomerCertificateAttachmentMutation,
    DeleteCustomerCertificateAttachmentMutationVariables
  >;
export const DeleteCustomerAttachmentDocument = gql`
  mutation deleteCustomerAttachment($id: ID!) {
    deleteCustomerAttachment(id: $id)
  }
`;
export type DeleteCustomerAttachmentMutationFn =
  ApolloReactCommon.MutationFunction<
    DeleteCustomerAttachmentMutation,
    DeleteCustomerAttachmentMutationVariables
  >;

/**
 * __useDeleteCustomerAttachmentMutation__
 *
 * To run a mutation, you first call `useDeleteCustomerAttachmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteCustomerAttachmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteCustomerAttachmentMutation, { data, loading, error }] = useDeleteCustomerAttachmentMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteCustomerAttachmentMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    DeleteCustomerAttachmentMutation,
    DeleteCustomerAttachmentMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    DeleteCustomerAttachmentMutation,
    DeleteCustomerAttachmentMutationVariables
  >(DeleteCustomerAttachmentDocument, options);
}
export type DeleteCustomerAttachmentMutationHookResult = ReturnType<
  typeof useDeleteCustomerAttachmentMutation
>;
export type DeleteCustomerAttachmentMutationResult =
  ApolloReactCommon.MutationResult<DeleteCustomerAttachmentMutation>;
export type DeleteCustomerAttachmentMutationOptions =
  ApolloReactCommon.BaseMutationOptions<
    DeleteCustomerAttachmentMutation,
    DeleteCustomerAttachmentMutationVariables
  >;
export const BulkCreateCustomerAttachmentDocument = gql`
  mutation bulkCreateCustomerAttachment(
    $input: CustomerAttachmentBulkCreateInput!
  ) {
    bulkCreateCustomerAttachment(input: $input) {
      id
      url
      name
      s3Key
    }
  }
`;
export type BulkCreateCustomerAttachmentMutationFn =
  ApolloReactCommon.MutationFunction<
    BulkCreateCustomerAttachmentMutation,
    BulkCreateCustomerAttachmentMutationVariables
  >;

/**
 * __useBulkCreateCustomerAttachmentMutation__
 *
 * To run a mutation, you first call `useBulkCreateCustomerAttachmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBulkCreateCustomerAttachmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [bulkCreateCustomerAttachmentMutation, { data, loading, error }] = useBulkCreateCustomerAttachmentMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useBulkCreateCustomerAttachmentMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    BulkCreateCustomerAttachmentMutation,
    BulkCreateCustomerAttachmentMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    BulkCreateCustomerAttachmentMutation,
    BulkCreateCustomerAttachmentMutationVariables
  >(BulkCreateCustomerAttachmentDocument, options);
}
export type BulkCreateCustomerAttachmentMutationHookResult = ReturnType<
  typeof useBulkCreateCustomerAttachmentMutation
>;
export type BulkCreateCustomerAttachmentMutationResult =
  ApolloReactCommon.MutationResult<BulkCreateCustomerAttachmentMutation>;
export type BulkCreateCustomerAttachmentMutationOptions =
  ApolloReactCommon.BaseMutationOptions<
    BulkCreateCustomerAttachmentMutation,
    BulkCreateCustomerAttachmentMutationVariables
  >;
export const CustomerCertificatesDocument = gql`
  query customerCertificates(
    $limit: Int
    $offset: Int
    $filters: CustomerCertificateSearchInput!
  ) {
    customerCertificates(limit: $limit, offset: $offset, filters: $filters) {
      count
      rows {
        id
        code
        scope
        customer {
          id
          name
        }
        type {
          id
          name
        }
        effectiveDate
        expiryDate
        attachments {
          id
          name
          s3Key
          url
          memo
        }
      }
    }
  }
`;

/**
 * __useCustomerCertificatesQuery__
 *
 * To run a query within a React component, call `useCustomerCertificatesQuery` and pass it any options that fit your needs.
 * When your component renders, `useCustomerCertificatesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCustomerCertificatesQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function useCustomerCertificatesQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    CustomerCertificatesQuery,
    CustomerCertificatesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    CustomerCertificatesQuery,
    CustomerCertificatesQueryVariables
  >(CustomerCertificatesDocument, options);
}
export function useCustomerCertificatesLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    CustomerCertificatesQuery,
    CustomerCertificatesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    CustomerCertificatesQuery,
    CustomerCertificatesQueryVariables
  >(CustomerCertificatesDocument, options);
}
export type CustomerCertificatesQueryHookResult = ReturnType<
  typeof useCustomerCertificatesQuery
>;
export type CustomerCertificatesLazyQueryHookResult = ReturnType<
  typeof useCustomerCertificatesLazyQuery
>;
export type CustomerCertificatesQueryResult = ApolloReactCommon.QueryResult<
  CustomerCertificatesQuery,
  CustomerCertificatesQueryVariables
>;
export const CustomerCertificateTypesDocument = gql`
  query customerCertificateTypes(
    $limit: Int
    $offset: Int
    $filters: CustomerCertificateTypeSearchInput!
  ) {
    customerCertificateTypes(
      limit: $limit
      offset: $offset
      filters: $filters
    ) {
      count
      rows {
        id
        name
      }
    }
  }
`;

/**
 * __useCustomerCertificateTypesQuery__
 *
 * To run a query within a React component, call `useCustomerCertificateTypesQuery` and pass it any options that fit your needs.
 * When your component renders, `useCustomerCertificateTypesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCustomerCertificateTypesQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function useCustomerCertificateTypesQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    CustomerCertificateTypesQuery,
    CustomerCertificateTypesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    CustomerCertificateTypesQuery,
    CustomerCertificateTypesQueryVariables
  >(CustomerCertificateTypesDocument, options);
}
export function useCustomerCertificateTypesLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    CustomerCertificateTypesQuery,
    CustomerCertificateTypesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    CustomerCertificateTypesQuery,
    CustomerCertificateTypesQueryVariables
  >(CustomerCertificateTypesDocument, options);
}
export type CustomerCertificateTypesQueryHookResult = ReturnType<
  typeof useCustomerCertificateTypesQuery
>;
export type CustomerCertificateTypesLazyQueryHookResult = ReturnType<
  typeof useCustomerCertificateTypesLazyQuery
>;
export type CustomerCertificateTypesQueryResult = ApolloReactCommon.QueryResult<
  CustomerCertificateTypesQuery,
  CustomerCertificateTypesQueryVariables
>;
export const CustomerAttachmentsDocument = gql`
  query customerAttachments(
    $limit: Int
    $offset: Int
    $filters: CustomerAttachmentSearchInput!
  ) {
    customerAttachments(limit: $limit, offset: $offset, filters: $filters) {
      count
      rows {
        id
        url
        s3Key
        customerId
        name
      }
    }
  }
`;

/**
 * __useCustomerAttachmentsQuery__
 *
 * To run a query within a React component, call `useCustomerAttachmentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCustomerAttachmentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCustomerAttachmentsQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function useCustomerAttachmentsQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    CustomerAttachmentsQuery,
    CustomerAttachmentsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    CustomerAttachmentsQuery,
    CustomerAttachmentsQueryVariables
  >(CustomerAttachmentsDocument, options);
}
export function useCustomerAttachmentsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    CustomerAttachmentsQuery,
    CustomerAttachmentsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    CustomerAttachmentsQuery,
    CustomerAttachmentsQueryVariables
  >(CustomerAttachmentsDocument, options);
}
export type CustomerAttachmentsQueryHookResult = ReturnType<
  typeof useCustomerAttachmentsQuery
>;
export type CustomerAttachmentsLazyQueryHookResult = ReturnType<
  typeof useCustomerAttachmentsLazyQuery
>;
export type CustomerAttachmentsQueryResult = ApolloReactCommon.QueryResult<
  CustomerAttachmentsQuery,
  CustomerAttachmentsQueryVariables
>;
export const CreateCustomerDocument = gql`
  mutation createCustomer($input: CustomerCreateInput!) {
    createCustomer(input: $input) {
      id
      code
      name
      createdAt
      updatedAt
    }
  }
`;
export type CreateCustomerMutationFn = ApolloReactCommon.MutationFunction<
  CreateCustomerMutation,
  CreateCustomerMutationVariables
>;

/**
 * __useCreateCustomerMutation__
 *
 * To run a mutation, you first call `useCreateCustomerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCustomerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCustomerMutation, { data, loading, error }] = useCreateCustomerMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateCustomerMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    CreateCustomerMutation,
    CreateCustomerMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    CreateCustomerMutation,
    CreateCustomerMutationVariables
  >(CreateCustomerDocument, options);
}
export type CreateCustomerMutationHookResult = ReturnType<
  typeof useCreateCustomerMutation
>;
export type CreateCustomerMutationResult =
  ApolloReactCommon.MutationResult<CreateCustomerMutation>;
export type CreateCustomerMutationOptions =
  ApolloReactCommon.BaseMutationOptions<
    CreateCustomerMutation,
    CreateCustomerMutationVariables
  >;
export const UpdateCustomerDocument = gql`
  mutation updateCustomer($input: CustomerUpdateInput!) {
    updateCustomer(input: $input) {
      id
      code
      name
      createdAt
      updatedAt
    }
  }
`;
export type UpdateCustomerMutationFn = ApolloReactCommon.MutationFunction<
  UpdateCustomerMutation,
  UpdateCustomerMutationVariables
>;

/**
 * __useUpdateCustomerMutation__
 *
 * To run a mutation, you first call `useUpdateCustomerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCustomerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCustomerMutation, { data, loading, error }] = useUpdateCustomerMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateCustomerMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    UpdateCustomerMutation,
    UpdateCustomerMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    UpdateCustomerMutation,
    UpdateCustomerMutationVariables
  >(UpdateCustomerDocument, options);
}
export type UpdateCustomerMutationHookResult = ReturnType<
  typeof useUpdateCustomerMutation
>;
export type UpdateCustomerMutationResult =
  ApolloReactCommon.MutationResult<UpdateCustomerMutation>;
export type UpdateCustomerMutationOptions =
  ApolloReactCommon.BaseMutationOptions<
    UpdateCustomerMutation,
    UpdateCustomerMutationVariables
  >;
export const CreateContactPersonDocument = gql`
  mutation createContactPerson($input: ContactPersonCreateInput!) {
    createContactPerson(input: $input) {
      id
      code
      name
      createdAt
      updatedAt
    }
  }
`;
export type CreateContactPersonMutationFn = ApolloReactCommon.MutationFunction<
  CreateContactPersonMutation,
  CreateContactPersonMutationVariables
>;

/**
 * __useCreateContactPersonMutation__
 *
 * To run a mutation, you first call `useCreateContactPersonMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateContactPersonMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createContactPersonMutation, { data, loading, error }] = useCreateContactPersonMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateContactPersonMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    CreateContactPersonMutation,
    CreateContactPersonMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    CreateContactPersonMutation,
    CreateContactPersonMutationVariables
  >(CreateContactPersonDocument, options);
}
export type CreateContactPersonMutationHookResult = ReturnType<
  typeof useCreateContactPersonMutation
>;
export type CreateContactPersonMutationResult =
  ApolloReactCommon.MutationResult<CreateContactPersonMutation>;
export type CreateContactPersonMutationOptions =
  ApolloReactCommon.BaseMutationOptions<
    CreateContactPersonMutation,
    CreateContactPersonMutationVariables
  >;
export const UpdateContactPersonDocument = gql`
  mutation updateContactPerson($input: ContactPersonUpdateInput!) {
    updateContactPerson(input: $input) {
      id
      code
      name
      createdAt
      updatedAt
    }
  }
`;
export type UpdateContactPersonMutationFn = ApolloReactCommon.MutationFunction<
  UpdateContactPersonMutation,
  UpdateContactPersonMutationVariables
>;

/**
 * __useUpdateContactPersonMutation__
 *
 * To run a mutation, you first call `useUpdateContactPersonMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateContactPersonMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateContactPersonMutation, { data, loading, error }] = useUpdateContactPersonMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateContactPersonMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    UpdateContactPersonMutation,
    UpdateContactPersonMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    UpdateContactPersonMutation,
    UpdateContactPersonMutationVariables
  >(UpdateContactPersonDocument, options);
}
export type UpdateContactPersonMutationHookResult = ReturnType<
  typeof useUpdateContactPersonMutation
>;
export type UpdateContactPersonMutationResult =
  ApolloReactCommon.MutationResult<UpdateContactPersonMutation>;
export type UpdateContactPersonMutationOptions =
  ApolloReactCommon.BaseMutationOptions<
    UpdateContactPersonMutation,
    UpdateContactPersonMutationVariables
  >;
export const CreateCustomerToContactPeopleDocument = gql`
  mutation createCustomerToContactPeople(
    $input: CreateCustomerToContactPeopleInput!
  ) {
    createCustomerToContactPeople(input: $input) {
      id
    }
  }
`;
export type CreateCustomerToContactPeopleMutationFn =
  ApolloReactCommon.MutationFunction<
    CreateCustomerToContactPeopleMutation,
    CreateCustomerToContactPeopleMutationVariables
  >;

/**
 * __useCreateCustomerToContactPeopleMutation__
 *
 * To run a mutation, you first call `useCreateCustomerToContactPeopleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCustomerToContactPeopleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCustomerToContactPeopleMutation, { data, loading, error }] = useCreateCustomerToContactPeopleMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateCustomerToContactPeopleMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    CreateCustomerToContactPeopleMutation,
    CreateCustomerToContactPeopleMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    CreateCustomerToContactPeopleMutation,
    CreateCustomerToContactPeopleMutationVariables
  >(CreateCustomerToContactPeopleDocument, options);
}
export type CreateCustomerToContactPeopleMutationHookResult = ReturnType<
  typeof useCreateCustomerToContactPeopleMutation
>;
export type CreateCustomerToContactPeopleMutationResult =
  ApolloReactCommon.MutationResult<CreateCustomerToContactPeopleMutation>;
export type CreateCustomerToContactPeopleMutationOptions =
  ApolloReactCommon.BaseMutationOptions<
    CreateCustomerToContactPeopleMutation,
    CreateCustomerToContactPeopleMutationVariables
  >;
export const DeleteCustomerToContactPeopleDocument = gql`
  mutation deleteCustomerToContactPeople(
    $input: DeleteCustomerToContactPeopleInput!
  ) {
    deleteCustomerToContactPeople(input: $input) {
      id
    }
  }
`;
export type DeleteCustomerToContactPeopleMutationFn =
  ApolloReactCommon.MutationFunction<
    DeleteCustomerToContactPeopleMutation,
    DeleteCustomerToContactPeopleMutationVariables
  >;

/**
 * __useDeleteCustomerToContactPeopleMutation__
 *
 * To run a mutation, you first call `useDeleteCustomerToContactPeopleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteCustomerToContactPeopleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteCustomerToContactPeopleMutation, { data, loading, error }] = useDeleteCustomerToContactPeopleMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteCustomerToContactPeopleMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    DeleteCustomerToContactPeopleMutation,
    DeleteCustomerToContactPeopleMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    DeleteCustomerToContactPeopleMutation,
    DeleteCustomerToContactPeopleMutationVariables
  >(DeleteCustomerToContactPeopleDocument, options);
}
export type DeleteCustomerToContactPeopleMutationHookResult = ReturnType<
  typeof useDeleteCustomerToContactPeopleMutation
>;
export type DeleteCustomerToContactPeopleMutationResult =
  ApolloReactCommon.MutationResult<DeleteCustomerToContactPeopleMutation>;
export type DeleteCustomerToContactPeopleMutationOptions =
  ApolloReactCommon.BaseMutationOptions<
    DeleteCustomerToContactPeopleMutation,
    DeleteCustomerToContactPeopleMutationVariables
  >;
export const DeleteCustomerCertificateDocument = gql`
  mutation deleteCustomerCertificate($id: ID!) {
    deleteCustomerCertificate(id: $id)
  }
`;
export type DeleteCustomerCertificateMutationFn =
  ApolloReactCommon.MutationFunction<
    DeleteCustomerCertificateMutation,
    DeleteCustomerCertificateMutationVariables
  >;

/**
 * __useDeleteCustomerCertificateMutation__
 *
 * To run a mutation, you first call `useDeleteCustomerCertificateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteCustomerCertificateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteCustomerCertificateMutation, { data, loading, error }] = useDeleteCustomerCertificateMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteCustomerCertificateMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    DeleteCustomerCertificateMutation,
    DeleteCustomerCertificateMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    DeleteCustomerCertificateMutation,
    DeleteCustomerCertificateMutationVariables
  >(DeleteCustomerCertificateDocument, options);
}
export type DeleteCustomerCertificateMutationHookResult = ReturnType<
  typeof useDeleteCustomerCertificateMutation
>;
export type DeleteCustomerCertificateMutationResult =
  ApolloReactCommon.MutationResult<DeleteCustomerCertificateMutation>;
export type DeleteCustomerCertificateMutationOptions =
  ApolloReactCommon.BaseMutationOptions<
    DeleteCustomerCertificateMutation,
    DeleteCustomerCertificateMutationVariables
  >;
export const BatchUpsertCustomersPrimaryUserDocument = gql`
  mutation BatchUpsertCustomersPrimaryUser(
    $input: CustomersPrimaryUserBatchUpsertInput!
  ) {
    BatchUpsertCustomersPrimaryUser(input: $input) {
      id
    }
  }
`;
export type BatchUpsertCustomersPrimaryUserMutationFn =
  ApolloReactCommon.MutationFunction<
    BatchUpsertCustomersPrimaryUserMutation,
    BatchUpsertCustomersPrimaryUserMutationVariables
  >;

/**
 * __useBatchUpsertCustomersPrimaryUserMutation__
 *
 * To run a mutation, you first call `useBatchUpsertCustomersPrimaryUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBatchUpsertCustomersPrimaryUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [batchUpsertCustomersPrimaryUserMutation, { data, loading, error }] = useBatchUpsertCustomersPrimaryUserMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useBatchUpsertCustomersPrimaryUserMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    BatchUpsertCustomersPrimaryUserMutation,
    BatchUpsertCustomersPrimaryUserMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    BatchUpsertCustomersPrimaryUserMutation,
    BatchUpsertCustomersPrimaryUserMutationVariables
  >(BatchUpsertCustomersPrimaryUserDocument, options);
}
export type BatchUpsertCustomersPrimaryUserMutationHookResult = ReturnType<
  typeof useBatchUpsertCustomersPrimaryUserMutation
>;
export type BatchUpsertCustomersPrimaryUserMutationResult =
  ApolloReactCommon.MutationResult<BatchUpsertCustomersPrimaryUserMutation>;
export type BatchUpsertCustomersPrimaryUserMutationOptions =
  ApolloReactCommon.BaseMutationOptions<
    BatchUpsertCustomersPrimaryUserMutation,
    BatchUpsertCustomersPrimaryUserMutationVariables
  >;
export const BatchUpsertCustomersSalesTeamUnitDocument = gql`
  mutation BatchUpsertCustomersSalesTeamUnit(
    $input: CustomersSalesTeamUnitBatchUpsertInput!
  ) {
    BatchUpsertCustomersSalesTeamUnit(input: $input) {
      customerId
    }
  }
`;
export type BatchUpsertCustomersSalesTeamUnitMutationFn =
  ApolloReactCommon.MutationFunction<
    BatchUpsertCustomersSalesTeamUnitMutation,
    BatchUpsertCustomersSalesTeamUnitMutationVariables
  >;

/**
 * __useBatchUpsertCustomersSalesTeamUnitMutation__
 *
 * To run a mutation, you first call `useBatchUpsertCustomersSalesTeamUnitMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBatchUpsertCustomersSalesTeamUnitMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [batchUpsertCustomersSalesTeamUnitMutation, { data, loading, error }] = useBatchUpsertCustomersSalesTeamUnitMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useBatchUpsertCustomersSalesTeamUnitMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    BatchUpsertCustomersSalesTeamUnitMutation,
    BatchUpsertCustomersSalesTeamUnitMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    BatchUpsertCustomersSalesTeamUnitMutation,
    BatchUpsertCustomersSalesTeamUnitMutationVariables
  >(BatchUpsertCustomersSalesTeamUnitDocument, options);
}
export type BatchUpsertCustomersSalesTeamUnitMutationHookResult = ReturnType<
  typeof useBatchUpsertCustomersSalesTeamUnitMutation
>;
export type BatchUpsertCustomersSalesTeamUnitMutationResult =
  ApolloReactCommon.MutationResult<BatchUpsertCustomersSalesTeamUnitMutation>;
export type BatchUpsertCustomersSalesTeamUnitMutationOptions =
  ApolloReactCommon.BaseMutationOptions<
    BatchUpsertCustomersSalesTeamUnitMutation,
    BatchUpsertCustomersSalesTeamUnitMutationVariables
  >;
export const CustomersDocument = gql`
  query customers($limit: Int, $offset: Int, $filters: CustomerSearchInput!) {
    customers(limit: $limit, offset: $offset, filters: $filters) {
      count
      rows {
        id
        name
        code
        address
        area {
          id
          name
        }
        primaryUsers {
          id
          userId
          salesTeamId
          salesTeam {
            id
            name
          }
          User {
            id
            name
          }
        }
        salesTeamGroupId
        salesTeamGroup {
          regionId
          id
          code
          name
          viewOrder
          createdAt
          updatedAt
          salesTeams {
            id
            name
            users {
              id
              name
              code
            }
          }
        }
        businessCode
        medicalCode
        yonyouCode
        do1Code
        navCode
        referenceCode
        phone
        mobile
        email
        fax
        address
        memo
        isGmp
        lastGmpBpmInstanceId
        lastGmpBpmStatus
        lastGmpBpmUrl
        createdAt
        updatedAt
        lastGmpBpmUser {
          id
          name
        }
        hasEffectiveGmpCertificate
        typeId
        type {
          id
          name
          code
        }
        contactPersonPhone
        creditQuota
        creditPeriodId
        shippingAddress
        contactPersonName
        bankAccountCode
        bankAccountName
        legalPersonName
        billingUnitName
        districtId
        isActive
        isGmp
        levels {
          id
          name
          type {
            code
          }
        }
        region {
          id
          code
          name
        }
        province {
          id
          name
        }
        cityId
        createdUser {
          id
          name
          createdAt
          updatedAt
        }
        updatedUser {
          id
          name
          createdAt
          updatedAt
        }
        contactPeople {
          id
          name
          phone
          mobile
          fax
          gender
          doctorCode
          jobTitle
          dept
        }
        categoryId
        attachments {
          id
          name
          s3Key
        }
        salesTeamUnits {
          id
          salesTeamId
          salesTeam {
            id
            name
          }
          user {
            id
            name
          }
        }
      }
    }
  }
`;

/**
 * __useCustomersQuery__
 *
 * To run a query within a React component, call `useCustomersQuery` and pass it any options that fit your needs.
 * When your component renders, `useCustomersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCustomersQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function useCustomersQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    CustomersQuery,
    CustomersQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<CustomersQuery, CustomersQueryVariables>(
    CustomersDocument,
    options,
  );
}
export function useCustomersLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    CustomersQuery,
    CustomersQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<CustomersQuery, CustomersQueryVariables>(
    CustomersDocument,
    options,
  );
}
export type CustomersQueryHookResult = ReturnType<typeof useCustomersQuery>;
export type CustomersLazyQueryHookResult = ReturnType<
  typeof useCustomersLazyQuery
>;
export type CustomersQueryResult = ApolloReactCommon.QueryResult<
  CustomersQuery,
  CustomersQueryVariables
>;
export const CustomerTypesDocument = gql`
  query CustomerTypes($filters: CustomerTypeSearchInput!) {
    customerTypes(filters: $filters) {
      rows {
        id
        name
        code
        salesTeamGroupId
        salesTeamGroup {
          id
          name
        }
      }
    }
  }
`;

/**
 * __useCustomerTypesQuery__
 *
 * To run a query within a React component, call `useCustomerTypesQuery` and pass it any options that fit your needs.
 * When your component renders, `useCustomerTypesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCustomerTypesQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function useCustomerTypesQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    CustomerTypesQuery,
    CustomerTypesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    CustomerTypesQuery,
    CustomerTypesQueryVariables
  >(CustomerTypesDocument, options);
}
export function useCustomerTypesLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    CustomerTypesQuery,
    CustomerTypesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    CustomerTypesQuery,
    CustomerTypesQueryVariables
  >(CustomerTypesDocument, options);
}
export type CustomerTypesQueryHookResult = ReturnType<
  typeof useCustomerTypesQuery
>;
export type CustomerTypesLazyQueryHookResult = ReturnType<
  typeof useCustomerTypesLazyQuery
>;
export type CustomerTypesQueryResult = ApolloReactCommon.QueryResult<
  CustomerTypesQuery,
  CustomerTypesQueryVariables
>;
export const ContactPersonsDocument = gql`
  query contactPersons(
    $limit: Int
    $offset: Int
    $filters: ContactPersonSearchInput!
  ) {
    contactPeople(limit: $limit, offset: $offset, filters: $filters) {
      count
      rows {
        id
        code
        name
        nickname
        jobTitle
        salesTeamGroupId
        gender
        doctorCode
        phone
        mobile
        email
        fax
        address
        departmentId
        department {
          id
          name
        }
        hobby
        skill
        referenceCode
        memo
        primaryUsers {
          id
          code
          name
          dept {
            id
            code
            name
          }
        }
        createdUser {
          id
          code
          name
          dept {
            id
            code
            name
          }
        }
        updatedUser {
          id
          code
          name
          dept {
            id
            code
            name
          }
        }
        customers {
          name
          id
          code
        }
        region {
          id
          name
        }
        salesTeamGroup {
          id
          name
        }
        createdAt
        updatedAt
        citizenCode
        skill
        salesTeamUnits {
          id
        }
      }
    }
  }
`;

/**
 * __useContactPersonsQuery__
 *
 * To run a query within a React component, call `useContactPersonsQuery` and pass it any options that fit your needs.
 * When your component renders, `useContactPersonsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useContactPersonsQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function useContactPersonsQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    ContactPersonsQuery,
    ContactPersonsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    ContactPersonsQuery,
    ContactPersonsQueryVariables
  >(ContactPersonsDocument, options);
}
export function useContactPersonsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    ContactPersonsQuery,
    ContactPersonsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    ContactPersonsQuery,
    ContactPersonsQueryVariables
  >(ContactPersonsDocument, options);
}
export type ContactPersonsQueryHookResult = ReturnType<
  typeof useContactPersonsQuery
>;
export type ContactPersonsLazyQueryHookResult = ReturnType<
  typeof useContactPersonsLazyQuery
>;
export type ContactPersonsQueryResult = ApolloReactCommon.QueryResult<
  ContactPersonsQuery,
  ContactPersonsQueryVariables
>;
export const ContactPeopleSelectDocument = gql`
  query contactPeopleSelect($filters: ContactPersonSearchInput!) {
    contactPeople(filters: $filters) {
      rows {
        id
        name
      }
    }
  }
`;

/**
 * __useContactPeopleSelectQuery__
 *
 * To run a query within a React component, call `useContactPeopleSelectQuery` and pass it any options that fit your needs.
 * When your component renders, `useContactPeopleSelectQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useContactPeopleSelectQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function useContactPeopleSelectQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    ContactPeopleSelectQuery,
    ContactPeopleSelectQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    ContactPeopleSelectQuery,
    ContactPeopleSelectQueryVariables
  >(ContactPeopleSelectDocument, options);
}
export function useContactPeopleSelectLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    ContactPeopleSelectQuery,
    ContactPeopleSelectQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    ContactPeopleSelectQuery,
    ContactPeopleSelectQueryVariables
  >(ContactPeopleSelectDocument, options);
}
export type ContactPeopleSelectQueryHookResult = ReturnType<
  typeof useContactPeopleSelectQuery
>;
export type ContactPeopleSelectLazyQueryHookResult = ReturnType<
  typeof useContactPeopleSelectLazyQuery
>;
export type ContactPeopleSelectQueryResult = ApolloReactCommon.QueryResult<
  ContactPeopleSelectQuery,
  ContactPeopleSelectQueryVariables
>;
export const ContactPersonDepartmentsDocument = gql`
  query contactPersonDepartments(
    $filters: ContactPersonDepartmentSearchInput!
  ) {
    contactPersonDepartments(filters: $filters) {
      rows {
        id
        name
        viewOrder
      }
    }
  }
`;

/**
 * __useContactPersonDepartmentsQuery__
 *
 * To run a query within a React component, call `useContactPersonDepartmentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useContactPersonDepartmentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useContactPersonDepartmentsQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function useContactPersonDepartmentsQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    ContactPersonDepartmentsQuery,
    ContactPersonDepartmentsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    ContactPersonDepartmentsQuery,
    ContactPersonDepartmentsQueryVariables
  >(ContactPersonDepartmentsDocument, options);
}
export function useContactPersonDepartmentsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    ContactPersonDepartmentsQuery,
    ContactPersonDepartmentsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    ContactPersonDepartmentsQuery,
    ContactPersonDepartmentsQueryVariables
  >(ContactPersonDepartmentsDocument, options);
}
export type ContactPersonDepartmentsQueryHookResult = ReturnType<
  typeof useContactPersonDepartmentsQuery
>;
export type ContactPersonDepartmentsLazyQueryHookResult = ReturnType<
  typeof useContactPersonDepartmentsLazyQuery
>;
export type ContactPersonDepartmentsQueryResult = ApolloReactCommon.QueryResult<
  ContactPersonDepartmentsQuery,
  ContactPersonDepartmentsQueryVariables
>;
export const SalesTeamGroupsDocument = gql`
  query salesTeamGroups($filters: SalesTeamGroupSearchInput!) {
    salesTeamGroups(filters: $filters) {
      count
      rows {
        regionId
        id
        code
        name
        viewOrder
        createdAt
        updatedAt
        salesTeams {
          id
          name
          users {
            id
            name
            code
          }
        }
      }
    }
  }
`;

/**
 * __useSalesTeamGroupsQuery__
 *
 * To run a query within a React component, call `useSalesTeamGroupsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSalesTeamGroupsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSalesTeamGroupsQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function useSalesTeamGroupsQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    SalesTeamGroupsQuery,
    SalesTeamGroupsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    SalesTeamGroupsQuery,
    SalesTeamGroupsQueryVariables
  >(SalesTeamGroupsDocument, options);
}
export function useSalesTeamGroupsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    SalesTeamGroupsQuery,
    SalesTeamGroupsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    SalesTeamGroupsQuery,
    SalesTeamGroupsQueryVariables
  >(SalesTeamGroupsDocument, options);
}
export type SalesTeamGroupsQueryHookResult = ReturnType<
  typeof useSalesTeamGroupsQuery
>;
export type SalesTeamGroupsLazyQueryHookResult = ReturnType<
  typeof useSalesTeamGroupsLazyQuery
>;
export type SalesTeamGroupsQueryResult = ApolloReactCommon.QueryResult<
  SalesTeamGroupsQuery,
  SalesTeamGroupsQueryVariables
>;
export const CreditPeriodsDocument = gql`
  query CreditPeriods($filters: CreditPeriodSearchInput!) {
    creditPeriods(filters: $filters) {
      rows {
        id
        name
      }
    }
  }
`;

/**
 * __useCreditPeriodsQuery__
 *
 * To run a query within a React component, call `useCreditPeriodsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCreditPeriodsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCreditPeriodsQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function useCreditPeriodsQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    CreditPeriodsQuery,
    CreditPeriodsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    CreditPeriodsQuery,
    CreditPeriodsQueryVariables
  >(CreditPeriodsDocument, options);
}
export function useCreditPeriodsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    CreditPeriodsQuery,
    CreditPeriodsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    CreditPeriodsQuery,
    CreditPeriodsQueryVariables
  >(CreditPeriodsDocument, options);
}
export type CreditPeriodsQueryHookResult = ReturnType<
  typeof useCreditPeriodsQuery
>;
export type CreditPeriodsLazyQueryHookResult = ReturnType<
  typeof useCreditPeriodsLazyQuery
>;
export type CreditPeriodsQueryResult = ApolloReactCommon.QueryResult<
  CreditPeriodsQuery,
  CreditPeriodsQueryVariables
>;
export const CustomerCategoriesDocument = gql`
  query customerCategories($filters: CustomerCategorySearchInput!) {
    customerCategories(filters: $filters) {
      rows {
        id
        name
        salesTeamGroupId
        viewOrder
      }
    }
  }
`;

/**
 * __useCustomerCategoriesQuery__
 *
 * To run a query within a React component, call `useCustomerCategoriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useCustomerCategoriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCustomerCategoriesQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function useCustomerCategoriesQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    CustomerCategoriesQuery,
    CustomerCategoriesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    CustomerCategoriesQuery,
    CustomerCategoriesQueryVariables
  >(CustomerCategoriesDocument, options);
}
export function useCustomerCategoriesLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    CustomerCategoriesQuery,
    CustomerCategoriesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    CustomerCategoriesQuery,
    CustomerCategoriesQueryVariables
  >(CustomerCategoriesDocument, options);
}
export type CustomerCategoriesQueryHookResult = ReturnType<
  typeof useCustomerCategoriesQuery
>;
export type CustomerCategoriesLazyQueryHookResult = ReturnType<
  typeof useCustomerCategoriesLazyQuery
>;
export type CustomerCategoriesQueryResult = ApolloReactCommon.QueryResult<
  CustomerCategoriesQuery,
  CustomerCategoriesQueryVariables
>;
export const CustomerIdsDocument = gql`
  query CustomerIds($limit: Int, $offset: Int, $filters: CustomerSearchInput!) {
    customers(limit: $limit, offset: $offset, filters: $filters) {
      count
      rows {
        id
      }
    }
  }
`;

/**
 * __useCustomerIdsQuery__
 *
 * To run a query within a React component, call `useCustomerIdsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCustomerIdsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCustomerIdsQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function useCustomerIdsQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    CustomerIdsQuery,
    CustomerIdsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<CustomerIdsQuery, CustomerIdsQueryVariables>(
    CustomerIdsDocument,
    options,
  );
}
export function useCustomerIdsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    CustomerIdsQuery,
    CustomerIdsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    CustomerIdsQuery,
    CustomerIdsQueryVariables
  >(CustomerIdsDocument, options);
}
export type CustomerIdsQueryHookResult = ReturnType<typeof useCustomerIdsQuery>;
export type CustomerIdsLazyQueryHookResult = ReturnType<
  typeof useCustomerIdsLazyQuery
>;
export type CustomerIdsQueryResult = ApolloReactCommon.QueryResult<
  CustomerIdsQuery,
  CustomerIdsQueryVariables
>;
export const CustomerAreasDocument = gql`
  query CustomerAreas($filters: CustomerAreaSearchInput!) {
    customerAreas(filters: $filters) {
      count
      rows {
        id
        name
      }
    }
  }
`;

/**
 * __useCustomerAreasQuery__
 *
 * To run a query within a React component, call `useCustomerAreasQuery` and pass it any options that fit your needs.
 * When your component renders, `useCustomerAreasQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCustomerAreasQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function useCustomerAreasQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    CustomerAreasQuery,
    CustomerAreasQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    CustomerAreasQuery,
    CustomerAreasQueryVariables
  >(CustomerAreasDocument, options);
}
export function useCustomerAreasLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    CustomerAreasQuery,
    CustomerAreasQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    CustomerAreasQuery,
    CustomerAreasQueryVariables
  >(CustomerAreasDocument, options);
}
export type CustomerAreasQueryHookResult = ReturnType<
  typeof useCustomerAreasQuery
>;
export type CustomerAreasLazyQueryHookResult = ReturnType<
  typeof useCustomerAreasLazyQuery
>;
export type CustomerAreasQueryResult = ApolloReactCommon.QueryResult<
  CustomerAreasQuery,
  CustomerAreasQueryVariables
>;
export const CustomerLevelsDocument = gql`
  query CustomerLevels($filters: CustomerLevelSearchInput!) {
    customerLevels(filters: $filters) {
      count
      rows {
        id
        name
        type {
          code
        }
      }
    }
  }
`;

/**
 * __useCustomerLevelsQuery__
 *
 * To run a query within a React component, call `useCustomerLevelsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCustomerLevelsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCustomerLevelsQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function useCustomerLevelsQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    CustomerLevelsQuery,
    CustomerLevelsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    CustomerLevelsQuery,
    CustomerLevelsQueryVariables
  >(CustomerLevelsDocument, options);
}
export function useCustomerLevelsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    CustomerLevelsQuery,
    CustomerLevelsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    CustomerLevelsQuery,
    CustomerLevelsQueryVariables
  >(CustomerLevelsDocument, options);
}
export type CustomerLevelsQueryHookResult = ReturnType<
  typeof useCustomerLevelsQuery
>;
export type CustomerLevelsLazyQueryHookResult = ReturnType<
  typeof useCustomerLevelsLazyQuery
>;
export type CustomerLevelsQueryResult = ApolloReactCommon.QueryResult<
  CustomerLevelsQuery,
  CustomerLevelsQueryVariables
>;
export const CreateVisitDocument = gql`
  mutation createVisit($input: VisitCreateInput!) {
    createVisit(input: $input) {
      id
    }
  }
`;
export type CreateVisitMutationFn = ApolloReactCommon.MutationFunction<
  CreateVisitMutation,
  CreateVisitMutationVariables
>;

/**
 * __useCreateVisitMutation__
 *
 * To run a mutation, you first call `useCreateVisitMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateVisitMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createVisitMutation, { data, loading, error }] = useCreateVisitMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateVisitMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    CreateVisitMutation,
    CreateVisitMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    CreateVisitMutation,
    CreateVisitMutationVariables
  >(CreateVisitDocument, options);
}
export type CreateVisitMutationHookResult = ReturnType<
  typeof useCreateVisitMutation
>;
export type CreateVisitMutationResult =
  ApolloReactCommon.MutationResult<CreateVisitMutation>;
export type CreateVisitMutationOptions = ApolloReactCommon.BaseMutationOptions<
  CreateVisitMutation,
  CreateVisitMutationVariables
>;
export const UpdateVisitDocument = gql`
  mutation updateVisit($input: VisitUpdateInput!) {
    updateVisit(input: $input) {
      id
    }
  }
`;
export type UpdateVisitMutationFn = ApolloReactCommon.MutationFunction<
  UpdateVisitMutation,
  UpdateVisitMutationVariables
>;

/**
 * __useUpdateVisitMutation__
 *
 * To run a mutation, you first call `useUpdateVisitMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateVisitMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateVisitMutation, { data, loading, error }] = useUpdateVisitMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateVisitMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    UpdateVisitMutation,
    UpdateVisitMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    UpdateVisitMutation,
    UpdateVisitMutationVariables
  >(UpdateVisitDocument, options);
}
export type UpdateVisitMutationHookResult = ReturnType<
  typeof useUpdateVisitMutation
>;
export type UpdateVisitMutationResult =
  ApolloReactCommon.MutationResult<UpdateVisitMutation>;
export type UpdateVisitMutationOptions = ApolloReactCommon.BaseMutationOptions<
  UpdateVisitMutation,
  UpdateVisitMutationVariables
>;
export const VisitsDocument = gql`
  query visits($limit: Int, $offset: Int, $filters: VisitSearchInput!) {
    visits(limit: $limit, offset: $offset, filters: $filters) {
      count
      rows {
        ...VisitBasicInfo
        ...VisitDetailData
      }
    }
  }
  ${VisitBasicInfoFragmentDoc}
  ${VisitDetailDataFragmentDoc}
`;

/**
 * __useVisitsQuery__
 *
 * To run a query within a React component, call `useVisitsQuery` and pass it any options that fit your needs.
 * When your component renders, `useVisitsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useVisitsQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function useVisitsQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    VisitsQuery,
    VisitsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<VisitsQuery, VisitsQueryVariables>(
    VisitsDocument,
    options,
  );
}
export function useVisitsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    VisitsQuery,
    VisitsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<VisitsQuery, VisitsQueryVariables>(
    VisitsDocument,
    options,
  );
}
export type VisitsQueryHookResult = ReturnType<typeof useVisitsQuery>;
export type VisitsLazyQueryHookResult = ReturnType<typeof useVisitsLazyQuery>;
export type VisitsQueryResult = ApolloReactCommon.QueryResult<
  VisitsQuery,
  VisitsQueryVariables
>;
export const VisitTypesDocument = gql`
  query visitTypes($limit: Int, $offset: Int, $filters: VisitTypeSearchInput!) {
    visitTypes(limit: $limit, offset: $offset, filters: $filters) {
      count
      rows {
        id
        name
        code
        salesTeamGroup {
          ...SalesTeamGroup
        }
      }
    }
  }
  ${SalesTeamGroupFragmentDoc}
`;

/**
 * __useVisitTypesQuery__
 *
 * To run a query within a React component, call `useVisitTypesQuery` and pass it any options that fit your needs.
 * When your component renders, `useVisitTypesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useVisitTypesQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function useVisitTypesQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    VisitTypesQuery,
    VisitTypesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<VisitTypesQuery, VisitTypesQueryVariables>(
    VisitTypesDocument,
    options,
  );
}
export function useVisitTypesLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    VisitTypesQuery,
    VisitTypesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    VisitTypesQuery,
    VisitTypesQueryVariables
  >(VisitTypesDocument, options);
}
export type VisitTypesQueryHookResult = ReturnType<typeof useVisitTypesQuery>;
export type VisitTypesLazyQueryHookResult = ReturnType<
  typeof useVisitTypesLazyQuery
>;
export type VisitTypesQueryResult = ApolloReactCommon.QueryResult<
  VisitTypesQuery,
  VisitTypesQueryVariables
>;
export const VisitTimePeriodsDocument = gql`
  query visitTimePeriods(
    $limit: Int
    $offset: Int
    $filters: VisitTimePeriodSearchInput!
  ) {
    visitTimePeriods(limit: $limit, offset: $offset, filters: $filters) {
      count
      rows {
        id
        name
        salesTeamGroup {
          ...SalesTeamGroup
        }
      }
    }
  }
  ${SalesTeamGroupFragmentDoc}
`;

/**
 * __useVisitTimePeriodsQuery__
 *
 * To run a query within a React component, call `useVisitTimePeriodsQuery` and pass it any options that fit your needs.
 * When your component renders, `useVisitTimePeriodsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useVisitTimePeriodsQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function useVisitTimePeriodsQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    VisitTimePeriodsQuery,
    VisitTimePeriodsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    VisitTimePeriodsQuery,
    VisitTimePeriodsQueryVariables
  >(VisitTimePeriodsDocument, options);
}
export function useVisitTimePeriodsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    VisitTimePeriodsQuery,
    VisitTimePeriodsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    VisitTimePeriodsQuery,
    VisitTimePeriodsQueryVariables
  >(VisitTimePeriodsDocument, options);
}
export type VisitTimePeriodsQueryHookResult = ReturnType<
  typeof useVisitTimePeriodsQuery
>;
export type VisitTimePeriodsLazyQueryHookResult = ReturnType<
  typeof useVisitTimePeriodsLazyQuery
>;
export type VisitTimePeriodsQueryResult = ApolloReactCommon.QueryResult<
  VisitTimePeriodsQuery,
  VisitTimePeriodsQueryVariables
>;
export const VisitPropertiesDocument = gql`
  query visitProperties(
    $limit: Int
    $offset: Int
    $filters: VisitPropertySearchInput!
  ) {
    visitProperties(limit: $limit, offset: $offset, filters: $filters) {
      count
      rows {
        id
        name
        salesTeamGroup {
          ...SalesTeamGroup
        }
      }
    }
  }
  ${SalesTeamGroupFragmentDoc}
`;

/**
 * __useVisitPropertiesQuery__
 *
 * To run a query within a React component, call `useVisitPropertiesQuery` and pass it any options that fit your needs.
 * When your component renders, `useVisitPropertiesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useVisitPropertiesQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function useVisitPropertiesQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    VisitPropertiesQuery,
    VisitPropertiesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    VisitPropertiesQuery,
    VisitPropertiesQueryVariables
  >(VisitPropertiesDocument, options);
}
export function useVisitPropertiesLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    VisitPropertiesQuery,
    VisitPropertiesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    VisitPropertiesQuery,
    VisitPropertiesQueryVariables
  >(VisitPropertiesDocument, options);
}
export type VisitPropertiesQueryHookResult = ReturnType<
  typeof useVisitPropertiesQuery
>;
export type VisitPropertiesLazyQueryHookResult = ReturnType<
  typeof useVisitPropertiesLazyQuery
>;
export type VisitPropertiesQueryResult = ApolloReactCommon.QueryResult<
  VisitPropertiesQuery,
  VisitPropertiesQueryVariables
>;
export const VisitPropertyTypesDocument = gql`
  query visitPropertyTypes(
    $limit: Int
    $offset: Int
    $filters: VisitPropertyTypeSearchInput!
  ) {
    visitPropertyTypes(limit: $limit, offset: $offset, filters: $filters) {
      count
      rows {
        id
        name
        salesTeamGroup {
          ...SalesTeamGroup
        }
        properties {
          id
          name
        }
      }
    }
  }
  ${SalesTeamGroupFragmentDoc}
`;

/**
 * __useVisitPropertyTypesQuery__
 *
 * To run a query within a React component, call `useVisitPropertyTypesQuery` and pass it any options that fit your needs.
 * When your component renders, `useVisitPropertyTypesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useVisitPropertyTypesQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function useVisitPropertyTypesQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    VisitPropertyTypesQuery,
    VisitPropertyTypesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    VisitPropertyTypesQuery,
    VisitPropertyTypesQueryVariables
  >(VisitPropertyTypesDocument, options);
}
export function useVisitPropertyTypesLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    VisitPropertyTypesQuery,
    VisitPropertyTypesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    VisitPropertyTypesQuery,
    VisitPropertyTypesQueryVariables
  >(VisitPropertyTypesDocument, options);
}
export type VisitPropertyTypesQueryHookResult = ReturnType<
  typeof useVisitPropertyTypesQuery
>;
export type VisitPropertyTypesLazyQueryHookResult = ReturnType<
  typeof useVisitPropertyTypesLazyQuery
>;
export type VisitPropertyTypesQueryResult = ApolloReactCommon.QueryResult<
  VisitPropertyTypesQuery,
  VisitPropertyTypesQueryVariables
>;
export const VisitGoalsDocument = gql`
  query visitGoals($limit: Int, $offset: Int, $filters: VisitGoalSearchInput!) {
    visitGoals(limit: $limit, offset: $offset, filters: $filters) {
      count
      rows {
        id
        name
      }
    }
  }
`;

/**
 * __useVisitGoalsQuery__
 *
 * To run a query within a React component, call `useVisitGoalsQuery` and pass it any options that fit your needs.
 * When your component renders, `useVisitGoalsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useVisitGoalsQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function useVisitGoalsQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    VisitGoalsQuery,
    VisitGoalsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<VisitGoalsQuery, VisitGoalsQueryVariables>(
    VisitGoalsDocument,
    options,
  );
}
export function useVisitGoalsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    VisitGoalsQuery,
    VisitGoalsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    VisitGoalsQuery,
    VisitGoalsQueryVariables
  >(VisitGoalsDocument, options);
}
export type VisitGoalsQueryHookResult = ReturnType<typeof useVisitGoalsQuery>;
export type VisitGoalsLazyQueryHookResult = ReturnType<
  typeof useVisitGoalsLazyQuery
>;
export type VisitGoalsQueryResult = ApolloReactCommon.QueryResult<
  VisitGoalsQuery,
  VisitGoalsQueryVariables
>;
export const VisitActionsDocument = gql`
  query visitActions(
    $limit: Int
    $offset: Int
    $filters: VisitActionSearchInput!
  ) {
    visitActions(limit: $limit, offset: $offset, filters: $filters) {
      count
      rows {
        id
        name
        salesTeamGroup {
          ...SalesTeamGroup
        }
      }
    }
  }
  ${SalesTeamGroupFragmentDoc}
`;

/**
 * __useVisitActionsQuery__
 *
 * To run a query within a React component, call `useVisitActionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useVisitActionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useVisitActionsQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function useVisitActionsQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    VisitActionsQuery,
    VisitActionsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    VisitActionsQuery,
    VisitActionsQueryVariables
  >(VisitActionsDocument, options);
}
export function useVisitActionsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    VisitActionsQuery,
    VisitActionsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    VisitActionsQuery,
    VisitActionsQueryVariables
  >(VisitActionsDocument, options);
}
export type VisitActionsQueryHookResult = ReturnType<
  typeof useVisitActionsQuery
>;
export type VisitActionsLazyQueryHookResult = ReturnType<
  typeof useVisitActionsLazyQuery
>;
export type VisitActionsQueryResult = ApolloReactCommon.QueryResult<
  VisitActionsQuery,
  VisitActionsQueryVariables
>;
export const VisitCheckInsDocument = gql`
  query VisitCheckIns(
    $filters: VisitCheckInSearchInput!
    $offset: Int
    $limit: Int
  ) {
    visitCheckIns(filters: $filters, offset: $offset, limit: $limit) {
      count
      rows {
        id
        content
        createdAt
        lat
        lng
        visit {
          id
        }
      }
    }
  }
`;

/**
 * __useVisitCheckInsQuery__
 *
 * To run a query within a React component, call `useVisitCheckInsQuery` and pass it any options that fit your needs.
 * When your component renders, `useVisitCheckInsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useVisitCheckInsQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useVisitCheckInsQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    VisitCheckInsQuery,
    VisitCheckInsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    VisitCheckInsQuery,
    VisitCheckInsQueryVariables
  >(VisitCheckInsDocument, options);
}
export function useVisitCheckInsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    VisitCheckInsQuery,
    VisitCheckInsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    VisitCheckInsQuery,
    VisitCheckInsQueryVariables
  >(VisitCheckInsDocument, options);
}
export type VisitCheckInsQueryHookResult = ReturnType<
  typeof useVisitCheckInsQuery
>;
export type VisitCheckInsLazyQueryHookResult = ReturnType<
  typeof useVisitCheckInsLazyQuery
>;
export type VisitCheckInsQueryResult = ApolloReactCommon.QueryResult<
  VisitCheckInsQuery,
  VisitCheckInsQueryVariables
>;
export const RentProductsDocument = gql`
  query RentProducts(
    $filters: RentProductSearchInput!
    $offset: Int
    $limit: Int
  ) {
    rentProducts(filters: $filters, offset: $offset, limit: $limit) {
      count
      rows {
        id
        name
      }
    }
  }
`;

/**
 * __useRentProductsQuery__
 *
 * To run a query within a React component, call `useRentProductsQuery` and pass it any options that fit your needs.
 * When your component renders, `useRentProductsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRentProductsQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useRentProductsQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    RentProductsQuery,
    RentProductsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    RentProductsQuery,
    RentProductsQueryVariables
  >(RentProductsDocument, options);
}
export function useRentProductsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    RentProductsQuery,
    RentProductsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    RentProductsQuery,
    RentProductsQueryVariables
  >(RentProductsDocument, options);
}
export type RentProductsQueryHookResult = ReturnType<
  typeof useRentProductsQuery
>;
export type RentProductsLazyQueryHookResult = ReturnType<
  typeof useRentProductsLazyQuery
>;
export type RentProductsQueryResult = ApolloReactCommon.QueryResult<
  RentProductsQuery,
  RentProductsQueryVariables
>;
export const CreateEyeFixedAssetDocument = gql`
  mutation createEyeFixedAsset($input: EyeFixedAssetCreateInput!) {
    createEyeFixedAsset(input: $input) {
      id
    }
  }
`;
export type CreateEyeFixedAssetMutationFn = ApolloReactCommon.MutationFunction<
  CreateEyeFixedAssetMutation,
  CreateEyeFixedAssetMutationVariables
>;

/**
 * __useCreateEyeFixedAssetMutation__
 *
 * To run a mutation, you first call `useCreateEyeFixedAssetMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateEyeFixedAssetMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createEyeFixedAssetMutation, { data, loading, error }] = useCreateEyeFixedAssetMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateEyeFixedAssetMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    CreateEyeFixedAssetMutation,
    CreateEyeFixedAssetMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    CreateEyeFixedAssetMutation,
    CreateEyeFixedAssetMutationVariables
  >(CreateEyeFixedAssetDocument, options);
}
export type CreateEyeFixedAssetMutationHookResult = ReturnType<
  typeof useCreateEyeFixedAssetMutation
>;
export type CreateEyeFixedAssetMutationResult =
  ApolloReactCommon.MutationResult<CreateEyeFixedAssetMutation>;
export type CreateEyeFixedAssetMutationOptions =
  ApolloReactCommon.BaseMutationOptions<
    CreateEyeFixedAssetMutation,
    CreateEyeFixedAssetMutationVariables
  >;
export const UpdateEyeFixedAssetDocument = gql`
  mutation updateEyeFixedAsset($input: EyeFixedAssetUpdateInput!) {
    updateEyeFixedAsset(input: $input) {
      id
    }
  }
`;
export type UpdateEyeFixedAssetMutationFn = ApolloReactCommon.MutationFunction<
  UpdateEyeFixedAssetMutation,
  UpdateEyeFixedAssetMutationVariables
>;

/**
 * __useUpdateEyeFixedAssetMutation__
 *
 * To run a mutation, you first call `useUpdateEyeFixedAssetMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateEyeFixedAssetMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateEyeFixedAssetMutation, { data, loading, error }] = useUpdateEyeFixedAssetMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateEyeFixedAssetMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    UpdateEyeFixedAssetMutation,
    UpdateEyeFixedAssetMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    UpdateEyeFixedAssetMutation,
    UpdateEyeFixedAssetMutationVariables
  >(UpdateEyeFixedAssetDocument, options);
}
export type UpdateEyeFixedAssetMutationHookResult = ReturnType<
  typeof useUpdateEyeFixedAssetMutation
>;
export type UpdateEyeFixedAssetMutationResult =
  ApolloReactCommon.MutationResult<UpdateEyeFixedAssetMutation>;
export type UpdateEyeFixedAssetMutationOptions =
  ApolloReactCommon.BaseMutationOptions<
    UpdateEyeFixedAssetMutation,
    UpdateEyeFixedAssetMutationVariables
  >;
export const EyeFixedAssetsDocument = gql`
  query eyeFixedAssets(
    $limit: Int
    $offset: Int
    $filters: EyeFixedAssetSearchInput!
  ) {
    eyeFixedAssets(limit: $limit, offset: $offset, filters: $filters) {
      count
      pageInfo {
        hasNextPage
      }
      rows {
        region {
          id
          name
        }
        id
        name
        code
        isBooking
        status
        memo
        company {
          id
          name
        }
        dept {
          id
          code
          name
        }
        pmUserId
        pmUser {
          id
          name
          dept {
            id
            name
          }
        }
        createdUser {
          id
          name
        }
        updatedUser {
          id
          name
        }
        items {
          materialId
          materialCode
          materialName
          materialModel
          materialSpec
          materialUnit
          udi
          sn
          memo
        }
        createdAt
        updatedAt
        eyeFixedAssetRentalRecords {
          id
          eyeServiceOrderId
          date1
          date2
          installDate
          dismantleDate
          status
          remark
          eyeServiceOrder {
            id
            code
            assigneeUser {
              id
              code
              name
            }
          }
          eyeFixedAssets {
            id
            name
          }
          region {
            id
            name
          }
        }
        currentAvailableRentalDate
        currEyeFixedAssetRentalRecord {
          eyeServiceOrder {
            customer {
              address
              name
              id
              code
            }
          }
        }
        nextEyeFixedAssetRentalRecord {
          id
          date1
          date2
          eyeServiceOrder {
            customer {
              code
              id
              name
            }
          }
        }
        prevEyeFixedAssetRentalRecord {
          id
          date1
          date2
          eyeServiceOrder {
            customer {
              code
              id
              name
            }
          }
        }
        images {
          id
          url
          name
          size
          s3Key
        }
        documents {
          id
          url
          name
          size
          s3Key
        }
        currentLocation
        types {
          id
          name
          code
        }
        warehouseId
        eyeFixedAssetServiceProvider {
          id
          code
        }
        borrowingDays
        deliveryDays
        maxExtendedDays
        maxExtendedTimes
      }
    }
  }
`;

/**
 * __useEyeFixedAssetsQuery__
 *
 * To run a query within a React component, call `useEyeFixedAssetsQuery` and pass it any options that fit your needs.
 * When your component renders, `useEyeFixedAssetsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEyeFixedAssetsQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function useEyeFixedAssetsQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    EyeFixedAssetsQuery,
    EyeFixedAssetsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    EyeFixedAssetsQuery,
    EyeFixedAssetsQueryVariables
  >(EyeFixedAssetsDocument, options);
}
export function useEyeFixedAssetsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    EyeFixedAssetsQuery,
    EyeFixedAssetsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    EyeFixedAssetsQuery,
    EyeFixedAssetsQueryVariables
  >(EyeFixedAssetsDocument, options);
}
export type EyeFixedAssetsQueryHookResult = ReturnType<
  typeof useEyeFixedAssetsQuery
>;
export type EyeFixedAssetsLazyQueryHookResult = ReturnType<
  typeof useEyeFixedAssetsLazyQuery
>;
export type EyeFixedAssetsQueryResult = ApolloReactCommon.QueryResult<
  EyeFixedAssetsQuery,
  EyeFixedAssetsQueryVariables
>;
export const WarehousesDocument = gql`
  query Warehouses($filters: WarehouseSearchInput!) {
    warehouses(filters: $filters) {
      rows {
        id
        createdAt
        updatedAt
        isActive
        code
        name
      }
    }
  }
`;

/**
 * __useWarehousesQuery__
 *
 * To run a query within a React component, call `useWarehousesQuery` and pass it any options that fit your needs.
 * When your component renders, `useWarehousesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useWarehousesQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function useWarehousesQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    WarehousesQuery,
    WarehousesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<WarehousesQuery, WarehousesQueryVariables>(
    WarehousesDocument,
    options,
  );
}
export function useWarehousesLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    WarehousesQuery,
    WarehousesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    WarehousesQuery,
    WarehousesQueryVariables
  >(WarehousesDocument, options);
}
export type WarehousesQueryHookResult = ReturnType<typeof useWarehousesQuery>;
export type WarehousesLazyQueryHookResult = ReturnType<
  typeof useWarehousesLazyQuery
>;
export type WarehousesQueryResult = ApolloReactCommon.QueryResult<
  WarehousesQuery,
  WarehousesQueryVariables
>;
export const EyeFixedAssetServiceProvidersDocument = gql`
  query EyeFixedAssetServiceProviders(
    $filters: EyeFixedAssetServiceProviderSearchInput!
  ) {
    eyeFixedAssetServiceProviders(filters: $filters) {
      rows {
        id
        code
        name
      }
    }
  }
`;

/**
 * __useEyeFixedAssetServiceProvidersQuery__
 *
 * To run a query within a React component, call `useEyeFixedAssetServiceProvidersQuery` and pass it any options that fit your needs.
 * When your component renders, `useEyeFixedAssetServiceProvidersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEyeFixedAssetServiceProvidersQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function useEyeFixedAssetServiceProvidersQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    EyeFixedAssetServiceProvidersQuery,
    EyeFixedAssetServiceProvidersQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    EyeFixedAssetServiceProvidersQuery,
    EyeFixedAssetServiceProvidersQueryVariables
  >(EyeFixedAssetServiceProvidersDocument, options);
}
export function useEyeFixedAssetServiceProvidersLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    EyeFixedAssetServiceProvidersQuery,
    EyeFixedAssetServiceProvidersQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    EyeFixedAssetServiceProvidersQuery,
    EyeFixedAssetServiceProvidersQueryVariables
  >(EyeFixedAssetServiceProvidersDocument, options);
}
export type EyeFixedAssetServiceProvidersQueryHookResult = ReturnType<
  typeof useEyeFixedAssetServiceProvidersQuery
>;
export type EyeFixedAssetServiceProvidersLazyQueryHookResult = ReturnType<
  typeof useEyeFixedAssetServiceProvidersLazyQuery
>;
export type EyeFixedAssetServiceProvidersQueryResult =
  ApolloReactCommon.QueryResult<
    EyeFixedAssetServiceProvidersQuery,
    EyeFixedAssetServiceProvidersQueryVariables
  >;
export const EyeFixedAssetRentalRecordsDocument = gql`
  query eyeFixedAssetRentalRecords(
    $limit: Int
    $offset: Int
    $filters: EyeFixedAssetRentalRecordSearchInput!
  ) {
    eyeFixedAssetRentalRecords(
      limit: $limit
      offset: $offset
      filters: $filters
    ) {
      count
      rows {
        id
        date1
        date2
        eyeServiceOrderId
        region {
          id
          name
        }
        installDate
        dismantleDate
        status
        remark
        createdAt
        updatedAt
        createdUser {
          id
          code
          name
        }
        updatedUser {
          id
          code
          name
        }
        eyeFixedAssets {
          id
          name
          items {
            id
            materialName
          }
        }
        eyeServiceOrder {
          code
          customer {
            name
            address
          }
          priority
        }
        eyeServiceOrderWorkDiaries {
          id
          users {
            id
            code
            name
          }
          hours
          eyeServiceOrderId
          remark
        }
      }
    }
  }
`;

/**
 * __useEyeFixedAssetRentalRecordsQuery__
 *
 * To run a query within a React component, call `useEyeFixedAssetRentalRecordsQuery` and pass it any options that fit your needs.
 * When your component renders, `useEyeFixedAssetRentalRecordsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEyeFixedAssetRentalRecordsQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function useEyeFixedAssetRentalRecordsQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    EyeFixedAssetRentalRecordsQuery,
    EyeFixedAssetRentalRecordsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    EyeFixedAssetRentalRecordsQuery,
    EyeFixedAssetRentalRecordsQueryVariables
  >(EyeFixedAssetRentalRecordsDocument, options);
}
export function useEyeFixedAssetRentalRecordsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    EyeFixedAssetRentalRecordsQuery,
    EyeFixedAssetRentalRecordsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    EyeFixedAssetRentalRecordsQuery,
    EyeFixedAssetRentalRecordsQueryVariables
  >(EyeFixedAssetRentalRecordsDocument, options);
}
export type EyeFixedAssetRentalRecordsQueryHookResult = ReturnType<
  typeof useEyeFixedAssetRentalRecordsQuery
>;
export type EyeFixedAssetRentalRecordsLazyQueryHookResult = ReturnType<
  typeof useEyeFixedAssetRentalRecordsLazyQuery
>;
export type EyeFixedAssetRentalRecordsQueryResult =
  ApolloReactCommon.QueryResult<
    EyeFixedAssetRentalRecordsQuery,
    EyeFixedAssetRentalRecordsQueryVariables
  >;
export const EyeServiceOrdersSelectDocument = gql`
  query eyeServiceOrdersSelect(
    $limit: Int
    $offset: Int
    $filters: EyeServiceOrderSearchInput!
  ) {
    eyeServiceOrders(limit: $limit, offset: $offset, filters: $filters) {
      count
      rows {
        id
        code
      }
    }
  }
`;

/**
 * __useEyeServiceOrdersSelectQuery__
 *
 * To run a query within a React component, call `useEyeServiceOrdersSelectQuery` and pass it any options that fit your needs.
 * When your component renders, `useEyeServiceOrdersSelectQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEyeServiceOrdersSelectQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function useEyeServiceOrdersSelectQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    EyeServiceOrdersSelectQuery,
    EyeServiceOrdersSelectQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    EyeServiceOrdersSelectQuery,
    EyeServiceOrdersSelectQueryVariables
  >(EyeServiceOrdersSelectDocument, options);
}
export function useEyeServiceOrdersSelectLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    EyeServiceOrdersSelectQuery,
    EyeServiceOrdersSelectQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    EyeServiceOrdersSelectQuery,
    EyeServiceOrdersSelectQueryVariables
  >(EyeServiceOrdersSelectDocument, options);
}
export type EyeServiceOrdersSelectQueryHookResult = ReturnType<
  typeof useEyeServiceOrdersSelectQuery
>;
export type EyeServiceOrdersSelectLazyQueryHookResult = ReturnType<
  typeof useEyeServiceOrdersSelectLazyQuery
>;
export type EyeServiceOrdersSelectQueryResult = ApolloReactCommon.QueryResult<
  EyeServiceOrdersSelectQuery,
  EyeServiceOrdersSelectQueryVariables
>;
export const EyeFixedAssetsSelectDocument = gql`
  query eyeFixedAssetsSelect(
    $limit: Int
    $offset: Int
    $filters: EyeFixedAssetSearchInput!
  ) {
    eyeFixedAssets(limit: $limit, offset: $offset, filters: $filters) {
      count
      rows {
        id
        name
      }
    }
  }
`;

/**
 * __useEyeFixedAssetsSelectQuery__
 *
 * To run a query within a React component, call `useEyeFixedAssetsSelectQuery` and pass it any options that fit your needs.
 * When your component renders, `useEyeFixedAssetsSelectQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEyeFixedAssetsSelectQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function useEyeFixedAssetsSelectQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    EyeFixedAssetsSelectQuery,
    EyeFixedAssetsSelectQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    EyeFixedAssetsSelectQuery,
    EyeFixedAssetsSelectQueryVariables
  >(EyeFixedAssetsSelectDocument, options);
}
export function useEyeFixedAssetsSelectLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    EyeFixedAssetsSelectQuery,
    EyeFixedAssetsSelectQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    EyeFixedAssetsSelectQuery,
    EyeFixedAssetsSelectQueryVariables
  >(EyeFixedAssetsSelectDocument, options);
}
export type EyeFixedAssetsSelectQueryHookResult = ReturnType<
  typeof useEyeFixedAssetsSelectQuery
>;
export type EyeFixedAssetsSelectLazyQueryHookResult = ReturnType<
  typeof useEyeFixedAssetsSelectLazyQuery
>;
export type EyeFixedAssetsSelectQueryResult = ApolloReactCommon.QueryResult<
  EyeFixedAssetsSelectQuery,
  EyeFixedAssetsSelectQueryVariables
>;
export const CreateEyeProductDocument = gql`
  mutation createEyeProduct($input: EyeProductCreateInput!) {
    createEyeProduct(input: $input) {
      id
      eyeProductTypeId
      eyeProductType {
        id
        regionId
        name
      }
      regionId
      name
      salePrice
      brand
      status
      createdAt
      updatedAt
    }
  }
`;
export type CreateEyeProductMutationFn = ApolloReactCommon.MutationFunction<
  CreateEyeProductMutation,
  CreateEyeProductMutationVariables
>;

/**
 * __useCreateEyeProductMutation__
 *
 * To run a mutation, you first call `useCreateEyeProductMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateEyeProductMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createEyeProductMutation, { data, loading, error }] = useCreateEyeProductMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateEyeProductMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    CreateEyeProductMutation,
    CreateEyeProductMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    CreateEyeProductMutation,
    CreateEyeProductMutationVariables
  >(CreateEyeProductDocument, options);
}
export type CreateEyeProductMutationHookResult = ReturnType<
  typeof useCreateEyeProductMutation
>;
export type CreateEyeProductMutationResult =
  ApolloReactCommon.MutationResult<CreateEyeProductMutation>;
export type CreateEyeProductMutationOptions =
  ApolloReactCommon.BaseMutationOptions<
    CreateEyeProductMutation,
    CreateEyeProductMutationVariables
  >;
export const CreateEyeProductItemDocument = gql`
  mutation createEyeProductItem($input: EyeProductItemCreateInput!) {
    createEyeProductItem(input: $input) {
      id
      eyeProductId
      eyeProductItemTypeId
      qty
      isOptional
      createdAt
      materialName
      material {
        id
        code
        name
        model
        spec
        unit
        countingUnit {
          id
          name
        }
      }
      materialSellingUnit
    }
  }
`;
export type CreateEyeProductItemMutationFn = ApolloReactCommon.MutationFunction<
  CreateEyeProductItemMutation,
  CreateEyeProductItemMutationVariables
>;

/**
 * __useCreateEyeProductItemMutation__
 *
 * To run a mutation, you first call `useCreateEyeProductItemMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateEyeProductItemMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createEyeProductItemMutation, { data, loading, error }] = useCreateEyeProductItemMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateEyeProductItemMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    CreateEyeProductItemMutation,
    CreateEyeProductItemMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    CreateEyeProductItemMutation,
    CreateEyeProductItemMutationVariables
  >(CreateEyeProductItemDocument, options);
}
export type CreateEyeProductItemMutationHookResult = ReturnType<
  typeof useCreateEyeProductItemMutation
>;
export type CreateEyeProductItemMutationResult =
  ApolloReactCommon.MutationResult<CreateEyeProductItemMutation>;
export type CreateEyeProductItemMutationOptions =
  ApolloReactCommon.BaseMutationOptions<
    CreateEyeProductItemMutation,
    CreateEyeProductItemMutationVariables
  >;
export const UpdateEyeProductItemDocument = gql`
  mutation UpdateEyeProductItem($input: EyeProductItemUpdateInput!) {
    updateEyeProductItem(input: $input) {
      id
      materialName
      qty
      materialSellingUnit
    }
  }
`;
export type UpdateEyeProductItemMutationFn = ApolloReactCommon.MutationFunction<
  UpdateEyeProductItemMutation,
  UpdateEyeProductItemMutationVariables
>;

/**
 * __useUpdateEyeProductItemMutation__
 *
 * To run a mutation, you first call `useUpdateEyeProductItemMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateEyeProductItemMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateEyeProductItemMutation, { data, loading, error }] = useUpdateEyeProductItemMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateEyeProductItemMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    UpdateEyeProductItemMutation,
    UpdateEyeProductItemMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    UpdateEyeProductItemMutation,
    UpdateEyeProductItemMutationVariables
  >(UpdateEyeProductItemDocument, options);
}
export type UpdateEyeProductItemMutationHookResult = ReturnType<
  typeof useUpdateEyeProductItemMutation
>;
export type UpdateEyeProductItemMutationResult =
  ApolloReactCommon.MutationResult<UpdateEyeProductItemMutation>;
export type UpdateEyeProductItemMutationOptions =
  ApolloReactCommon.BaseMutationOptions<
    UpdateEyeProductItemMutation,
    UpdateEyeProductItemMutationVariables
  >;
export const BulkUpdateEyeProductItemsDocument = gql`
  mutation BulkUpdateEyeProductItems($input: EyeProductItemBulkUpdateInput!) {
    bulkUpdateEyeProductItems(input: $input) {
      id
    }
  }
`;
export type BulkUpdateEyeProductItemsMutationFn =
  ApolloReactCommon.MutationFunction<
    BulkUpdateEyeProductItemsMutation,
    BulkUpdateEyeProductItemsMutationVariables
  >;

/**
 * __useBulkUpdateEyeProductItemsMutation__
 *
 * To run a mutation, you first call `useBulkUpdateEyeProductItemsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBulkUpdateEyeProductItemsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [bulkUpdateEyeProductItemsMutation, { data, loading, error }] = useBulkUpdateEyeProductItemsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useBulkUpdateEyeProductItemsMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    BulkUpdateEyeProductItemsMutation,
    BulkUpdateEyeProductItemsMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    BulkUpdateEyeProductItemsMutation,
    BulkUpdateEyeProductItemsMutationVariables
  >(BulkUpdateEyeProductItemsDocument, options);
}
export type BulkUpdateEyeProductItemsMutationHookResult = ReturnType<
  typeof useBulkUpdateEyeProductItemsMutation
>;
export type BulkUpdateEyeProductItemsMutationResult =
  ApolloReactCommon.MutationResult<BulkUpdateEyeProductItemsMutation>;
export type BulkUpdateEyeProductItemsMutationOptions =
  ApolloReactCommon.BaseMutationOptions<
    BulkUpdateEyeProductItemsMutation,
    BulkUpdateEyeProductItemsMutationVariables
  >;
export const UpdateEyeProductDocument = gql`
  mutation updateEyeProduct($input: EyeProductUpdateInput!) {
    updateEyeProduct(input: $input) {
      id
      eyeProductType {
        id
        regionId
        name
        createdAt
        updatedAt
      }
      name
      salePrice
      dealerPrice
      status
      description
      currencyId
      model
      regionId
      brand
      createdAt
      updatedAt
      eyeProductTypeId
      eyeProductItems {
        id
        eyeProductId
        eyeProductItemTypeId
        qty
        isOptional
        material {
          id
          code
          name
          model
          spec
          unit
        }
      }
      attachments {
        id
        url
        name
        size
        s3Key
      }
      standardDeliveryDays
      productUsers {
        id
        name
      }
    }
  }
`;
export type UpdateEyeProductMutationFn = ApolloReactCommon.MutationFunction<
  UpdateEyeProductMutation,
  UpdateEyeProductMutationVariables
>;

/**
 * __useUpdateEyeProductMutation__
 *
 * To run a mutation, you first call `useUpdateEyeProductMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateEyeProductMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateEyeProductMutation, { data, loading, error }] = useUpdateEyeProductMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateEyeProductMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    UpdateEyeProductMutation,
    UpdateEyeProductMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    UpdateEyeProductMutation,
    UpdateEyeProductMutationVariables
  >(UpdateEyeProductDocument, options);
}
export type UpdateEyeProductMutationHookResult = ReturnType<
  typeof useUpdateEyeProductMutation
>;
export type UpdateEyeProductMutationResult =
  ApolloReactCommon.MutationResult<UpdateEyeProductMutation>;
export type UpdateEyeProductMutationOptions =
  ApolloReactCommon.BaseMutationOptions<
    UpdateEyeProductMutation,
    UpdateEyeProductMutationVariables
  >;
export const DeleteEyeProductDocument = gql`
  mutation deleteEyeProduct($id: ID!) {
    deleteEyeProduct(id: $id)
  }
`;
export type DeleteEyeProductMutationFn = ApolloReactCommon.MutationFunction<
  DeleteEyeProductMutation,
  DeleteEyeProductMutationVariables
>;

/**
 * __useDeleteEyeProductMutation__
 *
 * To run a mutation, you first call `useDeleteEyeProductMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteEyeProductMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteEyeProductMutation, { data, loading, error }] = useDeleteEyeProductMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteEyeProductMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    DeleteEyeProductMutation,
    DeleteEyeProductMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    DeleteEyeProductMutation,
    DeleteEyeProductMutationVariables
  >(DeleteEyeProductDocument, options);
}
export type DeleteEyeProductMutationHookResult = ReturnType<
  typeof useDeleteEyeProductMutation
>;
export type DeleteEyeProductMutationResult =
  ApolloReactCommon.MutationResult<DeleteEyeProductMutation>;
export type DeleteEyeProductMutationOptions =
  ApolloReactCommon.BaseMutationOptions<
    DeleteEyeProductMutation,
    DeleteEyeProductMutationVariables
  >;
export const DeleteEyeProductItemDocument = gql`
  mutation deleteEyeProductItem($id: ID!) {
    deleteEyeProductItem(id: $id)
  }
`;
export type DeleteEyeProductItemMutationFn = ApolloReactCommon.MutationFunction<
  DeleteEyeProductItemMutation,
  DeleteEyeProductItemMutationVariables
>;

/**
 * __useDeleteEyeProductItemMutation__
 *
 * To run a mutation, you first call `useDeleteEyeProductItemMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteEyeProductItemMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteEyeProductItemMutation, { data, loading, error }] = useDeleteEyeProductItemMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteEyeProductItemMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    DeleteEyeProductItemMutation,
    DeleteEyeProductItemMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    DeleteEyeProductItemMutation,
    DeleteEyeProductItemMutationVariables
  >(DeleteEyeProductItemDocument, options);
}
export type DeleteEyeProductItemMutationHookResult = ReturnType<
  typeof useDeleteEyeProductItemMutation
>;
export type DeleteEyeProductItemMutationResult =
  ApolloReactCommon.MutationResult<DeleteEyeProductItemMutation>;
export type DeleteEyeProductItemMutationOptions =
  ApolloReactCommon.BaseMutationOptions<
    DeleteEyeProductItemMutation,
    DeleteEyeProductItemMutationVariables
  >;
export const EyeProductsDocument = gql`
  query eyeProducts(
    $offset: Int
    $limit: Int
    $filters: EyeProductSearchInput!
  ) {
    eyeProducts(offset: $offset, limit: $limit, filters: $filters) {
      count
      pageInfo {
        hasNextPage
      }
      rows {
        id
        eyeProductType {
          id
          regionId
          name
          createdAt
          updatedAt
        }
        name
        salePrice
        dealerPrice
        brand
        status
        company {
          id
          name
        }
        description
        currencyId
        currency {
          id
          name
        }
        model
        regionId
        createdAt
        updatedAt
        eyeProductTypeId
        eyeProductItems {
          id
          eyeProductId
          eyeProductItemTypeId
          qty
          isOptional
          createdAt
          viewOrder
          materialName
          material {
            id
            code
            name
            model
            spec
            salePrice
            saleCurrencyId
            countingUnit {
              id
              name
            }
          }
          materialSellingUnit
        }
        region {
          id
          name
        }
        attachments {
          id
          url
          name
          size
          s3Key
        }
        standardDeliveryDays
        productUsers {
          id
          name
        }
        eyePromotions {
          id
          name
          isAddon
          discountRate
          discountAmount
          createdAt
          date1
          regionId
          updatedAt
          currencyId
          eyePromotionAddonProductItems {
            id
            qty
            isOptional
            material {
              id
              unit
            }
          }
        }
        bonusCoefficient
        businessManagerPrice
        dsmPrice
        generalManagerPrice
        regionalManagerPrice
      }
    }
  }
`;

/**
 * __useEyeProductsQuery__
 *
 * To run a query within a React component, call `useEyeProductsQuery` and pass it any options that fit your needs.
 * When your component renders, `useEyeProductsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEyeProductsQuery({
 *   variables: {
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function useEyeProductsQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    EyeProductsQuery,
    EyeProductsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<EyeProductsQuery, EyeProductsQueryVariables>(
    EyeProductsDocument,
    options,
  );
}
export function useEyeProductsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    EyeProductsQuery,
    EyeProductsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    EyeProductsQuery,
    EyeProductsQueryVariables
  >(EyeProductsDocument, options);
}
export type EyeProductsQueryHookResult = ReturnType<typeof useEyeProductsQuery>;
export type EyeProductsLazyQueryHookResult = ReturnType<
  typeof useEyeProductsLazyQuery
>;
export type EyeProductsQueryResult = ApolloReactCommon.QueryResult<
  EyeProductsQuery,
  EyeProductsQueryVariables
>;
export const EyeProductNamesDocument = gql`
  query eyeProductNames(
    $offset: Int
    $limit: Int
    $filters: EyeProductSearchInput!
  ) {
    eyeProducts(offset: $offset, limit: $limit, filters: $filters) {
      count
      pageInfo {
        hasNextPage
      }
      rows {
        id
        name
      }
    }
  }
`;

/**
 * __useEyeProductNamesQuery__
 *
 * To run a query within a React component, call `useEyeProductNamesQuery` and pass it any options that fit your needs.
 * When your component renders, `useEyeProductNamesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEyeProductNamesQuery({
 *   variables: {
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function useEyeProductNamesQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    EyeProductNamesQuery,
    EyeProductNamesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    EyeProductNamesQuery,
    EyeProductNamesQueryVariables
  >(EyeProductNamesDocument, options);
}
export function useEyeProductNamesLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    EyeProductNamesQuery,
    EyeProductNamesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    EyeProductNamesQuery,
    EyeProductNamesQueryVariables
  >(EyeProductNamesDocument, options);
}
export type EyeProductNamesQueryHookResult = ReturnType<
  typeof useEyeProductNamesQuery
>;
export type EyeProductNamesLazyQueryHookResult = ReturnType<
  typeof useEyeProductNamesLazyQuery
>;
export type EyeProductNamesQueryResult = ApolloReactCommon.QueryResult<
  EyeProductNamesQuery,
  EyeProductNamesQueryVariables
>;
export const EyeProductItemsDocument = gql`
  query eyeProductItems($limit: Int, $filters: EyeProductSearchInput!) {
    eyeProducts(limit: $limit, filters: $filters) {
      rows {
        eyeProductItems {
          id
          eyeProductId
          eyeProductItemTypeId
          qty
          isOptional
          createdAt
          viewOrder
          materialName
          material {
            id
            code
            name
            model
            spec
            countingUnit {
              id
              name
            }
          }
          materialSellingUnit
        }
      }
    }
  }
`;

/**
 * __useEyeProductItemsQuery__
 *
 * To run a query within a React component, call `useEyeProductItemsQuery` and pass it any options that fit your needs.
 * When your component renders, `useEyeProductItemsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEyeProductItemsQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function useEyeProductItemsQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    EyeProductItemsQuery,
    EyeProductItemsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    EyeProductItemsQuery,
    EyeProductItemsQueryVariables
  >(EyeProductItemsDocument, options);
}
export function useEyeProductItemsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    EyeProductItemsQuery,
    EyeProductItemsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    EyeProductItemsQuery,
    EyeProductItemsQueryVariables
  >(EyeProductItemsDocument, options);
}
export type EyeProductItemsQueryHookResult = ReturnType<
  typeof useEyeProductItemsQuery
>;
export type EyeProductItemsLazyQueryHookResult = ReturnType<
  typeof useEyeProductItemsLazyQuery
>;
export type EyeProductItemsQueryResult = ApolloReactCommon.QueryResult<
  EyeProductItemsQuery,
  EyeProductItemsQueryVariables
>;
export const EyeProductsBrandDocument = gql`
  query eyeProductsBrand($limit: Int, $filters: EyeProductSearchInput!) {
    eyeProducts(limit: $limit, filters: $filters) {
      rows {
        brand
      }
    }
  }
`;

/**
 * __useEyeProductsBrandQuery__
 *
 * To run a query within a React component, call `useEyeProductsBrandQuery` and pass it any options that fit your needs.
 * When your component renders, `useEyeProductsBrandQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEyeProductsBrandQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function useEyeProductsBrandQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    EyeProductsBrandQuery,
    EyeProductsBrandQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    EyeProductsBrandQuery,
    EyeProductsBrandQueryVariables
  >(EyeProductsBrandDocument, options);
}
export function useEyeProductsBrandLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    EyeProductsBrandQuery,
    EyeProductsBrandQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    EyeProductsBrandQuery,
    EyeProductsBrandQueryVariables
  >(EyeProductsBrandDocument, options);
}
export type EyeProductsBrandQueryHookResult = ReturnType<
  typeof useEyeProductsBrandQuery
>;
export type EyeProductsBrandLazyQueryHookResult = ReturnType<
  typeof useEyeProductsBrandLazyQuery
>;
export type EyeProductsBrandQueryResult = ApolloReactCommon.QueryResult<
  EyeProductsBrandQuery,
  EyeProductsBrandQueryVariables
>;
export const CreateEyePromotionDocument = gql`
  mutation createEyePromotion($input: EyePromotionCreateInput!) {
    createEyePromotion(input: $input) {
      id
      date1
      date2
      regionId
      name
      discountRate
      discountAmount
      createdUserId
      createdUser {
        id
        code
        name
        companyId
        departmentId
      }
      updatedUser {
        id
        code
        name
        companyId
        departmentId
      }
      eyeProducts {
        id
        eyeProductTypeId
        regionId
        name
      }
      createdAt
      updatedAt
    }
  }
`;
export type CreateEyePromotionMutationFn = ApolloReactCommon.MutationFunction<
  CreateEyePromotionMutation,
  CreateEyePromotionMutationVariables
>;

/**
 * __useCreateEyePromotionMutation__
 *
 * To run a mutation, you first call `useCreateEyePromotionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateEyePromotionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createEyePromotionMutation, { data, loading, error }] = useCreateEyePromotionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateEyePromotionMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    CreateEyePromotionMutation,
    CreateEyePromotionMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    CreateEyePromotionMutation,
    CreateEyePromotionMutationVariables
  >(CreateEyePromotionDocument, options);
}
export type CreateEyePromotionMutationHookResult = ReturnType<
  typeof useCreateEyePromotionMutation
>;
export type CreateEyePromotionMutationResult =
  ApolloReactCommon.MutationResult<CreateEyePromotionMutation>;
export type CreateEyePromotionMutationOptions =
  ApolloReactCommon.BaseMutationOptions<
    CreateEyePromotionMutation,
    CreateEyePromotionMutationVariables
  >;
export const UpdateEyePromotionDocument = gql`
  mutation updateEyePromotion($input: EyePromotionUpdateInput!) {
    updateEyePromotion(input: $input) {
      id
      name
      regionId
      date1
      date2
      createdAt
      updatedAt
      description
      eyeProducts {
        id
        eyeProductType {
          id
          regionId
          name
          createdAt
          updatedAt
        }
        name
        salePrice
        dealerPrice
        status
        description
        currencyId
        model
        regionId
        brand
        createdAt
        updatedAt
        eyeProductTypeId
        standardDeliveryDays
        productUsers {
          id
          name
        }
        attachments {
          id
          url
          name
          size
          s3Key
        }
        eyeProductItems {
          id
          eyeProductId
          eyeProductItemTypeId
          qty
          isOptional
          material {
            id
            code
            name
            model
            spec
            unit
          }
        }
      }
      discountRate
      discountAmount
      createdUserId
      updatedUserId
      createdUser {
        id
        name
        code
      }
    }
  }
`;
export type UpdateEyePromotionMutationFn = ApolloReactCommon.MutationFunction<
  UpdateEyePromotionMutation,
  UpdateEyePromotionMutationVariables
>;

/**
 * __useUpdateEyePromotionMutation__
 *
 * To run a mutation, you first call `useUpdateEyePromotionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateEyePromotionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateEyePromotionMutation, { data, loading, error }] = useUpdateEyePromotionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateEyePromotionMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    UpdateEyePromotionMutation,
    UpdateEyePromotionMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    UpdateEyePromotionMutation,
    UpdateEyePromotionMutationVariables
  >(UpdateEyePromotionDocument, options);
}
export type UpdateEyePromotionMutationHookResult = ReturnType<
  typeof useUpdateEyePromotionMutation
>;
export type UpdateEyePromotionMutationResult =
  ApolloReactCommon.MutationResult<UpdateEyePromotionMutation>;
export type UpdateEyePromotionMutationOptions =
  ApolloReactCommon.BaseMutationOptions<
    UpdateEyePromotionMutation,
    UpdateEyePromotionMutationVariables
  >;
export const CreateEyePromotionAddonProductItemDocument = gql`
  mutation createEyePromotionAddonProductItem(
    $input: EyePromotionAddonProductItemCreateInput!
  ) {
    createEyePromotionAddonProductItem(input: $input) {
      id
      eyePromotionId
      eyeProductItemTypeId
      qty
      isOptional
      material {
        id
        code
        name
        model
        spec
        unit
      }
    }
  }
`;
export type CreateEyePromotionAddonProductItemMutationFn =
  ApolloReactCommon.MutationFunction<
    CreateEyePromotionAddonProductItemMutation,
    CreateEyePromotionAddonProductItemMutationVariables
  >;

/**
 * __useCreateEyePromotionAddonProductItemMutation__
 *
 * To run a mutation, you first call `useCreateEyePromotionAddonProductItemMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateEyePromotionAddonProductItemMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createEyePromotionAddonProductItemMutation, { data, loading, error }] = useCreateEyePromotionAddonProductItemMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateEyePromotionAddonProductItemMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    CreateEyePromotionAddonProductItemMutation,
    CreateEyePromotionAddonProductItemMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    CreateEyePromotionAddonProductItemMutation,
    CreateEyePromotionAddonProductItemMutationVariables
  >(CreateEyePromotionAddonProductItemDocument, options);
}
export type CreateEyePromotionAddonProductItemMutationHookResult = ReturnType<
  typeof useCreateEyePromotionAddonProductItemMutation
>;
export type CreateEyePromotionAddonProductItemMutationResult =
  ApolloReactCommon.MutationResult<CreateEyePromotionAddonProductItemMutation>;
export type CreateEyePromotionAddonProductItemMutationOptions =
  ApolloReactCommon.BaseMutationOptions<
    CreateEyePromotionAddonProductItemMutation,
    CreateEyePromotionAddonProductItemMutationVariables
  >;
export const DeleteEyePromotionAddonProductItemDocument = gql`
  mutation deleteEyePromotionAddonProductItem($id: ID!) {
    deleteEyePromotionAddonProductItem(id: $id)
  }
`;
export type DeleteEyePromotionAddonProductItemMutationFn =
  ApolloReactCommon.MutationFunction<
    DeleteEyePromotionAddonProductItemMutation,
    DeleteEyePromotionAddonProductItemMutationVariables
  >;

/**
 * __useDeleteEyePromotionAddonProductItemMutation__
 *
 * To run a mutation, you first call `useDeleteEyePromotionAddonProductItemMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteEyePromotionAddonProductItemMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteEyePromotionAddonProductItemMutation, { data, loading, error }] = useDeleteEyePromotionAddonProductItemMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteEyePromotionAddonProductItemMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    DeleteEyePromotionAddonProductItemMutation,
    DeleteEyePromotionAddonProductItemMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    DeleteEyePromotionAddonProductItemMutation,
    DeleteEyePromotionAddonProductItemMutationVariables
  >(DeleteEyePromotionAddonProductItemDocument, options);
}
export type DeleteEyePromotionAddonProductItemMutationHookResult = ReturnType<
  typeof useDeleteEyePromotionAddonProductItemMutation
>;
export type DeleteEyePromotionAddonProductItemMutationResult =
  ApolloReactCommon.MutationResult<DeleteEyePromotionAddonProductItemMutation>;
export type DeleteEyePromotionAddonProductItemMutationOptions =
  ApolloReactCommon.BaseMutationOptions<
    DeleteEyePromotionAddonProductItemMutation,
    DeleteEyePromotionAddonProductItemMutationVariables
  >;
export const EyePromotionsDocument = gql`
  query EyePromotions(
    $filters: EyePromotionSearchInput!
    $offset: Int
    $limit: Int
  ) {
    eyePromotions(filters: $filters, offset: $offset, limit: $limit) {
      count
      pageInfo {
        hasNextPage
      }
      rows {
        id
        name
        regionId
        currencyId
        region {
          id
          name
        }
        company {
          id
          name
        }
        eyePromotionAddonProductItems {
          id
          eyePromotionId
          eyeProductItemTypeId
          qty
          isOptional
          material {
            id
            code
            name
            model
            spec
            unit
          }
        }
        date1
        date2
        isAddon
        createdAt
        updatedAt
        description
        eyeProducts {
          id
          eyeProductType {
            id
            regionId
            name
            createdAt
            updatedAt
          }
          name
          salePrice
          dealerPrice
          status
          description
          currencyId
          model
          regionId
          brand
          createdAt
          updatedAt
          eyeProductTypeId
          eyeProductItems {
            id
            eyeProductId
            eyeProductItemTypeId
            qty
            isOptional
            createdAt
            materialName
            material {
              id
              code
              name
              model
              spec
              unit
            }
            materialSellingUnit
          }
          attachments {
            id
            url
            name
            size
            s3Key
          }
          standardDeliveryDays
          productUsers {
            id
            name
          }
          eyePromotions {
            id
            name
            isAddon
            discountRate
            discountAmount
            createdAt
            date1
            regionId
            updatedAt
            currencyId
            eyePromotionAddonProductItems {
              id
              qty
              isOptional
              material {
                id
                unit
              }
            }
          }
        }
        discountRate
        discountAmount
        createdUserId
        updatedUserId
        createdUser {
          id
          name
          code
        }
      }
    }
  }
`;

/**
 * __useEyePromotionsQuery__
 *
 * To run a query within a React component, call `useEyePromotionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useEyePromotionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEyePromotionsQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useEyePromotionsQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    EyePromotionsQuery,
    EyePromotionsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    EyePromotionsQuery,
    EyePromotionsQueryVariables
  >(EyePromotionsDocument, options);
}
export function useEyePromotionsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    EyePromotionsQuery,
    EyePromotionsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    EyePromotionsQuery,
    EyePromotionsQueryVariables
  >(EyePromotionsDocument, options);
}
export type EyePromotionsQueryHookResult = ReturnType<
  typeof useEyePromotionsQuery
>;
export type EyePromotionsLazyQueryHookResult = ReturnType<
  typeof useEyePromotionsLazyQuery
>;
export type EyePromotionsQueryResult = ApolloReactCommon.QueryResult<
  EyePromotionsQuery,
  EyePromotionsQueryVariables
>;
export const CreateEyeQuotationOrderDocument = gql`
  mutation createEyeQuotationOrder($input: EyeQuotationOrderCreateInput!) {
    createEyeQuotationOrder(input: $input) {
      id
      code
      regionId
      eyeServiceOrderId
      customerId
      invoicingCustomerId
      contactPerson
      contactPhone
      deptId
      address
    }
  }
`;
export type CreateEyeQuotationOrderMutationFn =
  ApolloReactCommon.MutationFunction<
    CreateEyeQuotationOrderMutation,
    CreateEyeQuotationOrderMutationVariables
  >;

/**
 * __useCreateEyeQuotationOrderMutation__
 *
 * To run a mutation, you first call `useCreateEyeQuotationOrderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateEyeQuotationOrderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createEyeQuotationOrderMutation, { data, loading, error }] = useCreateEyeQuotationOrderMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateEyeQuotationOrderMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    CreateEyeQuotationOrderMutation,
    CreateEyeQuotationOrderMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    CreateEyeQuotationOrderMutation,
    CreateEyeQuotationOrderMutationVariables
  >(CreateEyeQuotationOrderDocument, options);
}
export type CreateEyeQuotationOrderMutationHookResult = ReturnType<
  typeof useCreateEyeQuotationOrderMutation
>;
export type CreateEyeQuotationOrderMutationResult =
  ApolloReactCommon.MutationResult<CreateEyeQuotationOrderMutation>;
export type CreateEyeQuotationOrderMutationOptions =
  ApolloReactCommon.BaseMutationOptions<
    CreateEyeQuotationOrderMutation,
    CreateEyeQuotationOrderMutationVariables
  >;
export const RequestEyeQuotationOrderOfficialSealDocument = gql`
  mutation RequestEyeQuotationOrderOfficialSeal(
    $input: EyeQuotationOrderOfficialSealInput!
  ) {
    requestEyeQuotationOrderOfficialSeal(input: $input)
  }
`;
export type RequestEyeQuotationOrderOfficialSealMutationFn =
  ApolloReactCommon.MutationFunction<
    RequestEyeQuotationOrderOfficialSealMutation,
    RequestEyeQuotationOrderOfficialSealMutationVariables
  >;

/**
 * __useRequestEyeQuotationOrderOfficialSealMutation__
 *
 * To run a mutation, you first call `useRequestEyeQuotationOrderOfficialSealMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRequestEyeQuotationOrderOfficialSealMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [requestEyeQuotationOrderOfficialSealMutation, { data, loading, error }] = useRequestEyeQuotationOrderOfficialSealMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRequestEyeQuotationOrderOfficialSealMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    RequestEyeQuotationOrderOfficialSealMutation,
    RequestEyeQuotationOrderOfficialSealMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    RequestEyeQuotationOrderOfficialSealMutation,
    RequestEyeQuotationOrderOfficialSealMutationVariables
  >(RequestEyeQuotationOrderOfficialSealDocument, options);
}
export type RequestEyeQuotationOrderOfficialSealMutationHookResult = ReturnType<
  typeof useRequestEyeQuotationOrderOfficialSealMutation
>;
export type RequestEyeQuotationOrderOfficialSealMutationResult =
  ApolloReactCommon.MutationResult<RequestEyeQuotationOrderOfficialSealMutation>;
export type RequestEyeQuotationOrderOfficialSealMutationOptions =
  ApolloReactCommon.BaseMutationOptions<
    RequestEyeQuotationOrderOfficialSealMutation,
    RequestEyeQuotationOrderOfficialSealMutationVariables
  >;
export const EyeQuotationOrdersDocument = gql`
  query eyeQuotationOrders(
    $limit: Int
    $offset: Int
    $filters: EyeQuotationOrderSearchInput!
  ) {
    eyeQuotationOrders(limit: $limit, offset: $offset, filters: $filters) {
      count
      rows {
        province {
          id
          name
        }
        city {
          id
          name
        }
        district {
          id
          name
        }
        eyeQuotationOrderBusinesses {
          business {
            id
            code
            title
            businessesUsers {
              id
              user {
                id
                name
              }
              salesTeam {
                id
                name
              }
            }
          }
        }
        creditPeriod {
          id
          name
        }
        eyeQuotationOrderType {
          code
          name
          id
        }
        bpmUrl
        localStandardAmount
        officialSealBpmUrl
        officialSealStatus
        id
        code
        userId
        customerId
        invoicingCustomerId
        eyeServiceOrderId
        bpmInstanceId
        exchangeRate
        warrantyMonths
        warrantyPeriodTypeId
        expectDeliveryDate
        expectPaymentDate
        warrantyBuyType
        orderCode
        realAmount
        untaxedAmount
        contactPerson
        contactPhone
        regionId
        status
        address
        currencyId
        standardAmount
        discountRate
        discountAmount
        extraDiscountAmount
        realAmount
        realDiscountAmount
        realDiscountRate
        taxRate
        commissionAmount
        discountRateWithoutCommission
        status
        description
        createdAt
        status
        createdAt
        region {
          id
          name
          localCurrency {
            id
            name
          }
        }
        eyeQuotationOrderProductItems {
          id
          materialId
          materialCode
          materialModel
          materialSpec
          materialUnit
          materialName
          qty
          unitPrice
          unitPriceVat
          eyeQuotationOrderProductId
          eyeQuotationOrderPromotionId
          discountedSellingPrice
        }
        eyeServiceOrder {
          id
          code
        }
        customer {
          id
          code
          name
        }
        currency {
          id
          name
          code
        }
        dept {
          id
          code
          name
          company {
            id
            name
          }
        }
        invoicingCustomer {
          id
          code
          name
        }
        eyeServiceOrder {
          eyeWarrantyContract {
            approvalCode
          }
        }
        createdUser {
          id
          code
          name
        }
        user {
          id
          code
          name
        }
        eyeQuotationOrderProducts {
          id
          name
          qty
          model
          brand
          unitPriceVat
          eyeProduct {
            id
            eyeProductType {
              id
              regionId
              name
              createdAt
              updatedAt
            }
            name
            salePrice
            status
            description
            currencyId
            model
            regionId
            brand
            createdAt
            updatedAt
            eyeProductTypeId
            eyeProductItems {
              id
              eyeProductId
              eyeProductItemTypeId
              qty
              isOptional
              createdAt
              material {
                id
                code
                name
                model
                spec
                unit
              }
            }
            eyePromotions {
              id
              name
              isAddon
              discountRate
              discountAmount
              createdAt
              date1
              regionId
              updatedAt
              eyePromotionAddonProductItems {
                id
                qty
                isOptional
                material {
                  id
                  unit
                }
              }
            }
          }
          items {
            id
            materialName
            materialCode
            unitPriceVat
            materialUnit
            materialSellingPrice
            discountedSellingPrice
            qty
            unitQty
          }
        }
        eyeQuotationOrderPromotions {
          id
          name
          eyePromotionId
          discountRate
          discountAmount
          currencyId
          eyePromotion {
            id
            name
            isAddon
            discountRate
            discountAmount
            currencyId
            eyePromotionAddonProductItems {
              id
              qty
              isOptional
              material {
                id
                name
                unit
              }
            }
          }
          items {
            id
            materialName
            materialCode
            unitPriceVat
            materialUnit
            qty
          }
        }
        eyeQuotationOrderWarrantyItems {
          id
          materialId
          materialCode
          materialModel
          materialUnit
          materialName
          sn
          udi
          qty
          memo
        }
        user {
          name
        }
        eyeWarrantyPeriodType {
          id
          code
          name
        }
        biddingPrice
      }
    }
  }
`;

/**
 * __useEyeQuotationOrdersQuery__
 *
 * To run a query within a React component, call `useEyeQuotationOrdersQuery` and pass it any options that fit your needs.
 * When your component renders, `useEyeQuotationOrdersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEyeQuotationOrdersQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function useEyeQuotationOrdersQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    EyeQuotationOrdersQuery,
    EyeQuotationOrdersQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    EyeQuotationOrdersQuery,
    EyeQuotationOrdersQueryVariables
  >(EyeQuotationOrdersDocument, options);
}
export function useEyeQuotationOrdersLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    EyeQuotationOrdersQuery,
    EyeQuotationOrdersQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    EyeQuotationOrdersQuery,
    EyeQuotationOrdersQueryVariables
  >(EyeQuotationOrdersDocument, options);
}
export type EyeQuotationOrdersQueryHookResult = ReturnType<
  typeof useEyeQuotationOrdersQuery
>;
export type EyeQuotationOrdersLazyQueryHookResult = ReturnType<
  typeof useEyeQuotationOrdersLazyQuery
>;
export type EyeQuotationOrdersQueryResult = ApolloReactCommon.QueryResult<
  EyeQuotationOrdersQuery,
  EyeQuotationOrdersQueryVariables
>;
export const ExchangeRatesDocument = gql`
  query exchangeRates(
    $limit: Int
    $offset: Int
    $filters: ExchangeRateSearchInput!
  ) {
    exchangeRates(limit: $limit, offset: $offset, filters: $filters) {
      count
      rows {
        id
        rate
        currency1Id
        currency2Id
      }
    }
  }
`;

/**
 * __useExchangeRatesQuery__
 *
 * To run a query within a React component, call `useExchangeRatesQuery` and pass it any options that fit your needs.
 * When your component renders, `useExchangeRatesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useExchangeRatesQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function useExchangeRatesQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    ExchangeRatesQuery,
    ExchangeRatesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    ExchangeRatesQuery,
    ExchangeRatesQueryVariables
  >(ExchangeRatesDocument, options);
}
export function useExchangeRatesLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    ExchangeRatesQuery,
    ExchangeRatesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    ExchangeRatesQuery,
    ExchangeRatesQueryVariables
  >(ExchangeRatesDocument, options);
}
export type ExchangeRatesQueryHookResult = ReturnType<
  typeof useExchangeRatesQuery
>;
export type ExchangeRatesLazyQueryHookResult = ReturnType<
  typeof useExchangeRatesLazyQuery
>;
export type ExchangeRatesQueryResult = ApolloReactCommon.QueryResult<
  ExchangeRatesQuery,
  ExchangeRatesQueryVariables
>;
export const EyeQuotationOrderTypesDocument = gql`
  query eyeQuotationOrderTypes($filters: EyeQuotationOrderTypeSearchInput!) {
    eyeQuotationOrderTypes(filters: $filters) {
      rows {
        code
        id
        name
      }
    }
  }
`;

/**
 * __useEyeQuotationOrderTypesQuery__
 *
 * To run a query within a React component, call `useEyeQuotationOrderTypesQuery` and pass it any options that fit your needs.
 * When your component renders, `useEyeQuotationOrderTypesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEyeQuotationOrderTypesQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function useEyeQuotationOrderTypesQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    EyeQuotationOrderTypesQuery,
    EyeQuotationOrderTypesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    EyeQuotationOrderTypesQuery,
    EyeQuotationOrderTypesQueryVariables
  >(EyeQuotationOrderTypesDocument, options);
}
export function useEyeQuotationOrderTypesLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    EyeQuotationOrderTypesQuery,
    EyeQuotationOrderTypesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    EyeQuotationOrderTypesQuery,
    EyeQuotationOrderTypesQueryVariables
  >(EyeQuotationOrderTypesDocument, options);
}
export type EyeQuotationOrderTypesQueryHookResult = ReturnType<
  typeof useEyeQuotationOrderTypesQuery
>;
export type EyeQuotationOrderTypesLazyQueryHookResult = ReturnType<
  typeof useEyeQuotationOrderTypesLazyQuery
>;
export type EyeQuotationOrderTypesQueryResult = ApolloReactCommon.QueryResult<
  EyeQuotationOrderTypesQuery,
  EyeQuotationOrderTypesQueryVariables
>;
export const CreateEyeServiceOrderDocument = gql`
  mutation CreateEyeServiceOrder($input: EyeServiceOrderCreateInput!) {
    createEyeServiceOrder(input: $input) {
      id
      code
    }
  }
`;
export type CreateEyeServiceOrderMutationFn =
  ApolloReactCommon.MutationFunction<
    CreateEyeServiceOrderMutation,
    CreateEyeServiceOrderMutationVariables
  >;

/**
 * __useCreateEyeServiceOrderMutation__
 *
 * To run a mutation, you first call `useCreateEyeServiceOrderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateEyeServiceOrderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createEyeServiceOrderMutation, { data, loading, error }] = useCreateEyeServiceOrderMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateEyeServiceOrderMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    CreateEyeServiceOrderMutation,
    CreateEyeServiceOrderMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    CreateEyeServiceOrderMutation,
    CreateEyeServiceOrderMutationVariables
  >(CreateEyeServiceOrderDocument, options);
}
export type CreateEyeServiceOrderMutationHookResult = ReturnType<
  typeof useCreateEyeServiceOrderMutation
>;
export type CreateEyeServiceOrderMutationResult =
  ApolloReactCommon.MutationResult<CreateEyeServiceOrderMutation>;
export type CreateEyeServiceOrderMutationOptions =
  ApolloReactCommon.BaseMutationOptions<
    CreateEyeServiceOrderMutation,
    CreateEyeServiceOrderMutationVariables
  >;
export const UpdateEyeServiceOrderDocument = gql`
  mutation UpdateEyeServiceOrder($input: EyeServiceOrderUpdateInput!) {
    updateEyeServiceOrder(input: $input) {
      id
    }
  }
`;
export type UpdateEyeServiceOrderMutationFn =
  ApolloReactCommon.MutationFunction<
    UpdateEyeServiceOrderMutation,
    UpdateEyeServiceOrderMutationVariables
  >;

/**
 * __useUpdateEyeServiceOrderMutation__
 *
 * To run a mutation, you first call `useUpdateEyeServiceOrderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateEyeServiceOrderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateEyeServiceOrderMutation, { data, loading, error }] = useUpdateEyeServiceOrderMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateEyeServiceOrderMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    UpdateEyeServiceOrderMutation,
    UpdateEyeServiceOrderMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    UpdateEyeServiceOrderMutation,
    UpdateEyeServiceOrderMutationVariables
  >(UpdateEyeServiceOrderDocument, options);
}
export type UpdateEyeServiceOrderMutationHookResult = ReturnType<
  typeof useUpdateEyeServiceOrderMutation
>;
export type UpdateEyeServiceOrderMutationResult =
  ApolloReactCommon.MutationResult<UpdateEyeServiceOrderMutation>;
export type UpdateEyeServiceOrderMutationOptions =
  ApolloReactCommon.BaseMutationOptions<
    UpdateEyeServiceOrderMutation,
    UpdateEyeServiceOrderMutationVariables
  >;
export const DeleteEyeServiceOrderDocument = gql`
  mutation DeleteEyeServiceOrder($deleteEyeServiceOrderId: ID!) {
    deleteEyeServiceOrder(id: $deleteEyeServiceOrderId)
  }
`;
export type DeleteEyeServiceOrderMutationFn =
  ApolloReactCommon.MutationFunction<
    DeleteEyeServiceOrderMutation,
    DeleteEyeServiceOrderMutationVariables
  >;

/**
 * __useDeleteEyeServiceOrderMutation__
 *
 * To run a mutation, you first call `useDeleteEyeServiceOrderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteEyeServiceOrderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteEyeServiceOrderMutation, { data, loading, error }] = useDeleteEyeServiceOrderMutation({
 *   variables: {
 *      deleteEyeServiceOrderId: // value for 'deleteEyeServiceOrderId'
 *   },
 * });
 */
export function useDeleteEyeServiceOrderMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    DeleteEyeServiceOrderMutation,
    DeleteEyeServiceOrderMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    DeleteEyeServiceOrderMutation,
    DeleteEyeServiceOrderMutationVariables
  >(DeleteEyeServiceOrderDocument, options);
}
export type DeleteEyeServiceOrderMutationHookResult = ReturnType<
  typeof useDeleteEyeServiceOrderMutation
>;
export type DeleteEyeServiceOrderMutationResult =
  ApolloReactCommon.MutationResult<DeleteEyeServiceOrderMutation>;
export type DeleteEyeServiceOrderMutationOptions =
  ApolloReactCommon.BaseMutationOptions<
    DeleteEyeServiceOrderMutation,
    DeleteEyeServiceOrderMutationVariables
  >;
export const CreateEyeServiceOrderItemDocument = gql`
  mutation CreateEyeServiceOrderItem($input: EyeServiceOrderItemCreateInput!) {
    createEyeServiceOrderItem(input: $input) {
      id
    }
  }
`;
export type CreateEyeServiceOrderItemMutationFn =
  ApolloReactCommon.MutationFunction<
    CreateEyeServiceOrderItemMutation,
    CreateEyeServiceOrderItemMutationVariables
  >;

/**
 * __useCreateEyeServiceOrderItemMutation__
 *
 * To run a mutation, you first call `useCreateEyeServiceOrderItemMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateEyeServiceOrderItemMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createEyeServiceOrderItemMutation, { data, loading, error }] = useCreateEyeServiceOrderItemMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateEyeServiceOrderItemMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    CreateEyeServiceOrderItemMutation,
    CreateEyeServiceOrderItemMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    CreateEyeServiceOrderItemMutation,
    CreateEyeServiceOrderItemMutationVariables
  >(CreateEyeServiceOrderItemDocument, options);
}
export type CreateEyeServiceOrderItemMutationHookResult = ReturnType<
  typeof useCreateEyeServiceOrderItemMutation
>;
export type CreateEyeServiceOrderItemMutationResult =
  ApolloReactCommon.MutationResult<CreateEyeServiceOrderItemMutation>;
export type CreateEyeServiceOrderItemMutationOptions =
  ApolloReactCommon.BaseMutationOptions<
    CreateEyeServiceOrderItemMutation,
    CreateEyeServiceOrderItemMutationVariables
  >;
export const UpdateEyeServiceOrderItemDocument = gql`
  mutation UpdateEyeServiceOrderItem($input: EyeServiceOrderItemUpdateInput!) {
    updateEyeServiceOrderItem(input: $input) {
      id
    }
  }
`;
export type UpdateEyeServiceOrderItemMutationFn =
  ApolloReactCommon.MutationFunction<
    UpdateEyeServiceOrderItemMutation,
    UpdateEyeServiceOrderItemMutationVariables
  >;

/**
 * __useUpdateEyeServiceOrderItemMutation__
 *
 * To run a mutation, you first call `useUpdateEyeServiceOrderItemMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateEyeServiceOrderItemMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateEyeServiceOrderItemMutation, { data, loading, error }] = useUpdateEyeServiceOrderItemMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateEyeServiceOrderItemMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    UpdateEyeServiceOrderItemMutation,
    UpdateEyeServiceOrderItemMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    UpdateEyeServiceOrderItemMutation,
    UpdateEyeServiceOrderItemMutationVariables
  >(UpdateEyeServiceOrderItemDocument, options);
}
export type UpdateEyeServiceOrderItemMutationHookResult = ReturnType<
  typeof useUpdateEyeServiceOrderItemMutation
>;
export type UpdateEyeServiceOrderItemMutationResult =
  ApolloReactCommon.MutationResult<UpdateEyeServiceOrderItemMutation>;
export type UpdateEyeServiceOrderItemMutationOptions =
  ApolloReactCommon.BaseMutationOptions<
    UpdateEyeServiceOrderItemMutation,
    UpdateEyeServiceOrderItemMutationVariables
  >;
export const DeleteEyeServiceOrderItemDocument = gql`
  mutation DeleteEyeServiceOrderItem($deleteEyeServiceOrderItemId: ID!) {
    deleteEyeServiceOrderItem(id: $deleteEyeServiceOrderItemId)
  }
`;
export type DeleteEyeServiceOrderItemMutationFn =
  ApolloReactCommon.MutationFunction<
    DeleteEyeServiceOrderItemMutation,
    DeleteEyeServiceOrderItemMutationVariables
  >;

/**
 * __useDeleteEyeServiceOrderItemMutation__
 *
 * To run a mutation, you first call `useDeleteEyeServiceOrderItemMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteEyeServiceOrderItemMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteEyeServiceOrderItemMutation, { data, loading, error }] = useDeleteEyeServiceOrderItemMutation({
 *   variables: {
 *      deleteEyeServiceOrderItemId: // value for 'deleteEyeServiceOrderItemId'
 *   },
 * });
 */
export function useDeleteEyeServiceOrderItemMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    DeleteEyeServiceOrderItemMutation,
    DeleteEyeServiceOrderItemMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    DeleteEyeServiceOrderItemMutation,
    DeleteEyeServiceOrderItemMutationVariables
  >(DeleteEyeServiceOrderItemDocument, options);
}
export type DeleteEyeServiceOrderItemMutationHookResult = ReturnType<
  typeof useDeleteEyeServiceOrderItemMutation
>;
export type DeleteEyeServiceOrderItemMutationResult =
  ApolloReactCommon.MutationResult<DeleteEyeServiceOrderItemMutation>;
export type DeleteEyeServiceOrderItemMutationOptions =
  ApolloReactCommon.BaseMutationOptions<
    DeleteEyeServiceOrderItemMutation,
    DeleteEyeServiceOrderItemMutationVariables
  >;
export const CreateEyeServiceOrderAttachFileDocument = gql`
  mutation CreateEyeServiceOrderAttachFile(
    $input: EyeServiceOrderAttachFileCreateInput!
  ) {
    createEyeServiceOrderAttachFile(input: $input) {
      id
    }
  }
`;
export type CreateEyeServiceOrderAttachFileMutationFn =
  ApolloReactCommon.MutationFunction<
    CreateEyeServiceOrderAttachFileMutation,
    CreateEyeServiceOrderAttachFileMutationVariables
  >;

/**
 * __useCreateEyeServiceOrderAttachFileMutation__
 *
 * To run a mutation, you first call `useCreateEyeServiceOrderAttachFileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateEyeServiceOrderAttachFileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createEyeServiceOrderAttachFileMutation, { data, loading, error }] = useCreateEyeServiceOrderAttachFileMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateEyeServiceOrderAttachFileMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    CreateEyeServiceOrderAttachFileMutation,
    CreateEyeServiceOrderAttachFileMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    CreateEyeServiceOrderAttachFileMutation,
    CreateEyeServiceOrderAttachFileMutationVariables
  >(CreateEyeServiceOrderAttachFileDocument, options);
}
export type CreateEyeServiceOrderAttachFileMutationHookResult = ReturnType<
  typeof useCreateEyeServiceOrderAttachFileMutation
>;
export type CreateEyeServiceOrderAttachFileMutationResult =
  ApolloReactCommon.MutationResult<CreateEyeServiceOrderAttachFileMutation>;
export type CreateEyeServiceOrderAttachFileMutationOptions =
  ApolloReactCommon.BaseMutationOptions<
    CreateEyeServiceOrderAttachFileMutation,
    CreateEyeServiceOrderAttachFileMutationVariables
  >;
export const DeleteEyeServiceOrderAttachFileDocument = gql`
  mutation DeleteEyeServiceOrderAttachFile(
    $deleteEyeServiceOrderAttachFileId: ID!
  ) {
    deleteEyeServiceOrderAttachFile(id: $deleteEyeServiceOrderAttachFileId)
  }
`;
export type DeleteEyeServiceOrderAttachFileMutationFn =
  ApolloReactCommon.MutationFunction<
    DeleteEyeServiceOrderAttachFileMutation,
    DeleteEyeServiceOrderAttachFileMutationVariables
  >;

/**
 * __useDeleteEyeServiceOrderAttachFileMutation__
 *
 * To run a mutation, you first call `useDeleteEyeServiceOrderAttachFileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteEyeServiceOrderAttachFileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteEyeServiceOrderAttachFileMutation, { data, loading, error }] = useDeleteEyeServiceOrderAttachFileMutation({
 *   variables: {
 *      deleteEyeServiceOrderAttachFileId: // value for 'deleteEyeServiceOrderAttachFileId'
 *   },
 * });
 */
export function useDeleteEyeServiceOrderAttachFileMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    DeleteEyeServiceOrderAttachFileMutation,
    DeleteEyeServiceOrderAttachFileMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    DeleteEyeServiceOrderAttachFileMutation,
    DeleteEyeServiceOrderAttachFileMutationVariables
  >(DeleteEyeServiceOrderAttachFileDocument, options);
}
export type DeleteEyeServiceOrderAttachFileMutationHookResult = ReturnType<
  typeof useDeleteEyeServiceOrderAttachFileMutation
>;
export type DeleteEyeServiceOrderAttachFileMutationResult =
  ApolloReactCommon.MutationResult<DeleteEyeServiceOrderAttachFileMutation>;
export type DeleteEyeServiceOrderAttachFileMutationOptions =
  ApolloReactCommon.BaseMutationOptions<
    DeleteEyeServiceOrderAttachFileMutation,
    DeleteEyeServiceOrderAttachFileMutationVariables
  >;
export const CreateEyeFixedAssetRentalRecordDocument = gql`
  mutation createEyeFixedAssetRentalRecord(
    $input: EyeFixedAssetRentalRecordCreateInput!
  ) {
    createEyeFixedAssetRentalRecord(input: $input) {
      id
      date1
      date2
      status
      remark
      regionId
      company {
        id
        name
      }
      eyeFixedAssets {
        region {
          id
          name
        }
        id
        name
        isBooking
        status
        memo
        dept {
          id
          code
          name
        }
        createdUser {
          id
        }
        updatedUser {
          id
        }
        items {
          materialId
          materialCode
          materialName
          materialModel
          materialSpec
          materialUnit
          udi
          sn
          memo
        }
        createdAt
        updatedAt
        eyeFixedAssetRentalRecords {
          id
          eyeServiceOrderId
          date1
          date2
          installDate
          dismantleDate
          status
          remark
          eyeFixedAssets {
            id
            name
          }
          region {
            id
            name
          }
        }
      }
      region {
        id
        name
      }
    }
  }
`;
export type CreateEyeFixedAssetRentalRecordMutationFn =
  ApolloReactCommon.MutationFunction<
    CreateEyeFixedAssetRentalRecordMutation,
    CreateEyeFixedAssetRentalRecordMutationVariables
  >;

/**
 * __useCreateEyeFixedAssetRentalRecordMutation__
 *
 * To run a mutation, you first call `useCreateEyeFixedAssetRentalRecordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateEyeFixedAssetRentalRecordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createEyeFixedAssetRentalRecordMutation, { data, loading, error }] = useCreateEyeFixedAssetRentalRecordMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateEyeFixedAssetRentalRecordMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    CreateEyeFixedAssetRentalRecordMutation,
    CreateEyeFixedAssetRentalRecordMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    CreateEyeFixedAssetRentalRecordMutation,
    CreateEyeFixedAssetRentalRecordMutationVariables
  >(CreateEyeFixedAssetRentalRecordDocument, options);
}
export type CreateEyeFixedAssetRentalRecordMutationHookResult = ReturnType<
  typeof useCreateEyeFixedAssetRentalRecordMutation
>;
export type CreateEyeFixedAssetRentalRecordMutationResult =
  ApolloReactCommon.MutationResult<CreateEyeFixedAssetRentalRecordMutation>;
export type CreateEyeFixedAssetRentalRecordMutationOptions =
  ApolloReactCommon.BaseMutationOptions<
    CreateEyeFixedAssetRentalRecordMutation,
    CreateEyeFixedAssetRentalRecordMutationVariables
  >;
export const UpdateEyeFixedAssetRentalRecordDocument = gql`
  mutation updateEyeFixedAssetRentalRecord(
    $input: EyeFixedAssetRentalRecordUpdateInput!
  ) {
    updateEyeFixedAssetRentalRecord(input: $input) {
      id
      date1
      date2
      status
      remark
      regionId
      company {
        id
        name
      }
      eyeFixedAssets {
        region {
          id
          name
        }
        id
        name
        isBooking
        status
        memo
        dept {
          id
          code
          name
        }
        createdUser {
          id
        }
        updatedUser {
          id
        }
        items {
          materialId
          materialCode
          materialName
          materialModel
          materialSpec
          materialUnit
          udi
          sn
          memo
        }
        createdAt
        updatedAt
        eyeFixedAssetRentalRecords {
          id
          eyeServiceOrderId
          date1
          date2
          installDate
          dismantleDate
          status
          remark
          eyeFixedAssets {
            id
            name
          }
          region {
            id
            name
          }
        }
      }
      region {
        id
        name
      }
    }
  }
`;
export type UpdateEyeFixedAssetRentalRecordMutationFn =
  ApolloReactCommon.MutationFunction<
    UpdateEyeFixedAssetRentalRecordMutation,
    UpdateEyeFixedAssetRentalRecordMutationVariables
  >;

/**
 * __useUpdateEyeFixedAssetRentalRecordMutation__
 *
 * To run a mutation, you first call `useUpdateEyeFixedAssetRentalRecordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateEyeFixedAssetRentalRecordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateEyeFixedAssetRentalRecordMutation, { data, loading, error }] = useUpdateEyeFixedAssetRentalRecordMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateEyeFixedAssetRentalRecordMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    UpdateEyeFixedAssetRentalRecordMutation,
    UpdateEyeFixedAssetRentalRecordMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    UpdateEyeFixedAssetRentalRecordMutation,
    UpdateEyeFixedAssetRentalRecordMutationVariables
  >(UpdateEyeFixedAssetRentalRecordDocument, options);
}
export type UpdateEyeFixedAssetRentalRecordMutationHookResult = ReturnType<
  typeof useUpdateEyeFixedAssetRentalRecordMutation
>;
export type UpdateEyeFixedAssetRentalRecordMutationResult =
  ApolloReactCommon.MutationResult<UpdateEyeFixedAssetRentalRecordMutation>;
export type UpdateEyeFixedAssetRentalRecordMutationOptions =
  ApolloReactCommon.BaseMutationOptions<
    UpdateEyeFixedAssetRentalRecordMutation,
    UpdateEyeFixedAssetRentalRecordMutationVariables
  >;
export const UpdateEyeServiceOrderApprovalStatusDocument = gql`
  mutation UpdateEyeServiceOrderApprovalStatus(
    $input: EyeServiceOrderApprovalStatusUpdateInput!
  ) {
    updateEyeServiceOrderApprovalStatus(input: $input) {
      id
    }
  }
`;
export type UpdateEyeServiceOrderApprovalStatusMutationFn =
  ApolloReactCommon.MutationFunction<
    UpdateEyeServiceOrderApprovalStatusMutation,
    UpdateEyeServiceOrderApprovalStatusMutationVariables
  >;

/**
 * __useUpdateEyeServiceOrderApprovalStatusMutation__
 *
 * To run a mutation, you first call `useUpdateEyeServiceOrderApprovalStatusMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateEyeServiceOrderApprovalStatusMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateEyeServiceOrderApprovalStatusMutation, { data, loading, error }] = useUpdateEyeServiceOrderApprovalStatusMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateEyeServiceOrderApprovalStatusMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    UpdateEyeServiceOrderApprovalStatusMutation,
    UpdateEyeServiceOrderApprovalStatusMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    UpdateEyeServiceOrderApprovalStatusMutation,
    UpdateEyeServiceOrderApprovalStatusMutationVariables
  >(UpdateEyeServiceOrderApprovalStatusDocument, options);
}
export type UpdateEyeServiceOrderApprovalStatusMutationHookResult = ReturnType<
  typeof useUpdateEyeServiceOrderApprovalStatusMutation
>;
export type UpdateEyeServiceOrderApprovalStatusMutationResult =
  ApolloReactCommon.MutationResult<UpdateEyeServiceOrderApprovalStatusMutation>;
export type UpdateEyeServiceOrderApprovalStatusMutationOptions =
  ApolloReactCommon.BaseMutationOptions<
    UpdateEyeServiceOrderApprovalStatusMutation,
    UpdateEyeServiceOrderApprovalStatusMutationVariables
  >;
export const RequestEyeFixedAssetRentalExtensionDocument = gql`
  mutation RequestEyeFixedAssetRentalExtension(
    $input: EyeServiceOrderRequestExtensionInput!
  ) {
    requestEyeFixedAssetRentalExtension(input: $input)
  }
`;
export type RequestEyeFixedAssetRentalExtensionMutationFn =
  ApolloReactCommon.MutationFunction<
    RequestEyeFixedAssetRentalExtensionMutation,
    RequestEyeFixedAssetRentalExtensionMutationVariables
  >;

/**
 * __useRequestEyeFixedAssetRentalExtensionMutation__
 *
 * To run a mutation, you first call `useRequestEyeFixedAssetRentalExtensionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRequestEyeFixedAssetRentalExtensionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [requestEyeFixedAssetRentalExtensionMutation, { data, loading, error }] = useRequestEyeFixedAssetRentalExtensionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRequestEyeFixedAssetRentalExtensionMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    RequestEyeFixedAssetRentalExtensionMutation,
    RequestEyeFixedAssetRentalExtensionMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    RequestEyeFixedAssetRentalExtensionMutation,
    RequestEyeFixedAssetRentalExtensionMutationVariables
  >(RequestEyeFixedAssetRentalExtensionDocument, options);
}
export type RequestEyeFixedAssetRentalExtensionMutationHookResult = ReturnType<
  typeof useRequestEyeFixedAssetRentalExtensionMutation
>;
export type RequestEyeFixedAssetRentalExtensionMutationResult =
  ApolloReactCommon.MutationResult<RequestEyeFixedAssetRentalExtensionMutation>;
export type RequestEyeFixedAssetRentalExtensionMutationOptions =
  ApolloReactCommon.BaseMutationOptions<
    RequestEyeFixedAssetRentalExtensionMutation,
    RequestEyeFixedAssetRentalExtensionMutationVariables
  >;
export const EyeServiceOrdersDocument = gql`
  query eyeServiceOrders(
    $limit: Int
    $offset: Int
    $filters: EyeServiceOrderSearchInput!
  ) {
    eyeServiceOrders(limit: $limit, offset: $offset, filters: $filters) {
      count
      rows {
        id
        priority
        code
        region {
          id
          name
        }
        businessId
        createdUser {
          id
          name
          createdAt
          updatedAt
        }
        updatedUser {
          id
          name
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
        eyeServiceOrderType {
          id
          name
          code
        }
        contactPerson
        contactPhone
        address
        estimatedDate
        regionId
        customer {
          id
          code
          name
          businessCode
          medicalCode
        }
        status
        approvalStatus
        company {
          id
          name
        }
        assigneeDept {
          id
          code
          name
          company {
            id
            name
          }
        }
        assigneeUser {
          id
          name
        }
        approverUser {
          id
          name
        }
        eyeWarrantyContract {
          id
          code
        }
        eyeServiceOrderWorkDiaries {
          id
          date
          hours
          status
          remark
          users {
            id
            name
          }
          eyeServiceOrder {
            code
            status
            eyeServiceOrderType {
              name
            }
            customer {
              id
              name
            }
            assigneeDept {
              id
              name
              company {
                id
                name
              }
            }
          }
        }
        eyeQuotationOrders {
          id
          code
          customer {
            id
            code
            name
          }
          currency {
            id
            name
          }
          dept {
            id
            code
            name
          }
          invoicingCustomer {
            id
            code
            name
          }
          eyeServiceOrder {
            eyeWarrantyContract {
              approvalCode
            }
          }
          eyeQuotationOrderBusinesses {
            business {
              id
              code
              title
            }
          }
          orderCode
          realAmount
          untaxedAmount
          contactPerson
          contactPhone
          address
          user {
            id
            name
          }
          status
          createdAt
        }
        eyeFixedAssetRentalRecords {
          id
          date1
          date2
          installDate
          dismantleDate
          status
          remark
          regionId
          eyeFixedAssets {
            region {
              id
              name
            }
            id
            name
            status
            memo
            isBooking
            dept {
              id
              code
              name
            }
            createdUser {
              id
            }
            updatedUser {
              id
            }
            items {
              materialId
              materialCode
              materialName
              materialModel
              materialSpec
              materialUnit
              udi
              sn
              memo
            }
            createdAt
            updatedAt
            currentLocation
            eyeFixedAssetRentalRecords {
              id
              eyeServiceOrderId
              date1
              date2
              installDate
              dismantleDate
              status
              remark
              eyeServiceOrder {
                id
                code
                estimatedDate
                assigneeUser {
                  id
                  code
                  name
                }
              }
              eyeFixedAssets {
                id
                name
              }
              region {
                id
                name
              }
            }
          }
          region {
            id
            name
          }
          company {
            id
            name
          }
        }
        costCenterId
        dispatcherUserId
        memo
        description
        eyeFixedAssetRentalGoalId
        eyeFixedAssetRentalGoal {
          id
          code
          name
        }
        bpmUrl
        extension {
          extendDate1
          extendDate2
          bpmUrl
          approvalStatus
        }
        extensionCount
        user {
          id
          code
          name
          dept {
            id
            name
          }
        }
      }
    }
  }
`;

/**
 * __useEyeServiceOrdersQuery__
 *
 * To run a query within a React component, call `useEyeServiceOrdersQuery` and pass it any options that fit your needs.
 * When your component renders, `useEyeServiceOrdersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEyeServiceOrdersQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function useEyeServiceOrdersQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    EyeServiceOrdersQuery,
    EyeServiceOrdersQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    EyeServiceOrdersQuery,
    EyeServiceOrdersQueryVariables
  >(EyeServiceOrdersDocument, options);
}
export function useEyeServiceOrdersLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    EyeServiceOrdersQuery,
    EyeServiceOrdersQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    EyeServiceOrdersQuery,
    EyeServiceOrdersQueryVariables
  >(EyeServiceOrdersDocument, options);
}
export type EyeServiceOrdersQueryHookResult = ReturnType<
  typeof useEyeServiceOrdersQuery
>;
export type EyeServiceOrdersLazyQueryHookResult = ReturnType<
  typeof useEyeServiceOrdersLazyQuery
>;
export type EyeServiceOrdersQueryResult = ApolloReactCommon.QueryResult<
  EyeServiceOrdersQuery,
  EyeServiceOrdersQueryVariables
>;
export const EyeServiceOrderPendingAssignmentDocument = gql`
  query eyeServiceOrderPendingAssignment(
    $limit: Int
    $offset: Int
    $filters: EyeServiceOrderSearchInput!
  ) {
    eyeServiceOrderPendingAssignment(
      limit: $limit
      offset: $offset
      filters: $filters
    ) {
      count
      rows {
        id
        priority
        code
        region {
          id
          name
        }
        businessId
        createdUser {
          id
          name
          createdAt
          updatedAt
        }
        updatedUser {
          id
          name
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
        eyeServiceOrderType {
          id
          name
          code
        }
        contactPerson
        contactPhone
        address
        estimatedDate
        regionId
        customer {
          id
          code
          name
          businessCode
          medicalCode
        }
        status
        approvalStatus
        company {
          id
          name
        }
        assigneeDept {
          id
          code
          name
          company {
            id
            name
          }
        }
        assigneeUser {
          id
          name
        }
        approverUser {
          id
          name
        }
        eyeWarrantyContract {
          id
          code
        }
        eyeServiceOrderWorkDiaries {
          id
          date
          hours
          status
          remark
          users {
            id
            name
          }
          eyeServiceOrder {
            code
            status
            eyeServiceOrderType {
              name
            }
            customer {
              id
              name
            }
            assigneeDept {
              id
              name
              company {
                id
                name
              }
            }
          }
        }
        eyeQuotationOrders {
          id
          code
          customer {
            id
            code
            name
          }
          currency {
            id
            name
          }
          dept {
            id
            code
            name
          }
          invoicingCustomer {
            id
            code
            name
          }
          eyeServiceOrder {
            eyeWarrantyContract {
              approvalCode
            }
          }
          eyeQuotationOrderBusinesses {
            business {
              id
              code
              title
            }
          }
          orderCode
          realAmount
          untaxedAmount
          contactPerson
          contactPhone
          address
          user {
            id
            name
          }
          status
          createdAt
        }
        eyeFixedAssetRentalRecords {
          id
          date1
          date2
          installDate
          dismantleDate
          status
          remark
          regionId
          eyeFixedAssets {
            region {
              id
              name
            }
            id
            name
            status
            memo
            isBooking
            dept {
              id
              code
              name
            }
            createdUser {
              id
            }
            updatedUser {
              id
            }
            items {
              materialId
              materialCode
              materialName
              materialModel
              materialSpec
              materialUnit
              udi
              sn
              memo
            }
            createdAt
            updatedAt
            currentLocation
            eyeFixedAssetRentalRecords {
              id
              eyeServiceOrderId
              date1
              date2
              installDate
              dismantleDate
              status
              remark
              eyeServiceOrder {
                id
                code
                estimatedDate
                assigneeUser {
                  id
                  code
                  name
                }
              }
              eyeFixedAssets {
                id
                name
              }
              region {
                id
                name
              }
            }
          }
          region {
            id
            name
          }
          company {
            id
            name
          }
        }
        costCenterId
        dispatcherUserId
        memo
        description
        eyeFixedAssetRentalGoalId
        eyeFixedAssetRentalGoal {
          id
          code
          name
        }
        bpmUrl
        extension {
          extendDate1
          extendDate2
          bpmUrl
          approvalStatus
        }
        extensionCount
        user {
          id
          code
          name
          dept {
            id
            name
          }
        }
      }
    }
  }
`;

/**
 * __useEyeServiceOrderPendingAssignmentQuery__
 *
 * To run a query within a React component, call `useEyeServiceOrderPendingAssignmentQuery` and pass it any options that fit your needs.
 * When your component renders, `useEyeServiceOrderPendingAssignmentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEyeServiceOrderPendingAssignmentQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function useEyeServiceOrderPendingAssignmentQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    EyeServiceOrderPendingAssignmentQuery,
    EyeServiceOrderPendingAssignmentQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    EyeServiceOrderPendingAssignmentQuery,
    EyeServiceOrderPendingAssignmentQueryVariables
  >(EyeServiceOrderPendingAssignmentDocument, options);
}
export function useEyeServiceOrderPendingAssignmentLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    EyeServiceOrderPendingAssignmentQuery,
    EyeServiceOrderPendingAssignmentQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    EyeServiceOrderPendingAssignmentQuery,
    EyeServiceOrderPendingAssignmentQueryVariables
  >(EyeServiceOrderPendingAssignmentDocument, options);
}
export type EyeServiceOrderPendingAssignmentQueryHookResult = ReturnType<
  typeof useEyeServiceOrderPendingAssignmentQuery
>;
export type EyeServiceOrderPendingAssignmentLazyQueryHookResult = ReturnType<
  typeof useEyeServiceOrderPendingAssignmentLazyQuery
>;
export type EyeServiceOrderPendingAssignmentQueryResult =
  ApolloReactCommon.QueryResult<
    EyeServiceOrderPendingAssignmentQuery,
    EyeServiceOrderPendingAssignmentQueryVariables
  >;
export const EyeServiceOrderTypesDocument = gql`
  query EyeServiceOrderTypes($filters: EyeServiceOrderTypeSearchInput!) {
    eyeServiceOrderTypes(filters: $filters) {
      rows {
        id
        code
        name
      }
    }
  }
`;

/**
 * __useEyeServiceOrderTypesQuery__
 *
 * To run a query within a React component, call `useEyeServiceOrderTypesQuery` and pass it any options that fit your needs.
 * When your component renders, `useEyeServiceOrderTypesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEyeServiceOrderTypesQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function useEyeServiceOrderTypesQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    EyeServiceOrderTypesQuery,
    EyeServiceOrderTypesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    EyeServiceOrderTypesQuery,
    EyeServiceOrderTypesQueryVariables
  >(EyeServiceOrderTypesDocument, options);
}
export function useEyeServiceOrderTypesLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    EyeServiceOrderTypesQuery,
    EyeServiceOrderTypesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    EyeServiceOrderTypesQuery,
    EyeServiceOrderTypesQueryVariables
  >(EyeServiceOrderTypesDocument, options);
}
export type EyeServiceOrderTypesQueryHookResult = ReturnType<
  typeof useEyeServiceOrderTypesQuery
>;
export type EyeServiceOrderTypesLazyQueryHookResult = ReturnType<
  typeof useEyeServiceOrderTypesLazyQuery
>;
export type EyeServiceOrderTypesQueryResult = ApolloReactCommon.QueryResult<
  EyeServiceOrderTypesQuery,
  EyeServiceOrderTypesQueryVariables
>;
export const CompaniesDocument = gql`
  query Companies($filters: CompanySearchInput!) {
    companies(filters: $filters) {
      rows {
        depts {
          id
          companyId
          name
          code
        }
        id
        name
      }
    }
  }
`;

/**
 * __useCompaniesQuery__
 *
 * To run a query within a React component, call `useCompaniesQuery` and pass it any options that fit your needs.
 * When your component renders, `useCompaniesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCompaniesQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function useCompaniesQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    CompaniesQuery,
    CompaniesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<CompaniesQuery, CompaniesQueryVariables>(
    CompaniesDocument,
    options,
  );
}
export function useCompaniesLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    CompaniesQuery,
    CompaniesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<CompaniesQuery, CompaniesQueryVariables>(
    CompaniesDocument,
    options,
  );
}
export type CompaniesQueryHookResult = ReturnType<typeof useCompaniesQuery>;
export type CompaniesLazyQueryHookResult = ReturnType<
  typeof useCompaniesLazyQuery
>;
export type CompaniesQueryResult = ApolloReactCommon.QueryResult<
  CompaniesQuery,
  CompaniesQueryVariables
>;
export const EyeServiceOrderItemsDocument = gql`
  query EyeServiceOrderItems($filters: EyeServiceOrderItemSearchInput!) {
    eyeServiceOrderItems(filters: $filters) {
      rows {
        id
        materialId
        materialCode
        materialModel
        materialSpec
        materialUnit
        materialName
        sn
        udi
        qty
        memo
      }
    }
  }
`;

/**
 * __useEyeServiceOrderItemsQuery__
 *
 * To run a query within a React component, call `useEyeServiceOrderItemsQuery` and pass it any options that fit your needs.
 * When your component renders, `useEyeServiceOrderItemsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEyeServiceOrderItemsQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function useEyeServiceOrderItemsQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    EyeServiceOrderItemsQuery,
    EyeServiceOrderItemsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    EyeServiceOrderItemsQuery,
    EyeServiceOrderItemsQueryVariables
  >(EyeServiceOrderItemsDocument, options);
}
export function useEyeServiceOrderItemsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    EyeServiceOrderItemsQuery,
    EyeServiceOrderItemsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    EyeServiceOrderItemsQuery,
    EyeServiceOrderItemsQueryVariables
  >(EyeServiceOrderItemsDocument, options);
}
export type EyeServiceOrderItemsQueryHookResult = ReturnType<
  typeof useEyeServiceOrderItemsQuery
>;
export type EyeServiceOrderItemsLazyQueryHookResult = ReturnType<
  typeof useEyeServiceOrderItemsLazyQuery
>;
export type EyeServiceOrderItemsQueryResult = ApolloReactCommon.QueryResult<
  EyeServiceOrderItemsQuery,
  EyeServiceOrderItemsQueryVariables
>;
export const EyeServiceOrderAttachFilesDocument = gql`
  query EyeServiceOrderAttachFiles(
    $filters: EyeServiceOrderAttachFileSearchInput!
    $offset: Int
    $limit: Int
  ) {
    eyeServiceOrderAttachFiles(
      filters: $filters
      offset: $offset
      limit: $limit
    ) {
      rows {
        url
        id
        name
      }
    }
  }
`;

/**
 * __useEyeServiceOrderAttachFilesQuery__
 *
 * To run a query within a React component, call `useEyeServiceOrderAttachFilesQuery` and pass it any options that fit your needs.
 * When your component renders, `useEyeServiceOrderAttachFilesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEyeServiceOrderAttachFilesQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useEyeServiceOrderAttachFilesQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    EyeServiceOrderAttachFilesQuery,
    EyeServiceOrderAttachFilesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    EyeServiceOrderAttachFilesQuery,
    EyeServiceOrderAttachFilesQueryVariables
  >(EyeServiceOrderAttachFilesDocument, options);
}
export function useEyeServiceOrderAttachFilesLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    EyeServiceOrderAttachFilesQuery,
    EyeServiceOrderAttachFilesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    EyeServiceOrderAttachFilesQuery,
    EyeServiceOrderAttachFilesQueryVariables
  >(EyeServiceOrderAttachFilesDocument, options);
}
export type EyeServiceOrderAttachFilesQueryHookResult = ReturnType<
  typeof useEyeServiceOrderAttachFilesQuery
>;
export type EyeServiceOrderAttachFilesLazyQueryHookResult = ReturnType<
  typeof useEyeServiceOrderAttachFilesLazyQuery
>;
export type EyeServiceOrderAttachFilesQueryResult =
  ApolloReactCommon.QueryResult<
    EyeServiceOrderAttachFilesQuery,
    EyeServiceOrderAttachFilesQueryVariables
  >;
export const CostCentersDocument = gql`
  query CostCenters($filters: CostCenterSearchInput!, $limit: Int) {
    costCenters(filters: $filters, limit: $limit) {
      count
      rows {
        id
        name
        isActive
      }
    }
  }
`;

/**
 * __useCostCentersQuery__
 *
 * To run a query within a React component, call `useCostCentersQuery` and pass it any options that fit your needs.
 * When your component renders, `useCostCentersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCostCentersQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useCostCentersQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    CostCentersQuery,
    CostCentersQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<CostCentersQuery, CostCentersQueryVariables>(
    CostCentersDocument,
    options,
  );
}
export function useCostCentersLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    CostCentersQuery,
    CostCentersQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    CostCentersQuery,
    CostCentersQueryVariables
  >(CostCentersDocument, options);
}
export type CostCentersQueryHookResult = ReturnType<typeof useCostCentersQuery>;
export type CostCentersLazyQueryHookResult = ReturnType<
  typeof useCostCentersLazyQuery
>;
export type CostCentersQueryResult = ApolloReactCommon.QueryResult<
  CostCentersQuery,
  CostCentersQueryVariables
>;
export const DispatcherUsersDocument = gql`
  query DispatcherUsers($filters: DispatcherUserSearchInput!, $limit: Int) {
    dispatcherUsers(filters: $filters, limit: $limit) {
      rows {
        id
        isActive
        user {
          name
          code
        }
      }
    }
  }
`;

/**
 * __useDispatcherUsersQuery__
 *
 * To run a query within a React component, call `useDispatcherUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useDispatcherUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDispatcherUsersQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useDispatcherUsersQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    DispatcherUsersQuery,
    DispatcherUsersQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    DispatcherUsersQuery,
    DispatcherUsersQueryVariables
  >(DispatcherUsersDocument, options);
}
export function useDispatcherUsersLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    DispatcherUsersQuery,
    DispatcherUsersQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    DispatcherUsersQuery,
    DispatcherUsersQueryVariables
  >(DispatcherUsersDocument, options);
}
export type DispatcherUsersQueryHookResult = ReturnType<
  typeof useDispatcherUsersQuery
>;
export type DispatcherUsersLazyQueryHookResult = ReturnType<
  typeof useDispatcherUsersLazyQuery
>;
export type DispatcherUsersQueryResult = ApolloReactCommon.QueryResult<
  DispatcherUsersQuery,
  DispatcherUsersQueryVariables
>;
export const EyeFixedAssetsItemDocument = gql`
  query EyeFixedAssetsItem($filters: EyeFixedAssetSearchInput!) {
    eyeFixedAssets(filters: $filters) {
      rows {
        id
        name
        region {
          id
          name
        }
        company {
          id
          name
        }
        eyeFixedAssetRentalRecords {
          status
        }
        items {
          materialId
          materialCode
        }
        currentLocation
        memo
      }
    }
  }
`;

/**
 * __useEyeFixedAssetsItemQuery__
 *
 * To run a query within a React component, call `useEyeFixedAssetsItemQuery` and pass it any options that fit your needs.
 * When your component renders, `useEyeFixedAssetsItemQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEyeFixedAssetsItemQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function useEyeFixedAssetsItemQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    EyeFixedAssetsItemQuery,
    EyeFixedAssetsItemQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    EyeFixedAssetsItemQuery,
    EyeFixedAssetsItemQueryVariables
  >(EyeFixedAssetsItemDocument, options);
}
export function useEyeFixedAssetsItemLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    EyeFixedAssetsItemQuery,
    EyeFixedAssetsItemQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    EyeFixedAssetsItemQuery,
    EyeFixedAssetsItemQueryVariables
  >(EyeFixedAssetsItemDocument, options);
}
export type EyeFixedAssetsItemQueryHookResult = ReturnType<
  typeof useEyeFixedAssetsItemQuery
>;
export type EyeFixedAssetsItemLazyQueryHookResult = ReturnType<
  typeof useEyeFixedAssetsItemLazyQuery
>;
export type EyeFixedAssetsItemQueryResult = ApolloReactCommon.QueryResult<
  EyeFixedAssetsItemQuery,
  EyeFixedAssetsItemQueryVariables
>;
export const EyeFixedAssetRentalObjectsDocument = gql`
  query eyeFixedAssetRentalObjects(
    $filters: EyeFixedAssetRentalObjectSearchInput!
    $limit: Int
  ) {
    eyeFixedAssetRentalObjects(filters: $filters, limit: $limit) {
      rows {
        name
        id
        code
      }
    }
  }
`;

/**
 * __useEyeFixedAssetRentalObjectsQuery__
 *
 * To run a query within a React component, call `useEyeFixedAssetRentalObjectsQuery` and pass it any options that fit your needs.
 * When your component renders, `useEyeFixedAssetRentalObjectsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEyeFixedAssetRentalObjectsQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useEyeFixedAssetRentalObjectsQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    EyeFixedAssetRentalObjectsQuery,
    EyeFixedAssetRentalObjectsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    EyeFixedAssetRentalObjectsQuery,
    EyeFixedAssetRentalObjectsQueryVariables
  >(EyeFixedAssetRentalObjectsDocument, options);
}
export function useEyeFixedAssetRentalObjectsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    EyeFixedAssetRentalObjectsQuery,
    EyeFixedAssetRentalObjectsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    EyeFixedAssetRentalObjectsQuery,
    EyeFixedAssetRentalObjectsQueryVariables
  >(EyeFixedAssetRentalObjectsDocument, options);
}
export type EyeFixedAssetRentalObjectsQueryHookResult = ReturnType<
  typeof useEyeFixedAssetRentalObjectsQuery
>;
export type EyeFixedAssetRentalObjectsLazyQueryHookResult = ReturnType<
  typeof useEyeFixedAssetRentalObjectsLazyQuery
>;
export type EyeFixedAssetRentalObjectsQueryResult =
  ApolloReactCommon.QueryResult<
    EyeFixedAssetRentalObjectsQuery,
    EyeFixedAssetRentalObjectsQueryVariables
  >;
export const EyeFixedAssetRentalGoalsDocument = gql`
  query EyeFixedAssetRentalGoals(
    $filters: EyeFixedAssetRentalGoalSearchInput!
    $limit: Int
  ) {
    eyeFixedAssetRentalGoals(filters: $filters, limit: $limit) {
      rows {
        id
        code
        name
        isActive
      }
    }
  }
`;

/**
 * __useEyeFixedAssetRentalGoalsQuery__
 *
 * To run a query within a React component, call `useEyeFixedAssetRentalGoalsQuery` and pass it any options that fit your needs.
 * When your component renders, `useEyeFixedAssetRentalGoalsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEyeFixedAssetRentalGoalsQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useEyeFixedAssetRentalGoalsQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    EyeFixedAssetRentalGoalsQuery,
    EyeFixedAssetRentalGoalsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    EyeFixedAssetRentalGoalsQuery,
    EyeFixedAssetRentalGoalsQueryVariables
  >(EyeFixedAssetRentalGoalsDocument, options);
}
export function useEyeFixedAssetRentalGoalsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    EyeFixedAssetRentalGoalsQuery,
    EyeFixedAssetRentalGoalsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    EyeFixedAssetRentalGoalsQuery,
    EyeFixedAssetRentalGoalsQueryVariables
  >(EyeFixedAssetRentalGoalsDocument, options);
}
export type EyeFixedAssetRentalGoalsQueryHookResult = ReturnType<
  typeof useEyeFixedAssetRentalGoalsQuery
>;
export type EyeFixedAssetRentalGoalsLazyQueryHookResult = ReturnType<
  typeof useEyeFixedAssetRentalGoalsLazyQuery
>;
export type EyeFixedAssetRentalGoalsQueryResult = ApolloReactCommon.QueryResult<
  EyeFixedAssetRentalGoalsQuery,
  EyeFixedAssetRentalGoalsQueryVariables
>;
export const CreateEyeServiceOrderWorkDiaryDocument = gql`
  mutation CreateEyeServiceOrderWorkDiary(
    $input: EyeServiceOrderWorkDiaryCreateInput!
  ) {
    createEyeServiceOrderWorkDiary(input: $input) {
      id
    }
  }
`;
export type CreateEyeServiceOrderWorkDiaryMutationFn =
  ApolloReactCommon.MutationFunction<
    CreateEyeServiceOrderWorkDiaryMutation,
    CreateEyeServiceOrderWorkDiaryMutationVariables
  >;

/**
 * __useCreateEyeServiceOrderWorkDiaryMutation__
 *
 * To run a mutation, you first call `useCreateEyeServiceOrderWorkDiaryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateEyeServiceOrderWorkDiaryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createEyeServiceOrderWorkDiaryMutation, { data, loading, error }] = useCreateEyeServiceOrderWorkDiaryMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateEyeServiceOrderWorkDiaryMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    CreateEyeServiceOrderWorkDiaryMutation,
    CreateEyeServiceOrderWorkDiaryMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    CreateEyeServiceOrderWorkDiaryMutation,
    CreateEyeServiceOrderWorkDiaryMutationVariables
  >(CreateEyeServiceOrderWorkDiaryDocument, options);
}
export type CreateEyeServiceOrderWorkDiaryMutationHookResult = ReturnType<
  typeof useCreateEyeServiceOrderWorkDiaryMutation
>;
export type CreateEyeServiceOrderWorkDiaryMutationResult =
  ApolloReactCommon.MutationResult<CreateEyeServiceOrderWorkDiaryMutation>;
export type CreateEyeServiceOrderWorkDiaryMutationOptions =
  ApolloReactCommon.BaseMutationOptions<
    CreateEyeServiceOrderWorkDiaryMutation,
    CreateEyeServiceOrderWorkDiaryMutationVariables
  >;
export const UpdateEyeServiceOrderWorkDiaryDocument = gql`
  mutation UpdateEyeServiceOrderWorkDiary(
    $input: EyeServiceOrderWorkDiaryUpdateInput!
  ) {
    updateEyeServiceOrderWorkDiary(input: $input) {
      id
    }
  }
`;
export type UpdateEyeServiceOrderWorkDiaryMutationFn =
  ApolloReactCommon.MutationFunction<
    UpdateEyeServiceOrderWorkDiaryMutation,
    UpdateEyeServiceOrderWorkDiaryMutationVariables
  >;

/**
 * __useUpdateEyeServiceOrderWorkDiaryMutation__
 *
 * To run a mutation, you first call `useUpdateEyeServiceOrderWorkDiaryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateEyeServiceOrderWorkDiaryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateEyeServiceOrderWorkDiaryMutation, { data, loading, error }] = useUpdateEyeServiceOrderWorkDiaryMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateEyeServiceOrderWorkDiaryMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    UpdateEyeServiceOrderWorkDiaryMutation,
    UpdateEyeServiceOrderWorkDiaryMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    UpdateEyeServiceOrderWorkDiaryMutation,
    UpdateEyeServiceOrderWorkDiaryMutationVariables
  >(UpdateEyeServiceOrderWorkDiaryDocument, options);
}
export type UpdateEyeServiceOrderWorkDiaryMutationHookResult = ReturnType<
  typeof useUpdateEyeServiceOrderWorkDiaryMutation
>;
export type UpdateEyeServiceOrderWorkDiaryMutationResult =
  ApolloReactCommon.MutationResult<UpdateEyeServiceOrderWorkDiaryMutation>;
export type UpdateEyeServiceOrderWorkDiaryMutationOptions =
  ApolloReactCommon.BaseMutationOptions<
    UpdateEyeServiceOrderWorkDiaryMutation,
    UpdateEyeServiceOrderWorkDiaryMutationVariables
  >;
export const DeleteEyeServiceOrderWorkDiaryDocument = gql`
  mutation DeleteEyeServiceOrderWorkDiary(
    $deleteEyeServiceOrderWorkDiaryId: ID!
  ) {
    deleteEyeServiceOrderWorkDiary(id: $deleteEyeServiceOrderWorkDiaryId)
  }
`;
export type DeleteEyeServiceOrderWorkDiaryMutationFn =
  ApolloReactCommon.MutationFunction<
    DeleteEyeServiceOrderWorkDiaryMutation,
    DeleteEyeServiceOrderWorkDiaryMutationVariables
  >;

/**
 * __useDeleteEyeServiceOrderWorkDiaryMutation__
 *
 * To run a mutation, you first call `useDeleteEyeServiceOrderWorkDiaryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteEyeServiceOrderWorkDiaryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteEyeServiceOrderWorkDiaryMutation, { data, loading, error }] = useDeleteEyeServiceOrderWorkDiaryMutation({
 *   variables: {
 *      deleteEyeServiceOrderWorkDiaryId: // value for 'deleteEyeServiceOrderWorkDiaryId'
 *   },
 * });
 */
export function useDeleteEyeServiceOrderWorkDiaryMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    DeleteEyeServiceOrderWorkDiaryMutation,
    DeleteEyeServiceOrderWorkDiaryMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    DeleteEyeServiceOrderWorkDiaryMutation,
    DeleteEyeServiceOrderWorkDiaryMutationVariables
  >(DeleteEyeServiceOrderWorkDiaryDocument, options);
}
export type DeleteEyeServiceOrderWorkDiaryMutationHookResult = ReturnType<
  typeof useDeleteEyeServiceOrderWorkDiaryMutation
>;
export type DeleteEyeServiceOrderWorkDiaryMutationResult =
  ApolloReactCommon.MutationResult<DeleteEyeServiceOrderWorkDiaryMutation>;
export type DeleteEyeServiceOrderWorkDiaryMutationOptions =
  ApolloReactCommon.BaseMutationOptions<
    DeleteEyeServiceOrderWorkDiaryMutation,
    DeleteEyeServiceOrderWorkDiaryMutationVariables
  >;
export const CreateEyeServiceOrderWorkDiaryItemDocument = gql`
  mutation CreateEyeServiceOrderWorkDiaryItem(
    $input: EyeServiceOrderWorkDiaryItemCreateInput!
  ) {
    createEyeServiceOrderWorkDiaryItem(input: $input) {
      id
    }
  }
`;
export type CreateEyeServiceOrderWorkDiaryItemMutationFn =
  ApolloReactCommon.MutationFunction<
    CreateEyeServiceOrderWorkDiaryItemMutation,
    CreateEyeServiceOrderWorkDiaryItemMutationVariables
  >;

/**
 * __useCreateEyeServiceOrderWorkDiaryItemMutation__
 *
 * To run a mutation, you first call `useCreateEyeServiceOrderWorkDiaryItemMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateEyeServiceOrderWorkDiaryItemMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createEyeServiceOrderWorkDiaryItemMutation, { data, loading, error }] = useCreateEyeServiceOrderWorkDiaryItemMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateEyeServiceOrderWorkDiaryItemMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    CreateEyeServiceOrderWorkDiaryItemMutation,
    CreateEyeServiceOrderWorkDiaryItemMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    CreateEyeServiceOrderWorkDiaryItemMutation,
    CreateEyeServiceOrderWorkDiaryItemMutationVariables
  >(CreateEyeServiceOrderWorkDiaryItemDocument, options);
}
export type CreateEyeServiceOrderWorkDiaryItemMutationHookResult = ReturnType<
  typeof useCreateEyeServiceOrderWorkDiaryItemMutation
>;
export type CreateEyeServiceOrderWorkDiaryItemMutationResult =
  ApolloReactCommon.MutationResult<CreateEyeServiceOrderWorkDiaryItemMutation>;
export type CreateEyeServiceOrderWorkDiaryItemMutationOptions =
  ApolloReactCommon.BaseMutationOptions<
    CreateEyeServiceOrderWorkDiaryItemMutation,
    CreateEyeServiceOrderWorkDiaryItemMutationVariables
  >;
export const UpdateEyeServiceOrderWorkDiaryItemDocument = gql`
  mutation UpdateEyeServiceOrderWorkDiaryItem(
    $input: EyeServiceOrderWorkDiaryItemUpdateInput!
  ) {
    updateEyeServiceOrderWorkDiaryItem(input: $input) {
      id
    }
  }
`;
export type UpdateEyeServiceOrderWorkDiaryItemMutationFn =
  ApolloReactCommon.MutationFunction<
    UpdateEyeServiceOrderWorkDiaryItemMutation,
    UpdateEyeServiceOrderWorkDiaryItemMutationVariables
  >;

/**
 * __useUpdateEyeServiceOrderWorkDiaryItemMutation__
 *
 * To run a mutation, you first call `useUpdateEyeServiceOrderWorkDiaryItemMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateEyeServiceOrderWorkDiaryItemMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateEyeServiceOrderWorkDiaryItemMutation, { data, loading, error }] = useUpdateEyeServiceOrderWorkDiaryItemMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateEyeServiceOrderWorkDiaryItemMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    UpdateEyeServiceOrderWorkDiaryItemMutation,
    UpdateEyeServiceOrderWorkDiaryItemMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    UpdateEyeServiceOrderWorkDiaryItemMutation,
    UpdateEyeServiceOrderWorkDiaryItemMutationVariables
  >(UpdateEyeServiceOrderWorkDiaryItemDocument, options);
}
export type UpdateEyeServiceOrderWorkDiaryItemMutationHookResult = ReturnType<
  typeof useUpdateEyeServiceOrderWorkDiaryItemMutation
>;
export type UpdateEyeServiceOrderWorkDiaryItemMutationResult =
  ApolloReactCommon.MutationResult<UpdateEyeServiceOrderWorkDiaryItemMutation>;
export type UpdateEyeServiceOrderWorkDiaryItemMutationOptions =
  ApolloReactCommon.BaseMutationOptions<
    UpdateEyeServiceOrderWorkDiaryItemMutation,
    UpdateEyeServiceOrderWorkDiaryItemMutationVariables
  >;
export const DeleteEyeServiceOrderWorkDiaryItemDocument = gql`
  mutation DeleteEyeServiceOrderWorkDiaryItem(
    $deleteEyeServiceOrderWorkDiaryItemId: ID!
  ) {
    deleteEyeServiceOrderWorkDiaryItem(
      id: $deleteEyeServiceOrderWorkDiaryItemId
    )
  }
`;
export type DeleteEyeServiceOrderWorkDiaryItemMutationFn =
  ApolloReactCommon.MutationFunction<
    DeleteEyeServiceOrderWorkDiaryItemMutation,
    DeleteEyeServiceOrderWorkDiaryItemMutationVariables
  >;

/**
 * __useDeleteEyeServiceOrderWorkDiaryItemMutation__
 *
 * To run a mutation, you first call `useDeleteEyeServiceOrderWorkDiaryItemMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteEyeServiceOrderWorkDiaryItemMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteEyeServiceOrderWorkDiaryItemMutation, { data, loading, error }] = useDeleteEyeServiceOrderWorkDiaryItemMutation({
 *   variables: {
 *      deleteEyeServiceOrderWorkDiaryItemId: // value for 'deleteEyeServiceOrderWorkDiaryItemId'
 *   },
 * });
 */
export function useDeleteEyeServiceOrderWorkDiaryItemMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    DeleteEyeServiceOrderWorkDiaryItemMutation,
    DeleteEyeServiceOrderWorkDiaryItemMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    DeleteEyeServiceOrderWorkDiaryItemMutation,
    DeleteEyeServiceOrderWorkDiaryItemMutationVariables
  >(DeleteEyeServiceOrderWorkDiaryItemDocument, options);
}
export type DeleteEyeServiceOrderWorkDiaryItemMutationHookResult = ReturnType<
  typeof useDeleteEyeServiceOrderWorkDiaryItemMutation
>;
export type DeleteEyeServiceOrderWorkDiaryItemMutationResult =
  ApolloReactCommon.MutationResult<DeleteEyeServiceOrderWorkDiaryItemMutation>;
export type DeleteEyeServiceOrderWorkDiaryItemMutationOptions =
  ApolloReactCommon.BaseMutationOptions<
    DeleteEyeServiceOrderWorkDiaryItemMutation,
    DeleteEyeServiceOrderWorkDiaryItemMutationVariables
  >;
export const CreateEyeServiceOrderWorkDiaryAttachFileDocument = gql`
  mutation CreateEyeServiceOrderWorkDiaryAttachFile(
    $input: EyeServiceOrderWorkDiaryAttachFileCreateInput!
  ) {
    createEyeServiceOrderWorkDiaryAttachFile(input: $input) {
      id
    }
  }
`;
export type CreateEyeServiceOrderWorkDiaryAttachFileMutationFn =
  ApolloReactCommon.MutationFunction<
    CreateEyeServiceOrderWorkDiaryAttachFileMutation,
    CreateEyeServiceOrderWorkDiaryAttachFileMutationVariables
  >;

/**
 * __useCreateEyeServiceOrderWorkDiaryAttachFileMutation__
 *
 * To run a mutation, you first call `useCreateEyeServiceOrderWorkDiaryAttachFileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateEyeServiceOrderWorkDiaryAttachFileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createEyeServiceOrderWorkDiaryAttachFileMutation, { data, loading, error }] = useCreateEyeServiceOrderWorkDiaryAttachFileMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateEyeServiceOrderWorkDiaryAttachFileMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    CreateEyeServiceOrderWorkDiaryAttachFileMutation,
    CreateEyeServiceOrderWorkDiaryAttachFileMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    CreateEyeServiceOrderWorkDiaryAttachFileMutation,
    CreateEyeServiceOrderWorkDiaryAttachFileMutationVariables
  >(CreateEyeServiceOrderWorkDiaryAttachFileDocument, options);
}
export type CreateEyeServiceOrderWorkDiaryAttachFileMutationHookResult =
  ReturnType<typeof useCreateEyeServiceOrderWorkDiaryAttachFileMutation>;
export type CreateEyeServiceOrderWorkDiaryAttachFileMutationResult =
  ApolloReactCommon.MutationResult<CreateEyeServiceOrderWorkDiaryAttachFileMutation>;
export type CreateEyeServiceOrderWorkDiaryAttachFileMutationOptions =
  ApolloReactCommon.BaseMutationOptions<
    CreateEyeServiceOrderWorkDiaryAttachFileMutation,
    CreateEyeServiceOrderWorkDiaryAttachFileMutationVariables
  >;
export const UpdateEyeServiceOrderWorkDiaryAttachFileDocument = gql`
  mutation UpdateEyeServiceOrderWorkDiaryAttachFile(
    $input: EyeServiceOrderWorkDiaryAttachFileUpdateInput!
  ) {
    updateEyeServiceOrderWorkDiaryAttachFile(input: $input) {
      id
    }
  }
`;
export type UpdateEyeServiceOrderWorkDiaryAttachFileMutationFn =
  ApolloReactCommon.MutationFunction<
    UpdateEyeServiceOrderWorkDiaryAttachFileMutation,
    UpdateEyeServiceOrderWorkDiaryAttachFileMutationVariables
  >;

/**
 * __useUpdateEyeServiceOrderWorkDiaryAttachFileMutation__
 *
 * To run a mutation, you first call `useUpdateEyeServiceOrderWorkDiaryAttachFileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateEyeServiceOrderWorkDiaryAttachFileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateEyeServiceOrderWorkDiaryAttachFileMutation, { data, loading, error }] = useUpdateEyeServiceOrderWorkDiaryAttachFileMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateEyeServiceOrderWorkDiaryAttachFileMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    UpdateEyeServiceOrderWorkDiaryAttachFileMutation,
    UpdateEyeServiceOrderWorkDiaryAttachFileMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    UpdateEyeServiceOrderWorkDiaryAttachFileMutation,
    UpdateEyeServiceOrderWorkDiaryAttachFileMutationVariables
  >(UpdateEyeServiceOrderWorkDiaryAttachFileDocument, options);
}
export type UpdateEyeServiceOrderWorkDiaryAttachFileMutationHookResult =
  ReturnType<typeof useUpdateEyeServiceOrderWorkDiaryAttachFileMutation>;
export type UpdateEyeServiceOrderWorkDiaryAttachFileMutationResult =
  ApolloReactCommon.MutationResult<UpdateEyeServiceOrderWorkDiaryAttachFileMutation>;
export type UpdateEyeServiceOrderWorkDiaryAttachFileMutationOptions =
  ApolloReactCommon.BaseMutationOptions<
    UpdateEyeServiceOrderWorkDiaryAttachFileMutation,
    UpdateEyeServiceOrderWorkDiaryAttachFileMutationVariables
  >;
export const DeleteEyeServiceOrderWorkDiaryAttachFileDocument = gql`
  mutation DeleteEyeServiceOrderWorkDiaryAttachFile(
    $deleteEyeServiceOrderWorkDiaryAttachFileId: ID!
  ) {
    deleteEyeServiceOrderWorkDiaryAttachFile(
      id: $deleteEyeServiceOrderWorkDiaryAttachFileId
    )
  }
`;
export type DeleteEyeServiceOrderWorkDiaryAttachFileMutationFn =
  ApolloReactCommon.MutationFunction<
    DeleteEyeServiceOrderWorkDiaryAttachFileMutation,
    DeleteEyeServiceOrderWorkDiaryAttachFileMutationVariables
  >;

/**
 * __useDeleteEyeServiceOrderWorkDiaryAttachFileMutation__
 *
 * To run a mutation, you first call `useDeleteEyeServiceOrderWorkDiaryAttachFileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteEyeServiceOrderWorkDiaryAttachFileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteEyeServiceOrderWorkDiaryAttachFileMutation, { data, loading, error }] = useDeleteEyeServiceOrderWorkDiaryAttachFileMutation({
 *   variables: {
 *      deleteEyeServiceOrderWorkDiaryAttachFileId: // value for 'deleteEyeServiceOrderWorkDiaryAttachFileId'
 *   },
 * });
 */
export function useDeleteEyeServiceOrderWorkDiaryAttachFileMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    DeleteEyeServiceOrderWorkDiaryAttachFileMutation,
    DeleteEyeServiceOrderWorkDiaryAttachFileMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    DeleteEyeServiceOrderWorkDiaryAttachFileMutation,
    DeleteEyeServiceOrderWorkDiaryAttachFileMutationVariables
  >(DeleteEyeServiceOrderWorkDiaryAttachFileDocument, options);
}
export type DeleteEyeServiceOrderWorkDiaryAttachFileMutationHookResult =
  ReturnType<typeof useDeleteEyeServiceOrderWorkDiaryAttachFileMutation>;
export type DeleteEyeServiceOrderWorkDiaryAttachFileMutationResult =
  ApolloReactCommon.MutationResult<DeleteEyeServiceOrderWorkDiaryAttachFileMutation>;
export type DeleteEyeServiceOrderWorkDiaryAttachFileMutationOptions =
  ApolloReactCommon.BaseMutationOptions<
    DeleteEyeServiceOrderWorkDiaryAttachFileMutation,
    DeleteEyeServiceOrderWorkDiaryAttachFileMutationVariables
  >;
export const CreateEyeServiceOrderWorkDiaryCheckInDocument = gql`
  mutation CreateEyeServiceOrderWorkDiaryCheckIn(
    $input: EyeServiceOrderWorkDiaryCheckInCreateInput!
  ) {
    createEyeServiceOrderWorkDiaryCheckIn(input: $input) {
      id
    }
  }
`;
export type CreateEyeServiceOrderWorkDiaryCheckInMutationFn =
  ApolloReactCommon.MutationFunction<
    CreateEyeServiceOrderWorkDiaryCheckInMutation,
    CreateEyeServiceOrderWorkDiaryCheckInMutationVariables
  >;

/**
 * __useCreateEyeServiceOrderWorkDiaryCheckInMutation__
 *
 * To run a mutation, you first call `useCreateEyeServiceOrderWorkDiaryCheckInMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateEyeServiceOrderWorkDiaryCheckInMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createEyeServiceOrderWorkDiaryCheckInMutation, { data, loading, error }] = useCreateEyeServiceOrderWorkDiaryCheckInMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateEyeServiceOrderWorkDiaryCheckInMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    CreateEyeServiceOrderWorkDiaryCheckInMutation,
    CreateEyeServiceOrderWorkDiaryCheckInMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    CreateEyeServiceOrderWorkDiaryCheckInMutation,
    CreateEyeServiceOrderWorkDiaryCheckInMutationVariables
  >(CreateEyeServiceOrderWorkDiaryCheckInDocument, options);
}
export type CreateEyeServiceOrderWorkDiaryCheckInMutationHookResult =
  ReturnType<typeof useCreateEyeServiceOrderWorkDiaryCheckInMutation>;
export type CreateEyeServiceOrderWorkDiaryCheckInMutationResult =
  ApolloReactCommon.MutationResult<CreateEyeServiceOrderWorkDiaryCheckInMutation>;
export type CreateEyeServiceOrderWorkDiaryCheckInMutationOptions =
  ApolloReactCommon.BaseMutationOptions<
    CreateEyeServiceOrderWorkDiaryCheckInMutation,
    CreateEyeServiceOrderWorkDiaryCheckInMutationVariables
  >;
export const EyeServiceOrderWorkDiariesDocument = gql`
  query eyeServiceOrderWorkDiaries(
    $limit: Int
    $offset: Int
    $filters: EyeServiceOrderWorkDiarySearchInput!
  ) {
    eyeServiceOrderWorkDiaries(
      limit: $limit
      offset: $offset
      filters: $filters
    ) {
      count
      rows {
        id
        region {
          id
          name
        }
        date
        hours
        status
        remark
        users {
          id
          code
          name
          dept {
            id
            code
            name
          }
        }
        eyeServiceOrder {
          id
          code
          eyeServiceOrderTypeId
          status
          eyeServiceOrderType {
            name
          }
          customer {
            id
            name
          }
          assigneeDept {
            id
            name
          }
          contactPerson
          contactPhone
          address
        }
        eyeServiceOrderWorkDiaryType {
          id
          name
        }
        estimatedHours
      }
    }
  }
`;

/**
 * __useEyeServiceOrderWorkDiariesQuery__
 *
 * To run a query within a React component, call `useEyeServiceOrderWorkDiariesQuery` and pass it any options that fit your needs.
 * When your component renders, `useEyeServiceOrderWorkDiariesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEyeServiceOrderWorkDiariesQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function useEyeServiceOrderWorkDiariesQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    EyeServiceOrderWorkDiariesQuery,
    EyeServiceOrderWorkDiariesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    EyeServiceOrderWorkDiariesQuery,
    EyeServiceOrderWorkDiariesQueryVariables
  >(EyeServiceOrderWorkDiariesDocument, options);
}
export function useEyeServiceOrderWorkDiariesLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    EyeServiceOrderWorkDiariesQuery,
    EyeServiceOrderWorkDiariesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    EyeServiceOrderWorkDiariesQuery,
    EyeServiceOrderWorkDiariesQueryVariables
  >(EyeServiceOrderWorkDiariesDocument, options);
}
export type EyeServiceOrderWorkDiariesQueryHookResult = ReturnType<
  typeof useEyeServiceOrderWorkDiariesQuery
>;
export type EyeServiceOrderWorkDiariesLazyQueryHookResult = ReturnType<
  typeof useEyeServiceOrderWorkDiariesLazyQuery
>;
export type EyeServiceOrderWorkDiariesQueryResult =
  ApolloReactCommon.QueryResult<
    EyeServiceOrderWorkDiariesQuery,
    EyeServiceOrderWorkDiariesQueryVariables
  >;
export const EyeServiceOrderWorkDiaryTypesDocument = gql`
  query EyeServiceOrderWorkDiaryTypes(
    $filters: EyeServiceOrderWorkDiaryTypeSearchInput!
  ) {
    eyeServiceOrderWorkDiaryTypes(filters: $filters) {
      rows {
        id
        name
      }
    }
  }
`;

/**
 * __useEyeServiceOrderWorkDiaryTypesQuery__
 *
 * To run a query within a React component, call `useEyeServiceOrderWorkDiaryTypesQuery` and pass it any options that fit your needs.
 * When your component renders, `useEyeServiceOrderWorkDiaryTypesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEyeServiceOrderWorkDiaryTypesQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function useEyeServiceOrderWorkDiaryTypesQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    EyeServiceOrderWorkDiaryTypesQuery,
    EyeServiceOrderWorkDiaryTypesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    EyeServiceOrderWorkDiaryTypesQuery,
    EyeServiceOrderWorkDiaryTypesQueryVariables
  >(EyeServiceOrderWorkDiaryTypesDocument, options);
}
export function useEyeServiceOrderWorkDiaryTypesLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    EyeServiceOrderWorkDiaryTypesQuery,
    EyeServiceOrderWorkDiaryTypesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    EyeServiceOrderWorkDiaryTypesQuery,
    EyeServiceOrderWorkDiaryTypesQueryVariables
  >(EyeServiceOrderWorkDiaryTypesDocument, options);
}
export type EyeServiceOrderWorkDiaryTypesQueryHookResult = ReturnType<
  typeof useEyeServiceOrderWorkDiaryTypesQuery
>;
export type EyeServiceOrderWorkDiaryTypesLazyQueryHookResult = ReturnType<
  typeof useEyeServiceOrderWorkDiaryTypesLazyQuery
>;
export type EyeServiceOrderWorkDiaryTypesQueryResult =
  ApolloReactCommon.QueryResult<
    EyeServiceOrderWorkDiaryTypesQuery,
    EyeServiceOrderWorkDiaryTypesQueryVariables
  >;
export const EyeServiceOrderWorkDiaryItemsDocument = gql`
  query EyeServiceOrderWorkDiaryItems(
    $filters: EyeServiceOrderWorkDiaryItemSearchInput!
    $offset: Int
    $limit: Int
  ) {
    eyeServiceOrderWorkDiaryItems(
      filters: $filters
      offset: $offset
      limit: $limit
    ) {
      rows {
        id
        materialId
        materialCode
        materialModel
        materialSpec
        materialUnit
        materialName
      }
    }
  }
`;

/**
 * __useEyeServiceOrderWorkDiaryItemsQuery__
 *
 * To run a query within a React component, call `useEyeServiceOrderWorkDiaryItemsQuery` and pass it any options that fit your needs.
 * When your component renders, `useEyeServiceOrderWorkDiaryItemsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEyeServiceOrderWorkDiaryItemsQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useEyeServiceOrderWorkDiaryItemsQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    EyeServiceOrderWorkDiaryItemsQuery,
    EyeServiceOrderWorkDiaryItemsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    EyeServiceOrderWorkDiaryItemsQuery,
    EyeServiceOrderWorkDiaryItemsQueryVariables
  >(EyeServiceOrderWorkDiaryItemsDocument, options);
}
export function useEyeServiceOrderWorkDiaryItemsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    EyeServiceOrderWorkDiaryItemsQuery,
    EyeServiceOrderWorkDiaryItemsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    EyeServiceOrderWorkDiaryItemsQuery,
    EyeServiceOrderWorkDiaryItemsQueryVariables
  >(EyeServiceOrderWorkDiaryItemsDocument, options);
}
export type EyeServiceOrderWorkDiaryItemsQueryHookResult = ReturnType<
  typeof useEyeServiceOrderWorkDiaryItemsQuery
>;
export type EyeServiceOrderWorkDiaryItemsLazyQueryHookResult = ReturnType<
  typeof useEyeServiceOrderWorkDiaryItemsLazyQuery
>;
export type EyeServiceOrderWorkDiaryItemsQueryResult =
  ApolloReactCommon.QueryResult<
    EyeServiceOrderWorkDiaryItemsQuery,
    EyeServiceOrderWorkDiaryItemsQueryVariables
  >;
export const EyeServiceOrderWorkDiaryAttachFilesDocument = gql`
  query EyeServiceOrderWorkDiaryAttachFiles(
    $filters: EyeServiceOrderWorkDiaryAttachFileSearchInput!
    $offset: Int
    $limit: Int
  ) {
    eyeServiceOrderWorkDiaryAttachFiles(
      filters: $filters
      offset: $offset
      limit: $limit
    ) {
      rows {
        url
        id
        name
      }
    }
  }
`;

/**
 * __useEyeServiceOrderWorkDiaryAttachFilesQuery__
 *
 * To run a query within a React component, call `useEyeServiceOrderWorkDiaryAttachFilesQuery` and pass it any options that fit your needs.
 * When your component renders, `useEyeServiceOrderWorkDiaryAttachFilesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEyeServiceOrderWorkDiaryAttachFilesQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useEyeServiceOrderWorkDiaryAttachFilesQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    EyeServiceOrderWorkDiaryAttachFilesQuery,
    EyeServiceOrderWorkDiaryAttachFilesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    EyeServiceOrderWorkDiaryAttachFilesQuery,
    EyeServiceOrderWorkDiaryAttachFilesQueryVariables
  >(EyeServiceOrderWorkDiaryAttachFilesDocument, options);
}
export function useEyeServiceOrderWorkDiaryAttachFilesLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    EyeServiceOrderWorkDiaryAttachFilesQuery,
    EyeServiceOrderWorkDiaryAttachFilesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    EyeServiceOrderWorkDiaryAttachFilesQuery,
    EyeServiceOrderWorkDiaryAttachFilesQueryVariables
  >(EyeServiceOrderWorkDiaryAttachFilesDocument, options);
}
export type EyeServiceOrderWorkDiaryAttachFilesQueryHookResult = ReturnType<
  typeof useEyeServiceOrderWorkDiaryAttachFilesQuery
>;
export type EyeServiceOrderWorkDiaryAttachFilesLazyQueryHookResult = ReturnType<
  typeof useEyeServiceOrderWorkDiaryAttachFilesLazyQuery
>;
export type EyeServiceOrderWorkDiaryAttachFilesQueryResult =
  ApolloReactCommon.QueryResult<
    EyeServiceOrderWorkDiaryAttachFilesQuery,
    EyeServiceOrderWorkDiaryAttachFilesQueryVariables
  >;
export const EyeServiceOrderWorkDiaryCheckInsDocument = gql`
  query EyeServiceOrderWorkDiaryCheckIns(
    $filters: EyeServiceOrderWorkDiaryCheckInSearchInput!
  ) {
    eyeServiceOrderWorkDiaryCheckIns(filters: $filters) {
      rows {
        id
        content
        lat
        lng
        createdAt
      }
      count
    }
  }
`;

/**
 * __useEyeServiceOrderWorkDiaryCheckInsQuery__
 *
 * To run a query within a React component, call `useEyeServiceOrderWorkDiaryCheckInsQuery` and pass it any options that fit your needs.
 * When your component renders, `useEyeServiceOrderWorkDiaryCheckInsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEyeServiceOrderWorkDiaryCheckInsQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function useEyeServiceOrderWorkDiaryCheckInsQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    EyeServiceOrderWorkDiaryCheckInsQuery,
    EyeServiceOrderWorkDiaryCheckInsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    EyeServiceOrderWorkDiaryCheckInsQuery,
    EyeServiceOrderWorkDiaryCheckInsQueryVariables
  >(EyeServiceOrderWorkDiaryCheckInsDocument, options);
}
export function useEyeServiceOrderWorkDiaryCheckInsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    EyeServiceOrderWorkDiaryCheckInsQuery,
    EyeServiceOrderWorkDiaryCheckInsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    EyeServiceOrderWorkDiaryCheckInsQuery,
    EyeServiceOrderWorkDiaryCheckInsQueryVariables
  >(EyeServiceOrderWorkDiaryCheckInsDocument, options);
}
export type EyeServiceOrderWorkDiaryCheckInsQueryHookResult = ReturnType<
  typeof useEyeServiceOrderWorkDiaryCheckInsQuery
>;
export type EyeServiceOrderWorkDiaryCheckInsLazyQueryHookResult = ReturnType<
  typeof useEyeServiceOrderWorkDiaryCheckInsLazyQuery
>;
export type EyeServiceOrderWorkDiaryCheckInsQueryResult =
  ApolloReactCommon.QueryResult<
    EyeServiceOrderWorkDiaryCheckInsQuery,
    EyeServiceOrderWorkDiaryCheckInsQueryVariables
  >;
export const CreateEyeWarrantyContractDocument = gql`
  mutation CreateEyeWarrantyContract($input: EyeWarrantyContractCreateInput!) {
    createEyeWarrantyContract(input: $input) {
      id
      code
    }
  }
`;
export type CreateEyeWarrantyContractMutationFn =
  ApolloReactCommon.MutationFunction<
    CreateEyeWarrantyContractMutation,
    CreateEyeWarrantyContractMutationVariables
  >;

/**
 * __useCreateEyeWarrantyContractMutation__
 *
 * To run a mutation, you first call `useCreateEyeWarrantyContractMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateEyeWarrantyContractMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createEyeWarrantyContractMutation, { data, loading, error }] = useCreateEyeWarrantyContractMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateEyeWarrantyContractMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    CreateEyeWarrantyContractMutation,
    CreateEyeWarrantyContractMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    CreateEyeWarrantyContractMutation,
    CreateEyeWarrantyContractMutationVariables
  >(CreateEyeWarrantyContractDocument, options);
}
export type CreateEyeWarrantyContractMutationHookResult = ReturnType<
  typeof useCreateEyeWarrantyContractMutation
>;
export type CreateEyeWarrantyContractMutationResult =
  ApolloReactCommon.MutationResult<CreateEyeWarrantyContractMutation>;
export type CreateEyeWarrantyContractMutationOptions =
  ApolloReactCommon.BaseMutationOptions<
    CreateEyeWarrantyContractMutation,
    CreateEyeWarrantyContractMutationVariables
  >;
export const UpdateEyeWarrantyContractDocument = gql`
  mutation UpdateEyeWarrantyContract($input: EyeWarrantyContractUpdateInput!) {
    updateEyeWarrantyContract(input: $input) {
      id
    }
  }
`;
export type UpdateEyeWarrantyContractMutationFn =
  ApolloReactCommon.MutationFunction<
    UpdateEyeWarrantyContractMutation,
    UpdateEyeWarrantyContractMutationVariables
  >;

/**
 * __useUpdateEyeWarrantyContractMutation__
 *
 * To run a mutation, you first call `useUpdateEyeWarrantyContractMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateEyeWarrantyContractMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateEyeWarrantyContractMutation, { data, loading, error }] = useUpdateEyeWarrantyContractMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateEyeWarrantyContractMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    UpdateEyeWarrantyContractMutation,
    UpdateEyeWarrantyContractMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    UpdateEyeWarrantyContractMutation,
    UpdateEyeWarrantyContractMutationVariables
  >(UpdateEyeWarrantyContractDocument, options);
}
export type UpdateEyeWarrantyContractMutationHookResult = ReturnType<
  typeof useUpdateEyeWarrantyContractMutation
>;
export type UpdateEyeWarrantyContractMutationResult =
  ApolloReactCommon.MutationResult<UpdateEyeWarrantyContractMutation>;
export type UpdateEyeWarrantyContractMutationOptions =
  ApolloReactCommon.BaseMutationOptions<
    UpdateEyeWarrantyContractMutation,
    UpdateEyeWarrantyContractMutationVariables
  >;
export const DeleteEyeWarrantyContractDocument = gql`
  mutation DeleteEyeWarrantyContract($deleteEyeWarrantyContractId: ID!) {
    deleteEyeWarrantyContract(id: $deleteEyeWarrantyContractId)
  }
`;
export type DeleteEyeWarrantyContractMutationFn =
  ApolloReactCommon.MutationFunction<
    DeleteEyeWarrantyContractMutation,
    DeleteEyeWarrantyContractMutationVariables
  >;

/**
 * __useDeleteEyeWarrantyContractMutation__
 *
 * To run a mutation, you first call `useDeleteEyeWarrantyContractMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteEyeWarrantyContractMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteEyeWarrantyContractMutation, { data, loading, error }] = useDeleteEyeWarrantyContractMutation({
 *   variables: {
 *      deleteEyeWarrantyContractId: // value for 'deleteEyeWarrantyContractId'
 *   },
 * });
 */
export function useDeleteEyeWarrantyContractMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    DeleteEyeWarrantyContractMutation,
    DeleteEyeWarrantyContractMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    DeleteEyeWarrantyContractMutation,
    DeleteEyeWarrantyContractMutationVariables
  >(DeleteEyeWarrantyContractDocument, options);
}
export type DeleteEyeWarrantyContractMutationHookResult = ReturnType<
  typeof useDeleteEyeWarrantyContractMutation
>;
export type DeleteEyeWarrantyContractMutationResult =
  ApolloReactCommon.MutationResult<DeleteEyeWarrantyContractMutation>;
export type DeleteEyeWarrantyContractMutationOptions =
  ApolloReactCommon.BaseMutationOptions<
    DeleteEyeWarrantyContractMutation,
    DeleteEyeWarrantyContractMutationVariables
  >;
export const CreateEyeWarrantyContractItemDocument = gql`
  mutation CreateEyeWarrantyContractItem(
    $input: EyeWarrantyContractItemCreateInput!
  ) {
    createEyeWarrantyContractItem(input: $input) {
      id
    }
  }
`;
export type CreateEyeWarrantyContractItemMutationFn =
  ApolloReactCommon.MutationFunction<
    CreateEyeWarrantyContractItemMutation,
    CreateEyeWarrantyContractItemMutationVariables
  >;

/**
 * __useCreateEyeWarrantyContractItemMutation__
 *
 * To run a mutation, you first call `useCreateEyeWarrantyContractItemMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateEyeWarrantyContractItemMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createEyeWarrantyContractItemMutation, { data, loading, error }] = useCreateEyeWarrantyContractItemMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateEyeWarrantyContractItemMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    CreateEyeWarrantyContractItemMutation,
    CreateEyeWarrantyContractItemMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    CreateEyeWarrantyContractItemMutation,
    CreateEyeWarrantyContractItemMutationVariables
  >(CreateEyeWarrantyContractItemDocument, options);
}
export type CreateEyeWarrantyContractItemMutationHookResult = ReturnType<
  typeof useCreateEyeWarrantyContractItemMutation
>;
export type CreateEyeWarrantyContractItemMutationResult =
  ApolloReactCommon.MutationResult<CreateEyeWarrantyContractItemMutation>;
export type CreateEyeWarrantyContractItemMutationOptions =
  ApolloReactCommon.BaseMutationOptions<
    CreateEyeWarrantyContractItemMutation,
    CreateEyeWarrantyContractItemMutationVariables
  >;
export const UpdateEyeWarrantyContractItemDocument = gql`
  mutation UpdateEyeWarrantyContractItem(
    $input: EyeWarrantyContractItemUpdateInput!
  ) {
    updateEyeWarrantyContractItem(input: $input) {
      id
    }
  }
`;
export type UpdateEyeWarrantyContractItemMutationFn =
  ApolloReactCommon.MutationFunction<
    UpdateEyeWarrantyContractItemMutation,
    UpdateEyeWarrantyContractItemMutationVariables
  >;

/**
 * __useUpdateEyeWarrantyContractItemMutation__
 *
 * To run a mutation, you first call `useUpdateEyeWarrantyContractItemMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateEyeWarrantyContractItemMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateEyeWarrantyContractItemMutation, { data, loading, error }] = useUpdateEyeWarrantyContractItemMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateEyeWarrantyContractItemMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    UpdateEyeWarrantyContractItemMutation,
    UpdateEyeWarrantyContractItemMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    UpdateEyeWarrantyContractItemMutation,
    UpdateEyeWarrantyContractItemMutationVariables
  >(UpdateEyeWarrantyContractItemDocument, options);
}
export type UpdateEyeWarrantyContractItemMutationHookResult = ReturnType<
  typeof useUpdateEyeWarrantyContractItemMutation
>;
export type UpdateEyeWarrantyContractItemMutationResult =
  ApolloReactCommon.MutationResult<UpdateEyeWarrantyContractItemMutation>;
export type UpdateEyeWarrantyContractItemMutationOptions =
  ApolloReactCommon.BaseMutationOptions<
    UpdateEyeWarrantyContractItemMutation,
    UpdateEyeWarrantyContractItemMutationVariables
  >;
export const DeleteEyeWarrantyContractItemDocument = gql`
  mutation DeleteEyeWarrantyContractItem(
    $deleteEyeWarrantyContractItemId: ID!
  ) {
    deleteEyeWarrantyContractItem(id: $deleteEyeWarrantyContractItemId)
  }
`;
export type DeleteEyeWarrantyContractItemMutationFn =
  ApolloReactCommon.MutationFunction<
    DeleteEyeWarrantyContractItemMutation,
    DeleteEyeWarrantyContractItemMutationVariables
  >;

/**
 * __useDeleteEyeWarrantyContractItemMutation__
 *
 * To run a mutation, you first call `useDeleteEyeWarrantyContractItemMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteEyeWarrantyContractItemMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteEyeWarrantyContractItemMutation, { data, loading, error }] = useDeleteEyeWarrantyContractItemMutation({
 *   variables: {
 *      deleteEyeWarrantyContractItemId: // value for 'deleteEyeWarrantyContractItemId'
 *   },
 * });
 */
export function useDeleteEyeWarrantyContractItemMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    DeleteEyeWarrantyContractItemMutation,
    DeleteEyeWarrantyContractItemMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    DeleteEyeWarrantyContractItemMutation,
    DeleteEyeWarrantyContractItemMutationVariables
  >(DeleteEyeWarrantyContractItemDocument, options);
}
export type DeleteEyeWarrantyContractItemMutationHookResult = ReturnType<
  typeof useDeleteEyeWarrantyContractItemMutation
>;
export type DeleteEyeWarrantyContractItemMutationResult =
  ApolloReactCommon.MutationResult<DeleteEyeWarrantyContractItemMutation>;
export type DeleteEyeWarrantyContractItemMutationOptions =
  ApolloReactCommon.BaseMutationOptions<
    DeleteEyeWarrantyContractItemMutation,
    DeleteEyeWarrantyContractItemMutationVariables
  >;
export const EyeWarrantyContractsDocument = gql`
  query eyeWarrantyContracts(
    $limit: Int
    $offset: Int
    $filters: EyeWarrantyContractSearchInput!
  ) {
    eyeWarrantyContracts(limit: $limit, offset: $offset, filters: $filters) {
      count
      rows {
        id
        code
        orderCode
        approvalCode
        customerId
        customer {
          code
          name
          address
        }
        createdAt
        updatedAt
        createdUser {
          id
          name
          createdAt
          updatedAt
        }
        updatedUser {
          id
          name
          createdAt
          updatedAt
        }
        contactPerson
        contactPhone
        company {
          id
          name
        }
        dept {
          id
          code
          name
        }
        warrantyMonths
        warrantyDate1
        warrantyDate2
        warrantyPeriodTypeId
        eyeWarrantyPeriodType {
          name
        }
        region {
          id
          name
        }
        user {
          id
          name
          dept {
            id
            name
          }
        }
        status
        description
        memo
        eyeWarrantyContractItems {
          materialId
          materialCode
          materialName
          materialModel
          materialSpec
          materialUnit
          qty
          udi
          sn
          memo
        }
        eyeServiceOrder {
          id
          priority
          code
          eyeServiceOrderType {
            id
            name
          }
          estimatedDate
          customer {
            id
            code
            name
          }
          status
          approvalStatus
          assigneeDept {
            id
            name
          }
          assigneeUser {
            id
            name
          }
          approverUser {
            id
            name
          }
          eyeServiceOrderWorkDiaries {
            id
            date
            hours
            status
            remark
            users {
              id
              name
            }
            eyeServiceOrder {
              code
              status
              eyeServiceOrderType {
                name
              }
              customer {
                id
                name
              }
              assigneeDept {
                id
                name
              }
            }
          }
        }
      }
    }
  }
`;

/**
 * __useEyeWarrantyContractsQuery__
 *
 * To run a query within a React component, call `useEyeWarrantyContractsQuery` and pass it any options that fit your needs.
 * When your component renders, `useEyeWarrantyContractsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEyeWarrantyContractsQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function useEyeWarrantyContractsQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    EyeWarrantyContractsQuery,
    EyeWarrantyContractsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    EyeWarrantyContractsQuery,
    EyeWarrantyContractsQueryVariables
  >(EyeWarrantyContractsDocument, options);
}
export function useEyeWarrantyContractsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    EyeWarrantyContractsQuery,
    EyeWarrantyContractsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    EyeWarrantyContractsQuery,
    EyeWarrantyContractsQueryVariables
  >(EyeWarrantyContractsDocument, options);
}
export type EyeWarrantyContractsQueryHookResult = ReturnType<
  typeof useEyeWarrantyContractsQuery
>;
export type EyeWarrantyContractsLazyQueryHookResult = ReturnType<
  typeof useEyeWarrantyContractsLazyQuery
>;
export type EyeWarrantyContractsQueryResult = ApolloReactCommon.QueryResult<
  EyeWarrantyContractsQuery,
  EyeWarrantyContractsQueryVariables
>;
export const EyeWarrantyPeriodTypesDocument = gql`
  query EyeWarrantyPeriodTypes($filters: EyeWarrantyPeriodTypeSearchInput!) {
    eyeWarrantyPeriodTypes(filters: $filters) {
      rows {
        name
        id
      }
    }
  }
`;

/**
 * __useEyeWarrantyPeriodTypesQuery__
 *
 * To run a query within a React component, call `useEyeWarrantyPeriodTypesQuery` and pass it any options that fit your needs.
 * When your component renders, `useEyeWarrantyPeriodTypesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEyeWarrantyPeriodTypesQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function useEyeWarrantyPeriodTypesQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    EyeWarrantyPeriodTypesQuery,
    EyeWarrantyPeriodTypesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    EyeWarrantyPeriodTypesQuery,
    EyeWarrantyPeriodTypesQueryVariables
  >(EyeWarrantyPeriodTypesDocument, options);
}
export function useEyeWarrantyPeriodTypesLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    EyeWarrantyPeriodTypesQuery,
    EyeWarrantyPeriodTypesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    EyeWarrantyPeriodTypesQuery,
    EyeWarrantyPeriodTypesQueryVariables
  >(EyeWarrantyPeriodTypesDocument, options);
}
export type EyeWarrantyPeriodTypesQueryHookResult = ReturnType<
  typeof useEyeWarrantyPeriodTypesQuery
>;
export type EyeWarrantyPeriodTypesLazyQueryHookResult = ReturnType<
  typeof useEyeWarrantyPeriodTypesLazyQuery
>;
export type EyeWarrantyPeriodTypesQueryResult = ApolloReactCommon.QueryResult<
  EyeWarrantyPeriodTypesQuery,
  EyeWarrantyPeriodTypesQueryVariables
>;
export const EyeWarrantyContractItemsDocument = gql`
  query EyeWarrantyContractItems(
    $filters: EyeWarrantyContractItemSearchInput!
    $offset: Int
    $limit: Int
  ) {
    eyeWarrantyContractItems(
      filters: $filters
      offset: $offset
      limit: $limit
    ) {
      rows {
        id
        materialId
        materialCode
        materialModel
        materialSpec
        materialUnit
        materialName
        sn
        udi
        qty
        memo
      }
    }
  }
`;

/**
 * __useEyeWarrantyContractItemsQuery__
 *
 * To run a query within a React component, call `useEyeWarrantyContractItemsQuery` and pass it any options that fit your needs.
 * When your component renders, `useEyeWarrantyContractItemsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEyeWarrantyContractItemsQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useEyeWarrantyContractItemsQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    EyeWarrantyContractItemsQuery,
    EyeWarrantyContractItemsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    EyeWarrantyContractItemsQuery,
    EyeWarrantyContractItemsQueryVariables
  >(EyeWarrantyContractItemsDocument, options);
}
export function useEyeWarrantyContractItemsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    EyeWarrantyContractItemsQuery,
    EyeWarrantyContractItemsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    EyeWarrantyContractItemsQuery,
    EyeWarrantyContractItemsQueryVariables
  >(EyeWarrantyContractItemsDocument, options);
}
export type EyeWarrantyContractItemsQueryHookResult = ReturnType<
  typeof useEyeWarrantyContractItemsQuery
>;
export type EyeWarrantyContractItemsLazyQueryHookResult = ReturnType<
  typeof useEyeWarrantyContractItemsLazyQuery
>;
export type EyeWarrantyContractItemsQueryResult = ApolloReactCommon.QueryResult<
  EyeWarrantyContractItemsQuery,
  EyeWarrantyContractItemsQueryVariables
>;
export const EcInventoryModelsDocument = gql`
  query EcInventoryModels {
    EcInventoryModels {
      id
      name
    }
  }
`;

/**
 * __useEcInventoryModelsQuery__
 *
 * To run a query within a React component, call `useEcInventoryModelsQuery` and pass it any options that fit your needs.
 * When your component renders, `useEcInventoryModelsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEcInventoryModelsQuery({
 *   variables: {
 *   },
 * });
 */
export function useEcInventoryModelsQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    EcInventoryModelsQuery,
    EcInventoryModelsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    EcInventoryModelsQuery,
    EcInventoryModelsQueryVariables
  >(EcInventoryModelsDocument, options);
}
export function useEcInventoryModelsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    EcInventoryModelsQuery,
    EcInventoryModelsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    EcInventoryModelsQuery,
    EcInventoryModelsQueryVariables
  >(EcInventoryModelsDocument, options);
}
export type EcInventoryModelsQueryHookResult = ReturnType<
  typeof useEcInventoryModelsQuery
>;
export type EcInventoryModelsLazyQueryHookResult = ReturnType<
  typeof useEcInventoryModelsLazyQuery
>;
export type EcInventoryModelsQueryResult = ApolloReactCommon.QueryResult<
  EcInventoryModelsQuery,
  EcInventoryModelsQueryVariables
>;
export const EcInventorySpecsDocument = gql`
  query EcInventorySpecs {
    EcInventorySpecs {
      id
      modelId
      name
      model {
        id
        name
      }
    }
  }
`;

/**
 * __useEcInventorySpecsQuery__
 *
 * To run a query within a React component, call `useEcInventorySpecsQuery` and pass it any options that fit your needs.
 * When your component renders, `useEcInventorySpecsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEcInventorySpecsQuery({
 *   variables: {
 *   },
 * });
 */
export function useEcInventorySpecsQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    EcInventorySpecsQuery,
    EcInventorySpecsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    EcInventorySpecsQuery,
    EcInventorySpecsQueryVariables
  >(EcInventorySpecsDocument, options);
}
export function useEcInventorySpecsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    EcInventorySpecsQuery,
    EcInventorySpecsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    EcInventorySpecsQuery,
    EcInventorySpecsQueryVariables
  >(EcInventorySpecsDocument, options);
}
export type EcInventorySpecsQueryHookResult = ReturnType<
  typeof useEcInventorySpecsQuery
>;
export type EcInventorySpecsLazyQueryHookResult = ReturnType<
  typeof useEcInventorySpecsLazyQuery
>;
export type EcInventorySpecsQueryResult = ApolloReactCommon.QueryResult<
  EcInventorySpecsQuery,
  EcInventorySpecsQueryVariables
>;
export const EcInventoryWarehousesDocument = gql`
  query EcInventoryWarehouses {
    EcInventoryWarehouses {
      id
      name
    }
  }
`;

/**
 * __useEcInventoryWarehousesQuery__
 *
 * To run a query within a React component, call `useEcInventoryWarehousesQuery` and pass it any options that fit your needs.
 * When your component renders, `useEcInventoryWarehousesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEcInventoryWarehousesQuery({
 *   variables: {
 *   },
 * });
 */
export function useEcInventoryWarehousesQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    EcInventoryWarehousesQuery,
    EcInventoryWarehousesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    EcInventoryWarehousesQuery,
    EcInventoryWarehousesQueryVariables
  >(EcInventoryWarehousesDocument, options);
}
export function useEcInventoryWarehousesLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    EcInventoryWarehousesQuery,
    EcInventoryWarehousesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    EcInventoryWarehousesQuery,
    EcInventoryWarehousesQueryVariables
  >(EcInventoryWarehousesDocument, options);
}
export type EcInventoryWarehousesQueryHookResult = ReturnType<
  typeof useEcInventoryWarehousesQuery
>;
export type EcInventoryWarehousesLazyQueryHookResult = ReturnType<
  typeof useEcInventoryWarehousesLazyQuery
>;
export type EcInventoryWarehousesQueryResult = ApolloReactCommon.QueryResult<
  EcInventoryWarehousesQuery,
  EcInventoryWarehousesQueryVariables
>;
export const EcInventoryStatDocument = gql`
  query EcInventoryStat($filter: EcInventoryStatFilterInput!) {
    EcInventoryStat(filter: $filter) {
      details {
        modelId
        qty
        warehouses {
          warehouse
          qty
          statuses {
            status
            qty
          }
        }
        rentalPeriods {
          rentalPeriod
          qty
        }
      }
    }
  }
`;

/**
 * __useEcInventoryStatQuery__
 *
 * To run a query within a React component, call `useEcInventoryStatQuery` and pass it any options that fit your needs.
 * When your component renders, `useEcInventoryStatQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEcInventoryStatQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useEcInventoryStatQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    EcInventoryStatQuery,
    EcInventoryStatQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    EcInventoryStatQuery,
    EcInventoryStatQueryVariables
  >(EcInventoryStatDocument, options);
}
export function useEcInventoryStatLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    EcInventoryStatQuery,
    EcInventoryStatQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    EcInventoryStatQuery,
    EcInventoryStatQueryVariables
  >(EcInventoryStatDocument, options);
}
export type EcInventoryStatQueryHookResult = ReturnType<
  typeof useEcInventoryStatQuery
>;
export type EcInventoryStatLazyQueryHookResult = ReturnType<
  typeof useEcInventoryStatLazyQuery
>;
export type EcInventoryStatQueryResult = ApolloReactCommon.QueryResult<
  EcInventoryStatQuery,
  EcInventoryStatQueryVariables
>;
export const EcInventoryDetailsDocument = gql`
  query EcInventoryDetails($filter: EcInventoryDetailFilterInput!) {
    EcInventoryDetails(filter: $filter) {
      model
      spec
      status
      rentalPeriod
      warehouse
      materialCode
      materialName
      customerCode
      customerName
      limitDate
      orderDate
      qty
    }
  }
`;

/**
 * __useEcInventoryDetailsQuery__
 *
 * To run a query within a React component, call `useEcInventoryDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useEcInventoryDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEcInventoryDetailsQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useEcInventoryDetailsQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    EcInventoryDetailsQuery,
    EcInventoryDetailsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    EcInventoryDetailsQuery,
    EcInventoryDetailsQueryVariables
  >(EcInventoryDetailsDocument, options);
}
export function useEcInventoryDetailsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    EcInventoryDetailsQuery,
    EcInventoryDetailsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    EcInventoryDetailsQuery,
    EcInventoryDetailsQueryVariables
  >(EcInventoryDetailsDocument, options);
}
export type EcInventoryDetailsQueryHookResult = ReturnType<
  typeof useEcInventoryDetailsQuery
>;
export type EcInventoryDetailsLazyQueryHookResult = ReturnType<
  typeof useEcInventoryDetailsLazyQuery
>;
export type EcInventoryDetailsQueryResult = ApolloReactCommon.QueryResult<
  EcInventoryDetailsQuery,
  EcInventoryDetailsQueryVariables
>;
export const CreateMaterialDocument = gql`
  mutation CreateMaterial($input: MaterialCreateInput!) {
    createMaterial(input: $input) {
      id
      name
    }
  }
`;
export type CreateMaterialMutationFn = ApolloReactCommon.MutationFunction<
  CreateMaterialMutation,
  CreateMaterialMutationVariables
>;

/**
 * __useCreateMaterialMutation__
 *
 * To run a mutation, you first call `useCreateMaterialMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateMaterialMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createMaterialMutation, { data, loading, error }] = useCreateMaterialMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateMaterialMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    CreateMaterialMutation,
    CreateMaterialMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    CreateMaterialMutation,
    CreateMaterialMutationVariables
  >(CreateMaterialDocument, options);
}
export type CreateMaterialMutationHookResult = ReturnType<
  typeof useCreateMaterialMutation
>;
export type CreateMaterialMutationResult =
  ApolloReactCommon.MutationResult<CreateMaterialMutation>;
export type CreateMaterialMutationOptions =
  ApolloReactCommon.BaseMutationOptions<
    CreateMaterialMutation,
    CreateMaterialMutationVariables
  >;
export const UpdateMaterialDocument = gql`
  mutation UpdateMaterial($input: MaterialUpdateInput!) {
    updateMaterial(input: $input) {
      id
      name
    }
  }
`;
export type UpdateMaterialMutationFn = ApolloReactCommon.MutationFunction<
  UpdateMaterialMutation,
  UpdateMaterialMutationVariables
>;

/**
 * __useUpdateMaterialMutation__
 *
 * To run a mutation, you first call `useUpdateMaterialMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateMaterialMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateMaterialMutation, { data, loading, error }] = useUpdateMaterialMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateMaterialMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    UpdateMaterialMutation,
    UpdateMaterialMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    UpdateMaterialMutation,
    UpdateMaterialMutationVariables
  >(UpdateMaterialDocument, options);
}
export type UpdateMaterialMutationHookResult = ReturnType<
  typeof useUpdateMaterialMutation
>;
export type UpdateMaterialMutationResult =
  ApolloReactCommon.MutationResult<UpdateMaterialMutation>;
export type UpdateMaterialMutationOptions =
  ApolloReactCommon.BaseMutationOptions<
    UpdateMaterialMutation,
    UpdateMaterialMutationVariables
  >;
export const MaterialsDocument = gql`
  query materials($offset: Int, $limit: Int, $filters: MaterialSearchInput!) {
    materials(offset: $offset, limit: $limit, filters: $filters) {
      count
      pageInfo {
        hasNextPage
      }
      rows {
        id
        code
        brand
        model
        name
        name2
        spec
        unit
        color
        status
        type {
          id
          name
        }
        companyId
        company {
          id
          name
          code
        }
        regionId
        region {
          id
          name
          code
        }
        repairType {
          id
          name
        }
        supplier {
          id
          name
        }
        images {
          id
          url
          name
          size
          s3Key
        }
        taxRate
        salePrice
        saleCurrencyId
        costPrice
        costCurrencyId
        salesTypes {
          id
          name
        }
        salesProductTypes {
          id
          name
        }
        countingUnit {
          id
          name
        }
        syncSource {
          id
          name
        }
        createdUser {
          id
          name
        }
        updatedUser {
          id
          name
        }
        createdAt
        updatedAt
      }
    }
  }
`;

/**
 * __useMaterialsQuery__
 *
 * To run a query within a React component, call `useMaterialsQuery` and pass it any options that fit your needs.
 * When your component renders, `useMaterialsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMaterialsQuery({
 *   variables: {
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function useMaterialsQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    MaterialsQuery,
    MaterialsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<MaterialsQuery, MaterialsQueryVariables>(
    MaterialsDocument,
    options,
  );
}
export function useMaterialsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    MaterialsQuery,
    MaterialsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<MaterialsQuery, MaterialsQueryVariables>(
    MaterialsDocument,
    options,
  );
}
export type MaterialsQueryHookResult = ReturnType<typeof useMaterialsQuery>;
export type MaterialsLazyQueryHookResult = ReturnType<
  typeof useMaterialsLazyQuery
>;
export type MaterialsQueryResult = ApolloReactCommon.QueryResult<
  MaterialsQuery,
  MaterialsQueryVariables
>;
export const MaterialRepairTypesDocument = gql`
  query materialRepairTypes($filters: MaterialRepairTypeSearchInput!) {
    materialRepairTypes(filters: $filters) {
      rows {
        id
        name
        code
      }
    }
  }
`;

/**
 * __useMaterialRepairTypesQuery__
 *
 * To run a query within a React component, call `useMaterialRepairTypesQuery` and pass it any options that fit your needs.
 * When your component renders, `useMaterialRepairTypesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMaterialRepairTypesQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function useMaterialRepairTypesQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    MaterialRepairTypesQuery,
    MaterialRepairTypesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    MaterialRepairTypesQuery,
    MaterialRepairTypesQueryVariables
  >(MaterialRepairTypesDocument, options);
}
export function useMaterialRepairTypesLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    MaterialRepairTypesQuery,
    MaterialRepairTypesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    MaterialRepairTypesQuery,
    MaterialRepairTypesQueryVariables
  >(MaterialRepairTypesDocument, options);
}
export type MaterialRepairTypesQueryHookResult = ReturnType<
  typeof useMaterialRepairTypesQuery
>;
export type MaterialRepairTypesLazyQueryHookResult = ReturnType<
  typeof useMaterialRepairTypesLazyQuery
>;
export type MaterialRepairTypesQueryResult = ApolloReactCommon.QueryResult<
  MaterialRepairTypesQuery,
  MaterialRepairTypesQueryVariables
>;
export const SuppliersDocument = gql`
  query suppliers($filters: SupplierSearchInput!) {
    suppliers(filters: $filters) {
      rows {
        id
        code
        name
        name2
      }
    }
  }
`;

/**
 * __useSuppliersQuery__
 *
 * To run a query within a React component, call `useSuppliersQuery` and pass it any options that fit your needs.
 * When your component renders, `useSuppliersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSuppliersQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function useSuppliersQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    SuppliersQuery,
    SuppliersQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<SuppliersQuery, SuppliersQueryVariables>(
    SuppliersDocument,
    options,
  );
}
export function useSuppliersLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    SuppliersQuery,
    SuppliersQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<SuppliersQuery, SuppliersQueryVariables>(
    SuppliersDocument,
    options,
  );
}
export type SuppliersQueryHookResult = ReturnType<typeof useSuppliersQuery>;
export type SuppliersLazyQueryHookResult = ReturnType<
  typeof useSuppliersLazyQuery
>;
export type SuppliersQueryResult = ApolloReactCommon.QueryResult<
  SuppliersQuery,
  SuppliersQueryVariables
>;
export const CountingUnitsDocument = gql`
  query CountingUnits($filters: CountingUnitSearchInput!, $limit: Int) {
    countingUnits(filters: $filters, limit: $limit) {
      rows {
        id
        name
        code
      }
    }
  }
`;

/**
 * __useCountingUnitsQuery__
 *
 * To run a query within a React component, call `useCountingUnitsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCountingUnitsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCountingUnitsQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useCountingUnitsQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    CountingUnitsQuery,
    CountingUnitsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    CountingUnitsQuery,
    CountingUnitsQueryVariables
  >(CountingUnitsDocument, options);
}
export function useCountingUnitsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    CountingUnitsQuery,
    CountingUnitsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    CountingUnitsQuery,
    CountingUnitsQueryVariables
  >(CountingUnitsDocument, options);
}
export type CountingUnitsQueryHookResult = ReturnType<
  typeof useCountingUnitsQuery
>;
export type CountingUnitsLazyQueryHookResult = ReturnType<
  typeof useCountingUnitsLazyQuery
>;
export type CountingUnitsQueryResult = ApolloReactCommon.QueryResult<
  CountingUnitsQuery,
  CountingUnitsQueryVariables
>;
export const ReadNotificationsDocument = gql`
  mutation readNotifications($ids: [ID!]!) {
    readNotifications(ids: $ids) {
      id
      userId
      isRead
      createdAt
      message
      type
    }
  }
`;
export type ReadNotificationsMutationFn = ApolloReactCommon.MutationFunction<
  ReadNotificationsMutation,
  ReadNotificationsMutationVariables
>;

/**
 * __useReadNotificationsMutation__
 *
 * To run a mutation, you first call `useReadNotificationsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useReadNotificationsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [readNotificationsMutation, { data, loading, error }] = useReadNotificationsMutation({
 *   variables: {
 *      ids: // value for 'ids'
 *   },
 * });
 */
export function useReadNotificationsMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    ReadNotificationsMutation,
    ReadNotificationsMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    ReadNotificationsMutation,
    ReadNotificationsMutationVariables
  >(ReadNotificationsDocument, options);
}
export type ReadNotificationsMutationHookResult = ReturnType<
  typeof useReadNotificationsMutation
>;
export type ReadNotificationsMutationResult =
  ApolloReactCommon.MutationResult<ReadNotificationsMutation>;
export type ReadNotificationsMutationOptions =
  ApolloReactCommon.BaseMutationOptions<
    ReadNotificationsMutation,
    ReadNotificationsMutationVariables
  >;
export const NotificationsDocument = gql`
  query notifications(
    $limit: Int
    $offset: Int
    $filters: NotificationSearchInput!
  ) {
    notifications(limit: $limit, offset: $offset, filters: $filters) {
      count
      rows {
        id
        userId
        type
        message
        values {
          ... on NotificationActionInnerPath {
            path
          }
        }
        isRead
        createdAt
        updatedAt
      }
    }
  }
`;

/**
 * __useNotificationsQuery__
 *
 * To run a query within a React component, call `useNotificationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useNotificationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNotificationsQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function useNotificationsQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    NotificationsQuery,
    NotificationsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    NotificationsQuery,
    NotificationsQueryVariables
  >(NotificationsDocument, options);
}
export function useNotificationsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    NotificationsQuery,
    NotificationsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    NotificationsQuery,
    NotificationsQueryVariables
  >(NotificationsDocument, options);
}
export type NotificationsQueryHookResult = ReturnType<
  typeof useNotificationsQuery
>;
export type NotificationsLazyQueryHookResult = ReturnType<
  typeof useNotificationsLazyQuery
>;
export type NotificationsQueryResult = ApolloReactCommon.QueryResult<
  NotificationsQuery,
  NotificationsQueryVariables
>;
export const NotificationSubscriptionDocument = gql`
  subscription notificationSubscription {
    notificationSubscription {
      id
      userId
      isRead
      createdAt
      message
      values {
        ... on NotificationActionInnerPath {
          path
        }
      }
      type
    }
  }
`;

/**
 * __useNotificationSubscriptionSubscription__
 *
 * To run a query within a React component, call `useNotificationSubscriptionSubscription` and pass it any options that fit your needs.
 * When your component renders, `useNotificationSubscriptionSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNotificationSubscriptionSubscription({
 *   variables: {
 *   },
 * });
 */
export function useNotificationSubscriptionSubscription(
  baseOptions?: ApolloReactHooks.SubscriptionHookOptions<
    NotificationSubscriptionSubscription,
    NotificationSubscriptionSubscriptionVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useSubscription<
    NotificationSubscriptionSubscription,
    NotificationSubscriptionSubscriptionVariables
  >(NotificationSubscriptionDocument, options);
}
export type NotificationSubscriptionSubscriptionHookResult = ReturnType<
  typeof useNotificationSubscriptionSubscription
>;
export type NotificationSubscriptionSubscriptionResult =
  ApolloReactCommon.SubscriptionResult<NotificationSubscriptionSubscription>;
export const BulkUpdateSalesPerformanceSalesProductUserGoalViewDocument = gql`
  mutation BulkUpdateSalesPerformanceSalesProductUserGoalView(
    $input: SalesPerformanceSalesProductUserGoalViewBulkUpdateInput!
  ) {
    bulkUpdateSalesPerformanceSalesProductUserGoalView(input: $input) {
      amount
      createdAt
      id
      month
      qty
      updatedAt
      userGoalId
    }
  }
`;
export type BulkUpdateSalesPerformanceSalesProductUserGoalViewMutationFn =
  ApolloReactCommon.MutationFunction<
    BulkUpdateSalesPerformanceSalesProductUserGoalViewMutation,
    BulkUpdateSalesPerformanceSalesProductUserGoalViewMutationVariables
  >;

/**
 * __useBulkUpdateSalesPerformanceSalesProductUserGoalViewMutation__
 *
 * To run a mutation, you first call `useBulkUpdateSalesPerformanceSalesProductUserGoalViewMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBulkUpdateSalesPerformanceSalesProductUserGoalViewMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [bulkUpdateSalesPerformanceSalesProductUserGoalViewMutation, { data, loading, error }] = useBulkUpdateSalesPerformanceSalesProductUserGoalViewMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useBulkUpdateSalesPerformanceSalesProductUserGoalViewMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    BulkUpdateSalesPerformanceSalesProductUserGoalViewMutation,
    BulkUpdateSalesPerformanceSalesProductUserGoalViewMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    BulkUpdateSalesPerformanceSalesProductUserGoalViewMutation,
    BulkUpdateSalesPerformanceSalesProductUserGoalViewMutationVariables
  >(BulkUpdateSalesPerformanceSalesProductUserGoalViewDocument, options);
}
export type BulkUpdateSalesPerformanceSalesProductUserGoalViewMutationHookResult =
  ReturnType<
    typeof useBulkUpdateSalesPerformanceSalesProductUserGoalViewMutation
  >;
export type BulkUpdateSalesPerformanceSalesProductUserGoalViewMutationResult =
  ApolloReactCommon.MutationResult<BulkUpdateSalesPerformanceSalesProductUserGoalViewMutation>;
export type BulkUpdateSalesPerformanceSalesProductUserGoalViewMutationOptions =
  ApolloReactCommon.BaseMutationOptions<
    BulkUpdateSalesPerformanceSalesProductUserGoalViewMutation,
    BulkUpdateSalesPerformanceSalesProductUserGoalViewMutationVariables
  >;
export const BulkUpdateSalesPerformanceSalesProductTeamGoalViewDocument = gql`
  mutation BulkUpdateSalesPerformanceSalesProductTeamGoalView(
    $input: SalesPerformanceSalesProductTeamGoalViewBulkUpdateInput!
  ) {
    bulkUpdateSalesPerformanceSalesProductTeamGoalView(input: $input) {
      id
      teamGoalId
      month
      amount
      qty
      createdAt
      updatedAt
    }
  }
`;
export type BulkUpdateSalesPerformanceSalesProductTeamGoalViewMutationFn =
  ApolloReactCommon.MutationFunction<
    BulkUpdateSalesPerformanceSalesProductTeamGoalViewMutation,
    BulkUpdateSalesPerformanceSalesProductTeamGoalViewMutationVariables
  >;

/**
 * __useBulkUpdateSalesPerformanceSalesProductTeamGoalViewMutation__
 *
 * To run a mutation, you first call `useBulkUpdateSalesPerformanceSalesProductTeamGoalViewMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBulkUpdateSalesPerformanceSalesProductTeamGoalViewMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [bulkUpdateSalesPerformanceSalesProductTeamGoalViewMutation, { data, loading, error }] = useBulkUpdateSalesPerformanceSalesProductTeamGoalViewMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useBulkUpdateSalesPerformanceSalesProductTeamGoalViewMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    BulkUpdateSalesPerformanceSalesProductTeamGoalViewMutation,
    BulkUpdateSalesPerformanceSalesProductTeamGoalViewMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    BulkUpdateSalesPerformanceSalesProductTeamGoalViewMutation,
    BulkUpdateSalesPerformanceSalesProductTeamGoalViewMutationVariables
  >(BulkUpdateSalesPerformanceSalesProductTeamGoalViewDocument, options);
}
export type BulkUpdateSalesPerformanceSalesProductTeamGoalViewMutationHookResult =
  ReturnType<
    typeof useBulkUpdateSalesPerformanceSalesProductTeamGoalViewMutation
  >;
export type BulkUpdateSalesPerformanceSalesProductTeamGoalViewMutationResult =
  ApolloReactCommon.MutationResult<BulkUpdateSalesPerformanceSalesProductTeamGoalViewMutation>;
export type BulkUpdateSalesPerformanceSalesProductTeamGoalViewMutationOptions =
  ApolloReactCommon.BaseMutationOptions<
    BulkUpdateSalesPerformanceSalesProductTeamGoalViewMutation,
    BulkUpdateSalesPerformanceSalesProductTeamGoalViewMutationVariables
  >;
export const BulkUpdateSalesPerformanceSalesProductUserForecastViewDocument = gql`
  mutation BulkUpdateSalesPerformanceSalesProductUserForecastView(
    $input: SalesPerformanceSalesProductUserForecastViewBulkUpdateInput!
  ) {
    bulkUpdateSalesPerformanceSalesProductUserForecastView(input: $input) {
      id
      userForecastId
      month
      qtyEarlyMonth
      qtyLateMonth
      createdAt
      updatedAt
      quota
    }
  }
`;
export type BulkUpdateSalesPerformanceSalesProductUserForecastViewMutationFn =
  ApolloReactCommon.MutationFunction<
    BulkUpdateSalesPerformanceSalesProductUserForecastViewMutation,
    BulkUpdateSalesPerformanceSalesProductUserForecastViewMutationVariables
  >;

/**
 * __useBulkUpdateSalesPerformanceSalesProductUserForecastViewMutation__
 *
 * To run a mutation, you first call `useBulkUpdateSalesPerformanceSalesProductUserForecastViewMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBulkUpdateSalesPerformanceSalesProductUserForecastViewMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [bulkUpdateSalesPerformanceSalesProductUserForecastViewMutation, { data, loading, error }] = useBulkUpdateSalesPerformanceSalesProductUserForecastViewMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useBulkUpdateSalesPerformanceSalesProductUserForecastViewMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    BulkUpdateSalesPerformanceSalesProductUserForecastViewMutation,
    BulkUpdateSalesPerformanceSalesProductUserForecastViewMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    BulkUpdateSalesPerformanceSalesProductUserForecastViewMutation,
    BulkUpdateSalesPerformanceSalesProductUserForecastViewMutationVariables
  >(BulkUpdateSalesPerformanceSalesProductUserForecastViewDocument, options);
}
export type BulkUpdateSalesPerformanceSalesProductUserForecastViewMutationHookResult =
  ReturnType<
    typeof useBulkUpdateSalesPerformanceSalesProductUserForecastViewMutation
  >;
export type BulkUpdateSalesPerformanceSalesProductUserForecastViewMutationResult =
  ApolloReactCommon.MutationResult<BulkUpdateSalesPerformanceSalesProductUserForecastViewMutation>;
export type BulkUpdateSalesPerformanceSalesProductUserForecastViewMutationOptions =
  ApolloReactCommon.BaseMutationOptions<
    BulkUpdateSalesPerformanceSalesProductUserForecastViewMutation,
    BulkUpdateSalesPerformanceSalesProductUserForecastViewMutationVariables
  >;
export const SalesPerformanceSalesProductTypesDocument = gql`
  query SalesPerformanceSalesProductTypes(
    $filters: SalesPerformanceSalesProductTypeSearchInput!
    $offset: Int
    $limit: Int
  ) {
    salesPerformanceSalesProductTypes(
      filters: $filters
      offset: $offset
      limit: $limit
    ) {
      rows {
        id
        name
        code
        createdAt
        salesTeamGroup {
          id
          name
        }
        salesTeamGroupId
        updatedAt
        viewOrder
      }
      count
    }
  }
`;

/**
 * __useSalesPerformanceSalesProductTypesQuery__
 *
 * To run a query within a React component, call `useSalesPerformanceSalesProductTypesQuery` and pass it any options that fit your needs.
 * When your component renders, `useSalesPerformanceSalesProductTypesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSalesPerformanceSalesProductTypesQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useSalesPerformanceSalesProductTypesQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    SalesPerformanceSalesProductTypesQuery,
    SalesPerformanceSalesProductTypesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    SalesPerformanceSalesProductTypesQuery,
    SalesPerformanceSalesProductTypesQueryVariables
  >(SalesPerformanceSalesProductTypesDocument, options);
}
export function useSalesPerformanceSalesProductTypesLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    SalesPerformanceSalesProductTypesQuery,
    SalesPerformanceSalesProductTypesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    SalesPerformanceSalesProductTypesQuery,
    SalesPerformanceSalesProductTypesQueryVariables
  >(SalesPerformanceSalesProductTypesDocument, options);
}
export type SalesPerformanceSalesProductTypesQueryHookResult = ReturnType<
  typeof useSalesPerformanceSalesProductTypesQuery
>;
export type SalesPerformanceSalesProductTypesLazyQueryHookResult = ReturnType<
  typeof useSalesPerformanceSalesProductTypesLazyQuery
>;
export type SalesPerformanceSalesProductTypesQueryResult =
  ApolloReactCommon.QueryResult<
    SalesPerformanceSalesProductTypesQuery,
    SalesPerformanceSalesProductTypesQueryVariables
  >;
export const SalesPerformanceSalesProductUserGoalViewDocument = gql`
  query SalesPerformanceSalesProductUserGoalView(
    $salesTeamGroupId: ID!
    $year: Int!
    $userIds: [ID!]
  ) {
    salesPerformanceSalesProductUserGoalView(
      salesTeamGroupId: $salesTeamGroupId
      year: $year
      userIds: $userIds
    ) {
      year
      salesTeamGroupId
      users {
        salesTeamUnitId
        months {
          month
          types {
            salesProductTypeId
            amount
            qty
            salesProductType {
              id
              name
              code
            }
          }
        }
        salesTeamUnit {
          id
          name
        }
      }
      salesTeamGroup {
        id
        name
        code
        directorId
        regionId
        companyId
      }
    }
  }
`;

/**
 * __useSalesPerformanceSalesProductUserGoalViewQuery__
 *
 * To run a query within a React component, call `useSalesPerformanceSalesProductUserGoalViewQuery` and pass it any options that fit your needs.
 * When your component renders, `useSalesPerformanceSalesProductUserGoalViewQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSalesPerformanceSalesProductUserGoalViewQuery({
 *   variables: {
 *      salesTeamGroupId: // value for 'salesTeamGroupId'
 *      year: // value for 'year'
 *      userIds: // value for 'userIds'
 *   },
 * });
 */
export function useSalesPerformanceSalesProductUserGoalViewQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    SalesPerformanceSalesProductUserGoalViewQuery,
    SalesPerformanceSalesProductUserGoalViewQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    SalesPerformanceSalesProductUserGoalViewQuery,
    SalesPerformanceSalesProductUserGoalViewQueryVariables
  >(SalesPerformanceSalesProductUserGoalViewDocument, options);
}
export function useSalesPerformanceSalesProductUserGoalViewLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    SalesPerformanceSalesProductUserGoalViewQuery,
    SalesPerformanceSalesProductUserGoalViewQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    SalesPerformanceSalesProductUserGoalViewQuery,
    SalesPerformanceSalesProductUserGoalViewQueryVariables
  >(SalesPerformanceSalesProductUserGoalViewDocument, options);
}
export type SalesPerformanceSalesProductUserGoalViewQueryHookResult =
  ReturnType<typeof useSalesPerformanceSalesProductUserGoalViewQuery>;
export type SalesPerformanceSalesProductUserGoalViewLazyQueryHookResult =
  ReturnType<typeof useSalesPerformanceSalesProductUserGoalViewLazyQuery>;
export type SalesPerformanceSalesProductUserGoalViewQueryResult =
  ApolloReactCommon.QueryResult<
    SalesPerformanceSalesProductUserGoalViewQuery,
    SalesPerformanceSalesProductUserGoalViewQueryVariables
  >;
export const SalesPerformanceSalesProductTeamGoalViewDocument = gql`
  query SalesPerformanceSalesProductTeamGoalView(
    $salesTeamGroupId: ID!
    $year: Int!
    $salesTeamIds: [ID!]
  ) {
    salesPerformanceSalesProductTeamGoalView(
      salesTeamGroupId: $salesTeamGroupId
      year: $year
      salesTeamIds: $salesTeamIds
    ) {
      year
      salesTeamGroupId
      teams {
        months {
          month
          types {
            salesProductTypeId
            amount
            qty
            salesProductType {
              id
              name
              code
            }
          }
        }
        salesTeam {
          id
          name
        }
        salesTeamId
      }
      salesTeamGroup {
        id
        name
        code
        directorId
        regionId
        companyId
      }
    }
  }
`;

/**
 * __useSalesPerformanceSalesProductTeamGoalViewQuery__
 *
 * To run a query within a React component, call `useSalesPerformanceSalesProductTeamGoalViewQuery` and pass it any options that fit your needs.
 * When your component renders, `useSalesPerformanceSalesProductTeamGoalViewQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSalesPerformanceSalesProductTeamGoalViewQuery({
 *   variables: {
 *      salesTeamGroupId: // value for 'salesTeamGroupId'
 *      year: // value for 'year'
 *      salesTeamIds: // value for 'salesTeamIds'
 *   },
 * });
 */
export function useSalesPerformanceSalesProductTeamGoalViewQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    SalesPerformanceSalesProductTeamGoalViewQuery,
    SalesPerformanceSalesProductTeamGoalViewQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    SalesPerformanceSalesProductTeamGoalViewQuery,
    SalesPerformanceSalesProductTeamGoalViewQueryVariables
  >(SalesPerformanceSalesProductTeamGoalViewDocument, options);
}
export function useSalesPerformanceSalesProductTeamGoalViewLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    SalesPerformanceSalesProductTeamGoalViewQuery,
    SalesPerformanceSalesProductTeamGoalViewQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    SalesPerformanceSalesProductTeamGoalViewQuery,
    SalesPerformanceSalesProductTeamGoalViewQueryVariables
  >(SalesPerformanceSalesProductTeamGoalViewDocument, options);
}
export type SalesPerformanceSalesProductTeamGoalViewQueryHookResult =
  ReturnType<typeof useSalesPerformanceSalesProductTeamGoalViewQuery>;
export type SalesPerformanceSalesProductTeamGoalViewLazyQueryHookResult =
  ReturnType<typeof useSalesPerformanceSalesProductTeamGoalViewLazyQuery>;
export type SalesPerformanceSalesProductTeamGoalViewQueryResult =
  ApolloReactCommon.QueryResult<
    SalesPerformanceSalesProductTeamGoalViewQuery,
    SalesPerformanceSalesProductTeamGoalViewQueryVariables
  >;
export const SalesPerformanceSalesProductUserForecastViewDocument = gql`
  query SalesPerformanceSalesProductUserForecastView(
    $salesTeamGroupId: ID!
    $year: Int!
    $month1: Int
    $month2: Int
    $userIds: [ID!]
  ) {
    salesPerformanceSalesProductUserForecastView(
      salesTeamGroupId: $salesTeamGroupId
      year: $year
      month1: $month1
      month2: $month2
      userIds: $userIds
    ) {
      year
      salesTeamGroupId
      salesTeamGroup {
        id
        name
      }
      overall {
        months {
          month
          types {
            salesProductTypeId
            salesProductType {
              id
              name
              code
            }
            quota
            qtyEarlyMonth
            qtyEarlyMonthAccuracyRate
            qtyLateMonth
            qtyLateMonthAccuracyRate
            qtyActual
          }
        }
      }
      users {
        salesTeamUnit {
          id
          name
        }
        months {
          month
          types {
            salesProductTypeId
            salesProductType {
              id
              name
              code
            }
            quota
            quotaAchievementRate
            qtyEarlyMonth
            qtyEarlyMonthAccuracyRate
            qtyLateMonth
            qtyLateMonthAccuracyRate
            qtyActual
          }
        }
      }
    }
  }
`;

/**
 * __useSalesPerformanceSalesProductUserForecastViewQuery__
 *
 * To run a query within a React component, call `useSalesPerformanceSalesProductUserForecastViewQuery` and pass it any options that fit your needs.
 * When your component renders, `useSalesPerformanceSalesProductUserForecastViewQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSalesPerformanceSalesProductUserForecastViewQuery({
 *   variables: {
 *      salesTeamGroupId: // value for 'salesTeamGroupId'
 *      year: // value for 'year'
 *      month1: // value for 'month1'
 *      month2: // value for 'month2'
 *      userIds: // value for 'userIds'
 *   },
 * });
 */
export function useSalesPerformanceSalesProductUserForecastViewQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    SalesPerformanceSalesProductUserForecastViewQuery,
    SalesPerformanceSalesProductUserForecastViewQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    SalesPerformanceSalesProductUserForecastViewQuery,
    SalesPerformanceSalesProductUserForecastViewQueryVariables
  >(SalesPerformanceSalesProductUserForecastViewDocument, options);
}
export function useSalesPerformanceSalesProductUserForecastViewLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    SalesPerformanceSalesProductUserForecastViewQuery,
    SalesPerformanceSalesProductUserForecastViewQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    SalesPerformanceSalesProductUserForecastViewQuery,
    SalesPerformanceSalesProductUserForecastViewQueryVariables
  >(SalesPerformanceSalesProductUserForecastViewDocument, options);
}
export type SalesPerformanceSalesProductUserForecastViewQueryHookResult =
  ReturnType<typeof useSalesPerformanceSalesProductUserForecastViewQuery>;
export type SalesPerformanceSalesProductUserForecastViewLazyQueryHookResult =
  ReturnType<typeof useSalesPerformanceSalesProductUserForecastViewLazyQuery>;
export type SalesPerformanceSalesProductUserForecastViewQueryResult =
  ApolloReactCommon.QueryResult<
    SalesPerformanceSalesProductUserForecastViewQuery,
    SalesPerformanceSalesProductUserForecastViewQueryVariables
  >;
export const BulkUpdateSalesPerformanceSalesUserGoalViewDocument = gql`
  mutation BulkUpdateSalesPerformanceSalesUserGoalView(
    $input: SalesPerformanceSalesUserGoalViewBulkUpdateInput!
  ) {
    bulkUpdateSalesPerformanceSalesUserGoalView(input: $input) {
      id
      month
      userGoalId
      amount
      createdAt
      updatedAt
    }
  }
`;
export type BulkUpdateSalesPerformanceSalesUserGoalViewMutationFn =
  ApolloReactCommon.MutationFunction<
    BulkUpdateSalesPerformanceSalesUserGoalViewMutation,
    BulkUpdateSalesPerformanceSalesUserGoalViewMutationVariables
  >;

/**
 * __useBulkUpdateSalesPerformanceSalesUserGoalViewMutation__
 *
 * To run a mutation, you first call `useBulkUpdateSalesPerformanceSalesUserGoalViewMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBulkUpdateSalesPerformanceSalesUserGoalViewMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [bulkUpdateSalesPerformanceSalesUserGoalViewMutation, { data, loading, error }] = useBulkUpdateSalesPerformanceSalesUserGoalViewMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useBulkUpdateSalesPerformanceSalesUserGoalViewMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    BulkUpdateSalesPerformanceSalesUserGoalViewMutation,
    BulkUpdateSalesPerformanceSalesUserGoalViewMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    BulkUpdateSalesPerformanceSalesUserGoalViewMutation,
    BulkUpdateSalesPerformanceSalesUserGoalViewMutationVariables
  >(BulkUpdateSalesPerformanceSalesUserGoalViewDocument, options);
}
export type BulkUpdateSalesPerformanceSalesUserGoalViewMutationHookResult =
  ReturnType<typeof useBulkUpdateSalesPerformanceSalesUserGoalViewMutation>;
export type BulkUpdateSalesPerformanceSalesUserGoalViewMutationResult =
  ApolloReactCommon.MutationResult<BulkUpdateSalesPerformanceSalesUserGoalViewMutation>;
export type BulkUpdateSalesPerformanceSalesUserGoalViewMutationOptions =
  ApolloReactCommon.BaseMutationOptions<
    BulkUpdateSalesPerformanceSalesUserGoalViewMutation,
    BulkUpdateSalesPerformanceSalesUserGoalViewMutationVariables
  >;
export const BulkUpdateSalesPerformanceSalesTeamGoalViewDocument = gql`
  mutation BulkUpdateSalesPerformanceSalesTeamGoalView(
    $input: SalesPerformanceSalesTeamGoalViewBulkUpdateInput!
  ) {
    bulkUpdateSalesPerformanceSalesTeamGoalView(input: $input) {
      id
      teamGoalId
      month
      amount
      createdAt
      updatedAt
    }
  }
`;
export type BulkUpdateSalesPerformanceSalesTeamGoalViewMutationFn =
  ApolloReactCommon.MutationFunction<
    BulkUpdateSalesPerformanceSalesTeamGoalViewMutation,
    BulkUpdateSalesPerformanceSalesTeamGoalViewMutationVariables
  >;

/**
 * __useBulkUpdateSalesPerformanceSalesTeamGoalViewMutation__
 *
 * To run a mutation, you first call `useBulkUpdateSalesPerformanceSalesTeamGoalViewMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBulkUpdateSalesPerformanceSalesTeamGoalViewMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [bulkUpdateSalesPerformanceSalesTeamGoalViewMutation, { data, loading, error }] = useBulkUpdateSalesPerformanceSalesTeamGoalViewMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useBulkUpdateSalesPerformanceSalesTeamGoalViewMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    BulkUpdateSalesPerformanceSalesTeamGoalViewMutation,
    BulkUpdateSalesPerformanceSalesTeamGoalViewMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    BulkUpdateSalesPerformanceSalesTeamGoalViewMutation,
    BulkUpdateSalesPerformanceSalesTeamGoalViewMutationVariables
  >(BulkUpdateSalesPerformanceSalesTeamGoalViewDocument, options);
}
export type BulkUpdateSalesPerformanceSalesTeamGoalViewMutationHookResult =
  ReturnType<typeof useBulkUpdateSalesPerformanceSalesTeamGoalViewMutation>;
export type BulkUpdateSalesPerformanceSalesTeamGoalViewMutationResult =
  ApolloReactCommon.MutationResult<BulkUpdateSalesPerformanceSalesTeamGoalViewMutation>;
export type BulkUpdateSalesPerformanceSalesTeamGoalViewMutationOptions =
  ApolloReactCommon.BaseMutationOptions<
    BulkUpdateSalesPerformanceSalesTeamGoalViewMutation,
    BulkUpdateSalesPerformanceSalesTeamGoalViewMutationVariables
  >;
export const BulkUpdateSalesPerformanceSalesUserForecastViewDocument = gql`
  mutation BulkUpdateSalesPerformanceSalesUserForecastView(
    $input: SalesPerformanceSalesUserForecastViewBulkUpdateInput!
  ) {
    bulkUpdateSalesPerformanceSalesUserForecastView(input: $input) {
      id
      userForecastId
      month
      amountEarlyMonth
      amountLateMonth
      createdAt
      updatedAt
      quota
    }
  }
`;
export type BulkUpdateSalesPerformanceSalesUserForecastViewMutationFn =
  ApolloReactCommon.MutationFunction<
    BulkUpdateSalesPerformanceSalesUserForecastViewMutation,
    BulkUpdateSalesPerformanceSalesUserForecastViewMutationVariables
  >;

/**
 * __useBulkUpdateSalesPerformanceSalesUserForecastViewMutation__
 *
 * To run a mutation, you first call `useBulkUpdateSalesPerformanceSalesUserForecastViewMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBulkUpdateSalesPerformanceSalesUserForecastViewMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [bulkUpdateSalesPerformanceSalesUserForecastViewMutation, { data, loading, error }] = useBulkUpdateSalesPerformanceSalesUserForecastViewMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useBulkUpdateSalesPerformanceSalesUserForecastViewMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    BulkUpdateSalesPerformanceSalesUserForecastViewMutation,
    BulkUpdateSalesPerformanceSalesUserForecastViewMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    BulkUpdateSalesPerformanceSalesUserForecastViewMutation,
    BulkUpdateSalesPerformanceSalesUserForecastViewMutationVariables
  >(BulkUpdateSalesPerformanceSalesUserForecastViewDocument, options);
}
export type BulkUpdateSalesPerformanceSalesUserForecastViewMutationHookResult =
  ReturnType<typeof useBulkUpdateSalesPerformanceSalesUserForecastViewMutation>;
export type BulkUpdateSalesPerformanceSalesUserForecastViewMutationResult =
  ApolloReactCommon.MutationResult<BulkUpdateSalesPerformanceSalesUserForecastViewMutation>;
export type BulkUpdateSalesPerformanceSalesUserForecastViewMutationOptions =
  ApolloReactCommon.BaseMutationOptions<
    BulkUpdateSalesPerformanceSalesUserForecastViewMutation,
    BulkUpdateSalesPerformanceSalesUserForecastViewMutationVariables
  >;
export const SalesPerformanceSalesTypesDocument = gql`
  query SalesPerformanceSalesTypes(
    $filters: SalesPerformanceSalesTypeSearchInput!
    $offset: Int
    $limit: Int
  ) {
    salesPerformanceSalesTypes(
      filters: $filters
      offset: $offset
      limit: $limit
    ) {
      rows {
        id
        salesTeamGroupId
        name
        code
        viewOrder
        createdAt
        updatedAt
        salesTeamGroup {
          id
          name
        }
      }
      count
    }
  }
`;

/**
 * __useSalesPerformanceSalesTypesQuery__
 *
 * To run a query within a React component, call `useSalesPerformanceSalesTypesQuery` and pass it any options that fit your needs.
 * When your component renders, `useSalesPerformanceSalesTypesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSalesPerformanceSalesTypesQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useSalesPerformanceSalesTypesQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    SalesPerformanceSalesTypesQuery,
    SalesPerformanceSalesTypesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    SalesPerformanceSalesTypesQuery,
    SalesPerformanceSalesTypesQueryVariables
  >(SalesPerformanceSalesTypesDocument, options);
}
export function useSalesPerformanceSalesTypesLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    SalesPerformanceSalesTypesQuery,
    SalesPerformanceSalesTypesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    SalesPerformanceSalesTypesQuery,
    SalesPerformanceSalesTypesQueryVariables
  >(SalesPerformanceSalesTypesDocument, options);
}
export type SalesPerformanceSalesTypesQueryHookResult = ReturnType<
  typeof useSalesPerformanceSalesTypesQuery
>;
export type SalesPerformanceSalesTypesLazyQueryHookResult = ReturnType<
  typeof useSalesPerformanceSalesTypesLazyQuery
>;
export type SalesPerformanceSalesTypesQueryResult =
  ApolloReactCommon.QueryResult<
    SalesPerformanceSalesTypesQuery,
    SalesPerformanceSalesTypesQueryVariables
  >;
export const SalesPerformanceSalesUserGoalViewDocument = gql`
  query SalesPerformanceSalesUserGoalView(
    $salesTeamGroupId: ID!
    $year: Int!
    $userIds: [ID!]
  ) {
    salesPerformanceSalesUserGoalView(
      salesTeamGroupId: $salesTeamGroupId
      year: $year
      userIds: $userIds
    ) {
      salesTeamGroup {
        id
        name
        code
        directorId
        regionId
        companyId
      }
      salesTeamGroupId
      users {
        salesTeamUnitId
        months {
          month
          types {
            salesTypeId
            amount
            salesType {
              id
              name
              code
            }
          }
        }
        salesTeamUnit {
          id
          name
        }
      }
      year
    }
  }
`;

/**
 * __useSalesPerformanceSalesUserGoalViewQuery__
 *
 * To run a query within a React component, call `useSalesPerformanceSalesUserGoalViewQuery` and pass it any options that fit your needs.
 * When your component renders, `useSalesPerformanceSalesUserGoalViewQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSalesPerformanceSalesUserGoalViewQuery({
 *   variables: {
 *      salesTeamGroupId: // value for 'salesTeamGroupId'
 *      year: // value for 'year'
 *      userIds: // value for 'userIds'
 *   },
 * });
 */
export function useSalesPerformanceSalesUserGoalViewQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    SalesPerformanceSalesUserGoalViewQuery,
    SalesPerformanceSalesUserGoalViewQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    SalesPerformanceSalesUserGoalViewQuery,
    SalesPerformanceSalesUserGoalViewQueryVariables
  >(SalesPerformanceSalesUserGoalViewDocument, options);
}
export function useSalesPerformanceSalesUserGoalViewLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    SalesPerformanceSalesUserGoalViewQuery,
    SalesPerformanceSalesUserGoalViewQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    SalesPerformanceSalesUserGoalViewQuery,
    SalesPerformanceSalesUserGoalViewQueryVariables
  >(SalesPerformanceSalesUserGoalViewDocument, options);
}
export type SalesPerformanceSalesUserGoalViewQueryHookResult = ReturnType<
  typeof useSalesPerformanceSalesUserGoalViewQuery
>;
export type SalesPerformanceSalesUserGoalViewLazyQueryHookResult = ReturnType<
  typeof useSalesPerformanceSalesUserGoalViewLazyQuery
>;
export type SalesPerformanceSalesUserGoalViewQueryResult =
  ApolloReactCommon.QueryResult<
    SalesPerformanceSalesUserGoalViewQuery,
    SalesPerformanceSalesUserGoalViewQueryVariables
  >;
export const SalesPerformanceSalesTeamGoalViewDocument = gql`
  query SalesPerformanceSalesTeamGoalView(
    $salesTeamGroupId: ID!
    $year: Int!
    $salesTeamIds: [ID!]
  ) {
    salesPerformanceSalesTeamGoalView(
      salesTeamGroupId: $salesTeamGroupId
      year: $year
      salesTeamIds: $salesTeamIds
    ) {
      salesTeamGroup {
        id
        name
        code
        directorId
        regionId
        companyId
      }
      salesTeamGroupId
      year
      teams {
        salesTeamId
        months {
          month
          types {
            salesTypeId
            amount
            salesType {
              id
              name
              code
            }
          }
        }
        salesTeam {
          id
          name
        }
      }
    }
  }
`;

/**
 * __useSalesPerformanceSalesTeamGoalViewQuery__
 *
 * To run a query within a React component, call `useSalesPerformanceSalesTeamGoalViewQuery` and pass it any options that fit your needs.
 * When your component renders, `useSalesPerformanceSalesTeamGoalViewQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSalesPerformanceSalesTeamGoalViewQuery({
 *   variables: {
 *      salesTeamGroupId: // value for 'salesTeamGroupId'
 *      year: // value for 'year'
 *      salesTeamIds: // value for 'salesTeamIds'
 *   },
 * });
 */
export function useSalesPerformanceSalesTeamGoalViewQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    SalesPerformanceSalesTeamGoalViewQuery,
    SalesPerformanceSalesTeamGoalViewQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    SalesPerformanceSalesTeamGoalViewQuery,
    SalesPerformanceSalesTeamGoalViewQueryVariables
  >(SalesPerformanceSalesTeamGoalViewDocument, options);
}
export function useSalesPerformanceSalesTeamGoalViewLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    SalesPerformanceSalesTeamGoalViewQuery,
    SalesPerformanceSalesTeamGoalViewQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    SalesPerformanceSalesTeamGoalViewQuery,
    SalesPerformanceSalesTeamGoalViewQueryVariables
  >(SalesPerformanceSalesTeamGoalViewDocument, options);
}
export type SalesPerformanceSalesTeamGoalViewQueryHookResult = ReturnType<
  typeof useSalesPerformanceSalesTeamGoalViewQuery
>;
export type SalesPerformanceSalesTeamGoalViewLazyQueryHookResult = ReturnType<
  typeof useSalesPerformanceSalesTeamGoalViewLazyQuery
>;
export type SalesPerformanceSalesTeamGoalViewQueryResult =
  ApolloReactCommon.QueryResult<
    SalesPerformanceSalesTeamGoalViewQuery,
    SalesPerformanceSalesTeamGoalViewQueryVariables
  >;
export const SalesPerformanceSalesUserForecastViewDocument = gql`
  query SalesPerformanceSalesUserForecastView(
    $salesTeamGroupId: ID!
    $year: Int!
    $month1: Int
    $month2: Int
    $userIds: [ID!]
  ) {
    salesPerformanceSalesUserForecastView(
      salesTeamGroupId: $salesTeamGroupId
      year: $year
      month1: $month1
      month2: $month2
      userIds: $userIds
    ) {
      year
      salesTeamGroupId
      salesTeamGroup {
        id
        name
      }
      overall {
        months {
          month
          types {
            salesTypeId
            salesType {
              id
              name
            }
            quota
            quotaAchievementRate
            amountEarlyMonth
            amountEarlyMonthAccuracyRate
            amountLateMonth
            amountLateMonthAccuracyRate
            amountActual
          }
        }
      }
      users {
        salesTeamUnitId
        salesTeamUnit {
          id
          name
        }
        months {
          month
          types {
            salesTypeId
            salesType {
              id
              name
            }
            quota
            quotaAchievementRate
            amountEarlyMonth
            amountEarlyMonthAccuracyRate
            amountLateMonth
            amountLateMonthAccuracyRate
            amountActual
          }
        }
      }
    }
  }
`;

/**
 * __useSalesPerformanceSalesUserForecastViewQuery__
 *
 * To run a query within a React component, call `useSalesPerformanceSalesUserForecastViewQuery` and pass it any options that fit your needs.
 * When your component renders, `useSalesPerformanceSalesUserForecastViewQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSalesPerformanceSalesUserForecastViewQuery({
 *   variables: {
 *      salesTeamGroupId: // value for 'salesTeamGroupId'
 *      year: // value for 'year'
 *      month1: // value for 'month1'
 *      month2: // value for 'month2'
 *      userIds: // value for 'userIds'
 *   },
 * });
 */
export function useSalesPerformanceSalesUserForecastViewQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    SalesPerformanceSalesUserForecastViewQuery,
    SalesPerformanceSalesUserForecastViewQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    SalesPerformanceSalesUserForecastViewQuery,
    SalesPerformanceSalesUserForecastViewQueryVariables
  >(SalesPerformanceSalesUserForecastViewDocument, options);
}
export function useSalesPerformanceSalesUserForecastViewLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    SalesPerformanceSalesUserForecastViewQuery,
    SalesPerformanceSalesUserForecastViewQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    SalesPerformanceSalesUserForecastViewQuery,
    SalesPerformanceSalesUserForecastViewQueryVariables
  >(SalesPerformanceSalesUserForecastViewDocument, options);
}
export type SalesPerformanceSalesUserForecastViewQueryHookResult = ReturnType<
  typeof useSalesPerformanceSalesUserForecastViewQuery
>;
export type SalesPerformanceSalesUserForecastViewLazyQueryHookResult =
  ReturnType<typeof useSalesPerformanceSalesUserForecastViewLazyQuery>;
export type SalesPerformanceSalesUserForecastViewQueryResult =
  ApolloReactCommon.QueryResult<
    SalesPerformanceSalesUserForecastViewQuery,
    SalesPerformanceSalesUserForecastViewQueryVariables
  >;
export const CreateRoleDocument = gql`
  mutation CreateRole($input: RoleCreateInput!) {
    createRole(input: $input) {
      id
    }
  }
`;
export type CreateRoleMutationFn = ApolloReactCommon.MutationFunction<
  CreateRoleMutation,
  CreateRoleMutationVariables
>;

/**
 * __useCreateRoleMutation__
 *
 * To run a mutation, you first call `useCreateRoleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateRoleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createRoleMutation, { data, loading, error }] = useCreateRoleMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateRoleMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    CreateRoleMutation,
    CreateRoleMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    CreateRoleMutation,
    CreateRoleMutationVariables
  >(CreateRoleDocument, options);
}
export type CreateRoleMutationHookResult = ReturnType<
  typeof useCreateRoleMutation
>;
export type CreateRoleMutationResult =
  ApolloReactCommon.MutationResult<CreateRoleMutation>;
export type CreateRoleMutationOptions = ApolloReactCommon.BaseMutationOptions<
  CreateRoleMutation,
  CreateRoleMutationVariables
>;
export const UpdateRoleDocument = gql`
  mutation UpdateRole($input: RoleUpdateInput!) {
    updateRole(input: $input) {
      id
    }
  }
`;
export type UpdateRoleMutationFn = ApolloReactCommon.MutationFunction<
  UpdateRoleMutation,
  UpdateRoleMutationVariables
>;

/**
 * __useUpdateRoleMutation__
 *
 * To run a mutation, you first call `useUpdateRoleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateRoleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateRoleMutation, { data, loading, error }] = useUpdateRoleMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateRoleMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    UpdateRoleMutation,
    UpdateRoleMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    UpdateRoleMutation,
    UpdateRoleMutationVariables
  >(UpdateRoleDocument, options);
}
export type UpdateRoleMutationHookResult = ReturnType<
  typeof useUpdateRoleMutation
>;
export type UpdateRoleMutationResult =
  ApolloReactCommon.MutationResult<UpdateRoleMutation>;
export type UpdateRoleMutationOptions = ApolloReactCommon.BaseMutationOptions<
  UpdateRoleMutation,
  UpdateRoleMutationVariables
>;
export const BulkCreateRolesUserDocument = gql`
  mutation bulkCreateRolesUser($input: RolesUserBulkCreateInput!) {
    bulkCreateRolesUser(input: $input) {
      userId
      roleId
    }
  }
`;
export type BulkCreateRolesUserMutationFn = ApolloReactCommon.MutationFunction<
  BulkCreateRolesUserMutation,
  BulkCreateRolesUserMutationVariables
>;

/**
 * __useBulkCreateRolesUserMutation__
 *
 * To run a mutation, you first call `useBulkCreateRolesUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBulkCreateRolesUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [bulkCreateRolesUserMutation, { data, loading, error }] = useBulkCreateRolesUserMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useBulkCreateRolesUserMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    BulkCreateRolesUserMutation,
    BulkCreateRolesUserMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    BulkCreateRolesUserMutation,
    BulkCreateRolesUserMutationVariables
  >(BulkCreateRolesUserDocument, options);
}
export type BulkCreateRolesUserMutationHookResult = ReturnType<
  typeof useBulkCreateRolesUserMutation
>;
export type BulkCreateRolesUserMutationResult =
  ApolloReactCommon.MutationResult<BulkCreateRolesUserMutation>;
export type BulkCreateRolesUserMutationOptions =
  ApolloReactCommon.BaseMutationOptions<
    BulkCreateRolesUserMutation,
    BulkCreateRolesUserMutationVariables
  >;
export const DeleteRolesUserDocument = gql`
  mutation deleteRolesUser($input: RolesUserDeleteInput!) {
    deleteRolesUser(input: $input)
  }
`;
export type DeleteRolesUserMutationFn = ApolloReactCommon.MutationFunction<
  DeleteRolesUserMutation,
  DeleteRolesUserMutationVariables
>;

/**
 * __useDeleteRolesUserMutation__
 *
 * To run a mutation, you first call `useDeleteRolesUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteRolesUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteRolesUserMutation, { data, loading, error }] = useDeleteRolesUserMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteRolesUserMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    DeleteRolesUserMutation,
    DeleteRolesUserMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    DeleteRolesUserMutation,
    DeleteRolesUserMutationVariables
  >(DeleteRolesUserDocument, options);
}
export type DeleteRolesUserMutationHookResult = ReturnType<
  typeof useDeleteRolesUserMutation
>;
export type DeleteRolesUserMutationResult =
  ApolloReactCommon.MutationResult<DeleteRolesUserMutation>;
export type DeleteRolesUserMutationOptions =
  ApolloReactCommon.BaseMutationOptions<
    DeleteRolesUserMutation,
    DeleteRolesUserMutationVariables
  >;
export const RolesDocument = gql`
  query roles($filters: RoleSearchInput!, $offset: Int, $limit: Int) {
    roles(filters: $filters, offset: $offset, limit: $limit) {
      count
      rows {
        allowCompanyIds
        allowRegions {
          id
          name
        }
        allowSalesTeamGroups {
          id
          name
        }
        allowSalesTeams {
          group {
            id
            name
          }
          id
          name
        }
        allowProductTeamIds
        id
        isSystemAdmin
        isOnlyOwnUser
        allowUserIds
        allowUsers {
          id
          name
          dept {
            id
            name
          }
        }
        allowCompanyIds
        isAllowAllCnCompanies
        isAllowAllTwCompanies
        isAllowAllRegions
        isAllowAllSalesTeamGroups
        permissions {
          applicationId
          code
          description
          name
          id
          group {
            id
            name
          }
          groupId
        }
        name
      }
    }
  }
`;

/**
 * __useRolesQuery__
 *
 * To run a query within a React component, call `useRolesQuery` and pass it any options that fit your needs.
 * When your component renders, `useRolesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRolesQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useRolesQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    RolesQuery,
    RolesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<RolesQuery, RolesQueryVariables>(
    RolesDocument,
    options,
  );
}
export function useRolesLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    RolesQuery,
    RolesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<RolesQuery, RolesQueryVariables>(
    RolesDocument,
    options,
  );
}
export type RolesQueryHookResult = ReturnType<typeof useRolesQuery>;
export type RolesLazyQueryHookResult = ReturnType<typeof useRolesLazyQuery>;
export type RolesQueryResult = ApolloReactCommon.QueryResult<
  RolesQuery,
  RolesQueryVariables
>;
export const SalesTeamsDocument = gql`
  query salesTeams($filters: SalesTeamSearchInput!) {
    salesTeams(filters: $filters) {
      rows {
        group {
          id
          name
        }
        id
        code
        name
        parentId
        viewOrder
        children {
          id
          code
          name
          parentId
          viewOrder
          children {
            id
            code
            name
            parentId
            viewOrder
            nestedUsers {
              id
              name
              code
            }
            children {
              id
              code
              name
              parentId
              viewOrder
              nestedUsers {
                id
                name
                code
              }
              children {
                id
                code
                name
                parentId
                viewOrder
                nestedUsers {
                  id
                  name
                  code
                }
                children {
                  id
                  code
                  name
                  parentId
                  viewOrder
                  nestedUsers {
                    id
                    name
                    code
                  }
                  children {
                    id
                    code
                    name
                    parentId
                    viewOrder
                    nestedUsers {
                      id
                      name
                      code
                    }
                  }
                }
              }
            }
          }
          nestedUsers {
            id
            name
            code
          }
        }
        nestedUsers {
          id
          name
          code
        }
        nestedUnits {
          id
          name
          user {
            id
            name
          }
        }
      }
    }
  }
`;

/**
 * __useSalesTeamsQuery__
 *
 * To run a query within a React component, call `useSalesTeamsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSalesTeamsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSalesTeamsQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function useSalesTeamsQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    SalesTeamsQuery,
    SalesTeamsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<SalesTeamsQuery, SalesTeamsQueryVariables>(
    SalesTeamsDocument,
    options,
  );
}
export function useSalesTeamsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    SalesTeamsQuery,
    SalesTeamsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    SalesTeamsQuery,
    SalesTeamsQueryVariables
  >(SalesTeamsDocument, options);
}
export type SalesTeamsQueryHookResult = ReturnType<typeof useSalesTeamsQuery>;
export type SalesTeamsLazyQueryHookResult = ReturnType<
  typeof useSalesTeamsLazyQuery
>;
export type SalesTeamsQueryResult = ApolloReactCommon.QueryResult<
  SalesTeamsQuery,
  SalesTeamsQueryVariables
>;
export const PermissionsDocument = gql`
  query permissions($filters: PermissionSearchInput!) {
    permissions(filters: $filters) {
      rows {
        id
        name
        applicationId
        code
        description
        group {
          id
          name
        }
        groupId
      }
    }
  }
`;

/**
 * __usePermissionsQuery__
 *
 * To run a query within a React component, call `usePermissionsQuery` and pass it any options that fit your needs.
 * When your component renders, `usePermissionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePermissionsQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function usePermissionsQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    PermissionsQuery,
    PermissionsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<PermissionsQuery, PermissionsQueryVariables>(
    PermissionsDocument,
    options,
  );
}
export function usePermissionsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    PermissionsQuery,
    PermissionsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    PermissionsQuery,
    PermissionsQueryVariables
  >(PermissionsDocument, options);
}
export type PermissionsQueryHookResult = ReturnType<typeof usePermissionsQuery>;
export type PermissionsLazyQueryHookResult = ReturnType<
  typeof usePermissionsLazyQuery
>;
export type PermissionsQueryResult = ApolloReactCommon.QueryResult<
  PermissionsQuery,
  PermissionsQueryVariables
>;
export const PermissionGroupsDocument = gql`
  query permissionGroups($filters: PermissionGroupSearchInput!) {
    permissionGroups(filters: $filters) {
      rows {
        id
        name
        permissions {
          id
          name
          description
          applicationId
        }
      }
    }
  }
`;

/**
 * __usePermissionGroupsQuery__
 *
 * To run a query within a React component, call `usePermissionGroupsQuery` and pass it any options that fit your needs.
 * When your component renders, `usePermissionGroupsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePermissionGroupsQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function usePermissionGroupsQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    PermissionGroupsQuery,
    PermissionGroupsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    PermissionGroupsQuery,
    PermissionGroupsQueryVariables
  >(PermissionGroupsDocument, options);
}
export function usePermissionGroupsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    PermissionGroupsQuery,
    PermissionGroupsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    PermissionGroupsQuery,
    PermissionGroupsQueryVariables
  >(PermissionGroupsDocument, options);
}
export type PermissionGroupsQueryHookResult = ReturnType<
  typeof usePermissionGroupsQuery
>;
export type PermissionGroupsLazyQueryHookResult = ReturnType<
  typeof usePermissionGroupsLazyQuery
>;
export type PermissionGroupsQueryResult = ApolloReactCommon.QueryResult<
  PermissionGroupsQuery,
  PermissionGroupsQueryVariables
>;
export const PermissionApplicationsDocument = gql`
  query permissionApplications($filters: PermissionApplicationSearchInput!) {
    permissionApplications(filters: $filters) {
      rows {
        id
        name
      }
    }
  }
`;

/**
 * __usePermissionApplicationsQuery__
 *
 * To run a query within a React component, call `usePermissionApplicationsQuery` and pass it any options that fit your needs.
 * When your component renders, `usePermissionApplicationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePermissionApplicationsQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function usePermissionApplicationsQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    PermissionApplicationsQuery,
    PermissionApplicationsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    PermissionApplicationsQuery,
    PermissionApplicationsQueryVariables
  >(PermissionApplicationsDocument, options);
}
export function usePermissionApplicationsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    PermissionApplicationsQuery,
    PermissionApplicationsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    PermissionApplicationsQuery,
    PermissionApplicationsQueryVariables
  >(PermissionApplicationsDocument, options);
}
export type PermissionApplicationsQueryHookResult = ReturnType<
  typeof usePermissionApplicationsQuery
>;
export type PermissionApplicationsLazyQueryHookResult = ReturnType<
  typeof usePermissionApplicationsLazyQuery
>;
export type PermissionApplicationsQueryResult = ApolloReactCommon.QueryResult<
  PermissionApplicationsQuery,
  PermissionApplicationsQueryVariables
>;
export const CreateProductTeamDocument = gql`
  mutation CreateProductTeam($input: ProductTeamCreateInput!) {
    createProductTeam(input: $input) {
      id
    }
  }
`;
export type CreateProductTeamMutationFn = ApolloReactCommon.MutationFunction<
  CreateProductTeamMutation,
  CreateProductTeamMutationVariables
>;

/**
 * __useCreateProductTeamMutation__
 *
 * To run a mutation, you first call `useCreateProductTeamMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateProductTeamMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createProductTeamMutation, { data, loading, error }] = useCreateProductTeamMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateProductTeamMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    CreateProductTeamMutation,
    CreateProductTeamMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    CreateProductTeamMutation,
    CreateProductTeamMutationVariables
  >(CreateProductTeamDocument, options);
}
export type CreateProductTeamMutationHookResult = ReturnType<
  typeof useCreateProductTeamMutation
>;
export type CreateProductTeamMutationResult =
  ApolloReactCommon.MutationResult<CreateProductTeamMutation>;
export type CreateProductTeamMutationOptions =
  ApolloReactCommon.BaseMutationOptions<
    CreateProductTeamMutation,
    CreateProductTeamMutationVariables
  >;
export const UpdateProductTeamDocument = gql`
  mutation UpdateProductTeam($input: ProductTeamUpdateInput!) {
    updateProductTeam(input: $input) {
      id
    }
  }
`;
export type UpdateProductTeamMutationFn = ApolloReactCommon.MutationFunction<
  UpdateProductTeamMutation,
  UpdateProductTeamMutationVariables
>;

/**
 * __useUpdateProductTeamMutation__
 *
 * To run a mutation, you first call `useUpdateProductTeamMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateProductTeamMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateProductTeamMutation, { data, loading, error }] = useUpdateProductTeamMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateProductTeamMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    UpdateProductTeamMutation,
    UpdateProductTeamMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    UpdateProductTeamMutation,
    UpdateProductTeamMutationVariables
  >(UpdateProductTeamDocument, options);
}
export type UpdateProductTeamMutationHookResult = ReturnType<
  typeof useUpdateProductTeamMutation
>;
export type UpdateProductTeamMutationResult =
  ApolloReactCommon.MutationResult<UpdateProductTeamMutation>;
export type UpdateProductTeamMutationOptions =
  ApolloReactCommon.BaseMutationOptions<
    UpdateProductTeamMutation,
    UpdateProductTeamMutationVariables
  >;
export const ProductTeamsDocument = gql`
  query productTeams(
    $filters: ProductTeamSearchInput!
    $offset: Int
    $limit: Int
  ) {
    productTeams(filters: $filters, offset: $offset, limit: $limit) {
      rows {
        id
        name
        users {
          id
          name
          dept {
            id
            name
          }
        }
        businessProducts {
          id
          name
        }
      }
      count
    }
  }
`;

/**
 * __useProductTeamsQuery__
 *
 * To run a query within a React component, call `useProductTeamsQuery` and pass it any options that fit your needs.
 * When your component renders, `useProductTeamsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProductTeamsQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useProductTeamsQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    ProductTeamsQuery,
    ProductTeamsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    ProductTeamsQuery,
    ProductTeamsQueryVariables
  >(ProductTeamsDocument, options);
}
export function useProductTeamsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    ProductTeamsQuery,
    ProductTeamsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    ProductTeamsQuery,
    ProductTeamsQueryVariables
  >(ProductTeamsDocument, options);
}
export type ProductTeamsQueryHookResult = ReturnType<
  typeof useProductTeamsQuery
>;
export type ProductTeamsLazyQueryHookResult = ReturnType<
  typeof useProductTeamsLazyQuery
>;
export type ProductTeamsQueryResult = ApolloReactCommon.QueryResult<
  ProductTeamsQuery,
  ProductTeamsQueryVariables
>;
export const CreateSalesTeamDocument = gql`
  mutation createSalesTeam($input: SalesTeamCreateInput!) {
    createSalesTeam(input: $input) {
      id
    }
  }
`;
export type CreateSalesTeamMutationFn = ApolloReactCommon.MutationFunction<
  CreateSalesTeamMutation,
  CreateSalesTeamMutationVariables
>;

/**
 * __useCreateSalesTeamMutation__
 *
 * To run a mutation, you first call `useCreateSalesTeamMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateSalesTeamMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createSalesTeamMutation, { data, loading, error }] = useCreateSalesTeamMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateSalesTeamMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    CreateSalesTeamMutation,
    CreateSalesTeamMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    CreateSalesTeamMutation,
    CreateSalesTeamMutationVariables
  >(CreateSalesTeamDocument, options);
}
export type CreateSalesTeamMutationHookResult = ReturnType<
  typeof useCreateSalesTeamMutation
>;
export type CreateSalesTeamMutationResult =
  ApolloReactCommon.MutationResult<CreateSalesTeamMutation>;
export type CreateSalesTeamMutationOptions =
  ApolloReactCommon.BaseMutationOptions<
    CreateSalesTeamMutation,
    CreateSalesTeamMutationVariables
  >;
export const UpdateSalesTeamDocument = gql`
  mutation updateSalesTeam($input: SalesTeamUpdateInput!) {
    updateSalesTeam(input: $input) {
      id
    }
  }
`;
export type UpdateSalesTeamMutationFn = ApolloReactCommon.MutationFunction<
  UpdateSalesTeamMutation,
  UpdateSalesTeamMutationVariables
>;

/**
 * __useUpdateSalesTeamMutation__
 *
 * To run a mutation, you first call `useUpdateSalesTeamMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateSalesTeamMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateSalesTeamMutation, { data, loading, error }] = useUpdateSalesTeamMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateSalesTeamMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    UpdateSalesTeamMutation,
    UpdateSalesTeamMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    UpdateSalesTeamMutation,
    UpdateSalesTeamMutationVariables
  >(UpdateSalesTeamDocument, options);
}
export type UpdateSalesTeamMutationHookResult = ReturnType<
  typeof useUpdateSalesTeamMutation
>;
export type UpdateSalesTeamMutationResult =
  ApolloReactCommon.MutationResult<UpdateSalesTeamMutation>;
export type UpdateSalesTeamMutationOptions =
  ApolloReactCommon.BaseMutationOptions<
    UpdateSalesTeamMutation,
    UpdateSalesTeamMutationVariables
  >;
export const BulkUpdateSalesTeamDocument = gql`
  mutation bulkUpdateSalesTeam($input: SalesTeamBulkUpdateInput!) {
    bulkUpdateSalesTeam(input: $input) {
      id
    }
  }
`;
export type BulkUpdateSalesTeamMutationFn = ApolloReactCommon.MutationFunction<
  BulkUpdateSalesTeamMutation,
  BulkUpdateSalesTeamMutationVariables
>;

/**
 * __useBulkUpdateSalesTeamMutation__
 *
 * To run a mutation, you first call `useBulkUpdateSalesTeamMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBulkUpdateSalesTeamMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [bulkUpdateSalesTeamMutation, { data, loading, error }] = useBulkUpdateSalesTeamMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useBulkUpdateSalesTeamMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    BulkUpdateSalesTeamMutation,
    BulkUpdateSalesTeamMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    BulkUpdateSalesTeamMutation,
    BulkUpdateSalesTeamMutationVariables
  >(BulkUpdateSalesTeamDocument, options);
}
export type BulkUpdateSalesTeamMutationHookResult = ReturnType<
  typeof useBulkUpdateSalesTeamMutation
>;
export type BulkUpdateSalesTeamMutationResult =
  ApolloReactCommon.MutationResult<BulkUpdateSalesTeamMutation>;
export type BulkUpdateSalesTeamMutationOptions =
  ApolloReactCommon.BaseMutationOptions<
    BulkUpdateSalesTeamMutation,
    BulkUpdateSalesTeamMutationVariables
  >;
export const CreateSalesTeamUnitDocument = gql`
  mutation CreateSalesTeamUnit($input: SalesTeamUnitCreateInput!) {
    createSalesTeamUnit(input: $input) {
      id
    }
  }
`;
export type CreateSalesTeamUnitMutationFn = ApolloReactCommon.MutationFunction<
  CreateSalesTeamUnitMutation,
  CreateSalesTeamUnitMutationVariables
>;

/**
 * __useCreateSalesTeamUnitMutation__
 *
 * To run a mutation, you first call `useCreateSalesTeamUnitMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateSalesTeamUnitMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createSalesTeamUnitMutation, { data, loading, error }] = useCreateSalesTeamUnitMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateSalesTeamUnitMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    CreateSalesTeamUnitMutation,
    CreateSalesTeamUnitMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    CreateSalesTeamUnitMutation,
    CreateSalesTeamUnitMutationVariables
  >(CreateSalesTeamUnitDocument, options);
}
export type CreateSalesTeamUnitMutationHookResult = ReturnType<
  typeof useCreateSalesTeamUnitMutation
>;
export type CreateSalesTeamUnitMutationResult =
  ApolloReactCommon.MutationResult<CreateSalesTeamUnitMutation>;
export type CreateSalesTeamUnitMutationOptions =
  ApolloReactCommon.BaseMutationOptions<
    CreateSalesTeamUnitMutation,
    CreateSalesTeamUnitMutationVariables
  >;
export const UpdateSalesTeamUnitDocument = gql`
  mutation UpdateSalesTeamUnit($input: SalesTeamUnitUpdateInput!) {
    updateSalesTeamUnit(input: $input) {
      id
    }
  }
`;
export type UpdateSalesTeamUnitMutationFn = ApolloReactCommon.MutationFunction<
  UpdateSalesTeamUnitMutation,
  UpdateSalesTeamUnitMutationVariables
>;

/**
 * __useUpdateSalesTeamUnitMutation__
 *
 * To run a mutation, you first call `useUpdateSalesTeamUnitMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateSalesTeamUnitMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateSalesTeamUnitMutation, { data, loading, error }] = useUpdateSalesTeamUnitMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateSalesTeamUnitMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    UpdateSalesTeamUnitMutation,
    UpdateSalesTeamUnitMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    UpdateSalesTeamUnitMutation,
    UpdateSalesTeamUnitMutationVariables
  >(UpdateSalesTeamUnitDocument, options);
}
export type UpdateSalesTeamUnitMutationHookResult = ReturnType<
  typeof useUpdateSalesTeamUnitMutation
>;
export type UpdateSalesTeamUnitMutationResult =
  ApolloReactCommon.MutationResult<UpdateSalesTeamUnitMutation>;
export type UpdateSalesTeamUnitMutationOptions =
  ApolloReactCommon.BaseMutationOptions<
    UpdateSalesTeamUnitMutation,
    UpdateSalesTeamUnitMutationVariables
  >;
export const SalesTeamsTableDocument = gql`
  query salesTeamsTable(
    $filters: SalesTeamSearchInput!
    $offset: Int
    $limit: Int
  ) {
    salesTeams(filters: $filters, offset: $offset, limit: $limit) {
      rows {
        id
        group {
          name
          id
        }
        id
        name
        managerId
        manager {
          id
          name
          departmentId
        }
        parent {
          id
          name
        }
        requiredForPrimaryCustomer
        users {
          id
          name
          dept {
            id
            name
          }
        }
      }
      count
    }
  }
`;

/**
 * __useSalesTeamsTableQuery__
 *
 * To run a query within a React component, call `useSalesTeamsTableQuery` and pass it any options that fit your needs.
 * When your component renders, `useSalesTeamsTableQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSalesTeamsTableQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useSalesTeamsTableQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    SalesTeamsTableQuery,
    SalesTeamsTableQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    SalesTeamsTableQuery,
    SalesTeamsTableQueryVariables
  >(SalesTeamsTableDocument, options);
}
export function useSalesTeamsTableLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    SalesTeamsTableQuery,
    SalesTeamsTableQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    SalesTeamsTableQuery,
    SalesTeamsTableQueryVariables
  >(SalesTeamsTableDocument, options);
}
export type SalesTeamsTableQueryHookResult = ReturnType<
  typeof useSalesTeamsTableQuery
>;
export type SalesTeamsTableLazyQueryHookResult = ReturnType<
  typeof useSalesTeamsTableLazyQuery
>;
export type SalesTeamsTableQueryResult = ApolloReactCommon.QueryResult<
  SalesTeamsTableQuery,
  SalesTeamsTableQueryVariables
>;
export const SalesTeamUnitsDocument = gql`
  query SalesTeamUnits(
    $filters: SalesTeamUnitSearchInput!
    $limit: Int
    $offset: Int
  ) {
    salesTeamUnits(filters: $filters, limit: $limit, offset: $offset) {
      rows {
        id
        name
        userId
        user {
          id
          name
          departmentId
        }
        salesTeamId
        salesTeam {
          id
          name
        }
      }
      count
    }
  }
`;

/**
 * __useSalesTeamUnitsQuery__
 *
 * To run a query within a React component, call `useSalesTeamUnitsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSalesTeamUnitsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSalesTeamUnitsQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *   },
 * });
 */
export function useSalesTeamUnitsQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    SalesTeamUnitsQuery,
    SalesTeamUnitsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    SalesTeamUnitsQuery,
    SalesTeamUnitsQueryVariables
  >(SalesTeamUnitsDocument, options);
}
export function useSalesTeamUnitsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    SalesTeamUnitsQuery,
    SalesTeamUnitsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    SalesTeamUnitsQuery,
    SalesTeamUnitsQueryVariables
  >(SalesTeamUnitsDocument, options);
}
export type SalesTeamUnitsQueryHookResult = ReturnType<
  typeof useSalesTeamUnitsQuery
>;
export type SalesTeamUnitsLazyQueryHookResult = ReturnType<
  typeof useSalesTeamUnitsLazyQuery
>;
export type SalesTeamUnitsQueryResult = ApolloReactCommon.QueryResult<
  SalesTeamUnitsQuery,
  SalesTeamUnitsQueryVariables
>;
export const LoginDocument = gql`
  mutation login($ssoToken: String!) {
    userLogin(SSOToken: $ssoToken) {
      id
      name
      code
      email
      token
    }
  }
`;
export type LoginMutationFn = ApolloReactCommon.MutationFunction<
  LoginMutation,
  LoginMutationVariables
>;

/**
 * __useLoginMutation__
 *
 * To run a mutation, you first call `useLoginMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLoginMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [loginMutation, { data, loading, error }] = useLoginMutation({
 *   variables: {
 *      ssoToken: // value for 'ssoToken'
 *   },
 * });
 */
export function useLoginMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    LoginMutation,
    LoginMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<LoginMutation, LoginMutationVariables>(
    LoginDocument,
    options,
  );
}
export type LoginMutationHookResult = ReturnType<typeof useLoginMutation>;
export type LoginMutationResult =
  ApolloReactCommon.MutationResult<LoginMutation>;
export type LoginMutationOptions = ApolloReactCommon.BaseMutationOptions<
  LoginMutation,
  LoginMutationVariables
>;
export const MeDocument = gql`
  query me {
    me {
      region {
        id
        name
      }
      id
      code
      email
      name
      authType
      company {
        region {
          id
          name
        }
        id
        code
      }
      dept {
        company {
          region {
            id
            name
          }
          id
          code
          name
        }
        id
        code
        name
        manager {
          id
          name
        }
      }
      salesTeamUnits {
        id
        name
      }
      salesTeams {
        id
        top {
          id
        }
      }
      userPermission {
        codes
        allowRegionIds
      }
      availableSalesTeamGroups {
        code
        id
        name
        region {
          id
          name
          code
        }
      }
      defaultAvailableSalesTeamGroup {
        id
        name
        code
        region {
          id
          name
          code
        }
      }
    }
  }
`;

/**
 * __useMeQuery__
 *
 * To run a query within a React component, call `useMeQuery` and pass it any options that fit your needs.
 * When your component renders, `useMeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMeQuery({
 *   variables: {
 *   },
 * });
 */
export function useMeQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<MeQuery, MeQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<MeQuery, MeQueryVariables>(
    MeDocument,
    options,
  );
}
export function useMeLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    MeQuery,
    MeQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<MeQuery, MeQueryVariables>(
    MeDocument,
    options,
  );
}
export type MeQueryHookResult = ReturnType<typeof useMeQuery>;
export type MeLazyQueryHookResult = ReturnType<typeof useMeLazyQuery>;
export type MeQueryResult = ApolloReactCommon.QueryResult<
  MeQuery,
  MeQueryVariables
>;
export const UsersDocument = gql`
  query users($limit: Int, $offset: Int, $filters: UserSearchInput!) {
    users(limit: $limit, offset: $offset, filters: $filters) {
      count
      rows {
        id
        name
        code
        region {
          id
        }
        company {
          id
          code
          name
        }
        dept {
          id
          code
          name
        }
        store {
          id
          code
          name
        }
        email
        isAudiologist
        isHearingAidEngineer
        isOutsourcing
        isLocked
        isActive
        roles {
          id
          name
          code
        }
      }
    }
  }
`;

/**
 * __useUsersQuery__
 *
 * To run a query within a React component, call `useUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUsersQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function useUsersQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    UsersQuery,
    UsersQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<UsersQuery, UsersQueryVariables>(
    UsersDocument,
    options,
  );
}
export function useUsersLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    UsersQuery,
    UsersQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<UsersQuery, UsersQueryVariables>(
    UsersDocument,
    options,
  );
}
export type UsersQueryHookResult = ReturnType<typeof useUsersQuery>;
export type UsersLazyQueryHookResult = ReturnType<typeof useUsersLazyQuery>;
export type UsersQueryResult = ApolloReactCommon.QueryResult<
  UsersQuery,
  UsersQueryVariables
>;
export const UsersSelectDocument = gql`
  query usersSelect($limit: Int, $offset: Int, $filters: UserSearchInput!) {
    users(limit: $limit, offset: $offset, filters: $filters) {
      rows {
        id
        name
        code
        dept {
          id
          code
          name
        }
        companyId
      }
    }
  }
`;

/**
 * __useUsersSelectQuery__
 *
 * To run a query within a React component, call `useUsersSelectQuery` and pass it any options that fit your needs.
 * When your component renders, `useUsersSelectQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUsersSelectQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function useUsersSelectQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    UsersSelectQuery,
    UsersSelectQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<UsersSelectQuery, UsersSelectQueryVariables>(
    UsersSelectDocument,
    options,
  );
}
export function useUsersSelectLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    UsersSelectQuery,
    UsersSelectQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    UsersSelectQuery,
    UsersSelectQueryVariables
  >(UsersSelectDocument, options);
}
export type UsersSelectQueryHookResult = ReturnType<typeof useUsersSelectQuery>;
export type UsersSelectLazyQueryHookResult = ReturnType<
  typeof useUsersSelectLazyQuery
>;
export type UsersSelectQueryResult = ApolloReactCommon.QueryResult<
  UsersSelectQuery,
  UsersSelectQueryVariables
>;
