import React, { useEffect, useState } from 'react';
import type { PropsWithChildren } from 'react';
import {
  useMenuBadgeQuery,
  useMenuBadgeSubscriptionSubscription,
  useMeQuery,
} from './graphql/types/generate';
import { MultiTabsLayout } from './modules/common/layouts/multiTabs.layout';
import { useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { MenuProps, Tooltip } from 'antd';
import { useRecoilState, useSetRecoilState } from 'recoil';
import { ROUTE_PATH } from '@/consts';
import { pageViewsState } from './store/page.store';
import { usePageView } from './hooks/usePage.hook';
import {
  HomeOutlined,
  TeamOutlined,
  SearchOutlined,
  PlusOutlined,
  CalendarOutlined,
  SnippetsOutlined,
  AuditOutlined,
  LaptopOutlined,
  FileWordOutlined,
  AccountBookOutlined,
  UserOutlined,
  CheckSquareOutlined,
  ClusterOutlined,
  StockOutlined,
  ScheduleOutlined,
  GoldOutlined,
  BarChartOutlined,
  RocketOutlined,
  DatabaseOutlined,
} from '@ant-design/icons';
import * as localStorageHelper from '@/helpers/localStorage.helper';
import { meState } from './store/me.store';
import { salesGroupState } from './store/salesGroup.store';

interface Props extends PropsWithChildren {}

const Container: React.FC<Props> = (props) => {
  const [pages, setPages] = useRecoilState(pageViewsState);
  const { handleOpenPage, handlePageRemove, handlePageChange, openPage } =
    usePageView();

  const location = useLocation();
  const navigate = useNavigate();
  const { data } = useMeQuery();
  const setMeState = useSetRecoilState(meState);
  const setSalesSate = useSetRecoilState(salesGroupState);
  const { t, i18n } = useTranslation();
  const me = localStorageHelper.getMe();
  const [
    isEyeServiceOrderDataMoreThenOne,
    setIsEyeServiceOrderDataMoreThenOne,
  ] = useState({
    waitAcceptance: false,
    waitApprove: false,
    waitAssign: false,
  });

  const { data: menuBages } = useMenuBadgeQuery();
  const { data: menuBadgeSubscription } =
    useMenuBadgeSubscriptionSubscription();

  const handleLanguageChange = async (key: string) => {
    await i18n.changeLanguage(key);
    let newPage = pages.map((page) => ({
      ...page,
    }));
    setPages(newPage);
  };

  const handleProfileItemClick = (key: string) => {
    navigate(key);
  };

  const items: MenuProps['items'] = [
    {
      key: ROUTE_PATH.HOME,
      icon: <HomeOutlined />,
      label: t('home'),
    },
    {
      key: ROUTE_PATH.CUSTOMER.INDEX,
      label: t('customer'),
      icon: <TeamOutlined />,
      children: [
        {
          key: ROUTE_PATH.CUSTOMER.SEARCH,
          label: t('customer:search'),
          icon: <SearchOutlined />,
        },
        {
          key: ROUTE_PATH.CUSTOMER.CREATE,
          label: t('customer:create'),
          icon: <PlusOutlined />,
        },
        {
          key: ROUTE_PATH.CUSTOMER.CONTACT_PERSON.SEARCH,
          label: t('contactPerson:search'),
          icon: <SearchOutlined />,
        },
        {
          key: ROUTE_PATH.CUSTOMER.CONTACT_PERSON.CREATE,
          label: t('contactPerson:create'),
          icon: <PlusOutlined />,
        },
        {
          key: ROUTE_PATH.CUSTOMER.COMPETITOR.SEARCH,
          label: t('competitor:search'),
          icon: <SearchOutlined />,
        },
        {
          key: ROUTE_PATH.CUSTOMER.COMPETITOR.CREATE,
          label: t('competitor:create'),
          icon: <PlusOutlined />,
        },
      ],
    },
    {
      key: ROUTE_PATH.CUSTOMER.BUSINESS.INDEX,
      label: t('business'),
      icon: <FileWordOutlined />,
      children: [
        {
          key: ROUTE_PATH.CUSTOMER.BUSINESS.SEARCH,
          label: t('business:search'),
          icon: <SearchOutlined />,
        },
        {
          key: ROUTE_PATH.CUSTOMER.BUSINESS.CREATE,
          label: t('business:create'),
          icon: <PlusOutlined />,
        },
      ],
    },
    {
      key: ROUTE_PATH.BUSINESS_PRODUCT.INDEX,
      label: t('business:product'),
      icon: <AccountBookOutlined />,
      children: [
        {
          key: ROUTE_PATH.BUSINESS_PRODUCT.SEARCH,
          label: t('business:product search'),
          icon: <SearchOutlined />,
        },
        {
          key: ROUTE_PATH.BUSINESS_PRODUCT.CREATE,
          label: t('business:create product'),
          icon: <PlusOutlined />,
        },
      ],
    },
    {
      key: ROUTE_PATH.CUSTOMER.VISIT.INDEX,
      label: t('visit'),
      icon: <FileWordOutlined />,
      children: [
        {
          key: ROUTE_PATH.CUSTOMER.VISIT.SEARCH,
          label: t('visit:search'),
          icon: <SearchOutlined />,
        },
        {
          key: ROUTE_PATH.CUSTOMER.VISIT.CREATE,
          label: t('visit:create'),
          icon: <PlusOutlined />,
        },
        {
          key: ROUTE_PATH.CUSTOMER.VISIT.CALENDAR,
          label: t('visit:calendar'),
          icon: <ScheduleOutlined />,
        },
      ],
    },
    {
      key: ROUTE_PATH.ANALYSIS.INDEX,
      label: t('analysis:analysis'),
      icon: <StockOutlined />,
      children: [
        {
          key: ROUTE_PATH.ANALYSIS.BUSINESS,
          label: t('analysis:business'),
          icon: <StockOutlined />,
        },
      ],
    },
    {
      key: ROUTE_PATH.ACHIEVEMENT.INDEX,
      label: t('achievement:achievement'),
      icon: <FileWordOutlined />,
      children: [
        {
          key: ROUTE_PATH.ACHIEVEMENT.NAV_ORDER.SEARCH,
          label: t('achievement:nav order search'),
          icon: <SearchOutlined />,
        },
        {
          key: ROUTE_PATH.ACHIEVEMENT.NAV_SALES_INVOICE.SEARCH,
          label: t('achievement:sales order search'),
          icon: <SearchOutlined />,
        },
        {
          key: ROUTE_PATH.ACHIEVEMENT.DASHBOARD_PRODUCTS.INDEX,
          label: t('dashboard:title'),
          icon: <RocketOutlined />,
        },
        {
          key: ROUTE_PATH.ACHIEVEMENT.MARKETING_TARGET.INDEX,
          label: t('dashboard:marketing title'),
          icon: <RocketOutlined />,
        },
        {
          key: ROUTE_PATH.ACHIEVEMENT.DASHBOARD_TARGET.INDEX,
          label: t('dashboard:target title'),
          icon: <RocketOutlined />,
        },
      ],
    },
    {
      key: ROUTE_PATH.QUOTATION.INDEX,
      label: t('quotation'),
      icon: <FileWordOutlined />,
      children: [
        {
          key: ROUTE_PATH.QUOTATION.SEARCH,
          label: t('quotation:search'),
          icon: <SearchOutlined />,
        },
        {
          key: ROUTE_PATH.QUOTATION.CREATE,
          label: t('quotation:create'),
          icon: <PlusOutlined />,
        },
      ],
    },
    {
      key: ROUTE_PATH.SERVICE_ORDER.INDEX,
      label: t('service order'),
      icon: <SnippetsOutlined />,
      children: [
        {
          key: ROUTE_PATH.SERVICE_ORDER.WORK_ORDER_LIST,
          label: t('work order list'),
          icon: <CalendarOutlined />,
        },
        {
          key: ROUTE_PATH.SERVICE_ORDER.WORK_DIARY_SEARCH,
          label: t('workDiary:search'),
          icon: <SearchOutlined />,
        },
        {
          key: ROUTE_PATH.SERVICE_ORDER.CREATE_WORK_DIARY,
          label: t('workDiary:add'),
          icon: <PlusOutlined />,
        },
        {
          key: ROUTE_PATH.SERVICE_ORDER.SEARCH,
          label: t('serviceOrder:search'),
          icon: <SearchOutlined />,
        },
        {
          key: ROUTE_PATH.SERVICE_ORDER.CREATE,
          label: t('serviceOrder:create'),
          icon: <PlusOutlined />,
        },
        // {
        //   key: ROUTE_PATH.SERVICE_ORDER.WAIT_APPROVE,
        //   label: (
        //     <div className="flex justify-start items-center">
        //       <span>{t('serviceOrder:wait approve')}</span>
        //       {isEyeServiceOrderDataMoreThenOne.waitApprove && (
        //         <div className="ml-[5px] rounded-[4px] w-[8px] h-[8px] bg-red-600" />
        //       )}
        //     </div>
        //   ),
        //   icon: <CheckSquareOutlined />,
        // },
        {
          key: ROUTE_PATH.SERVICE_ORDER.WAIT_ASSIGN,
          label: (
            <div className="flex justify-start items-center">
              <span>{t('serviceOrder:wait assign')}</span>
              {isEyeServiceOrderDataMoreThenOne.waitAssign && (
                <div className="ml-[5px] rounded-[4px] w-[8px] h-[8px] bg-red-600" />
              )}
            </div>
          ),

          icon: <CheckSquareOutlined />,
        },
        {
          key: ROUTE_PATH.SERVICE_ORDER.ACCPETANCE,
          label: (
            <div className="flex justify-start items-center">
              <span>{t('serviceOrder:wait acceptance')}</span>
              {isEyeServiceOrderDataMoreThenOne.waitAcceptance && (
                <div className="ml-[5px] rounded-[4px] w-[8px] h-[8px] bg-red-600" />
              )}
            </div>
          ),
          icon: <CheckSquareOutlined />,
        },
      ],
    },
    {
      key: ROUTE_PATH.WARRANTY_CONTRACT.INDEX,
      label: t('warranty contract'),
      icon: <AuditOutlined />,
      children: [
        {
          key: ROUTE_PATH.WARRANTY_CONTRACT.SEARCH,
          label: t('warrantyContract:search'),
          icon: <SearchOutlined />,
        },
        {
          key: ROUTE_PATH.WARRANTY_CONTRACT.CREATE,
          label: t('warrantyContract:create'),
          icon: <PlusOutlined />,
        },
      ],
    },
    {
      key: ROUTE_PATH.FIXED_ASSET.INDEX,
      label: t('fixed asset'),
      icon: <LaptopOutlined />,
      children: [
        {
          key: ROUTE_PATH.FIXED_ASSET.SEARCH,
          label: t('fixedAsset:search'),
          icon: <SearchOutlined />,
        },
        {
          key: ROUTE_PATH.FIXED_ASSET.CREATE,
          label: t('fixedAsset:create'),
          icon: <PlusOutlined />,
        },
        {
          key: ROUTE_PATH.FIXED_ASSET.LEND_LIST,
          label: t('lend list'),
          icon: <CalendarOutlined />,
        },
      ],
    },
    {
      key: ROUTE_PATH.PRODUCT_EYE.INDEX,
      label: t('product eye'),
      icon: <AccountBookOutlined />,
      children: [
        {
          key: ROUTE_PATH.PRODUCT_EYE.SEARCH,
          label: t('product:search'),
          icon: <SearchOutlined />,
        },
        {
          key: ROUTE_PATH.PRODUCT_EYE.CREATE,
          label: t('product:create'),
          icon: <PlusOutlined />,
        },
      ],
    },
    {
      key: ROUTE_PATH.PROMOTION_EYE.INDEX,
      label: t('EyePromotion'),
      icon: <AccountBookOutlined />,
      children: [
        {
          key: ROUTE_PATH.PROMOTION_EYE.SEARCH,
          label: t('promotion:search'),
          icon: <SearchOutlined />,
        },
        {
          key: ROUTE_PATH.PROMOTION_EYE.CREATE,
          label: t('promotion:create'),
          icon: <PlusOutlined />,
        },
      ],
    },
    {
      key: ROUTE_PATH.MATERIAL.INDEX,
      label: t('material:product number'),
      icon: <GoldOutlined />,
      children: [
        {
          key: ROUTE_PATH.MATERIAL.SEARCH,
          label: t('material:search'),
          icon: <SearchOutlined />,
        },
        {
          key: ROUTE_PATH.MATERIAL.CREATE,
          label: t('material:create'),
          icon: <PlusOutlined />,
        },
      ],
    },
    {
      key: ROUTE_PATH.SALES_TEAM.INDEX,
      label: t('salesTeam:team'),
      icon: <TeamOutlined />,
      children: [
        {
          key: ROUTE_PATH.SALES_TEAM.SEARCH,
          label: t('salesTeam:search'),
          icon: <SearchOutlined />,
        },
        {
          key: ROUTE_PATH.SALES_TEAM.CREATE,
          label: t('salesTeam:create'),
          icon: <PlusOutlined />,
        },
        {
          key: ROUTE_PATH.SALES_TEAM.GROUP,
          label: t('salesTeam:group edit'),
          icon: <ClusterOutlined />,
        },
        {
          key: ROUTE_PATH.SALES_TEAM.UNIT_SEARCH,
          label: t('salesTeam:search unit'),
          icon: <SearchOutlined />,
        },
        {
          key: ROUTE_PATH.SALES_TEAM.UNIT_CREATE,
          label: t('salesTeam:create unit'),
          icon: <PlusOutlined />,
        },
      ],
    },
    {
      key: ROUTE_PATH.PM_TEAM.INDEX,
      label: t('pmTeam:team'),
      icon: <TeamOutlined />,
      children: [
        {
          key: ROUTE_PATH.PM_TEAM.SEARCH,
          label: t('pmTeam:search'),
          icon: <SearchOutlined />,
        },
        {
          key: ROUTE_PATH.PM_TEAM.CREATE,
          label: t('pmTeam:create'),
          icon: <PlusOutlined />,
        },
      ],
    },
    {
      key: ROUTE_PATH.PERMISSIONS.INDEX,
      label: t('permissions:auth manage'),
      icon: <TeamOutlined />,
      children: [
        {
          key: ROUTE_PATH.PERMISSIONS.USER,
          label: t('permissions:user management'),
          icon: <UserOutlined />,
        },
        {
          key: ROUTE_PATH.PERMISSIONS.ROLE.SEARCH,
          label: t('permissions:role management'),
          icon: <UserOutlined />,
        },
      ],
    },
    {
      key: ROUTE_PATH.SALES_PERFORMANCE.INDEX,
      label: t('performance:sales performance'),
      icon: <BarChartOutlined />,
      children: [
        {
          key: ROUTE_PATH.SALES_PERFORMANCE.FORECAST.SEARCH,
          label: (
            <Tooltip title={t('performance:sales forcast title')}>
              {t('performance:sales forcast title')}
            </Tooltip>
          ),
          icon: <SearchOutlined />,
        },
        {
          key: ROUTE_PATH.SALES_PERFORMANCE.TARGET.SALES_SEARCH,
          label: (
            <Tooltip title={t('performance:sales performance title')}>
              {t('performance:sales performance title')}
            </Tooltip>
          ),
          icon: <SearchOutlined />,
        },
        {
          key: ROUTE_PATH.SALES_PERFORMANCE.TARGET.SALESTEAM_SEARCH,
          label: (
            <Tooltip title={t('performance:sales performance team title')}>
              {t('performance:sales performance team title')}
            </Tooltip>
          ),
          icon: <SearchOutlined />,
        },
      ],
    },
    {
      key: ROUTE_PATH.PRODUCT_PERFORMANVE.INDEX,
      label: t('performance:products performance'),
      icon: <BarChartOutlined />,
      children: [
        {
          key: ROUTE_PATH.PRODUCT_PERFORMANVE.FORECAST.SEARCH,
          label: (
            <Tooltip title={t('performance:products forcast title')}>
              {t('performance:products forcast title')}
            </Tooltip>
          ),
          icon: <SearchOutlined />,
        },
        {
          key: ROUTE_PATH.PRODUCT_PERFORMANVE.TARGET.SALES_SEARCH,
          label: (
            <Tooltip title={t('performance:products performance title')}>
              {t('performance:products performance title')}
            </Tooltip>
          ),
          icon: <SearchOutlined />,
        },
        {
          key: ROUTE_PATH.PRODUCT_PERFORMANVE.TARGET.SALESTEAM_SEARCH,
          label: (
            <Tooltip title={t('performance:products performance team title')}>
              {t('performance:products performance team title')}
            </Tooltip>
          ),
          icon: <SearchOutlined />,
        },
      ],
    },
    {
      key: ROUTE_PATH.INVENTORY.SEARCH,
      icon: <DatabaseOutlined />,
      label: t('inventory:title'),
    },
  ];

  const defaultOpenKeys: string[] = [
    ROUTE_PATH.CUSTOMER.INDEX,
    ROUTE_PATH.QUOTATION.INDEX,
    ROUTE_PATH.SERVICE_ORDER.INDEX,
    ROUTE_PATH.CUSTOMER.BUSINESS.INDEX,
    ROUTE_PATH.BUSINESS_PRODUCT.INDEX,
    ROUTE_PATH.CUSTOMER.VISIT.INDEX,
    ROUTE_PATH.ANALYSIS.INDEX,
    ROUTE_PATH.ACHIEVEMENT.INDEX,
    // ROUTE_PATH.WARRANTY_CONTRACT.INDEX,
    // ROUTE_PATH.FIXED_ASSET.INDEX,
    // ROUTE_PATH.PRODUCT_EYE.INDEX,
    // ROUTE_PATH.PROMOTION_EYE.INDEX,
  ];

  const defaultSelectedKeys: string[] = [
    `${location.pathname}${location.search}`,
  ];

  const selectedKey: string[] = [`${location.pathname}${location.search}`];

  // const filteredAuthMenuItems = useMemo(
  //   () =>
  //     items?.filter((item) => {
  //       const permissions = me?.userPermission?.codes;
  //       const subMenuItem = item as SubMenuType;
  //       const filteredChildrenItem = subMenuItem?.children?.filter((child) =>
  //         permissions?.some((code) => {
  //           const auth = routers[child?.key as string]?.auth as string[];
  //           return auth?.includes(code);
  //         }),
  //       );
  //       subMenuItem.children = filteredChildrenItem;
  //       return filteredChildrenItem?.length > 0;
  //     }),
  //   [items, me?.userPermission, routers, data?.me],
  // );

  useEffect(() => {
    if (!me?.userPermission) return;
    if (location.pathname !== ROUTE_PATH.HOME) {
      let params = new URLSearchParams(location.search);
      openPage({
        path: location.pathname,
        search: location.search ? params : undefined,
      });
    }
  }, [location.key, me]);

  useEffect(() => {
    setMeState(me || data?.me || {});
    setSalesSate(
      me?.defaultAvailableSalesTeamGroup ||
        data?.me.defaultAvailableSalesTeamGroup,
    );
  }, [data?.me]);

  useEffect(() => {
    const badges = menuBages?.menuBadge;
    setIsEyeServiceOrderDataMoreThenOne({
      waitApprove: (badges?.eyeServiceOrderWaitForApproval ?? 0) > 0,
      waitAssign: (badges?.eyeServiceOrderWaitForAssign ?? 0) > 0,
      waitAcceptance: (badges?.eyeServiceOrderWaitForAcceptance ?? 0) > 0,
    });
  }, [menuBages?.menuBadge]);

  useEffect(() => {
    const badges = menuBadgeSubscription?.menuBadgeSubscription;
    setIsEyeServiceOrderDataMoreThenOne((prev) => ({
      waitApprove:
        typeof badges?.eyeServiceOrderWaitForApproval === 'number'
          ? badges?.eyeServiceOrderWaitForApproval > 0
          : prev.waitApprove,
      waitAssign:
        typeof badges?.eyeServiceOrderWaitForAssign === 'number'
          ? badges?.eyeServiceOrderWaitForAssign > 0
          : prev.waitAssign,
      waitAcceptance:
        typeof badges?.eyeServiceOrderWaitForAcceptance === 'number'
          ? badges?.eyeServiceOrderWaitForAcceptance > 0
          : prev.waitAcceptance,
    }));
  }, [menuBadgeSubscription?.menuBadgeSubscription]);

  return (
    <MultiTabsLayout
      name={me?.name!}
      menuItems={items}
      salesTeamGroup={me?.availableSalesTeamGroups || []}
      defaultSalesGroup={me?.defaultAvailableSalesTeamGroup}
      defaultOpenKeys={defaultOpenKeys}
      defaultSelectedKeys={defaultSelectedKeys}
      pages={pages}
      onProfileItemClick={handleProfileItemClick}
      onLanguageChange={handleLanguageChange}
      selectedKey={selectedKey}
      onMenuItemClick={(key) => handleOpenPage({ path: key })}
      onPageChange={(key) => handlePageChange({ path: key })}
      onPageRemove={(key) => handlePageRemove({ path: key })}
    />
  );
};
export default Container;
